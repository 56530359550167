export const monthsLabels = {
   rus: {
      default: "",
      parent: [
         "Января",
         "Февраля",
         "Марта",
         "Апреля",
         "Мая",
         "Июня",
         "Июля",
         "Августа",
         "Сентября",
         "Октября",
         "Ноября",
         "Декабря",
      ],
   },
};
export const weeks = [
   "Понедельник",
   "Вторник",
   "Среда",
   "Четверг",
   "Пятница",
   "Суббота",
   "Воскресенье",
];
export const usaWeek = [
   "Воскресенье",
   "Понедельник",
   "Вторник",
   "Среда",
   "Четверг",
   "Пятница",
   "Суббота",
];
export const sidebarWeek = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вск"];
export const weeksOffset = [
   "Среда",
   "Четверг",
   "Пятница",
   "Суббота",
   "Воскресенье",
   "Понедельник",
   "Вторник",
];
