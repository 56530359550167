import React, { FC } from "react";
import styles from "./index.module.css";
import Image from "./assets/Image.png";
import { useTranslation } from "react-i18next";

type propsType = {};
export const About: FC<propsType> = (props) => {
   const { t } = useTranslation();

   return (
      <div className={styles.container}>
         <div className={styles.main_header}>
            {t("Landings.About.header", "О нас")}
         </div>
         <div className={styles.background}>
            {t("Landings.About.background", "Бесплатное обучение для всех")}
         </div>
         <div className={styles.description_container}>
            <img src={Image} className={styles.image} />
            <div className={styles.bottom}>
               <div className={styles.description1}>
                  <p className={styles.header}>
                     {t(
                        "Landings.About.description-header-first",
                        "Наше видение"
                     )}
                  </p>
                  <p className={styles.label}>
                     {t(
                        "Landings.About.description-label-first",
                        "Как сообщество твердо верит в Повестку дня ООН в области устойчивого развития на 2015 год и гордится тем, что поддерживает цель 4 в области устойчивого развития - обеспечение качественного образования для всех. Мы предполагаем свободный и равный доступ к образовательным платформам и материалам по всему миру. Мы желаем, чтобы каждый без каких-либо обстоятельств мог получить разностороннее и грамотное образование."
                     )}
                  </p>
               </div>
               <div className={styles.description2}>
                  <p className={styles.header}>
                     {t(
                        "Landings.About.description-header-second",
                        "Наша миссия"
                     )}
                  </p>
                  <p className={styles.label}>
                     {t(
                        "Landings.About.description-label-second",
                        "В E-Tutor International наша миссия - помогать студентам во всем мире без дополнительных затрат повышать свою успеваемость по всем различным предметам. Мы представляем собой разнообразное сообщество, которое разделяет страсть к учебе и упорной работе в академической карьере. Наша программа является инновационной и единственной в своем роде, поскольку мы приспосабливаемся к потребностям студентов, когда мы вместе отправляемся в путешествие как международное сообщество студентов и учащихся."
                     )}
                  </p>
               </div>
            </div>
            <div className={styles.bottom_line}>E-Tutor International</div>
         </div>
      </div>
   );
};
