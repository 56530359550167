import { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TeacherSearchFilters } from "components/Landings/Filter/TeachersSearch/components/TeacherSearchFilters";
import { useQuery } from "hooks/useQuery";
import { userApi } from "api/userApi/methods";
import { useDispatch, useSelector } from "react-redux";
import { fastLessonSliceSelectors } from "store/fastLessonSlice";
import { teacherType } from "types/userType";
import moment from "moment-timezone";
import { TeacherCard } from "components/Landings/Main/TeachersForYou/TeacherCard";
import { useMedia } from "hooks/useMedia";
import { Preloader } from "components/UI/Preloader";
import { SelectItemType } from "../../../UI/Select/types";
import { errorCaseHandler } from "store/lessonSlice/thunk";
import { showDateYMDNative } from "helpers/date/showDate";

export type TeacherSearchFiltersType = {
   date?: Date;
   rating?: SelectItemType<number>;
   date_from?: any;
   duration?: any;
};

export const TeachersSearch: FC = () => {
   const { t } = useTranslation();
   const {
      control,
      setValue,
      handleSubmit,
      reset,
      setError,
      clearErrors,
      formState,
   } = useForm({
      defaultValues: {
         date: null,
         date_from: undefined,
         date_from_input: "",
         duration: undefined,
         duration_input: "",
         rating: undefined,
         rating_input: "",
      },
   });
   const dispatch = useDispatch();
   const [teachers, setTeachers] = useState<Array<teacherType>>([]);
   const [limit, setLimit] = useState(20);
   const { width } = useMedia();
   const form = useWatch({
      control,
   });

   const [filters, setFilters] = useState<TeacherSearchFiltersType>({});
   const resetHandler = () => {
      //@ts-ignore
      setValue("date", null);
      setValue("date_from", undefined);
      setValue("date_from_input", "");
      setValue("duration", undefined);
      setValue("duration_input", "");
      setValue("rating", undefined);
      setValue("rating_input", "");
      setFilters({});
      setLimit(20);
      setTeachers([]);
      clearErrors();
   };

   const searchFiltersData = useSelector(
      fastLessonSliceSelectors.getFiltersState
   );

   const teachersResponse = useQuery(
      () => {
         const date = moment(showDateYMDNative(filters.date as Date));
         const startDate = filters.date
            ? date
                 .add(filters?.date_from?.value?.minutes || 0, "minutes")
                 .add(filters?.date_from?.value?.hours || 0, "hours")
                 .utcOffset("+0000")
                 .format()
            : undefined;

         return userApi.getUsers({
            limit: String(limit),
            offset: String(limit - 20),
            education_system_id: searchFiltersData.data.educationSystem?.id,
            type: "teacher",
            subject_id: searchFiltersData.data.subject?.id,
            slot_start_time: startDate?.replaceAll("+", "%2B"),
            duration: filters?.duration?.value?.time,
            teaching_rating: filters?.rating?.value,
            is_has_active_slots: true,
            is_banned: false,
            is_blocked: false,
         });
      },
      {
         serializer: (res) =>
            res?.data.data as {
               items_count: number;
               items: Array<teacherType>;
            },
         setDataCondition: (res) => {
            if (res.status == 400) {
               errorCaseHandler(
                  dispatch,
                  res.data.data.errors[0].message,
                  "close"
               );
               return false;
            }
            return true;
         },
         deps: [filters, limit],
      }
   );
   const submitFilters = (data: any) => {
      clearErrors();
      if (data.date && !data.date_from) {
         setError("date_from", {
            message: t(
               "Errors.fields.required",
               "Поле обязательно к заполнению"
            ),
         });
         return;
      }
      if (!data.date && data.date_from) {
         setError("date", {
            message: t(
               "Errors.fields.required",
               "Поле обязательно к заполнению"
            ),
         });
         return;
      }

      setFilters({
         date: data.date || filters.date,
         rating: data.rating || filters.rating,
         date_from: data.date_from || filters.date_from,
         duration: data.duration || filters.duration,
      });
   };
   const simulateSubmit = () => {
      submitFilters(form);
   };
   const increaseLimit = () => {
      setLimit((prev) => prev + 20);
   };
   useEffect(() => {
      if (teachersResponse.data) {
         setTeachers((prev) => [...prev, ...teachersResponse.data.items]);
      }
   }, [teachersResponse.data]);
   useEffect(() => {
      setTeachers([]);
   }, [filters]);

   return (
      <div className={styles.container}>
         {width < 1024 && (
            <TeacherSearchFilters
               control={control}
               setValue={setValue}
               submit={simulateSubmit}
               reset={resetHandler}
               filters={filters}
               setFilters={setFilters}
               errors={formState.errors}
            />
         )}
         <div className={styles.header}>
            {t(
               "SearchFilters.teacher-filters.found-by-request",
               "Найдено по запросу:"
            )}{" "}
            <p className={styles.header_counter}>
               {teachersResponse.data?.items_count}{" "}
               {t(
                  "SearchFilters.teacher-filters.teachers-label",
                  "репетиторов"
               )}
            </p>
         </div>

         <div className={styles.teachers_container}>
            <div className={styles.teachers_top}>
               {teachers.map((item) => (
                  <TeacherCard key={item.id} userData={item} />
               ))}
            </div>
            {teachersResponse.isLoading && (
               <Preloader
                  style={{
                     width: 40,
                     height: 60,
                  }}
                  loading={teachersResponse.isLoading}
                  variant={"centred"}
               />
            )}
            {!teachersResponse.isLoading &&
               teachersResponse?.data?.items_count >= limit && (
                  <button
                     onClick={increaseLimit}
                     className={styles.show_more_button}
                  >
                     {t(
                        "SearchFilters.teacher-filters.show-more",
                        "Показать ещё"
                     )}
                  </button>
               )}
         </div>
         {width >= 1024 && (
            <TeacherSearchFilters
               control={control}
               submit={simulateSubmit}
               setValue={setValue}
               reset={resetHandler}
               filters={filters}
               setFilters={setFilters}
               errors={formState.errors}
            />
         )}
      </div>
   );
};
