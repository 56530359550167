import { authSlice } from "store/authSlice";
import { lkSlice } from "store/lkSlice";
import { notificationSlice } from "store/notificationSlice";
import { ChatThunk } from "store/chatSlice/thunk";
import { chatSlice } from "store/chatSlice";
import { fastLessonSlice } from "store/fastLessonSlice";
import { scheduleSlice } from "store/scheduleSlice";

export const logout = (dispatch: any) => {
   dispatch(authSlice.actions.logout());
   dispatch(lkSlice.actions.clear());
   dispatch(notificationSlice.actions.clear());
   dispatch(chatSlice.actions.clearSlice());
   dispatch(fastLessonSlice.actions.clear());
   dispatch(scheduleSlice.actions.clear());
};
