import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { routes } from "../../../../../../routes";
import { ReactComponent as ArrowIcon } from "../../assets/arrow.svg";
import { ReactComponent as CameraIcon } from "./assets/camera_dark.svg";
import { Button } from "../../../../../UI/Button";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "../../../../../../store/lkSlice";
import { useGetPaymentByPaymentId } from "hooks/useGetPaymentByPaymentId";
import { dateMethods } from "helpers/date";
import {paymentStatus, statusResolver } from "../../components/Table/TableItem";
import { Preloader } from "components/UI/Preloader";
import { useLocalCommonState } from "../../../../../../core/LTools/core/hooks/useLocalCommonState";
import { lessonApi } from "../../../../../../api/lessonApi/methods";

export const TransactionDetails: FC = () => {
   const [price, setPrice] = useState<number | undefined>(undefined);
   const [meetingUrl, setMeetingUrl] = useState("");

   const { t } = useTranslation();
   const user = useSelector(lkSliceSelectors.getUserData);
   const { paymentId }: { paymentId: string } = useParams();
   const { payment, error, isLoading } = useGetPaymentByPaymentId(paymentId);

   const getLesson = () => {
      lessonApi.getLessonData({ lesson_id: payment?.invite.lesson_id })
         .then((res) => {
            setPrice(res.data.data.price);
            setMeetingUrl(res.data.data.meeting_record_url);
         });
   };

   useEffect(() => {
      if (payment?.invite?.lesson_id) {
         getLesson();
      }
   }, [payment]);

   return (
      <div className={cn(styles.container, "LkPaymentPage")}>
         <div className={styles.title}>
            {t("Payment.pages.TransactionDetails.title", "Карточка операции")}
         </div>
         <div className={styles.subtitles}>
            <NavLink
               exact
               to={routes.lk.payment.root}
               activeClassName={styles.button_active}
            >
               <div className={styles.subtitles__item}>
                  <ArrowIcon className={styles.subtitles__icon}/>
                  <div className={styles.subtitles__title}>
                     {t("Payment.nav-links.payment", "Оплата")}
                  </div>
               </div>
            </NavLink>
            <NavLink
               exact
               to={routes.lk.payment.transactions}
               activeClassName={styles.button_active}
            >
               <div className={styles.subtitles__item}>
                  <ArrowIcon className={styles.subtitles__icon}/>
                  <div className={styles.subtitles__title}>
                     {t("Payment.nav-links.transactions-list", "Список операций")}
                  </div>
               </div>
            </NavLink>
            <NavLink
               to={routes.lk.payment.transactions + "/1"}
               activeClassName={styles.button_active}
            >
               <div className={styles.subtitles__item}>
                  <ArrowIcon className={styles.subtitles__icon}/>
                  <div className={styles.subtitles__title}>
                     {t("Payment.nav-links.transaction-details", "Карточка операции")}
                  </div>
               </div>
            </NavLink>
         </div>
         {isLoading ? <Preloader loading={isLoading}/> :
         <div className={styles.lesson__container}>
                <div className={styles.lesson__info}>
                   <div className={styles.lesson__title}>{payment?.invite?.subject?.name}</div>
                   <div className={styles.lesson__items}>
                      <div className={styles.lesson__item}>
                         <div className={styles.lesson__subtitle}>
                            {t(
                                "Payment.pages.TransactionDetails.headers.lesson",
                                "Тема урока"
                            )}
                         </div>
                         <div className={styles.lesson__text}>{payment?.invite?.topic}</div>
                      </div>
                      <div className={styles.lesson__item}>
                         <div className={styles.lesson__subtitle}>
                            {t(
                                "Payment.pages.TransactionDetails.headers.date",
                                "Дата и время занятия"
                            )}
                         </div>
                         <div className={styles.lesson__text}>
                            {dateMethods.format(payment?.invite?.date_from, "DD.MM.YYYY")}
                            <br/>
                            {dateMethods.getTimeInterval(payment?.invite?.date_from, payment?.invite?.date_to)}
                         </div>
                      </div>
                      <div className={styles.lesson__item}>
                         <div className={styles.lesson__subtitle}>
                            {user.type === "student"
                                ? t(
                                    "Payment.pages.TransactionDetails.headers.teacher",
                                    "Преподаватель"
                                ) : t(
                                    "Payment.pages.TransactionDetails.headers.student-full-name",
                                    "ФИО ученика"
                                )}
                         </div>
                         <div
                             className={styles.lesson__text}>{payment?.invite?.student?.first_name} {payment?.invite?.student?.last_name} </div>
                      </div>
                      {/*<div className={styles.divider}></div>*/}
                      <div className={styles.lesson__item}>
                         <div className={styles.lesson__subtitle}>
                            {t(
                                "Payment.pages.TransactionDetails.headers.duration",
                                "Продолжительность"
                            )}
                         </div>
                         <div
                             className={styles.lesson__text}>{dateMethods.calculateLessonDuration(payment?.invite?.date_from, payment?.invite?.date_to)}</div>
                      </div>
                      <div className={styles.lesson__item}>
                         <div className={styles.lesson__subtitle}>
                            {t(
                                "Payment.pages.TransactionDetails.headers.cost",
                                "Стоимость"
                            )}
                         </div>
                         <div className={styles.lesson__text}>{price} {payment?.currency}</div>
                      </div>
                      <div className={styles.lesson__item}>
                         <div className={styles.lesson__subtitle}>
                            {t(
                                "Payment.pages.TransactionDetails.headers.payment-status",
                                "Статус платежа"
                            )}
                         </div>
                         <div
                             className={styles.lesson__text}>{statusResolver[payment?.type as paymentStatus]?.title || " - "}</div>
                      </div>
                   </div>
                </div>
            {/*<div className={styles.lesson__video}>*/}
            {/*   <CameraIcon className={styles.lesson__icon}/>*/}
            {/*   <div className={styles.lesson__subtitle}>Ссылка на урок</div>*/}
            {/*   <a href={"/#"} className={styles.lesson__link}>*/}
            {/*      https://zoom.yandex.ru/search/?text=program+change+initiated+brainwave+phace&lr=193*/}
            {/*   </a>*/}
            {/*</div>*/}
            <div className={styles.lesson__video}>
               <div className={styles.zoom_wrapper}>
                  <CameraIcon className={styles.zoom_icon}/>{" "}
                  <a href={meetingUrl} target={"_blank"}>
                     <Button className={styles.button} variant={"primary"}>
                        {t("Lk.lesson-service.buttons.lesson", "Занятие")}
                     </Button>
                  </a>
               </div>
            </div>
            <div className={styles.lesson__data}>
               {/*<div className={styles.lesson__title}>*/}
               {/*   {t(*/}
               {/*      "Payment.pages.TransactionDetails.headers.payment-data",*/}
               {/*      "Платежные данные"*/}
               {/*   )}*/}
               {/*</div>*/}
               {/*<div className={styles.lesson__item}>*/}
               {/*   /!*TODO: UUID*!/*/}
               {/*   <div className={styles.lesson__subtitle}>UUID</div>*/}
               {/*   <div className={styles.lesson__text}>1ee1f527-d242-687a-b20e-11bcde91b0eb</div>*/}
               {/*</div>*/}
               {user.type === "student" ? (
                  <div className={styles.lesson__item}>
                     <div className={styles.lesson__subtitle}>
                        {t(
                           "Payment.pages.TransactionDetails.headers.card-details",
                           "Реквизиты карты"
                        )}
                     </div>
                     <div className={styles.lesson__text}>40873993700198767002</div>
                  </div>
               ) : (
                  <>
                     {/*<div className={styles.lesson__item}>*/}
                     {/*   <div className={styles.lesson__subtitle}>*/}
                     {/*      {t(*/}
                     {/*         "Payment.pages.TransactionDetails.headers.bank-account-number",*/}
                     {/*         "№ счтеа"*/}
                     {/*      )}*/}
                     {/*   </div>*/}
                     {/*   <div className={styles.lesson__text}>{payment?.bank_card?.pan.toLowerCase()}</div>*/}
                     {/*</div>*/}
                     {/*<div className={styles.lesson__item}>*/}
                     {/*   <div className={styles.lesson__subtitle}>*/}
                     {/*      {t(*/}
                     {/*         "Payment.pages.TransactionDetails.headers.bank-account-number-corr",*/}
                     {/*         "№ кор счета"*/}
                     {/*      )}*/}
                     {/*   </div>*/}
                     {/*   <div className={styles.lesson__text}>{payment?.bank_card?.mps ? payment?.bank_card?.mps : " - "}</div>*/}
                     {/*</div>*/}
                     <div className={styles.lesson__title}>
                        {t(
                           "Payment.pages.TransactionDetails.headers.requisites",
                           "Реквизиты"
                        )}
                     </div>
                     <div className={styles.lesson__item}>
                        <div className={styles.lesson__subtitle}>
                           {t(
                              "Payment.pages.TransactionDetails.fields.name",
                              "Name"
                           )}
                        </div>
                        <div className={styles.lesson__text}>
                           {payment?.requisites.name || "-"}
                        </div>
                     </div>
                     <div className={styles.lesson__item}>
                        <div className={styles.lesson__subtitle}>
                           {t(
                              "Payment.pages.TransactionDetails.fields.account-number",
                              "Account number"
                           )}
                        </div>
                        <div className={styles.lesson__text}>
                           {payment?.requisites.requisites.account_number || "-"}
                        </div>
                     </div>
                     <div className={styles.lesson__item}>
                        <div className={styles.lesson__subtitle}>
                           {t(
                              "Payment.pages.TransactionDetails.fields.sort-code-or-routing-number",
                              "Sort code or routing number"
                           )}
                        </div>
                        <div className={styles.lesson__text}>
                           {payment?.requisites.requisites.sort_code_or_routing_number || "-"}
                        </div>
                     </div>
                     <div className={styles.lesson__item}>
                        <div className={styles.lesson__subtitle}>
                           {t(
                              "Payment.pages.TransactionDetails.fields.bic",
                              "BIC"
                           )}
                        </div>
                        <div className={styles.lesson__text}>
                           {payment?.requisites.requisites.bic || "-"}
                        </div>
                     </div>
                     <div className={styles.lesson__item}>
                        <div className={styles.lesson__subtitle}>
                           {t(
                              "Payment.pages.TransactionDetails.fields.iban",
                              "IBAN"
                           )}
                        </div>
                        <div className={styles.lesson__text}>
                           {payment?.requisites.requisites.iban || "-"}
                        </div>
                     </div>
                     <div className={styles.lesson__item}>
                        <div className={styles.lesson__subtitle}>
                           {t(
                              "Payment.pages.TransactionDetails.address-line-1",
                              "Address Line 1"
                           )}
                        </div>
                        <div className={styles.lesson__text}>
                           {payment?.requisites.address.address_line_1 || "-"}
                        </div>
                     </div>
                     <div className={styles.lesson__item}>
                        <div className={styles.lesson__subtitle}>
                           {t(
                              "Payment.pages.TransactionDetails.address-line-2",
                              "Address Line 2"
                           )}
                        </div>
                        <div className={styles.lesson__text}>
                           {payment?.requisites.address.address_line_2 || "-"}
                        </div>
                     </div>
                     <div className={styles.lesson__item}>
                        <div className={styles.lesson__subtitle}>
                           {t(
                              "Payment.pages.TransactionDetails.fields.city",
                              "City"
                           )}
                        </div>
                        <div className={styles.lesson__text}>
                           {payment?.requisites.address.city || "-"}
                        </div>
                     </div>
                     <div className={styles.lesson__item}>
                        <div className={styles.lesson__subtitle}>
                           {t(
                              "Payment.pages.TransactionDetails.fields.postal-code",
                              "Postal code"
                           )}
                        </div>
                        <div className={styles.lesson__text}>
                           {payment?.requisites.address.postal_code || "-"}
                        </div>
                     </div>
                     <div className={styles.lesson__item}>
                        <div className={styles.lesson__subtitle}>
                           {t(
                              "Payment.pages.TransactionDetails.fields.recipient-bank-country",
                              "Recipient bank country"
                           )}
                        </div>
                        <div className={styles.lesson__text}>
                           {payment?.requisites.address.recipient_bank_country || "-"}
                        </div>
                     </div>
                     <div className={styles.lesson__item}>
                        <div className={styles.lesson__subtitle}>
                           {t(
                              "Payment.pages.TransactionDetails.fields.state-or-province",
                              "State or province"
                           )}
                        </div>
                        <div className={styles.lesson__text}>
                           {payment?.requisites.address.state_or_province || "-"}
                        </div>
                     </div>
                  </>
               )}
            </div>
         </div>
         }
      </div>
   );
};
