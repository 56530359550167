import { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";

type useQueryType<T> = {
   data: T extends Object ? T : AxiosResponse<any> | undefined;
   isLoading: boolean;
   setData: (data: any) => void;
   fetch: () => void;
};
export type serializerType<T> = (data: AxiosResponse<any> | undefined) => T;
type propsType<T> = {
   deps?: Array<any>;
   serializer?: serializerType<T>;
   reserializeDeps?: Array<any>;
   condition?: boolean;
   ping?: number;
   setDataCondition?: (res: AxiosResponse<any>) => boolean;
   cachedValueName?: "subjects" | "languages" | "level";
};

/**
 * @useQuery
 * @param method: колбек запроса
 * @param options: {
 *   @deps: состояние при изменении которого произойдёт refetch
 *   @serializer: колбек который принимает респонс
 *   и трансформирует данные так как удобно + типизация
 *   @condition: boolean переменная, условие для выполнения запроса
 *   @setDataCondition: колбек для того чтобы решить стоит сетить новую дату
 *   @cache: string - имя поля кешируемого значения
 * }
 */

var useQueryCache: any = {};

export function useQuery<T>(
   method: (token?: any) => Promise<AxiosResponse<any>>,
   options?: propsType<T>
): useQueryType<T> {
   const [axiosResponse, setAxiosResponse] = useState<AxiosResponse<any>>();
   const [isLoading, setIsLoading] = useState(false);
   const [result, setResult] = useState<any>();
   const deps = options?.deps ? [...options.deps] : [];

   const fetchConditionWrapper = async () => {
      if (options?.condition !== undefined) {
         if (options.condition) {
            await fetch();
         }
      } else {
         await fetch();
      }
   };

   const checkIfCaching = () => {
      if (options?.cachedValueName && useQueryCache[options.cachedValueName]) {
         return useQueryCache[options.cachedValueName];
      }

      return false;
   };

   const setResultHandler = (data: any) => {
      setResult(options?.serializer ? options.serializer(data) : data);
   };

   const fetch = async () => {
      const cached = checkIfCaching();
      if (cached) {
         setAxiosResponse(cached);
         setResultHandler(cached);
         return;
      }

      setIsLoading(true);
      const response = await method();
      setIsLoading(false);

      if (response && response.status != 500) {
         if (
            options?.setDataCondition
               ? options.setDataCondition(response)
               : true
         ) {
            setAxiosResponse(response);

            if (options?.cachedValueName) {
               useQueryCache[options?.cachedValueName] = response;
            }

            setResult(
               options?.serializer ? options.serializer(response) : response
            );
         }
      }
   };

   useEffect(() => {
      if (options?.reserializeDeps) {
         setResult(
            options?.serializer
               ? options.serializer(axiosResponse)
               : axiosResponse
         );
      }
   }, options?.reserializeDeps);

   useEffect(() => {
      fetchConditionWrapper();
   }, [...deps]);
   useEffect(() => {
      if (!options?.ping) return;
      let intervalId = window.setInterval(fetch, options.ping);
      return () => window.clearInterval(intervalId);
   }, []);

   return {
      //@ts-ignore
      data: result,
      isLoading,
      setData: setResult,
      fetch: fetch,
   };
}
