import React, { FC, useEffect } from "react";
import { Schedule } from "components/Lk/Pages/SchedulePage/Schedule";
import { ScheduleFilter } from "components/Lk/Pages/SchedulePage/ScheduleFilter";
import { Events } from "components/Lk/Pages/SchedulePage/Events";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "store";
import { scheduleSlice } from "store/scheduleSlice";

type propsType = {};
export type ScheduleFilterFormType = {
   student_open: boolean;
   student_proved: boolean;
   student_unproved: boolean;
   teacher_open: boolean;
   teacher_proved: boolean;
   slot: boolean;
   teacher_unproved: boolean;
};
export const SchedulePage: FC<propsType> = (props) => {
   const filters = useSelector(
      (state: StateType) => state.scheduleSlice.filters
   );
   const { control, setValue } = useForm<ScheduleFilterFormType>({
      defaultValues: filters,
   });
   const form = useWatch({ control });
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(scheduleSlice.actions.setFilters(form));
   }, [form]);
   return (
      <>
         <Schedule setValue={setValue} />
         <ScheduleFilter control={control} />
         <Events setValue={setValue} />
      </>
   );
};
