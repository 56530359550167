import { TitleSwitcher } from "../../../Notebook/components/NoteHistory/components/TitleSwitcher";
import styles from "./index.module.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { NoteTable } from "./Table";
import { Preloader } from "../../../../../UI/Preloader";
import { Icons } from "../../../../../../assets/iconsCorrect";
import { NavLink } from "react-router-dom";
import { routes } from "../../../../../../routes";
import { ReactComponent as ArrowIcon } from "../../../../../../assets/iconsCorrect/uiIcons/Arrow.svg";
import { adminApi } from "../../../../../../api/adminApi";
import { getFileFromEvent } from "../../../../../../helpers/file";
import { useQuery } from "react-query";
import { lkApi } from "../../../../../../api/LkApi/methods";
import { paymentRegistryDataType } from "../../../../../../api/adminApi/types";
import moment from "moment/moment";
import { ErrorType } from "../../../../../../core/LTools/core/types/types";

export const WorkingWithRegistries = () => {
   const [switcherState, setSwitcherState] = useState(false);
   const [dragActive, setDragActive] = useState(false);
   const [handling, setHandling] = useState(false);
   const [finished, setFinished] = useState(false);
   const [registryId, setRegistryId] = useState<number | undefined>(undefined);
   const [registryStatus, setRegistryStatus] = useState<"processing" | "finished" | undefined>(undefined);
   const [registries, setRegistries] = useState<Array<{ date: Date, id: number }>>([]);
   const [error, setError] = useState<ErrorType | null>(null);

   const { t } = useTranslation();

   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();

      if (e.target.files) {
         uploadRegistry(e.target.files[0]);
      }
   };

   const handleDrag = (e: React.DragEvent<HTMLFormElement>) => {
      e.preventDefault();
      setDragActive(true);
   };

   const handleLeave = (e: React.DragEvent<HTMLFormElement>) => {
      e.preventDefault();
      setDragActive(false);
   };

   const handleDrop = (e: React.DragEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
         setHandling(true);
         uploadRegistry(e.dataTransfer.files[0]);
      }
   };

   const uploadRegistry = (file: any) => {
      setHandling(true);

      getFileFromEvent(file).then((res: any) => {
         adminApi.uploadPaymentRegistry({ content: res?.value })
            .then((res) => {
               if (res.status === 201) {
                  setRegistryId(res.data.data.id);
               }
               if (res.status === 400) {
                  setError(res.data.data.errors[0]);
                  setHandling(false);
               }
            })
            .catch((error) => {
               setHandling(false);
            });
      });
   };

   const { data, isLoading } = useQuery(
      "downloadPaymentRegistry",
      () => adminApi.getPaymentRegistryById({
         registry_id: registryId!,
      }),
      {
         onSuccess: (res) => {
            if (res.data.data.status === "finished") {
               setHandling(false);
            }
            setRegistryStatus(res.data.data.status);
         },
         enabled: !!registryId && registryStatus !== "finished",
         refetchInterval: 3000,
      }
   );

   const registryData: paymentRegistryDataType = data?.data.data;

   // console.log(registryData, isLoading);



   // useEffect(() => {
   //    if (handling) {
   //       setTimeout(() => {
   //          setFinished(true);
   //          setHandling(false);
   //       }, 3000);
   //    }
   // }, [handling]);

   useEffect(() => {
      if (registryStatus === "finished") {
         setFinished(true);
      }
   }, [registryStatus]);

   useEffect(() => {
      adminApi.getPaymentRegistry().then(res => setRegistries(res.data.data.items));
   }, []);

   return (
      <div className={cn(styles.container, "LkPaymentPage")}>
         <div className={styles.title}>
            {t("FinancialOperations.pages.working-with-registries", "Работа с реестрами")}
         </div>
         <div className={styles.subtitles}>
            <NavLink
               exact
               to={routes.lk.financialOperations.root}
               activeClassName={styles.button_active}
            >
               <div className={styles.subtitles__item}>
                  <ArrowIcon className={styles.subtitles__icon}/>
                  <div className={styles.subtitles__title}>
                     {t("FinancialOperations.links.financial-operations", "Финансовые операции")}
                  </div>
               </div>
            </NavLink>
            <NavLink
               exact
               to={routes.lk.financialOperations.operations.workingWithRegistries}
               activeClassName={styles.button_active}
            >
               <div className={styles.subtitles__item}>
                  <ArrowIcon className={styles.subtitles__icon}/>
                  <div className={styles.subtitles__title}>
                     {t("FinancialOperations.links.working-with-registries", "Работа с реестрами")}
                  </div>
               </div>
            </NavLink>
         </div>
         <TitleSwitcher
            selected={switcherState}
            labels={[
               t("FinancialOperations.headers.registry-loading", "Загрузка реестра"),
               t("FinancialOperations.headers.registry-receiving", "Получение реестра"),
            ]}
            setSelect={setSwitcherState}
            className={styles.switcher}
         />
         {switcherState ? (
            <NoteTable
               data={registries}
            />
         ) : (
            <div className={styles.wrapper}>
               <div className={styles.subtitle}>
                  {t(
                     "FinancialOperations.text.load-register-for-handling",
                     "Загрузите реестр для обработки (формат .csv)"
                  )}
               </div>
               <div className={styles.drag_area}>
                  <form
                     className={styles.form}
                     onDragEnter={handleDrag}
                     onDragOver={handleDrag}
                     onDragLeave={handleLeave}
                     onDrop={handleDrop}>
                     <label className={cn(styles.label, {
                        [styles.drag_active]: dragActive,
                     })}>
                        <Preloader loading={handling} variant={"middle"} />
                        {!handling && <Icons.ui.Upload />}
                        <input type="file" className={styles.input} onChange={handleChange} />
                     </label>
                  </form>
               </div>
               {handling && (
                  <div>
                     {t(
                        "FinancialOperations.text.import-result-waiting",
                        "Ожидание результата импорта"
                     )}
                  </div>
               )}
               {finished && (
                  <div className={styles.result}>
                     {/*<div>*/}
                     {/*   {t(*/}
                     {/*      "FinancialOperations.text.last-launch-info",*/}
                     {/*      "Сведения о последнем запуске"*/}
                     {/*   )}:*/}
                     {/*</div>*/}
                     <div>
                        {t(
                           "FinancialOperations.text.registry-is-uploaded",
                           "Реестр загружен"
                        )}:{" "}
                        {moment(registryData.created_at).format("DD.MM.YYYY, HH:mm:ss")}
                     </div>
                     <div>
                        {t(
                           "FinancialOperations.text.status",
                           "Статус"
                        )}:{" "}
                        {registryData.status}
                     </div>
                     <div>
                        {t(
                           "FinancialOperations.text.total-lines",
                           "Всего строк"
                        )}:{" "}
                        {registryData.entry_count}
                     </div>
                     <div>
                        {t(
                           "FinancialOperations.text.successfully-processed",
                           "Успешно обработано"
                        )}:{" "}
                        {registryData.success_count}
                     </div>
                     <div>
                        {t(
                           "FinancialOperations.text.errors-count",
                           "Количество ошибок"
                        )}:{" "}
                        {registryData.error_count}
                     </div>
                     {/*<div>*/}
                     {/*   {t(*/}
                     {/*      "FinancialOperations.text.user",*/}
                     {/*      "Пользователь"*/}
                     {/*   )}: Администратор*/}
                     {/*</div>*/}
                     {/*<div>*/}
                     {/*   {t(*/}
                     {/*      "FinancialOperations.text.launch-date",*/}
                     {/*      "Дата запуска"*/}
                     {/*   )}: 05.05.2024 12:13:40*/}
                     {/*</div>*/}
                     {/*<div>*/}
                     {/*   {t(*/}
                     {/*      "FinancialOperations.text.result",*/}
                     {/*      "Результат"*/}
                     {/*   )}: Обработан 1 объект*/}
                     {/*</div>*/}
                  </div>
               )}
               {error && (
                  <div className={styles.error}>
                     <div>
                        {t(
                           "FinancialOperations.text.error-type",
                           "Тип ошибки"
                        )}{" "}{error.code}
                     </div>
                     <div>
                        {t(
                           "FinancialOperations.text.error-text",
                           "Текст ошибки"
                        )}{" "}{error.message}
                     </div>
                  </div>
               )}
            </div>
         )}
      </div>
   );
};
