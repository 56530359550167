import React, { FC, useEffect, useRef } from "react";
import styles from "../index.module.css";
import { useBlurred } from "hooks/useBlurred";
import { UseFormWatch } from "react-hook-form";
import { useSelect } from "../useSelect";
import { SelectItemRender } from "../SelectElement/SelectElement";
import { Input, InputPropsType } from "../../Input";
import { ReactComponent as DownArrowSelect } from "../../../../assets/buttons/DownArrowSelect.svg";
import { ReactComponent as MenuSearchIcon } from "../../../../assets/buttons/MenuSearchIcon.svg";
import cn from "classnames";
import { Preloader } from "components/UI/Preloader";
import { useScrollLimit } from "hooks/useScrollLimit";
import { SelectItemType } from "../types";
import { useTranslation } from "react-i18next";
import { Icons } from "assets/iconsCorrect";

type propsType<T, F> = {
   watch?: UseFormWatch<any>;
   name: string;
   setValue: any;
   mask?: string;
   options?: Array<{
      id: number;
      text: string;
      value: F;
   }>;
   onSelectValue?: (v: { id: number; test: string; value: F }) => void;
   component?: FC<F>;
   searchField?: boolean;
   bgColor?: string;
   variant?: "default" | "blue-bg" | "filter";
   defaultSelectValue?: SelectItemType<F>;
   loupe?: boolean;
   clearable?: boolean;
   setError?: any;
} & InputPropsType<T>;

export function SelectUnderControl<T, F>({
   mask,
   children,
   options,
   searchField,
   control,
   onSelectValue,
   loupe = false,
   defaultSelectValue,
   setError,
   ...other
}: propsType<T, F>) {
   const ref = useRef<HTMLDivElement>(null);
   const { i18n } = useTranslation();
   const select = useSelect<F>({
      control,
      setValue: other.setValue,
      name: other.name,
      onSelect: onSelectValue,
      setError,
   });
   useBlurred(ref, select.setFocus, select.focus);

   const inputValueLowerCase = select?.inputValue?.toLowerCase();
   const { limit, updateLimitOnScroll } = useScrollLimit({
      step: 40,
      difference: 2000,
   });

   const clearHandler = () => {
      other.setValue(other.name, undefined);
      other.setValue(other.name + " _input", "");
   };
   /* init */
   useEffect(() => {
      if (defaultSelectValue) {
         other.setValue(other.name, defaultSelectValue);
         other.setValue(other.name + "_input", defaultSelectValue?.text);
      } else {
         other.setValue(other.name, undefined);
         other.setValue(other.name + "_input", "");
      }
   }, [defaultSelectValue]);

   const renderSupport = () => {
      /** @priority
       * loading -> customSupport -> loupe -> default
       *  */
      if (other.isLoading)
         return (
            <Preloader
               style={{
                  width: 20,
                  height: 20,
               }}
               loading={true}
            />
         );
      if (select.selected?.value && other.clearable) {
         return <Icons.ui.closeIcon onClick={clearHandler} />;
      }
      if (other.support) {
         return other.support;
      }
      if (loupe) return <MenuSearchIcon />;

      return (
         <DownArrowSelect
            style={{
               transform: `rotateX(${select.focus ? 180 : 0}deg)`,
            }}
         />
      );
   };

   useEffect(() => {
      i18n.on("languageChanged", () => {
         other.setValue(other.name, undefined);
         other.setValue(other.name + "_input", "");
      });
   }, []);

   const displayOptions = () => {
      if (!options) return [];

      return options
         .filter((item, index) => {
            /*
             * Селект с поиском && В инпут введены данные
             * && Данные в инпуте не равны данным в селекте
             *  */

            if (
               searchField &&
               inputValueLowerCase &&
               select?.selected?.value.text != select.inputValue
            ) {
               return (
                  item.text
                     .toLocaleLowerCase()
                     // .replaceAll("(", `\\` + `(`)
                     .includes(inputValueLowerCase)
               );
            }
            return index <= limit;
         })
         ?.map((item, index) => {
            return (
               <SelectItemRender
                  key={index}
                  index={index}
                  select={select.setSelectHandler}
                  element={item}
                  component={other.component}
               />
            );
         });
   };
   const onBlur = (e: any) => {};

   const formRef = control.register(other.name).ref;
   return (
      <div
         className={cn(styles.container, {
            [`${other.className}`]: other.className,
            [styles[`container--${other.variant}`]]: other.variant,
            [styles[`container--readonly`]]: other.readOnly,
            [styles[`container--search`]]: searchField,
            [styles.disabled]: other.disabled,
         })}
         style={{
            ...other.style,
            backgroundColor: other.bgColor,
         }}
         ref={ref}
         onBlur={onBlur}
      >
         <div ref={formRef} />
         {other.variant == "filter" ? (
            <div
               className={styles.filter}
               onClick={() => select.setFocus(true)}
            >
               {other.label}
            </div>
         ) : (
            <Input
               onFocus={() => select.setFocus(true)}
               {...other}
               onChange={select.onChange}
               control={control}
               disabled={other.isLoading || other.readOnly}
               readOnly={!searchField}
               /*@ts-ignore*/
               name={other.name + "_input"}
               className={cn(styles.input)}
               support={
                  <div
                     className={styles.support}
                     onClick={() => select.setFocus((prevState) => !prevState)}
                  >
                     {renderSupport()}
                  </div>
               }
               style={{
                  backgroundColor: other.bgColor,
               }}
            />
         )}

         {select.focus && (
            <div className={styles.list} onScroll={updateLimitOnScroll}>
               {displayOptions()}
            </div>
         )}
      </div>
   );
}
