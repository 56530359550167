import React, { ChangeEvent, FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import EarthIcon from "./assets/Earth.png";
import { Switcher } from "../../../UI/Switcher";
import { useForm, useWatch } from "react-hook-form";
import { SelectUnderControl } from "../../../UI/Select/SelectUnderControl";
import { useHistory } from "react-router-dom";
import { firstLetterUpperCase } from "helpers/string/firstLetterUppercase";
import { useDispatch, useSelector } from "react-redux";
import {
   fastLessonSlice,
   fastLessonSliceSelectors,
} from "store/fastLessonSlice";
import { Trans, useTranslation, withTranslation } from "react-i18next";
import { useQuery } from "hooks/useQuery";
import { formsDataApi } from "api/formsDataApi/methods";
import { languageSelectSerializer } from "serializers/languages";
import { subjectSelectSerializer } from "serializers/subject";
import {
   educationSystemType,
   languagesNameType,
   levelType,
} from "store/formsDataSlice/types";
import { Icons } from "assets/iconsCorrect";
import { selectLanguageItem } from "../../../UI/Select/SelectItems/SelectLanguageItem";
import { subjectType } from "store/types";
import { routes } from "routes";
import i18n from "services/translateService/i18n";
import { authSliceSelectors } from "store/authSlice";
import { lkSliceSelectors } from "../../../../store/lkSlice";
import {
   languageCodeResolver,
   resolveLanguageNameByCode,
} from "../../../../staticData/languages";
import { Select } from "../../../UI/SelectNew";

type propsType = {
   config: {
      redirectUrl: string;
   };
};
type formType = {
   language: any;
   fast: string;
   search: string;
   subject: any;
};
export const ThisComponent: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const { control, handleSubmit, setValue } = useForm<formType>();
   const history = useHistory();
   const dispatch = useDispatch();
   const isFast = useSelector(fastLessonSliceSelectors.getIsFast);
   const token = useSelector(authSliceSelectors.getToken);
   const langCode = useSelector(lkSliceSelectors.getLang);

   const subject = useWatch({
      control,
      name: "subject",
   });
   const language = useWatch({
      control,
      name: "language",
   });

   /* fetch */
   const { data: languages, isLoading: languagesLoading } = useQuery(
      formsDataApi.getLanguage,
      {
         cachedValueName: "languages",
         serializer: languageSelectSerializer,
         reserializeDeps: [i18n.language],
      }
   );

   const { data: subjects, isLoading: subjectsLoading } = useQuery(
      formsDataApi.getSubject,
      {
         serializer: subjectSelectSerializer,
         cachedValueName: "subjects",
      }
   );
   const { data: level, isLoading: levelLoading } = useQuery(
      formsDataApi.getLevel,
      {
         cachedValueName: "level",
         serializer: (res): Array<levelType> => res?.data?.data?.items,
      }
   );

   /* actions */
   const setLevelHandler = (level: levelType) => {
      dispatch(
         fastLessonSlice.actions.setFiltersData({
            level,
         })
      );
   };

   const submit = (data: any) => {
      if (data.language) {
         dispatch(
            fastLessonSlice.actions.setFiltersData({
               language: data.language.value,
            })
         );
      }
      history.push(props.config.redirectUrl);
   };
   const setIsFastSearch = (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.currentTarget.checked;
      dispatch(fastLessonSlice.actions.setIsFast(val));
   };

   /* effects */
   useEffect(() => {
      const chosenSubject = subject?.value as subjectType;
      if (chosenSubject) {
         if (token) {
            dispatch(
               fastLessonSlice.actions.setFiltersData({
                  subject: chosenSubject,
                  educationSystem: chosenSubject.education_system,
                  level: chosenSubject.education_system.level,
                  language: language?.value,
               })
            );
            if (isFast) {
               history.push(routes.lk.lesson.fast.register);
            } else {
               history.push(routes.landings.filter);
            }

            dispatch(fastLessonSlice.actions.setFiltersStage(2));
         } else {
            history.push(routes.registration.auth.firstStage);
         }
      }
   }, [subject]);

   useEffect(() => {
      dispatch(fastLessonSlice.actions.clearFilters());
      dispatch(fastLessonSlice.actions.setIsFast(false));
      dispatch(fastLessonSlice.actions.setFiltersStage(0));
   }, []);

   useEffect(() => {
      console.log(langCode, resolveLanguageNameByCode[langCode]);

      const foundLanguage = languages?.find((language) =>
         language?.value?.name?.includes(resolveLanguageNameByCode[langCode])
      );
      if (foundLanguage) {
         console.log("resolved lang", foundLanguage);
         setValue("language", {
            id: foundLanguage?.id,
            text: foundLanguage?.text,
            value: {
               title: foundLanguage?.text,
               id: foundLanguage?.id,
               name: foundLanguage?.value?.name as languagesNameType,
            },
         }); //@ts-ignore
         setValue("language_input", foundLanguage.text);
         dispatch(
            fastLessonSlice.actions.setFiltersData({
               language: foundLanguage?.value,
            })
         );
      } else if (languages?.length) {
         console.log("else", languages);
         const defaultLanguage = languages?.find(
            (language) => language.value.name == "English"
         );

         setValue("language", defaultLanguage);
         setValue(
            //@ts-ignore
            "language_input",
            defaultLanguage?.text
         );

         dispatch(
            fastLessonSlice.actions.setFiltersData({
               language: defaultLanguage?.value,
            })
         );
      }
   }, [langCode, languages]);

   return (
      <div className={styles.container}>
         <div className={styles.title}>
            <Trans i18nKey='Landings.SearchFilters.title'>
               <p>Начните обучаться</p>
               <p>сегодня!</p>
            </Trans>
         </div>
         <form className={styles.form} onSubmit={handleSubmit(submit)}>
            <div className={styles.switch}>
               <div className={styles.switch_left}>
                  <Switcher
                     onChange={setIsFastSearch}
                     variant={"primary"}
                     control={control}
                     name='fast'
                  />
                  <p>
                     {t(
                        "Landings.SearchFilters.fast_register_title",
                        "Быстрая онлайн запись"
                     )}
                  </p>
               </div>
               {/*<div className={styles.switch_right}>?</div>*/}
            </div>
            <div className={styles.filters}>
               <div className={styles.mobile_title}>
                  {t("Landings.SearchFilters.mobile-title-search", "Поиск")}
               </div>
               <div className={styles.filters_left}>
                  <Select
                     name='language'
                     isLoading={languagesLoading}
                     setValue={setValue}
                     placeholder={t(
                        "Landings.SearchFilters.entry-filters-left",
                        "Язык обучения"
                     )}
                     options={languages}
                     optionComponent={selectLanguageItem}
                     variant='blue-bg'
                     control={control}
                  />
               </div>
               <SelectUnderControl
                  name='subject'
                  setValue={setValue}
                  isLoading={subjectsLoading}
                  label={t(
                     "Landings.SearchFilters.entry-filters-right",
                     "Введите интересуемый предмет"
                  )}
                  options={subjects}
                  readOnly={!languages?.length}
                  searchField
                  component={selectSubjectItem}
                  control={control}
                  bgColor={"#F8F8F8"}
                  variant='blue-bg'
                  loupe
               />
            </div>
            <div className={styles.mobile_title}>
               {t("Landings.SearchFilters.mobile-title-filters", "Фильтры")}
            </div>
            <div className={styles.gradeWrapper}>
               {level?.map((level, index) => (
                  <button
                     key={index}
                     data-id={level.id}
                     className={styles.grade_button}
                     onClick={() => setLevelHandler(level)}
                  >
                     {level?.name &&
                        React.createElement(Icons.levelIcons[level?.name], {
                           className: "SearchFilters__entry-grade-button-icon",
                        })}
                     <p>{level.name}</p>
                  </button>
               ))}
            </div>
         </form>
         <img className={styles.background_earth} src={EarthIcon} />
      </div>
   );
};

export const selectSubjectItem = (subject: subjectType) => {
   return (
      <div className={styles.select_subject_item}>
         <div className={styles["select_subject_item__name"]}>
            {firstLetterUpperCase(subject.name)}
         </div>
         <div>
            <span className={styles["select_subject_item__es"]}>
               {subject.education_system.name}
            </span>
            <span className={styles["select_subject_item__es"]}>
               {subject.education_system.level.name}
            </span>
            {subject?.category ? subject?.category?.name : ""}
         </div>
      </div>
   );
};

export const SearchFilters = withTranslation()(ThisComponent);
