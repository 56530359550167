import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";

type propsType = {
   title: string;
};
export const ProfileCardsWrapper: FC<propsType> = (props) => {
   return (
      <div className={styles.container}>
         <h2 className={styles.title}>{props.title}</h2>
         <main className={styles.main}>{props.children}</main>
      </div>
   );
};
