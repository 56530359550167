import i18n from "services/translateService/i18n";
import { eventPeriodicityType } from "api/eventSlotApi/types";
import { addMethodToUpdateLang } from "services/translateService/useLangInit";

export let resolveTitleByPeriodicity: {
   [key in eventPeriodicityType]: string;
};

addMethodToUpdateLang(() => {
   resolveTitleByPeriodicity = {
      every_day: i18n.t("CreateSlot.periodicity.array.0", "Каждый день"),
      every_week: i18n.t("CreateSlot.periodicity.array.1", "Каждую неделю"),
      every_month: i18n.t("CreateSlot.periodicity.array.2", "Каждый месяц"),
      once: i18n.t("CreateSlot.periodicity.array.3", "Не повторять"),
   };
});
