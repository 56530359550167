import React, { FC } from "react";
import styles from "./index.module.css";
import { Route } from "react-router-dom";
import { routes } from "../../../../../../routes";
import { SystemsOverview } from "./Pages/SystemsOverview";
import { AddSystem } from "./Pages/AddSystem";
import { AddSubject } from "./Pages/AddSubject";
import { AddCategory } from "./Pages/AddCategory";

type propsType = {};
export const EducationSystems: FC<propsType> = (props) => {
   return (
      <div className={styles.container}>
         <Route exact path={routes.lk.admin.educationSystems.root}>
            <SystemsOverview />
         </Route>
         <Route
            path={[
               routes.lk.admin.educationSystems.addSystem + "/:id",
               routes.lk.admin.educationSystems.addSystem,
            ]}
         >
            <AddSystem />
         </Route>
         <Route
            path={[
               routes.lk.admin.educationSystems.addSubject + "/:id",
               routes.lk.admin.educationSystems.addSubject,
            ]}
         >
            <AddSubject />
         </Route>
         <Route
            path={[
               routes.lk.admin.educationSystems.addCategory + "/:id",
               routes.lk.admin.educationSystems.addCategory,
            ]}
         >
            <AddCategory />
         </Route>
      </div>
   );
};
