import { createRequest } from "../../core/LTools/core/apiMethods/createRequest";
import { mainAxios } from "../index";
import {
   addFileToApplicationType,
   createApplicationType,
   createLessonFromApplicationType,
   deleteApplicationType,
   deleteSuggestionType,
   getApplicationFileType,
   getApplicationsType,
   getApplicationType,
   getSuggestionsType,
} from "./types";
import { dataToQueryString } from "../../core/LTools/core/apiMethods/dataToQueryString";

export const applicationApi = {
   /* Application */
   createApplication: async ({
      user_id,
      ...payload
   }: createApplicationType) => {
      return createRequest(
         mainAxios.post(`/user/${user_id}/application`, payload)
      );
   },
   getApplications: async ({ user_id }: getApplicationsType) => {
      return createRequest(mainAxios.get(`/user/${user_id}/application`));
   },
   getApplication: async ({ user_id, application_id }: getApplicationType) => {
      return createRequest(
         mainAxios.get(`/user/${user_id}/application/${application_id}`)
      );
   },
   getApplicationFile: async ({
      file_id,
      application_id,
   }: getApplicationFileType) => {
      return createRequest(
         mainAxios.get(`/application/${application_id}/file/${file_id}`)
      );
   },
   deleteApplication: async ({
      user_id,
      application_id,
   }: deleteApplicationType) => {
      return createRequest(
         mainAxios.delete(`/user/${user_id}/application/${application_id}`)
      );
   },
   addFileToApplication: async ({
      application_id,
      user_id,
      ...file
   }: addFileToApplicationType) => {
      return createRequest(
         mainAxios.post(
            `/user/${user_id}/application/${application_id}/file`,
            file
         )
      );
   },

   /* Suggestion */
   createLessonFromApplication: async ({
      application_id,
      suggestion_id,
   }: createLessonFromApplicationType) => {
      return createRequest(
         mainAxios.post(
            `/application/${application_id}/suggestion/${suggestion_id}/lesson`
         )
      );
   },
   deleteSuggestion: async ({
      application_id,
      suggestion_id,
   }: deleteSuggestionType) => {
      return createRequest(
         mainAxios.delete(
            `/application/${application_id}/suggestion/${suggestion_id}`
         )
      );
   },
   getSuggestions: async ({ user_id, ...query }: getSuggestionsType) => {
      return createRequest(
         mainAxios.get(`/user/${user_id}/suggestion${dataToQueryString(query)}`)
      );
   },
};
