import React from "react";
import { Icons } from "../../../../../assets/iconsCorrect";
import styles from "./index.module.css";

export const selectLanguageItem = ({
   title,
   name,
}: {
   title: string;
   name: string;
}) => {
   return (
      <div className={styles.language_select}>
         {/*@ts-ignore*/}
         {React.createElement(Icons.languageFlags[name], {
            className: styles["language_select__icon"],
         })}
         {title}
      </div>
   );
};
