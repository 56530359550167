// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoadedDialogItem_container__2Owgi {\n    margin: 5px 0;\n}\n\n.LoadedDialogItem_content__16-U- {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    border-bottom: 1px solid #f6f7f8;\n    margin: 0 5px;\n    transition: .4s;\n    width: calc(100%- 15px);\n    height: 50px;\n    animation-duration: 1.8s;\n    animation-fill-mode: forwards;\n    animation-iteration-count: infinite;\n    animation-name: LoadedDialogItem_placeHolderShimmer__3_Vf2;\n    animation-timing-function: linear;\n\n    background: #E6EFFE;\n    background: linear-gradient(to right, #E6EFFE 8%, #E6EFFE 38%, #E6EFFE 54%);\n    background-size: 1000px 640px;\n\n    position: relative;\n}\n\n\n@media screen and (max-width: 1023px) {\n\n}", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/Messages/components/LoadedDialogItem/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,gCAAgC;IAChC,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,YAAY;IACZ,wBAAwB;IACxB,6BAA6B;IAC7B,mCAAmC;IACnC,0DAAkC;IAClC,iCAAiC;;IAEjC,mBAAmB;IACnB,2EAA2E;IAC3E,6BAA6B;;IAE7B,kBAAkB;AACtB;;;AAGA;;AAEA","sourcesContent":[".container {\n    margin: 5px 0;\n}\n\n.content {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    border-bottom: 1px solid #f6f7f8;\n    margin: 0 5px;\n    transition: .4s;\n    width: calc(100%- 15px);\n    height: 50px;\n    animation-duration: 1.8s;\n    animation-fill-mode: forwards;\n    animation-iteration-count: infinite;\n    animation-name: placeHolderShimmer;\n    animation-timing-function: linear;\n\n    background: #E6EFFE;\n    background: linear-gradient(to right, #E6EFFE 8%, #E6EFFE 38%, #E6EFFE 54%);\n    background-size: 1000px 640px;\n\n    position: relative;\n}\n\n\n@media screen and (max-width: 1023px) {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "LoadedDialogItem_container__2Owgi",
	"content": "LoadedDialogItem_content__16-U-",
	"placeHolderShimmer": "LoadedDialogItem_placeHolderShimmer__3_Vf2"
};
export default ___CSS_LOADER_EXPORT___;
