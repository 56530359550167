import React, {
   AllHTMLAttributes,
   Dispatch,
   ReactNode,
   SetStateAction,
   SVGProps,
} from "react";
import styles from "./index.module.css";
import ReactInputMask from "react-input-mask";
import { Control, Controller, Path } from "react-hook-form";
import cn from "classnames";

export type New_InputPropsType<T> = {
   mask?: string;
   support?: SVGProps<SVGElement> | ReactNode[] | ReactNode | any;
   control: Control<T>;
   name: Path<T>;
   placeholder?: string;
   type?: string;
   isLoading?: boolean;
   isLock?: boolean;
   value?: string;
   setIsEmpty?: Dispatch<SetStateAction<boolean>>;
   error?: string;
   // isFilled: boolean;

   modify?: ["search"?];
} & AllHTMLAttributes<HTMLInputElement>;

export function Input_module<T>(props: New_InputPropsType<T>) {
   const { className, ...other } = props;
   return (
      <div
         data-class='Input'
         className={cn({
            [styles.container]: true,
            [[props.modify?.map((item) => styles[String(item)])]
               .join("")
               .split(",")
               .join(" ")]: props.modify,
            [`${props.className}`]: props.className,
         })}
      >
         <Controller
            control={props.control}
            name={props.name}
            render={({ field }) => (
               <ReactInputMask
                  className={styles.input}
                  autoComplete='off'
                  // @ts-ignore
                  maskChar={null}
                  // @ts-ignore
                  // type={isVisible ? "text" : "password"}
                  mask={props.mask || ""}
                  {...other}
                  {...field}
                  placeholder={props.label}
                  // value={props.value && String(field.value)}
                  onChange={(e) => {
                     field.onChange(e);
                     if (props.onChange) {
                        props.onChange(e);
                     }
                  }}
               />
            )}
         />
         {Boolean(props.error) && (
            <div className={styles.error}>{props.error}</div>
         )}
         {Boolean(props.support) && (
            <div className={styles.support}>{props.support}</div>
         )}
      </div>
   );
}
