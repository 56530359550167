import React, { FC } from "react";
import { Route } from "react-router-dom";
import { routes } from "routes";
import { RegisterFastLesson } from "./RegisterFastLesson";
import "./style.css";
import { DefaultLesson } from "./Pages/DefaultLesson";
import { GroupLesson } from "./Pages/GroupLesson";
import { InvitePage } from "./Pages/InvitePage";
import { SearchFastLesson } from "./Pages/SearchFastLesson";
import { ApplicationLesson } from "./Pages/Application";
import { SendRequest } from "./SendRequest";
import { CreateSlotOrLesson } from "./CreateSlotOrLesson";

type propsType = {};
export const LessonsService: FC<propsType> = (props) => {
   return (
      <div className='Lessons LkDefaultPage'>
         {/* Fast */}
         <Route path={routes.lk.lesson.fast.register}>
            <RegisterFastLesson />
         </Route>
         <Route path={routes.lk.lesson.fast.search + "/:id"}>
            <SearchFastLesson />
         </Route>
         <Route path={routes.lk.lesson.application.view + "/:id"}>
            <ApplicationLesson />
         </Route>
         {/* Default */}
         <Route path={routes.lk.lesson.default.view + "/:id"}>
            <DefaultLesson />
         </Route>

         {/* Group */}
         <Route path={routes.lk.lesson.group.view + "/:id"}>
            <GroupLesson />
         </Route>

         {/* Invite */}
         <Route path={routes.lk.lesson.invite.view + "/:id"}>
            <InvitePage />
         </Route>

         {/* SendRequest */}
         <Route path={routes.lk.lesson.sendRequest + "/:slot_id/:id"}>
            <SendRequest />
         </Route>

         {/* CreateSlot */}
         <Route path={routes.lk.lesson.create_slot}>
            <CreateSlotOrLesson />
         </Route>
      </div>
   );
};
