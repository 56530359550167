import React, { FC, useState } from "react";
import styles from "components/UI/Calendar/Bricks/CalendarHeader/style.module.css";
import cn from "classnames";
import { ReactComponent as Arrow } from "assets/icons/Arrow-right.svg";
import { months } from "components/UI/Calendar/helpers";
import { getMonth, getYear } from "date-fns";
import { motion } from "framer-motion";
import { calendarAnimation } from "components/UI/Calendar/animations";
import range from "lodash/range";
import { useDispatch } from "react-redux";
import { scheduleSlice } from "store/scheduleSlice";
import moment from "moment-timezone";

type propsType = {
   date: Date;
   setIsOpen: any;
};

export const PopupCalendar: FC<propsType> = ({ date, setIsOpen }) => {
   const [isVisibleSelectYear, setIsVisibleSelectYear] = useState(false);
   const [isVisibleSelectMonth, setIsVisibleSelectMonth] = useState(true);
   const years = range(1970, getYear(moment().toDate()) + 6, 1);
   const dispatch = useDispatch();
   const toggleIsVisibleSelectYear = () => {
      setIsVisibleSelectYear(true);
      setIsVisibleSelectMonth(false);
   };

   const toggleIsVisibleSelectMonth = () => {
      setIsVisibleSelectMonth(true);
      setIsVisibleSelectYear(false);
   };

   const hideAllPopup = () => {
      setIsVisibleSelectMonth(false);
      setIsVisibleSelectYear(false);
   };

   return (
      <header
         className={cn({
            [styles.header]: true,
            [styles.header_popup]: true,
         })}
      >
         <button
            onClick={(event) => {
               event.preventDefault();
               isVisibleSelectYear || (isVisibleSelectMonth && hideAllPopup());
               setIsOpen(false);
               // : decreaseMonth();
            }}
            style={{
               position: "absolute",
               left: "25px",
            }}
            // disabled={prevMonthButtonDisabled}
         >
            <Arrow
               style={{
                  transform: "rotateZ(180deg)",
                  width: "25px",
                  height: "25px",
               }}
            />
         </button>
         <div
            className={cn({
               [styles.month_year]: true,
            })}
            style={{
               paddingTop: "5px",
               height: "25px",
            }}
         >
            <span
               onClick={toggleIsVisibleSelectMonth}
               className={cn({
                  [styles.month]: true,
               })}
            >
               {moment().month(date.getMonth()).format("MMMM")}
            </span>
            <span onClick={toggleIsVisibleSelectYear} className={styles.year}>
               {getYear(date)}
            </span>
         </div>
         <motion.div
            variants={calendarAnimation}
            initial='close'
            animate={isVisibleSelectYear ? "open" : "close"}
            className={cn({
               [styles.choice]: true,
               // [styles.choice_big]: big,
            })}
         >
            {years.reverse().map((year) => (
               <p
                  className={cn({
                     [styles.current_year]: getYear(date) == year,
                  })}
                  onClick={() => {
                     dispatch(
                        scheduleSlice.actions.setDate(
                           new Date(date.setFullYear(year)).toString()
                        )
                     );
                     toggleIsVisibleSelectYear();
                     setIsOpen(false);
                  }}
               >
                  {year}
               </p>
            ))}
         </motion.div>
         <motion.div
            variants={calendarAnimation}
            initial='close'
            animate={isVisibleSelectMonth ? "open" : "close"}
            className={cn({
               [styles.choice]: true,
               // [styles.choice_big]: big,
            })}
         >
            {months.map((month, index) => (
               <p
                  className={cn({
                     [styles.current_year]: months[getMonth(date)] === month,
                  })}
                  onClick={() => {
                     // changeMonth(months.indexOf(month));
                     setIsOpen(false);
                     dispatch(
                        scheduleSlice.actions.setDate(
                           new Date(date.setMonth(index)).toString()
                        )
                     );
                     toggleIsVisibleSelectMonth();
                  }}
               >
                  {moment().month(index).format("MMMM")}
               </p>
            ))}
         </motion.div>
      </header>
   );
};
