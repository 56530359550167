// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".adminItem_container__1fa0M {\n    width: 100%;\n    height: 60px;\n\n    display: flex;\n    align-items: center;\n    border-radius: 9px;\n    padding: 8px 10px;\n}\n.adminItem_container__1fa0M:hover {\n\n    background: #E6EFFE;\n\n}\n.adminItem_avatar__3HZPe {\n    width: 36px;\n    height: 36px;\n    border-radius: 50%;\n    margin-right: 10px;\n}\n\n\n.adminItem_names__30QJb {\n    font-family: Source Sans Pro;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 20px;\n\n    color: #000000;\n}", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/Messages/components/sendChatToAdmin/adminItem/index.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;;IAEZ,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;AACrB;AACA;;IAEI,mBAAmB;;AAEvB;AACA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;AACtB;;;AAGA;IACI,4BAA4B;IAC5B,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;;IAEjB,cAAc;AAClB","sourcesContent":[".container {\n    width: 100%;\n    height: 60px;\n\n    display: flex;\n    align-items: center;\n    border-radius: 9px;\n    padding: 8px 10px;\n}\n.container:hover {\n\n    background: #E6EFFE;\n\n}\n.avatar {\n    width: 36px;\n    height: 36px;\n    border-radius: 50%;\n    margin-right: 10px;\n}\n\n\n.names {\n    font-family: Source Sans Pro;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 20px;\n\n    color: #000000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "adminItem_container__1fa0M",
	"avatar": "adminItem_avatar__3HZPe",
	"names": "adminItem_names__30QJb"
};
export default ___CSS_LOADER_EXPORT___;
