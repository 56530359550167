import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import { Icons } from "../../../assets/iconsCorrect";
import { useBlurred } from "../../../hooks/useBlurred";

type propsType = {
   title?: string;
   buttons?: ReactNode;
   onBackArrow?: () => void;
   isOpen: boolean;
   onClose?: () => void;
   setIsOpen: (val: boolean) => void;
};

export const Popup: FC<propsType> = ({
   title,
   onBackArrow,
   buttons,
   isOpen,
   setIsOpen,
   onClose,
   children,
}) => {
   const ref = useRef<HTMLDivElement | null>(null);
   useBlurred(ref, setIsOpen, isOpen);

   useEffect(() => {
      if (!isOpen) {
         onClose && onClose();
      }
   }, [isOpen]);

   if (!isOpen) return null;
   return (
      <div className={styles.container}>
         <div ref={ref} className={styles.popup}>
            <Icons.ui.closeIcon
               onClick={() => setIsOpen(false)}
               className={styles.close_icon}
            />
            {onBackArrow && (
               <Icons.buttons.DownArrowSelect
                  onClick={onBackArrow}
                  className={styles.back_icon}
               />
            )}
            {title && <h4 className={styles.title}>{title}</h4>}
            <div className={styles.content}>
               <main className={styles.main}>{children}</main>
               {buttons && <div className={styles.buttons}>{buttons}</div>}
            </div>
         </div>
      </div>
   );
};
