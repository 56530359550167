import React, { FC } from "react";
import styles from "./index.module.css";
import { Icons } from "assets/iconsCorrect";
import cn from "classnames";
import moment from "moment/moment";
import { adminApi } from "../../../../../../../../api/adminApi";
import { downloadFile } from "../../../../../../../../helpers/file";
import { Trans } from "react-i18next";

export type NoteHistoryTableItemType = {
   item: {
      id: number;
      date: Date;
   };
};

export const NoteTableItem: FC<NoteHistoryTableItemType> = ({ item }) => {
   const handleDownload = (id: number) => {
      adminApi.downloadPaymentRegistry({ registry_id: id })
         .then(res => downloadFile(res.data.data.content));
   };

   if (!item) return null;

   return (
      <>
         <tr className={styles.preview}>
            <td className={styles.table_item}>{item.id}</td>
            <td className={cn(styles.table_item, styles.item_bold)}>
               {moment(item.date).format("DD.MM.YYYY")}
            </td>
            <td className={styles.button}>
               <button onClick={() => handleDownload(item.id)}>
                  <Trans i18nKey={"FinancialOperations.text.download-file"}>
                     Скачать файл
                  </Trans>
               </button>
            </td>
         </tr>
      </>
   );
};
