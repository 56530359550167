import React, {FC} from "react";
import styles from "./style.module.css";
import {NotificationCardHeader} from "../../components/Header";
import {NotificationCardUser} from "../../components/User";
import {notificationCardIcons} from "../../assets";
import cn from "classnames";
import {useTranslation} from "react-i18next";
import {notificationPropsType} from "../../types";
import {NotificationCardWrapper} from "../../components/NotificationCardWrapper";
import {NotificationCardSuggestInfo} from "components/Lk/components/Notifications/components/Duration";
import {NotificationCardButtons_Suggest} from "components/Lk/components/Notifications/components/Buttons_Suggest";

type propsType = { notification: notificationPropsType; isPush?: boolean };
export const NotificationSuggestionCard: FC<propsType> = (props) => {
   const { notification, isPush = false } = props;

   const { t } = useTranslation();

   const iconResolver = (): any => {
      switch (notification.entity.status) {
         case "new_suggestion":
            return notificationCardIcons.question;
         case "approved_suggestion":
            return notificationCardIcons.approve;
         case "rejected_suggestion":
            return notificationCardIcons.cross;
      }
   };

   const titleResolver = (): any => {
      switch (notification.entity.status) {
         case "new_suggestion":
            return t(
               "Lk.Notifications.SuggestionCard.new-suggestion",
               "Запрос на Быстрое занятие"
            );
         case "approved_suggestion":
            return t(
               "Lk.Notifications.SuggestionCard.approved-suggestion",
               "Быстрое занятие принято"
            );
         case "rejected_suggestion":
            return t(
               "Lk.Notifications.SuggestionCard.rejected-suggestion",
               "Быстрое занятие отклонено"
            );
      }
   };

   if (!notification) return null;
   return (
      <NotificationCardWrapper
         className={cn(styles.container, {
            [styles[`${notification.entity.status}`]]: true,
         })}
      >
         <NotificationCardHeader
            date={notification.date}
            icon={iconResolver()}
            title={titleResolver()}
         />
         <NotificationCardUser user={notification.related_entity} />
         <NotificationCardSuggestInfo
             notification={notification}
         />
         {notification.entity.status === "new_suggestion" && <NotificationCardButtons_Suggest
             entity_id={Number(notification.entity?.id)}
             isPush={isPush}
             notification={notification}
         />}
      </NotificationCardWrapper>
   );
};
