import React, { FC } from "react";
import styles from "components/Lk/Pages/SchedulePage/Events/EventDay/index.module.css";
import { useSelector } from "react-redux";
import { scheduleSliceSelectors } from "store/scheduleSlice";
import { EventTimeLine } from "components/UI/EventTimeLine";
import { lkSliceSelectors } from "store/lkSlice";
import { ticketTypeResolver } from "helpers/date/ticketsTypeResolver";
import { firstLetterUpperCase } from "helpers/string/firstLetterUppercase";
import moment from "moment-timezone";
import { StateType } from "store";
import { getDayOfWeek } from "helpers/schedule";

type propsType = {
   day: number;
   eventData?: {
      hours: Array<{
         hour: string;
         tickets: any[];
      }>;
   };
};

export const EventDay: FC<propsType> = (props) => {
   const calendarData = useSelector(scheduleSliceSelectors.getDate);
   const dayOfWeek = getDayOfWeek(calendarData, props.day);
   const { id } = useSelector(lkSliceSelectors.getUserData);
   const filters = useSelector(
      (state: StateType) => state.scheduleSlice.filters
   );

   return (
      <div className={styles.container}>
         <div className={styles.header}>
            <p>
               {props.day}
               {/*{props.month && months[props.month.getMonth()]}*/}
            </p>
            {firstLetterUpperCase(
               moment().month(calendarData.getMonth()).format("MMMM")
            )}
         </div>
         <div className={styles.events_container}>
            {props.eventData &&
               props.eventData.hours.map((item) => {
                  return (
                     <>
                        {ticketTypeResolver(item.tickets, id, filters)[0] && (
                           <EventTimeLine hour={item.hour} />
                        )}
                        <div className={styles.hours_events}>
                           {ticketTypeResolver(item.tickets, id, filters)}
                        </div>
                     </>
                  );
               })}
         </div>
      </div>
   );
};
