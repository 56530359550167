import React, { FC, useEffect } from "react";
import { SelectUnderControl } from "../../../UI/Select/SelectUnderControl";
import { ReactComponent as FrenchIcon } from "../../Main/SearchFilters/assets/Franch.svg";
import { ReactComponent as SpainIcon } from "../../Main/SearchFilters/assets/Spain.svg";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as RussianIcon } from "./assets/Russian.svg";
import { ReactComponent as EnglishIcon } from "./assets/English.svg";
import cn from "classnames";
import {
   fastLessonSlice,
   fastLessonSliceSelectors,
} from "store/fastLessonSlice";
import { useQuery } from "hooks/useQuery";
import { formsDataApi } from "api/formsDataApi/methods";
import { educationSystemType, languageType } from "store/formsDataSlice/types";
import { languagesTitleResolver } from "staticData/languages";
import { languageSelectSerializer } from "serializers/languages";
import { useTranslation } from "react-i18next";
import { selectLanguageItem } from "../../../UI/Select/SelectItems/SelectLanguageItem";
import styles from "./index.module.css";
import { Icons } from "assets/iconsCorrect";
import { useHistory } from "react-router-dom";
import { Preloader } from "components/UI/Preloader";
import { uiSlice } from "store/modalWindowSlice";
import { lkSliceSelectors } from "store/lkSlice";

type propsType = {};
type formType = {
   language: any;
   language_input: string;
};
export const EducationSystemPicker: FC<propsType> = (props) => {
   const { t, i18n } = useTranslation();
   const { data: filtersState } = useSelector(
      fastLessonSliceSelectors.getFiltersState
   );
   const { language, level } = filtersState;
   const { control, watch, setValue } = useForm<formType>();
   const selectedLanguage = useWatch({ control, name: "language" });
   const dispatch = useDispatch();
   const lang = useSelector(lkSliceSelectors.getLang);

   /* fetch */
   const { data: es, isLoading: esLoading } = useQuery(
      () =>
         formsDataApi.getEducationSystem({
            level_id: String(level?.id),
         }),
      {
         condition: !!level?.id,
         serializer: (res) =>
            res?.data.data.items as Array<educationSystemType>,
      }
   );
   const { data: languages } = useQuery(formsDataApi.getLanguage, {
      cachedValueName: "languages",
      serializer: languageSelectSerializer,
      reserializeDeps: [lang],
   });

   /* update stage */
   useEffect(() => {
      if (es && level?.name == "Elementary School") {
         dispatch(
            fastLessonSlice.actions.setFiltersData({
               educationSystem: es[0],
            })
         );
         dispatch(fastLessonSlice.actions.setFiltersStage(1));
      }
   }, [es]);
   /* update filters */
   useEffect(() => {
      if (selectedLanguage) {
         dispatch(
            fastLessonSlice.actions.setFiltersData({
               language: selectedLanguage.value,
            })
         );
      }
   }, [selectedLanguage]);

   /* init */
   useEffect(() => {
      if (language?.name) {
         setValue("language", {
            text: language?.name,
            value: {
               ...language,
               title: languagesTitleResolver[language?.name || "Russian"],
            },
         });
         setValue("language_input", languagesTitleResolver[language?.name]);
      }
   }, []);

   const isEducationSystemAllowed = (item: educationSystemType) => {
      return item.languages.find((item) => language?.name == item.name);
   };

   return (
      <div className={styles.container}>
         <div className={styles.language_title}>
            {t("SearchFilters.grade.choose-language", "Выберите язык обучения")}
         </div>
         <SelectUnderControl
            watch={watch}
            name='language'
            setValue={setValue}
            label={t("SearchFilters.grade.language", "Язык обучения")}
            options={languages}
            variant='blue-bg'
            bgColor={"var(--color-black4)"}
            component={selectLanguageItem}
            control={control}
            className={styles.select_language}
         />
         <div className={styles.system_label}>
            {t(
               "SearchFilters.grade.education-system",
               "Выберите вашу систему образования"
            )}
         </div>
         <div className={styles.systems_container}>
            {es ? (
               es.map((item: educationSystemType) => (
                  <div
                     className={cn({
                        [styles.slot_container]: true,
                        [styles.slot_container_disabled]:
                           filtersState.language?.name &&
                           !isEducationSystemAllowed(item),
                     })}
                     onClick={() => {
                        dispatch(
                           fastLessonSlice.actions.setFiltersData({
                              educationSystem: item,
                           })
                        );
                        dispatch(fastLessonSlice.actions.setFiltersStage(1));
                     }}
                  >
                     <div className={styles.language_icon}>
                        {item.languages.slice(0, 8).map((item) => {
                           return React.createElement(
                              Icons.languageFlags[item.name],
                              {
                                 className: styles.es_flag,
                              }
                           );
                        })}
                     </div>
                     <div className={styles.slot_title}>{item.name}</div>
                  </div>
               ))
            ) : (
               <Preloader variant={"centred"} loading={esLoading} />
            )}
         </div>
      </div>
   );
};
