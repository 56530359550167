import React, { FC } from "react";
import styles from "./style.module.css";
import { routes } from "routes";
import { NavLink, Route } from "react-router-dom";
import { ProfileSettings } from "./ProfileSettings";
import { useTranslation } from "react-i18next";
import { ServiceSettings } from "./ServiceSettings";
import { Worksheet } from "./Worksheet";

export const Settings: FC = () => {
   const { t } = useTranslation();

   return (
      <div className={styles.container + " LkDefaultPage"}>
         <div className={styles.switcher}>
            <NavLink
               to={routes.lk.settings.profile}
               activeClassName={styles.button_active}
               className={styles.link}
            >
               <button className={styles.button}>
                  {t("Settings.titles.account", "Аккаунт")}
               </button>
            </NavLink>
            <NavLink
               to={routes.lk.settings.service}
               activeClassName={styles.button_active}
               className={styles.link}
            >
               <button className={styles.button}>
                  {t("Settings.titles.service.title", "Сервис")}
               </button>
            </NavLink>
            <NavLink
               to={routes.lk.settings.worksheet}
               activeClassName={styles.button_active}
               className={styles.link}
            >
               <button className={styles.button}>
                  {t("Settings.titles.form", "Анкета")}
               </button>
            </NavLink>
         </div>
         <Route path={routes.lk.settings.profile}>
            <ProfileSettings />
         </Route>
         <Route path={routes.lk.settings.service}>
            <ServiceSettings />
         </Route>
         <Route path={routes.lk.settings.worksheet}>
            <Worksheet />
         </Route>
      </div>
   );
};
