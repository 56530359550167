// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FileDownloader_container__3V3zD {\n    display: flex;\n    align-items: center;\n\n}\n.FileDownloader_file__1-UKr {\n\n}\n\n.FileDownloader_text__29_1Q {\n    font-family: Source Sans Pro;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    line-height: 20px;\n    margin-left: 8px;\n    margin-right: 20px;\n    max-width: 150px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n\n.FileDownloader_link__3mO5s {\ndisplay: flex;\n    align-items: center;\n    cursor: pointer;\n}\n\n.FileDownloader_loading__1B1u3 {\n    width: 16px;\n    height: 16px;\n}", "",{"version":3,"sources":["webpack://src/components/UI/FileDownloader/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;;AAEvB;AACA;;AAEA;;AAEA;IACI,4BAA4B;IAC5B,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;AACA,aAAa;IACT,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n\n}\n.file {\n\n}\n\n.text {\n    font-family: Source Sans Pro;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    line-height: 20px;\n    margin-left: 8px;\n    margin-right: 20px;\n    max-width: 150px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n\n.link {\ndisplay: flex;\n    align-items: center;\n    cursor: pointer;\n}\n\n.loading {\n    width: 16px;\n    height: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FileDownloader_container__3V3zD",
	"file": "FileDownloader_file__1-UKr",
	"text": "FileDownloader_text__29_1Q",
	"link": "FileDownloader_link__3mO5s",
	"loading": "FileDownloader_loading__1B1u3"
};
export default ___CSS_LOADER_EXPORT___;
