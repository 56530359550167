import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { scheduleSlice } from "../../../store/scheduleSlice";
import { Icons } from "../../../assets/iconsCorrect";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../../store";
import { useTranslation } from "react-i18next";

type propsType = {
   setValue: any;
};
export const ScheduleFiltersUi: FC<propsType> = ({ setValue }) => {
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const isFiltersOpened = useSelector(
      (state: StateType) => state.scheduleSlice.isFiltersOpened
   );
   const filters = useSelector(
      (state: StateType) => state.scheduleSlice.filters
   );
   return (
      <div className={styles.container}>
         <button
            className={styles.filter_button}
            onClick={() => {
               dispatch(
                  scheduleSlice.actions.setIsFiltersOpened(!isFiltersOpened)
               );
            }}
         >
            <Icons.ui.FilterIcon className={styles.button_icon} />
            {t("Filters.title", "Фильтры")}
         </button>
         <div className={styles.filters_container}>
            {filters.student_proved && (
               <button
                  className={styles.picked_filter + " " + styles.student_proved}
               >
                  <p>
                     {t(
                        "Lk.ScheduleFilter.filter.student-proved",
                        "Подтвержденные занятия"
                     )}
                  </p>
                  <Icons.inputs.Attach
                     onClick={() => setValue("student_proved", false)}
                  />
               </button>
            )}
            {filters.student_unproved && (
               <button
                  className={
                     styles.picked_filter + " " + styles.student_unproved
                  }
               >
                  <p>
                     {t(
                        "Lk.ScheduleFilter.filter.student-unproved",
                        "Неподтвержденные занятия"
                     )}
                  </p>
                  <Icons.inputs.Attach
                     onClick={() => setValue("student_unproved", false)}
                  />
               </button>
            )}
            {filters.student_open && (
               <button
                  className={styles.picked_filter + " " + styles.student_open}
               >
                  <p>
                     {t(
                        "Lk.ScheduleFilter.filter.student-open-lesson",
                        "Открытый урок"
                     )}
                  </p>
                  <Icons.inputs.Attach
                     onClick={() => setValue("student_open", false)}
                  />
               </button>
            )}
            {filters.slot && (
               <button className={styles.picked_filter + " " + styles.slot}>
                  <p>
                     {t(
                        "Lk.ScheduleFilter.filter.teacher-time",
                        "Время для занятий"
                     )}
                  </p>
                  <Icons.inputs.Attach
                     onClick={() => setValue("slot", false)}
                  />
               </button>
            )}
            {filters.teacher_unproved && (
               <button
                  className={
                     styles.picked_filter + " " + styles.teacher_unproved
                  }
               >
                  <p>
                     {t(
                        "Lk.ScheduleFilter.filter.teacher-unproved",
                        "Неподтвержденные занятия"
                     )}
                  </p>
                  <Icons.inputs.Attach
                     onClick={() => setValue("teacher_unproved", false)}
                  />
               </button>
            )}
            {filters.teacher_proved && (
               <button
                  className={styles.picked_filter + " " + styles.teacher_proved}
               >
                  <p>
                     {t(
                        "Lk.ScheduleFilter.filter.teacher-proved",
                        "Подтвержденные занятия"
                     )}
                  </p>
                  <Icons.inputs.Attach
                     onClick={() => setValue("teacher_proved", false)}
                  />
               </button>
            )}
            {filters.teacher_open && (
               <button
                  className={styles.picked_filter + " " + styles.teacher_open}
               >
                  <p>
                     {t(
                        "Lk.ScheduleFilter.filter.student-open-lesson",
                        "Открытый урок"
                     )}
                  </p>
                  <Icons.inputs.Attach
                     onClick={() => setValue("teacher_open", false)}
                  />
               </button>
            )}
         </div>
      </div>
   );
};
