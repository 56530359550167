import React, { useState } from "react";
import styles from "../TeacherActivation/index.module.css";
import { PageTitle } from "components/UI/PageTitle";
import { routes } from "routes";
import { Paginate } from "../../components/Paginate";
import { Button } from "components/UI/Button";
import { useTranslation } from "react-i18next";
import { useQuery } from "hooks/useQuery";
import { userApi } from "api/userApi/methods";
import { AdminUsersTable } from "components/Lk/Pages/AdminService/Pages/Users/components/Table/AdminUsersTable";
import { useForm } from "react-hook-form";
import { serverArrayType } from "types/types";
import { usePaginator } from "hooks/usePagination";
import { AdminTableFilters } from "components/Lk/Pages/AdminService/Pages/Users/components/Filters";

export const AdminUsersPanel = () => {
   const { t } = useTranslation();
   const [needUpdate, setNeedUpdate] = useState<boolean>(true);
   const { setValue, control, getValues } = useForm();
   const [filters, setFilters] = useState<any>({});
   const [itemsCount, setItemsCount] = useState(0);
   const paginator = usePaginator({
      perPage: 10,
      length: itemsCount,
   });

   function setNeedUpdateState() {
      setNeedUpdate(true);
   }
   const { data: usersData, isLoading, fetch: updateTable } = useQuery(
      () =>
         userApi.getUsers({
            limit: "10",
            offset: String(paginator.offset),
            type: filters?.type?.value,
            user_role: filters.role?.value,
            user_name: filters?.name,
         }),
      {
         deps: [paginator.offset, needUpdate, filters],
         serializer: (res) => {
            setItemsCount(res?.data.data.items_count);
            return res?.data.data as serverArrayType<Array<any>>;
         },
      }
   );

   const submitFilters = () => {
      paginator.setCurrent(0);
      setFilters(getValues());
   };

   return (
      <div className={styles.container}>
         <PageTitle
            urlToBack={routes.lk.admin.root}
            title={t("Lk.AdminPanel.cards.users", "Пользователи")}
            support={usersData?.items_count}
         />
         <AdminTableFilters
            control={control}
            setValue={setValue}
            submitFilters={submitFilters}
         />
         {window.innerWidth > 719 ? (
            <>
               {!isLoading && usersData ? (
                  <AdminUsersTable
                     updateState={setNeedUpdateState}
                     data={usersData.items}
                     updateTable={updateTable}
                  />
               ) : (
                  <div className={styles.loadingBlock}>
                     {Array(8)
                        .fill("")
                        .map((_) => (
                           <div className={styles.loadingItem} />
                        ))}
                  </div>
               )}
            </>
         ) : null}

         {window.innerWidth <= 719 ? (
            <>
               {usersData && (
                  <AdminUsersTable
                     updateTable={updateTable}
                     updateState={setNeedUpdateState}
                     data={usersData.items}
                  />
               )}
               {/*{usersData && (*/}
               {/*   <Button className={styles.show_more} variant={"primary"}>*/}
               {/*      {t(*/}
               {/*         "Lk.AdminPanel.TeacherActivation.show-more",*/}
               {/*         "Показать еще"*/}
               {/*      )}*/}
               {/*   </Button>*/}
               {/*)}*/}
            </>
         ) : null}
         {usersData?.items_count > 10 && (
            <div className={styles.paginate}>
               <Paginate
                  pageCount={Math.ceil(usersData.items_count / 10)}
                  onPageActive={() => null}
                  onPageChange={paginator.onPageChange}
               />
            </div>
         )}
      </div>
   );
};
