import React, { FC } from "react";
import styles from "components/Lk/Pages/SchedulePage/Schedule/ScheduleCalendar/index.module.css";
import { ScheduleDaysOfWeek } from "components/Lk/Pages/SchedulePage/Schedule/ScheduleCalendar/ScheduleDaysOfWeek";
import { ScheduleDays } from "components/Lk/Pages/SchedulePage/Schedule/ScheduleCalendar/ScheduleDays";

type propsType = {
   type: string;
};
export const ScheduleCalendar: FC<propsType> = (props) => {
   return (
      <div className={styles.container}>
         <ScheduleDaysOfWeek type={props.type} />
         <ScheduleDays type={props.type} />
      </div>
   );
};
