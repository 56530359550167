import React, { FC } from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import cn from "classnames";
import { routes } from "../../../../routes";
import { lkSliceSelectors } from "../../../../store/lkSlice";
import { ReactComponent as ChangesIcon } from "./assets/Changes.svg";
import { ReactComponent as GearIcon } from "./assets/Gear.svg";
import { ReactComponent as LstIcon } from "./assets/List.svg";
import { ReactComponent as PaymentIcon } from "./assets/Payment.svg";

export const Payment: FC = () => {
   const { t } = useTranslation();
   const user = useSelector(lkSliceSelectors.getUserData);

  return (
      <div className={cn(styles.container, "LkPaymentPage")}>
         <div className={styles.title}>
            {t("Payment.title", "Оплата занятий")}
         </div>
         <div className={styles.cards}>
            <Link to={routes.lk.payment.transactions}>
               <div className={cn(styles.card__item, styles.card_blue)}>
                  <LstIcon className={styles.icon}/>
                  <div className={cn(styles.text, styles.text_white)}>
                     <Trans i18nKey={"Payment.titles.operations-list"}>
                        Список <br /> операций
                     </Trans>
                  </div>
               </div>
            </Link>
            {(user.type === "student" || user.type === "inactive_teacher") && (
               <Link to={routes.lk.payment.addCardForPayment}>
                  <div className={cn(styles.card__item, styles.card_purple)}>
                     <PaymentIcon className={styles.icon}/>
                     <div className={styles.text}>
                        <Trans i18nKey={"Payment.titles.cards-for-payment"}>
                           Карты <br /> для оплаты
                        </Trans>
                     </div>
                  </div>
               </Link>
            )}
            {user.type === "teacher" && (
               <>
                  <Link to={routes.lk.payment.addCardForPayout}>
                     <div className={cn(styles.card__item, styles.card_purple)}>
                        <GearIcon className={styles.icon}/>
                        <div className={styles.text}>
                           <Trans i18nKey={"Payment.titles.bank-account-details"}>
                              Реквизиты счета
                           </Trans>
                        </div>
                     </div>
                  </Link>
                  <Link to={routes.lk.payment.setOrChangeRate}>
                     <div className={cn(styles.card__item, styles.card_blue_light)}>
                        <ChangesIcon className={styles.icon}/>
                        <div className={styles.text}>
                           <Trans i18nKey={"Payment.titles.set-or-change-rate"}>
                              Установка <br /> или смена тарифа
                           </Trans>
                        </div>
                     </div>
                  </Link>
                  <Link to={routes.lk.payment.addCardForPayment}>
                     <div className={cn(styles.card__item, styles.card_blue_dark)}>
                        <PaymentIcon className={styles.icon}/>
                        <div className={cn(styles.text, styles.text_white)}>
                           <Trans i18nKey={"Payment.titles.add-cards-for-payment"}>
                              Привязка <br /> карт оплаты
                           </Trans>
                        </div>
                     </div>
                  </Link>
               </>
            )}
         </div>
      </div>
   );
};
