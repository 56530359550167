import { getAllCards } from "api/adminApi/types";
import { dataToQueryString } from "api/methods/dataToQueryString";
import { createRequest, createRequestTyped } from "../../core/LTools/core/apiMethods/createRequest";
import { store } from "../../store";
import { cardType, getSlotsType } from "../../store/lkSlice/types";
import { mainAxios } from "../index";
import { userIdType } from "../types";
import { requisitesType, saveRequisitesType } from "./types";

/* @TODO DEPRECATED */

class LkApiClass {
   user_id: string | null = null;

   constructor() {}

   getUser = async () => {
      return createRequest(mainAxios.get(`/user/${this.user_id}`));
   };
   getPrice = async () => {
      return createRequest(mainAxios.get(`/user/${this.user_id}/price`));
   };
   getPriceByUserId = async ({user_id}: userIdType) => {
      return createRequest(mainAxios.get(`/user/${user_id}/price`));
   };
   getCard = async (query: getAllCards) => {
      return createRequest(mainAxios.get(`/user/${this.user_id}/card${dataToQueryString(query)}`));
   };
   getCardByUserID = async ({user_id , ...query}: any) => {
      return createRequest(mainAxios.get(`/user/${user_id}/card${dataToQueryString(query)}`));
   };
   getCardByCardIDAdmin = async (card_id: number) => {
      return createRequest(mainAxios.get(`/card/${card_id}`));
   };
   getCardByCardID = async (card_id: number) => {
      return createRequest(mainAxios.get(`/user/${this.user_id}/card/${card_id}`));
   };
   createNewCard = async (type: cardType) => {
      return createRequest(mainAxios.post(`/user/${this.user_id}/card`, {type}));
   };
   getPaymentByPaymentId = async (payment_id: string) => {
      return createRequest(mainAxios.get(`/payment/${payment_id}`));
   };
   getInvitePayment = async ({invite_id, payment_id} : any) => {
      return createRequest(mainAxios.get(`/invite/${invite_id}/payment/${payment_id}`));
   };
   addInvitePayment = async ({invite_id, bank_card_id} : any) => {
      return createRequest(mainAxios.post(`/invite/${invite_id}/payment`, {bank_card_id}));
   };
   addInviteNoPayment = async ({ invite_id } : any) => {
      return createRequest(mainAxios.post(`/invite/${invite_id}/nopayment`));
   };
   deleteCard = async (card_id: number | string) => {
      return createRequest(mainAxios.delete(`/user/${this.user_id}/card/${card_id}`));
   };

   deletePayment = async ({payment_id}: any) => {
      return createRequest(mainAxios.delete(`/payment/${payment_id}`));
   };

   updatePrice = async ({id, price, currency}: any) => {
      return createRequest(mainAxios.put(`/user/${this.user_id}/price/${id}`, {price, currency}));
   };
   getSlots = async (props: getSlotsType) => {
      return createRequest(
         mainAxios.get(
            `/user/${this.user_id}/slot?limit=${props.limit}&offset=${props.offset}`
         )
      );
   };
   getRequisites = async () => {
      return createRequestTyped<{ data: requisitesType }>(
         mainAxios.get(`/user/${this.user_id}/payout-requisites`)
      );
   };
   saveRequisites = async ({ ...payload }: saveRequisitesType) => {
      return createRequest(mainAxios.post(`/user/${this.user_id}/payout-requisites`, payload))
   };
}

// create instance
export const lkApi = new LkApiClass();

// subscribe to store to change id
const changeId = () => {
   let prevId = lkApi.user_id;
   let newId = store.getState().authReducer.user_id;

   if (prevId !== String(newId)) {
      lkApi.user_id = String(newId);
   }
};
const unsubscribe = store.subscribe(changeId);
