import React, { FC } from "react";
import styles from "./index.module.css";
import { ReactComponent as RatingIcon } from "./assets/Rating.svg";
import { ReactComponent as OnlineIcon } from "./assets/Online.svg";
import { useTranslation } from "react-i18next";
import { teacherType } from "types/userType";
import { useGetUserAvatar } from "hooks/useGetUserAvatar";
import { useSelector } from "react-redux";
import { authSliceSelectors } from "store/authSlice";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { routes } from "routes";
import { fastLessonSlice } from "store/fastLessonSlice";
import cn from "classnames";
import { useGoToProfile } from "helpers/util/goToProfile";

type propsType = {
   userData: teacherType;
   variant?: "landing";
};
export const TeacherCard: FC<propsType> = ({ userData, variant }) => {
   const { t } = useTranslation();
   const token = useSelector(authSliceSelectors.getToken);
   const [avatar] = useGetUserAvatar(userData.id);
   const commonState = useLocalCommonState();

   const action = () => {
      const teachersUrl = routes.lk.profile + `/${userData.id}`;
      if (token) {
         commonState.location = teachersUrl;
      } else {
         commonState.dispatch(
            fastLessonSlice.actions.setUrlOfTeacher(teachersUrl)
         );
         commonState.location = routes.registration.auth.firstStage;
      }
   };

   return (
      <div
         className={cn({
            [styles.mobile_container]: true,
            [styles.mobile_container_landing]: variant == "landing",
         })}
      >
         <div onClick={action} className={styles.container}>
            <div className={styles.top_container}>
               <div className={styles.left}>
                  <div className={styles.avatar_container}>
                     <img className={styles.avatar} src={avatar} />
                     {userData.online && (
                        <OnlineIcon className={styles.online_icon} />
                     )}
                  </div>
                  <div className={styles.name}>
                     <p>{userData.first_name}</p>
                     <p className={styles.last_name}>{userData.last_name}</p>
                  </div>
               </div>
               <div className={styles.right}>
                  <div className={styles.rating_container}>
                     <RatingIcon className={styles.rating_icon} />
                     <p>
                        {userData.teaching_rating
                           ? Number(userData.teaching_rating).toFixed(2)
                           : "—"}
                     </p>
                  </div>
               </div>
            </div>
            <button onClick={action} className={styles.link}>
               {t("Landings.teacher-sign-up", "Записаться на занятие")}
            </button>
         </div>
         <button onClick={action} className={styles.mobile_link}>
            {t("Landings.teacher-card-sign-up-mobile", "Записаться")}
         </button>
      </div>
   );
};
