import React, { FC, useRef } from "react";
import styles from "components/Lk/Pages/SchedulePage/ScheduleFilter/index.module.css";
import { CheckBox } from "components/UI/Input/CheckBox";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { useMedia } from "hooks/useMedia";
import { Icons } from "assets/iconsCorrect";
import { useBlurred } from "hooks/useBlurred";
import { useDispatch, useSelector } from "react-redux";
import { scheduleSlice } from "store/scheduleSlice";
import { StateType } from "store";
import { ScheduleFilterFormType } from "components/Lk/Pages/SchedulePage/index";
import { filterCheckboxesData } from "components/Lk/Pages/SchedulePage/ScheduleFilter/filterCheckboxData";

type propsType = {
   control: Control<ScheduleFilterFormType, object>;
};
export const ScheduleFilter: FC<propsType> = ({ control }) => {
   const { t } = useTranslation();
   const isFiltersOpen = useSelector(
      (state: StateType) => state.scheduleSlice.isFiltersOpened
   );
   const { width } = useMedia();
   const ref = useRef<any>(null);
   const dispatch = useDispatch();
   useBlurred(
      ref,
      (value) => {
         dispatch(scheduleSlice.actions.setIsFiltersOpened(false));
      },
      isFiltersOpen
   );

   return (
      <div
         className={cn({
            [styles.shadow]: width < 1920 && isFiltersOpen,
         })}
      >
         {(isFiltersOpen || width >= 1920) && (
            <form
               className={styles.container}
               ref={ref}
               onSubmit={(event) => {
                  event.preventDefault();
               }}
            >
               <button
                  className={styles.close_button}
                  onClick={(event) => {
                     event.preventDefault();
                     dispatch(scheduleSlice.actions.setIsFiltersOpened(false));
                  }}
               >
                  <Icons.ui.cancelIcon />
               </button>
               <div className={styles.title}>
                  <p>{t("Lk.ScheduleFilter.title", "Фильтры")}</p>
               </div>
               <div className={styles.filters_container}>
                  <div className={styles.filters_title}>
                     <p>
                        {t("Lk.ScheduleFilter.filter.title-student", "Студент")}
                     </p>
                  </div>
                  {filterCheckboxesData.student.map((checkBoxData) => {
                     return (
                        <CheckBox
                           control={control}
                           name={checkBoxData.name as any}
                           className={
                              styles.checkbox + " " + styles[checkBoxData.name]
                           }
                           label={checkBoxData.label}
                        />
                     );
                  })}
               </div>
               <div className={styles.teacher_container}>
                  <div className={styles.filters_title}>
                     <p>
                        {t(
                           "Lk.ScheduleFilter.filter.title-teacher",
                           "Преподаватель"
                        )}
                     </p>
                  </div>
                  {filterCheckboxesData.teacher.map((checkBoxData) => {
                     return (
                        <CheckBox
                           control={control}
                           name={checkBoxData.name as any}
                           className={
                              styles.checkbox + " " + styles[checkBoxData.name]
                           }
                           label={checkBoxData.label}
                        />
                     );
                  })}
               </div>
            </form>
         )}
      </div>
   );
};
