import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import { MenuButton } from "../../../UI/MenuButton";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactComponent as MenuLkIcon } from "../../../../assets/buttons/MenuLkIcon.svg";
import { routes } from "routes";
import { useTranslation } from "react-i18next";
import { lkSliceSelectors } from "store/lkSlice";
import { MobileMenu } from "components/Lk/components/MobileMenu";
import cn from "classnames";
import { useBlurred } from "hooks/useBlurred";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { Icons } from "assets/iconsCorrect";
import { useMedia } from "hooks/useMedia";
import { LangSwitcherSmall } from "components/UI/LangSwitcher/LangSwitcherSmall";
import { OnlineStatusSwitcher } from "components/UI/OnlineStatusSwitcher";
import {
   navigationLinkDataType,
   navigationLinksData,
} from "components/Lk/components/Menu/navigationLinksData";
import { logout } from "store/helpers";
import { useGoToProfile } from "helpers/util/goToProfile";
import { useQuery } from "../../../../hooks/useQuery";
import { chatApi } from "../../../../api/chatApi/methods";
import { ReactComponent as MessagesIcon } from "../../../../assets/buttons/MenuMessagesIcon.svg";
import { authSliceSelectors } from "../../../../store/authSlice";
import { lc } from "../../../../store/LC";

export const Menu: FC = () => {
   const [isMenuOpen, setIsMenuOpen] = useState(false);
   const { t, i18n } = useTranslation();
   const user = useSelector(lkSliceSelectors.getUserData);
   const ref = useRef(null);
   const commonState = useLocalCommonState();
   const { width } = useMedia();
   const [menuData, setMenuData] = useState<Array<navigationLinkDataType>>([]);

  const goToProfile = useGoToProfile(user.id);

   const logoutHandler = () => {
      logout(commonState.dispatch);
      commonState.location = routes.registration.auth.firstStage;
   };

   useBlurred(ref, setIsMenuOpen, isMenuOpen);

   const userType =
      user.type == "teacher" && !user.is_blocked ? "teacher" : "student";

   // Effect for filtering navigation data
   useEffect(() => {
      if (user?.type) {
         setMenuData(
            navigationLinksData.filter((item) => {
               if (item.allowedUserRoles) {
                  if (!item.allowedUserRoles?.includes(user.role)) {
                     return false;
                  }
               }
               if (item.allowedUserType) {
                  if (!item.allowedUserType?.includes(userType)) {
                     return false;
                  }
               }

               return true;
            })
         );
      }
   }, [user, i18n.language]);

   async function getChatVisibleStatus() {
      const token = lc.get("token");

      if (token) {
         chatApi.getCheckInvisibleMessages().then((res) => {
            setHasInvisibleMessages(res?.data?.data?.result);
            setTimeout(() => {
               getChatVisibleStatus();
            }, 10000);
         });
      }
   }
   useEffect(() => {
      getChatVisibleStatus();
   }, []);

   const [hasInvisibleMessages, setHasInvisibleMessages] = useState<boolean>(
      false
   );

   return (
      <>
         <div
            className={cn({
               [styles.container]: true,
               [styles.container__opened]: isMenuOpen,
            })}
            ref={ref}
         >
            <div className={styles.nav}>
               <a onClick={goToProfile}>
                  <MenuButton
                     text={t("Menu.teacherButtons.profile", "Профиль")}
                     support={MenuLkIcon}
                  />
               </a>
               {menuData.map((item, index) => (
                  <NavLink
                     onClick={() => setIsMenuOpen(false)}
                     activeClassName={styles.button_active}
                     className={cn({
                        [`${styles["button--disabled"]}`]: item.disabled,
                     })}
                     to={item.route}
                     key={index}
                  >
                     <MenuButton
                        disabled={item.disabled}
                        text={item.text}
                        support={item.support}
                        hasInvisibleMessage={
                           item.support == MessagesIcon
                              ? hasInvisibleMessages
                              : false
                        }
                     />
                  </NavLink>
               ))}
               {width < 1024 && (
                  <>
                     <NavLink
                        onClick={() => setIsMenuOpen(false)}
                        activeClassName={styles.button_active}
                        to={routes.lk.settings.profile}
                     >
                        <MenuButton
                           text={t("Lk.Header.settings", "Настройки")}
                           support={Icons.buttons.MenuAdminSettings}
                        />
                     </NavLink>
                     <MenuButton
                        onClick={logoutHandler}
                        text={t("Lk.Header.logout", "Выйти")}
                        support={Icons.buttons.LogoutIcon}
                     />
                  </>
               )}
               <div className={styles.switchers}>
                  {width < 1024 && (
                     <LangSwitcherSmall className={styles.lang} />
                  )}
                  {width < 1024 &&
                     user.type == "teacher" &&
                     !user.is_blocked && (
                        <OnlineStatusSwitcher
                           className={styles.online_switcher}
                        />
                     )}
               </div>
            </div>
            {isMenuOpen && (
               <button
                  className={styles.hide_button}
                  onClick={() => setIsMenuOpen(false)}
               >
                  <Icons.ui.CrossMenuIcon className={styles.burger} />
                  <p>{t("Lk.Mobile-menu.button", "Меню")}</p>
               </button>
            )}
         </div>
         <div
            className={cn(styles.gradient, {
               [`${styles["gradient--opened"]}`]: isMenuOpen,
            })}
         />
         <MobileMenu setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
      </>
   );
};
