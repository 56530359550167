import React, { FC } from "react";
import styles from "components/Lk/Pages/SchedulePage/Schedule/ScheduleCalendar/ScheduleDaysOfWeek/index.module.css";
import cn from "classnames";
import { sidebarWeek, weeks } from "components/Lk/data/date";
import moment from "moment-timezone";
import { firstLetterUpperCase } from "helpers/string/firstLetterUppercase";

type propsType = {
   type: string;
};

export const ScheduleDaysOfWeek: FC<propsType> = (props) => {

    const firstDayOfMonth = moment().startOf('month').day();

   return (
      <div
         className={cn({
            [styles.container]: true,
            [styles["container--sidebar"]]: props.type == "sidebar",
         })}
      >
         <div className={styles.fullscreen_days}>
            {props.type == "fullscreen" &&
               weeks.map((item, index) => {
                   const dateObject = moment().startOf('isoWeek').add(index, 'day');
                  return (
                     <div key={index + 1333} className={styles.day}>
                        <p className={styles.day_index}>
                            {firstLetterUpperCase(dateObject.format("dddd"))}
                        </p>
                     </div>
                  );
               })}
         </div>
         <div className={styles.mobile_days}>
            {props.type == "fullscreen" &&
               sidebarWeek.map((item, index) => {
                   const dateObject = moment().startOf('isoWeek').add(index, 'day');
                  return (
                     <div className={styles.day}>
                        <p className={styles.day_index}>
                            {firstLetterUpperCase(dateObject.format("dd"))}
                        </p>
                     </div>
                  );
               })}
         </div>
         {props.type == "sidebar" &&
            sidebarWeek.map((item, index) => {
                const dateObject = moment().startOf('isoWeek').add(index, 'day');
               return (
                  <div
                     key={index}
                     className={cn({
                        [styles.day]: true,
                        [styles["day--sidebar"]]: props.type == "sidebar",
                     })}
                  >
                     <p className={styles.day_index}>
                        {firstLetterUpperCase(dateObject.format("dd"))}
                     </p>
                  </div>
               );
            })}
      </div>
   );
};
