// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__3mVsU {\n  background: var(--color-black4);\n}\n\n.style_create__uaMRL{\n  background: var(--color-black4);\n}\n\n.style_reject__2m-Vt{\n  background: #FEE7EB;\n}\n.style_approve__UDZP0 {\n  background: var(--color-blue4);\n}\n.style_new_suggestion__Ss2ee {\nbackground: #F8EBFE;\n}\n.style_approved_suggestion__2Eodf {\n  background: var(--color-blue4);\n}\n.style_rejected_suggestion__109L7 {\n  background: #FEE7EB;\n}\n\n.style_buttons__yj2Ru {\n  display: flex;\n  justify-content: space-between;\n}\n\n.style_button__3odTh {\n  font-family: var(--font-Manrope);\n  color: var(--color-blue2);\n  font-size: 14px;\n  font-weight: 500;\n}\n\n.style_loading__2dtSe {\n\n  height: 24px;\n  width: -moz-fit-content;\n  width: fit-content;\n}", "",{"version":3,"sources":["webpack://src/components/Lk/components/Notifications/variants/NotificationSuggestionCard/style.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,8BAA8B;AAChC;AACA;AACA,mBAAmB;AACnB;AACA;EACE,8BAA8B;AAChC;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,gCAAgC;EAChC,yBAAyB;EACzB,eAAe;EACf,gBAAgB;AAClB;;AAEA;;EAEE,YAAY;EACZ,uBAAkB;EAAlB,kBAAkB;AACpB","sourcesContent":[".container {\n  background: var(--color-black4);\n}\n\n.create{\n  background: var(--color-black4);\n}\n\n.reject{\n  background: #FEE7EB;\n}\n.approve {\n  background: var(--color-blue4);\n}\n.new_suggestion {\nbackground: #F8EBFE;\n}\n.approved_suggestion {\n  background: var(--color-blue4);\n}\n.rejected_suggestion {\n  background: #FEE7EB;\n}\n\n.buttons {\n  display: flex;\n  justify-content: space-between;\n}\n\n.button {\n  font-family: var(--font-Manrope);\n  color: var(--color-blue2);\n  font-size: 14px;\n  font-weight: 500;\n}\n\n.loading {\n\n  height: 24px;\n  width: fit-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__3mVsU",
	"create": "style_create__uaMRL",
	"reject": "style_reject__2m-Vt",
	"approve": "style_approve__UDZP0",
	"new_suggestion": "style_new_suggestion__Ss2ee",
	"approved_suggestion": "style_approved_suggestion__2Eodf",
	"rejected_suggestion": "style_rejected_suggestion__109L7",
	"buttons": "style_buttons__yj2Ru",
	"button": "style_button__3odTh",
	"loading": "style_loading__2dtSe"
};
export default ___CSS_LOADER_EXPORT___;
