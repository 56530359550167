// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateSlotWarning_container__1y1v1 {\n\n}\n\n.CreateSlotWarning_button__3ifpy {\n    display: block;\n    margin: 0 auto;\n}", "",{"version":3,"sources":["webpack://src/components/ModalWindow/templates/CreateSlotWarning/index.module.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,cAAc;IACd,cAAc;AAClB","sourcesContent":[".container {\n\n}\n\n.button {\n    display: block;\n    margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CreateSlotWarning_container__1y1v1",
	"button": "CreateSlotWarning_button__3ifpy"
};
export default ___CSS_LOADER_EXPORT___;
