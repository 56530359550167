// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LkStudentOwn_container__2FzY1 {\n    /*margin-top: 100px;*/\n}\n\n.LkStudentOwn_title__2X1lQ {\n    margin-bottom: 20px;\n    font-size: 30px;\n}", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/LkProfile/LkStudent/components/LkStudentOwn/index.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":[".container {\n    /*margin-top: 100px;*/\n}\n\n.title {\n    margin-bottom: 20px;\n    font-size: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "LkStudentOwn_container__2FzY1",
	"title": "LkStudentOwn_title__2X1lQ"
};
export default ___CSS_LOADER_EXPORT___;
