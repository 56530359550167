import React from "react";
import "./style/index.css";
import { Landings } from "components/Landings";
import { Route } from "react-router-dom";
import { SandBox } from "components/SandBox";
import { routes } from "routes";
import { Lk } from "components/Lk";
import { RegistrationTeacher } from "components/Registration/TeacherPages";
import { RegistrationStudent } from "components/Registration/StudentPages";
import { Auth } from "components/Registration/Auth";
import { Header } from "components/Header";
import { useInit } from "init/useInit";
import { ModalWindow } from "components/ModalWindow";
import { ServicesConnector } from "services/ServicesConnector";
import { PrivacyPolicy } from "./components/PrivacyPolicy";
import { UserAgreement } from "./components/UserAgreement";
import { Footer } from "./components/Landings/Footer";

export default function App() {
   const { readyState } = useInit();

   if (!readyState) return null;

   return (
      <div className='App'>
         <ServicesConnector />
         <ModalWindow />
         <Route exact path={["/", routes.landings.filter]}>
            <Header />
            <Landings />
         </Route>
         <Route path={routes.registration.student.root}>
            <RegistrationStudent />
         </Route>
         <Route path={routes.registration.auth.root}>
            <Auth />
         </Route>
         <Route path={routes.registration.teacher.root}>
            <RegistrationTeacher />
         </Route>
         <Route exact path='/about'>
            <SandBox />
         </Route>
         <Route path={routes.privacyPolicy}>
            <PrivacyPolicy />
         </Route>
         <Route path={routes.userAgreement}>
            <UserAgreement />
         </Route>
         <Route path={routes.lk.root}>
            <Header />
            <Lk />
            <Footer />
         </Route>
      </div>
   );
};
