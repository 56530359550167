// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RestorePassword_action_button__2EoDg {\n    margin-top: 70px;\n}\n.RestorePassword_input__3G2t5 {\n    margin-bottom: 20px;\n}", "",{"version":3,"sources":["webpack://src/components/Registration/Auth/RestorePassword/index.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".action_button {\n    margin-top: 70px;\n}\n.input {\n    margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"action_button": "RestorePassword_action_button__2EoDg",
	"input": "RestorePassword_input__3G2t5"
};
export default ___CSS_LOADER_EXPORT___;
