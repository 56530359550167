import { thunkType } from "../../core/LTools/core/types/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { LTools } from "../../core/LTools/constructor";
import { authApi } from "../../api/AuthApi/methods";
import { authSlice } from "../authSlice";
import {
   addSubjectType,
   createCategoryType,
   editEducationSystemType,
   editSubjectType,
   postEducationSystemType,
   updateCategoryType,
} from "../../api/adminApi/types";
import { adminApi } from "../../api/adminApi";
import { createModalWindow, uiSlice } from "store/modalWindowSlice";
import { addMethodToUpdateLang } from "services/translateService/useLangInit";
import { routes } from "routes";

let statuses: { success: any; error: any };
addMethodToUpdateLang(() => {
   statuses = {
      success: createModalWindow({
         template: "default",
         payload: {
            status: "success",
            button: "close",
            title: "Success",
         },
      }),
      error: createModalWindow({
         template: "default",
         payload: {
            status: "error",
            button: "close",
            title: "Error",
         },
      }),
   };
});

export const adminEditorThunk = {
   createCategory: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & createCategoryType>) {
      return createAsyncThunk("createCategory", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => adminApi.createCategory(props),
            responseReactionCreator: (response) => ({
               201: () => {
                  commonState.dispatch(
                     uiSlice.actions.setModalType(statuses.success)
                  );
                  commonState.location = routes.lk.admin.educationSystems.root;
               },
               400: () => {
                  commonState.dispatch(
                     uiSlice.actions.setModalType(statuses.error)
                  );
               },
               404: () => {
                  commonState.dispatch(
                     uiSlice.actions.setModalType(statuses.error)
                  );
               },
            }),
         });

         return response as any;
      })();
   },
   updateCategory: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & updateCategoryType>) {
      return createAsyncThunk("updateCategory", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => adminApi.updateCategory(props),
            responseReactionCreator: (response) => ({
               200: () => {
                  console.log(statuses.success);
                  commonState.dispatch(
                     uiSlice.actions.setModalType(statuses.success)
                  );
                  commonState.location = routes.lk.admin.educationSystems.root;
               },
               400: () => {
                  commonState.dispatch(
                     uiSlice.actions.setModalType(statuses.error)
                  );
               },
               404: () => {
                  commonState.dispatch(
                     uiSlice.actions.setModalType(statuses.error)
                  );
               },
            }),
         });

         return response as any;
      })();
   },
   addSystem: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & postEducationSystemType>) {
      return createAsyncThunk("addSystem", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => adminApi.postEducationSystem(props),
            responseReactionCreator: (response) => ({
               201: () => {
                  commonState.dispatch(
                     uiSlice.actions.setModalType(statuses.success)
                  );
               },
               400: () => {
                  commonState.dispatch(
                     uiSlice.actions.setModalType(statuses.error)
                  );
               },
               404: () => {
                  commonState.dispatch(
                     uiSlice.actions.setModalType(statuses.error)
                  );
               },
               500: () => {
                  commonState.dispatch(
                     uiSlice.actions.setModalType(statuses.error)
                  );
               },
            }),
         });

         return response as any;
      })();
   },
   editSystem: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & editEducationSystemType>) {
      return createAsyncThunk("addSystem", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => adminApi.editEducationSystem(props),
            responseReactionCreator: (response) => ({
               200: () => {
                  commonState.dispatch(
                     uiSlice.actions.setModalType(statuses.success)
                  );
               },
               400: () => {
                  commonState.dispatch(
                     uiSlice.actions.setModalType(statuses.error)
                  );
               },
               404: () => {
                  commonState.dispatch(
                     uiSlice.actions.setModalType(statuses.error)
                  );
               },
               500: () => {
                  commonState.dispatch(
                     uiSlice.actions.setModalType(statuses.error)
                  );
               },
            }),
         });

         return response as any;
      })();
   },
   addSubject: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & addSubjectType>) {
      return createAsyncThunk("addSystem", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => adminApi.addSubject(props),
            responseReactionCreator: (response) => ({
               201: () => {
                  commonState.dispatch(
                     uiSlice.actions.setModalType(statuses.success)
                  );
                  commonState.location = routes.lk.admin.educationSystems.root;
               },
               400: () => {
                  commonState.dispatch(
                     uiSlice.actions.setModalType(statuses.error)
                  );
               },
               404: () => {
                  commonState.dispatch(
                     uiSlice.actions.setModalType(statuses.error)
                  );
               },
               500: () => {
                  commonState.dispatch(
                     uiSlice.actions.setModalType(statuses.error)
                  );
               },
            }),
         });

         return response as any;
      })();
   },
   editSubject: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & editSubjectType>) {
      return createAsyncThunk("addSystem", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => adminApi.editSubject(props),
            responseReactionCreator: (response) => ({
               200: () => {
                  commonState.dispatch(
                     uiSlice.actions.setModalType(statuses.success)
                  );
                  commonState.location = routes.lk.admin.educationSystems.root;
               },
               400: () => {
                  commonState.dispatch(
                     uiSlice.actions.setModalType(statuses.error)
                  );
               },
               404: () => {
                  commonState.dispatch(
                     uiSlice.actions.setModalType(statuses.error)
                  );
               },
               500: () => {
                  commonState.dispatch(
                     uiSlice.actions.setModalType(statuses.error)
                  );
               },
            }),
         });

         return response as any;
      })();
   },
};
