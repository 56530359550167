// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProfileCardsWrapper_container__122bN {\n    width: 100%;\n    margin-top: 100px;\n}\n\n.ProfileCardsWrapper_title__2pZyu {\n    font-weight: 600;\n    font-size: 32px;\n    margin-bottom: 30px;\n}\n\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n    .ProfileCardsWrapper_container__122bN {\n        margin-top: 50px;\n    }\n}\n\n@media screen and (max-width: 1023px) {\n\n}\n\n@media screen and (max-width: 719px) {\n\n}", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/LkProfile/components/ProfileCardsWrapper/index.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,mBAAmB;AACvB;;AAEA;;AAEA;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA;;AAEA;;AAEA;;AAEA","sourcesContent":[".container {\n    width: 100%;\n    margin-top: 100px;\n}\n\n.title {\n    font-weight: 600;\n    font-size: 32px;\n    margin-bottom: 30px;\n}\n\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n    .container {\n        margin-top: 50px;\n    }\n}\n\n@media screen and (max-width: 1023px) {\n\n}\n\n@media screen and (max-width: 719px) {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ProfileCardsWrapper_container__122bN",
	"title": "ProfileCardsWrapper_title__2pZyu"
};
export default ___CSS_LOADER_EXPORT___;
