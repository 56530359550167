import React, { AllHTMLAttributes, FC, useEffect } from "react";
import styles from "./index.module.css";
import { Control, useWatch } from "react-hook-form";
import cn from "classnames";

type TextAreaType = {
   control: Control<any>;
   name: string;
   error?: string;
} & AllHTMLAttributes<HTMLTextAreaElement>;

export const TextArea: FC<TextAreaType> = (props) => {
   const { placeholder, className, ...other } = props;
   const inputProps = props.control.register(props.name);

   function auto_grow() {
      let objDiv = document.getElementById("textArea");
      // @ts-ignore
      objDiv.style.height = "24px";
      // @ts-ignore
      objDiv.style.height = objDiv.scrollHeight + "px";
   }

   const textArea = useWatch({
      control: props.control,
      name: props.name,
      defaultValue: "",
   });

   useEffect(() => {
      auto_grow();
   }, [textArea]);

   return (
      <>
         <textarea
            id={"textArea"}
            className={cn(styles.textArea, {
               [`${className}`]: className,
            })}
            onInput={auto_grow}
            placeholder={placeholder}
            maxLength={1500}
            {...other}
            {...inputProps}
         />
         <div className={styles.error}>{props.error}</div>
      </>
   );
};
