import { Icons } from "assets/iconsCorrect";
import React, { FC, HTMLAttributes } from "react";
import styles from "./index.module.css";
import { dateMethods, getTimeDifference } from "helpers/date";
import cn from "classnames";

type propsType = {
   date_from: string;
   date_to: string;
   color?: "white" | "black";
} & HTMLAttributes<HTMLDivElement>;
export const TimeInterval: FC<propsType> = ({
   date_from,
   date_to,
   color = "black",
   ...props
}) => {
   return (
      <div {...props} className={cn(styles.container, props.className)}>
         <Icons.ui.clockIcon
            className={cn(styles.icon, styles[`icon--${color}`])}
         />
         {dateMethods.getTime(date_from)}-{dateMethods.getTime(date_to)}
         <p className={styles.duration}>
            {getTimeDifference(date_from, date_to).formatted}
         </p>
      </div>
   );
};
