// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Board_container__3NbVs {\n    border-radius: 10px;\n    background-color: var(--color-primary-blue-4-very-light);\n}\n\n.Board_content__365-1 {\n    padding: 9px 15px;\n    text-align: center;\n    font-family: \"Source Sans Pro\";\n    font-weight: 400;\n    line-height: normal;\n}\n", "",{"version":3,"sources":["webpack://src/components/UI/Board/index.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,wDAAwD;AAC5D;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,8BAA8B;IAC9B,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".container {\n    border-radius: 10px;\n    background-color: var(--color-primary-blue-4-very-light);\n}\n\n.content {\n    padding: 9px 15px;\n    text-align: center;\n    font-family: \"Source Sans Pro\";\n    font-weight: 400;\n    line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Board_container__3NbVs",
	"content": "Board_content__365-1"
};
export default ___CSS_LOADER_EXPORT___;
