import React, { FC, useState } from "react";
import styles from "./index.module.css";
import cn from "classnames";
import { ReactComponent as Arrow } from "../../../assets/arrow.svg";
import { AdminInfoBlock } from "../UserInfoBlock";
import { useTranslation } from "react-i18next";
import { useGetUserAvatar } from "hooks/useGetUserAvatar";
import { showDateDMY } from "helpers/date/showDate";
import { CreateChatButton } from "components/UI/CreateChatButton";
import { useHistory } from "react-router-dom";
import { routes } from "../../../../../../../../../routes";

type AdminTableItemType = {
   inactiveTeacherData: any;
   updateState: () => void;
};

export const AdminTableItem: FC<AdminTableItemType> = (props) => {
   const { inactiveTeacherData, updateState } = props;
   const [avatar] = useGetUserAvatar(inactiveTeacherData.id);
   const history = useHistory();
   const { t } = useTranslation();

   const [isOpened, setIsOpened] = useState<boolean>(false);

   function openUserInfo() {
      // console.log("opened");
      setIsOpened((prevState) => !prevState);
   }

   return (
      <>
         <tr className={styles.preview}>
            <td className={styles.preview_photo}>
               <img src={avatar} alt='' />
            </td>
            <td className={styles.table_item}>
               <p className={styles.user_id}>{inactiveTeacherData.id}</p>
               <p
                  className={styles.user_name}
                  onClick={() =>
                     history.push(
                        routes.lk.lkStudent + "/" + inactiveTeacherData.id
                     )
                  }
               >
                  {inactiveTeacherData.first_name}{" "}
                  {inactiveTeacherData.last_name}
               </p>
            </td>
            <td className={styles.table_item}>
               {showDateDMY(inactiveTeacherData.registration_date)}
            </td>
            <td className={styles.table_item}>
               {showDateDMY(inactiveTeacherData.birth_date)}
            </td>
            <td className={styles.table_item}>
               {inactiveTeacherData.type === "inactive_teacher" &&
                  t("Lk.AdminPanel.Table.student", "Студент")}
            </td>
            <td className={[styles.table_item, styles.email].join(" ")}>
               {inactiveTeacherData.email}
            </td>
            <td>
               <CreateChatButton user_id={inactiveTeacherData.id} />
            </td>
            <td className={styles.button}>
               <button onClick={() => openUserInfo()}>
                  <Arrow />
               </button>
            </td>
         </tr>
         <tr style={{ borderBottom: "unset" }}>
            <td
               style={{ padding: "0" }}
               colSpan={window.innerWidth > 1439 ? 7 : 6}
            >
               <div
                  className={cn(styles.info, {
                     [styles["info-open"]]: isOpened,
                  })}
               >
                  <AdminInfoBlock
                     updateState={updateState}
                     inactiveTeacherData={inactiveTeacherData}
                     avatar={avatar}
                  />
               </div>
            </td>
         </tr>
      </>
   );
};
