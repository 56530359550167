import React, { FC } from "react";
import styles from "./index.module.css";
import { routes } from "routes";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { slotType } from "types/LessonService/types";
import moment from "moment-timezone";
import { TimeInterval } from "components/UI/TimeInterval";
import { Button } from "components/UI/Button";
import { Icons } from "assets/iconsCorrect";

type propsType = {
   teacher_id: string;
} & slotType;
export const GuestSlotCard: FC<propsType> = ({ teacher_id, ...slot }) => {
   const { t } = useTranslation();

   const history = useHistory();

   const goToSlot = () => {
      history.push(routes.lk.lesson.sendRequest + `/${slot.id}/${teacher_id}`);
   };

   if (!slot) return null;
   const date = moment(slot.date_from);
   return (
      <div className={styles.container}>
         <main className={styles.date_and_time}>
            <div className={styles.date}>
               <h5 className={styles.day_and_mount}>
                  {date.format("DD")}, {date.format("MMMM")}
                  {", "}
                  {date.format("YYYY")}
               </h5>
               <h6 className={styles.week_day}>{date.format("dddd")}</h6>
            </div>
            <TimeInterval date_to={slot.date_to} date_from={slot.date_from} />
         </main>
         <Button onClick={goToSlot} variant={"link"} className={styles.button}>
            <Icons.ui.calendarIcon style={{ marginRight: 10 }} />
            {t("Lk.FreeDate.send-request", "Отправить запрос")}
         </Button>
      </div>
   );
};
