import React, { FC } from "react";
import styles from "./index.module.css";
import { ReviewsData } from "./ReviewsData";
import { ReviewItem } from "./ReviewItem";
import { SliderArrows } from "../../../UI/SliderArrows";
import { useSlider } from "hooks/useSlider";
import { useTranslation } from "react-i18next";

type propsType = {};
export const Reviews: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const {
      mouseMoveHandler,
      mouseUpHandler,
      mouseDownHandler,
      translate,
      currentSlide,
      setCurrentSlide,
   } = useSlider({
      length: ReviewsData.length,
   });

   return (
      <div className={styles.container}>
         <span id='reviews' style={{ paddingTop: 90, marginTop: -90 }} />
         <div className={styles.header}>
            <p>{t("Landings.Reviews.header", "Отзывы")}</p>
         </div>
         <div className={styles.background}>
            <p>{t("Landings.Reviews.background-text", "Отзывы о нас")}</p>
         </div>
         <div
            className={styles.reviews_container}
            onMouseDown={mouseDownHandler}
            onMouseMove={mouseMoveHandler}
            onMouseUp={mouseUpHandler}
         >
            {ReviewsData.map((item, index) => {
               return (
                  <ReviewItem
                     key={index}
                     translate={translate}
                     setCurrentSlide={setCurrentSlide}
                     review={item}
                     id={index}
                     currentSlide={currentSlide}
                  />
               );
            })}
         </div>
         <div className={styles.arrows}>
            <SliderArrows
               length={ReviewsData.length}
               current={currentSlide}
               setCurrent={setCurrentSlide}
            />
         </div>
      </div>
   );
};
