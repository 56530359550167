import React, { FC, HTMLAttributes, useEffect, useState } from "react";
import styles from "./index.module.css";
import cn from "classnames";

type propsType = {} & HTMLAttributes<HTMLDivElement>;
export const NotificationCardWrapper: FC<propsType> = (props) => {
   return (
      <div
         className={cn(styles.container, {
            [`${props.className}`]: props.className,
         })}
         data-class="NotificationCard"
      >
         {props.children}
      </div>
   );
};
