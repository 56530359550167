import React, { FC } from "react";
import styles from "./index.module.css";
import { ReactComponent as SearchLogo } from "./assets/Search.svg";
import { ReactComponent as DownArrow } from "./assets/DownArrow.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { lkSliceSelectors } from "store/lkSlice";
import { routes } from "routes";
import { useSelector } from "react-redux";
import { NotificationsSidebar } from "../../Lk/components/Notifications/NotificationsSidebar/NotificationsSidebar";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { useGetUserAvatar } from "hooks/useGetUserAvatar";
import { Icons } from "assets/iconsCorrect";
import { OnlineStatusSwitcher } from "components/UI/OnlineStatusSwitcher";
import { LangSwitcherSmall } from "components/UI/LangSwitcher/LangSwitcherSmall";
import { logout } from "store/helpers";
import { useGoToProfile } from "helpers/util/goToProfile";

type propsType = {};
export const HeaderLk: FC<propsType> = () => {
   const { t } = useTranslation();
   const user = useSelector(lkSliceSelectors.getUserData);
   const commonState = useLocalCommonState();
   const [avatar] = useGetUserAvatar(user?.id);

   const logoutHandler = () => {
      logout(commonState.dispatch);
      commonState.location = routes.registration.auth.firstStage;
   };

   const goToProfile = useGoToProfile(user.id);

   return (
      <div className={styles.container}>
         <Icons.logos.logoFull className={styles.logo} onClick={goToProfile} />

         <div className={styles.nav}>
            {user.type == "teacher" && !user.is_blocked && (
               <OnlineStatusSwitcher className={styles.online_switcher} />
            )}

            <div className={styles.lang}>
               <LangSwitcherSmall />
            </div>
            <Link
               to={routes.lk.search_lesson.first_stage}
               className={styles.search_lesson}
            >
               <button>{React.createElement(SearchLogo)}</button>
               {/*{t("Lk.Header.search-button", "Поиск занятий")}*/}
            </Link>
            <NotificationsSidebar />
            <div className={styles.profile_container}>
               <div className={styles.profile_button}>
                  <img src={avatar} />
                  {user.first_name ? (
                     <p>{user.first_name}</p>
                  ) : (
                     <div className={styles.name_loading} />
                  )}
                  {React.createElement(DownArrow)}
               </div>
               <div className={styles.profile_email}>{user.email}</div>
               <ul className={styles.hover_menu}>
                  <a className={styles.dropdown__link} onClick={goToProfile}>
                     {t("Lk.Header.profile", "Профиль")}
                  </a>

                  <Link
                     className={styles.dropdown__link}
                     to={routes.lk.settings.profile}
                  >
                     {t("Lk.Header.settings", "Настройки")}
                  </Link>

                  <div
                     className={styles.dropdown__link}
                     onClick={logoutHandler}
                  >
                     {t("Lk.Header.logout", "Выйти")}
                  </div>
               </ul>
            </div>
         </div>
      </div>
   );
};
