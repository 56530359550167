export const getTimeFromTo = (firstDate: string, secondDate: string) => {
   const getDate = (string: string) => {
      return new Date(
         0,
         0,
         0,
         Number(string.split('.')[0]),
         Number(string.split('.')[1])
      );
   };

   const different = (Number(getDate(secondDate)) - Number(getDate(firstDate)));
   let differentRes, hours, minuts;

   if (different === 0) {
      return 0;
   } else if (different > 0) {
      differentRes = different;
      hours = Math.floor((differentRes % 86400000) / 3600000);
      minuts = Math.round(((differentRes % 86400000) % 3600000) / 60000);
   } else {
      differentRes = Math.abs((+getDate(firstDate) - +getDate(secondDate)));
      hours = Math.floor(24 - (differentRes % 86400000) / 3600000);
      minuts = Math.round(60 - ((differentRes % 86400000) % 3600000) / 60000);
   }

   const time = hours + ':' + minuts;
   const result = (+time.split(":")[0] * 60 + +time.split(":")[1]) / 60;

   return result;
};
