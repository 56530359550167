import React, { FC } from "react";
import styles from "./index.module.css";
import cn from "classnames";
import { ReactComponent as Checked } from "../../../../assets/messageChecked.svg";
import { ReactComponent as Alerted } from "../../../../assets/messageAlerted.svg";
import { ReactComponent as Load } from "../../../../assets/messageLoad.svg";
import { ReactComponent as Warning } from "../../../../assets/warning.svg";
import { FileDownloader } from "components/UI/FileDownloader";
import { useGetUserAvatar } from "hooks/useGetUserAvatar";
import { studentType } from "types/userType";
import { MessageType } from "store/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";
import moment from "moment-timezone";
import { createModalWindow, uiSlice } from "store/modalWindowSlice";
import { useGoToProfile } from "helpers/util/goToProfile";

type PropType = {
   owner: boolean;
   item: MessageType;
   chat_id: number;
   onClickReport: (message_id: number) => void;
};

export const Message: FC<PropType> = (props) => {
   const { item, owner, chat_id, onClickReport } = props;
   const dispatch = useDispatch();

   const myUser = useSelector(lkSliceSelectors.getUserData);
   const message = item;

   function sendWarning() {
      dispatch(
         uiSlice.actions.setModalType(
            createModalWindow<"create_lesson_report">({
               template: "create_lesson_report",
               payload: {
                  type: "message",
               },
            })
         )
      );
   }
   const goToProfile = useGoToProfile(item.user.id);

   const [avatar] = useGetUserAvatar(String(item.user.id));
   return (
      <div
         className={cn(styles.container, {
            [styles.ownerMessage]: owner,
         })}
      >
         <div onClick={goToProfile} className={styles.avatar}>
            <img src={avatar} alt='' />
         </div>
         <div className={styles.body}>
            <h5 className={styles.name}>
               {item.user.first_name + " " + item.user.last_name}
            </h5>
            {item.message !== "" && (
               <p className={styles.text}> {item.message}</p>
            )}
            {item.files.length > 0 && (
               <div className={styles.files}>
                  {item.files.map((item, index) => (
                     <div className={styles.files_item}>
                        <FileDownloader
                           user_id={myUser.id}
                           chat={{
                              file_id: item.id,
                              chat_id: chat_id,
                              file_name: item.file_name,
                              message_id: message.id,
                           }}
                        >
                           {item.file_name}
                        </FileDownloader>
                     </div>
                  ))}
               </div>
            )}
            <div className={styles.bottom}>
               <div className={styles.status}>
                  {owner ? (
                     <>
                        {item.message_status === "visible" ? (
                           <Checked />
                        ) : (
                           <Alerted />
                        )}
                     </>
                  ) : null}
                  <p className={styles.time}>
                     {moment(item.date).format("HH:mm DD.MM.YYYY")}
                  </p>
                  {/*<p className={styles.time}>{item.date.substr(11, 5)}</p>*/}
               </div>
               {!owner && (
                  <button onClick={sendWarning} className={styles.warning}>
                     <Warning />
                  </button>
               )}
            </div>
         </div>
         <div className={styles.space} />
      </div>
   );
};
