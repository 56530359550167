// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__3-qYf {\n  font-family: var(--font-Source-Sans-Pro);\n  margin-bottom: 10px;\n}\n\n.style_title__4b5lE {\n  font-size: 14px;\n  font-weight: 600;\n  margin-bottom: 4px;\n}\n\n.style_date__33BoU {\n  display: flex;\n}\n\n.style_day__1q9Fb {\n  margin-right: 5px;\n}\n\n.style_time__25jFH {\n  font-weight: 300;\n}", "",{"version":3,"sources":["webpack://src/components/Lk/components/Notifications/components/Date/style.module.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".container {\n  font-family: var(--font-Source-Sans-Pro);\n  margin-bottom: 10px;\n}\n\n.title {\n  font-size: 14px;\n  font-weight: 600;\n  margin-bottom: 4px;\n}\n\n.date {\n  display: flex;\n}\n\n.day {\n  margin-right: 5px;\n}\n\n.time {\n  font-weight: 300;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__3-qYf",
	"title": "style_title__4b5lE",
	"date": "style_date__33BoU",
	"day": "style_day__1q9Fb",
	"time": "style_time__25jFH"
};
export default ___CSS_LOADER_EXPORT___;
