import { useState } from "react";

export type usePaginatorType = {
   pageCount: number;
   onPageChange: (n: { selected: number }) => void;
   current: number;
   pageRangeDisplayed: number;
   offset: number;
   setCurrent: (val: number) => void;
   setLength: React.Dispatch<React.SetStateAction<number>>;
};
type usePaginatorPropsType = {
   length: number;
   perPage: number;
};
export const usePaginator = (props: usePaginatorPropsType): usePaginatorType => {
   const [current, setCurrent] = useState(0);
   const [length, setLength] = useState<number>(props.length);


   return {
      current,
      pageCount: Math.ceil(length / props.perPage),
      onPageChange: (n: { selected: number }) => setCurrent(n.selected),
      pageRangeDisplayed: props.perPage,
      offset: current * props.perPage,
      setCurrent,
      setLength: setLength,
   };
};
