import { TeacherCard } from "components/Lk/Pages/LkProfile/Cards/TeacherCard";
import { StudentCard } from "components/Lk/Pages/LkProfile/Cards/StudentCard";
import React from "react";
import { TeacherCardSlot } from "components/Lk/Pages/LkProfile/Cards/TeacherCardSlot";

export const ticketTypeResolver = (tickets: any, id: string, filters?: any) => {
   return tickets.map((ticket: any) => {
      if (filters) {
         switch (ticket.type) {
            case "slot": {
               return filters.slot ? (
                  <TeacherCardSlot invites={ticket} {...ticket} />
               ) : (
                  false
               );
            }
            case "invite": {
               return filters.student_unproved ? (
                  <StudentCard type={"invite"} {...ticket} />
               ) : (
                  false
               );
            }
            case "lesson": {
               if (ticket.teacher.id == id) {
                  return filters.teacher_proved ? (
                     <TeacherCard type={"lesson"} {...ticket} />
                  ) : (
                     false
                  );
               } else {
                  return filters.student_proved ? (
                     <StudentCard type={"lesson"} {...ticket} />
                  ) : (
                     false
                  );
               }
            }
            case "group_lesson": {
               if (ticket.teacher.id == id) {
                  return filters.teacher_open ? (
                     <TeacherCard type={"group_lesson"} {...ticket} />
                  ) : (
                     false
                  );
               } else {
                  return filters.student_open ? (
                     <StudentCard type={"group_lesson"} {...ticket} />
                  ) : (
                     false
                  );
               }
            }
         }
      } else {
         switch (ticket.type) {
            case "slot": {
               return <TeacherCardSlot invites={ticket} {...ticket} />;
            }
            case "invite": {
               return <StudentCard type={"invite"} {...ticket} />;
            }
            case "lesson": {
               if (ticket.teacher.id == id) {
                  return <TeacherCard type={"lesson"} {...ticket} />;
               } else {
                  return <StudentCard type={"lesson"} {...ticket} />;
               }
            }
            case "group_lesson": {
               if (ticket.teacher.id == id) {
                  return <TeacherCard type={"group_lesson"} {...ticket} />;
               } else {
                  return <StudentCard type={"group_lesson"} {...ticket} />;
               }
            }
         }
      }
   });
};
