import React, { FC } from "react";
import { Route } from "react-router-dom";
import { routes } from "routes";
import { FirstStage } from "./FirstStage";
import { Authorization } from "./Authorization";
import { RegistrationCode } from "./RegistrationCode";
import { RegistrationWrapper } from "../RegistrationWrapper";
import { CreatePassword } from "./CreatePassword";
import { RestorePassword } from "./RestorePassword";
import { RegistrationBackground } from "components/Registration/RegistrationBackground";

type propsType = {};
export const Auth: FC<propsType> = () => {
   return (
      <div className='RegistrationStudent'>
         <RegistrationWrapper>
            <Route exact path={routes.registration.auth.firstStage}>
               <FirstStage />
            </Route>
            <Route exact path={routes.registration.auth.authorization}>
               <Authorization />
            </Route>
            <Route
               exact
               path={[
                  routes.registration.auth.registrationCode,
                  routes.registration.auth.registrationCode +
                     "/:template/:code",
               ]}
            >
               <RegistrationCode />
            </Route>
            <Route exact path={routes.registration.auth.createPassword}>
               <CreatePassword />
            </Route>
            <Route exact path={routes.registration.auth.restorePassword}>
               <RestorePassword />
            </Route>
         </RegistrationWrapper>
         <RegistrationBackground type={"authorization"} />
      </div>
   );
};
