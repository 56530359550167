import React, { FC, useEffect, useState } from "react";
import { useQuery } from "../../../../../../../hooks/useQuery";
import { groupLessonApi } from "../../../../../../../api/groupLessonApi/methods";
import { lessonType } from "../../../../../../../types/LessonService/types";
import { useSelector } from "react-redux";
import { authSliceSelectors } from "../../../../../../../store/authSlice";
import { NoteTable } from "../components/Table";
import { Paginate } from "../../../../AdminService/components/Paginate";
import { usePaginator } from "../../../../../../../hooks/usePagination";
import styles from "./index.module.css";
import { serverArrayType } from "../../../../../../../types/types";
import { lessonApi } from "../../../../../../../api/lessonApi/methods";
import { SelectUnderControl } from "../../../../../../UI/Select/SelectUnderControl";
import { useForm, useWatch } from "react-hook-form";
import { Calendar } from "../../../../../../UI/Calendar";
import { Input } from "../../../../../../UI/Input";
import moment from "moment-timezone";
import { useDebounce } from "hooks/useDebounce";
import { sortingOptions } from "helpers/selectOptions";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../../UI/Button";
import { useMedia } from "../../../../../../../hooks/useMedia";
import { Preloader } from "../../../../../../UI/Preloader";
import { Select } from "../../../../../../UI/SelectNew";

type propsType = {
   template: "default" | "group";
};
export const DefaultLessonsTable: FC<propsType> = ({ template }) => {
   const { t } = useTranslation();
   const userId = useSelector(authSliceSelectors.getId);
   const [didMount, setDidMount] = useState(false);
   const { setValue, control, reset } = useForm<any>({
      defaultValues: {
         nameField: "",
         date: undefined,
         sort: sortingOptions[0],
         sort_input: sortingOptions[0].text,
      },
   });
   const [itemsCount, setItemsCount] = useState<number>(0);
   const [nameField, date, sort] = useWatch({
      control,
      name: ["nameField", "date", "sort"],
   });

   const { width } = useMedia();
   const [lessons, setLessons] = useState<serverArrayType<Array<lessonType>>>({
      items: [],
      items_count: 0,
   });

   const name = useDebounce(nameField);
   const paginator = usePaginator({
      length: itemsCount,
      perPage: 10,
   });

   /* fetch */
   /**
    * @DateMoment
    * Из календаря получаем date - моментом приводим его к нашей таймзоне без времени.
    * Таким образом получается что мы выбрали 00:00 в нашей текущей таймзоне.
    * Делаем оффсет к нулевому.
    */
   const prepareDateForRequest = (date: string | undefined) => {
      return date ? moment(date).startOf("day").utcOffset("+0000") : undefined;
   };
   const getDefaultLessons = () => {
      return lessonApi.getLessons({
         limit: "10",
         date_from: date
            ? prepareDateForRequest(date)!.format("YYYY-MM-DD")
            : undefined,
         user_name: name,
         date_to: date
            ? prepareDateForRequest(date)!.add(1, "day").format("YYYY-MM-DD")
            : undefined,
         order_by_date: sort ? sort?.value : undefined,
         offset: String(paginator.offset),
         lesson_statuses: ["finished_lesson"],
      });
   };
   const getGroupLessons = () => {
      return groupLessonApi.getGroupLessons({
         limit: "10",
         offset: String(paginator.offset),
         order_by_date: sort ? sort?.value : undefined,
         user_name: name,
         date_from: date
            ? prepareDateForRequest(date)!.format("YYYY-MM-DD")
            : undefined,
         date_to: date
            ? prepareDateForRequest(date)!.add(1, "day").format("YYYY-MM-DD")
            : undefined,
         user_id: userId,
         lesson_statuses: ["finished_group_lesson"],
      });
   };

   useEffect(() => {
      setLessons({ items: [], items_count: 0 });
      paginator.setCurrent(0);
   }, [sort, date, name, template]);
   const { data: lessonsResponse, isLoading: loading } = useQuery(
      template == "default" ? getDefaultLessons : getGroupLessons,
      {
         serializer: (res) => {
            console.log(paginator.offset, sort, date, name);
            setItemsCount(res?.data?.data?.items_count);
            if (width < 1024) {
               setLessons((prev) => ({
                  items_count: res?.data?.data?.items_count,
                  items: [...prev.items, ...res?.data?.data?.items],
               }));
            } else {
               setLessons({
                  items_count: res?.data?.data?.items_count,
                  items: res?.data?.data?.items as Array<lessonType>,
               });
            }
         },
         deps: [paginator.offset, sort, date, name],
      }
   );

   const mobilePaginatorHandler = () => {
      paginator.setCurrent(paginator.current + 1);
   };
   /* reset */
   useEffect(() => {
      if (didMount) {
         reset();
         paginator.setCurrent(0);
      } else {
         setDidMount(true);
      }
   }, [template]);
   return (
      <div>
         <div className={styles.filters}>
            <Select
               className={styles.filters__input}
               variant={"blue-bg"}
               name='sort'
               setValue={setValue}
               options={sortingOptions}
               control={control}
            />
            <Calendar
               className={styles.filters__input}
               control={control}
               name='date'
               placeholder={t("UI.DateAndTimePicker.date", "Дата")}
            />
            <Input
               className={styles.filters__input}
               control={control}
               name='nameField'
               label={t("Common.filters.search_by_name", "Поиск по имени")}
            />
         </div>
         <NoteTable
            data={lessons.items}
            loading={width >= 1024 ? loading : false}
            type={template == "default" ? "lesson" : "group_lesson"}
         />
         {itemsCount > 10 && (
            <Paginate
               className={styles.paginator}
               pageCount={paginator.pageCount}
               onPageChange={paginator.onPageChange}
               onPageActive={() => null}
            />
         )}

         {width < 1024 && loading && (
            <Preloader loading={loading} variant={"centred"} />
         )}
         {itemsCount > paginator.current * 10 && (
            <div className={styles.paginator_mobile}>
               <Button variant={"outline"} onClick={mobilePaginatorHandler}>
                  {t("SearchFilters.teacher-filters.show-more", "Показать ещё")}
               </Button>
            </div>
         )}
      </div>
   );
};
