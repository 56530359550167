import { AxiosResponse } from "axios";
import { languagesNameType, languageType } from "../store/formsDataSlice/types";
import { languagesTitleResolver } from "../staticData/languages";
export function languageSelectSerializer(
   res: AxiosResponse<any> | undefined
): Array<{
   id: number;
   text: string;
   value: {
      title: string;
      id: number;
      name: languagesNameType;
   };
}> {
   return res?.data?.data?.items.map((item: languageType) => ({
      id: item.id,
      text: languagesTitleResolver[item.name],
      value: {
         title: languagesTitleResolver[item.name],
         ...item,
      },
   }));
}
