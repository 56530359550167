import React, { FC } from "react";
import styles from "./index.module.css";
import cn from "classnames";
import { studentType } from "types/userType";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";
import { useGetUserAvatar } from "hooks/useGetUserAvatar";

import adminChatIcon from "../../assets/adminChatIcon.jpg";
import { useLocalCommonState } from "../../../../../../core/LTools/core/hooks/useLocalCommonState";
import { useTranslation } from "react-i18next";
import { DialogType } from "store/chatSlice";

type PropsType = {
   id: number;
   name?: string;
   users: studentType[];
   last_message_time: string | null;
   onSelectChat: (id: number) => void;
   has_invisible_messages?: boolean;
   active: boolean;
   isAdminChat: boolean;
   chat: DialogType;
};

export const DialogItem: FC<PropsType> = (props) => {
   const commonState = useLocalCommonState();
   const { t } = useTranslation();

   /**
    *
    * props.name существует на случай если у диалога можно будет задать пользовательский нейм.
    *
    * поле active - статус выбран диалог или нет( true || false )
    * */

   const {
      last_message_time,
      name,
      users,
      id,
      onSelectChat,
      active,
      isAdminChat,
      has_invisible_messages,
      chat,
   } = props;

   /**
    * Вытаскиваем id из слайса, логичней будет вынести его на родителя.
    * @TODO Вынести id в родителя и прокидать пропсом
    * */

   const my_user = useSelector(lkSliceSelectors.getUserData);

   const [avatar] = useGetUserAvatar(
      isAdminChat && my_user.role === "user"
         ? undefined
         : users.find((item) => item.id !== my_user.id)?.id
   ) || [adminChatIcon];

   /**
    * Простая функция selectChat вешается на всю компоненту DialogItem
    * которая передает id диалога и вытаскивает имя первого юзера, игнорируя нашего из масива users
    * */

   function selectChat() {
      onSelectChat(id);
   }

   function checkOnline(users: studentType[]) {
      users.map((item) => {
         if (item.online) return true;
      });
      return false;
   }

   /**
    *
    */

   // if (!users.find((item) => item.id !== my_user.id)?.first_name) {
   //    return null;
   // }

   const dialogName = (function () {
      const getAnotherUser = (findMethod: (user: any) => boolean) => {
         const user = users.find(findMethod);
         if (user) {
            return (
               <>
                  {user.first_name}
                  <br />
                  {user.last_name}
               </>
            );
         } else {
            return "";
         }
      };

      try {
         if (isAdminChat) {
            if (my_user.role == "user") {
               if (chat.lead_admin) {
                  return (
                     <>
                        {chat.lead_admin.first_name}
                        <br />
                        {chat.lead_admin.last_name}
                     </>
                  );
               } else {
                  return t("Messages.administration", "Администрация");
               }
            } else {
               const defaultUserName = getAnotherUser(
                  (user) => user.user_role == "user"
               );
               if (defaultUserName) {
                  return defaultUserName;
               } else {
                  return t("Messages.administration", "Администрация");
               }
            }
         } else {
            return getAnotherUser((user) => user.id !== my_user.id);
         }
      } catch (e) {
         return "";
      }
   })();

   if (!dialogName) return null;

   return (
      <div
         onClick={selectChat}
         className={cn(styles.container, {
            [styles.active]: active,
         })}
      >
         <div className={styles.content}>
            <div className={styles.left}>
               <div
                  className={cn(styles.has_invisible_messages, {
                     [styles.has_invisible_messages_false]: !has_invisible_messages,
                  })}
               />
               <img className={styles.avatar} src={avatar} alt='' />
               <div className={styles.title}>
                  <h5 className={styles.first_name}>{dialogName}</h5>
               </div>
            </div>
            <div className={styles.right}>
               <p className={styles.lastMessage_date}>
                  {/*{last_message_time !== null && last_message_time*/}
                  {/*   .substr(0, 10)*/}
                  {/*   .split("-")*/}
                  {/*   .reverse()*/}
                  {/*   .join(".")}*/}
               </p>
               <p className={styles.lastMessage_time}>
                  {/*{last_message_time !== null && last_message_time.substr(11, 5)}*/}
               </p>
            </div>
         </div>
      </div>
   );
};
