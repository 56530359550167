import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { AdminTable } from "./components/Table/AdminTable";
import { routes } from "routes";
import { Paginate } from "../../components/Paginate";
import { Button } from "components/UI/Button";
import { useQuery } from "hooks/useQuery";
import { userApi } from "api/userApi/methods";
import { PageTitle } from "components/UI/PageTitle";
import { teacherType } from "types/userType";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { AdminTableFilters } from "components/Lk/Pages/AdminService/Pages/Users/components/Filters";
import { useForm } from "react-hook-form";

export const AdminTeacherActivation = () => {
   const { id: search_user_id } = useParams<{ id: string }>();
   const commonState = useLocalCommonState();
   const { t } = useTranslation();
   const [selectedPage, setSelectedPage] = useState<number>(1);
   const [needUpdate, setNeedUpdate] = useState<boolean>(true);
   const form = useForm();
   const [filters, setFilters] = useState<any>();
   const submitFilters = () => {
      setFilters(form.getValues());
      setNeedUpdate(true);
   };

   function openMoreItems() {
      setSelectedPage((prevState) => prevState + 1);
   }

   const updateState = () => {
      if (search_user_id) {
         commonState.location = routes.lk.admin.activation;
      }
      setNeedUpdate(true);
   };

   const getTeachersUseQueryConfig = {
      method: () =>
         search_user_id
            ? userApi.getUser({
                 user_id: String(search_user_id),
              })
            : userApi.getUsers({
                 limit: `10`,
                 offset: `${10 * (selectedPage - 1)}`,
                 user_name: filters?.name,
                 type: "inactive_teacher",
                 is_banned: false,
              }),
      serializer: (res: any) => {
         if (search_user_id) {
            if ((res.data?.data as teacherType).type != "inactive_teacher") {
               updateState();
            }
            return {
               items: [res?.data.data] as Array<teacherType>,
               items_count: 1,
            };
         } else {
            return {
               items: res?.data.data.items as Array<teacherType>,
               items_count: res?.data.data.items_count as number,
            };
         }
      },
   };

   const { data: teachersData, isLoading, fetch } = useQuery(
      getTeachersUseQueryConfig.method,
      {
         deps: [selectedPage, needUpdate, search_user_id, filters],
         condition: needUpdate,
         serializer: (res) => {
            setNeedUpdate(false);
            return getTeachersUseQueryConfig.serializer(res);
         },
      }
   );

   useEffect(() => {
      setNeedUpdate(true);
   }, [search_user_id]);

   console.log(filters);

   return (
      <div className={styles.container}>
         <PageTitle
            urlToBack={routes.lk.admin.root}
            title={t("Lk.AdminPanel.page-title", "Панель админа")}
            support={!search_user_id ? teachersData?.items_count : 1}
         />
         <AdminTableFilters
            control={form.control}
            setValue={form.setValue}
            submitFilters={submitFilters}
            hide={["type"]}
         />
         {window.innerWidth > 719 && (
            <>
               {!isLoading && teachersData ? (
                  <AdminTable
                     updateState={updateState}
                     data={teachersData?.items?.filter(
                        (item: any, index: number) =>
                           index < selectedPage * 10 &&
                           index >= (selectedPage - 1) * 10
                     )}
                  />
               ) : (
                  <div className={styles.loadingBlock}>
                     {Array(
                        teachersData?.items_count
                           ? teachersData.items_count
                           : 10
                     )
                        .fill("")
                        .map((_) => (
                           <div className={styles.loadingItem} />
                        ))}
                  </div>
               )}
               {teachersData?.items_count > 10 && (
                  <div className={styles.paginate}>
                     <Paginate
                        pageCount={Math.ceil(teachersData.items_count / 10)}
                        onPageActive={(selectedItem) => {
                           setNeedUpdate(true);
                           setSelectedPage(selectedItem.selected + 1);
                        }}
                        onPageChange={(selectedItem) => {
                           setNeedUpdate(true);
                           setSelectedPage(selectedItem.selected + 1);
                        }}
                     />
                  </div>
               )}{" "}
            </>
         )}
         {window.innerWidth <= 719 && (
            <>
               {teachersData && (
                  <AdminTable
                     updateState={updateState}
                     data={teachersData?.items.filter(
                        (item: any, index: number) => index < selectedPage * 10
                     )}
                  />
               )}
               {teachersData?.items_count > 10 &&
                  selectedPage * 10 < teachersData.items_count && (
                     <Button
                        className={styles.openMore}
                        onClick={openMoreItems}
                        variant={"secondary"}
                     >
                        {t(
                           "Lk.AdminPanel.TeacherActivation.show-more",
                           "Показать еще"
                        )}
                     </Button>
                  )}
            </>
         )}
      </div>
   );
};
