import React, { FC, useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LkStudent } from "./LkStudent";
import { LkTeacher } from "./LkTeacher";
import { Profile } from "./Profile";
import { useQuery } from "hooks/useQuery";
import { userApi } from "api/userApi/methods";
import { useTranslation } from "react-i18next";
import styles from "./index.module.css";
import { teacherType } from "types/userType";
import { TitleSwitcher } from "components/Lk/Pages/Notebook/components/NoteHistory/components/TitleSwitcher";
import { PageTitle } from "components/UI/PageTitle";
import { useMedia } from "hooks/useMedia";
import { ProfileCardMobile } from "components/Lk/Pages/LkProfile/components/ProfileCardMobile";
import { Preloader } from "components/UI/Preloader";
import { lkSliceSelectors } from "store/lkSlice";
import { MainUserDataSettings } from "components/Lk/Pages/Settings/ProfileSettings/components/MainUserDataSettings";
import { EditProfile } from "components/Lk/Pages/LkProfile/Profile/Edit";
import { useGetUserAvatar } from "hooks/useGetUserAvatar";
import {
   createModalWindow,
   modalWindowTemplateType,
   uiSlice,
} from "../../../../store/modalWindowSlice";

type LkContextType = {
   template: {
      isOwn: boolean;
      isExtended: boolean;
      tempRole: "teacher" | "student";
      defined: boolean;
   };
   setTemplate: (val: LkContextType["template"]) => void;
   profileData: teacherType;
   setIsEditing: (val: boolean) => void;
   isEditing: boolean;
   avatar?: string;
   fetch: () => void;
};

const templateInitial: LkContextType["template"] = {
   isOwn: false,
   isExtended: false,
   tempRole: "student",
   defined: false,
};
const LkContext = React.createContext<LkContextType>({
   template: templateInitial,
   profileData: {} as teacherType,
   setTemplate: () => null,
   setIsEditing: () => null,
   isEditing: false,
   fetch: () => null,
});
export const useLkContext = () => useContext(LkContext);

export const LkProfile: FC = () => {
   const { t } = useTranslation();
   const clientUser = useSelector(lkSliceSelectors.getUserData);
   const params = useParams<{ id: string }>();
   const [template, setTemplate] = useState<LkContextType["template"]>({
      ...templateInitial,
   });
   const dispatch = useDispatch();
   const [isEditing, setIsEditing] = useState(false);
   const isOwn = clientUser.id == params.id;
   const { pathname } = useLocation();
   const { width } = useMedia();
   const [isDisplaySlotsWarning, setIsDisplaySlotsWarning] = useState(true);
   /* fetch user */
   const { data: user, setData: setUserData, fetch } = useQuery(
      () =>
         userApi.getUser({
            user_id: params.id,
         }),
      {
         serializer: (data) => {
            return data?.data.data as teacherType;
         },
         /**
          * fetch only if clientUser exists in store and page is not own
          **/
         condition: clientUser.type != undefined && !isOwn,
         deps: [params.id, clientUser.type],
      }
   );

   /**
    * define actual user by isOwnFlag
    **/
   const resolvedUser = isOwn ? clientUser : user;

   const [avatar, refetchAvatar] = useGetUserAvatar(resolvedUser?.id);

   /* methods */
   const defineTemplate = (): LkContextType["template"] => {
      let template: LkContextType["template"] = {
         isOwn: false,
         isExtended: false,
         tempRole: "student",
         defined: true,
      };
      if (clientUser.id == params.id) {
         template.isOwn = true;
      }
      if (resolvedUser.type == "teacher" && !resolvedUser.is_blocked) {
         template.isExtended = true;
         template.tempRole = "teacher";
      }

      return template;
   };
   const switchTemplate = (val: boolean) => {
      setTemplate({ ...template, tempRole: val ? "student" : "teacher" });
   };

   /* effects */
   useEffect(() => {
      if (user || isOwn) {
         setTemplate(defineTemplate());
      }
   }, [pathname, user]);

   // clear
   useEffect(() => {
      return () => {
         setUserData(undefined);
         setTemplate({ ...templateInitial });
      };
   }, [params.id]);

   const onChangeProfileData = () => {
      setIsEditing(false);
      fetch();
   };

   useEffect(() => {
      setIsEditing(false);
   }, [user?.id]);

   /* VIEW */
   if (!user && !isOwn)
      return <Preloader className={styles.preloader} loading={true} />;

   const refetch = () => {
      fetch();
      refetchAvatar();
   };

   return (
      <LkContext.Provider
         value={{
            template,
            setTemplate,
            profileData: resolvedUser,
            setIsEditing,
            isEditing,
            avatar,
            fetch: refetch,
         }}
      >
         <div className={styles.container}>
            {width > 719 ? (
               <Profile />
            ) : (
               <ProfileCardMobile
                  avatar={avatar}
                  template={template}
                  user={resolvedUser}
               />
            )}
            <div className={styles.body}>
               <PageTitle
                  className={styles.page_title}
                  title={
                     isEditing
                        ? t("Lk.Header.edit", "Редактирование информации")
                        : t("Lk.Header.profile", "Профиль")
                  }
               />
               {isEditing ? (
                  <EditProfile user={user} />
               ) : (
                  <>
                     {template.isOwn && template.isExtended && (
                        <TitleSwitcher
                           labels={[
                              t(
                                 "Lk.LkProfile.RoleSwitcher.teacher-button",
                                 "Преподаватель"
                              ),
                              t(
                                 "Lk.LkProfile.RoleSwitcher.student-button",
                                 "Студент"
                              ),
                           ]}
                           className={styles.role_switcher}
                           setSelect={switchTemplate}
                           selected={template.tempRole == "student"}
                        />
                     )}
                     {!template.isOwn && template.isExtended && (
                        <div className={styles.info_title}>
                           {t(
                              "Lk.LkProfile.title-teacher",
                              "Информация о преподавателе"
                           )}
                        </div>
                     )}
                     {!template.isOwn && !template.isExtended && (
                        <div className={styles.title}>
                           {t(
                              "Lk.LkProfile.title-student",
                              "Информация о студенте"
                           )}
                        </div>
                     )}
                     <main className={styles.main}>
                        {template.isExtended ? <LkTeacher /> : <LkStudent />}
                     </main>
                  </>
               )}
            </div>
         </div>
      </LkContext.Provider>
   );
};
