import React, { FC, useState } from "react";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { userApi } from "api/userApi/methods";
import { routes } from "routes";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { notificationSlice } from "store/notificationSlice";

import { notificationPropsType } from "components/Lk/components/Notifications/types";
import { Preloader } from "components/UI/Preloader";
import { lkSliceSelectors } from "store/lkSlice";
import { lessonThunk } from "store/lessonSlice/thunk";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";

type propsType = {
   notification: notificationPropsType;
   isPush: boolean;
   entity_id?: number;
};

export const NotificationCardButtons_Suggest: FC<propsType> = (props) => {
   const commonState = useLocalCommonState();
   const { notification, isPush, entity_id } = props;
   const user = useSelector(lkSliceSelectors.getUserData);

   const { t } = useTranslation();

   const history = useHistory();
   const dispatch = useDispatch();

   function toOpenNotification() {
      hideNotification();
      history.push(
         routes.lk.lesson.application.view + "/" + notification.entity.id
      );
   }

   const createLessonFromApplication = () => {
      commonState.dispatch(
         lessonThunk.createLessonFromApplication({
            commonState,
            application_id: String(notification.entity.application?.id),
            suggestion_id: String(notification.entity.id),
            callbacks: {
               201: (res) => {
                  commonState.location =
                     routes.lk.lesson.default.view + `/${res?.data.data.id}`;
                  hideNotification();
               },
               404: () => {
                  hideNotification();
               },
            },
         })
      );
   };

   const deleteSuggestion = () => {
      commonState.dispatch(
         lessonThunk.deleteSuggestion({
            commonState,
            application_id: String(notification.entity.application?.id),
            suggestion_id: String(notification.entity.id),
            callbacks: {
               204: () => {
                  hideNotification();
               },
               404: () => {
                  hideNotification();
               },
            },
         })
      );
   };

   function hideNotification() {
      commonState.loading = true;
      userApi
         .changeNotificationStatus({
            notification_ids: [notification.id],
         })
         .then((response) => {
            dispatch(
               notificationSlice.actions.setStatusToReadNotification(
                  notification.id
               )
            );
            commonState.loading = false;
         });
   }

   return (
      <div className={styles.container}>
         <button
            disabled={commonState.loading}
            onClick={createLessonFromApplication}
            className={styles.button}
            style={{ color: "#2D49FE" }}
         >
            {t("Lk.Notifications.buttons.accept", "Принять")}
         </button>
         <button
            disabled={commonState.loading}
            onClick={deleteSuggestion}
            className={styles.button}
            style={{ color: "#F44A68" }}
         >
            {t("Lk.Notifications.buttons.reject", "Отклонить")}
         </button>

         {commonState.loading ? (
            <Preloader
               style={{ height: "14px", width: "14px" }}
               loading={true}
            />
         ) : (
            <button onClick={toOpenNotification} className={styles.button}>
               {t("Lk.Notifications.buttons.more", "Подробнее")}
            </button>
         )}
      </div>
   );
};
