import React, { FC } from "react";
import styles from "./index.module.css";
import Image1 from "./assets/Image1.png";
import Image2 from "./assets/Image2.png";

import { ReactComponent as ButtonBorder } from "./assets/Button.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routes } from "routes";
import { useSelector } from "react-redux";
import { authSliceSelectors } from "store/authSlice";
import { lkSliceSelectors } from "store/lkSlice";

type propsType = {};
export const HowWeWork: FC<propsType> = (props) => {
   const token = useSelector(authSliceSelectors.getToken);
   const { type, id: user_id } = useSelector(lkSliceSelectors.getUserData);
   const { t } = useTranslation();
   return (
      <div className={styles.container}>
         <div className={styles.main_header} id='how-we-work'>
            {t("Landings.HowWeWork.header", "Как мы работаем?")}
         </div>
         <div className={styles.section1}>
            <div className={styles.section1_header}>
               {t("Landings.HowWeWork.section1__header", "Для студентов")}
            </div>
            <div className={styles.section1_description_container}>
               <img src={Image1} className={styles.image} />
               <div className={styles.section1_description}>
                  <p className={styles.section1_title1}>
                     {t(
                        "Landings.HowWeWork.section1__title1",
                        "Они меняют мир к лучшему"
                     )}
                  </p>
                  <p className={styles.section1_title2}>
                     {t(
                        "Landings.HowWeWork.section1__title2",
                        "Преподавательский состав Школы насчитывает более 400 талантливых сотрудников — это иностранные педагоги и российские специалисты, которые ведут активную профессиональную практику и следят за актуальными мировыми трендами"
                     )}
                  </p>
                  <Link
                     to={
                        token
                           ? routes.lk.profile + `/${user_id}`
                           : routes.registration.auth.firstStage
                     }
                  >
                     <button className={styles.section1_button}>
                        <ButtonBorder
                           className={styles.section1_button_image}
                        />
                        {t("Landings.HowWeWork.section1-button", "Вникнуть")}
                     </button>
                  </Link>
               </div>
            </div>
         </div>
         <div className={styles.section2}>
            <div className={styles.section2_header}>
               {t("Landings.HowWeWork.section2__header", "Для преподавателей")}
            </div>
            <div className={styles.section2_description_container}>
               <div className={styles.section2_description}>
                  <p className={styles.section1_title1}>
                     {t(
                        "Landings.HowWeWork.section1__title1-second",
                        "Они меняют мир к лучшему"
                     )}
                  </p>
                  <p className={styles.section1_title2}>
                     {t(
                        "Landings.HowWeWork.section1__title2-second",
                        "Преподавательский состав Школы насчитывает более 400 талантливых сотрудников — это иностранные педагоги и российские специалисты, которые ведут активную профессиональную практику и следят за актуальными мировыми трендами"
                     )}
                  </p>
               </div>
               <img src={Image2} className={styles.image} />
            </div>
         </div>
      </div>
   );
};
