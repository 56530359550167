// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__2g0Oo {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  font-size: 12px;\n  font-family: var(--font-Manrope);\n  margin-bottom: 6px;\n}\n\n.style_left__DhKmq {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.style_title__11ZUB {\n  font-weight: 500;\n}\n\n.style_icon__2lXgs {\n  width: 13px;\n  margin-right: 5px;\n}", "",{"version":3,"sources":["webpack://src/components/Lk/components/Notifications/components/Header/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe;EACf,gCAAgC;EAChC,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  font-size: 12px;\n  font-family: var(--font-Manrope);\n  margin-bottom: 6px;\n}\n\n.left {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.title {\n  font-weight: 500;\n}\n\n.icon {\n  width: 13px;\n  margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__2g0Oo",
	"left": "style_left__DhKmq",
	"title": "style_title__11ZUB",
	"icon": "style_icon__2lXgs"
};
export default ___CSS_LOADER_EXPORT___;
