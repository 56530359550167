import styles from "./index.module.css";

export const PrivacyPolicy = () => {
   return (
      <div className={styles.container}>
         <h3>Privacy Policy</h3>
         <p>
            E-Tutor International, a company registered in Estonia (No. 16788028), has created this
            Privacy Statement ("Statement") to demonstrate our firm commitment to the privacy of the
            data you provide us when using this website.
            This policy applies in cases where we act as the data controller with respect to the personal
            data of our website visitors and service users; in other words, when we determine the
            purposes and means of processing those personal data.
         </p>
         <p>
            Unless otherwise stated, all definitions in the Terms apply to this policy. In this Policy,
            references to "You" and "Your" are references to the User, such as www.e-
            tutorinternational.com (the Site), whether you are a Client, Tutor, or other user. By using the Site,
            you agree to the rules set out in this Policy. This relates to information we collect about
            Users of our Site, and, among other things, this Policy describes what personal information
            "Personal Information" we collect from you, how and when it is used, how we protect it, and
            who has access to it. This Statement includes our Policy regarding cookies.
         </p>
         <p>
            E-Tutor International recognizes the importance of privacy and the protection of personal
            information. This privacy policy explains how we use and protect the personal information we
            hold, whether it concerns our students, parents, teachers, potential teachers, their
            employees, consultants, and third-party service providers in connection with our business,
            and was developed in accordance with the Estonian Data Protection Act 2018 and the
            General Data Protection Regulation (GDPR) of the EU.
         </p>
         <p>
            We may from time to time change this Privacy Policy. We may provide you with additional
            notice (for example, by adding a statement to the homepage of our websites or sending you
            a notification via email). We encourage you to review the Privacy Policy each time you
            interact with us to stay informed about our information practices and the ways you can help
            protect your privacy.
         </p>
         <p>
            <h3>WHAT IS PERSONAL INFORMATION</h3>
            Personal information means information about an identifiable individual or information that
            allows an individual to be identified. It does not include information about business contacts,
            such as name, title, business address, and business phone number, when it is used for
            business communication.
         </p>
         <p>
            <h3>OUR OBLIGATIONS</h3>
            E-Tutor International takes responsibility for maintaining and protecting personal information
            under our control. We have appointed a Data Controller, with whom users can directly
            contact at sup@e-tutorinternational.com. Our data protection officer is responsible for the
            daily compliance with the requirements. We regularly review this policy and our methods of
            personal data protection to ensure compliance with applicable law and current best
            practices.
         </p>
         <p>
            <h3>HOW WE COLLECT, USE, AND DISCLOSE PERSONAL INFORMATION</h3>
            We collect and process personal information only if it is necessary to achieve the purposes
            we have defined. We do not collect and store personal information indiscriminately and wil
            delete all provided information within 6 years after the termination of use of our services,
            except for information required for tax and regulatory purposes for a longer limitation period.
         </p>
         <p>
            E-Tutor International typically collects personal information voluntarily provided by the
            relevant person. However, sometimes personal information is obtained from other sources,
            such as government agencies or third parties, such as employers, reference materials, and
            service providers, as permitted by law. If the purpose of collecting personal information is
            unclear, you may ask the E-Tutor International representative you are dealing with to explain
            the purpose of the data collection. Generally, we wil be happy to provide you with all the
            personal information we have upon written request at sup@e-tutorinternational.com
         </p>
         <p>
            The purpose of collecting personal information about tutors, students, parents, employees,
            and consultants is to establish and maintain our contractual or other business relationships
            with that person.
         </p>
         <p>
            The primary purpose of collecting personal information about tutors and students is to
            facilitate the provision of online educational tutoring services, including giving students the
            opportunity to quickly and easily assess the professional and educational level of tutors, as
            well as providing us with the means to effectively communicate with tutors, students, and
            parents, and to maintain mailing lists. We may also use your personal information to:
         </p>
         <p>
            Respond to your comments, questions, and requests and provide customer service.
            Communicate with you about services, offers, and promotions, as well as provide news and
            information we believe wil be of interest to you. We wil request separate consent for
            marketing and promotional emails as required by GDPR regulation. Manage your account
            and send you technical notifications, updates, security alerts, and administrative support and
            administration messages. Track and analyze trends, usage, and activities.
         </p>
      </div>
   );
};
