import React, { FC } from "react";
import styles from "./style.module.css";
import { ReactComponent as ClockIcon } from "./assets/Clock.svg";
import { format } from "date-fns";
import cn from "classnames";
import { useMedia } from "hooks/useMedia";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";

type propsType = {
   variant:
      | "slot"
      | "teacher_proved"
      | "student_proved"
      | "student_unproved"
      | "teacher_invite"
      | "teacher_open"
      | "student_open"
      | string;
   dateFrom: string;
   dateTo: string;
   length: number;
   calendarType: string;
};
export const ScheduleTicketTypes: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const statusTitleHandler = () => {
      if (props.length <= 1) {
         return (
            <p className={styles.title}>
               {moment(props.dateFrom).format("HH:mm") +
                  "-" +
                  moment(props.dateTo).format("HH:mm")}
            </p>
         );
      } else if (props.variant != "slot") {
         if (props.variant.includes("invite")) {
            return (
               <p className={styles.title}>
                  {t(
                     "Lk.ScheduleFilter.filter.teacher-unproved",
                     "Неподтвержденные занятия"
                  )}
               </p>
            );
         } else {
            return (
               <p className={styles.title}>
                  {t(
                     "Lk.ScheduleFilter.filter.teacher-proved",
                     "Подтвержденные занятия"
                  )}
               </p>
            );
         }
      } else {
         return (
            <p className={styles.title}>
               {t("Lk.ScheduleFilter.filter.slots", "Слотов")}
            </p>
         );
      }
   };
   const { width } = useMedia();
   return (
      <div className={styles.container}>
         {
            //вывод в календарь
            props.calendarType == (width > 1439 ? "fullscreen" : "sidebar") ? (
               <>
                  {props.length > 1 && (
                     <p className={styles.counter}>{props.length}×</p>
                  )}
                  <ClockIcon
                     className={cn({
                        [styles.icon_default]: true,
                        [styles.icon_st_lesson]:
                           props.variant == "student_proved" ||
                           props.variant == "student_open",
                        [styles.icon_st_invite]:
                           props.variant == "student_unproved",
                        [styles.icon_tch_lesson]:
                           props.variant == "teacher_proved" ||
                           props.variant == "teacher_open",
                        [styles.icon_tch_invite]:
                           props.variant == "teacher_invite",
                     })}
                  />
                  {statusTitleHandler()}
               </>
            ) : (
               //вывод в сайдбар
               <div
                  className={cn({
                     [styles.dot]: true,
                     [styles.dot_st_lesson]:
                        props.variant == "student_proved" ||
                        props.variant == "student_open",
                     [styles.dot_tch_lesson]:
                        props.variant == "teacher_proved" ||
                        props.variant == "teacher_open",
                     [styles.dot_st_invite]:
                        props.variant == "student_unproved",
                     [styles.dot_tch_invite]: props.variant == "teacher_invite",
                  })}
               />
            )
         }
      </div>
   );
};
