import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { educationSystemType, levelType } from "./types";
import { subjectType } from "../types";
import { StateType } from "store/index";
//@TODO refactoring
const initialState: {
   level: Array<levelType>;
   educationSystem: Array<educationSystemType>;
   subjects: Array<subjectType>;
} = {
   educationSystem: [],
   level: [],
   subjects: [],
};

export const formDataSlice = createSlice({
   name: "@formDataSlice",
   initialState,
   reducers: {
      setSubjects: (state, { payload }: PayloadAction<Array<any>>) => {
         state.subjects = payload;
      },
   },
});

export const formDataSliceSelectors = {
   getSubjects: (state: StateType) => state.formDataSlice.subjects,
};
