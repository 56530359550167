import React, { FC, useRef } from "react";
import styles from "./style.module.css";
import { NotificationInviteCard } from "./variants/NotificationInviteCard";
import { NotificationLessonCard } from "./variants/NotificationLessonCard";
import { NotificationGroupLessonCard } from "./variants/NotificationGroupLessonCard";
import { NotificationStatusCard } from "./variants/NotificationStatusCard";
import { notificationEntityType, notificationPropsType } from "./types";
import { DateGroupTitle } from "./components/DateGroupTitle";
import { NotificationSuggestionCard } from "components/Lk/components/Notifications/variants/NotificationSuggestionCard";
import { NotificationChatCard } from "./variants/NotificationChatCard";
import { useMedia } from "hooks/useMedia";

type propsType = {
   notificationsData: Array<notificationPropsType>;
   splitByDate?: boolean;
};

const notificationCardResolver: {
   [key in notificationEntityType]?: FC<any>;
} = {
   invite: NotificationInviteCard,
   lesson: NotificationLessonCard,
   group_lesson: NotificationGroupLessonCard,
   suggestion: NotificationSuggestionCard,
   chat: NotificationChatCard,
   message: NotificationChatCard,
   user: NotificationStatusCard,
};

const notificationsGroupHandler = (
   notifications: Array<notificationPropsType>
) => {
   let groupsByDate: {
      [key: string]: Array<notificationPropsType>;
   } = {};
   notifications.map((item) => {
      let date = item.date.slice(0, 10);
      if (!groupsByDate[date]) {
         groupsByDate[date] = [];
      }
      groupsByDate[date].push({
         ...item,
      });
   });
   return groupsByDate;
};

export const Notifications: FC<propsType> = ({
   notificationsData,
   splitByDate = true,
}) => {
   const renderCards = (notifications: Array<notificationPropsType>) => {
      return notifications?.map((notification) => {
         if (notificationCardResolver[notification.entity_type] != undefined) {
            return React.createElement(
               //@ts-ignore
               notificationCardResolver[notification.entity_type],
               {
                  notification: {
                     ...notification,
                  },
                  isPush: splitByDate,
                  key: notification.id,
               }
            );
         } else {
            return null;
         }
      });
   };

   const displayRenderedCards = () => {
      const groupsByDate = notificationsGroupHandler(notificationsData);
      let renderedCards = [];
      for (let keyByDate in groupsByDate) {
         renderedCards.push(
            <div>
               <DateGroupTitle keyByDate={keyByDate} />
               {renderCards(groupsByDate[keyByDate])}
            </div>
         );
      }
      return renderedCards;
   };

   const { width } = useMedia();
   const ref = useRef<HTMLDivElement>(null);
   return (
      <div className={styles.container} ref={ref}>
         {!splitByDate
            ? renderCards(notificationsData)
            : displayRenderedCards()}
      </div>
   );
};
