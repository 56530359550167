import React, { FC, useState } from "react";
import styles from "./index.module.css";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { routes } from "../../../../../../routes";
import { ReactComponent as ArrowIcon } from "../../assets/arrow.svg";
import { ReactComponent as ArrowDropdown } from "../../assets/arrow_dropdown.svg";
// import { Table } from "./components/Table";
import { Button } from "../../../../../UI/Button";
import { useGetPrice } from "hooks/useGetPrice";
import {useFieldArray, useForm } from "react-hook-form";
import { RateInput } from "components/UI/Input/RateInput";
import { Select } from "components/UI/SelectNew";
import { SelectItemType } from "components/UI/Select/types";
import axios from "axios";
import { lkApi } from "api/LkApi/methods";
import { Preloader } from "components/UI/Preloader";
import { useMedia } from "hooks/useMedia";
import mobileStyles from "./mobile.module.css";
import { getFirstErrorFromResponse } from "helpers/errors";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';



const deepEqual = function (x:any, y:any) {
    if (x === y) {
        return true;
    }
    else if ((typeof x == "object" && x != null) && (typeof y == "object" && y != null)) {
        if (Object.keys(x).length != Object.keys(y).length)
            return false;

        for (let prop in x) {
            if (y.hasOwnProperty(prop))
            {
                if (! deepEqual(x[prop], y[prop]))
                    return false;
            }
            else
                return false;
        }

        return true;
    }
    else
        return false;
}

const transformTableData = (data: any) =>
    data?.map((item: any) => ({
    ...item,
    currency: {
        id: item.id,
        text: item.currency,
        value: item.currency
    }
}));

function transformStringToCurrency(stringValue: string, id: number) {
    return  [
        {
            id,
            text: stringValue,
            value: stringValue
        }
    ];
}



const defualtCurrencyOprtions: Array<SelectItemType<
   | "RUR (EUR/RUR/USD/GBP)"
   | "EUR (EUR/RUR/USD/GBP)"
   | "USD (EUR/RUR/USD/GBP)"
   | "GBP (EUR/RUR/USD/GBP)"
>> = [
    {
        id: 0,
        text: "EUR",
        value: "EUR (EUR/RUR/USD/GBP)"
    },
    {
        id: 1,
        text: "USD",
        value: "USD (EUR/RUR/USD/GBP)"
    },
    {
        id: 2,
        text: "GBP",
        value: "GBP (EUR/RUR/USD/GBP)"
    }
]

const Table = ({tableData}: { tableData: Array<any> }) => {

    const [isLoading, setIsLoading] = useState(false)
    const { t } = useTranslation();
    const { width } = useMedia();
    // wont be changed
    const [transformedTableData] = useState<Array<any>>(transformTableData(tableData))
    const form = useForm({
        defaultValues: {
            array: transformedTableData
        },
    })
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control: form.control,
        name: "array",
    });


    const [errorMap, setErrorMap] = useState<Record<number, string>>({});

    const handleChangeItemsData = async (changedItems: Array<any>) => {

        const newErrorMap: Record<number, string> = {};

        const promises = changedItems.map(async (item, index) => {
            try {
                const response = await lkApi.updatePrice({
                    id: item?.id,
                    price: Number(item?.price),
                    currency: item?.currency.text,
                });

                if (response.status === 400) {
                    const firstError = getFirstErrorFromResponse(response);
                    newErrorMap[item.id] = firstError;
                } else {
                    form.setValue(`array.${index}.price`, response?.data?.data?.price);
                }
            } catch (error: any) {
                if (error.response && error.response.status === 400) {
                    const firstError = getFirstErrorFromResponse(error);
                    newErrorMap[item.id] = firstError;
                }
            }
        });

        await Promise.allSettled(promises);

        setErrorMap(newErrorMap);
    }


    const onSubmit = (data: any) => {
        const changedItems: Array<any> = [];
        transformedTableData.forEach(item => {
            const itemInsideForm = data.array.find((i: any) => i.id === item.id);
            const isChanged = !deepEqual(itemInsideForm, item)

            if (isChanged) {
                changedItems.push(itemInsideForm)
            }
        })


        setIsLoading(true)

        handleChangeItemsData(changedItems).then((data: any) => {
            setIsLoading(false)
        })
    }

    const handleInputChange = (index: number) => {
        const newErrorMap = { ...errorMap };
        delete newErrorMap[tableData[index].id];
        setErrorMap(newErrorMap);
    };


    if (isLoading) return <> <Preloader variant="large" loading={isLoading}/> </>


    return (
     <form onSubmit={form.handleSubmit(onSubmit)} className={styles.table}>
        {/*<Table data={data} />*/}
        {tableData.map((item, index) => {


            const currencyOptions= transformStringToCurrency(item?.currency, item?.id)

            if (width < 720) {

                return (
                    <div className={mobileStyles.item}>
                        <div className={mobileStyles.preview}>
                            <div className={mobileStyles.preview_header}>
                                <div className={mobileStyles.title_block}>
                                    <div className={mobileStyles.subject}>{item?.subject?.name}</div>
                                    <div className={mobileStyles.note}>{item?.subject?.education_system_name}</div>
                                </div>
                                <div className={mobileStyles.select_block}>
                                    <Select
                                        className={styles.select}
                                        variant={"blue-bg"}
                                        name={`array.${index}.currency`}
                                        setValue={form.setValue}
                                        options={defualtCurrencyOprtions}
                                        control={form.control}
                                        support={<ArrowDropdown/>}
                                        selectTitle={t("Payment.pages.SetOrChangeRate.body.currency", "Валюта")}
                                        variantTitle={"small-title"}
                                        titleColor={"black"}
                                    />
                                    <RateInput
                                        onChange={() => handleInputChange(index)}
                                        error={errorMap[item.id]}
                                        defaultValue={item?.price ? item?.price : 0}
                                        type={"number"}
                                        className={cn(styles.input, {[styles.input_error]: errorMap[item.id]})}
                                        control={form.control}
                                        name={`array.${index}.price`}
                                        placeholder={t("Payment.pages.SetOrChangeRate.body.hourly-cost", "Стоимость часа")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }


            return (
                <div className={styles.table_items}>
                    <div>
                        <div className={styles.subject}>{item?.subject?.name}</div>
                        <div className={styles.note}>{item?.subject?.education_system_name}</div>
                    </div>
                        <div className={styles.select_block}>
                            <Select
                                className={styles.select}
                                variant={"blue-bg"}
                                name={`array.${index}.currency`}
                                setValue={form.setValue}
                                options={defualtCurrencyOprtions}
                                control={form.control}
                                support={<ArrowDropdown/>}
                                selectTitle={t("Payment.pages.SetOrChangeRate.body.currency", "Валюта")}
                                variantTitle={"small-title"}
                                titleColor={"black"}
                            />
                            <div className={styles.rate_input}>
                                <RateInput
                                onChange={() => handleInputChange(index)}
                                error={errorMap[item.id]}
                                defaultValue={item?.price ? item?.price : 0}
                                type={"number"}
                                name={`array.${index}.price`}
                                className={cn(styles.input, {[styles.input_error]: errorMap[item.id]})}
                                control={form.control}
                                placeholder={t("Payment.pages.SetOrChangeRate.body.hourly-cost", "Стоимость часа")}
                                />
                            </div>
                        </div>
                </div>
            )
        })}

        <div className={styles.button__container}>
            <Button  className={styles.button}>
                {t("Buttons.save", "Сохранить")}
            </Button>
        </div>
    </form>)
}

export const SetOrChangeRate: FC = () => {
   const { t } = useTranslation();

   const { data, error, isLoading} = useGetPrice()

    const tableData = transformTableData(data)


   return (
      <div className={cn(styles.container, "LkPaymentPage")}>
         <div className={styles.title}>
            {t(
               "Payment.pages.SetOrChangeRate.title",
               "Установка или смена тарифа"
            )}
         </div>
         <div className={styles.subtitles}>
            <NavLink
               exact
               to={routes.lk.payment.root}
               activeClassName={styles.button_active}
            >
               <div className={styles.subtitles__item}>
                  <ArrowIcon className={styles.subtitles__icon}/>
                  <div className={styles.subtitles__title}>
                     {t(
                        "Payment.nav-links.lessons-payment",
                        "Оплата занятий"
                     )}
                  </div>
               </div>
            </NavLink>
            <NavLink
               exact
               to={routes.lk.payment.addCardForPayment}
               activeClassName={styles.button_active}
            >
               <div className={styles.subtitles__item}>
                  <ArrowIcon className={styles.subtitles__icon}/>
                  <div className={styles.subtitles__title}>
                     {t(
                        "Payment.nav-links.set-or-change-rate",
                        "Установка или смена тарифа"
                     )}
                  </div>
               </div>
            </NavLink>
         </div>
          {isLoading ? <Preloader variant="large" loading={isLoading}/> : <> {data && <Table tableData={data}/>} </>}
      </div>
   )
}
