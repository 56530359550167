import React, { FC, useState } from "react";
import styles from "./index.module.css";
import { ListButton } from "components/UI/ListButton";
import { studentType } from "types/userType";
import { StudentCard } from "components/Lk/Pages/LkProfile/Cards/StudentCard";
import { UserWidget } from "components/UI/UserWidget";
import { useTranslation } from "react-i18next";

type propsType = {
   students: Array<studentType>;
};
export const StudentsList: FC<propsType> = (props) => {
   const [isOpen, setIsOpen] = useState(false);
   const { t } = useTranslation();
   return (
      <div className={styles.container}>
         <ListButton
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            title={"Students"}
            counter={props.students.length}
         />
         {isOpen && (
            <div className={styles.students_list}>
               {props.students.map((student) => {
                  return <UserWidget user={student} variant={"student"} />;
               })}
               <button
                  className={styles.button}
                  onClick={() => setIsOpen(false)}
               >
                  {t("Menu.teacherButtons.collapse", "Свернуть")}
               </button>
            </div>
         )}
      </div>
   );
};
