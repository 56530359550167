import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { NotificationsSidebar } from "components/Lk/components/Notifications/NotificationsSidebar/NotificationsSidebar";
import { useLocation } from "react-router-dom";
import { headerTitlesData } from "components/Header/HeaderLkMobile/headerTitlesData";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";
import cn from "classnames";
import { routes } from "routes";

type propsType = {};
export const HeaderLkMobile: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const lang = useSelector(lkSliceSelectors.getLang);
   const location = useLocation();
   const [title, setTitle] = useState("");

   useEffect(() => {
      const headerDataObject = headerTitlesData.find((item) => {
         if (item.strict) {
            return location.pathname == item.route;
         } else {
            return location.pathname.includes(item.route);
         }
      });
      setTitle(headerDataObject?.title || "");
   }, [location, lang]);

   if (!title) return null;
   return (
      <div
         className={cn({
            [styles.container]: true,
            [styles.title_none]: [
               routes.lk.statistic.teacher,
               routes.lk.statistic.student,
            ].includes(location.pathname),
         })}
      >
         <h3 className={styles.title}>{title}</h3>
         {/*<NotificationsSidebar />*/}
      </div>
   );
};
