import React, { FC } from "react";
import styles from "./index.module.css";
import { NotificationCardHeader } from "../../components/Header";
import { NotificationCardUser } from "../../components/User";
import { NotificationCardButtons } from "../../components/Buttons";
import { notificationCardIcons } from "../../assets";
import { useTranslation } from "react-i18next";
import { notificationPropsType } from "../../types";
import { NotificationCardWrapper } from "../../components/NotificationCardWrapper";
import { useSelector } from "react-redux";
import { authSliceSelectors } from "store/authSlice";
import { NotificationCardDate } from "components/Lk/components/Notifications/components/Date";

// @TODO разбить на 2
type propsType = { notification: notificationPropsType; isPush?: boolean };
export const NotificationChatCard: FC<propsType> = (props) => {
   const userId = useSelector(authSliceSelectors.getId);
   const { t } = useTranslation();

   const { notification, isPush = false } = props;

   const headerData = (function () {
      switch (notification.notification_type) {
         case "create_admin_chat":
            return {
               icon: notificationCardIcons.message,
               text: t(
                  "Lk.Notifications.MessageCard.admin-chat-title",
                  "Чат поддержки"
               ),
            };
         case "create_abuse_message":
            return {
               icon: notificationCardIcons.report,
               text: t(
                  "Lk.Notifications.MessageCard.abuse-message",
                  "Жалоба на сообщение"
               ),
            };
         case "create_abuse_vote":
            return {
               icon: notificationCardIcons.report,
               text: t(
                  "Lk.Notifications.MessageCard.abuse-lesson",
                  "Жалоба на урок"
               ),
            };
      }
   })();

   const user =
      notification.entity_type == "message"
         ? //@ts-ignore
           notification.entity.user
         : //@ts-ignore
           notification.entity.users[0];

   return (
      <NotificationCardWrapper className={styles.container}>
         <NotificationCardHeader
            date={notification.date}
            icon={headerData?.icon}
            title={headerData?.text}
         />
         <NotificationCardUser user={user} />
         {notification.entity_type == "message" && (
            <NotificationCardDate
               disableTitle
               entity_type={notification.entity_type}
               date_to={notification.entity.date_to}
               date_from={notification.entity.date_from}
               notification_type={notification.notification_type}
            />
         )}
         <NotificationCardButtons isPush={isPush} notification={notification} />
      </NotificationCardWrapper>
   );
};
