import React, { FC, PropsWithChildren, useState } from "react";
import styles from "./style.module.css";
import { PhotoUploader } from "components/UI/PhotoUploader";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";
import moment from "moment-timezone";
import { PassInput } from "components/UI/Input/PassInput";
import { Button } from "components/UI/Button";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { lkSLiceThunk } from "store/lkSlice/thunk";
import { authThunks } from "store/authSlice/thunks";
import { Calendar } from "components/UI/Calendar";
import { defaultFileType } from "api/types";
import { modalWindowTemplateType, uiSlice } from "store/modalWindowSlice";
import { Form } from "core/LTools/core/functional components/Form";
import { Input } from "components/UI/Input";
import { dateMethods } from "helpers/date";
import {
   getDateWithoutTime,
   showDateDMY,
   showDateYMD,
} from "helpers/date/showDate";
import { useGetUserAvatar } from "hooks/useGetUserAvatar";
import { MainUserDataSettings } from "components/Lk/Pages/Settings/ProfileSettings/components/MainUserDataSettings";
import cn from "classnames";
import { Popup } from "../../../../UI/Popup";
import { authApi } from "../../../../../api/AuthApi/methods";
import { Preloader } from "../../../../UI/Preloader";
import { logout } from "../../../../../store/helpers";
import { routes } from "../../../../../routes";

export type ProfileSettingsFormType = {
   avatar: any;
   name: string;
   lastName: string;
   birthDate: Date;
   password: string;
   password_confirm: string;
   old_password: string;
};
export const ProfileSettings: FC = () => {
   const [isPopupOpen, setIsPopupOpen] = useState(false);
   const [loadingDelete, setLoadingDelete] = useState(false);

   const { t } = useTranslation();
   const user = useSelector(lkSliceSelectors.getUserData);
   const {
      control,
      handleSubmit,
      clearErrors,
      setValue,
      formState: { errors },
   } = useForm<ProfileSettingsFormType>({
      defaultValues: {
         name: user.first_name,
         lastName: user.last_name,
         birthDate: getDateWithoutTime(user.birth_date),
      },
   });
   const commonState = useLocalCommonState();
   const [avatar] = useGetUserAvatar(user.id);

   const successCaseHandler = () => {
      commonState.dispatch(
         uiSlice.actions.setModalType({
            template: "default" as modalWindowTemplateType["default"],
            payload: {
               title: t("Common.success", "Успешно"),
               button: "close",
               status: "success",
            },
         })
      );
   };

   const changePassword = (data: any) => {
      commonState.dispatch(
         authThunks.changePassword({
            commonState,
            callbacks: {
               "200": successCaseHandler,
               403: (res) => {
                  commonState.errors = {
                     ...commonState.errors,
                     old_password: res?.data?.data?.errors[0]?.message,
                  };
               },
            },
            old_password: data.old_password,
            password: data.password,
            password_confirm: data.password_confirm,
         })
      );
   };

   const handleDelete = () => {
      setLoadingDelete(true);

      authApi.deleteUser()
         .then((res) => {
            if (res.status === 204) {
               logout(commonState.dispatch);
               commonState.location = routes.landings.main;
            }
         })
         .catch()
         .finally(() => {
            setLoadingDelete(false);
            setIsPopupOpen(false);
         });
   };

   const popupData = {
      buttons: (
         <>
            {loadingDelete ? (
               <Preloader
                  className={styles.preloadSendMessage}
                  loading={true}
               />
            ) : (
               <>
                  <Button type={"button"} style={{ marginBottom: 24 }} onClick={handleDelete}>
                     {t("Buttons.yes", "Да")}
                  </Button>
                  <Button className={styles.button_delete} onClick={() => setIsPopupOpen(false)}>
                     {t("Buttons.cancel", "Отмена")}
                  </Button>
               </>
            )}
         </>
      )
   };

   return (
      <div className={styles.container}>
         <MainUserDataSettings/>
         <Form
            commonState={commonState}
            clearErrors={clearErrors}
            onSubmit={handleSubmit(changePassword)}
            errors={errors}
         >
            <div className={styles.pass_title}>
               {t("Settings.change-password.title", "Смена пароля")}
            </div>
            <div className={styles.input}>
               <PassInput
                  control={control}
                  name='old_password'
                  label={t(
                     "Settings.change-password.labels.old-password",
                     "Введите старый пароль"
                  )}
                  error={commonState?.errors?.old_password}
               />
            </div>
            <div className={styles.input}>
               <PassInput
                  control={control}
                  name='password'
                  label={t(
                     "Settings.change-password.labels.new-password",
                     "Введите новый пароль"
                  )}
                  error={
                     errors.password?.message || commonState.errors.password
                  }
               />
            </div>
            <div className={styles.input}>
               <PassInput
                  control={control}
                  name='password_confirm'
                  label={t(
                     "Settings.change-password.labels.repeat-new-password",
                     "Повторите новый пароль"
                  )}
                  error={
                     errors.password_confirm?.message ||
                     commonState.errors.password_confirm
                  }
               />
            </div>
            <div className={styles.button}>
               <Button loading={commonState.loading}>
                  {t("Buttons.save", "Сохранить")}
               </Button>
            </div>
            <div className={styles.button}>
               {user.role !== "superadmin" && (
                  <Button
                     type={"button"}
                     className={styles.button_delete}
                     loading={commonState.loading}
                     onClick={() => setIsPopupOpen(true)}
                  >
                     {t("Buttons.delete_profile", "Удалить профиль")}
                  </Button>
               )}
            </div>
            <Popup
               isOpen={isPopupOpen}
               setIsOpen={setIsPopupOpen}
               title={t("Settings.account.are-you-sure-you-want-to-delete-your-profile", "Вы точно хотите удалить?")}
               {...popupData}
            />
         </Form>
      </div>
   );
};
