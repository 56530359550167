export const routes = {
   sandbox: "/sandbox",
   privacyPolicy: "/privacy_policy",
   userAgreement: "/user_agreement",

   landings: {
      main: "/",
      filter: "/filters",
   },
   registration: {
      auth: {
         root: "/registration/auth",
         firstStage: "/registration/auth/first-stage",
         authorization: "/registration/auth/authorization",
         forgotPassword: "/registration/auth/forgot-password",
         registrationCode: "/registration/auth/code",
         createPassword: "/registration/auth/create-password",
         restorePassword: "/registration/auth/restore-password",
      },
      student: {
         root: "/registration/student",
         studentRegistration: "/registration/student/student-registration",
         registration: "/registration/student/registration",
         fillInformation: "/registration/student/fillInformation",
      },
      teacher: {
         root: "/registration/teacher",
         firstStage: "/registration/teacher/first-stage",
         studyLanguages: "/registration/teacher/study-languages",
         instructionsSystems:
            "/registration/teacher/instruction-education-systems",
         files: "/registration/teacher/files",
         done: "/registration/teacher/done",
      },
   },
   lk: {
      root: "/lk",
      messages: "/lk/messages",
      n_messages: "/lk/n_messages",
      profile: "/lk/profile",
      lkStudent: "/lk/profile/student",
      lkTeacher: "/lk/profile/teacher",
      schedule: "/lk/schedule",
      statistic: {
         root: "/lk/statistic",
         student: "/lk/statistic/student",
         teacher: "/lk/statistic/teacher",
      },
      payment: {
         root: "/lk/payment",
         transactions: "/lk/payment/transactions",
         addCardForPayout: "/lk/payment/add-card-for-payout",
         addCardForPayment: "/lk/payment/add-card-for-payment",
         setOrChangeRate: "/lk/payment/set-or-change-rate",
      },
      financialOperations: {
         root: "/lk/financial-operations",
         operations: {
            root: "/lk/financial-operations/operations",
            workingWithRegistries: "/lk/financial-operations/working-with-registries",
         }
      },
      admin: {
         root: "/lk/admin",
         activation: "/lk/admin/activation",
         users: "/lk/admin/users",
         educationSystems: {
            root: "/lk/admin/education-systems",
            addSystem: "/lk/admin/education-systems/add-system",
            addSubject: "/lk/admin/education-systems/add-subject",
            addCategory: "/lk/admin/education-systems/add-category",
         },
      },
      settings: {
         root: "/lk/settings",
         profile: "/lk/settings/profile",
         service: "/lk/settings/service",
         worksheet: "/lk/settings/worksheet",
      },
      logout: "/lk/logout",
      notebook: {
         root: "/lk/notebook",
         peoples: {
            root: "/lk/notebook/peoples",
            students: "/lk/notebook/peoples/students",
            teachers: "/lk/notebook/peoples/teachers",
         },
         history: "/lk/notebook/history",
      },
      search_lesson: {
         root: "/lk/search_lesson",
         first_stage: "/lk/search_lesson/first_stage",
         main_stage: "/lk/search_lesson/main_stage",
      },
      lesson: {
         root: "/lk/lesson",
         create_slot: "/lk/lesson/create_slot",
         sendRequest: "/lk/lesson/send-request",
         fast: {
            root: "/lk/lesson/fast",
            search: "/lk/lesson/fast/search",
            register: "/lk/lesson/fast/register",
         },
         group: {
            view: "/lk/lesson/group/view",
         },
         default: {
            view: "/lk/lesson/default/view",
         },
         invite: {
            view: "/lk/lesson/invite/view",
         },
         application: {
            view: "/lk/lesson/application/view",
         },
      },
   },
};
