import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { SearchFilters } from "../../../Landings/Main/SearchFilters";
import { routes } from "routes";
import { Route, useHistory } from "react-router-dom";
import { SearchLessonFilter } from "../../../Landings/Filter";

type propsType = {};
export const SearchLesson: FC<propsType> = (props) => {
   const history = useHistory();

   return (
      <div className={styles.container}>
         <Route path={routes.lk.search_lesson.first_stage}>
            <div className={styles.first_stage}>
               <SearchFilters
                  config={{
                     redirectUrl: routes.lk.search_lesson.main_stage,
                  }}
               />
            </div>
         </Route>
         <Route path={routes.lk.search_lesson.main_stage}>
            <SearchLessonFilter
               config={{
                  firstBreadCrumbAction: () =>
                     history.push(routes.lk.search_lesson.first_stage),
               }}
            />
         </Route>
      </div>
   );
};
