import React, { FC } from "react";
import styles from "./index.module.css";
import { useTranslation } from "react-i18next";
import { Button } from "components/UI/Button";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";
import { ContentBlockContainer } from "components/UI/ContentBlockContainer";
import { PageTitle } from "components/UI/PageTitle";
import { useMedia } from "../../../../../../hooks/useMedia";
import { getTeacherSearchFiltersElements } from "./formElements";
import { TeacherSearchFiltersType } from "../../index";
import { TeacherSearchPopupFilters } from "./TeacherSearchPopupFilters";

type propsType = {
   control: Control<any>;
   setValue: UseFormSetValue<any>;
   submit: () => void;
   reset: any;
   filters: TeacherSearchFiltersType;
   errors: any;
   setFilters: (v: any) => void;
};
export const TeacherSearchFilters: FC<propsType> = ({
   control,
   setValue,
   reset,
   submit,
   errors,
   filters,
   setFilters,
}) => {
   const { t } = useTranslation();
   const { width } = useMedia();

   const formElements = getTeacherSearchFiltersElements({
      control,
      setValue,
      t,
      filters,
      errors,
   });

   /* Tablet/Mobile filters */
   if (width < 1024) {
      return (
         <TeacherSearchPopupFilters
            submit={submit}
            setValue={setValue}
            control={control}
            filters={filters}
            setFilters={setFilters}
            errors={errors}
         />
      );
   }

   /* Desktop filters */
   return (
      <div className={styles.wrapper}>
         <div className={styles.container}>
            <PageTitle title={t("Filters.title", "Фильтры")} />
            <ContentBlockContainer
               title={t(
                  "SearchFilters.teacher-filters.lesson-date",
                  "Дата занятия"
               )}
               className={styles.info_block}
            >
               {formElements.date}
            </ContentBlockContainer>

            <ContentBlockContainer
               title={t(
                  "SearchFilters.teacher-filters.lesson-period",
                  "Период занятия"
               )}
               className={styles.info_block}
            >
               <div className={styles.period}>
                  {formElements.date_from}
                  {formElements.duration}
               </div>
            </ContentBlockContainer>

            <ContentBlockContainer
               title={t("Notebook.headers.rating", "Рейтинг")}
               className={styles.info_block}
            >
               {formElements.rating}
            </ContentBlockContainer>

            <div className={styles.mobile_button}>
               <Button onClick={submit} variant={"primary"}>
                  {t("SearchFilters.teacher-filters.apply", "Применить")}
               </Button>

               <Button
                  form={"_"}
                  onClick={reset}
                  variant={"link"}
                  style={{
                     color: "var(--color-warning)",
                     textDecoration: "none",
                     margin: "0 auto",
                  }}
               >
                  {t("SearchFilters.teacher-filters.reset", "Сбросить")}
               </Button>
            </div>
         </div>
      </div>
   );
};
