import React, {
   ButtonHTMLAttributes,
   FC,
   FunctionComponent,
   SVGProps,
} from "react";
import styles from "./style.module.css";
import cn from "classnames";

type propsType = {
   text: string;
   support: FunctionComponent<SVGProps<SVGSVGElement>>;
   hasInvisibleMessage? : boolean
} & ButtonHTMLAttributes<any>;
export const MenuButton: FC<propsType> = ({
   text,
    hasInvisibleMessage,
   support,
   children,
   ...props
}) => {
   return (
      <button
         className={cn(styles.container, {
            [`${styles["container--disabled"]}`]: props.disabled,
         })}
         {...props}
      >
         <div className={styles.support_container}>
            {React.createElement(support, { className: styles.support })}
         </div>
         <div className={styles.label}>{text}</div>
         {hasInvisibleMessage && <div className={styles.hasInvisibleMessage}/>}
      </button>
   );
};
