import React, { FC } from "react";
import { useForm, useWatch } from "react-hook-form";
import { PassInput } from "../../../UI/Input/PassInput";
import { routes } from "routes";
import { useHistory } from "react-router-dom";
import { Button } from "../../../UI/Button";
import { useSelector } from "react-redux";
import { authPasswordSchema } from "helpers/validationSchemes";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as TextCheckIcon } from "../../../../assets/inputs/TextCheck.svg";
import { authSlice, authSliceSelectors } from "store/authSlice";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { authThunks } from "store/authSlice/thunks";
import { useTranslation } from "react-i18next";
import { lkSLiceThunk } from "store/lkSlice/thunk";
import styles from "./index.module.css";
import { Form } from "core/LTools/core/functional components/Form";
import { Preloader } from "components/UI/Preloader";
import { Input } from "components/UI/Input";

type propsType = {};
export type AuthorizationPropsType = {
   email: string;
   password: string;
};
export const Authorization: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const history = useHistory();
   const {
      control,
      handleSubmit,
      formState: { errors },
      clearErrors,
   } = useForm<AuthorizationPropsType>({
      // @ts-ignore
      resolver: yupResolver(authPasswordSchema),
      reValidateMode: "onSubmit",
   });
   const email = useSelector(authSliceSelectors.getEmail);
   const id = useSelector(authSliceSelectors.getId);
   const commonState = useLocalCommonState();

   const submit = ({ password }: AuthorizationPropsType) => {
      // Получает токен
      commonState.dispatch(
         authThunks.getToken({
            commonState,
            password,
            callbacks: {
               "200": () => {
                  // После токена получает юзера и сетает его дату в лк слайс
                  commonState.dispatch(
                     lkSLiceThunk.getUser({
                        commonState,
                        needRedirect: true,
                     })
                  );
               },
            },
         })
      );
   };
   const changeAccountHandler = () => {
      history.push(routes.registration.auth.firstStage);
      //@TODO добавить в стор метод логаут
   };
   const forgotPasswordHandler = () => {
      commonState.dispatch(authSlice.actions.setIsRestoring(true));
      commonState.dispatch(
         authThunks.createCode({
            commonState,
            template: "restore",
            callbacks: {
               201: () => {
                  commonState.location =
                     routes.registration.auth.registrationCode;
               },
            },
         })
      );
   };

   const passInput = useWatch({ control, name: "password" });
   return (
      <Form
         clearErrors={clearErrors}
         commonState={commonState}
         errors={errors}
         className='flex-col'
         onSubmit={handleSubmit(submit)}
      >
         <div className={styles.inputs}>
            <Input
               control={control}
               name='email'
               label={t(
                  "Registration.Auth.Authorization.email-label",
                  "Ваш email"
               )}
               defaultValue={email}
               support={<TextCheckIcon />}
               readOnly
            />
            <PassInput
               autoFocus
               control={control}
               name='password'
               label={t(
                  "Registration.Auth.Authorization.password-label",
                  "Ваш пароль"
               )}
               error={errors.password?.message || commonState.errors.password}
            />
         </div>
         <Button
            className={styles.link_button}
            variant='link'
            form='_'
            onClick={forgotPasswordHandler}
         >
            {t(
               "Registration.Auth.Authorization.password-forgot",
               "Забыли пароль?"
            )}
         </Button>
         <Button
            variant='link'
            className={styles.link_button}
            onClick={changeAccountHandler}
            form='_'
         >
            {t(
               "Registration.Auth.Authorization.another-account",
               "Другой аккаунт?"
            )}
         </Button>
         <Button
            variant={"primary"}
            disabled={!passInput}
            className={styles.action_button}
            loading={commonState.loading}
         >
            {t("Registration.Auth.Authorization.enter-button", "Войти")}
         </Button>
      </Form>
   );
};
