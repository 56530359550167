import React, { FC, useState } from "react";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";
import { firstLetterUpperCase } from "helpers/string/firstLetterUppercase";
import { ReactComponent as FacebookIcon } from "../../assets/Facebook.svg";
import { ReactComponent as LinkdIcon } from "../../assets/Linkd.svg";
import { ReactComponent as TwitterIcon } from "../../assets/Twitter.svg";
import { ReactComponent as DocIcon } from "../../assets/Doc.svg";
import { ReactComponent as DownloadIcon } from "../../assets/Download.svg";
import { CardStats } from "../CardStats";
import cn from "classnames";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";
import { useGetUserAvatar } from "hooks/useGetUserAvatar";
import { roleType } from "types/types";
import { downloadFile } from "components/UI/FilesArea";
import { certificateApi } from "api/certificateApi";
import { Icons } from "assets/iconsCorrect";
import moment from "moment-timezone";
import { Preloader } from "components/UI/Preloader";
import { showDateDMY } from "helpers/date/showDate";

type propsType = {
   role: roleType;
};
export const StatisticCertificate: FC<propsType> = ({ role }) => {
   const { t } = useTranslation();
   const user = useSelector(lkSliceSelectors.getUserData);
   const [avatar] = useGetUserAvatar(user?.id);
   const certificate = useSelector(lkSliceSelectors.getCertificate);
   const [loading, setLoading] = useState(false);

   if (!user) return <></>;

   const download = (e: any) => {
      if (!certificate) return;
      setLoading(true);
      downloadFile(e, () =>
         certificateApi.get({
            user_id: user.id,
            certificate_id: certificate.id,
            user_type: role == "teacher" ? "teacher" : "student",
         })
      ).then(() => {
         setLoading(false);
      });
   };

   if (!certificate) return null;

   const levels = (function () {
      const levels = new Set<string>();
      user.instruction_education_systems.map((item) => {
         levels.add(item.level.name);
      });
      return Array.from(levels);
   })();

   // sharing
   const shareOnFacebook = () => {
      //@ts-ignore
      window.FB.ui(
         {
            display: "popup",
            method: "share",
            href: `http://etutor.fingineers.ru/certificate/${certificate?.id}`,
         },
         function () {}
      );
   };

   return (
      <div
         className={cn(style.certificate, {
            [style.teacher]: role === "teacher",
         })}
      >
         <div className={style.header}>
            <div className={style.header_title}>
               {t("Lk.Statistic.title-certificate", "Сертификат")}
            </div>
            <Icons.logos.logoFull />
         </div>
         <div className={style.role}>
            {role == "student"
               ? t("Lk.Statistic.role-student", "Студент")
               : t("ScheduleFilter.filter.title-student", "Преподаватель")}
         </div>
         <div className={style.user}>
            <img className={style.avatar} src={avatar} />
            <div className={style.user_name}>
               {certificate?.user?.first_name} <br />
               {certificate?.user?.last_name}
            </div>
         </div>
         <div className={style.user_info_wrapper}>
            <div className={style.label}>
               {t("Lk.Statistic.birthdate", "Дата рождения")}
            </div>
            {certificate?.user?.birth_date && (
               <div className={style.user_info_value}>
                  {showDateDMY(certificate?.user?.birth_date)}
               </div>
            )}
         </div>
         {role == "teacher" ? (
            <div className={style.directions}>
               <div className={style.user_info_wrapper}>
                  <div className={style.label}>
                     {t("Lk.Statistic.teaching-language", "Язык преподавания")}
                  </div>
                  <div className={style.user_info_value}>
                     {certificate?.user?.instruction_languages.map((item) => (
                        <span>{item.name}</span>
                     ))}
                  </div>
               </div>
               <div className={style.user_info_wrapper}>
                  <div className={style.label}>
                     {t("Lk.Statistic.grade", "Грейд")}
                  </div>
                  <div className={style.user_info_value}>
                     {levels?.map((item) => (
                        <span>{item}</span>
                     ))}
                  </div>
               </div>
               <div className={style.user_info_wrapper}>
                  <div className={style.label}>
                     {t("Lk.Statistic.study-system", "Система образования")}
                  </div>
                  <div className={style.user_info_value}>
                     {certificate?.user?.instruction_education_systems.map(
                        (item) => (
                           <span>{item.name}</span>
                        )
                     )}
                  </div>
               </div>
               <div className={style.user_info_wrapper}>
                  <div className={style.label}>
                     {t("Lk.Statistic.subjects", "Предметы")}
                  </div>
                  <div className={style.user_info_value}>
                     {certificate?.user?.instruction_subjects.map((item) => (
                        <span>{firstLetterUpperCase(item.name)}</span>
                     ))}
                  </div>
               </div>
            </div>
         ) : (
            <div className={style.directions}>
               {certificate?.user?.study_grade && (
                  <div className={style.user_info_wrapper}>
                     <div className={style.label}>
                        {t("Notebook.headers.grade", "Грейд")}
                     </div>
                     <div className={style.user_info_value}>
                        {certificate?.user?.study_grade.name}
                     </div>
                  </div>
               )}
               {user.study_education_system && (
                  <div className={style.user_info_wrapper}>
                     <div className={style.label}>
                        {t("Lk.LkStudent.system", "Система образования")}
                     </div>
                     <div className={style.user_info_value}>
                        {certificate?.user?.study_education_system.name}
                     </div>
                  </div>
               )}
            </div>
         )}

         <div className={style.user_info_wrapper + " " + style.period}>
            <div className={style.label}>
               {t("Lk.Statistic.period", "Период")}
            </div>
            <div className={style.user_info_value}>
               <Icons.ui.calendarIcon style={{ marginRight: 10 }} />
               {moment(certificate?.date_from).format("DD.MM.YYYY")} —{" "}
               {moment(certificate?.date_to).format("DD.MM.YYYY")}
            </div>
         </div>

         <CardStats isStatic role={role} />
         {user.type == "teacher" && !user.is_blocked && (
            <div className={style.footer}>
               <div>
                  <div className={style.share_title}>
                     {t("Lk.Statistic.share-label", "Поделиться результатом")}
                  </div>
                  <div className={style.share_buttons_wrapper}>
                     <button
                        className={style.share_button}
                        onClick={shareOnFacebook}
                     >
                        {React.createElement(FacebookIcon)}
                     </button>

                     <a
                        target={"_blank"}
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=http://etutor.fingineers.ru/certificate/${certificate?.id}`}
                     >
                        <button className={style.share_button}>
                           {React.createElement(LinkdIcon)}
                        </button>
                     </a>
                     <a
                        className='twitter-share-button'
                        href={`https://twitter.com/intent/tweet?text=http://etutor.fingineers.ru/certificate/${certificate?.id}`}
                        target={"_blank"}
                     >
                        <button className={style.share_button}>
                           {React.createElement(TwitterIcon)}
                        </button>
                     </a>
                  </div>
               </div>
               <div className={style.download}>
                  <div className={style.share_title}>
                     {t("Lk.Statistic.download-title", "Скачать")}
                  </div>
                  {loading ? (
                     <Preloader
                        loading={true}
                        style={{
                           margin: 0,
                           marginTop: 10,
                           width: 30,
                           height: 30,
                        }}
                     />
                  ) : (
                     <a
                        data-name='Certificate'
                        onClick={download}
                        className={style.download_link}
                     >
                        <DocIcon className={style.download_doc_icon} />
                        {t("Lk.Statistic.download-title", "Скачать")}
                        <DownloadIcon className={style.download_icon} />
                     </a>
                  )}
               </div>
            </div>
         )}
      </div>
   );
};
