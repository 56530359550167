import React, { FC, useEffect, useState } from "react";
import styles from "./style.module.css";
import { useForm, useWatch } from "react-hook-form";
import { FilesInput, onAddFileType } from "../../../UI/Input/FilesInput";
import { Button } from "../../../UI/Button";
import { useHistory } from "react-router-dom";
import { routes } from "routes";
import { CheckBox } from "../../../UI/Input/CheckBox";
import { ListButton } from "../../../UI/ListButton";
import { useTranslation } from "react-i18next";
import { userApi } from "api/userApi/methods";
import { useSelector } from "react-redux";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { lkSLiceThunk } from "store/lkSlice/thunk";
import { lkSliceSelectors } from "store/lkSlice";
import { arrayLengthWithoutEmpty } from "helpers/array";
import { PhotoUploader } from "../../../UI/PhotoUploader";
import { defaultFileType } from "api/types";
import { Preloader } from "components/UI/Preloader";
import { Form } from "core/LTools/core/functional components/Form";
import { uiSlice } from "store/modalWindowSlice";
import i18n from "services/translateService/i18n";
import { getFirstErrorFromResponse } from "helpers/errors";

type propsType = {};
type FormType = {
   passport: any;
   grades: any;
   fast: any;
   avatar: defaultFileType;
};
export const AddFiles: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const { control, handleSubmit, setValue } = useForm<FormType>({
      defaultValues: {
         fast: false,
      },
   });
   const passport = useWatch({
      control,
      name: "passport",
   });
   const avatar = useWatch({
      control,
      name: "avatar",
   });
   const grade = useWatch({
      control,
      name: "grades",
   });

   const user = useSelector(lkSliceSelectors.getUserData);
   const history = useHistory();
   const [isOpen, setIsOpen] = useState(false);
   const [isGradesOpen, setIsGradesOpen] = useState(false);
   const commonState = useLocalCommonState();
   const teacherForm = useSelector(lkSliceSelectors.getTeacherForm);
   const onSubmit = (data: any) => {
      commonState.dispatch(
         lkSLiceThunk.updateUser({
            user_id: user.id,
            commonState,
            payload: {
               last_name: user.last_name,
               first_name: user.first_name,
               birth_date: user.birth_date,
               is_ready_get_quick_invite: data.fast,
               //@ts-ignore @TODO сделать нормальный тип для апдейта юзера
               instruction_subjects: teacherForm.subjects?.map(
                  (item) => item.id
               ),
               //@ts-ignore @TODO сделать нормальный тип для апдейта юзера
               instruction_languages: teacherForm.languages.map(
                  (item) => item.id
               ),
            },
            callbacks: {
               200: () => {
                  history.push(routes.registration.teacher.done);
               },
               400: (res) => {
                  commonState.dispatch(
                     uiSlice.actions.setModalType({
                        template: "default",
                        payload: {
                           title: i18n.t("Common.errors.default", "Ошибка"),
                           button: "to_lk",
                           status: "error",
                           subtitle: getFirstErrorFromResponse(res),
                        },
                     })
                  );
               },
            },
         })
      );
   };

   /* Add files callbacks */
   const uploadPassportDocuments = (file: onAddFileType) => {
      return userApi.uploadUserFile({
         user_id: user.id,
         file_type: "passport",
         file_name: file.file_name,
         content: file.content,
      });
   };
   const uploadSchoolTranscript = (file: onAddFileType) => {
      return userApi.uploadUserFile({
         user_id: user.id,
         file_type: "school_transcript",
         file_name: file.file_name,
         content: file.content,
      });
   };
   const uploadAvatar = (file: defaultFileType) => {
      commonState.dispatch(
         lkSLiceThunk.uploadFile({
            user_id: user.id,
            commonState,
            ...file,
            file_type: "avatar",
         })
      );
   };
   useEffect(() => {
      if (avatar) {
         uploadAvatar(avatar);
      }
   }, [avatar]);

   if (!user.id) return null;
   /* view */
   const hasAvatar = user?.files?.find((item) => item.file_type == "avatar");
   return (
      <form onSubmit={handleSubmit(onSubmit)}>
         <div className={styles.title}>
            {t("Registration.TeacherPages.AddFiles.title", "Срочные уроки")}
         </div>
         <CheckBox
            name='fast'
            control={control}
            label={t(
               "Registration.TeacherPages.AddFiles.checkbox-label",
               "Принимать запросы на быстрые занятия"
            )}
         />
         <div className={styles.description}>
            {t(
               "Registration.TeacherPages.AddFiles.checkbox-note",
               "Разрешить принимать запросы на проведение быстрых занятий от учеников"
            )}
         </div>
         <div className={styles.title}>
            {t(
               "Registration.TeacherPages.AddFiles.upload.files-title",
               "Добавьте документы"
            )}
         </div>
         <div className={styles.description}>
            <p>
               {t(
                  "Registration.TeacherPages.AddFiles.upload.files",
                  "Загрузите файлы в форматах"
               )}{" "}
               <br />{" "}
               {t(
                  "Registration.TeacherPages.AddFiles.upload.file-formats",
                  "jpg, png и pdf:"
               )}
            </p>
            <p>
               1){" "}
               {t(
                  "Registration.TeacherPages.AddFiles.upload.passport",
                  "Passport / National ID"
               )}
            </p>
            <p className={styles.description_margin}>
               2){" "}
               {t(
                  "Registration.TeacherPages.AddFiles.upload.transcript",
                  "School Transcript (Grades)"
               )}
            </p>
            {t(
               "Registration.TeacherPages.AddFiles.upload.note",
               "Данный шаг необходим для получения статуса Преподавателя"
            )}
         </div>
         <ListButton
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            title={t(
               "Registration.TeacherPages.AddFiles.upload.passport",
               "Passport / National ID"
            )}
            counter={arrayLengthWithoutEmpty(passport)}
         />
         {isOpen && (
            <div className={styles.passport}>
               <FilesInput
                  setValue={setValue}
                  control={control}
                  name='passport'
                  onAddFile={uploadPassportDocuments}
                  accept={".jpg, .png, .pdf, .jpeg"}
               />
            </div>
         )}
         <ListButton
            setIsOpen={setIsGradesOpen}
            isOpen={isGradesOpen}
            title={t(
               "Registration.TeacherPages.AddFiles.upload.transcript",
               "School Transcript (Grades)"
            )}
            counter={arrayLengthWithoutEmpty(grade)}
         />
         {isGradesOpen && (
            <div className={styles.passport}>
               <FilesInput
                  setValue={setValue}
                  control={control}
                  name='grades'
                  onAddFile={uploadSchoolTranscript}
                  accept={".jpg, .png, .pdf, .jpeg"}
               />
            </div>
         )}
         {!hasAvatar && (
            <div className={styles.photo}>
               <PhotoUploader setValue={setValue} name='avatar' />
            </div>
         )}
         <Button
            className={"Registration__button"}
            variant={"primary"}
            type='submit'
            disabled={!(grade?.length && passport?.length)}
            loading={commonState.loading}
         >
            {t("Registration.continue-button", "Продолжить")}
         </Button>
      </form>
   );
};
//@TODO вынести стили для кнопки
