import { ChangeEvent, useEffect, useState } from "react";
import {
   Control,
   UseFormSetError,
   UseFormSetValue,
   useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

type useSelectType<T> = {
   name: string;
   setValue: UseFormSetValue<any>;
   control: Control<any>;
   onSelect?: (v: { id: number; test: string; value: T }) => void;
   setError?: any;
};
export function useSelect<T>({
   name: selectName,
   setValue,
   control,
   onSelect,
   setError,
}: useSelectType<T>) {
   const [focus, setFocus] = useState(false);
   const inputName = selectName + "_input";
   const form = useWatch({
      control,
   });
   const selected = form[`${selectName}`];
   const inputValue = form[`${inputName}`];

   /* delete junk from input */
   useEffect(() => {
      if (!focus) {
         setValue(inputName, selected?.text || "");
      }
   }, [focus]);

   const setSelectHandler = (v: any) => {
      setValue(selectName, v);
      setFocus(false);
      onSelect && onSelect(v);
      setError && setError(selectName, {});
   };

   const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      setValue(inputName, e.target.value);
   };

   return {
      focus,
      setFocus,
      inputValue,
      onChange,
      selected,
      setSelectHandler,
   };
}
