// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Authorization_inputs__1tjKl {\n    width: 100%;\n    margin-bottom: 20px;\n}\n\n.Authorization_link_button__2K00P {\n    margin-bottom: 20px;\n}\n\n.Authorization_action_button__21nX1 {\n    margin-top: 40px;\n}\n\n@media screen and (max-width: 719px) {\n    .Authorization_link_button__2K00P {\n        width: 100%;\n        text-align: center;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/Registration/Auth/Authorization/index.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,WAAW;QACX,kBAAkB;IACtB;AACJ","sourcesContent":[".inputs {\n    width: 100%;\n    margin-bottom: 20px;\n}\n\n.link_button {\n    margin-bottom: 20px;\n}\n\n.action_button {\n    margin-top: 40px;\n}\n\n@media screen and (max-width: 719px) {\n    .link_button {\n        width: 100%;\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputs": "Authorization_inputs__1tjKl",
	"link_button": "Authorization_link_button__2K00P",
	"action_button": "Authorization_action_button__21nX1"
};
export default ___CSS_LOADER_EXPORT___;
