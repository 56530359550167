// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FilesArea_container__2zDFA {\n    max-width: 320px;\n}\n\n.FilesArea_file__2Ih4L {\n    display: flex;\n    align-items: center;\n}\n\n.FilesArea_file__document_icon__2Swbz {\n    margin-right: 8px;\n    flex-shrink: 0;\n}\n\n.FilesArea_file__text__1N4KW {\n    margin-right: 8px;\n    max-width: 240px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n.FilesArea_file__download__y_Djq {\n    flex-shrink: 0;\n    cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://src/components/UI/FilesArea/index.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,eAAe;AACnB","sourcesContent":[".container {\n    max-width: 320px;\n}\n\n.file {\n    display: flex;\n    align-items: center;\n}\n\n.file__document_icon {\n    margin-right: 8px;\n    flex-shrink: 0;\n}\n\n.file__text {\n    margin-right: 8px;\n    max-width: 240px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n.file__download {\n    flex-shrink: 0;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FilesArea_container__2zDFA",
	"file": "FilesArea_file__2Ih4L",
	"file__document_icon": "FilesArea_file__document_icon__2Swbz",
	"file__text": "FilesArea_file__text__1N4KW",
	"file__download": "FilesArea_file__download__y_Djq"
};
export default ___CSS_LOADER_EXPORT___;
