// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__2MPgn {\n  font-family: var(--font-Source-Sans-Pro);\n  font-size: 14px;\n  margin-bottom: 10px;\n}\n\n.style_title__17JD9 {\n  font-weight: 600;\n  line-height: 18px;\n  margin-bottom: 4px;\n}\n\n.style_text__3vLfP {\n  line-height: 18px;\n}", "",{"version":3,"sources":["webpack://src/components/Lk/components/Notifications/components/Message/style.module.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".container {\n  font-family: var(--font-Source-Sans-Pro);\n  font-size: 14px;\n  margin-bottom: 10px;\n}\n\n.title {\n  font-weight: 600;\n  line-height: 18px;\n  margin-bottom: 4px;\n}\n\n.text {\n  line-height: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__2MPgn",
	"title": "style_title__17JD9",
	"text": "style_text__3vLfP"
};
export default ___CSS_LOADER_EXPORT___;
