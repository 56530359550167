import * as React from "react";

export function PassVis(props: React.SVGProps<SVGSVGElement>) {
   return (
      <svg
         width={16}
         height={14}
         viewBox='0 0 16 12'
         fill='none'
         xmlns='http://www.w3.org/2000/svg'
         {...props}
      >
         <path
            d='M8 .676c-3.333 0-6.18 2.073-7.333 5 1.153 2.926 4 5 7.333 5a7.881 7.881 0 007.333-5A7.881 7.881 0 008 .676zm0 8.333a3.335 3.335 0 010-6.667A3.335 3.335 0 018 9.01zm0-5.333c-1.104 0-2 .896-2 2 0 1.103.896 2 2 2 1.103 0 2-.897 2-2 0-1.104-.897-2-2-2z'
            fill='#2D49FE'
         />
      </svg>
   );
}
