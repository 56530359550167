// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ControlButtonsInviteCard_container__2MA5G {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n}\n\n.ControlButtonsInviteCard_container__2MA5G > svg {\n    cursor: pointer;\n}", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/LkProfile/Cards/TeacherCard/components/ControlButtonsInviteCard/index.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".container {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n}\n\n.container > svg {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ControlButtonsInviteCard_container__2MA5G"
};
export default ___CSS_LOADER_EXPORT___;
