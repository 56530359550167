import { createRequest } from "core/LTools/core/apiMethods/createRequest";
import { mainAxios } from "api/index";
import {
   addSubjectType,
   blockTeacherType,
   blockUserType,
   confirmApplicationType,
   createCategoryType,
   editEducationSystemType,
   editSubjectType,
   getAllPaymentsQueryType,
   getCategoryType,
   postEducationSystemType,
   setUserStatusType,
   updateCategoryType,
   updateUserByAdminType,
} from "api/adminApi/types";
import { AxiosResponse } from "axios";
import { dataToQueryString } from "api/methods/dataToQueryString";



export const adminApi = {
   confirmApplication: async ({ user_id }: confirmApplicationType) => {
      return createRequest(mainAxios.post(`/user/${user_id}:activate`));
   },
   updateUser: async ({ user_id, ...body }: updateUserByAdminType) => {
      return createRequest(mainAxios.put(`/user/${user_id}`, body));
   },
   declineApplication: async ({ user_id }: confirmApplicationType) => {
      return createRequest(mainAxios.post(`/user/${user_id}:decline`));
   },
   blockTeacher: async ({ user_id }: blockTeacherType) => {
      return createRequest(mainAxios.post(`/user/${user_id}/teacher:decline`));
   },
   setUserStatus: async ({ user_id, role }: setUserStatusType) => {
      return createRequest(mainAxios.patch(`/user/${user_id}`, { role: role }));
   },
   blockUser: async ({ user_id }: blockUserType) => {
      return createRequest(mainAxios.post(`/user/${user_id}:block`));
   },
   editEducationSystem: async ({
      level_id,
      education_system_id,
      data,
   }: editEducationSystemType) => {
      return createRequest(
         mainAxios.put(
            `/level/${level_id}/education-system/${education_system_id}`,
            data
         )
      );
   },
   postEducationSystem: async ({ level_id, data }: postEducationSystemType) => {
      return createRequest(
         mainAxios.post(`/level/${level_id}/education-system`, data)
      );
   },

   createCategory: async ({
      education_system_id,
      ...body
   }: createCategoryType) => {
      return createRequest(
         mainAxios.post(
            `/education-system/${education_system_id}/category`,
            body
         )
      );
   },
   updateCategory: async ({
      education_system_id,
      category_id,
      ...body
   }: updateCategoryType) => {
      return createRequest(
         mainAxios.put(
            `/education-system/${education_system_id}/category/${category_id}`,
            body
         )
      );
   },
   addSubject: async ({ education_system_id, data }: addSubjectType) => {
      return createRequest(
         mainAxios.post(
            `/education-system/${education_system_id}/subject`,
            data
         )
      );
   },
   editSubject: async ({
      education_system_id,
      subject_id,
      data,
   }: editSubjectType) => {
      return createRequest(
         mainAxios.put(
            `/education-system/${education_system_id}/subject/${subject_id}`,
            data
         )
      );
   },
   getCategory: async ({ education_system_id }: getCategoryType) => {
      return createRequest(
         mainAxios.get(`/education-system/${education_system_id}/category`)
      );
   },

   getAllPaymentsById: async ({ user_id, ...query }: getAllPaymentsQueryType) => {
      return createRequest(
         mainAxios.get(`/user/${user_id}/payment${dataToQueryString(query)}`)
      );
   },

   getAllPayments: async (query: getAllPaymentsQueryType) => {
      return createRequest(
          mainAxios.get(`/payment${dataToQueryString(query)}`)
      );
   },
   getPaymentRegistry: async () => {
      return createRequest(mainAxios.get(`/payment-registry`));
   },
   uploadPaymentRegistry: async ({ content }: { content: string }) => {
      return createRequest(mainAxios.post(`/payment-registry-result`, { content }));
   },
   getPaymentRegistryById: async ({ registry_id }: { registry_id: number }) => {
      return createRequest(mainAxios.get(`/payment-registry-result/${registry_id}`));
   },
   downloadPaymentRegistry: async ({ registry_id }: { registry_id: number }) => {
      return createRequest(mainAxios.get(`payment-registry/${registry_id}/download`));
   }
};
