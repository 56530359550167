import cn from "classnames";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import { routes } from "../../../../../../routes";
import { Trans, useTranslation } from "react-i18next";
import React from "react";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "../../../../../../store/lkSlice";
import { ReactComponent as LstIcon } from "./assets/List.svg";
import { ReactComponent as PaymentIcon } from "./assets/Payment.svg";

export const FinancialOperationsMain = () => {
   const { t } = useTranslation();
   const user = useSelector(lkSliceSelectors.getUserData);

   return (
      <div className={cn(styles.container, "LkPaymentPage")}>
         <div className={styles.title}>
            {t("FinancialOperations.title", "Финансовые операции")}
         </div>
         <div className={styles.cards}>
            <Link to={routes.lk.financialOperations.operations.root}>
               <div className={cn(styles.card__item, styles.card_blue)}>
                  <LstIcon className={styles.icon}/>
                  <div className={cn(styles.text, styles.text_white)}>
                     <Trans i18nKey={"FinancialOperations.links.financial-operations"}>
                        Финансовые операции
                     </Trans>
                  </div>
               </div>
            </Link>
            {(user.type === "student" || user.type === "inactive_teacher") && (
               <Link to={routes.lk.financialOperations.operations.workingWithRegistries}>
                  <div className={cn(styles.card__item, styles.card_purple)}>
                     <PaymentIcon className={styles.icon}/>
                     <div className={styles.text}>
                        <Trans i18nKey={"FinancialOperations.links.working-with-registries"}>
                           Работа с реестрами
                        </Trans>
                     </div>
                  </div>
               </Link>
            )}
         </div>
      </div>
   );
};
