// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_body_container__363G_ {\n    width: 310px;\n}\n\n.style_user_widget__2zU_G {\n    margin-bottom: 18px;\n}\n.style_board__44CM8 {\n    margin-bottom: 41px;\n}\n\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n\n}\n\n@media screen and (max-width: 1023px) {\n    .style_container__1UFGC {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n    }\n}\n\n@media screen and (max-width: 719px) {\n\n}", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/LessonsService/Pages/DefaultLesson/style.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB;AACA;IACI,mBAAmB;AACvB;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,mBAAmB;IACvB;AACJ;;AAEA;;AAEA","sourcesContent":[".body_container {\n    width: 310px;\n}\n\n.user_widget {\n    margin-bottom: 18px;\n}\n.board {\n    margin-bottom: 41px;\n}\n\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n\n}\n\n@media screen and (max-width: 1023px) {\n    .container {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n    }\n}\n\n@media screen and (max-width: 719px) {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body_container": "style_body_container__363G_",
	"user_widget": "style_user_widget__2zU_G",
	"board": "style_board__44CM8",
	"container": "style_container__1UFGC"
};
export default ___CSS_LOADER_EXPORT___;
