// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SelectLanguageItem_language_select__17aMl {\n    display: flex;\n    align-items: center;\n    font-size: 14px;\n}\n.SelectLanguageItem_language_select__icon__1ttq4 {\n    width: 24px;\n    height: 24px;\n    margin-right: 10px;\n}", "",{"version":3,"sources":["webpack://src/components/UI/Select/SelectItems/SelectLanguageItem/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".language_select {\n    display: flex;\n    align-items: center;\n    font-size: 14px;\n}\n.language_select__icon {\n    width: 24px;\n    height: 24px;\n    margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"language_select": "SelectLanguageItem_language_select__17aMl",
	"language_select__icon": "SelectLanguageItem_language_select__icon__1ttq4"
};
export default ___CSS_LOADER_EXPORT___;
