import React, { FC, useState } from "react";
import styles from "./style.module.css";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as DropDownIcon } from "../assets/Dropdown.svg";
import cn from "classnames";
import { useQuery } from "../../../../../../hooks/useQuery";
import { lkSliceSelectors } from "../../../../../../store/lkSlice";
import { studentRegistrationSchema } from "../../../../../../helpers/validationSchemes";
import { useSelector } from "react-redux";
import { RadioInput } from "../../../../../UI/Input/RadioInput";
import { Button } from "../../../../../UI/Button";
import {
   educationSystemType,
   levelType,
} from "../../../../../../store/formsDataSlice/types";
import { formsDataApi } from "../../../../../../api/formsDataApi/methods";
import { lkSLiceThunk } from "../../../../../../store/lkSlice/thunk";
import { useLocalCommonState } from "../../../../../../core/LTools/core/hooks/useLocalCommonState";
import { useTranslation } from "react-i18next";
import { Preloader } from "components/UI/Preloader";
import { modalWindowTemplateType, uiSlice } from "store/modalWindowSlice";
import { SettingsDropdown } from "components/Lk/Pages/Settings/Worksheet/components/SettingsDropdown";

type propsType = {};
type formType = {
   es: string;
   level: string;
};
export const StudentWorksheet: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const {
      control,
      handleSubmit,
      register,
      formState: { errors },
      setValue,
      getValues,
   } = useForm<formType>({
      // @ts-ignore
      resolver: yupResolver(studentRegistrationSchema),
      defaultValues: {
         es: "",
         level: "",
      },
   });
   const userData = useSelector(lkSliceSelectors.getUserData);
   const commonState = useLocalCommonState();

   const successCaseHandler = () => {
      commonState.dispatch(
         uiSlice.actions.setModalType({
            template: "default" as modalWindowTemplateType["default"],
            payload: {
               title: t("Common.success", "Успешно"),
               button: "close",
               status: "success",
            },
         })
      );
   };

   const levelField = useWatch({
      control,
      name: "level",
   });
   const { data: levelsData, isLoading: levelsDataLoading } = useQuery(
      formsDataApi.getLevel,
      {
         cachedValueName: "level",
         serializer: (res) => {
            setValue(
               "level",
               String(userData?.study_education_system?.level?.id)
            );
            return res?.data.data.items as Array<levelType>;
         },
      }
   );
   const { data: esData, isLoading: esDataLoading } = useQuery(
      () =>
         formsDataApi.getEducationSystem({
            level_id: String(levelField),
         }),
      {
         deps: [levelField],
         serializer: (res) => {
            setValue("es", String(userData?.study_education_system?.id));
            return res?.data.data.items as Array<educationSystemType>;
         },
      }
   );
   const submit = (data: formType) => {
      if (!userData.id) return;
      commonState.dispatch(
         lkSLiceThunk.updateUser({
            commonState,
            callbacks: {
               "200": successCaseHandler,
            },
            user_id: String(userData.id),
            payload: {
               // @ts-ignore
               study_education_system: data.es,
               first_name: userData.first_name,
               last_name: userData.last_name,
               birth_date: userData.birth_date,
            },
         })
      );
   };
   return (
      <SettingsDropdown
         className={styles.container}
         title={t("Settings.form.student.title", "Анкета студента")}
      >
         <form onSubmit={handleSubmit(submit)} className={styles.form}>
            <div className={styles.form__block}>
               <div className={styles.title}>
                  {t(
                     "Settings.form.student.education-level",
                     "Ваш уровень образования"
                  )}
               </div>
               {levelsDataLoading ? (
                  <Preloader loading={levelsDataLoading} variant={"centred"} />
               ) : (
                  <div>
                     {levelsData?.map((item: any) => (
                        <RadioInput
                           key={item.id}
                           control={control}
                           name='level'
                           label={item.name}
                           value={item.id}
                        />
                     ))}
                     {errors?.level?.message && (
                        <div className={styles.input_error}>
                           {errors?.level?.message}
                        </div>
                     )}
                  </div>
               )}
            </div>

            <div className={styles.form__block}>
               {esDataLoading ? (
                  <Preloader loading={esDataLoading} variant={"centred"} />
               ) : (
                  esData?.length && (
                     <>
                        <div className={styles.title}>
                           {t(
                              "Settings.form.student.education-system",
                              "Ваша система образования"
                           )}
                        </div>
                        <div className={styles.es_container}>
                           {esData.map((item: any) => (
                              <RadioInput
                                 key={item.id}
                                 control={control}
                                 name='es'
                                 label={item.name}
                                 value={item.id}
                              />
                           ))}
                        </div>
                     </>
                  )
               )}
            </div>

            <Button variant={"primary"} loading={commonState.loading}>
               {t("Buttons.save", "Сохранить")}
            </Button>
         </form>
      </SettingsDropdown>
   );
};
