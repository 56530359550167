// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Paginate_container__3F_tC {\n    display: flex;\n    align-items: center;\n    width: 100%;\n}\n\n.Paginate_button__SHa3g {\n\n    font-family: Manrope;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    line-height: 22px;\n\n    color: var(--color-black);\n    background: transparent;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 2px 6px;\n    cursor: pointer;\n    margin: 0 2px;\n    transition: .4s;\n}\n.Paginate_button_selected__2HvGA {\n    color: #FFFFFF;\n    background: #0741AD;\n    border-radius: 4px;\n    transition: .4s;\n\n}\n.Paginate_none__17-T4 {\n    display: none;\n}", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/AdminService/components/Paginate/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;AACf;;AAEA;;IAEI,oBAAoB;IACpB,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;;IAEjB,yBAAyB;IACzB,uBAAuB;;IAEvB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,eAAe;IACf,aAAa;IACb,eAAe;AACnB;AACA;IAEI,cAAc;IACd,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;;AAEnB;AACA;IACI,aAAa;AACjB","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    width: 100%;\n}\n\n.button {\n\n    font-family: Manrope;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    line-height: 22px;\n\n    color: var(--color-black);\n    background: transparent;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 2px 6px;\n    cursor: pointer;\n    margin: 0 2px;\n    transition: .4s;\n}\n.button_selected {\n    composes: button;\n    color: #FFFFFF;\n    background: #0741AD;\n    border-radius: 4px;\n    transition: .4s;\n\n}\n.none {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Paginate_container__3F_tC",
	"button": "Paginate_button__SHa3g",
	"button_selected": "Paginate_button_selected__2HvGA Paginate_button__SHa3g",
	"none": "Paginate_none__17-T4"
};
export default ___CSS_LOADER_EXPORT___;
