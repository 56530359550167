import React, { useEffect, useState } from "react";

type useSliderType = {
   length: number;
};
export const useSlider = ({ length }: useSliderType) => {
   const [currentSlide, setCurrentSlide] = useState(0);
   const [translate, setTranslate] = useState(0);
   const [startXPosition, setStartXPosition] = useState(0);
   const [isActive, setIsActive] = useState(false);
   const mouseMoveHandler = (e: any) => {
      if (isActive) {
         setTranslate((startXPosition - e.clientX) / 2);
      }
   };

   const mouseDownHandler = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>
   ) => {
      e.preventDefault();
      e.stopPropagation();
      setStartXPosition(e.clientX);
      setIsActive(true);
   };

   const mouseUpHandler = (e: any) => {
      e.preventDefault();

      if (translate > 150) {
         if (currentSlide < length - 2) {
            setCurrentSlide((currentSlide + 1) % length);
         }
      }
      if (translate < -150) {
         if (currentSlide > 0) {
            setCurrentSlide(currentSlide - 1);
         }
      }
      setIsActive(false);
   };

   useEffect(() => {
      if (!isActive) {
         setTranslate(0);
      }
   }, [isActive]);

   return {
      mouseUpHandler: () => null,
      mouseDownHandler: () => null,
      mouseMoveHandler: () => null,
      currentSlide,
      setCurrentSlide,
      translate,
   };
};
