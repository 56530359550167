import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routes } from "routes";
import styles from "./index.module.css";
import { Icons } from "assets/iconsCorrect";
import { HeaderMobileMenu } from "components/Header/HeaderLanding/HeaderMobileMenu";
import { LangSwitcher } from "../../UI/LangSwitcher";
import { LangSwitcherSmall } from "components/UI/LangSwitcher/LangSwitcherSmall";
import { useSelector } from "react-redux";
import { StateType } from "store";
import { lkSliceSelectors } from "store/lkSlice";

type propsType = {};
export const HeaderLanding: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const [isVisible, setIsVisible] = useState(false);
   const authProcessState = useSelector(lkSliceSelectors.getAuthProcessState);
   console.log(authProcessState);
   return (
      <div className={styles.container}>
         <Link to='/'>
            <Icons.logos.logoFull />
         </Link>

         <div className={styles.nav}>
            <a className={styles.nav_link} href='#how-we-work'>
               {t("Header.how-we-work", "Как мы работаем")}
            </a>
            <a className={styles.nav_link} href='#FAQ'>
               {t("Header.faq", "FAQ")}
            </a>
            <a className={styles.nav_link} href='#reviews'>
               {t("Landings.Reviews.header", "Отзывы")}
            </a>
         </div>

         <div className={styles.register}>
            <LangSwitcherSmall />
            {authProcessState ? (
               <div className={styles.loading + " " + styles.loadingBg} />
            ) : (
               <Link
                  className={styles.registration_button}
                  to={routes.registration.auth.firstStage}
               >
                  {t("Header.registration", "Зарегистрироваться/Войти")}
               </Link>
            )}
         </div>
         <Icons.ui.burgerIcon
            className={styles.burger}
            onClick={() => {
               setIsVisible(!isVisible);
            }}
         />
         <HeaderMobileMenu isVisible={isVisible} setIsVisible={setIsVisible} />
      </div>
   );
};
