import React, { FC, HTMLAttributes, useEffect, useState } from "react";
import styles from "./index.module.css";
import { Icons } from "../../../assets/iconsCorrect";

type propsType = {
   title: string;
   value: string;
} & HTMLAttributes<HTMLDivElement>;
export const FilterElement: FC<propsType> = ({ value, title, ...props }) => {
   return (
      <div className={styles.container} {...props}>
         <span className={styles.title}>{title}</span>
         <span className={styles.value}>{value}</span>
         <Icons.buttons.DownArrowSelect className={styles.arrow} />
      </div>
   );
};
