// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__3LI89 {\n  font-family: var(--font-Source-Sans-Pro);\n  margin-bottom: 10px;\n}\n\n.style_title__32oav {\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 18px;\n  margin-bottom: 4px;\n}\n\n.style_value__1aPhg {\n  display: flex;\n}\n\n\n\n.style_text__2mEzG {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 20px;\n\n}\n.style_value__1aPhg {\n  margin-bottom: 10px;\n}", "",{"version":3,"sources":["webpack://src/components/Lk/components/Notifications/components/Duration/style.module.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;;;AAIA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;;AAEnB;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".container {\n  font-family: var(--font-Source-Sans-Pro);\n  margin-bottom: 10px;\n}\n\n.title {\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 18px;\n  margin-bottom: 4px;\n}\n\n.value {\n  display: flex;\n}\n\n\n\n.text {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 20px;\n\n}\n.value {\n  margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__3LI89",
	"title": "style_title__32oav",
	"value": "style_value__1aPhg",
	"text": "style_text__2mEzG"
};
export default ___CSS_LOADER_EXPORT___;
