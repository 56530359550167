// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__3lwhT {\n  background: var(--color-black4);\n}\n\n.style_create__38Zia{\n  background: var(--color-black4);\n}\n\n.style_reject__N0tcu{\n  background: #FEE7EB;\n}\n.style_approve__3ZRzZ {\n  background: var(--color-blue4);\n}\n\n\n.style_buttons__CcKDF {\n  display: flex;\n  justify-content: space-between;\n}\n\n.style_button__DbHm2 {\n  font-family: var(--font-Manrope);\n  color: var(--color-blue2);\n  font-size: 14px;\n  font-weight: 500;\n}\n\n.style_loading__N7-sb {\n\n  height: 24px;\n  width: -moz-fit-content;\n  width: fit-content;\n}", "",{"version":3,"sources":["webpack://src/components/Lk/components/Notifications/variants/NotificationInviteCard/style.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,8BAA8B;AAChC;;;AAGA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,gCAAgC;EAChC,yBAAyB;EACzB,eAAe;EACf,gBAAgB;AAClB;;AAEA;;EAEE,YAAY;EACZ,uBAAkB;EAAlB,kBAAkB;AACpB","sourcesContent":[".container {\n  background: var(--color-black4);\n}\n\n.create{\n  background: var(--color-black4);\n}\n\n.reject{\n  background: #FEE7EB;\n}\n.approve {\n  background: var(--color-blue4);\n}\n\n\n.buttons {\n  display: flex;\n  justify-content: space-between;\n}\n\n.button {\n  font-family: var(--font-Manrope);\n  color: var(--color-blue2);\n  font-size: 14px;\n  font-weight: 500;\n}\n\n.loading {\n\n  height: 24px;\n  width: fit-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__3lwhT",
	"create": "style_create__38Zia",
	"reject": "style_reject__N0tcu",
	"approve": "style_approve__3ZRzZ",
	"buttons": "style_buttons__CcKDF",
	"button": "style_button__DbHm2",
	"loading": "style_loading__N7-sb"
};
export default ___CSS_LOADER_EXPORT___;
