import React, { FC, useEffect, useState } from "react";
import styles from "./style.module.css";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { useSelector } from "react-redux";
import { authSliceSelectors } from "store/authSlice";
import { useForm, useWatch } from "react-hook-form";
import { usePaginator } from "hooks/usePagination";
import { lkSliceSelectors } from "store/lkSlice";
import { Paginate } from "../../../AdminService/components/Paginate";
import { Table } from "components/UI/Table";
import { teacherType } from "types/userType";
import { TitleSwitcher } from "components/Lk/Pages/Notebook/components/NoteHistory/components/TitleSwitcher";
import { useTranslation } from "react-i18next";
import { PageTitle } from "components/UI/PageTitle";
import { routes } from "routes";
import { useQuery } from "../../../../../../hooks/useQuery";
import { userApi } from "../../../../../../api/userApi/methods";
import { roleType } from "../../../../../../types/types";
import { SelectItemType } from "../../../../../UI/Select/types";
import { useDebounce } from "../../../../../../hooks/useDebounce";
import { useMedia } from "../../../../../../hooks/useMedia";
import { getNotePeoplesFilterFields } from "./components/filterFields";
import { NotePeoplesPopupFilters } from "./components/PopupFilters";
import { TeacherTableItem } from "components/Lk/Pages/Notebook/components/NotePeoples/components/PeopleTableItems/TeacherTableItem";
import { StudentTableItem } from "components/Lk/Pages/Notebook/components/NotePeoples/components/PeopleTableItems/StudentTableItem";
import { Preloader } from "../../../../../UI/Preloader";
import { Button } from "../../../../../UI/Button";
import { lessonType } from "../../../../../../types/LessonService/types";

export type TableElementType = {
   id: string;
   is_like: boolean;
   user: teacherType;
   user_type: roleType;
};
type filtersType = {
   rating?: SelectItemType<number>;
   name?: string;
   category?: SelectItemType<boolean>;
};

export const NotePeoples: FC = () => {
   /* state */
   const { t } = useTranslation();
   const { control, setValue, getValues, reset } = useForm();
   const user_id = useSelector(authSliceSelectors.getId);
   const { type } = useSelector(lkSliceSelectors.getUserData);
   const commonState = useLocalCommonState();
   const [itemsCount, setItemsCount] = useState(0);
   const [filters, setFilters] = useState<filtersType>({});
   const [isTeacherTable, setIsTeacherTable] = useState<boolean>(
      commonState.location === routes.lk.notebook.peoples.teachers
   );

   const { width } = useMedia();
   const formNameField = useWatch({
      control,
      name: "user_name",
   });
   const name = useDebounce(formNameField);
   const paginator = usePaginator({
      length: itemsCount,
      perPage: 20,
   });
   const [peoplesData, setPeoplesData] = useState<Array<TableElementType>>([]);
   const [filtersLoading, setFiltersLoading] = useState(false);
   useEffect(() => {
      setFiltersLoading(true);
      setPeoplesData([]);
      paginator.setCurrent(0);
   }, [name, isTeacherTable, filters]);
   /* fetch */
   const {
      data: notebookData,
      isLoading: notebookLoading,
      fetch,
      setData,
   } = useQuery(
      () =>
         userApi.getNotebook({
            offset: String(paginator.offset),
            limit: "20",
            user_id,
            user_type: isTeacherTable ? "teacher" : "student",
            user_name: name || undefined,
            order_by_like: "desc",
            is_like: filters?.category?.value ? true : undefined,
            [`${isTeacherTable ? "teaching_rating" : "study_rating"}`]:
               filters.rating?.value || undefined,
         }),
      {
         serializer: (res) => {
            setItemsCount(res?.data?.data?.items_count);
            if (width < 1024) {
               setPeoplesData((prev) => [...prev, ...res?.data?.data?.items]);
            } else {
               setPeoplesData(
                  res?.data?.data?.items as Array<TableElementType>
               );
            }
            setFiltersLoading(false);
            // return res?.data.data.items as Array<TableElementType>;
         },
         deps: [paginator.offset, filters, name, isTeacherTable],
      }
   );

   const resetAll = () => {
      setFilters({});
      setValue("rating", undefined);
      setValue("rating_input", "");
      setValue("category", undefined);
      setValue("category_input", "");
      setValue("user_name", "");
      paginator.setCurrent(0);
   };

   const setTableHandler = (val: boolean) => {
      resetAll();
      setData([]);
      setIsTeacherTable(val);
   };

   const onSelect = (v: any) => {
      setFilters(getValues());
   };
   const formElements = getNotePeoplesFilterFields({
      t,
      control,
      setValue,
      onSelect,
   });
   const mobilePaginatorHandler = () => {
      paginator.setCurrent(paginator.current + 1);
   };
   const resolveTableHeaders = () => {
      return width < 720
         ? []
         : [
              "",
              t("Notebook.headers.name", "Имя"),
              t("Notebook.headers.rating", "Рейтинг"),
              t("Notebook.headers.education_systems", "Системы образования"),
              t("Notebook.headers.grade", "Грейд"),
              "",
           ];
   };

   return (
      <div className={styles.container + " LkDefaultPage"}>
         {type === "teacher" ? (
            <TitleSwitcher
               disabled={notebookLoading}
               labels={[
                  t("Notebook.titles.your_students", "Ваши студенты"),
                  t("Notebook.titles.your_teachers", "Ваши преподаватели"),
               ]}
               className={styles.role_switcher}
               setSelect={setTableHandler}
               selected={isTeacherTable}
            />
         ) : (
            <PageTitle
               title={t("Notebook.titles.your_teachers", "Ваши преподаватели")}
            />
         )}
         <NotePeoplesPopupFilters formElements={formElements} />

         <Table
            numberColumn={6}
            loading={width >= 1024 ? notebookLoading : filtersLoading}
            tableHeaders={resolveTableHeaders()}
            tableItem={isTeacherTable ? TeacherTableItem : StudentTableItem}
            data={peoplesData}
         />
         {itemsCount > 20 && (
            <div className={styles.paginator_wrapper}>
               <Paginate
                  pageCount={paginator.pageCount}
                  onPageActive={() => null}
                  onPageChange={paginator.onPageChange}
               />
            </div>
         )}

         {width < 1024 && notebookLoading && (
            <Preloader loading={notebookLoading} variant={"centred"} />
         )}
         {itemsCount > paginator.current * 10 && (
            <div className={styles.paginator_mobile}>
               <Button variant={"outline"} onClick={mobilePaginatorHandler}>
                  {t("SearchFilters.teacher-filters.show-more", "Показать ещё")}
               </Button>
            </div>
         )}
      </div>
   );
};
