import { routes } from "routes";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMedia } from "hooks/useMedia";
import { ScheduleSidebar } from "./index";

type propsType = {};
const routesToRender = [
   {
      route: routes.lk.statistic.root,
      strict: false,
   },
   {
      route: routes.lk.statistic.teacher,
      strict: false,
   },
   {
      route: routes.lk.statistic.student,
      strict: false,
   },
   {
      route: routes.lk.profile,
      strict: false,
   },
   {
      route: routes.lk.notebook.root,
      strict: true,
   },
   {
      route: routes.lk.lesson.root,
      strict: false,
   },
   {
      route: routes.lk.messages,
      strict: false,
   },
   {
      route: routes.lk.notebook.root,
      strict: false,
   },
   {
      route: routes.lk.payment.root,
      strict: false,
   },
   {
      route: routes.lk.financialOperations.root,
      strict: false,
   },
];

export const ScheduleSidebarHoc: FC<propsType> = (props) => {
   const { pathname } = useLocation();
   const [shouldSidebarRender, setShouldSidebarRender] = useState(false);
   const { width } = useMedia();

   const sidebarRenderHandler = () => {
      const isPathnameCorrect = !!routesToRender.find((routeData) => {
         return routeData.strict
            ? routeData.route === pathname
            : pathname.includes(routeData.route);
      });

      // Сайдбара по дизайну нет на 1440 только в /lk/statistic, в остальных раутах он есть на 1440
      // Если открыта статистика и ширина 1920 - isWidthCorrect true,
      // если другой раут и ширина 1440 - isWidthCorrect true
      const isWidthCorrectSertificate =
         pathname === routes.lk.statistic.root ||
         pathname === routes.lk.statistic.teacher ||
         pathname === routes.lk.statistic.student
            ? width >= 1920
            : width >= 1440;
      const isWidthCorrectNotebook =
         pathname === routes.lk.notebook.history ||
         pathname === routes.lk.notebook.peoples.students ||
         pathname === routes.lk.notebook.peoples.teachers
            ? width === 1920
            : true;

      return (
         isWidthCorrectSertificate &&
         isWidthCorrectNotebook &&
         isPathnameCorrect
      );
   };

   useEffect(() => {
      setShouldSidebarRender(sidebarRenderHandler());
   }, [pathname, width]);

   return shouldSidebarRender ? <ScheduleSidebar /> : <></>;
};
