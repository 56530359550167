// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TimeInterval_container__1fnmt {\n    padding-top: 6px;\n    padding-bottom: 6px;\n    display: flex;\n    justify-content: start;\n    align-items: center;\n}\n\n.TimeInterval_icon__32Z4S {\n    margin-right: 10px;\n    width: 16px;\n    height: 16px;\n}\n.TimeInterval_icon--white__3h4Df path {\n    fill: white;\n}\n\n.TimeInterval_duration__KtN7- {\n    font-weight: 300;\n    margin-left: 0.5em;\n}", "",{"version":3,"sources":["webpack://src/components/UI/TimeInterval/index.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;AACA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".container {\n    padding-top: 6px;\n    padding-bottom: 6px;\n    display: flex;\n    justify-content: start;\n    align-items: center;\n}\n\n.icon {\n    margin-right: 10px;\n    width: 16px;\n    height: 16px;\n}\n.icon--white path {\n    fill: white;\n}\n\n.duration {\n    font-weight: 300;\n    margin-left: 0.5em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TimeInterval_container__1fnmt",
	"icon": "TimeInterval_icon__32Z4S",
	"icon--white": "TimeInterval_icon--white__3h4Df",
	"duration": "TimeInterval_duration__KtN7-"
};
export default ___CSS_LOADER_EXPORT___;
