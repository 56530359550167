// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Prompt_container__IeQL2 {\n    position: relative;\n    padding: 21px 22px;\n    padding-right: 35px;\n    font-family: var(--font-Source-Sans-Pro);\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    line-height: 20px;\n    background-color: var(--color-black4);\n    border-radius: 10px;\n}\n.Prompt_button__3y58g {\n    position: absolute;\n    top: 9px;\n    right: 9px;\n}\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n\n}\n\n@media screen and (max-width: 1023px) {\n\n}\n\n@media screen and (max-width: 719px) {\n    .Prompt_container__IeQL2 {\n        max-width: 288px;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/UI/Prompt/index.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;IACnB,wCAAwC;IACxC,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,qCAAqC;IACrC,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,UAAU;AACd;AACA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".container {\n    position: relative;\n    padding: 21px 22px;\n    padding-right: 35px;\n    font-family: var(--font-Source-Sans-Pro);\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    line-height: 20px;\n    background-color: var(--color-black4);\n    border-radius: 10px;\n}\n.button {\n    position: absolute;\n    top: 9px;\n    right: 9px;\n}\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n\n}\n\n@media screen and (max-width: 1023px) {\n\n}\n\n@media screen and (max-width: 719px) {\n    .container {\n        max-width: 288px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Prompt_container__IeQL2",
	"button": "Prompt_button__3y58g"
};
export default ___CSS_LOADER_EXPORT___;
