import React, { FC, useEffect, useRef, useState } from "react";
import style from "./style.module.css";
import { ReactComponent as DateIcon } from "../../assets/Date.svg";
import moment from "moment-timezone";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";
import { useBlurred } from "hooks/useBlurred";
import { Calendar } from "components/UI/Calendar";

type propsType = {
   dateFrom: string;
   dateTo: string;
   setDateFrom: any;
   setDateTo: any;
};
type formType = {
   dateFrom: string;
   dateTo: string;
};

export const PeriodPicker: FC<propsType> = (props) => {
   const { t } = useTranslation();

   const [isTeacherPeriodOpen, setIsTeacherPeriodOpen] = useState(false);
   const { control } = useForm<any>();
   const teacherPeriodRef = useRef(null);
   const { dateTo, dateFrom } = useWatch({ control });
   useEffect(() => {
      if (dateTo) {
         props.setDateTo(dateTo);
      }
      if (dateFrom) {
         props.setDateFrom(dateFrom);
      }
   }, [dateFrom, dateTo]);
   useBlurred(teacherPeriodRef, setIsTeacherPeriodOpen, isTeacherPeriodOpen);

   return (
      <div className={style.period_container}>
         <p className={style.period_title}>
            {t("Lk.Statistic.period", "Период")}
         </p>
         <div className={style["card--date"]}>
            <DateIcon className={style.card_date_icon} />
            <div>
               <p onClick={() => setIsTeacherPeriodOpen(!isTeacherPeriodOpen)}>
                  {dateFrom
                     ? moment(dateFrom).format("DD.MM.yyyy")
                     : t("Common.start_date", "Дата начала")}
                  -
                  {dateTo
                     ? moment(dateTo).format("DD.MM.yyyy")
                     : t("Common.end_date", "Дата конца")}
               </p>
               <div
                  ref={teacherPeriodRef}
                  className={cn({
                     [style.period_picker_hidden]: true,
                     [style.period_picker_shown]: isTeacherPeriodOpen,
                  })}
               >
                  <Calendar
                     control={control}
                     name='dateFrom'
                     placeholder={t("Common.start_period", "Начало периода")}
                  />
                  <Calendar
                     control={control}
                     name='dateTo'
                     placeholder={t("Common.end_period", "Конец периода")}
                  />
               </div>
            </div>
         </div>
      </div>
   );
};
