import React, { FC, HTMLAttributes, useEffect, useState } from "react";
import styles from "./style.module.css";
import { Icons } from "../../../../../assets/iconsCorrect";
import { Button } from "../../index";
import { useTranslation } from "react-i18next";

type propsType = {
   setIsPopupOpen: (val: boolean) => void;
} & HTMLAttributes<HTMLButtonElement>;
export const FilterButton: FC<propsType> = ({ setIsPopupOpen, ...props }) => {
   const { t } = useTranslation();
   return (
      <Button {...props} onClick={() => setIsPopupOpen(true)} bgColor='gray'>
         <Icons.ui.FilterIcon /> {t("Buttons.filters", "Фильтры")}
      </Button>
   );
};
