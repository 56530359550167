import React, { FC, useEffect, useState } from "react";
import { Button } from "../../../../../../UI/Button";
import { FilterElement } from "components/UI/FilterElement";
import { getTeacherSearchFiltersElements } from "../formElements";
import styles from "../index.module.css";
import { Popup } from "components/UI/Popup";
import { Control, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { FilterButton } from "../../../../../../UI/Button/Templates/FilterButton";
import { TeacherSearchFiltersType } from "../../../index";
import { FiltersArea } from "../../../../../../UI/FiltersArea";

type propsType = {
   control: Control<any>;
   setValue: any;
   submit: () => void;
   filters: TeacherSearchFiltersType;
   setFilters: (val: any) => void;
   errors: any;
};
export const TeacherSearchPopupFilters: FC<propsType> = ({
   control,
   setValue,
   submit,
   errors,
   filters,
   setFilters,
}) => {
   const { t } = useTranslation();
   const [isPopupOpen, setIsPopupOpen] = useState(false);
   const [popupTemplate, setPopupTemplate] = useState<
      "default" | "date" | "duration" | "date_from" | "rating"
   >("default");
   const form = useWatch({
      control,
   });

   const formElements = getTeacherSearchFiltersElements({
      control,
      setValue,
      t,
      filters,
      errors,
   });

   const resetForm = () => {
      setPopupTemplate("default");
      control.unregister();
   };
   const resetFilters = () => {
      setFilters({});
      resetForm();
   };

   const setDefaultTemplate = () => {
      setPopupTemplate("default");
   };

   const popupData = (function () {
      switch (popupTemplate) {
         case "date":
            return {
               component: formElements.date,
               buttons: (
                  <Button onClick={setDefaultTemplate}>
                     {t("SearchFilters.teacher-filters.apply", "Применить")}
                  </Button>
               ),
               onBackArrow: setDefaultTemplate,
               title: t("Lk.ScheduleFilter.title", "Фильтры"),
            };
         case "duration":
            return {
               component: formElements.duration,
               buttons: (
                  <Button onClick={setDefaultTemplate}>
                     {t("SearchFilters.teacher-filters.apply", "Применить")}
                  </Button>
               ),
               onBackArrow: setDefaultTemplate,
               title: t("Lk.ScheduleFilter.title", "Фильтры"),
            };
         case "rating":
            return {
               component: formElements.rating,
               buttons: (
                  <Button onClick={setDefaultTemplate}>
                     {t("SearchFilters.teacher-filters.apply", "Применить")}
                  </Button>
               ),
               onBackArrow: setDefaultTemplate,
               title: t("Lk.ScheduleFilter.title", "Фильтры"),
            };
         case "date_from":
            return {
               component: formElements.date_from,
               buttons: (
                  <Button onClick={setDefaultTemplate}>
                     {t("SearchFilters.teacher-filters.apply", "Применить")}
                  </Button>
               ),
               onBackArrow: setDefaultTemplate,
               title: t("Lk.ScheduleFilter.title", "Фильтры"),
            };
         default:
            return {
               component: (
                  <div>
                     <FilterElement
                        onClick={() => setPopupTemplate("date")}
                        title={t("UI.DateAndTimePicker.date", "Дата")}
                        value={
                           form?.date
                              ? moment(form?.date).format("DD.MM.YYYY")
                              : filters?.date
                              ? moment(filters?.date).format("DD.MM.YYYY")
                              : ""
                        }
                     />
                     <FilterElement
                        onClick={() => setPopupTemplate("date_from")}
                        title={t("Filters.start-time", "Время начала")}
                        value={(
                           form?.date_from?.text ||
                           filters?.date_from?.text ||
                           ""
                        ).replace(".", ":")}
                     />
                     <FilterElement
                        onClick={() => setPopupTemplate("duration")}
                        title={t(
                           "UI.DateAndTimePicker.duration",
                           "Длительность"
                        )}
                        value={
                           form?.duration?.text || filters?.duration?.text || ""
                        }
                     />
                     <FilterElement
                        onClick={() => setPopupTemplate("rating")}
                        title={t("Common.rating", "Рейтинг")}
                        value={
                           form?.rating?.text || filters?.rating?.text || ""
                        }
                     />
                  </div>
               ),
               buttons: (
                  <>
                     <Button
                        key={"buttonReset"}
                        onClick={resetFilters}
                        variant={"link"}
                        style={{
                           color: "var(--color-warning)",
                           textDecoration: "none",
                           margin: "0 auto",
                           marginBottom: "16px",
                        }}
                     >
                        {t("SearchFilters.teacher-filters.reset", "Сбросить")}
                     </Button>
                     <Button onClick={submit}>
                        {t("SearchFilters.teacher-filters.apply", "Применить")}
                     </Button>
                  </>
               ),
               title: t("Lk.ScheduleFilter.title", "Фильтры"),
            };
      }
   })();

   return (
      <>
         <FilterButton
            className={styles.filter_button}
            setIsPopupOpen={setIsPopupOpen}
         />
         <FiltersArea
            filters={[
               {
                  name: "duration",
                  value: filters?.duration?.value?.text,
                  title: t("UI.DateAndTimePicker.duration", "Длительность"),
                  clearHandler: () => {
                     setFilters((prev: any) => ({
                        ...prev,
                        duration: undefined,
                     }));
                  },
               },
               {
                  name: "rating",
                  value: filters?.rating?.value,
                  title: t("Common.rating", "Рейтинг"),
                  clearHandler: () => {
                     setFilters((prev: any) => ({
                        ...prev,
                        rating: undefined,
                     }));
                  },
               },
               {
                  name: "date_from",
                  value: filters?.date_from?.value?.text,
                  title: t("Filters.start-time", "Время начала"),
                  clearHandler: () => {
                     setFilters((prev: any) => ({
                        ...prev,
                        date_from: undefined,
                     }));
                  },
               },
               {
                  name: "date",
                  value: filters?.date
                     ? moment(filters?.date).format("DD.MM.YYYY")
                     : "",
                  title: t("UI.DateAndTimePicker.date", "Дата"),
                  clearHandler: () => {
                     setFilters((prev: any) => ({
                        ...prev,
                        date: undefined,
                     }));
                  },
               },
            ]}
         />
         <Popup
            onClose={resetForm}
            setIsOpen={setIsPopupOpen}
            isOpen={isPopupOpen}
            {...popupData}
         >
            {popupData.component}
         </Popup>
      </>
   );
};
