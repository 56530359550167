import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StateType } from "../index";
import { languageType } from "../formsDataSlice/types";
import { subjectType } from "../types";
import { teacherType } from "types/userType";
import { languageCodeType } from "services/translateService";
import { lc } from "store/LC";
import i18n from "services/translateService/i18n";
import { certificateType, statisticsType } from "types/types";

type initialState = {
   userData: teacherType;
   userSlots: {
      [key: string]: any;
   };
   lang: languageCodeType;
   teacherForm: {
      languages: Array<languageType>;
      subjects?: Array<subjectType>;
      documents?: {
         passport: Array<{
            content: string;
            name: string;
            type: string;
         }>;
         grades: Array<{
            content: string;
            name: string;
            type: string;
         }>;
      };
   };
   authProcessState: boolean;
   certificate?: certificateType;
   statistics: {
      teacher?: statisticsType;
      student?: statisticsType;
   };
   isOnline?: boolean
};
const initialState: initialState = {
   userData: {} as teacherType,
   statistics: {},
   userSlots: {},
   lang: (lc.get("lang") as languageCodeType) || i18n.language,
   teacherForm: {
      languages: [],
   },
   authProcessState: true,
};
export const lkSlice = createSlice({
   name: "lkSlice",
   initialState,
   reducers: {
      setData: (state, { payload }: PayloadAction<teacherType>) => {
         state.userData = payload;
         state.isOnline = payload.online
      },
      setIsOnline: (state, {payload}:PayloadAction<boolean>) => {
         state.isOnline = payload
      },
      setStatistics: (
         state,
         {
            payload,
         }: PayloadAction<{
            key: "student" | "teacher";
            value: statisticsType;
         }>
      ) => {
         state.statistics[payload.key] = payload.value;
      },
      setSlots: (state, { payload }: PayloadAction<any>) => {
         state.userSlots = payload;
      },
      setLang: (state, { payload }: PayloadAction<languageCodeType>) => {
         state.lang = payload;
         lc.set("lang", payload);
      },
      setCertificate: (state, { payload }: PayloadAction<certificateType>) => {
         state.certificate = payload;
      },
      updateTeacherForm: (state, { payload }: PayloadAction<any>) => {
         state.teacherForm = {
            ...state.teacherForm,
            ...payload,
         };
      },
      setAuthProcessState: (state, { payload }: PayloadAction<boolean>) => {
         state.authProcessState = payload;
      },
      clear: (state) => {
         return { ...initialState, authProcessState: false}
      }
   },
});

export const lkSliceSelectors = {
   getUserData: (state: StateType) => state.lkSLice.userData,
   getUserId: (state: StateType) => state.lkSLice.userData.id,
   getTeacherForm: (state: StateType) => state.lkSLice.teacherForm,
   getLang: (state: StateType) => state.lkSLice.lang,
   getCertificate: (state: StateType) => state.lkSLice.certificate,
   getAuthProcessState: (state: StateType) => state.lkSLice.authProcessState,
   getStatistics: (state: StateType) => state.lkSLice.statistics,
   getIsOnline: (state: StateType) => state.lkSLice.isOnline,
};
