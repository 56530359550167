import React, { HTMLAttributes, useState } from "react";

import styles from "./index.module.css";
import { ReactComponent as Avatar } from "../../../assets/inputs/Masters/Avatar.svg";
import { InputLoader } from "../../../assets/inputs/InputLoader";
import cn from "classnames";
import { ReactComponent as AttachIcon } from "../../../assets/inputs/Attach.svg";
import { useTranslation } from "react-i18next";
import { fileErrorTypeAlert } from "../../../helpers/uploadFiles";

type photoUploaderType = {
   name: string;
   error?: string;
   defaultImg?: string;
   setValue: any;
} & HTMLAttributes<HTMLInputElement>;
export const PhotoUploader = React.forwardRef<any, photoUploaderType>(
   (props, ref) => {
      const accept = ".jpg, .png, .jpeg";
      const { t } = useTranslation();
      const [profileImg, setProfileImage] = useState<
         string | ArrayBuffer | null
      >(null);
      const [isLoading, setIsLoading] = useState(false);
      const imageHandler = (e: any) => {
         props.onChange && props.onChange(e);
         const reader = new FileReader();
         if (fileErrorTypeAlert(e.target?.files?.[0], accept)) {
         } else {
            return;
         }
         reader?.readAsDataURL(e.target?.files?.[0]);
         reader.onloadstart = () => {
            setIsLoading(true);
         };
         reader.onloadend = () => {
            setTimeout(() => setIsLoading(false), 3000);
            if (reader.result != null) {
               setProfileImage(reader?.result);
               props.setValue(props.name, {
                  content: reader?.result,
                  file_name: e.target?.files?.[0]?.name,
               });
            }
         };
      };
      const deleteHandler = () => {
         setProfileImage(null);
         props.setValue(props.name, undefined);
      };

      return (
         <div className={styles.container}>
            <div className={styles.wrapper}>
               <div className={styles.imageHolder}>
                  {profileImg || props.defaultImg ? (
                     <img
                        src={
                           profileImg
                              ? profileImg.toString()
                              : props.defaultImg || ""
                        }
                        alt=''
                        className={cn({
                           [styles.photoPreview]: true,
                           [styles["photoPreview-loading"]]: isLoading,
                        })}
                     />
                  ) : (
                     <Avatar />
                  )}
                  {isLoading && (
                     <div className={styles.loader}>
                        <InputLoader />
                     </div>
                  )}
                  {!isLoading && profileImg && (
                     <div className={styles.trash} onClick={deleteHandler}>
                        <div>
                           <AttachIcon />
                        </div>
                     </div>
                  )}
               </div>
               <input
                  {...props}
                  type='file'
                  accept='image/png, image/jpg, image/jpeg'
                  ref={ref}
                  className={styles.input}
                  id='PhotoUploader__input'
                  onChange={imageHandler}
               />
               <div className={styles.labelWrapper}>
                  <label
                     className={styles.label}
                     htmlFor='PhotoUploader__input'
                  >
                     {t("UI.PhotoUploader.upload-button", "Загрузить фото")}
                  </label>
               </div>
            </div>
         </div>
      );
   }
);
