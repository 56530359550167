import React, { FC } from "react";
import styles from "components/Landings/Footer/index.module.css";
import { useTranslation } from "react-i18next";
import { Icons } from "assets/iconsCorrect";
// @ts-ignore
import pp from "../../../pdf/privacy_policy.pdf";
// @ts-ignore
import ua from "../../../pdf/user_agreement.pdf";
import { useHistory } from "react-router-dom";

export const Footer: FC = () => {
   const { t } = useTranslation();
   const history = useHistory();

   return (
      <>
         <div className={styles.container}>
            <div className={styles.icons}>
               <a href='mailto:support@e-tutorinternational.com'  className={styles.icon}>
                  <Icons.social.EmailIcon className={styles.icon} />
               </a>
               <a
                  target={"_blank"}
                  href='https://www.instagram.com/etutor.international/'
                  className={styles.icon}
               >
                  <Icons.social.InstIcon className={styles.icon} />
               </a>
               <a
                  href='http://linkedin.com//company/etutor-international'
                  target={"_blank"}
                  className={styles.icon}
               >
                  <Icons.social.LinkdIcon className={styles.icon} />
               </a>
               <a
                  href='https://www.youtube.com/channel/UCyfW_iwrOfvdO0_YevGF-Dw'
                  target={"_blank"}
                  className={styles.icon}
               >
                  <Icons.social.YouTubeIcon className={styles.icon} />
               </a>
            </div>
            <p className={styles.text}>
               {t(
                  "Footer.text",
                  "2021 All Rights Reserved | E-Tutor International"
               )}
               <a
                  onClick={() => history.push("privacy_policy")}
                  href={pp} download="Privacy Policy"> | Privacy Policy
               </a>
               <a
                  onClick={() => history.push("user_agreement")}
                  href={ua} download="User Agreement"> | User Agreement
               </a>
            </p>
            <p> Version 1.0 </p>
         </div>
      </>
   );
};
