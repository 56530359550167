import React, {FC, useEffect, useState} from "react";
import styles from "./index.module.css";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import {useForm, useWatch} from "react-hook-form";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";
import { ReactComponent as SearchIcon } from "./assets/search.svg";
import { ReactComponent as RubIcon } from "./assets/rub.svg";
import { ReactComponent as ArrowDropdown } from "./assets/arrow_dropdown.svg";
import { ReactComponent as LstIcon } from "./assets/List.svg";
import { NoteHistory } from "../../../Notebook/components/NoteHistory";
import { routes } from "../../../../../../routes";
import { Input } from "../../../../../UI/Input";
// import {sortingOptions} from "../../../../../../helpers/selectOptions";
import { Calendar } from "../../../../../UI/Calendar";
import { Select } from "../../../../../UI/SelectNew";
import { SelectElement } from "../../../../../UI/Select/SelectElement/SelectElement";
import { DefaultLessonsTable } from "../../../Notebook/components/NoteHistory/Table/defaultLessons";
import { NoteTable } from "../../components/Table";
import { useMedia } from "../../../../../../hooks/useMedia";
import { serverArrayType } from "../../../../../../types/types";
import { lessonType } from "../../../../../../types/LessonService/types";
import { SelectItemType } from "../../../../../UI/Select/types";
import i18n from "../../../../../../services/translateService/i18n";
import { FilterButton } from "../../../../../UI/Button/Templates/FilterButton";
import { voteType } from "../../../../../../store/types";
import { studentType, teacherType } from "../../../../../../types/userType";
import { languageType } from "../../../../../../store/formsDataSlice/types";
import { defaultFileType } from "../../../../../../api/types";
import { Paginate } from "../../../AdminService/components/Paginate";
import { usePaginator } from "../../../../../../hooks/usePagination";
import { FilterButtonSec } from "../../../../../UI/Button/Templates/FilterButtonSec";
import { lkSliceSelectors } from "../../../../../../store/lkSlice";
import {useFilters} from "../../../../../../hooks/useFilters";
import {adminApi} from "../../../../../../api/adminApi";
import moment from "moment/moment";
import {SmartForm} from "../../../../../hocs/SmartForm";
import {Preloader} from "../../../../../UI/Preloader";
import {Button} from "../../../../../UI/Button";
import {sortingOptions} from "../../../LessonPayment/Pages/TransactionsList";
import { format } from 'date-fns';

// const sortingOptions: Array<SelectItemType<"asc" | "desc">> = [
//    {
//       id: 1,
//       text: "Создан",
//       value: "desc",
//    },
//    {
//       id: 2,
//       text: "В обработке",
//       value: "desc",
//    },
//    {
//       id: 3,
//       text: "В процессе оплаты",
//       value: "desc",
//    },
//    {
//       id: 4,
//       text: "Оплачено",
//       value: "desc",
//    },
//    {
//       id: 5,
//       text: "Ошибка",
//       value: "desc",
//    },
// ];

// const lessons = {
//    items: [
//       {
//          id: 1,
//          subject: "Английский язык",
//          date: "14.05.2021 14:00-16:30",
//          teacher: "Иван Помидоров",
//          duration: "2 ч 30 мин.",
//          cost: "2500",
//          status: "В обработке",
//          type: "Входящий"
//       },
//       {
//          id: 2,
//          subject: "Информационные технологии",
//          date: "14.05.2021 14:00-16:30",
//          teacher: "Степан Корыто",
//          duration: "2 ч 30 мин.",
//          cost: "2500",
//          status: "Оплачено",
//          type: "Входящий"
//       },
//       {
//          id: 3,
//          subject: "Изобразительное искусство",
//          date: "14.05.2021 14:00-16:30",
//          teacher: "Андрей Гриль",
//          duration: "2 ч 30 мин.",
//          cost: "2500",
//          status: "В обработке",
//          type: "Входящий"
//       },
//       {
//          id: 4,
//          subject: "Высшая математика",
//          date: "14.05.2021 14:00-16:30",
//          teacher: "Антон Головогрудь",
//          duration: "2 ч 30 мин.",
//          cost: "2500",
//          status: "Ошибка",
//          type: "Входящий"
//       },
//       {
//          id: 5,
//          subject: "География",
//          date: "14.05.2021 14:00-16:30",
//          teacher: "Петр Филь",
//          duration: "2 ч 30 мин.",
//          cost: "2500",
//          status: "В обработке",
//          type: "Исходящий"
//       },
//       {
//          id: 6,
//          subject: "География",
//          date: "14.05.2021 14:00-16:30",
//          teacher: "Олег Александрович Окнов",
//          duration: "2 ч 30 мин.",
//          cost: "2500",
//          status: "В обработке",
//          type: "Исходящий"
//       },
//    ]
// }

export const FinancialOperations: FC = () => {
   const [isDefaultLesson, setIsDefaultLesson] = useState<boolean>(false);
   const [showAllFilters, setShowAllFilters] = useState<boolean>(false);

   const { t } = useTranslation();
   const { width } = useMedia();

   const user = useSelector(lkSliceSelectors.getUserData);

   const sortingOptions1: Array<SelectItemType<"asc" | "desc">> = [
      {
         id: 1,
         text: `15 ${t("Common.minutes", "минут")}`,
         value: "desc",
      },
      {
         id: 2,
         text: `30 ${t("Common.minutes", "минут")}`,
         value: "desc",
      },
      {
         id: 3,
         text: `45 ${t("Common.minutes", "минут")}`,
         value: "desc",
      },
   ];

   const sortingOptions2: Array<SelectItemType<"asc" | "desc">> = [
      {
         id: 1,
         text: t("FinancialOperations.filters.values.incoming", "Входящий"),
         value: "desc",
      },
      {
         id: 2,
         text: t("FinancialOperations.filters.values.outgoing", "Исходящий"),
         value: "desc",
      },
   ];

   // const [lessons, setLessons] = useState<serverArrayType<Array<lessonType>>>({
   //   items: [],
   //   items_count: 0,
   // });

   const {
      data,
      applyFiltersHandler,
      form,
      resetHandler,
      isLoading,
      itemsCount,
      setFilters,
   } = useFilters<{}, any>({
      initialState: {
         limit: 5,
         offset: 0,
      },

      queryKey: "payment",
      queryFn: (data) => {
         return adminApi.getAllPayments({
            limit: data.limit,
            offset: data.offset,
            amount_from: data.amount_from,
            amount_to: data.amount_to,
            date_from: data.date_from
                ? format(data.date_from, 'yyyy-MM-dd') + "T21:00:00%2B03:00"
                : undefined,
            date_to: data.date_to ? format(data.date_to, 'yyyy-MM-dd') + "T21:00:00%2B03:00" : undefined,
            fio: data.fio,
            type: data?.payment_status?.value
         });
      },
      defaultValues: {
         date_from: undefined,
         date_to: undefined,
         amount_from: "",
         amount_to: "",
         fio: "",
         type: ""
      },
   });

   const values: (string | boolean)[] = useWatch({
      control: form.control,
      name: [
         "date_from",
         "date_to",
         "amount_from",
         "amount_to",
         "fio",
         "payment_status"
      ],
   });

   // const [
   //    date_from,
   //    date_to,
   //    amount_from,
   //    amount_to,
   //    fio,
   //    payment_status
   // ] = useWatch({
   //    control: form.control,
   //    name: [
   //       "date_from",
   //       "date_to",
   //       "amount_from",
   //       "amount_to",
   //       "fio",
   //       "payment_status"
   //    ],
   // });

   const renderCrossIconPayment = () => {
      return Boolean(
          values.find((item) => {
             return item;
          })
      );
   };

   const paginator = usePaginator({
      perPage: 5,
      length: itemsCount,
   });

   useEffect(() => {
      setFilters((prev) => {
         return {
            ...prev,
            offset: paginator.offset,
         };
      });
   }, [paginator.offset]);

   // const { setValue, control, reset } = useForm<any>({
   //    defaultValues: {
   //       nameField: "",
   //       date: undefined,
   //       sort: sortingOptions[0],
   //       sort_input: sortingOptions[0].text,
   //    },
   // });

   return (
      <div className={cn(styles.container, "LkPaymentPage")}>
         <div className={styles.title}>
            {t("FinancialOperations.title", "Финансовые операции")}
         </div>
         <div className={styles.subtitles}>
            <NavLink
               exact
               to={routes.lk.financialOperations.root}
               activeClassName={styles.button_active}
            >
               <div className={styles.subtitles__item}>
                  <ArrowIcon className={styles.subtitles__icon}/>
                  <div className={styles.subtitles__title}>
                     {t("FinancialOperations.links.financial-operations", "Финансовые операции")}
                  </div>
               </div>
            </NavLink>
            <NavLink
               exact
               to={routes.lk.financialOperations.operations.root}
               activeClassName={styles.button_active}
            >
               <div className={styles.subtitles__item}>
                  <ArrowIcon className={styles.subtitles__icon}/>
                  <div className={styles.subtitles__title}>
                     {t("FinancialOperations.links.financial-operations", "Финансовые операции")}
                  </div>
               </div>
            </NavLink>
         </div>
         {/*<div className={styles.subtitles}>*/}
         {/*   <NavLink*/}
         {/*      exact*/}
         {/*      to={routes.lk.financialOperations.root}*/}
         {/*      activeClassName={styles.button_active}*/}
         {/*   >*/}
         {/*      <div className={styles.subtitles__item}>*/}
         {/*         <ArrowIcon className={styles.subtitles__icon}/>*/}
         {/*         <div className={styles.subtitles__title}>*/}
         {/*            {t("FinancialOperations.links.1", "Финансовые операции")}*/}
         {/*         </div>*/}
         {/*      </div>*/}
         {/*   </NavLink>*/}
         {/*</div>*/}
         <SmartForm
             submit={form.handleSubmit(applyFiltersHandler)}
             form={form}
         >
         <div className={styles["filters-block"]}>
            {width >= 1024 ? (
               <>
                  <div className={styles["filters-block_row"]}>
                     <Calendar
                        className={styles.filters__input_calendar}
                        control={form.control}
                        name='date_from'
                        placeholder={t("FinancialOperations.filters.date-from", "Дата от")}
                        placeholderColor={"dark-gray"}
                     />
                     <Calendar
                        className={styles.filters__input_calendar}
                        control={form.control}
                        name='date_to'
                        placeholder={t("FinancialOperations.filters.date-to", "Дата до")}
                        placeholderColor={"dark-gray"}
                     />
                     <Select
                        className={styles.filters__input_select}
                        variant={"blue-bg"}
                        name="payment_status"
                        setValue={form.setValue}
                        options={sortingOptions}
                        control={form.control}
                        support={<ArrowDropdown/>}
                        selectTitle={t("FinancialOperations.filters.status", "Статус")}
                        variantTitle={"small-title"}
                        titleColor={"black"}
                     />
                     <Input
                        className={styles.filters__input_search}
                        control={form.control}
                        name='fio'
                        label={t("FinancialOperations.filters.search-by-full-name", "Поиск по ФИО")}
                        // support={<SearchIcon/>}
                     />
                  </div>
                  <div className={styles["filters-block_row"]}>
                     <Input
                        className={styles.filters__input_sum}
                        control={form.control}
                        name='amount_from'
                        label={t("FinancialOperations.filters.sum-from", "Сумма от")}
                        support={<RubIcon/>}
                     />
                     <Input
                        className={styles.filters__input_sum}
                        control={form.control}
                        name='amount_to'
                        label={t("FinancialOperations.filters.sum-to", "Сумма до")}
                        support={<RubIcon/>}
                     />
                     <Select
                        className={styles.filters__input_select}
                        variant={"blue-bg"}
                        name='sort3'
                        setValue={form.setValue}
                        options={sortingOptions1}
                        control={form.control}
                        support={<ArrowDropdown/>}
                        selectTitle={t("FinancialOperations.filters.lesson-duration", "Продолжительность урока")}
                        variantTitle={"small-title"}
                        titleColor={"black"}
                     />
                     <Select
                        className={styles.filters__input_select}
                        variant={"blue-bg"}
                        name='sort-1'
                        setValue={form.setValue}
                        options={sortingOptions2}
                        control={form.control}
                        support={<ArrowDropdown/>}
                        selectTitle={t("FinancialOperations.filters.transaction-type", "Тип платежа")}
                        variantTitle={"small-title"}
                        titleColor={"black"}
                     />
                     {/*<Input*/}
                     {/*   className={styles.filters__input_search}*/}
                     {/*   control={control}*/}
                     {/*   name='nameField'*/}
                     {/*   label={t("FinancialOperations.filters.bank-account-number", "Номер счета")}*/}
                     {/*/>*/}
                  </div>
                  {/*<div className={styles["filters-block_row-3"]}>*/}

                  {/*</div>*/}
               </>
            ) : width > 719 ? (
               <>
                  <div className={styles["filters-block_row"]}>
                     <Calendar
                        className={styles.filters__input_calendar}
                        control={form.control}
                        name='date_from'
                        placeholder={t("FinancialOperations.filters.date-from", "Дата от")}
                        placeholderColor={"dark-gray"}
                     />
                     <Calendar
                        className={styles.filters__input_calendar}
                        control={form.control}
                        name='date_to'
                        placeholder={t("FinancialOperations.filters.date-to", "Дата до")}
                        placeholderColor={"dark-gray"}
                     />
                     <Select
                        className={styles.filters__input_select}
                        variant={"blue-bg"}
                        name="payment_status"
                        setValue={form.setValue}
                        options={sortingOptions}
                        control={form.control}
                        support={<ArrowDropdown/>}
                        selectTitle={t("FinancialOperations.filters.status", "Статус")}
                        variantTitle={"small-title"}
                        titleColor={"black"}
                     />
                     <FilterButtonSec
                        className={styles["filter-button"]} setIsPopupOpen={setShowAllFilters}
                        title={showAllFilters
                           ? t("FinancialOperations.filters.hide-filters", "Скрыть фильтры")
                           : t("FinancialOperations.filters.all-filters", "Все фильтры")
                        }
                        val={showAllFilters}
                     />
                  </div>
                  {showAllFilters && (
                     <>
                        <div className={styles["filters-block_row"]} style={{ justifyContent: "start" }}>
                           <Input
                              className={styles.filters__input_search1}
                              control={form.control}
                              name='fio'
                              label={t("FinancialOperations.filters.search-by-full-name", "Поиск по ФИО")}
                              // support={<SearchIcon/>}
                           />
                           <Input
                              className={styles.filters__input1}
                              control={form.control}
                              name='amount_from'
                              label={t("FinancialOperations.filters.sum-from", "Сумма от")}
                              support={<RubIcon/>}
                           />
                           <Input
                              className={styles.filters__input1}
                              control={form.control}
                              name='amount_to'
                              label={t("FinancialOperations.filters.sum-to", "Сумма до")}
                              support={<RubIcon/>}
                           />
                        </div>
                        <div className={styles["filters-block_row-3"]} style={{ justifyContent: "start" }}>
                           <div className={cn(styles.item, styles.item_select)}>
                              <Select
                                 className={styles.filters__select1}
                                 variant={"blue-bg"}
                                 name='sort3'
                                 setValue={form.setValue}
                                 options={sortingOptions1}
                                 control={form.control}
                                 support={<ArrowDropdown/>}
                                 selectTitle={t("FinancialOperations.filters.lesson-duration", "Продолжительность урока")}
                                 variantTitle={"small-title"}
                                 titleColor={"black"}
                              />
                           </div>
                           {/*<div className={cn(styles.item, styles.item_search)}>*/}
                           {/*   <Input*/}
                           {/*      control={control}*/}
                           {/*      name='nameField'*/}
                           {/*      label={t("FinancialOperations.filters.bank-account-number", "Номер счета")}*/}
                           {/*   />*/}
                           {/*</div>*/}
                           <div className={styles.item}>
                              <Select
                                 className={styles.filters__input_select}
                                 variant={"blue-bg"}
                                 name='sort-1'
                                 setValue={form.setValue}
                                 options={sortingOptions2}
                                 control={form.control}
                                 support={<ArrowDropdown/>}
                                 selectTitle={t("FinancialOperations.filters.transaction-type", "Тип платежа")}
                                 variantTitle={"small-title"}
                                 titleColor={"black"}
                              />
                           </div>
                        </div>
                     </>
                  )}
               </>
            ) : (
               <>
                  <Calendar
                     className={styles.filters__input_calendar}
                     control={form.control}
                     name='date_from'
                     placeholder={t("FinancialOperations.filters.date-from", "Дата от")}
                  />
                  <Calendar
                     className={styles.filters__input_calendar}
                     control={form.control}
                     name='date_to'
                     placeholder={t("FinancialOperations.filters.date-to", "Дата до")}
                  />
                  <Select
                     className={styles.filters__select}
                     variant={"blue-bg"}
                     name='payment_status'
                     setValue={form.setValue}
                     options={sortingOptions}
                     control={form.control}
                     support={<ArrowDropdown/>}
                     selectTitle={t("FinancialOperations.filters.status", "Статус")}
                     variantTitle={"small-title"}
                     titleColor={"black"}
                  />
                  {showAllFilters && (
                     <>
                        <Input
                           className={styles.filters__input_search1}
                           control={form.control}
                           name='fio'
                           label={t("FinancialOperations.filters.search-by-full-name", "Поиск по ФИО")}
                           // support={<SearchIcon/>}
                        />
                        <div className={styles.input_container}>
                           <Input
                              className={styles.filters__input_sum}
                              style={{ marginRight: "16px" }}
                              control={form.control}
                              name='amount_from'
                              label={t("FinancialOperations.filters.sum-from", "Сумма от")}
                              support={<RubIcon/>}
                           />
                           <Input
                              className={styles.filters__input_sum}
                              control={form.control}
                              name='amount_to'
                              label={t("FinancialOperations.filters.sum-to", "Сумма до")}
                              support={<RubIcon/>}
                           />
                        </div>
                        <Select
                           className={styles.filters__select1}
                           variant={"blue-bg"}
                           name='sort3'
                           setValue={form.setValue}
                           options={sortingOptions1}
                           control={form.control}
                           support={<ArrowDropdown/>}
                           selectTitle={t("FinancialOperations.filters.lesson-duration", "Продолжительность урока")}
                           variantTitle={"small-title"}
                           titleColor={"black"}
                        />
                        {/*{user.role === "admin" && (*/}
                        {/*   <Input*/}
                        {/*      className={styles.filters__input_search}*/}
                        {/*      control={control}*/}
                        {/*      name='nameField'*/}
                        {/*      label={t("FinancialOperations.filters.bank-account-number", "Номер счета")}*/}
                        {/*   />*/}
                        {/*)}*/}
                        {user.role === "admin" && user.type === "teacher" && (
                           <Select
                              className={styles.filters__select1}
                              style={{ marginBottom: "24px" }}
                              variant={"blue-bg"}
                              name='sort-1'
                              setValue={form.setValue}
                              options={sortingOptions2}
                              control={form.control}
                              support={<ArrowDropdown/>}
                              selectTitle={t("FinancialOperations.filters.transaction-type", "Тип платежа")}
                              variantTitle={"small-title"}
                              titleColor={"black"}
                           />
                        )}
                     </>
                  )}
                  <FilterButtonSec
                     className={styles.filters__button} setIsPopupOpen={setShowAllFilters}
                     title={showAllFilters
                        ? t("FinancialOperations.filters.hide-filters", "Скрыть фильтры")
                        : t("FinancialOperations.filters.all-filters", "Все фильтры")}
                     val={showAllFilters}
                  />
               </>
            )}
            <div className={styles.filter_button_container}>
              <Button className={styles.button}>Применить</Button>
              {renderCrossIconPayment() &&
              <Button onClick={resetHandler} className={styles.button}>Очистить фильтр</Button>
              }
            </div>
         </div>
         </SmartForm>
         {isLoading ? <Preloader loading={isLoading}/> :
             <NoteTable
                 data={data}
                 // loading={width >= 1024 ? loading : false}
                 // type={template == "default" ? "lesson" : "group_lesson"}
             />
         }
         {width >= 1024 && (
             <div className={styles.paginator_wrapper}>
                <Paginate
                    {...paginator}
                    pageCount={Math.ceil(itemsCount / 5)}
                    onPageActive={() => null}
                    onPageChange={paginator.onPageChange}
                />
             </div>
         )}
      </div>
   );
};
