import React, {
   FC,
   FormEventHandler,
   HTMLAttributes,
   useEffect,
   useState,
} from "react";
import { useLocalCommonStateType } from "../../hooks/useLocalCommonState";
import { UseFormClearErrors } from "react-hook-form";

type propsType = {
   onSubmit: FormEventHandler<HTMLFormElement>;
   commonState: useLocalCommonStateType<any>;
   clearErrors: UseFormClearErrors<any>;
   errors: any;
} & HTMLAttributes<HTMLFormElement>;
export const Form: FC<propsType> = ({
   children,
   onSubmit,
   commonState,
   clearErrors,
   errors,
   ...props
}) => {
   const onFormChange = (e: any) => {
      props.onChange && props.onChange(e);
      const name = e.target.name;
      if (commonState.errors[name]) {
         const tempErrors = { ...commonState.errors };
         delete tempErrors[name];
         commonState.errors = tempErrors;
      }
      if (errors[name]?.message) {
         clearErrors(name);
      }
   };

   return (
      <form onSubmit={onSubmit} onChange={onFormChange} {...props}>
         {children}
      </form>
   );
};
