import { useEffect, useState } from "react";

type useInfinityScrollPropsType = {
   callback: () => void;
   currentLength: number;
};
export const useInfinityScroll = ({
   callback,
   currentLength,
}: useInfinityScrollPropsType) => {
   const [calledOnIteration, setCalledOnIteration] = useState(false);
   const [scrollHeightState, setScrollHeightState] = useState(0);

   useEffect(() => {
      setCalledOnIteration(false);
   }, [scrollHeightState]);

   const onScroll = (e: any) => {
      const node = e.currentTarget;
      const scrollHeight = node.scrollHeight;
      const nodeHeight = node.getBoundingClientRect().height;
      const scrollTop = node.scrollTop;
      const scrollingPool = scrollHeight - nodeHeight;

      console.log(scrollHeight);

      if (scrollHeight != scrollHeightState) {
         setScrollHeightState(scrollHeight);
      }

      if (scrollingPool - scrollTop < 100) {
         if (!calledOnIteration && currentLength) {
            callback();
            setCalledOnIteration(true);
         }
      }
   };

   return {
      onScroll,
   };
};
