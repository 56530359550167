import React, { FC, useState } from "react";
import styles from "./index.module.css";
import cn from "classnames";
import { ReactComponent as Arrow } from "../../../assets/arrow.svg";
import { AdminUserInfoBlock } from "../UserInfoBlock";
import { useTranslation } from "react-i18next";
import { useGetUserAvatar } from "hooks/useGetUserAvatar";
import { showDateDMY } from "helpers/date/showDate";
import { CreateChatButton } from "components/UI/CreateChatButton";
import { useHistory } from "react-router-dom";
import { routes } from "../../../../../../../../../routes";
import { lkApi } from "api/LkApi/methods";
import { useQuery } from "react-query";
import { useGetCardByUserID } from "hooks/useGetCardByUserID";

type AdminTableItemType = {
   user: any;
   updateState: () => void;
   updateTable: () => void;
};

export const AdminUsersTableItem: FC<AdminTableItemType> = (props) => {
   const { user, updateState, updateTable } = props;
   const [avatar] = useGetUserAvatar(user.id);

   const { t } = useTranslation();
   const history = useHistory();

   const {error, isLoading, allCards, isOpened, toggleUserInfo} = useGetCardByUserID(user)



   return (
      <>
         <tr className={styles.preview}>
            <td className={styles.preview_photo}>
               <img src={avatar} alt='' />
            </td>
            <td className={styles.table_item}>
               <p className={styles.user_id}>{user.id}</p>
               <p
                  className={styles.user_name}
                  onClick={() =>
                     history.push(routes.lk.lkStudent + "/" + user.id)
                  }
               >
                  {user.first_name} {user.last_name}
               </p>
            </td>
            <td className={styles.table_item}>
               {showDateDMY(user.registration_date)}
            </td>
            <td className={styles.table_item}>
               {showDateDMY(user.birth_date)}
            </td>
            <td className={styles.table_item}>
               {user.type === "inactive_teacher" ||
               user.type === "student" ||
               user.is_blocked
                  ? t("Lk.AdminPanel.Table.student", "Студент")
                  : t(
                       "Lk.ScheduleFilter.filter.title-teacher",
                       "Преподаватель"
                    )}
            </td>
            <td className={[styles.table_item, styles.email].join(" ")}>
               {user.email}
            </td>
            <td>
               <CreateChatButton user_id={user.id} />
            </td>
            <td className={styles.button}>
               <button onClick={toggleUserInfo}>
                  <Arrow />
               </button>
            </td>
         </tr>
         <tr style={{ borderBottom: "unset" }}>
            <td
               style={{ padding: "0" }}
               colSpan={window.innerWidth > 1439 ? 7 : 6}
            >
               <div
                  className={cn(styles.info, {
                     [styles["info-open"]]: isOpened,
                  })}
               >
                  <AdminUserInfoBlock
                      allCards={allCards}
                     updateTable={updateTable}
                     updateState={updateState}
                     user={user}
                     avatar={avatar}
                  />
               </div>
            </td>
         </tr>
      </>
   );
};
