import React, { FC, useState } from "react";
import { CreateSlot } from "./CreateSlot";
import { CreateOpenLesson } from "./CreateOpenLesson";
import { PageTitle } from "components/UI/PageTitle";
import { TitleSwitcher } from "components/Lk/Pages/Notebook/components/NoteHistory/components/TitleSwitcher";
import { useTranslation } from "react-i18next";
import styles from "./index.module.css";

export const CreateSlotOrLesson: FC = () => {
   const { t } = useTranslation();
   // False equals group_lesson
   const [switcherState, setSwitcherState] = useState(false);

   return (
      <div className={styles.container}>
         <PageTitle
            behavior={"tablet_hide"}
            title={t("Lk.lesson-service.add-lesson.title", "Добавить занятие")}
         />
         <TitleSwitcher
            selected={switcherState}
            labels={[
               t("UI.LessonTypeSwitch.time", "Время проведения занятия"),
               t("UI.LessonTypeSwitch.group_lesson", "Открытый урок"),
            ]}
            setSelect={setSwitcherState}
            className={styles.switcher}
         />
         {switcherState ? <CreateOpenLesson /> : <CreateSlot />}
      </div>
   );
};
