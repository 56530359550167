import React, { FC, useEffect, useState } from "react";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { notification_typeType, notificationPropsType } from "../../types";
import { useQuery } from "hooks/useQuery";
import { userApi } from "api/userApi/methods";
import { lessonApi } from "api/lessonApi/methods";
import { LessonItemType } from "components/Lk/Pages/Notebook/components/NoteHistory/components/Table";
import { inviteApi } from "api/inviteApi/methods";
import { dateMethods } from "helpers/date";

type propsType = {
   date_to?: string;
   date_from: string;
   notification_type: notification_typeType;
   entity_type: string | null;
   disableTitle?: boolean;
};

export const NotificationCardDate: FC<propsType> = ({
   date_from,
   date_to,
   notification_type,
   entity_type,
   disableTitle,
}) => {
   const { t } = useTranslation();

   const dateTitleResolver = (): any => {
      switch (notification_type) {
         case "reject_lesson":
            return t(
               "Lk.Notifications.Date.reject-lesson",
               "Дата отмененного занятия"
            );
         case "role_student_to_inactive":
            return t(
               "Lk.Notifications.Date.student-to-inactive",
               "Дата подачи заявки"
            );
         default:
            return t("Lk.Notifications.Date.default", "Время занятия");
      }
   };

   return (
      <div className={styles.container}>
         {!disableTitle && (
            <div className={styles.title}>{dateTitleResolver()}</div>
         )}
         <div className={styles.date}>
            <div className={styles.day}>
               {dateMethods.format(date_from, "DD.MM.YYYY")}
            </div>
            <div className={styles.time}>
               {dateMethods.format(date_from, "HH:mm")}
               {date_to && <>-</>}
               {date_to && dateMethods.format(date_to, "HH:mm")}
            </div>
         </div>
      </div>
   );
};
