import React, { FC, ReactNode } from "react";
import styles from "./style.module.css";

type propsType = {
   icon?: ReactNode;
   title: string;
};
export const LessonTitle: FC<propsType> = (props) => {
   return (
      <h2 className={styles.container}>
         {props.icon && <div className={styles.icon}> {props.icon}</div>}
         {props.title}
      </h2>
   );
};
