import { mainAxios } from "api/index";
import {
   createCertificateType,
   getAllCertificateType,
   getCertificateBySignatureType,
   getCertificateType,
   getStatisticsType,
} from "api/certificateApi/types";
import { dataToQueryString } from "core/LTools/core/apiMethods/dataToQueryString";
import { createRequest } from "core/LTools/core/apiMethods/createRequest";

export const certificateApi = {
   create: ({ user_id, user_type, ...body }: createCertificateType) => {
      return createRequest(
         mainAxios.post(
            `/user/${user_id}/${user_type}/certificate${dataToQueryString(
               body
            )}`
         )
      );
   },
   get: ({ user_id, user_type, certificate_id }: getCertificateType) => {
      return createRequest(
         mainAxios.get(
            `/user/${user_id}/${user_type}/certificate/${certificate_id}`
         )
      );
   },
   getAll: ({ user_id }: getAllCertificateType) => {
      return createRequest(mainAxios.get(`/user/${user_id}/certificate`));
   },
   getBySignature: (query: getCertificateBySignatureType) => {
      return createRequest(
         mainAxios.get(`/certificate${dataToQueryString(query)}`)
      );
   },
   getStatistics: ({ user_id, user_type }: getStatisticsType) => {
      return createRequest(
         mainAxios.get(`/user/${user_id}/${user_type}/statistics`)
      );
   },
   getPublicCertificate: (id: string) => {
      return createRequest(mainAxios.get(`/certificate/${id}`));
   },
};
