import moment from "moment-timezone";

export const timelineTicketsGroupHandler = (tickets: {
   lessons: any[];
   invites: any[];
   groupLessons: any[];
   slots: any[];
}) => {
   const { lessons, invites, groupLessons, slots } = tickets;
   const arrayOfTickets = [...lessons, ...invites, ...groupLessons, ...slots];
   let currentDate = "";
   if (!arrayOfTickets.length) return {};
   const groupedTickets: {
      [key: string]: {
         hours: Array<{
            hour: string;
            tickets: any[];
         }>;
      };
   } = {
      // [moment(arrayOfTickets[0].date_from).format("DD-MM-YYYY")]: {
      //    hours: [
      //       {
      //          hour: moment(arrayOfTickets[0].date_from).format("HH-mm"),
      //          tickets: [arrayOfTickets[0]],
      //       },
      //    ],
      // },
   };
   arrayOfTickets.map((ticket, index) => {
      currentDate = moment(ticket.date_from).format("DD-MM-YYYY");
      let currentHour = moment(ticket.date_from).format("HH-mm");
      const findedHour = groupedTickets[currentDate]?.hours.find(
         (item) => item.hour == currentHour
      );
      if (findedHour) {
         findedHour.tickets = [...findedHour.tickets, ticket];
      } else {
         groupedTickets[currentDate]
            ? (groupedTickets[currentDate] = {
                 hours: [
                    ...groupedTickets[currentDate].hours,
                    {
                       hour: currentHour,
                       tickets: [ticket],
                    },
                 ],
              })
            : (groupedTickets[currentDate] = {
                 hours: [
                    {
                       hour: currentHour,
                       tickets: [ticket],
                    },
                 ],
              });
      }
   });
   return groupedTickets;
};
