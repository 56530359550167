import React, { FC } from "react";
import "./style.css";
import EarthIconPNG from "./assets/Earth.png";
import cn from "classnames";

type propsType = {
   type: "student" | "teacher" | "authorization";
};
export const RegistrationBackground: FC<propsType> = (props) => {
   return (
      <div
         className={cn({
            RegistrationBackground: true,
            "Teacher-background-color": props.type == "teacher",
            "Authorization-background-color": props.type == "authorization",
         })}
      >
         <img src={EarthIconPNG} className='RegistrationBackground__earth' />
      </div>
   );
};
