// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__343ST {\n    margin-bottom: 50px;\n}\n\n.style_form__2-qun {\n    padding: 20px;\n    display: flex;\n    justify-content: center;\n    align-items: flex-start;\n    flex-direction: column;\n    max-width: 360px;\n}\n\n.style_form__block__1K6pR {\n    margin-bottom: 40px;\n}\n\n.style_title__3hKa4 {\n    font-weight: 600;\n    font-size: 24px;\n    margin-bottom: 20px;\n}\n\n@media screen and (max-width: 719px) {\n    .style_container__343ST {\n        margin-bottom: 20px;\n    }\n    .style_form__2-qun {\n        padding: 14px;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/Settings/Worksheet/Student/style.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,uBAAuB;IACvB,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI;QACI,mBAAmB;IACvB;IACA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".container {\n    margin-bottom: 50px;\n}\n\n.form {\n    padding: 20px;\n    display: flex;\n    justify-content: center;\n    align-items: flex-start;\n    flex-direction: column;\n    max-width: 360px;\n}\n\n.form__block {\n    margin-bottom: 40px;\n}\n\n.title {\n    font-weight: 600;\n    font-size: 24px;\n    margin-bottom: 20px;\n}\n\n@media screen and (max-width: 719px) {\n    .container {\n        margin-bottom: 20px;\n    }\n    .form {\n        padding: 14px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__343ST",
	"form": "style_form__2-qun",
	"form__block": "style_form__block__1K6pR",
	"title": "style_title__3hKa4"
};
export default ___CSS_LOADER_EXPORT___;
