import React, {
   FC,
   HTMLAttributes,
   ReactNode,
   useEffect,
   useRef,
   useState,
} from "react";
import styles from "./index.module.css";
import cn from "classnames";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";
import { useBlurred } from "hooks/useBlurred";
import { Icons } from "assets/iconsCorrect";
import { Preloader } from "components/UI/Preloader";
import { ReactComponent as DownArrowSelect } from "../../../assets/buttons/DownArrowSelect.svg";

export type selectItemType<T> = {
   id: number;
   text: string;
   value: T;
};

type propsType<T> = {
   options?: Array<selectItemType<T>>;
   optionComponent?: FC<T>;
   selectedComponent?: FC<T>;
   control: Control<any>;
   search?: boolean;
   support?: ReactNode;
   defaultSelectValue?: selectItemType<T>;
   name: string;
   setInputValue?: (val: string) => void;
   onSelectValue?: (val: selectItemType<T>) => void;
   searchRule?: (val: T) => boolean;
   disableFiltering?: boolean;
   setValue: UseFormSetValue<any>;
   selectTitle?: string;
   isLoading?: boolean;
   customContent?: ReactNode;
   error?: string;
   showCustomContent?: boolean;
   onBlur?: () => void;
   variant?: "blue-bg" | "default";
   variantTitle?: "small-title";
   titleColor?: "black" | "default";
   disabled?: boolean;
   reset?: boolean;
   type?: "requisites";
} & HTMLAttributes<HTMLDivElement>;

export function Select<T>({
   options,
   optionComponent,
   selectedComponent,
   search,
   searchRule,
   setValue: setFormValue,
   disableFiltering,
   defaultSelectValue,
   setInputValue,
   name,
   onSelectValue,
   control,
   selectTitle,
   isLoading,
   customContent,
   showCustomContent,
   error,
   onBlur,
   support,
   reset,
   ...jsxAttr
}: propsType<T>) {
   const [isOpen, setIsOpen] = useState(false);
   const [searchValue, setSearchValue] = useState("");
   const [isSearchFocused, setIsSearchFocused] = useState(false);
   const selected: selectItemType<T> = useWatch({
      control,
      name: name,
   });
   const ref = useRef<HTMLDivElement>(null);
   useBlurred(ref, setIsOpen, isOpen);

   // Callbacks
   const onSearchValueChange = (e: any) => {
      const value = e.currentTarget.value;
      setSearchValue(value);
      setInputValue && setInputValue(value);
   };
   const onBlurHandler = (e: any) => {
      onBlur && onBlur(e);
      if (!e.relatedTarget || e.relatedTarget?.tagName != "LI") {
         setIsOpen(false);
      }
   };
   const onSelectProxy = (val: selectItemType<T>) => {
      setFormValue(name, val);
      onSelectValue && onSelectValue(val);
      setIsOpen(false);
   };

   // Clear search field by toggling "isOpen" state
   useEffect(() => {
      if (search && !isOpen) {
         setSearchValue("");
      }
   }, [isOpen]);

   // Set default value when component did mount
   useEffect(() => {
      if (defaultSelectValue) {
         onSelectProxy(defaultSelectValue);
      }
   }, []);

   // View methods
   // console.log(selectTitle);
   //
   // useEffect(() => {
   //    if (reset) {
   //       setFormValue(name, undefined);
   //    }
   // }, [reset]);

   const displayOptions = () => {
      if (!options?.length) return null;
      let resultOptions = [...options];
      if (search && !disableFiltering) {
         resultOptions = resultOptions.filter((item) =>
            item.text.toLowerCase().includes(searchValue.toLowerCase())
         );
      }

      return optionComponent
         ? resultOptions?.map((item) => {
              return (
                 <SelectItem
                    key={item.id}
                    onSelect={onSelectProxy}
                    payload={item}
                    className={styles.company_wrapper}
                 >
                    {React.createElement(optionComponent, item.value)}
                 </SelectItem>
              );
           })
         : resultOptions?.map((item) => {
              return (
                 <SelectItem
                    key={item.id}
                    onSelect={onSelectProxy}
                    payload={item}
                    className={styles.list_item}
                 >
                    <div className={styles.list_item_text}>{item.text}</div>
                 </SelectItem>
              );
           });
   };

   const displayHeader = () => {
      if ((isOpen && search) || (search && !selected?.value))
         return (
            <input
               onFocus={() => setIsOpen(true)}
               // onBlur={() => setIsSearchFocused(false)}
               autoFocus={!!selected?.value}
               value={searchValue}
               className={styles.search_input}
               onChange={onSearchValueChange}
            />
         );

      return (
         selected?.value &&
         (selectedComponent
            ? React.createElement(selectedComponent, selected.value)
            : selected.text)
      );
   };

   return (
      <div
         ref={ref}
         {...jsxAttr}
         onBlur={onBlurHandler}
         className={cn(styles.container, jsxAttr.className, {
            [styles.blue_bg]: jsxAttr.variant == "blue-bg",
            [styles.default]: jsxAttr.variant == "default",
            [styles.title_small]: jsxAttr.variantTitle == "small-title",
            [styles.disabled]: jsxAttr.disabled,
         })}
      >
         {/* Render main field */}
         <div
            className={cn(styles.header, {
               [styles.header_active]: isOpen,
               [styles.header_default]: jsxAttr.titleColor === "default",
            })}
            onClick={() => setIsOpen(!isOpen)}
         >
            <div
               className={cn(styles.select_title, {
                  [styles.select_title_small]: selected?.value || isOpen,
                  [styles.select_title_small_default]: selected?.value || isOpen && jsxAttr.variant == "default",
                  [styles.title_black]: jsxAttr.titleColor === "black",
                  [styles.title_default]: jsxAttr.titleColor === "default",
               })}
            >
               {selectTitle}
            </div>

            {displayHeader()}
            {error && <div className={styles.error}>{error}</div>}
            <div className={cn(styles.support, {
               [styles.support_small]: jsxAttr.variantTitle == "small-title",
            })}>
               {isLoading ? (
                  <Preloader
                     loading={true}
                     className={styles.header_support_loading}
                  />
               ) : support ? (
                 <div
                   className={styles.header_support}
                   style={{ transform: isOpen ? "rotateZ(180deg)" : "none" }}
                 >
                    {support}
                 </div>
               ) : (
                  <DownArrowSelect
                     className={styles.header_support}
                     // onClick={() => setIsOpen(!isOpen)}
                     style={{ transform: isOpen ? "rotateZ(180deg)" : "none" }}
                  />
               )}
            </div>
         </div>
         {/* Render list */}
         {!!options?.length && isOpen ? (
            <ul className={styles.list}>
               {customContent && showCustomContent
                  ? customContent
                  : displayOptions()}
            </ul>
         ) : (
            <></>
         )}
      </div>
   );
}

const SelectItem: FC<{
   onSelect: (val: any) => void;
   payload: any;
   className?: string;
}> = (props) => {
   const onSelectHandler = () => {
      props.onSelect(props.payload);
   };

   const keyDownHandler = (e: any) => {
      let codesLikeClick = ["Space", "Enter"];
      if (codesLikeClick.includes(e.code)) {
         props.onSelect(props.payload);
      }
   };

   return (
      <li
         onKeyUp={keyDownHandler}
         tabIndex={0}
         onClick={onSelectHandler}
         className={props.className}
      >
         {props.children}
      </li>
   );
};
