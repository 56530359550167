import React, { FC, useEffect, useRef, useState } from "react";
import styles from "components/Lk/Pages/SchedulePage/Events/index.module.css";
import { useSelector } from "react-redux";
import { EventDay } from "components/Lk/Pages/SchedulePage/Events/EventDay";
import { scheduleSliceSelectors } from "store/scheduleSlice";
import { ReactComponent as UpIcon } from "assets/icons/UpButtonIcon.svg";
import { useTranslation } from "react-i18next";
import { StateType } from "store";
import { format } from "date-fns";
import { ScheduleFiltersUi } from "components/UI/ScheduleFiltersUi";
import { useMedia } from "hooks/useMedia";
import { EventTimeLine } from "components/UI/EventTimeLine";
import { ticketTypeResolver } from "helpers/date/ticketsTypeResolver";
import { authSliceSelectors } from "store/authSlice";
import { showDateLabel } from "helpers/date/showDate";
import { Preloader } from "components/UI/Preloader";
import { daysInMonth } from "helpers/schedule";
import moment from "moment-timezone";

type propsType = {
   setValue: any;
};
export const Events: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const calendarDate = useSelector(scheduleSliceSelectors.getDate);
   const day = useSelector(scheduleSliceSelectors.getDay);
   const [days, setDays] = useState(daysInMonth(calendarDate));
   useEffect(() => {
      setDays(daysInMonth(calendarDate));
   }, [calendarDate]);
   const tickets = useSelector(
      (state: StateType) => state.scheduleSlice.ticketsForTimeline
   );
   const filters = useSelector(
      (state: StateType) => state.scheduleSlice.filters
   );
   const ticketsLoading = useSelector(
      (state: StateType) => state.scheduleSlice.loading
   );

   const showEvents = () => {
      let result: any[] = [];
      for (let i = day?.getDate() || 1; i <= days; i++) {
         result.push(
            <EventDay
               day={i}
               eventData={
                  tickets[
                     moment(new Date(day)).set("date", i).format("DD-MM-YYYY")
                  ]
               }
            />
         );
      }
      return result;
   };
   const startEventsRef = useRef<HTMLDivElement>(null);
   const scrollToBottom = () => {
      //@ts-ignore
      startEventsRef?.current?.scrollIntoView({ behavior: "smooth" });
   };
   const showEventsLoading = () => {
      let result: any[] = [];
      result.push(
         <div className={styles.loading_label}>
            <Preloader loading={ticketsLoading} variant={"small"} />
            <p className={styles.loading_title}>Ожидание</p>
         </div>
      );
      for (let i = day?.getDate() || 1; i <= days; i++) {
         result.push(
            <div className={styles.loading_slot + " " + styles.gradient} />
         );
      }
      return result;
   };
   const { width } = useMedia();
   const userId = useSelector(authSliceSelectors.getId);

   return (
      <>
         <div className={styles.container}>
            <div className={styles.header} ref={startEventsRef}>
               <ScheduleFiltersUi setValue={props.setValue} />
            </div>

            <div className={styles.events_container}>
               {width > 1023 ? (
                  <>
                     {tickets ? showEvents() : showEventsLoading()}
                     <button className={styles.button} onClick={scrollToBottom}>
                        {React.createElement(UpIcon)}
                        {t("Lk.Events.button-up", "Вернуться наверх")}
                     </button>
                  </>
               ) : (
                  <div className={styles.tickets}>
                     <div className={styles.label}>
                        {showDateLabel(calendarDate)}
                     </div>
                     {!tickets
                        ? showEventsLoading()
                        : tickets &&
                          tickets[
                             moment(calendarDate).format("DD-MM-YYYY")
                          ]?.hours.map((item) => {
                             return (
                                <div className={styles.timeline_container}>
                                   {ticketTypeResolver(
                                      item.tickets,
                                      userId,
                                      filters
                                   )[0] && <EventTimeLine hour={item.hour} />}
                                   <div className={styles.hours_events}>
                                      {ticketTypeResolver(
                                         item.tickets,
                                         userId,
                                         filters
                                      )}
                                   </div>
                                </div>
                             );
                          })}
                  </div>
               )}
            </div>
         </div>
      </>
   );
};
