import React, { FC, HTMLAttributes } from "react";
import styles from "./style.module.css";
import cn from "classnames";

type propsType = {
   infoKey: string;
   infoValue?: string;
   variant?: "bold";
} & HTMLAttributes<HTMLDivElement>;
export const InfoBlock: FC<propsType> = (props) => {
   return (
      <div
         className={cn(styles.container, {
            [`${props.className}`]: props.className,
            [`${styles[`${props.variant}`]}`]: props.variant,
         })}
         {...props}
      >
         <h2 className={styles.key}>{props.infoKey}</h2>
         {props.infoValue && (
            <h2 className={styles.value}>{props.infoValue}</h2>
         )}
         {props.children && (
            <div className={styles.value}>{props.children}</div>
         )}
      </div>
   );
};
