import { ReactComponent as ClassesIcon } from "../../../../assets/buttons/MenuClassesIcon.svg";
import { ReactComponent as MessagesIcon } from "../../../../assets/buttons/MenuMessagesIcon.svg";
import { ReactComponent as ScheduleIcon } from "../../../../assets/buttons/MenuScheduleIcon.svg";
import { ReactComponent as StatisticIcon } from "../../../../assets/buttons/MenuStatisticButton.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/buttons/MenuSearchIcon.svg";
import { ReactComponent as NotebookIcon } from "../../../../assets/buttons/MenuNotebookIcon.svg";
import { ReactComponent as AdminIcon } from "../../../../assets/buttons/MenuAdminSettings.svg";
import { ReactComponent as PaymentIcon } from "../../../../assets/buttons/MenuPaymentIcon.svg";
import { routes } from "routes";
import i18n from "services/translateService/i18n";
import { addMethodToUpdateLang } from "services/translateService/useLangInit";

export type navigationLinkDataType = {
   text: string;
   route: string;
   support: any;
   disabled?: boolean;
   allowedUserType?: Array<"teacher" | "inactive_teacher" | "student">;
   allowedUserRoles?: Array<"admin" | "superadmin" | "user">;
   isMobile?: boolean;
};

export let navigationLinksData: Array<navigationLinkDataType> = [];

addMethodToUpdateLang(() => {
   navigationLinksData = [
      {
         text: i18n.t("Menu.teacherButtons.admin-panel", "Панель админа"),
         route: routes.lk.admin.root,
         support: AdminIcon,
         allowedUserRoles: ["admin", "superadmin"],
      },
      {
         text: i18n.t("Menu.teacherButtons.schedule", "Расписание"),
         route: routes.lk.schedule,
         support: ScheduleIcon,
         isMobile: true,
      },
      {
         text: i18n.t("Menu.teacherButtons.add-classes", "Добавить занятия"),
         route: routes.lk.lesson.create_slot,
         support: ClassesIcon,
         allowedUserType: ["teacher"],
      },
      {
         text: i18n.t("Menu.teacherButtons.lessons-payment", "Оплата занятий"),
         route: routes.lk.payment.root,
         support: PaymentIcon,
      },
      {
         text: i18n.t("Menu.teacherButtons.financial-operations", "Финансовые операции"),
         route: routes.lk.financialOperations.root,
         support: PaymentIcon,
         allowedUserRoles: ["admin", "superadmin"],
      },
      {
         text: i18n.t("Menu.teacherButtons.notebook", "Записная книжка"),
         route: routes.lk.notebook.root,
         support: NotebookIcon,
      },
      {
         text: i18n.t("Menu.teacherButtons.statistic", "Статистика"),
         route: routes.lk.statistic.root,
         support: StatisticIcon,
      },
      {
         text: i18n.t("Menu.teacherButtons.search-classes", "Поиск занятий"),
         route: routes.lk.search_lesson.first_stage,
         support: SearchIcon,
         isMobile: true,
      },
      {
         text: i18n.t("Menu.teacherButtons.messages", "Сообщения"),
         route: routes.lk.messages + "/default",
         support: MessagesIcon,
         isMobile: true,
      },
   ];
});
