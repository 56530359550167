// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Edit_container__3M8Pb {\n    width: 350px;\n    max-width: 100%;\n    display: grid;\n    grid-template-columns: 1fr;\n    grid-row-gap: 10px;\n    justify-items: center;\n}\n\n.Edit_input__1uIgE {\n    width: 100%;\n}", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/LkProfile/Profile/Edit/index.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;IACf,aAAa;IACb,0BAA0B;IAC1B,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".container {\n    width: 350px;\n    max-width: 100%;\n    display: grid;\n    grid-template-columns: 1fr;\n    grid-row-gap: 10px;\n    justify-items: center;\n}\n\n.input {\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Edit_container__3M8Pb",
	"input": "Edit_input__1uIgE"
};
export default ___CSS_LOADER_EXPORT___;
