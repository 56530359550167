import React, { FC, useState } from "react";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";
import { StatisticCard } from "./components/StatisticCard";
import { StatisticCertificate } from "./components/StatisticCertificate";
import { BackButton } from "./components/BackButton";
import { routes } from "routes";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";

type propsType = {};

export const Statistic: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const { type, is_blocked } = useSelector(lkSliceSelectors.getUserData);

   return (
      <div className={style.container + " LkDefaultPage"}>
         <Route exact path={routes.lk.statistic.root}>
            <div className={style.header_title}>
               {t("Lk.Statistic.title-statistic", "Статистика")}
            </div>
            <div className={style.cards_container}>
               <StatisticCard role='student' />
               {type == "teacher" && !is_blocked && (
                  <StatisticCard role='teacher' />
               )}
            </div>
         </Route>
         <Route exact path={routes.lk.statistic.student}>
            <BackButton />
            <StatisticCertificate role='student' />
         </Route>
         <Route exact path={routes.lk.statistic.teacher}>
            <BackButton />
            <StatisticCertificate role='teacher' />
         </Route>
      </div>
   );
};
