import React, { FC, useState } from "react";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";
import { ReactComponent as ScheduleIcon } from "../../assets/Schedule.svg";
import { PeriodPicker } from "../PeriodPicker";
import { CardStats } from "../CardStats";
import { routes } from "../../../../../../routes";
import { Link } from "react-router-dom";
import { roleType } from "types/types";
import { useQuery } from "hooks/useQuery";
import { certificateApi } from "api/certificateApi";
import { useSelector } from "react-redux";
import { authSlice, authSliceSelectors } from "store/authSlice";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { lkSlice } from "store/lkSlice";
import moment from "moment-timezone";
import { uiSlice } from "store/modalWindowSlice";
import { errorCaseHandler } from "store/lessonSlice/thunk";
import { Preloader } from "components/UI/Preloader";
import { showDateYMDNative } from "helpers/date/showDate";

type propsType = {
   role: roleType;
};

export const StatisticCard: FC<propsType> = ({ role }) => {
   const { t } = useTranslation();
   const userId = useSelector(authSliceSelectors.getId);
   const commonState = useLocalCommonState();
   const [dateFrom, setDateFrom] = useState("");
   const [dateTo, setDateTo] = useState("");
   console.log(moment(showDateYMDNative(dateFrom)).utcOffset("+0000").format());

   const { fetch: createCertificate, isLoading: loading } = useQuery(
      () => {
         return certificateApi.create({
            user_id: userId,
            date_from: dateFrom
               ? moment(showDateYMDNative(dateFrom)).utcOffset("+0000").format()
               : undefined,
            date_to: dateTo
               ? moment(showDateYMDNative(dateTo)).utcOffset("+0000").format()
               : undefined,
            user_type: role == "teacher" ? "teacher" : "student",
         });
      },
      {
         condition: false,
         serializer: (res) => {
            if (res?.status == 201) {
               commonState.dispatch(
                  lkSlice.actions.setCertificate(res?.data.data)
               );
               commonState.location =
                  role == "teacher"
                     ? routes.lk.statistic.teacher
                     : routes.lk.statistic.student;
            } else {
               errorCaseHandler(
                  commonState.dispatch,
                  res?.data.data.errors[0]?.message,
                  "close"
               );
            }
            return res;
         },
      }
   );

   return (
      <div className={style.card}>
         <PeriodPicker
            dateFrom={dateFrom}
            dateTo={dateTo}
            setDateFrom={setDateFrom}
            setDateTo={setDateTo}
         />
         <div className={style.card_title}>
            {role === ("student" || "inactive_teacher")
               ? t("Lk.Statistic.role-student", "Студент")
               : t("Lk.Statistic.role-teacher", "Преподаватель")}
         </div>
         <CardStats dateFrom={dateFrom} dateTo={dateTo} role={role} />
         {loading ? (
            <Preloader
               style={{
                  width: 25,
                  height: 25,
                  margin: 0,
               }}
               loading={true}
            />
         ) : (
            <button
               onClick={createCertificate}
               className={style.generate_button}
            >
               {React.createElement(ScheduleIcon)}
               {t(
                  "Lk.Statistic.generate-certificate-button",
                  "Сгенерировать сертификат"
               )}
            </button>
         )}
      </div>
   );
};
