import { AxiosError, AxiosResponse } from "axios";

export const createRequest = async (request: any): Promise<AxiosResponse> => {
   try {
      return await request;
   } catch (e) {
      return (await (e as AxiosError).response) as AxiosResponse;
   }
};

export const createRequestTyped = async <T>(request: any): Promise<AxiosResponse<T>> => {
   try {
      return await request;
   } catch (e) {
      return (await (e as AxiosError).response) as AxiosResponse;
   }
};
