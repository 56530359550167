// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_timer__2NWph {\n    font-weight: 500;\n    font-size: 28px;\n    padding: 9px 18px;\n    background: #F8F8F8;\n    border-radius: 10px;\n    margin-bottom: 30px;\n    max-width: 120px;\n    text-align: center;\n}", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/LessonsService/Pages/SearchFastLesson/FastLessonHeader/style.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".timer {\n    font-weight: 500;\n    font-size: 28px;\n    padding: 9px 18px;\n    background: #F8F8F8;\n    border-radius: 10px;\n    margin-bottom: 30px;\n    max-width: 120px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timer": "style_timer__2NWph"
};
export default ___CSS_LOADER_EXPORT___;
