import React, { FC } from "react";
import styles from "./index.module.css";
import { Button } from "components/UI/Button";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSliceSelectors } from "store/authSlice";
import { routes } from "routes";
import { useTranslation } from "react-i18next";
import { modalWindowButtonType } from "store/modalWindowSlice";

type propsType = {
   title: string;
   subtitle: string;
   button?: modalWindowButtonType;
   close?: () => void;
};
export const ModalWindowBody: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const history = useHistory();
   const id = useSelector(authSliceSelectors.getId);
   const toLk = () => {
      props.close && props.close();
      history.push(routes.lk.profile + `/student/${id}`);
   };
   const createSlot = () => {
      props.close && props.close();

      history.push(routes.lk.lesson.create_slot);
   };
   return (
      <div className={styles.container}>
         <h2 className={styles.title}>{props.title}</h2>
         <p className={styles.subtitle}>{props.subtitle}</p>
         {props.button == "close" && (
            <Button onClick={props.close}>
               {t("Buttons.close", "Закрыть")}
            </Button>
         )}
         {props.button == "to_lk" && (
            <Button onClick={toLk}>
               {t("Buttons.to-lk", "В личный кабинет")}
            </Button>
         )}
      </div>
   );
};
