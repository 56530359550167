import { thunkType } from "core/LTools/core/types/types";
import { getLessonDataType } from "api/lessonApi/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { LTools } from "core/LTools/constructor";
import { userApi } from "api/userApi/methods";
import { notificationSlice } from "store/notificationSlice/index";

export const notificationThunk = {
   getAll: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {}>) {
      return createAsyncThunk("getLesson", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => userApi.getNotifications(props),
            responseReactionCreator: (response) => ({
               "200": () => {
                  commonState.dispatch(
                     notificationSlice.actions.onloadNotification({
                        is_new: false,
                        notifications: response.data.data.items,
                     })
                  );
               },
            }),
         });

         return response as any;
      })();
   },
   getNew: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {}>) {
      return createAsyncThunk("getLesson", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () =>
               userApi.getNotifications({
                  offset: "0",
                  limit: "4",
                  is_read: false,
               }),
            responseReactionCreator: (response) => ({
               "200": () => {
                  commonState.dispatch(
                     notificationSlice.actions.onloadNotification({
                        is_new: true,
                        notifications: response.data.data.items,
                     })
                  );
                  commonState.dispatch(
                     notificationSlice.actions.setCountNewNotification(
                        response.data.data.items_count
                     )
                  );
               },
            }),
         });

         return response as any;
      })();
   },
};
