import { createRequest } from "../../core/LTools/core/apiMethods/createRequest";
import { mainAxios } from "../index";
import {
   addFileToGroupLessonType,
   addStudentToGroupLessonType,
   createGroupLessonType,
   deleteGroupLessonType,
   deleteStudentFromGroupLessonType,
   getGroupLessonDataType,
   getGroupLessonFileType,
   getGroupLessonsType,
} from "./types";
import { dataToQueryString } from "../../core/LTools/core/apiMethods/dataToQueryString";
import { voteForLessonType } from "../lessonApi/types";

export const groupLessonApi = {
   createGroupLesson: async (payload: createGroupLessonType) => {
      return createRequest(mainAxios.post(`/group-lesson`, payload));
   },
   getGroupLessons: async ({ user_id, ...query }: getGroupLessonsType) => {
      return createRequest(
         mainAxios.get(
            `user/${user_id}/group-lesson${dataToQueryString(query)}`
         )
      );
   },
   getGroupLessonData: async ({ group_lesson_id }: getGroupLessonDataType) => {
      return createRequest(mainAxios.get(`/group-lesson/${group_lesson_id}`));
   },
   deleteGroupLesson: async ({ group_lesson_id }: deleteGroupLessonType) => {
      return createRequest(
         mainAxios.delete(`/group-lesson/${group_lesson_id}`)
      );
   },
   addStudentToGroupLesson: async ({
      group_lesson_id,
   }: addStudentToGroupLessonType) => {
      return createRequest(
         mainAxios.put(`/group-lesson/${group_lesson_id}/join`)
      );
   },
   deleteStudentFromLesson: async ({
      group_lesson_id,
   }: deleteStudentFromGroupLessonType) => {
      return createRequest(
         mainAxios.put(`/group-lesson/${group_lesson_id}:leave`)
      );
   },
   addFileToGroupLesson: async ({
      group_lesson_id,
      ...file
   }: addFileToGroupLessonType) => {
      return createRequest(
         mainAxios.post(`/group-lesson/${group_lesson_id}/file`, file)
      );
   },
   getGroupLessonFile: async ({
      group_lesson_id,
      file_id,
   }: getGroupLessonFileType) => {
      return createRequest(
         mainAxios.get(`/group-lesson/${group_lesson_id}/file/${file_id}`)
      );
   },
   vote: async ({ lesson_id, ...vote }: voteForLessonType) => {
      return createRequest(
         mainAxios.post(`/group-lesson/${lesson_id}/vote`, vote)
      );
   },
};
