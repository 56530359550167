// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_timeline__-WNRa {\n    width: 100%;\n    margin-top: 20px;\n    font-family: var(--font-Source-Sans-Pro);\n    font-style: normal;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 18px;\n    color: var(--color-black2);\n    display: flex;\n    align-items: center;\n    white-space: nowrap;\n    margin-bottom: 12px;\n}\n.style_timeline__-WNRa:after {\n    margin-left: 12px;\n    content: '';\n    width: 100%;\n    height: 1px;\n    background-color: var(--color-black2);\n}", "",{"version":3,"sources":["webpack://src/components/UI/EventTimeLine/style.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,wCAAwC;IACxC,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,0BAA0B;IAC1B,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,iBAAiB;IACjB,WAAW;IACX,WAAW;IACX,WAAW;IACX,qCAAqC;AACzC","sourcesContent":[".timeline {\n    width: 100%;\n    margin-top: 20px;\n    font-family: var(--font-Source-Sans-Pro);\n    font-style: normal;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 18px;\n    color: var(--color-black2);\n    display: flex;\n    align-items: center;\n    white-space: nowrap;\n    margin-bottom: 12px;\n}\n.timeline:after {\n    margin-left: 12px;\n    content: '';\n    width: 100%;\n    height: 1px;\n    background-color: var(--color-black2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timeline": "style_timeline__-WNRa"
};
export default ___CSS_LOADER_EXPORT___;
