import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCodeFieldLogic } from "../../../UI/Input/CodeField/useCodeFieldLogic";
import { CodeField } from "../../../UI/Input/CodeField";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { routes } from "routes";
import { TextLock } from "assets/inputs/TextLock";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { authSlice, authSliceSelectors } from "store/authSlice";
import { authThunks } from "store/authSlice/thunks";
import { useTranslation } from "react-i18next";
import styles from "./index.module.css";
import { Button } from "../../../UI/Button";
import { Preloader } from "components/UI/Preloader";
import { Input } from "components/UI/Input";
type propsType = {};
type registrationCodeFormType = {
   email: string;
};
export const RegistrationCode: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const { template, code: codeFromUrl } = useParams<{
      template: string;
      code: string;
   }>();
   /* State */
   const history = useHistory();
   const email = useSelector(authSliceSelectors.getEmail);
   const id = useSelector(authSliceSelectors.getId);
   const isRestoring = useSelector(authSliceSelectors.getIsRestoring);
   const [code, setCode] = useState("");
   const { control } = useForm<registrationCodeFormType>({
      defaultValues: {
         email: email,
      },
   });
   const commonState = useLocalCommonState();
   const codeField = useCodeFieldLogic({
      onChange: (val) => setCode(val),
      codeLength: 4,
      defaultValue: codeFromUrl,
   });
   /* methods */
   const otherAccountAction = () => {
      history.push(routes.registration.auth.firstStage);
   };
   const sendCodeHandler = () => {
      commonState.dispatch(
         authThunks.createCode({
            commonState,
            template:
               isRestoring || template == "restore" ? "restore" : "register",
         })
      );
   };

   const submit = () => {
      commonState.dispatch(
         authThunks.checkCode({
            commonState,
            code,
            callbacks: {
               204: () => {
                  if (isRestoring || template == "restore") {
                     commonState.dispatch(
                        authSlice.actions.setIsRestoring(false)
                     );
                     commonState.location =
                        routes.registration.auth.restorePassword;
                  } else {
                     commonState.location =
                        routes.registration.auth.createPassword;
                  }
               },
               404: () => {
                  commonState.errors = {
                     code: "Неверный код",
                  };
               },
            },
         })
      );
   };

   /* Логика для обычного ввода кода */
   useEffect(() => {
      if (code.length == 4 && !codeFromUrl) {
         submit();
      }
   }, [code]);

   /**
    * Логика для ввода кода через урл
    * условия нужны для того чтобы убедиться
    * что autoAuth засетил данные из лк
    * */
   useEffect(() => {
      if (
         codeFromUrl?.length == 4 &&
         id != null &&
         email != null &&
         code.length == 4
      ) {
         submit();
      }
   }, [codeFromUrl, id, email, code]);

   return (
      <form className='flex-col'>
         <Preloader loading={commonState.loading} variant='fixed' />
         <Input
            control={control}
            name='email'
            label={t(
               "Registration.Auth.RegistrationCode.email-input-label",
               "Ваш email"
            )}
            support={<TextLock />}
            value='example@gmail.com'
            readOnly
         />
         <p className={styles.text}>
            {t(
               "Registration.Auth.RegistrationCode.note",
               "На указанный электронный адрес было отправлено письмо с кодом для подтверждения аккаунта"
            )}
         </p>
         <Button form={"_"} variant='link' className={styles.button_link}>
            <a target='_blank' href={"https://gmail.com"}>
               {t(
                  "Registration.Auth.RegistrationCode.email-link",
                  "Перейти в почтовый ящик"
               )}
            </a>
         </Button>
         <Button
            form={"_"}
            onClick={otherAccountAction}
            variant='link'
            className={styles.button_link}
         >
            {t(
               "Registration.Auth.RegistrationCode.another-account",
               "Другой аккаунт"
            )}
         </Button>
         <h3 className={styles.action_title}>
            {t(
               "Registration.Auth.RegistrationCode.enter-email-code",
               "Введите код из письма"
            )}
         </h3>
         <div className={styles.code}>
            <CodeField
               sendCodeHandler={sendCodeHandler}
               error={commonState.errors.code}
               {...codeField}
            />
         </div>
      </form>
   );
};
