import React, { FC } from "react";
import { useQuery } from "hooks/useQuery";
import { applicationApi } from "api/applicationApi/methods";
import { useSelector } from "react-redux";
import { authSliceSelectors } from "store/authSlice";
import { LessonTitle } from "../../components/LessonTitle";
import { useTranslation } from "react-i18next";
import { LessonBody } from "../../components/LessonBody";
import { Button } from "components/UI/Button";
import styles from "./style.module.css";
import { UserWidget } from "components/UI/UserWidget";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { lessonThunk } from "store/lessonSlice/thunk";
import { suggestionType } from "store/fastLessonSlice/types";
import { useParams } from "react-router-dom";
import { routes } from "routes";
import { Preloader } from "components/UI/Preloader";
import { useGoToProfile } from "helpers/util/goToProfile";

export const ApplicationLesson: FC = () => {
   const { t } = useTranslation();
   const user_id = useSelector(authSliceSelectors.getId);
   const commonState = useLocalCommonState();
   const { id } = useParams<{ id: string }>();
   const goToProfile = useGoToProfile(user_id);

   /* fetch */
   const { data: suggestion } = useQuery(
      () =>
         applicationApi.getSuggestions({
            user_id,
            offset: "0",
            limit: "100",
            suggestion_statuses: ["new_suggestion"],
         }),
      {
         serializer: (res) => {
            const suggestion = res?.data.data.items.find(
               (item: any) => item.id == id
            ) as suggestionType;
            if (!suggestion) {
               goToProfile();
            }

            return suggestion;
         },
      }
   );

   const createLessonFromApplication = () => {
      commonState.dispatch(
         lessonThunk.createLessonFromApplication({
            commonState,
            application_id: String(suggestion?.application.id),
            suggestion_id: String(suggestion?.id),
            callbacks: {
               201: (res) => {
                  commonState.location =
                     routes.lk.lesson.default.view + `/${res?.data.data.id}`;
               },
            },
         })
      );
   };

   const deleteSuggestion = () => {
      commonState.dispatch(
         lessonThunk.deleteSuggestion({
            commonState,
            application_id: String(suggestion?.application.id),
            suggestion_id: String(suggestion?.id),
            callbacks: {
               204: (res) => {
                  commonState.location = routes.lk.profile + `/${user_id}`;
               },
            },
         })
      );
   };

   const getApplicationFile = async (file_id: string) => {
      return await applicationApi.getApplicationFile({
         application_id: String(suggestion?.application?.id),
         file_id,
      });
   };

   if (!suggestion)
      return <Preloader loading={true} variant='lesson-service' />;
   return (
      <div>
         <LessonTitle
            title={t(
               "Lk.lesson-service.application.title",
               "Ожидание подтверждения"
            )}
         />
         <main className='Lessons__main'>
            <UserWidget
               user={suggestion?.application?.student}
               variant={"student"}
            />
            <LessonBody
               subject={{
                  ...suggestion.application.subject,
                  education_system:
                     suggestion.application.subject.education_system,
               }}
               date_from={suggestion.application.created_at}
               date_to={undefined}
               duration={suggestion.application.duration}
               topic={suggestion.application.topic}
               grade={suggestion.application.grade}
               language={suggestion.application.language}
               files={suggestion.application.files}
               getFileMethod={getApplicationFile}
            />
            <div className={styles.buttons}>
               <Button
                  onClick={createLessonFromApplication}
                  className={styles.button}
                  variant='primary'
               >
                  {t("Lk.lesson-service.invite.accept", "Подтвердить заявку")}
               </Button>
               <Button variant='red' onClick={deleteSuggestion}>
                  {t("Lk.lesson-service.invite.cancel", "Отклонить заявку")}
               </Button>
            </div>
         </main>
      </div>
   );
};
