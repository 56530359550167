import moment from "moment-timezone";
import { lc } from "store/LC";
import { arrayOfTimezones } from "staticData/timezone";

export const getTimeDifference = (
   startTime: moment.Moment | string,
   endTime: moment.Moment | string
) => {
   startTime = moment(startTime);
   endTime = moment(endTime);

   // Вычисляет время в мс между датами
   const duration = moment.duration(endTime.diff(startTime));

   // Возвращает разницу в минутах дробным числом
   const minutes = duration.asMinutes();

   // Возвращает разницу в часах дробным числом
   const hours = duration.asHours();

   // Если разница больше 60 минут, фунцкия возращает строку только с часами
   let formatted =
      `${Math.round(hours * 10) / 10} hour` +
      (Math.floor(hours) !== 1 ? "s" : "");

   // Если разница меньше 60 минут, фунцкия возвращает строку только с минутами
   if (duration.asMinutes() < 60) {
      formatted =
         `${Math.floor(minutes)} minute` +
         (Math.floor(minutes) !== 1 ? "s" : "");
   }

   const raw = (arg: "minutes" | "hours") => {
      if (arg === "minutes") {
         return duration.asMinutes();
      }
      if (arg === "hours") {
         return duration.asHours();
      }
   };

   return {
      raw,
      formatted: duration.hours() + "." + duration.minutes(),
   };
};

export const addZeroToNumber = (number: number | string) => {
   return Number(number) <= 9 ? "0" + number : number;
};

export const dateMethods = {
   calculateTimeDifference: (
      timeFrom: Date | string,
      timeTo: Date | string
   ) => {
      const t1 = moment(timeTo);
      const t2 = moment(timeFrom);
      const diff = t1.diff(t2);

      return diff / 3600000;
   },

   getTwoDatesByDateAndInterval: (dates: {
      date: string;
      date_from: string;
      duration: string;
   }) => {
      const date_from = moment(dates.date)
         .add(dates.date_from, "hours")
         .utcOffset("+0000")
         .format();
      const date_to = moment(date_from)
         .add(dates.duration, "minutes")
         .utcOffset("+0000")
         .format();

      return {
         date_from,
         date_to,
      };
   },

   getTime: (date: string | Date) => {
      return moment(date).format("HH:mm");
   },

   getTimeInterval: (date_from: string, date_to: string) => {
      return (
         moment(date_from).format("HH:mm") +
         "-" +
         moment(date_to).format("HH:mm")
      );
   },

   format: (date: string | Date, options?: string) => {
      return moment(date).format(options);
   },

   formatDMY: (date: string | Date) => {
      const dateObject = new Date(date);

      return `${dateObject.getFullYear()}-${addZeroToNumber(
         dateObject.getMonth() + 1
      )}-${addZeroToNumber(dateObject.getDate())}T00:00:00Z`;
   },

   calculateLessonDuration: (date_from: string, date_to: string) => {
      const startTime = moment(date_from);
      const endTime = moment(date_to);

      const durationInMilliseconds = endTime.diff(startTime);

      const durationInMinutes = moment.duration(durationInMilliseconds).asMinutes();

      if (durationInMinutes < 60) {
         return `${Math.round(durationInMinutes)} minute${durationInMinutes !== 1 ? 's' : ''}`;
      } else {
         const hours = Math.floor(durationInMinutes / 60);
         const minutes = Math.round(durationInMinutes % 60);
         return `${hours} hour${hours !== 1 ? 's' : ''} ${minutes} minute${minutes !== 1 ? 's' : ''}`;
      }
   }
};

export const getDateWithLocalOffset = (date: Date | string) => {
   const tzInLc = lc.get("timeZone");
   const tz = arrayOfTimezones
      .find((item) => item.name == tzInLc)!
      .value.replace(":", "");
   return moment(date).utcOffset(tz, true).format();
};
