export const base64Correcter = (base64?: string) => {
   if (!base64) return "";
   const indexOfData = base64.indexOf("data");
   const fileType = base64.slice(indexOfData + 4, base64.indexOf("/"));
   const mimeType = base64.slice(
      base64.indexOf("/") + 1,
      base64.indexOf("base64")
   );
   const data = base64.slice(base64.indexOf("base64") + 6);
   return `data:${fileType}/${mimeType};base64,${data}`;
};

export const getFileFromEvent = <T>(file: any): Promise<T> => {
   return new Promise((resolve, reject) => {
      try {
         const reader = new FileReader();
         if (file) {
            reader.readAsDataURL(file);
         }
         const extension = (file.name as string).substr(
            (file.name as string).lastIndexOf(".")
         );

         reader.onloadend = async function () {
            resolve({
               //@ts-ignore
               value: reader.result as string,
               file_name: file.name,
               type: file.type,
               extension,
            });
         };
      } catch (e) {
         console.log(e);
         reject("Ошибка загрузки файла");
      }
   });
};

export const downloadFile = (data: any, name?: string, target?: "_blank") => {
   const dataArr = data.split(";base64,");
   const contentType = dataArr[0].slice(5);
   const base64Data = dataArr[1];

   try {
      let raw = atob(base64Data);
      let uInt8Array = new Uint8Array(raw.length);
      for (let i = 0; i < raw.length; ++i) {
         uInt8Array[i] = raw.charCodeAt(i);
      }

      const link = document.createElement("a");
      const blob = new Blob([uInt8Array], { type: contentType });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);

      link.setAttribute("download", name || "File");
      if (target) {
         link.setAttribute("target", target);
      }
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
   } catch (e) {
      console.log(e);
   }
};
