import React, { FC, HTMLAttributes, useEffect } from "react";
import styles from "./index.module.css";
import { Switcher } from "components/UI/Switcher";
import { useForm, useWatch } from "react-hook-form";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { useTranslation } from "react-i18next";
import { useQuery } from "hooks/useQuery";
import { userApi } from "api/userApi/methods";
import { useDispatch, useSelector } from "react-redux";
import { authSliceSelectors } from "store/authSlice";
import { lkSlice, lkSliceSelectors } from "store/lkSlice";
import cn from "classnames";

type propsType = {} & HTMLAttributes<HTMLDivElement>;
export const OnlineStatusSwitcher: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const { control, setValue } = useForm({
      reValidateMode: "onSubmit",
   });
   const { id } = useSelector(lkSliceSelectors.getUserData);
   const isOnline = useSelector(lkSliceSelectors.getIsOnline);
   const isOnlineField = useWatch({
      control,
      name: "status",
   });
   const dispatch = useDispatch();

   const changeStatus = useQuery(
      () =>
         userApi.changeOnlineStatus({
            user_id: id,
            status: isOnline ? "offline" : "online",
         }),
      {
         condition: false,
         serializer: (res) => {
            dispatch(lkSlice.actions.setIsOnline(!isOnline));
            return res;
         },
      }
   );

   useEffect(() => {
      if (id !== null && isOnline !== undefined && isOnline !== null) {
         setValue("status", isOnline);
      }
   }, [isOnline]);

   if (id === undefined) return null;
   return (
      <Switcher
         onChange={changeStatus.fetch}
         className={cn(styles.container, props.className)}
         name='status'
         control={control}
         falseStatus={"Offline"}
         trueStatus={t("Lk.Profile.status-switcher-true", "Готов обучать")}
         variant={"status"}
         disabled={changeStatus.isLoading}
      />
   );
};
