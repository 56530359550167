import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ru from "../../i18n/locales/ru/common.json";
import en from "../../i18n/locales/en/common.json";
import es from "../../i18n/locales/es/common.json";
import de from "../../i18n/locales/de/common.json";
import it from "../../i18n/locales/it/common.json";
import fr from "../../i18n/locales/fr/common.json";
//@ts-ignore
let langFromLc = localStorage.getItem("lang");

i18n.use(initReactI18next).init({
   resources: {
      en: { translation: en },
      ru: { translation: ru },
      es: { translation: es },
      de: { translation: de },
      it: { translation: it },
      fr: { translation: fr },
   },
   lng: langFromLc || "en",
   fallbackLng: "en",
   interpolation: {
      escapeValue: false,
   },
});
export default i18n;
