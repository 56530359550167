import React, { FC } from "react";
import styles from "./style.module.css";
import { useForm } from "react-hook-form";
import { Button } from "../../../UI/Button";
import { routes } from "routes";
import { useTranslation } from "react-i18next";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { lkSlice } from "store/lkSlice";
import i18n from "../../../../services/translateService/i18n";
import { SelectSubjectArea } from "components/Registration/TeacherPages/TeacherRegistrationChooseSubjects/components/SelectSubjectArea";

export const TeacherRegistrationChooseSubjects: FC = () => {
   const { t } = useTranslation();
   const { control, handleSubmit, setValue } = useForm<any>();
   const commonState = useLocalCommonState();

   const onSubmit = (data: any) => {
      const serializedSubjects = data.subjects
         .filter((item: string) => !!item)
         ?.map((item: string) => JSON.parse(item));

      commonState.dispatch(
         lkSlice.actions.updateTeacherForm({
            subjects: serializedSubjects,
         })
      );
      commonState.location = routes.registration.teacher.files;
   };

   return (
      <div className={styles.container}>
         <h2 className={"RegistrationTeacher__title"}>
            {i18n.t(
               "Registration.titles.education-system",
               "Выберите предметы, которые вы будете преподавать"
            )}
         </h2>
         <SelectSubjectArea
            control={control}
            onSubmit={handleSubmit(onSubmit)}
            setValue={setValue}
         />
         <Button
            className={"Registration__button"}
            variant={"primary"}
            type='submit'
            form='form'
         >
            {t(
               "Registration.TeacherPages.TeacherInstructionsSystems.continue-button",
               "Продолжить"
            )}
         </Button>
      </div>
   );
};
