import React, { AllHTMLAttributes, FC } from "react";
import styles from "./index.module.css";
import cn from "classnames";
import {Preloader} from "components/UI/Preloader";

type propsType = {
   type?: "button" | "submit" | "reset" | undefined;
   onClick?: any;
   form?: any;
   bgColor?: "gray" | "gray-no-hover";
   loading? : boolean
   variant?: "primary" | "red" | "secondary" | "decision" | "link" | "outline" | "filters";
} & AllHTMLAttributes<HTMLButtonElement>;
export const Button: FC<propsType> = (props) => {
   const {
      className,
      disabled,
      variant = "primary",
      children,
      bgColor,
       loading = false,
      ...other
   } = props;

   return (
      <button
         {...other}
         disabled={disabled || loading}
         className={cn({
            [styles.button]: true,
            [styles[`${variant}`]]: true,
            [styles["button--disabled"]]: disabled,
            [styles[`button--${bgColor}`]]: bgColor,
             [styles['button-loading']] : loading,
            [`${className}`]: className,
         })}
      >
         {!loading ? children : <Preloader className={styles.loading}  loading={loading} />}
      </button>
   );
};
