import item_styles from "components/Lk/Pages/Notebook/components/NotePeoples/components/PeopleTableItems/index.module.css";
import { ReactComponent as Star } from "assets/icons/StarRating.svg";
import { ReactComponent as ToChat } from "assets/icons/toChat.svg";
import React from "react";
import { TableElementType } from "components/Lk/Pages/Notebook/components/NotePeoples/index";
import { useNotebookPeopleTableHandlers } from "components/Lk/Pages/Notebook/components/NotePeoples/components/PeopleTableItems/useNotebookPeoleTableHandlers";
import { Icons } from "assets/iconsCorrect";
import styles from "components/Lk/Pages/Notebook/components/NotePeoples/style.module.css";
import { Preloader } from "components/UI/Preloader";

export const TeacherTableItem = ({
   user,
   is_like,
   user_type,
   id,
}: TableElementType) => {
   const methodsAndFields = useNotebookPeopleTableHandlers({
      user_type,
      is_like,
      user,
      id,
   });

   if (!user) return null;
   return (
      <tr className={item_styles.container}>
         <td
            onClick={methodsAndFields.likeHandler}
            className={item_styles.like}
         >
            {methodsAndFields.isLikingProcess ? (
               <Preloader loading style={{ width: 20, height: 20 }} />
            ) : methodsAndFields.liked ? (
               <Icons.ui.likeIconFilled className={styles.like_icon} />
            ) : (
               <Icons.ui.likeIconEmpty className={styles.like_icon} />
            )}
         </td>
         <td className={item_styles.info}>
            <div
               className={item_styles.row}
               onClick={methodsAndFields.goToUserProfile}
               style={{
                  cursor: "pointer",
               }}
            >
               <img
                  className={item_styles.avatar}
                  src={methodsAndFields.avatar}
                  alt=''
               />
               <div className={item_styles.link}>
                  <p className={item_styles.name}>{user.first_name}</p>
                  <p className={item_styles.name}>{user.last_name}</p>
               </div>
            </div>
         </td>
         <td className={item_styles.rate}>
            <p className={item_styles.rateNumber}>
               <Star className={item_styles.star} />
               {user?.teaching_rating?.toFixed(2) || "—"}
            </p>
         </td>
         <td className={item_styles.education_system}>
            <p className={item_styles.text}>
               {user.instruction_education_systems?.[0]?.name}
            </p>
            {/*{user.instruction_education_systems.length > 1 && (*/}
            {/*   <button className={item_styles.showMore}>Показать еще</button>*/}
            {/*)}*/}
         </td>
         <td className={item_styles.study_grade}>
            <p className={item_styles.text}>
               {user?.study_education_system?.level?.name || "—"}
            </p>
         </td>
         <td
            className={item_styles.toChat}
            onClick={methodsAndFields.createChatWithUser}
         >
            <ToChat />
         </td>
      </tr>
   );
};
