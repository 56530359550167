import React, { FC, useEffect, useState } from "react";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { lessonApi } from "api/lessonApi/methods";
import { useForm } from "react-hook-form";
import { Rates } from "components/UI/Rates";
import { voteType } from "store/types";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";
import { notificationPropsType } from "components/Lk/components/Notifications/types";
import { groupLessonApi } from "api/groupLessonApi/methods";
import { Preloader } from "components/UI/Preloader";

type propsType = {
   notification: notificationPropsType;
   isGroupLesson?: boolean;
};
export const NotificationCardRating: FC<propsType> = (props) => {
   const { notification, isGroupLesson = false } = props;
   const user = useSelector(lkSliceSelectors.getUserData);

   const userVote: voteType | undefined = notification.entity.votes?.find(
      (item) => item.initiator_id === Number(user.id)
   );
   const { t } = useTranslation();

   const [isLoading, setIsLoading] = useState<boolean>(false);

   const {
      setValue,
      control,
      unregister,
      register,
      watch,
      reset,
      handleSubmit,
      clearErrors,

      formState: { errors },
   } = useForm({
      reValidateMode: "onSubmit",
   });

   // @ts-ignore
   const watchRates = watch("rate" + notification.entity.id);

   useEffect(() => {
      if (watchRates && !userVote) {
         voteForLesson(watchRates);
      }
   }, [watchRates]);

   function voteForLesson(rates: number) {
      setIsLoading(true);
      !isGroupLesson &&
         lessonApi
            .voteForLesson({
               lesson_id: String(notification.entity.id),
               rating: rates,
               target:
                  notification.entity.teacher?.id === user.id
                     ? "student"
                     : "teacher",
            })
            .then((response) => {
               setIsLoading(false);
            })
            .catch((e) => {
               setIsLoading(false);
            });
      isGroupLesson &&
         groupLessonApi
            .vote({
               lesson_id: String(notification.entity.id),
               rating: rates,
               target:
                  notification.entity.teacher?.id === user.id
                     ? "student"
                     : "teacher",
            })
            .then((response) => {
               setIsLoading(false);
            })
            .catch((e) => {
               setIsLoading(false);
            });
   }

   return (
      <div className={styles.container}>
         <div className={styles.title}>
            {t("Lk.Notifications.Rating.title", "Оцените ваше занятие")}
         </div>
         <div className={styles.rate}>
            <div className={styles.count}>-</div>
            {isLoading ? (
               <Preloader loading style={{ width: 30, height: 30 }} />
            ) : (
               <Rates
                  id={"rate" + notification.id}
                  value={userVote?.rating}
                  readOnly={Boolean(userVote) || Boolean(watchRates)}
                  name={"rate" + notification.entity.id}
                  control={control}
               />
            )}
         </div>
      </div>
   );
};
