import i18n from "services/translateService/i18n";
import { addMethodToUpdateLang } from "services/translateService/useLangInit";
import moment from "moment-timezone";
import { SelectItemType } from "components/UI/Select/types";

export let renderDurationOptions: (
   boundary?: number
) => Array<{
   id: number;
   text: string;
   value: {
      time: string;
      text: string;
      minutes: string;
   };
}> = () => [];

addMethodToUpdateLang(() => {
   renderDurationOptions = function (boundary) {
      const step = 15;
      let options: any = [];
      const topBoundary = boundary !== undefined ? boundary + 1 : undefined;

      for (let i = 1; i < (topBoundary || 9) && i < 9; i++) {
         options.push({
            id: i,
            text: `${step * i} ${i18n.t("Common.minutes", "минут")}`,
            value: {
               time: `${step * i} minutes`,
               text: `${step * i} ${i18n.t("Common.minutes", "минут")}`,
               minutes: `${step * i}`,
            },
         });
      }
      return options;
   };
});

export let renderStartTimeOptions: (
   val?: number
) => Array<{
   id: number;
   text: string;
   value: {
      text: string;
      hours: string;
      minutes: string;
   };
}>;

renderStartTimeOptions = function (bottomBoundariesMinutes?: number) {
   const date = moment().startOf("day");

   if (bottomBoundariesMinutes) {
      date.add(bottomBoundariesMinutes, "minutes");
   }
   const bottomBoundary = bottomBoundariesMinutes
      ? bottomBoundariesMinutes / 15
      : 0;
   const step = 15;

   let options: any = [];
   for (let i = bottomBoundary; i < 96; i++) {
      date.add(Number(!!i) * step, "minutes");
      options.push({
         id: i,
         text: date.format("HH.mm"),
         value: {
            text: date.format("HH.mm"),
            hours: date.hours(),
            minutes: date.minutes(),
         },
      });
   }
   return options;
};

export let sortingOptions: Array<SelectItemType<"asc" | "desc">> = [
   {
      id: 1,
      text: i18n.t("SelectOptions.sorting.ascending", "От новых к старым"),
      value: "desc",
   },
   {
      id: 2,
      text: i18n.t("SelectOptions.sorting.descending", "От старых к новым"),
      value: "asc",
   },
];
addMethodToUpdateLang(() => {
   sortingOptions = [
      {
         id: 1,
         text: i18n.t("SelectOptions.sorting.ascending", "От новых к старым"),
         value: "desc",
      },
      {
         id: 2,
         text: i18n.t("SelectOptions.sorting.descending", "От старых к новым"),
         value: "asc",
      },
   ];
});
