import React, { FC, HTMLAttributes } from "react";
import styles from "./index.module.css";
import cn from "classnames";

type propsType = {
   count?: number;
   variant?: "default" | "blue";
} & HTMLAttributes<HTMLDivElement>;
export const CircledCounter: FC<propsType> = ({
   count,
   variant = "default",
   ...props
}) => {
   return (
      <div
         className={cn(styles.container, {
            [`${props.className}`]: props.className,
            [`${styles[`${variant}`]}`]: true,
         })}
      >
         {count}
      </div>
   );
};
