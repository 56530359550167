import React, { FC, HTMLAttributes } from "react";
import styles from "./index.module.css";

import { studentType } from "../../../../../../../types/userType";
import { useGetUserAvatar } from "../../../../../../../hooks/useGetUserAvatar";

type SendChatToAdminItemType = {
   admin: studentType;
} & HTMLAttributes<HTMLDivElement>;

export const SendChatToAdminItem: FC<SendChatToAdminItemType> = (props) => {
   const { admin, ...other } = props;

   const [avatar] = useGetUserAvatar(admin.id);

   return (
      <div className={styles.container} {...other}>
         <img className={styles.avatar} src={avatar} alt='' />
         <div className={styles.names}>
            <p>{admin.first_name}</p>
            <p>{admin.last_name}</p>
         </div>
      </div>
   );
};
