import { AxiosResponse } from "axios";
import { commonMethodProps, statusHandlersType } from "../types/types";
import { methodResolver } from "../../constructor";

type responseHandlerPropsType<T> = {
   request: () => Promise<AxiosResponse<any>>;
   responseReactionCreator: (
      response: AxiosResponse<any>
   ) => statusHandlersType;
} & commonMethodProps<T>;

type makeRequestAndResolveConstructorType = {
   nonResponseCallback: () => void;
};

export const makeRequestAndResolveConstructor = ({
   // Принимает колбек, чтобы вызвать его при ошибке
   nonResponseCallback,
}: makeRequestAndResolveConstructorType) => {
   return async function <T>(props: responseHandlerPropsType<T>) {
      const {
         request,
         commonState,
         responseReactionCreator,
         callbacks,
         requestMethod,
      } = props;

      // Ставит лоадинг в commonState на тру и ждет выполнения запроса
      commonState.loading = true;
      const response = await request();
      commonState.loading = false;
      /* break if request was canceled */
      // При отмене вызывает колбек, который мы передали в аргументе, и делает ретерн
      if (!response) {
         nonResponseCallback();

         return;
      }

      // responseReactionCreator берется из санок. В санках описаны коды ответа и реакция на них
      const methodsDefinition = responseReactionCreator(response);

      /* define behavior */
      methodResolver({
         response,
         statusHandlers: methodsDefinition,
         callbacks,
         commonState,
         request,
         requestMethod,
      });

      return response;
   };
};
