import { userApi } from "api/userApi/methods";
import { useDispatch, useSelector } from "react-redux";
import { cacheSlice, cacheSliceSelectors } from "store/cacheSlice";
import { useEffect, useState } from "react";
import avatarMock from "assets/iconsCorrect/avatars/mockAvatar.png";

var tempCaching: Array<string> = [];

export const useGetUserAvatar = (
   id?: string,
   disableCachne?: boolean
): [string, () => void] => {
   const cachedAvatars = useSelector(cacheSliceSelectors.getAvatars);
   const [currentAvatar, setCurrentAvatar] = useState<string>("");
   const avatar = cachedAvatars[id || ""];

   const fetch = async () => {
      if (id == undefined) return;

      const response = await userApi.getAvatar({
         user_id: String(id),
      });
      if (response && (response.status == 200 || response.status == 304)) {
         setCurrentAvatar(response?.data?.data?.content);
      }
   };

   useEffect(() => {
      if (!id) return;
      fetch().finally();
   }, [id]);

   return [avatar || currentAvatar || avatarMock, fetch];
};
