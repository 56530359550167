import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { useForm, useWatch } from "react-hook-form";
import { ReactComponent as LogoSmall } from "./assets/logoSmall.svg";
import { ReactComponent as SearchIcon } from "./assets/search.svg";
import { ReactComponent as ArrowLeft } from "./assets/arrow_left.svg";
import { DialogItem } from "./components/DialogItem";
import { sortDate } from "./functions/sort";
import { MessagesArea } from "./components/MessagesArea";
import { Input_module } from "../../../UI/Input-module";
import { useSelector } from "react-redux";
import { chatSliceSelectors, DialogType } from "store/chatSlice";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { ChatThunk } from "store/chatSlice/thunk";
import { lkSliceSelectors } from "store/lkSlice";
import { ReportPop } from "components/Lk/Pages/Messages/components/ReportPop";
import { LoadedDialogItem } from "components/Lk/Pages/Messages/components/LoadedDialogItem";
import { useParams } from "react-router-dom";
import { routes } from "../../../../routes";
import {
   SendChatToAdmin,
   SendChatToAdminPop,
} from "./components/sendChatToAdmin";
import { uiSlice } from "store/modalWindowSlice";
import { TypeSwitcher } from "components/Lk/Pages/Messages/components/TypeSwitcher";

type FormType = {
   searchInput: string;
   selectAdminsChat: boolean;
};

/**
 * isAdminsChat == watchSelectAdminsChat
 * создан для предотвращения замыкания
 * */

var isAdminsChat: boolean = false;
var NeedPing: boolean = true;

/**
 *  @i18n key: Messages
 * */

export const Messages: FC = (props) => {
   /**
    *   Получение user_id и диалогов из стора
    *   вытаскиваем query
    * */

   const dialogs = useSelector(chatSliceSelectors.getDialogs);
   const adminsDialogs = useSelector(chatSliceSelectors.getAdminsDialogs);

   const isLoadedAdminsDialogs = useSelector(
      chatSliceSelectors.getLoadingAdminsDialogs
   );
   const isLoadedDialogs = useSelector(chatSliceSelectors.getLoadingDialogs);

   const { t } = useTranslation();
   const lang = useSelector(lkSliceSelectors.getLang);
   const my_user = useSelector(lkSliceSelectors.getUserData);

   const { type, chat_id } = useParams<{
      type: "default" | "help";
      chat_id: string;
   }>();

   /**
    * Hook Form и useWatch для получения значения InputSearch, который служит для фильтрации диалогов
    * */

   const { control } = useForm<FormType>({
      reValidateMode: "onSubmit",
   });

   const searchDialogsInput = useWatch({
      control,
      name: "searchInput",
      defaultValue: "",
   });

   function selectChat(id: number) {
      const selectedChatFromDialogs = [...dialogs, ...adminsDialogs].find(
         (item) => item.id === id
      );

      if (!selectedChatFromDialogs?.users) {
         return;
      }

      /**
       * Админский&user - Администрация
       * Админский&otherRole - Администрация
       */
      let name: string = "";
      const getAnotherUserName = () => {
         const user = selectedChatFromDialogs.users.find(
            (item) => item.id !== my_user.id
         );
         if (!user) return "";
         return user!.first_name + " " + user!.last_name;
      };

      if (selectedChatFromDialogs.is_admin_chat) {
         if (my_user.role === "user") {
            name = t("Messages.administration", "Администрация");
         } else {
            name = getAnotherUserName();
         }
         commonState.location =
            routes.lk.messages +
            `/${my_user.role === "user" ? "default" : "help"}/` +
            id;
      } else {
         name = getAnotherUserName();
         commonState.location = routes.lk.messages + "/default/" + id;
      }
      onSelectChat(id, name);
   }

   /**
    * Создаем стейт, где храним имена всех пользователей в конкректном чате
    * так же методы
    * */

   const [DialogsUsersNames, setDialogsUsersNames] = useState<string[]>(
      updateDialogsUsersNames(dialogs)
   );

   /**
    * @updateDialogsUsersNames
    * @params: массив диалогов(DialogType)
    * */
   function updateDialogsUsersNames(list: DialogType[]) {
      return list.map((item) => {
         let usersName: string = "";
         item.users.map((user) => {
            if (my_user.id !== user.id)
               usersName += user.first_name + " " + user.last_name + " ";
         });
         return usersName;
      });
   }

   useEffect(() => {
      chat_id && selectChat(Number(chat_id));
   }, [chat_id, lang]);

   /**
    * Обновляем стейт с именами при изменении диалогов
    * */
   useEffect(() => {
      setDialogsUsersNames(
         updateDialogsUsersNames(type == "default" ? dialogs : adminsDialogs)
      );
      chat_id && selectChat(Number(chat_id));
   }, [dialogs, adminsDialogs]);

   /**
    * selectedDialogs для выбора нужно диалога
    * */
   const [selectedDialogs, setSelectedDialogs] = useState<
      | {
           id: number;
           name: string;
        }
      | undefined
   >();

   const [isOpenedDialogs, setIsOpenedDialogs] = useState<boolean>(false);

   const [isChatSent, setIsChatSent] = useState(false);

   const [adminNameToGetComplaint, setAdminNameToGetComplaint] = useState<{
      id?: number;
      name?: string;
      chat_id?: number;
   }>({});

   function onSelectChat(id: number, name: string) {
      setSelectedDialogs({ id: id, name: name });
      setIsOpenedDialogs(true);
   }

   /**
    *  Метод фильтрации диалогов по searchDialogsInput(по users first_name  и last_name)
    * */

   function filterDialogsList(item: DialogType, index: number) {
      if (!item.users?.length) {
         return false;
      }

      if (searchDialogsInput) {
         if (
            DialogsUsersNames[index]
               ?.toLowerCase()
               ?.includes(searchDialogsInput.toLowerCase())
         ) {
            return item;
         }
      } else {
         return item;
      }
   }

   /**
    *  Методы для обновления чата
    * */

   const [firstLoad, setIsFirstLoad] = useState<boolean>(true);
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [initialTimeOutId, setInitialTimeOutId] = useState<number>();
   const commonState = useLocalCommonState();

   useEffect(() => {
      NeedPing = true;
      updateDialogsList(true);
   }, []);

   function updateDialogsList(isPing?: boolean) {
      firstLoad && setIsLoading(true);
      commonState.dispatch(
         ChatThunk.getDialogs({
            commonState,
            callbacks: {
               "200": (response) => {
                  if (isPing && NeedPing) {
                     const timeOutId = setTimeout(() => {
                        updateDialogsList(isPing);
                        setInitialTimeOutId(Number(timeOutId));
                     }, 5000);
                  }
               },
            },
            typeChat: isAdminsChat,
            userIsAdmin: my_user.role !== "user",
         })
      );
      firstLoad && setIsLoading(false);
      firstLoad && setIsFirstLoad(false);
   }

   useEffect(() => {
      return () => {
         NeedPing = false;
         window.clearTimeout(initialTimeOutId);
      };
   }, []);

   /**
    * Все для админки
    **/
   useEffect(() => {
      setSelectedDialogs(undefined);
      isAdminsChat = type == "help";
      updateDialogsList(false);
   }, [type]);

   const [isOpenReportPop, setIsOpenReportPop] = useState<boolean>(false);

   function closeReportPop() {
      setIsOpenReportPop(false);
   }

   const [isOpenSendChat, setIsOpenSendChat] = useState<boolean>(false);

   function setOpenSendChat() {
      setIsOpenSendChat((prevState) => !prevState);
   }

   const createChatWithUser = () => {
      commonState.dispatch(
         uiSlice.actions.setModalType({
            template: "create_admin_chat",
            payload: {},
         })
      );
   };

   const backToChatsMobileHandler = () => {
      setIsOpenedDialogs(false);
      commonState.location = routes.lk.messages + `/${type}`;
   };

   return (
      <div className={styles.container}>
         <h2 className={styles.title}>
            {type == "help"
               ? t("Messages.support", "Поддержка")
               : t("Messages.messages", "Сообщения")}
         </h2>
         <div className={styles.container1}>
            <div className={styles.header}>
               <div
                  className={cn(styles.header_left, {
                     [styles["header_left-open"]]: !isOpenedDialogs,
                  })}
               >
                  <SearchIcon className={styles.searchIcon} />
                  <Input_module
                     label={t("Buttons.search", "Поиск")}
                     placeholder={t("Buttons.search", "Поиск")}
                     style={{ paddingTop: "0", width: "100%" }}
                     control={control}
                     name={"searchInput"}
                  />
               </div>
               <div
                  className={cn(styles.header_right, {
                     [styles["header_right-open"]]: isOpenedDialogs,
                  })}
               >
                  <ArrowLeft
                     onClick={backToChatsMobileHandler}
                     className={styles.toDialogs}
                  />
                  {selectedDialogs?.name && <LogoSmall />}
                  <h5 className={styles.dialogName}>
                     {selectedDialogs?.name !== "" && selectedDialogs?.name}
                  </h5>
               </div>
            </div>
            <div className={styles.body}>
               <div
                  className={cn(styles.dialogs, {
                     [styles["dialogs-open"]]: !isOpenedDialogs,
                  })}
               >
                  <div className={styles.dialogsContent}>
                     {type == "help"
                        ? isLoadedAdminsDialogs
                           ? adminsDialogs
                                .filter(filterDialogsList)
                                .sort(sortDate)
                                .map((item, index) => (
                                   <DialogItem
                                      has_invisible_messages={
                                         item.is_has_invisible_messages
                                      }
                                      chat={item}
                                      key={item.id}
                                      onSelectChat={selectChat}
                                      id={item.id}
                                      users={item.users}
                                      isAdminChat={item.is_admin_chat}
                                      last_message_time={item.last_message_data}
                                      active={
                                         selectedDialogs
                                            ? selectedDialogs.id === item.id
                                            : false
                                      }
                                   />
                                ))
                           : Array(8)
                                .fill("")
                                .map((_) => <LoadedDialogItem />)
                        : isLoadedDialogs
                        ? dialogs
                             .filter(filterDialogsList)
                             .sort(sortDate)
                             .map((item, index) => (
                                <DialogItem
                                   chat={item}
                                   key={item.id}
                                   onSelectChat={selectChat}
                                   id={item.id}
                                   users={item.users}
                                   has_invisible_messages={
                                      item.is_has_invisible_messages
                                   }
                                   isAdminChat={item.is_admin_chat}
                                   last_message_time={item.last_message_data}
                                   active={
                                      selectedDialogs
                                         ? selectedDialogs.id === item.id
                                         : false
                                   }
                                />
                             ))
                        : Array(8)
                             .fill("")
                             .map((_) => <LoadedDialogItem />)}
                  </div>
                  {my_user.role != "user" && (
                     <div
                        className={styles.createAdminChat}
                        onClick={createChatWithUser}
                     >
                        {t("Messages.write_to_user", "Написать пользователю")}
                     </div>
                  )}
                  <div className={styles.toAdminChat}>
                     {my_user.role === "user" ? (
                        <div
                           onClick={() =>
                              setIsOpenReportPop((prevState) => !prevState)
                           }
                           className={styles.userWrapper}
                        >
                           <LogoSmall style={{ marginRight: "10px" }} />
                           {t(
                              "Messages.contact-support",
                              "Написать в поддержку"
                           )}
                        </div>
                     ) : (
                        <TypeSwitcher />
                     )}
                  </div>
                  {isOpenReportPop ? (
                     <ReportPop
                        closeReportPop={closeReportPop}
                        user_id={my_user.id}
                     />
                  ) : null}
               </div>
               <div
                  className={cn(styles.messages, {
                     [styles["messages-open"]]: isOpenedDialogs,
                  })}
               >
                  {chat_id ? (
                     <MessagesArea
                        key={chat_id}
                        chatId={Number(chat_id)}
                        isChatSent={isChatSent}
                        adminNameToGetComplaint={adminNameToGetComplaint}
                     />
                  ) : (
                     <div className={styles.nullChatId}>
                        <p className={styles.nullChatId_text}>
                           {t("Messages.select-dialogue", "Диалог не выбран")}
                        </p>
                     </div>
                  )}
               </div>

               {isOpenSendChat && selectedDialogs?.id && (
                  <SendChatToAdminPop
                     setIsOpened={setIsOpenSendChat}
                     chat_id={selectedDialogs.id}
                     isOpened={isOpenSendChat}
                     setIsChatSent={setIsChatSent}
                     setAdminNameToGetComplaint={setAdminNameToGetComplaint}
                  />
               )}
               {my_user.role === "superadmin" &&
                  type === "help" &&
                  selectedDialogs?.id && (
                     <SendChatToAdmin onClick={setOpenSendChat} />
                  )}
            </div>
         </div>
      </div>
   );
};
