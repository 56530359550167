import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
   categoryType,
   educationSystemType,
   levelType,
} from "../formsDataSlice/types";
import { StateType } from "../index";
import { subjectsSelectType } from "../../serializers/subject";
import { subjectType } from "../types";

export type pickedCategoryType = {
   category: categoryType | undefined;
   subjects: Array<subjectsSelectType>;
};
type initialStateType = {
   pickedLevel?: levelType;
   pickedSystem?: educationSystemType;
   pickedCategory?: pickedCategoryType;
   pickedSubject?: subjectType;
};
const initialState: initialStateType = {};

export const adminEditorSlice = createSlice({
   name: "adminEditorSlice",
   initialState,
   reducers: {
      setLevel: (state, { payload }: PayloadAction<levelType>) => {
         state.pickedLevel = payload;
         state.pickedCategory = undefined;
         state.pickedSystem = undefined;
      },
      setSystem: (state, { payload }: PayloadAction<educationSystemType>) => {
         state.pickedSystem = payload;
         state.pickedCategory = undefined;
      },
      setCategory: (state, { payload }: PayloadAction<pickedCategoryType>) => {
         state.pickedCategory = payload;
      },
      setSubject: (state, { payload }: PayloadAction<subjectType>) => {
         state.pickedSubject = payload;
      },
   },
});

export const adminEditorSliceSelectors = {
   getLevel: (state: StateType) => state.adminEditorSlice.pickedLevel,
   getSystem: (state: StateType) => state.adminEditorSlice.pickedSystem,
   getCategory: (state: StateType) => state.adminEditorSlice.pickedCategory,
   getSubject: (state: StateType) => state.adminEditorSlice.pickedSubject,
};
