import React, { FC, useState } from "react";
import styles from "./index.module.css";
import { FilterButton } from "../../../../../../../UI/Button/Templates/FilterButton";
import { Popup } from "../../../../../../../UI/Popup";
import { FilterElement } from "../../../../../../../UI/FilterElement";
import { Button } from "../../../../../../../UI/Button";
import { getAdminUsersFilterElements } from "../filterElemenets";
import { useMedia } from "../../../../../../../../hooks/useMedia";
import { Control, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";

type propsType = {
   control: Control<any>;
   setValue: UseFormSetValue<any>;
   submitFilters: (data: any) => void;
   hide?: Array<"name" | "type" | "role">;
};
export const AdminTableFilters: FC<propsType> = ({
   control,
   setValue,
   submitFilters,
   hide,
}) => {
   const { t } = useTranslation();
   const filterElements = getAdminUsersFilterElements({
      control,
      setValue,
      t,
   });
   const { width } = useMedia();
   const [template, setTemplate] = useState<
      "default" | "name" | "user_type" | "user_role"
   >("default");
   const [isPopupOpen, setIsPopupOpen] = useState(false);

   const definePopupBody = () => {
      switch (template) {
         case "default":
            return (
               <>
                  <FilterElement
                     onClick={() => setTemplate("name")}
                     title={t("Notebook.headers.name", "Имя")}
                     value={""}
                  />
                  {!hide?.includes("type") && (
                     <FilterElement
                        onClick={() => setTemplate("user_type")}
                        title={t("Common.userType", "Тип учётной записи")}
                        value={""}
                     />
                  )}
                  {!hide?.includes("role") && (
                     <FilterElement
                        onClick={() => setTemplate("user_role")}
                        title={t("Common.userRole.title", "Роль")}
                        value={""}
                     />
                  )}
               </>
            );
         case "name":
            return filterElements.name;
         case "user_type":
            return filterElements.userType;
         case "user_role":
            return filterElements.userRole;
      }
   };

   const onBackArrowHandler = () => {
      if (template != "default") {
         return () => setTemplate("default");
      } else return undefined;
   };
   const popupTitleResolver = () => {
      switch (template) {
         case "default":
            return t("Buttons.filters", "Фильтры");
         case "name":
            return t("Notebook.headers.name", "Имя");
         case "user_type":
            return t("Common.userType", "Тип учётной записи");
         case "user_role":
            return t("Common.userRole.title", "Роль");
      }
   };
   const onPopupButtonClickHandler = () => {
      if (template == "default") {
         return (data: any) => {
            setIsPopupOpen(false);
            submitFilters(data);
         };
      } else return () => setTemplate("default");
   };

   return (
      <div className={styles.container}>
         {width < 1024 ? (
            <>
               <FilterButton
                  className={styles.filter_button}
                  setIsPopupOpen={setIsPopupOpen}
               />
               <Popup
                  onBackArrow={onBackArrowHandler()}
                  isOpen={isPopupOpen}
                  setIsOpen={setIsPopupOpen}
                  title={popupTitleResolver()}
               >
                  <div className={styles.popup_filters}>
                     {definePopupBody()}
                     <Button
                        onClick={onPopupButtonClickHandler()}
                        style={{
                           margin: "0 auto",
                           marginBottom: 20,
                           marginTop: 40,
                        }}
                     >
                        {t("SearchFilters.teacher-filters.apply", "Применить")}
                     </Button>
                  </div>
               </Popup>
            </>
         ) : (
            <>
               <div className={styles.filters}>
                  <div className={styles.filters__block}>
                     {!hide?.includes("type") && (
                        <div>{filterElements.userType}</div>
                     )}
                     {!hide?.includes("role") && (
                        <div>{filterElements.userRole}</div>
                     )}
                  </div>
                  <div>{filterElements.name}</div>
               </div>
            </>
         )}

         {width >= 1024 && (
            <Button
               style={{
                  marginBottom: 20,
               }}
               onClick={submitFilters}
            >
               Применить
            </Button>
         )}
      </div>
   );
};
