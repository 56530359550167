import React, { FC } from "react";
import styles from "./index.module.css";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { routes } from "../../../../../../routes";
import { ReactComponent as ArrowIcon } from "../../assets/arrow.svg";
import { Table } from "./components/Table";
import { Button } from "../../../../../UI/Button";
import { useGetCard } from "hooks/useGetCard";
import { lkApi } from "api/LkApi/methods";
import { useAddCard } from "hooks/useAddCard";

// const data = [
//    {
//       id: 1,
//       number: "3770 xxxx xx00 0000",
//       type: "VISA",
//       date: "05.2025"
//    },
//    {
//       id: 2,
//       number: "5645 xxxx xx00 0000",
//       type: "Master Card",
//       date: "05.2025"
//    },
//    {
//       id: 3,
//       number: "3701 xxxx xx00 0000",
//       type: "American Express",
//       date: "05.2025"
//    },
// ]

export const AddCardForPayment: FC = () => {
   const { t } = useTranslation();

   const {data, isLoading: isLoadingGet} = useGetCard("payin")

    const {error, addCard, isLoading: isLoadingAdd} = useAddCard("payin")

   return (
      <div className={cn(styles.container, "LkPaymentPage")}>
         <div className={styles.title}>
            {t(
               "Payment.pages.AddCardsForPayment.title",
               "Карты для оплаты"
            )}
         </div>
         <div className={styles.subtitles}>
            <NavLink
               exact
               to={routes.lk.payment.root}
               activeClassName={styles.button_active}
            >
               <div className={styles.subtitles__item}>
                  <ArrowIcon className={styles.subtitles__icon}/>
                  <div className={styles.subtitles__title}>
                     {t("Payment.nav-links.payment", "Оплата")}
                  </div>
               </div>
            </NavLink>
            <NavLink
               exact
               to={routes.lk.payment.addCardForPayment}
               activeClassName={styles.button_active}
            >
               <div className={styles.subtitles__item}>
                  <ArrowIcon className={styles.subtitles__icon}/>
                  <div className={styles.subtitles__title}>
                     {t("Payment.nav-links.card-details", "Карты для оплаты")}
                  </div>
               </div>
            </NavLink>
         </div>
         <div className={styles.table}>
            <Table data={data} />
         </div>
         <div className={styles.button__container}>
            <Button className={styles.button} onClick={addCard} loading={isLoadingAdd}>
               {t(
                  "Payment.pages.AddCardsForPayout.buttons.add-new-card",
                  "Добавить новую карту"
               )}
            </Button>
         </div>
      </div>
   )
}
