import {useEffect} from "react";
import {useLocalCommonState} from "core/LTools/core/hooks/useLocalCommonState";
import {authSlice, authSliceSelectors} from "store/authSlice";
import {useSelector} from "react-redux";
import {lkSLiceThunk} from "store/lkSlice/thunk";
import {routes} from "routes";
import {lc} from "store/LC";
import {lkSlice, lkSliceSelectors} from "store/lkSlice";
import { matchPath, useLocation } from "react-router-dom";

/** @Logic
 * 1. Достаём данные из лк
 * 2. Получаем юезра из ауса
 * 3. Если получили - получаем в мейне
 * 4. Если получили - проверяем токен гет нотифом
 * 5.
 */
export const useAuth = () => {
   const { pathname } = useLocation();
   const tokenFromLc = lc.get("token");
   const userIdFromLk = lc.get("user_id");
   const emailFromLk = lc.get('email');
   const tokenFromStore = useSelector(authSliceSelectors.getToken);
   const userIdFromStore = useSelector(authSliceSelectors.getId);
   const isAuthProcess = useSelector(lkSliceSelectors.getAuthProcessState);
   const commonState = useLocalCommonState();

   const stopAuthProcess = () => {
      commonState.dispatch(lkSlice.actions.setAuthProcessState(false));
   };


   useEffect(() => {
      const match = matchPath(commonState.location, {
         path: routes.registration.auth.registrationCode+'/:template/:code',
         exact: true,
         strict: false
      })
      if (match) {
         if (userIdFromLk && emailFromLk) {
            commonState.dispatch(authSlice.actions.setId(userIdFromLk));
            commonState.dispatch(authSlice.actions.setEmail(emailFromLk));
         }
         stopAuthProcess();
         return
      }
      if (userIdFromLk && tokenFromLc != null) {
         commonState.dispatch(authSlice.actions.setId(userIdFromLk));
         commonState.dispatch(authSlice.actions.setToken(tokenFromLc));
      } else {
         stopAuthProcess();

         if (pathname !== "/privacy_policy" && pathname !== "/user_agreement") {
            commonState.location = routes.landings.main;
         }
      }
   }, []);

   const checkToken = () => {
      commonState.dispatch(
          lkSLiceThunk.getNotifications({
             commonState,
             limit: "1",
             offset: "0",
             is_read: true,
             callbacks: {
                200: () => {
                   getUserMain();
                },
                401: () => {
                   commonState.dispatch(authSlice.actions.logout());
                   commonState.location = routes.landings.main;
                   stopAuthProcess();
                },
                404: () => {
                   // commonState.dispatch(authSlice.actions.logout());
                   commonState.location = routes.registration.student.fillInformation;
                   stopAuthProcess();
                },
             },
          })
      );
   };

   const getUserMain = () => {
      commonState.dispatch(
          lkSLiceThunk.getUser({
             commonState,
             callbacks: {
                200: () => {
                   stopAuthProcess();
                },
                401: () => {
                   commonState.dispatch(authSlice.actions.logout());
                   commonState.location = routes.landings.main;
                   stopAuthProcess();
                },
                404: () => {
                   commonState.location =
                       routes.registration.student.fillInformation;
                   stopAuthProcess();
                },
             },
          })
      );
   };

   useEffect(() => {
      if (tokenFromStore && userIdFromStore && isAuthProcess) {
         checkToken();
      }
   }, [tokenFromStore, userIdFromStore]);
};
