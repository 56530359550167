import React, { FC } from "react";
import styles from "./style.module.css";
import { Button } from "components/UI/Button";
import { useHistory } from "react-router-dom";
import { routes } from "routes";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { authSliceSelectors } from "store/authSlice";
import { lkSliceSelectors } from "store/lkSlice";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { lessonThunk } from "store/lessonSlice/thunk";
import { useGoToProfile } from "helpers/util/goToProfile";

type propsType = {
   variant: "default" | "teacher" | "student";
   status: "new_invite" | "rejected_invite" | "approved_invite" | "payed_invite" | "not_payment_invite";
   invite_id?: string;
   slot_id?: string;
};
export const InviteButtons: FC<propsType> = ({
   invite_id,
   variant,
   slot_id,
   status: invite_status,
}) => {
   const { t } = useTranslation();
   const user_id = useSelector(authSliceSelectors.getId);
   const commonState = useLocalCommonState();
   const goToProfile = useGoToProfile(user_id);

   const rejectInvite = () => {
      commonState.dispatch(
         lessonThunk.deleteInvite({
            invite_id: String(invite_id),
            commonState,
            slot_id: String(slot_id),
            callbacks: {
               204: goToProfile,
            },
         })
      );
   };

   const acceptInvite = () => {
      commonState.dispatch(
         lessonThunk.createLessonByInvite({
            commonState,
            invite_id: String(invite_id),
            callbacks: {
               201: (res) => {
                  commonState.location =
                     routes.lk.lesson.default.view + `/${res?.data.data.id}`;
               },
            },
         })
      );
   };

   return (
      <div className={styles.container}>
         {variant == "default" && (
            <Button variant={"primary"} onClick={goToProfile}>
               {t("Buttons.to-lk", "В личный кабинет")}
            </Button>
         )}
         {variant == "teacher" && (
            <>
               <Button
                  onClick={acceptInvite}
                  className={styles.button}
                  loading={commonState.loading}
               >
                  {t("Lk.lesson-service.invite.accept", "Подтвердить заявку")}
               </Button>
               {(invite_status === "new_invite" || invite_status === "payed_invite" || invite_status === "not_payment_invite") && (
                  <Button
                     variant='red'
                     onClick={rejectInvite}
                     loading={commonState.loading}
                  >
                     {t("Lk.lesson-service.invite.cancel", "Отклонить заявку")}
                  </Button>
               )}
            </>
         )}
         {variant == "student" && (
            <>
               <Button
                  variant={"primary"}
                  onClick={goToProfile}
                  className={styles.button}
               >
                  {t("Buttons.to-lk", "В личный кабинет")}
               </Button>
               {(invite_status === "new_invite" || invite_status === "payed_invite" || invite_status === "not_payment_invite") && (
                  <Button
                     variant={"red"}
                     onClick={rejectInvite}
                     className={styles.button}
                     loading={commonState.loading}
                  >
                     {t("Buttons.cancel", "Отменить")}
                  </Button>
               )}
            </>
         )}
      </div>
   );
};
