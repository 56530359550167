import React, { FC, useEffect, useState } from "react";
import styles from "../../index.module.css";
import { Switcher } from "components/UI/Switcher";
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { routes } from "routes";

type propsType = {};
export const TypeSwitcher: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const { type } = useParams<{ type: "default" | "help" }>();
   const form = useForm();
   const history = useHistory();
   const value = useWatch({
      name: "selectAdminsChat",
      control: form.control,
      defaultValue: type == "help",
   });
   const valueTexted = value ? "help" : "default";

   // Если меняется value и оно "не равно" текущему роуту, редирект
   useEffect(() => {
      if (valueTexted != type) {
         history.push(routes.lk.messages + `/${valueTexted}`);
      }
   }, [value]);
   // Если меняется роут "не равный" value - меняем value
   useEffect(() => {
      if (valueTexted != type) {
         form.setValue("selectAdminsChat", type == "help");
      }
   }, [type]);

   return (
      <div className={styles.adminWrapper}>
         {t("Messages.support-dialogue", "Сообщения в поддержку")}
         <Switcher
            defaultChecked={type == "help"}
            className={styles.adminSwitcher}
            name={"selectAdminsChat"}
            control={form.control}
            variant={"primary"}
         />
      </div>
   );
};
