import React from "react";
import styles from "./index.module.css";
import { PageTitle } from "components/UI/PageTitle";
import { ReactComponent as IconTeacherActivate } from "./assets/teacherActivate.svg";
import { ReactComponent as Education } from "./assets/education.svg";
import { ReactComponent as HelpUsers } from "./assets/helpUsers.svg";
import { ReactComponent as SettingUser } from "./assets/settingUser.svg";
import { Link } from "react-router-dom";
import { routes } from "routes";
import { useTranslation } from "react-i18next";
import { useQuery } from "hooks/useQuery";
import { userApi } from "api/userApi/methods";
import { LinkCard } from "components/UI/LinkCard";

export const AdminRoot = () => {
   const { t } = useTranslation();

   const { data: teachersCount } = useQuery(
      () =>
         userApi.getUsers({
            limit: "0",
            offset: "0",
            type: "inactive_teacher",
         }),
      {
         deps: [],
         serializer: (res) => {
            return {
               items_count: res?.data.data.items_count,
            } as {
               items_count: number;
            };
         },
      }
   );
   const { data: UsersCount } = useQuery(
      () =>
         userApi.getUsers({
            limit: "0",
            offset: "0",
         }),
      {
         deps: [],
         serializer: (res) => {
            return {
               items_count: res?.data.data.items_count,
            } as {
               items_count: number;
            };
         },
      }
   );

   return (
      <div className={styles.container}>
         <PageTitle title={t("Lk.AdminPanel.page-title", "Панель админа")} />
         <div className={styles.Cards}>
            <Link to={routes.lk.admin.activation}>
               <LinkCard
                  className={styles.Cards_item}
                  icon={IconTeacherActivate}
                  title={t(
                     "Lk.AdminPanel.cards.approve-teachers",
                     "Преподаватели на активацию"
                  )}
                  bgColor={"#6C9DF9"}
                  count={teachersCount?.items_count}
                  modify={["white"]}
               />
            </Link>
            <Link to={routes.lk.admin.users}>
               <LinkCard
                  className={styles.Cards_item}
                  icon={SettingUser}
                  title={t("Lk.AdminPanel.cards.users", "Пользователи")}
                  count={UsersCount?.items_count}
                  bgColor={"#EBEBEB"}
               />
            </Link>
            <Link to={routes.lk.messages + "/help"}>
               <LinkCard
                  className={styles.Cards_item}
                  icon={HelpUsers}
                  title={t(
                     "Lk.AdminPanel.cards.support",
                     "Поддержка пользователей"
                  )}
                  count={null}
                  bgColor={"#E6EFFE"}
               />
            </Link>
            <Link to={routes.lk.admin.educationSystems.root}>
               <LinkCard
                  className={styles.Cards_item}
                  icon={Education}
                  title={t(
                     "Lk.AdminPanel.cards.education-system",
                     "Системы образования и предметы"
                  )}
                  count={null}
                  bgColor={"#EECEFD"}
               />
            </Link>
         </div>
      </div>
   );
};
