import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import moment from "moment";
import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";
import { ReactComponent as SearchIcon } from "./assets/search.svg";
import { ReactComponent as RubIcon } from "./assets/rub.svg";
import { ReactComponent as ArrowDropdown } from "./assets/arrow_dropdown.svg";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import { routes } from "../../../../../../routes";
import { Input } from "../../../../../UI/Input";
import { Calendar } from "../../../../../UI/Calendar";
import { Select } from "../../../../../UI/SelectNew";
import { NoteTable } from "../../components/Table";
import { useMedia } from "../../../../../../hooks/useMedia";
import { SelectItemType } from "../../../../../UI/Select/types";
import { Paginate } from "../../../AdminService/components/Paginate";
import { usePaginator } from "../../../../../../hooks/usePagination";
import { FilterButtonSec } from "../../../../../UI/Button/Templates/FilterButtonSec";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "../../../../../../store/lkSlice";
import { useTranslation } from "react-i18next";
import { useFilters } from "hooks/useFilters";
import { adminApi } from "api/adminApi";
import { useWatch } from "react-hook-form";
import { SmartForm } from "components/hocs/SmartForm";
import { Button } from "components/UI/Button";
import { Preloader } from "components/UI/Preloader";
import { format } from "date-fns";

type paymentStatus =
   | "new"
   | "error"
   | "success"
   | "rejected"
   | "processing";

export const sortingOptions: Array<SelectItemType<paymentStatus>> = [
   {
      id: 1,
      text: "Создан",
      value: "new",
   },
   {
      id: 2,
      text: "Ошибка",
      value: "error",
   },
   {
      id: 3,
      text: "Успешно",
      value: "success",
   },
   {
      id: 4,
      text: "Отменен",
      value: "rejected",
   },
   {
      id: 5,
      text: "В обработке",
      value: "processing",
   },
];

export const TransactionsList: FC = () => {
   // const [isDefaultLesson, setIsDefaultLesson] = useState<boolean>(false);
   const [showAllFilters, setShowAllFilters] = useState<boolean>(false);

   const { t } = useTranslation();
   const { width } = useMedia();

   const user = useSelector(lkSliceSelectors.getUserData);

   const sortingOptions1: Array<SelectItemType<"asc" | "desc">> = [
      {
         id: 1,
         text: `15 ${t("Common.minutes", "минут")}`,
         value: "desc",
      },
      {
         id: 2,
         text: `30 ${t("Common.minutes", "минут")}`,
         value: "desc",
      },
      {
         id: 3,
         text: `45 ${t("Common.minutes", "минут")}`,
         value: "desc",
      },
   ];

   const sortingOptions2: Array<SelectItemType<"payin" | "payout">> = [
      {
         id: 1,
         text: t("FinancialOperations.filters.values.incoming", "Входящий"),
         value: "payin",
      },
      {
         id: 2,
         text: t("FinancialOperations.filters.values.outgoing", "Исходящий"),
         value: "payout",
      },
   ];

   const {
      data,
      applyFiltersHandler,
      form,
      resetHandler,
      isLoading,
      itemsCount,
      setFilters,
   } = useFilters<{}, any>({
      initialState: {
         limit: 5,
         offset: 0,
      },

      queryKey: "payment",
      queryFn: (data) => {
         return adminApi.getAllPaymentsById({
            user_id: user?.id,
            limit: data.limit,
            offset: data.offset,
            amount_from: data.amount_from,
            amount_to: data.amount_to,
            date_from: data.date_from
                ? format(data.date_from, 'yyyy-MM-dd') + "T21:00:00%2B03:00"
                : undefined,
            date_to: data.date_to ? format(data.date_to, 'yyyy-MM-dd') + "T21:00:00%2B03:00" : undefined,
            fio: data.fio,
            payment_status: data?.payment_status?.value,
            payment_type: data?.payment_type?.value,
         });
      },
      defaultValues: {
         date_from: undefined,
         date_to: undefined,
         amount_from: "",
         amount_to: "",
         fio: "",
         payment_status: ""
      },
   });

   console.log(data);

   const [
      date_from,
      date_to,
      amount_from,
      amount_to,
      fio,
      payment_status
   ] = useWatch({
      control: form.control,
      name: [
         "date_from",
         "date_to",
         "amount_from",
         "amount_to",
         "fio",
         "payment_status"
      ],
   });

   const paginator = usePaginator({
      perPage: 5,
      length: itemsCount,
   });

   useEffect(() => {
      setFilters((prev) => {
         return {
            ...prev,
            offset: paginator.offset,
         };
      });
   }, [paginator.offset]);

   // const { setValue, control, reset, } = useForm<any>({
   //    defaultValues: {
   //       nameField: "",
   //       date: undefined,
   //       sort: sortingOptions[0],
   //       sort_input: sortingOptions[0].text,
   //    },
   // });

   return (
      <div className={cn(styles.container, "LkPaymentPage")}>
         <div className={styles.title}>
            {t("Payment.pages.TransactionsList.title", "Список операций")}
         </div>
         <div className={styles.subtitles}>
            <NavLink
               exact
               to={routes.lk.payment.root}
               activeClassName={styles.button_active}
               // className={styles.link}
            >
               <div className={styles.subtitles__item}>
                  <ArrowIcon className={styles.subtitles__icon}/>
                  <div className={styles.subtitles__title}>
                     {t("Payment.nav-links.payment", "Оплата")}
                  </div>
               </div>
            </NavLink>
            <NavLink
               exact
               to={routes.lk.payment.transactions}
               activeClassName={styles.button_active}
            >
               <div className={styles.subtitles__item}>
                  <ArrowIcon className={styles.subtitles__icon}/>
                  <div className={styles.subtitles__title}>
                     {t("Payment.nav-links.transactions-list", "Список операций")}
                  </div>
               </div>
            </NavLink>
         </div>
         <SmartForm
             submit={form.handleSubmit(applyFiltersHandler)}
             form={form}
         >
         <div className={styles["filters-block"]}>
            {width >= 1024 ? (
               <>
                  <div className={styles["filters-block_row"]}>
                     <Calendar
                        className={styles.filters__input_calendar}
                        control={form.control}
                        name='date_from'
                        minDate={moment().toDate()}
                        placeholder={t("FinancialOperations.filters.date-from", "Дата от")}
                        placeholderColor={"dark-gray"}
                     />
                     <Calendar
                        className={styles.filters__input_calendar}
                        control={form.control}
                        minDate={moment().toDate()}
                        name='date_to'
                        placeholder={t("FinancialOperations.filters.date-to", "Дата до")}
                        placeholderColor={"dark-gray"}
                     />
                     <Select
                        className={styles.filters__input_select}
                        variant={"blue-bg"}
                        name="payment_status"
                        setValue={form.setValue}
                        options={sortingOptions}
                        control={form.control}
                        support={<ArrowDropdown/>}
                        selectTitle={t("FinancialOperations.filters.status", "Статус")}
                        variantTitle={"small-title"}
                        titleColor={"black"}
                     />
                     <Input
                        className={styles.filters__input_search}
                        control={form.control}
                        name='fio'
                        label={t("FinancialOperations.filters.search-by-full-name", "Поиск по ФИО")}
                        support={<SearchIcon/>}
                     />
                  </div>
                  <div className={styles["filters-block_row"]}>
                     <Input
                        className={styles.filters__input_sum}
                        control={form.control}
                        name='amount_from'
                        label={t("FinancialOperations.filters.sum-from", "Сумма от")}
                        // support={<RubIcon/>}
                     />
                     <Input
                        className={styles.filters__input_sum}
                        control={form.control}
                        name='amount_to'
                        label={t("FinancialOperations.filters.sum-to", "Сумма до")}
                        // support={<RubIcon/>}
                     />
                     {/*<div className={cn(styles.item, styles.item_select)}>*/}
                        <Select
                           className={styles.filters__input_select}
                           variant={"blue-bg"}
                           name='sort3'
                           setValue={form.setValue}
                           options={sortingOptions1}
                           control={form.control}
                           support={<ArrowDropdown/>}
                           selectTitle={t("FinancialOperations.filters.lesson-duration", "Продолжительность урока")}
                           variantTitle={"small-title"}
                           titleColor={"black"}
                        />
                     {/*</div>*/}
                     {/*{user.role === "admin" && (*/}
                     {/*   <Input*/}
                     {/*      className={styles.filters__input_search}*/}
                     {/*      control={form.control}*/}
                     {/*      name='nameField'*/}
                     {/*      label={t("FinancialOperations.filters.bank-account-number", "Номер счета")}*/}
                     {/*   />*/}
                     {/*)}*/}
                  </div>
                  <div className={styles["filters-block_row-3"]}>
                     {/*{user.role === "admin" && user.type === "teacher" && (*/}
                        <Select
                           className={styles.filters__input_select}
                           variant={"blue-bg"}
                           name={"payment_type"}
                           setValue={form.setValue}
                           options={sortingOptions2}
                           control={form.control}
                           support={<ArrowDropdown/>}
                           selectTitle={t("FinancialOperations.filters.transaction-type", "Тип платежа")}
                           variantTitle={"small-title"}
                           titleColor={"black"}
                        />
                     {/*)}*/}
                  </div>
               </>
            ) : width > 719 ? (
               <>
                  <div className={styles["filters-block_row"]}>
                     <Calendar
                        className={styles.filters__input_calendar}
                        control={form.control}
                        name='date_from'
                        placeholder={t("FinancialOperations.filters.date-from", "Дата от")}
                        placeholderColor={"dark-gray"}
                     />
                     <Calendar
                        className={styles.filters__input_calendar}
                        control={form.control}
                        name='date_to'
                        placeholder={t("FinancialOperations.filters.date-to", "Дата до")}
                        placeholderColor={"dark-gray"}
                     />
                     <Select
                        className={styles.filters__input_select}
                        variant={"blue-bg"}
                        name="payment_status"
                        setValue={form.setValue}
                        options={sortingOptions}
                        control={form.control}
                        support={<ArrowDropdown/>}
                        selectTitle={t("FinancialOperations.filters.status", "Статус")}
                        variantTitle={"small-title"}
                        titleColor={"black"}
                     />
                     <FilterButtonSec
                        className={styles["filter-button"]} setIsPopupOpen={setShowAllFilters}
                        title={showAllFilters
                           ? t("FinancialOperations.filters.hide-filters", "Скрыть фильтры")
                           : t("FinancialOperations.filters.all-filters", "Все фильтры")
                        }
                        val={showAllFilters}
                     />
                  </div>
                  {showAllFilters && (
                     <>
                        <div className={styles["filters-block_row"]} style={{ justifyContent: "start" }}>
                           <Input
                              className={styles.filters__input_search1}
                              control={form.control}
                              name='fio'
                              label={t("FinancialOperations.filters.search-by-full-name", "Поиск по ФИО")}
                              support={<SearchIcon/>}
                           />
                           <Input
                              className={styles.filters__input1}
                              control={form.control}
                              name='amount_from'
                              label={t("FinancialOperations.filters.sum-from", "Сумма от")}
                              // support={<RubIcon/>}
                           />
                           <Input
                              className={styles.filters__input1}
                              control={form.control}
                              name='amount_to'
                              label={t("Payment.transactions-list.filters.sum-to", "Сумма до")}
                              // support={<RubIcon/>}
                           />
                        </div>
                        <div className={styles["filters-block_row-3"]} style={{ justifyContent: "start" }}>
                           <div className={cn(styles.item, styles.item_select)}>
                              <Select
                                 className={styles.filters__select1}
                                 variant={"blue-bg"}
                                 name='sort3'
                                 setValue={form.setValue}
                                 options={sortingOptions1}
                                 control={form.control}
                                 support={<ArrowDropdown/>}
                                 selectTitle={t("FinancialOperations.filters.lesson-duration", "Продолжительность урока")}
                                 variantTitle={"small-title"}
                                 titleColor={"black"}
                              />
                           </div>
                           {/*{user.role === "admin" && (*/}
                           {/*   <div className={cn(styles.item, styles.item_search)}>*/}
                           {/*      <Input*/}
                           {/*         // className={styles.filters__input_search}*/}
                           {/*         control={form.control}*/}
                           {/*         name='nameField'*/}
                           {/*         label={t("FinancialOperations.filters.bank-account-number", "Номер счета")}*/}
                           {/*      />*/}
                           {/*   </div>*/}
                           {/*)}*/}
                           {/*{user.role === "admin" && user.type === "teacher" && (*/}
                              <div className={styles.item}>
                                 <Select
                                    className={styles.filters__input_select}
                                    variant={"blue-bg"}
                                    name={"payment_type"}
                                    setValue={form.setValue}
                                    options={sortingOptions2}
                                    control={form.control}
                                    support={<ArrowDropdown/>}
                                    selectTitle={t("FinancialOperations.filters.transaction-type", "Тип платежа")}
                                    variantTitle={"small-title"}
                                    titleColor={"black"}
                                 />
                              </div>
                           {/*)}*/}
                        </div>
                     </>
                  )}
               </>
            ) : (
               <>
                  <Calendar
                     className={styles.filters__input_calendar}
                     control={form.control}
                     name='date_from'
                     placeholder={t("FinancialOperations.filters.date-from", "Дата от")}
                  />
                  <Calendar
                     className={styles.filters__input_calendar}
                     control={form.control}
                     name='date_to'
                     placeholder={t("FinancialOperations.filters.date-to", "Дата до")}
                  />
                  <Select
                     className={styles.filters__select}
                     variant={"blue-bg"}
                     name='payment_status'
                     setValue={form.setValue}
                     options={sortingOptions}
                     control={form.control}
                     support={<ArrowDropdown/>}
                     selectTitle={t("FinancialOperations.filters.status", "Статус")}
                     variantTitle={"small-title"}
                     titleColor={"black"}
                  />
                  {showAllFilters && (
                     <>
                        <Input
                           className={styles.filters__input_search1}
                           control={form.control}
                           name='fio'
                           label={t("FinancialOperations.filters.search-by-full-name", "Поиск по ФИО")}
                           support={<SearchIcon/>}
                        />
                        <div className={styles.input_container}>
                           <Input
                              className={styles.filters__input_sum}
                              style={{ marginRight: "16px" }}
                              control={form.control}
                              name='amount_from'
                              label={t("FinancialOperations.filters.sum-from", "Сумма от")}
                              // support={<RubIcon/>}
                           />
                           <Input
                              className={styles.filters__input_sum}
                              control={form.control}
                              name='amount_to'
                              label={t("FinancialOperations.filters.sum-to", "Сумма до")}
                              // support={<RubIcon/>}
                           />
                        </div>
                        <Select
                           className={styles.filters__select1}
                           variant={"blue-bg"}
                           name='sort3'
                           setValue={form.setValue}
                           options={sortingOptions1}
                           control={form.control}
                           support={<ArrowDropdown/>}
                           selectTitle={t("FinancialOperations.filters.lesson-duration", "Продолжительность урока")}
                           variantTitle={"small-title"}
                           titleColor={"black"}
                        />
                        {/*{user.role === "admin" && (*/}
                        {/*   <Input*/}
                        {/*      className={styles.filters__input_search}*/}
                        {/*      control={form.control}*/}
                        {/*      name='nameField'*/}
                        {/*      label={t("FinancialOperations.filters.bank-account-number", "Номер счета")}*/}
                        {/*   />*/}
                        {/*)}*/}
                        {/*{user.role === "admin" && user.type === "teacher" && (*/}
                           <Select
                              className={styles.filters__select1}
                              style={{ marginBottom: "24px" }}
                              variant={"blue-bg"}
                              name={"payment_type"}
                              setValue={form.setValue}
                              options={sortingOptions2}
                              control={form.control}
                              support={<ArrowDropdown/>}
                              selectTitle={t("FinancialOperations.filters.transaction-type", "Тип платежа")}
                              variantTitle={"small-title"}
                              titleColor={"black"}
                           />
                        {/*)}*/}
                     </>
                  )}
                  <FilterButtonSec
                     className={styles.filters__button} setIsPopupOpen={setShowAllFilters}
                     title={showAllFilters
                        ? t("FinancialOperations.filters.hide-filters", "Скрыть фильтры")
                        : t("FinancialOperations.filters.all-filters", "Все фильтры")
                     }
                     val={showAllFilters}
                  />
               </>
            )}
            <Button className={styles.button}>
               {t("SearchFilters.teacher-filters.apply", "Применить")}
            </Button>
         </div>
         </SmartForm>
         {isLoading ? <Preloader loading={isLoading}/> :
         <NoteTable
            data={data}
            // loading={width >= 1024 ? loading : false}
            // type={template == "default" ? "lesson" : "group_lesson"}
         />
         }
         {width >= 1024  && (
            <div className={styles.paginator_wrapper}>
               <Paginate
                   {...paginator}
                  pageCount={Math.ceil(itemsCount / 5)}
                  onPageActive={() => null}
                  onPageChange={paginator.onPageChange}
               />
            </div>
         )}
      </div>
   );
};
