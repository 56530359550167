// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_statistic_button__5YwN5 {\n  font-family: var(--font-Manrope);\n  display: none;\n  font-size: 28px;\n  line-height: 28px;\n  font-weight: 600;\n}\n\n@media screen and (max-width: 1023px) {\n  .style_statistic_button__5YwN5 {\n    display: flex;\n    align-items: center;\n    margin-bottom: 20px;\n  }\n\n  .style_button_arrow__3EwYO {\n    margin-right: 20px;\n  }\n}\n\n@media screen and (max-width: 719px) {\n  .style_statistic_button__5YwN5 {\n    margin-top: 16px;\n    font-size: 17px;\n    margin-left: 0;\n  }\n\n  .style_button_arrow__3EwYO {\n    margin-right: 16px;\n  }\n}", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/Statistic/components/BackButton/style.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,aAAa;EACb,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE;IACE,aAAa;IACb,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,gBAAgB;IAChB,eAAe;IACf,cAAc;EAChB;;EAEA;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".statistic_button {\n  font-family: var(--font-Manrope);\n  display: none;\n  font-size: 28px;\n  line-height: 28px;\n  font-weight: 600;\n}\n\n@media screen and (max-width: 1023px) {\n  .statistic_button {\n    display: flex;\n    align-items: center;\n    margin-bottom: 20px;\n  }\n\n  .button_arrow {\n    margin-right: 20px;\n  }\n}\n\n@media screen and (max-width: 719px) {\n  .statistic_button {\n    margin-top: 16px;\n    font-size: 17px;\n    margin-left: 0;\n  }\n\n  .button_arrow {\n    margin-right: 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statistic_button": "style_statistic_button__5YwN5",
	"button_arrow": "style_button_arrow__3EwYO"
};
export default ___CSS_LOADER_EXPORT___;
