import React, { useEffect, useState } from "react";
import "./style.css";
import { useCodeFieldLogic } from "./useCodeFieldLogic";
import cn from "classnames";
import { useTranslation } from "react-i18next";

var timeoutId = 0;

export type propsType = {
   length?: number | string;
   className?: string;
   placeholder?: string;
   error?: string;
   timeout?: number;
   onChange(value: string): void;
   timerCallback?(): void;
   sendCodeHandler?(): void;

   tempInput: any;
   fieldsArray: Array<string>;
   arrayOfInputs: any;
   setFieldsArray: (val: any) => void;
   onClickHandler: any;
   pasteHandler: any;
   keyPressHandler: any;
   onFocusHandler: any;
};

export const CodeField: React.FC<propsType> = (props) => {
   const {
      error,
      onChange,
      className,
      length = 4,
      placeholder,
      sendCodeHandler,
      timeout = 60,
      fieldsArray,
      setFieldsArray,
      tempInput,
      arrayOfInputs,
      keyPressHandler,
      onClickHandler,
      onFocusHandler,
      pasteHandler,
      timerCallback,
   } = props;
   let codeLength = Number(length);
   const { t } = useTranslation();

   /* state */
   const [timer, setTimer] = useState(timeout);
   const [sendCodeCounter, setSendCodeCounter] = useState(0);
   /* methods */
   function createTimer() {
      window.clearInterval(timeoutId);
      timeoutId = window.setInterval(updateTimer, 1000);
   }

   function updateTimer() {
      setTimer((timer) => {
         if (timer <= 0) {
            window.clearInterval(timeoutId);
            return 0;
         } else {
            return timer - 1;
         }
      });
   }

   function printTimer() {
      return (
         (timer / 60 < 60 ? "0" : "") +
         Math.floor(timer / 60) +
         ":" +
         (timer % 60 < 10 ? "0" : "") +
         (timer % 60)
      );
   }

   /* effects */
   useEffect(() => {
      timeoutId = window.setInterval(updateTimer, 1000);
   }, []);

   return (
      <div
         className={cn(className, {
            CodeField__wrapper: true,
            CodeField__wrapper_error: error,
         })}
      >
         <div className='CodeField'>
            {fieldsArray.map((_, index) => {
               const disabled = tempInput != index && !fieldsArray[index];

               function handlerRef(el: any) {
                  arrayOfInputs.current[index] = el;
               }

               function handlerClick(
                  event: React.MouseEvent<HTMLInputElement>
               ) {
                  onClickHandler(event, index);
               }

               return (
                  <div className='CodeField__input-wrapper'>
                     <input
                        className={cn({
                           CodeField__wrapper_error_number: error,
                        })}
                        type='tel'
                        key={index}
                        maxLength={1}
                        id={`${index}-id`}
                        data-cy={`field-${index}`}
                        placeholder={placeholder}
                        value={fieldsArray[index]}
                        disabled={disabled}
                        ref={handlerRef}
                        onKeyDown={keyPressHandler}
                        onFocus={onFocusHandler}
                        onClick={handlerClick}
                        onPaste={pasteHandler}
                     />
                  </div>
               );
            })}
            {error && <div className='CodeField__error'>{error}</div>}
         </div>
         <div className='CodeField__timer'>
            {timer >= -1 && (
               <>
                  <span>
                     <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                           if (timer == 0 && sendCodeCounter < 5) {
                              setTimer(60);
                              setSendCodeCounter(sendCodeCounter + 1);
                              createTimer();
                           }
                        }}
                        className='CodeField__timer-text'
                     >
                        <div className='CodeField__repeat-title'>
                           {" "}
                           {timer > 0 ? (
                              t(
                                 "UI.CodeField.duration",
                                 "Запросить код еще раз через "
                              ) +
                              (timer >= 60
                                 ? "01:" + (Math.floor(timer) - 60 + "0")
                                 : "00:" +
                                   (timer >= 10
                                      ? Math.floor(timer)
                                      : "0" + Math.floor(timer)))
                           ) : (
                              <>
                                 <p onClick={sendCodeHandler}>
                                    {t(
                                       "UI.CodeField.repeat",
                                       "Повторно отправить код"
                                    )}
                                 </p>
                                 <p>{sendCodeCounter} / 5</p>
                              </>
                           )}
                        </div>
                     </div>
                  </span>
                  <div
                     className='CodeField__line'
                     style={{
                        minWidth: "50px",
                        width:
                           timer > 0
                              ? `${
                                   ((61 - Math.ceil(timer * 100) / 100) / 60) *
                                   100
                                }%`
                              : "50px",
                        transition: "0.1s",
                     }}
                  />
               </>
            )}
         </div>
      </div>
   );
};
