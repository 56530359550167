import React, { FC } from "react";
import styles from "./index.module.css";
import cn from "classnames";

export const LoadedDialogItem: FC = () => {
   return (
      <div className={cn(styles.container, {})}>
         <div className={styles.content}></div>
      </div>
   );
};
