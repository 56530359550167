import {ReactComponent as HatIcon} from "./hat.svg";
import {ReactComponent as CalendarIcon} from "./calendar.svg";
import {ReactComponent as CheckMarkIcon} from "./checkmark.svg";
import {ReactComponent as ClockIcon} from "./clock.svg";
import {ReactComponent as CrossIcon} from "./cross.svg";
import {ReactComponent as InfoIcon} from "./info.svg";
import {ReactComponent as MessageIcon} from "./message.svg";
import {ReactComponent as PlusIcon} from "./plus.svg";
import {ReactComponent as QuestionIcon} from "./question.svg";
import {ReactComponent as ReportIcon} from "./report.svg";
import {ReactComponent as StarIcon} from "./star.svg";
import {FC} from "react";



type notificationCardIconsKeyType = "cross" |
   "approve" |
   "message" |
   "hat" |
   "calendar" |
   "info" |
   "clock" |
   "plus" |
   "question" |
   "report" |
   "star"
type notificationCardIcons = {
   [key in notificationCardIconsKeyType]: FC<any>
}

export const notificationCardIcons = {
   'cross': CrossIcon,
   'approve': CheckMarkIcon,
   'message': MessageIcon,
   'hat': HatIcon,
   'calendar': CalendarIcon,
   'info': InfoIcon,
   'clock': ClockIcon,
   'plus': PlusIcon,
   'question': QuestionIcon,
   'report': ReportIcon,
   'star': StarIcon,
}