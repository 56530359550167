import React, { FC, useEffect, useState } from "react";
import styles from "./style.module.css";
import { NotificationCardHeader } from "../../components/Header";
import { NotificationCardUser } from "../../components/User";
import { NotificationCardMessage } from "../../components/Message";
import { NotificationCardRating } from "../../components/Rating";
import { NotificationCardDate } from "../../components/Date";
import { NotificationCardButtons } from "../../components/Buttons";
import { notificationCardIcons } from "../../assets";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { notificationPropsType } from "../../types";
import { NotificationCardWrapper } from "../../components/NotificationCardWrapper";
import { useQuery } from "hooks/useQuery";
import { lessonApi } from "api/lessonApi/methods";
import { LessonItemType } from "components/Lk/Pages/Notebook/components/NoteHistory/components/Table";
import { userApi } from "api/userApi/methods";
import { teacherType } from "types/userType";

type propsType = { notification: notificationPropsType; isPush?: boolean };
export const NotificationLessonCard: FC<propsType> = (props) => {
   const { notification, isPush = false } = props;

   const { t } = useTranslation();

   const iconResolver = (): any => {
      switch (notification.notification_type) {
         case "start_lesson":
            return notificationCardIcons.clock;
         case "finish_lesson":
            return notificationCardIcons.star;
         case "reject_lesson":
            return notificationCardIcons.cross;
         default:
            return notificationCardIcons.clock;
      }
   };

   const titleResolver = (): any => {
      switch (notification.notification_type) {
         case "start_lesson":
            return t(
               "Lk.Notifications.LessonCard.title-join",
               "Занятие началось"
            );
         case "finish_lesson":
            return t(
               "Lk.Notifications.LessonCard.title-rate",
               "Оцените ваше занятие"
            );
         case "reject_lesson":
            return t(
               "Lk.Notifications.LessonCard.title-reject",
               "Занятие отменено"
            );
         default:
            return "";
      }
   };

   return (
      <NotificationCardWrapper
         className={cn(styles.container, {
            [styles[`${notification.notification_type}`]]: true,
         })}
      >
         <NotificationCardHeader
            date={notification.date}
            icon={iconResolver()}
            title={titleResolver()}
         />
         <NotificationCardUser user={notification.related_entity} />
         {notification.notification_type === "finish_lesson" ? (
            <NotificationCardRating notification={notification} />
         ) : (
            <NotificationCardDate
               entity_type={notification.entity_type}
               date_to={notification.entity.date_to}
               date_from={notification.entity.date_from}
               notification_type={notification.notification_type}
            />
         )}
         <NotificationCardButtons isPush={isPush} notification={notification} />
      </NotificationCardWrapper>
   );
};
