import React, { FC, useState } from "react";
import styles from "./index.module.css";
import { TitleSwitcher } from "./components/TitleSwitcher";
import { useTranslation } from "react-i18next";
import { DefaultLessonsTable } from "./Table/defaultLessons";

type propsType = {};

export const NoteHistory: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const [isDefaultLesson, setIsDefaultLesson] = useState<boolean>(false);

   return (
      <div className={styles.container}>
         <TitleSwitcher
            selected={isDefaultLesson}
            setSelect={setIsDefaultLesson}
            labels={[
               t("Notebook.history.opened_title", "Открытые"),
               t("Notebook.history.individual_title", "Индивидуальные"),
            ]}
            className={styles.header}
         />
         <div className={styles.table}>
            <DefaultLessonsTable
               template={isDefaultLesson ? "default" : "group"}
            />
         </div>
      </div>
   );
};
