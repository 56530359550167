import React, { useRef, useState } from "react";
import styles from "./index.module.css";
import { TextArea } from "components/UI/TextArea";
import { useForm } from "react-hook-form";
import { Button } from "components/UI/Button";
import { useBlurred } from "hooks/useBlurred";
import { ChatThunk } from "store/chatSlice/thunk";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";
import { chatSlice } from "store/chatSlice";
import { routes } from "routes";
import { useTranslation } from "react-i18next";

// import {ReactComponent as ASd} from "../../../../../../assets/buttons/";
type formType = {
   message: string;
};

export const ReportPop = ({
   user_id,
   closeReportPop,
   message_id,
}: {
   user_id: string;
   closeReportPop: () => void;
   message_id?: number;
}) => {
   const { t } = useTranslation();

   const [isLoading, setIsLoading] = useState<boolean>(false);
   const myUser = useSelector(lkSliceSelectors.getUserData);

   const commonState = useLocalCommonState();
   const {
      control,
      handleSubmit,
      formState: { errors },
   } = useForm<formType>({
      reValidateMode: "onSubmit",
   });
   const ref = useRef<HTMLDivElement>(null);
   useBlurred(ref, closeReportPop);

   function submit(data: formType) {
      setIsLoading(true);

      commonState.dispatch(
         ChatThunk.createChat({
            commonState,
            callbacks: {
               "201": (response) => {
                  commonState.location =
                     routes.lk.messages + "/help/" + response.data.data.id;

                  commonState.dispatch(
                     ChatThunk.creatNewMessage({
                        message: {
                           message: data.message,
                           files: [],
                           link_message_id: message_id,
                           message_type: "abuse_message",
                        },
                        chat_id: response.data.data.id,
                        commonState,
                        callbacks: { "201": () => closeReportPop() },
                     })
                  );
               },
            },
            admin_chat: true,
            users: [Number(user_id)],
         })
      );
      setIsLoading(false);
   }

   return (
      <div className={styles.container}>
         <div ref={ref} className={styles.body}>
            <form onSubmit={handleSubmit(submit)} className={styles.form}>
               <button
                  type={"button"}
                  onClick={closeReportPop}
                  className={styles.cross}
               >
                  <Cross />
               </button>
               <h2 className={styles.title}>
                  {t("Lk.ModalWindow.title", "Подача жалобы")}
               </h2>
               <TextArea
                  className={styles.area}
                  control={control}
                  name={"message"}
               />
               <Button loading={isLoading} className={styles.button}>
                  {t("Lk.ModalWindow.send-complaint", "Отправить жалобу")}
               </Button>
            </form>
         </div>
      </div>
   );
};

const Cross = () => (
   <svg
      width='8'
      height='8'
      viewBox='0 0 8 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
   >
      <path
         d='M0.5 0.5L7.5 7.5M7.5 0.5L0.5 7.5'
         stroke='white'
         stroke-width='0.777778'
         stroke-linecap='round'
         stroke-linejoin='round'
      />
   </svg>
);
