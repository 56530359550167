import { addMethodToUpdateLang } from "../../../../services/translateService/useLangInit";
import i18n from "services/translateService/i18n";

export let QuestionsData: Array<{
   ask: string;
   answer: string;
}>;

addMethodToUpdateLang(() => {
   QuestionsData = [
      {
         ask: i18n.t(
            "Landings.Questions.array.0.ask",
            "Is the service really free?"
         ),
         answer: i18n.t(
            "Landings.Questions.array.0.answer",
            "Yes, our service is 100% free for all"
         ),
      },
      {
         ask: i18n.t("Landings.Questions.array.1.ask", "Who are the tutors?"),
         answer: i18n.t(
            "Landings.Questions.array.1.answer",
            "Tutors are academically strong high-school students in their last 2 years of studies who share a passion for their subject and want to share it with others through teaching."
         ),
      },
      {
         ask: i18n.t("Landings.Questions.array.2.ask", "Who can be a student?"),
         answer: i18n.t(
            "Landings.Questions.array.2.answer",
            "Anyone! Elementary, middle, or high school. All ages are welcome."
         ),
      },
      {
         ask: i18n.t(
            "Landings.Questions.array.3.ask",
            "Will I get community service hours?"
         ),
         answer: i18n.t(
            "Landings.Questions.array.3.answer",
            'Of course! If you are a tutor or staff member (application in the "Our Team" tab, you may request an official community service report which we can provide you with.'
         ),
      },
      {
         ask: i18n.t(
            "Landings.Questions.array.4.ask",
            "What if my subject is not available?"
         ),
         answer: i18n.t(
            "Landings.Questions.array.4.answer",
            "Describe the item or answer the question so that site visitors who are interested get more information. You can emphasize this text with bullets, italics or bold, and add links."
         ),
      },
   ];
});
