import React, { FC, useEffect } from "react";
import "./style.css";
import { RegistrationWrapper } from "../RegistrationWrapper";
import { Route, useHistory } from "react-router-dom";
import { routes } from "routes";
import { RegistrationBackground } from "../RegistrationBackground";
import { TeacherFirstStage } from "./TeacherFirstStage";
import { TeacherPickLanguage } from "./TeacherPickLanguages";
import { TeacherRegistrationChooseSubjects } from "./TeacherRegistrationChooseSubjects";
import { AddFiles } from "./AddFiles";
import { Done } from "./Done";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "../../../store/lkSlice";
type propsType = {};

export const RegistrationTeacher: FC<propsType> = (props) => {
   const user = useSelector(lkSliceSelectors.getUserData);
   const history = useHistory();
   useEffect(() => {
      if (user?.type == "teacher") {
         history.push(routes.landings.main);
      }
   }, [user]);
   return (
      <div className='RegistrationTeacher'>
         <RegistrationWrapper variant={"teacher"}>
            <Route path={routes.registration.teacher.firstStage}>
               <TeacherFirstStage />
            </Route>
            <Route path={routes.registration.teacher.studyLanguages}>
               <TeacherPickLanguage />
            </Route>
            <Route path={routes.registration.teacher.instructionsSystems}>
               <TeacherRegistrationChooseSubjects />
            </Route>
            <Route path={routes.registration.teacher.files}>
               <AddFiles />
            </Route>
            <Route path={routes.registration.teacher.done}>
               <Done />
            </Route>
         </RegistrationWrapper>
         <RegistrationBackground type={"teacher"} />
      </div>
   );
};
