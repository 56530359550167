import { createRequest } from "../../core/LTools/core/apiMethods/createRequest";
import { mainAxios } from "../index";
import {
   changeLanguageType,
   changeNotificationStatusType,
   changeOnlineStatusType,
   changeTimezoneType,
   getNotebookType,
   getNotificationsType,
   getStatisticsType,
   getUserFileType,
   likeUserType,
   patchUserType,
   updateUserType,
   uploadUserFile,
} from "./types";
import { getUserAvatarType, getUserType, userIdType } from "../types";
import { dataToQueryString } from "core/LTools/core/apiMethods/dataToQueryString";

export const userApi = {
   getUsers: async (props: getUserType) => {
      return createRequest(mainAxios.get(`/user` + dataToQueryString(props)));
   },

   getAvatar: async ({ user_id }: getUserAvatarType) => {
      return createRequest(
         mainAxios.get(`/user/${user_id}/avatar`, {
            headers: {
               // "Cache-Control": "must-revalidate, public",
               // "Cache-Control": "public",
               // "Cache-Control": "must-revalidate, private",
               // "Cache-Control": "no-cache, public",
               // "If-Modified-Since": "Fri, 22 Oct 2021 05:26:24 +0000",
            },
         })
      );
   },

   getUser: async ({ user_id }: userIdType) => {
      return createRequest(mainAxios.get(`/user/${user_id}`));
   },

   update: async ({ user_id, payload }: updateUserType) => {
      return createRequest(mainAxios.put(`/user/${user_id}`, payload));
   },

   patch: async ({ user_id, payload }: patchUserType) => {
      return createRequest(mainAxios.patch(`/user/${user_id}`, payload));
   },

   uploadUserFile: async ({ user_id, ...payload }: uploadUserFile) => {
      return createRequest(mainAxios.post(`/user/${user_id}/file`, payload));
   },

   getNotebook: async ({ user_id, token, ...query }: getNotebookType) => {
      return createRequest(
         mainAxios.get(`/user/${user_id}/notebook${dataToQueryString(query)}`, {
            cancelToken: token,
         })
      );
   },

   likeUser: async ({ user_id, communication_id, ...body }: likeUserType) => {
      return createRequest(
         mainAxios.put(
            `/user/${user_id}/notebook/communication/${communication_id}`,
            body
         )
      );
   },

   getUserFile: async ({ user_id, file_id }: getUserFileType) => {
      return createRequest(mainAxios.get(`/user/${user_id}/file/${file_id}`));
   },

   getNotifications: async (query: getNotificationsType) => {
      return createRequest(
         mainAxios.get(`/notification${dataToQueryString(query)}`)
      );
   },
   changeNotificationStatus: async ({
      notification_ids,
   }: changeNotificationStatusType) => {
      return createRequest(
         mainAxios.post(`/notification:read`, { ids: notification_ids })
      );
   },
   changeOnlineStatus: async ({ status, user_id }: changeOnlineStatusType) => {
      return createRequest(mainAxios.post(`/user/${user_id}:${status}`));
   },
   getStatistics: async ({ user_id, ...query }: getStatisticsType) => {
      return createRequest(
         mainAxios.get(`/user/${user_id}/statistic${dataToQueryString(query)}`)
      );
   },
   changeLanguage: async ({ user_id, language }: changeLanguageType) => {
      return createRequest(
         mainAxios.put(`/user/${user_id}/language`, {
            language,
         })
      );
   },
   changeTimezone: async ({ user_id, timezone }: changeTimezoneType) => {
      return createRequest(
         mainAxios.put(`/user/${user_id}/timezone`, {
            timezone,
         })
      );
   },
};
