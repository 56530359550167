import { useMedia } from "hooks/useMedia";
import { useSelector } from "react-redux";
import { authSliceSelectors } from "store/authSlice";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { routes } from "routes";
import { ChatThunk } from "store/chatSlice/thunk";
import { useQuery } from "hooks/useQuery";
import { userApi } from "api/userApi/methods";
import { useGetUserAvatar } from "hooks/useGetUserAvatar";
import { TableItemType } from "components/UI/Table";
import { TableElementType } from "components/Lk/Pages/Notebook/components/NotePeoples/index";
import { useState } from "react";
import { useGoToProfile } from "helpers/util/goToProfile";

export const useNotebookPeopleTableHandlers = ({
   user,
   is_like,
   user_type,
   id: communication_id,
}: TableElementType) => {
   const { width } = useMedia();
   const clientUserId = useSelector(authSliceSelectors.getId);
   const commonState = useLocalCommonState();
   const [liked, setLiked] = useState(is_like);
   const goToUserProfile = useGoToProfile(user.id);

   const createChatWithUser = () => {
      commonState.dispatch(
         ChatThunk.createChat({
            needSetLocation: true,
            commonState,
            users: [Number(clientUserId), Number(user.id)],
            admin_chat: false,
         })
      );
   };

   const { fetch: likeHandler, isLoading: isLikingProcess } = useQuery(
      () =>
         userApi.likeUser({
            user_id: clientUserId,
            communication_id: communication_id,
            is_like: !liked,
         }),
      {
         condition: false,
         serializer: (res) => {
            setLiked(!liked);
            return res;
         },
      }
   );

   const [avatar] = useGetUserAvatar(user.id);

   return {
      avatar,
      likeHandler,
      createChatWithUser,
      width,
      goToUserProfile,
      liked,
      isLikingProcess,
   };
};
