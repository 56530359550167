import styles from "./index.module.css";

export const UserAgreement = () => {
   return (
      <div className={styles.container}>
         <p>
            These are the terms under which E-Tutor International (“we”, “us”, “our” or “the company”),
            a company registered in Estonia (company number 16788028), and whose registered office
            is at Harju maakond, Tallinn, Lasnamäe linnaosa, Väike-Paala tn 2, 11415 , makes the E-Tutor
            International platform available to people wishing to find and engage a tutor (“you”, “your”,
            “the client”). These terms were produced in accordance with Estonian legislation governing
            employment relations and recruitment services. We operate as a recruitment service
            provider in compliance with the Employment Contracts Act and relevant Estonian laws. We
            do not act as your agent for engaging tutors, as explained below.
         </p>
         <p>
            <h3>INTRODUCTION</h3>
            <h3>1. What we do</h3>
            1.1. E-Tutor International is an online 1-2-1 tutoring marketplace. Clients use E-Tutor
            International to find highly-qualified tutors for themselves or for others, such as their
            children, and then use our platform to communicate, schedule, have and pay for online
            classes.
         </p>
         <p>
            <h3>CLIENT TERMS</h3>
            <h3>2. Introduction</h3>
            2.1. Application of terms: These terms apply to you if you have created a client account.
            They form a contract between you and us. That contract includes everything incorporated by
            reference.<br />
            2.2. Students: you may engage tutors on behalf of others (“students”), for example for
            younger members of the family who would otherwise be ineligible to create an account.
            When you do so, you must ensure that your students obey the code of conduct in the same
            way you are required to.<br />
            2.3. Use of platform: you must communicate and arrange for classes to be taken through the
            platform.<br />
            2.4. Relationship with tutors: when you engage a tutor, you may negotiate any terms that
            you like, provided that in so doing you do not violate your agreement with us. You should
            bear in mind that our platform automatically carries out various tasks for you. The platform’s
            automatic operation is not intended to impose constraints on the kind of agreement you
            may make with a tutor and we leave it to you and the tutor to resolve how to make best use
            of the platform.<br />
            2.5. Information submitted to the site: You are responsible for all content that you make
            available through our platform. Accordingly, you warrant that:<br />
            2.5.1. You have the right to make that content available, for example because you are the
            owner of the content, or have suitable permission from the owner to do so.<br />
            2.5.2. The making available of the content does not infringe a third party’s patent, copyright,
            trademark, trade secret, moral rights or other intellectual property rights, or rights of
            publicity or privacy, or result in the violation of any applicable law or regulation.
         </p>
         <p>
            <h3>3. Our fee</h3>
            3.1. We charge a 20% fee (the “platform fee”) for each class, calculated as a fixed percentage
            on top of the tutor’s fee.<br />
            3.1.1. Fees may be subject to VAT or other similar sales or value-added tax applicable to the
            payment in question.<br />
            3.1.2. We believe that the majority of tutors are not VAT-registered, but this is not
            information we collect. Tax payable on fees by tutors is a matter for the tutor.<br />
            3.1.3. Our fee will be charged in the same currency as the fee agreed with the tutor.<br />
            3.2. If, in breach of this agreement, you take any action to conceal the fee due to us,
            including engaging the tutor to deliver classes other than through the platform, we may in
            our absolute discretion elect to serve notice on you for the payment of liquidated damages.
            The service of such a notice will convert the damages due from you to us for the breach or
            breaches identified in the notice to a debt of 250 euros (plus any VAT that may be due) owed
            by you to us on the date of service. You agree that it would be difficult for us to calculate
            properly the amount of damages and that it is therefore convenient for us to agree a fixed
            sum as liquidated damages.
         </p>
         <p>
            <h3>4. Payments</h3>
            4.1. In order to book a class, you must set up a payment method with us, for example by
            having a valid payment card associated with your account.<br />
            4.2. When you supply information for billing and payment purposes, it must be accurate and
            belong to you.
         </p>
         <p>
            <h3>5. Eligibility</h3>
            5.1. You must be enrolled into a school or university, of student age if home-schooled.<br />
            5.2. Students under the age of 18 must at all times use the platform only in conjunction with
            the permission of, and/or under the supervision of a parent, legal guardian, or appropriate
            person aged 18 or over
         </p>
         <p>
            <h3>6. Our service : general</h3>
            6.1. In return for the fee(s) you pay us, we:<br />
            6.1.1. Assemble, maintain and index a database of potential tutors.<br />
            6.1.2. Make the platform available for you to engage tutors and for you, or your students, to
            receive tutoring.<br />
            6.1.3. Provide support for your use of our platform.
         </p>
         <p>
            <h3>7. Our service : tutors</h3>
            7.1. Although we hope that you will find a tutor or tutors that are suitable for you, we
            cannot, and so do not, promise that you will find any or any suitable tutors in our database
            or that tutors you did find useful on one occasion will continue to be useful in another.<br />
            7.2. We make best efforts to ensure that those tutors who sign up are suitable to the
            following extent:<br />
            7.2.1. We ask tutors to provide, where possible, information revealing any criminal
            convictions that might disqualify them from teaching young people.<br />
            7.3. Where carrying out best efforts checks on tutors of the kind discussed above, we have
            no way of making absolutely certain that all such information is genuine, but we reject any
            tutor where we either have not seen; or are not convinced by the evidence we have been
            shown.<br />
            7.4. Except to the extent explained in the previous paragraphs, we have no control over the
            nature or quality of the tutors. We are not responsible for their failure to deliver classes or
            classes that are suitable for the student. It is up to you whether to engage a tutor and what
            action you take if you think a tutor’s skill or other conduct has fallen short of what you
            expect.
         </p>
         <p>
            <h3>8. Our service : platform and website</h3>
            8.1. Our platform is accessible through the website https://E-Tutor International.com.<br />
            8.2. We make best efforts to ensure that our platform runs properly and that it is available
            except for periods of scheduled maintenance. Despite our best efforts, there will be times
            when the platform is not available for one reason or another. For that reason, we cannot
            guarantee – and therefore do not promise that the operation of the platform will be
            continuous or faultless.<br />
            8.3. We may suspend your access to our platform at any time, irrespective of whether there
            are classes booked, if we believe that:<br />
            8.3.1. We are required to do so by law or your continued use of our platform contravenes, or
            is likely to contravene, any applicable law.<br />
            8.3.2. Your use of the platform violates, or is likely to violate, any other person’s rights.<br />
            8.3.3. We reasonably believe you are in breach of your agreement with us.<br />
            8.4. We reserve the right to modify and/or update the platform’s functionality as may be
            required at any time without prior notice.
         </p>
         <p>
            <h3>9. Our service: support</h3>
            9.1. We provide ongoing administrative support during weekdays except during public
            holidays. For example: we make best efforts to answer your queries; help with tech issues;
            mediate disputes; and sort out payment issues.
         </p>
         <p>
            <h3>10. Disputes</h3>
            10.1. If you have a grievance about a tutor, then you should communicate it in writing to the
            tutor and attempt to resolve the issue amicably. Your relationship with a tutor is between
            you and the tutor. We have no power to compel a tutor to refund you for a poorly conducted
            class.<br />
            10.2. However, if you are not able to resolve a dispute, you may escalate the matter to us.
            We may at our discretion investigate the cause of the grievance and seek to mediate a
            resolution, but we are under no obligation to do so or, if we are unable to obtain a successful
            resolution, provide any alternative remedy.<br />
            10.3. Any offer we may make in the event of dissatisfaction is at the discretion of the
            company and does not constitute acceptance of responsibility for the outcome of tutoring
            services.
         </p>
         <p>
            <h3>11. Duration and termination</h3>
            11.1. This agreement shall start on the date your account is created and continue until it is
            terminated by either party giving notice to the other, which they may do at any time and
            without any restriction, as follow:<br />
            11.1.1. You may terminate this agreement at any time by deleting your account in the
            "Settings" section.<br />
            11.1.2. We may terminate this agreement by sending you a notice by email indicating that
            we have terminated it.<br />
            11.2. Termination of this agreement does not extinguish any liabilities that have already
            accrued, for example you will continue to owe us for any outstanding unpaid fees. Unless
            otherwise stated in our terms, all other obligations on one party to the other shall cease on
            termination.
         </p>
         <p>
            <h3>12. Exclusion of Warranties</h3>
            12.1. Our platform is provided on an "as is," "where is," "as available" and "with all faults"
            basis and, to the fullest extent permitted by law, are provided without warranty of any kind.<br />
            12.2. We disclaim all warranties with respect to the platform, including any implied
            warranties of merchantability, fitness for a particular purpose, non-infringement and title,
            and any warranties regarding quiet enjoyment, quality of information, security, reliability,
            timeliness, availability of backed-up data and performance of the site and services.<br />
            12.3. We do not warrant that the site and services will meet your requirements, or that the
            operation of the site and services will be uninterrupted or error-free, or that defects in the
            site and services will be corrected in a timely manner, or that encryption algorithms,
            associated keys and other security measures will be secure or effective.<br />
            12.4. Other than carrying out visual checks of certain documentation as explained under
            “Our service: tutors” above, we do not attempt to verify the professional background or
            qualifications of tutors and we make no representations or warranties as to the claims made
            by, or the conduct of, tutors. It is your responsibility to take reasonable precautions in your
            communication with each other and check your compatibility with other users of the
            platform.<br />
            12.5. We make no guarantee as to the outcome of tutoring services, in respect of grades
            achieved, qualifications received and/or certification from any educational institution. The
            quality and outcome of a student’s work remains their responsibility.<br />
            12.6. No advice or information, whether oral or written, obtained from us or through the
            site, services or collective content, will create any warranty not expressly made herein.
         </p>
         <p>
            <h3>13. Liability and Damages</h3>
            13.1. Acceptance of these Terms includes an agreement to indemnify the company, without
            limit, in respect of any claims arising out of, or relating to, tutoring services rendered
            through the platform.<br />
            13.2. E-Tutor International is not an agent for any user of the platform and will not be
            responsible for any damages or harm which results from the interactions of users.<br />
            13.3. Any class between users and any purchase of tutoring services are done at the parties'
            sole risk and discretion and you agree to take reasonable precautions in all interactions to
            exercise common sense and sound judgement in using the platform and disclosing
            information, including any personal information, the provision of which is not required by
            the platform.<br />
            13.4. The company accepts no liability for the outcome of any tutoring services rendered
            through the platform, or any academic judgement made upon the student’s work by any
            educational institution or professional body with which they may be affiliated. Responsibility
            for the success or failure of their efforts rests entirely with the student.<br />
            13.5. We shall not be liable for any consequential, indirect or special losses or for any of the
            following, whether direct or indirect: loss of profit or savings, loss of opportunity or contract,
            loss of reputation or goodwill arising from use of the platform or associated tutoring
            services. We disclaim any liability for disruption to tutoring services from the unavailability
            or performance of the platform and will accept no liability for loss of earnings or any other
            detriment arising from this.<br />
            13.6. We shall not be liable for any loss or any other damages that result from a corruption
            of data, lost communications, loss or corruption of software or downtime of either the
            platform or any third-party systems, including the loss or corruption of automated class
            recordings.<br />
            13.7. Users are solely responsible for all of their communications with other users of the
            platform. The company accepts no liability for any user content or written communications
            between users. E-Tutor International does not control, nor is it responsible for, the truth,
            accuracy, completeness, safety, timeliness, quality, appropriateness, legality or applicability
            of anything said or written by users or other information made available through the
            platform.<br />
            13.8. E-Tutor International will not be held responsible for any Tutor's failure to comply with
            applicable laws or regulations.<br />
            13.9. In no event will E-Tutor International' aggregate liability arising out of or in connection with these terms exceed the total amount of all the platform fees paid in relation to tutoring
            services.<br />
            13.10. This agreement does not limit or exclude liability where such limitation or exclusion
            would not be permitted by applicable law.
         </p>
         <p>
            <h3>14. Data Protection</h3>
            14.1. We process the personal data of users solely for the purposes of the proper
            functioning of the platform, communication between users and billing.<br />
            14.2. For further information, please see our privacy notice.
         </p>
         <p>
            <h3>15. Trademarks, Intellectual Property and Licensing</h3>
            15.1. Nothing in this agreement shall be interpreted as (i) granting, by implication or
            otherwise, any licence to use any trademarks, logos and/or other branding materials or (ii)
            an assignment of any rights including intellectual property rights.<br />
            15.2. Any trademarks, trade names, service marks and logos used and displayed on this
            website are the registered or unregistered trademarks of, and the exclusive property of E-
            Tutor International, and/or its affiliates or others and are protected under applicable law.<br />
            15.3. You may not modify, copy, duplicate, print, archive, distribute, transmit, display,
            perform, reproduce, publish, licence, create derivative works from, transfer or sell any
            information, material, software, products or services from the platform without our prior
            written consent except as part of the normal use of our platform.<br />
            15.4. We do not claim any ownership rights of any user content and nothing in these terms
            will be deemed to restrict any rights that you may have to use and exploit such content. In
            some cases, for example recordings of classes, content may be hosted on an external
            platform in which case the content and usage rules of that platform will govern what you
            may do with content accessible from it.<br />
            15.5. No licences or rights are granted to you by implication or otherwise under any
            intellectual property rights owned or controlled by us or our licensors, except for the
            licences and rights expressly granted in these terms.
         </p>
         <p>
            <h3>16. Copyright Infringement</h3>
            16.1. If you believe that your work has been copied in violation of applicable copyright law
            and used in a way that constitutes copyright infringement, please provide us with the
            information set out below; without this information we will be unable to take action on your
            request:<br />
            i) Your contact information. We need sufficient information to be able to contact you, for
            example your physical or electronic email address or telephone number.<br />
            ii) Identification of the copyrighted work that you claim has been infringed. Please include
            reasonably sufficient details describing the copyrighted work that is claimed to be infringing.<br />
            iii) A description of where the material that you claim is infringing is located on E-Tutor
            International. Please include reasonably sufficient detail to enable us to identify and locate
            the work that is claimed to be infringing, including the explicit URL(s) of the exact work(s)
            and screen-shot(s) of the infringement.<br />
            16.2. We will review all claims of copyright infringement received and remove any content
            where we discover that it has been posted or distributed in violation of any copyright law. If
            you feel that content has been taken down inappropriately please contact our Copyright
            Agent who will provide information on the requirements and procedure for filing a counter-
            notification with us.
         </p>
         <p>
            <h3>17. Notices</h3>
            17.1. Any notices that need to be sent under these terms shall be written in English, sent by email to the receiving party's email address and deemed received when the receiving party's
            email server transmits a success code to the sending party. Our email address is sup@e-
            tutorinternational.com. Your email address is the most recent one you have entered on our
            system through your account.
         </p>
         <p>
            <h3>18. Governing Law, Jurisdiction and Legal Notices</h3>
            18.1. This agreement shall be governed by the laws of Estonia, and the courts of Estonia
            shall have jurisdiction to settle any disputes arising in connection with it.<br />
            18.2. You acknowledge that disputes shall be resolved in accordance with Estonian legal
            procedures. Any provision of this agreement that limits a user's legal rights under Estonian
            law or EU directives, including rights related to dispute resolution and collective redress, will
            be modified to the extent necessary to ensure compliance.<br />
            18.3. You acknowledge and agree that you and E-Tutor International each waive the right to
            participate as a plaintiff or class member in any purported class action or representative
            proceeding. Further, unless both you and E-Tutor International otherwise agree in writing,
            the arbitrator may not consolidate more than one person's claims, and may not otherwise
            preside over any form of any class or representative proceeding.
         </p>
         <p>
            <h3>19. General</h3>
            19.1. Our failure to enforce any right or provision of these terms will not constitute a waiver
            of future enforcement of that right or provision.<br />
            19.2. The exercise by either party of any of its remedies under these terms will be without
            prejudice to its other remedies under these terms or otherwise.<br />
            19.3. If any part of these terms and conditions of use is invalid or unenforceable pursuant to
            applicable laws, the invalid or unenforceable provision will be deemed to be severed from
            these terms without affecting the remainder hereof or superseded by a valid and
            enforceable provision that most closely matches the intent of the original provision.<br />
            19.4. Nothing in this agreement is intended to create a partnership, or to constitute one
            party the agent of another.
         </p>
      </div>
   );
};
