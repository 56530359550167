import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { ChatThunk } from "store/chatSlice/thunk";
import { routes } from "routes";
import { TextArea } from "components/UI/TextArea";
import React, { FC, useEffect } from "react";
import { Button } from "components/UI/Button";
import { lkSliceSelectors } from "store/lkSlice";
import { ModalWindowBody } from "components/ModalWindow/templates/components/ModalWindowBody";
import styles from "./index.module.css";
import { Icons } from "assets/iconsCorrect";

type propsType = {
   closeModal: () => void;
   type: "lesson" | "message";
};

export const ComplaintPopup: FC<propsType> = (props) => {
   const form = useForm({
      reValidateMode: "onSubmit",
   });
   const { control } = form;
   const { t } = useTranslation();
   const commonState = useLocalCommonState();
   const user = useSelector(lkSliceSelectors.getUserData);
   const postMessage = (chat_id: number, message: string) => {
      commonState.dispatch(
         ChatThunk.creatNewMessage({
            commonState,
            chat_id,
            message: {
               message,
               message_type:
                  props.type == "lesson" ? "abuse_vote" : "abuse_message",
            },
            callbacks: {
               "201": () => {
                  props.closeModal();
               },
            },
         })
      );
   };

   const createChat = ({ message }: { message: string }) => {
      commonState.dispatch(
         ChatThunk.createChat({
            admin_chat: true,
            users: [Number(user.id)],
            needSetLocation: false,
            commonState,
            callbacks: {
               "201": (responseChat) => {
                  const chat = responseChat.data.data;
                  postMessage(chat.id, message);
                  commonState.location =
                     routes.lk.messages + "/help/" + chat.id;
               },
            },
         })
      );
   };

   return (
      <form
         className={styles.container}
         onSubmit={form.handleSubmit(createChat)}
      >
         <ModalWindowBody
            title={
               props.type == "lesson"
                  ? t("Messages.complaint-to-lesson", "Пожаловаться на урок")
                  : t(
                       "Messages.complaint-to-message",
                       "Пожаловаться на сообщение"
                    )
            }
            subtitle={""}
            close={props.closeModal}
         />
         <Icons.ui.closeIcon
            className={styles.close}
            onClick={props.closeModal}
         />
         <TextArea
            placeholder={t(
               "UI.DefaultTextarea.placeholder",
               "Введите сообщение"
            )}
            className={styles.textarea}
            control={form.control}
            name={"message"}
         />
         <Button className={styles.button} loading={commonState.loading}>
            {t("Messages.contact-support", "Написать в поддержку")}
         </Button>
      </form>
   );
};
