import React, { FC, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Button } from "../../../UI/Button";
import { routes } from "routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { ReactComponent as GoogleIcon } from "./assets/Google.svg";
import { ReactComponent as AppleIcon } from "./assets/Apple.svg";
import { useTranslation } from "react-i18next";
import styles from "./index.module.css";
import { authThunks } from "store/authSlice/thunks";
import { emailSchema } from "helpers/validationSchemes";
import cn from "classnames";
import { Input } from "components/UI/Input";
import AppleSignin from 'react-apple-signin-auth';
import {appleAuthType} from "../../../../api/AuthApi/types";

export type FormType = {
   email: string;
};

export const FirstStage: FC = () => {
   const { t } = useTranslation();

   const {
      control,
      handleSubmit,
      formState: { errors },
   } = useForm<FormType>({
      // @ts-ignore
      resolver: yupResolver(emailSchema),
   });
   const email = useWatch({
      control: control,
      name: "email",
   });

   const commonState = useLocalCommonState();

   const submit = ({ email }: FormType) => {
      /** @Logic
       * 1. get user
       * if (user) REDIRECT password STOP
       *    else createUser
       * 2. if (user created) createCode
       * 3. if (code created) REDIRECT code STOP
       *  */
      const createCode = () => {
          const handleSuccess = () => {
              commonState.location = routes.registration.auth.registrationCode;
          };

         commonState.dispatch(
            authThunks.createCode({
               commonState,
               template: "register",
               callbacks: {
                  201: handleSuccess,
                  200: handleSuccess,
               },
            })
         );
      };
      const createUser = () => {
         commonState.dispatch(
            authThunks.createUser({
               commonState,
               email,
               callbacks: {
                  201: () => {
                     createCode();
                  },
                  200: () => {
                     createCode();
                  },
               },
            })
         );
      };

      commonState.dispatch(
         authThunks.getUser({
            commonState,
            email,
            callbacks: {
               200: (response) => {
                  const user = response.data.data[0];
                  if (user) {
                     commonState.location =
                        routes.registration.auth.authorization;
                  } else {
                     createUser();
                  }
               },
            },
         })
      );
   };

   const [tokenClient, setTokenClient] = useState({});

   const googleAuth = () => {
      //@ts-ignore
     tokenClient.requestAccessToken();
   };

  useEffect(() => {
    // @ts-ignore
    window.google.accounts.id.initialize({
      client_id: "212407717527-oa60ka7nqubirhj9fdnvi1ctdrhd5cja.apps.googleusercontent.com",
    })

    setTokenClient(
      // @ts-ignore
      window.google.accounts.oauth2.initTokenClient({
        client_id: "212407717527-oa60ka7nqubirhj9fdnvi1ctdrhd5cja.apps.googleusercontent.com",
        scope: "email profile openid",
        callback: (tokenResponse: any) => {
          commonState.dispatch(
            authThunks.googleAuth({
              google_token: tokenResponse.access_token,
              commonState,
            })
          );
        }
      }))
  }, []);

  const handleAppleAuth = (appleAuthResponse: appleAuthType) => {
      commonState.dispatch(
        authThunks.appleAuth({
          ...appleAuthResponse,
          commonState,
        })
      );
  }

   return (
      <div className={styles.container}>
         <div className={styles.title}>
            {t(
               "Registration.Auth.FirstStage.student-title",
               "Введите ваш email"
            )}
         </div>
         <form className='flex-col' onSubmit={handleSubmit(submit)}>
            <Input
               autoFocus
               control={control}
               name='email'
               type='email'
               label={t(
                  "Registration.Auth.FirstStage.email-input-label",
                  "Ваш email"
               )}
               error={errors.email?.message}
            />
            <p className={styles.text}>
               {t(
                  "Registration.Auth.FirstStage.new-account-note",
                  "При остутствии аккаунта процесс регистрации начнется автоматически."
               )}
               <br />
               <br />
               {t(
                  "Registration.Auth.FirstStage.password-note",
                  "Для входа в существующий аккаунт потребуется ввод пароля."
               )}
            </p>
            <Button
               className={cn(styles.action_button, {
                  "x-centred": true,
               })}
               type='submit'
               variant={"primary"}
               disabled={!email}
               loading={commonState.loading}
            >
               {t("Registration.Auth.FirstStage.continue-button", "Продолжить")}
            </Button>
         </form>
         <div className={styles.separator}>
            {t("Registration.Auth.FirstStage.or-line", "или")}
         </div>
         <Button
            variant='outline'
            className={cn(styles.button_google, {
               "x-centred": true,
            })}
            onClick={googleAuth}
         >
            <GoogleIcon className={styles.third_party_auth__icon} />
            {t(
               "Registration.Auth.FirstStage.google-button",
               "Вход через аккаунт Google"
            )}
         </Button>
          <AppleSignin
              /** Auth options passed to AppleID.auth.init() */
              authOptions={{
                  clientId: 'com.etutor.auth',
                  scope: 'email name',
                  redirectURI: 'https://e-tutorinternational.com/registration/auth/first-stage',
                  nonce: 'nonce',
                  usePopup: true,
              }}
              uiType="light"
              noDefaultStyle={false}
              onSuccess={(appleAuthResponse: appleAuthType) => handleAppleAuth(appleAuthResponse)}
              onError={(error: any) => console.error(error)}
              skipScript={false}
              render={(props: any) => <Button
                  {...props}
                  variant='outline'
                  className={cn(styles.button_apple, {
                      "x-centred": true,
                  })}
              >
                  <AppleIcon className={styles.third_party_auth__icon} />
                  {t(
                      "Registration.Auth.FirstStage.apple-button",
                      "Вход через аккаунт Apple"
                  )}
              </Button>}
          />

      </div>
   );
};
