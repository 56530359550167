// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__37tDN {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.style_button__21AKF {\n  font-family: var(--font-Manrope);\n  color: var(--color-blue2);\n  font-size: 14px;\n  font-weight: 500;\n}", "",{"version":3,"sources":["webpack://src/components/Lk/components/Notifications/components/Buttons/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,gCAAgC;EAChC,yBAAyB;EACzB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.button {\n  font-family: var(--font-Manrope);\n  color: var(--color-blue2);\n  font-size: 14px;\n  font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__37tDN",
	"button": "style_button__21AKF"
};
export default ___CSS_LOADER_EXPORT___;
