import React, { FC } from "react";
import styles from "./style.module.css";
import cn from "classnames";
import { cardType } from "../types";
import { useHistory } from "react-router-dom";
import { routes } from "routes";
import { CardTime } from "../components/CardTime";
import { useGetUserAvatar } from "hooks/useGetUserAvatar";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";
import { useTranslation } from "react-i18next";
import { coreLessonType } from "types/LessonService/types";

type propsType = {
   type: cardType;
} & {
   [key: string]: any;
} & coreLessonType;

const cardRouteResolver: {
   [key in cardType]?: string;
} = {
   lesson: routes.lk.lesson.default.view,
   invite: routes.lk.lesson.invite.view,
   group_lesson: routes.lk.lesson.group.view,
};

const resolveCardColorByType = (type: cardType): "white" | "black" => {
   switch (type) {
      case "lesson":
      case "invite":
      case "group_lesson":
         return "white";
      default:
         return "white";
   }
};

export const StudentCard: FC<propsType> = ({ type, ...other }) => {
   const history = useHistory();
   const user = useSelector(lkSliceSelectors.getUserData);
   const { t } = useTranslation();

   const defineUser = () => {
      if (other?.student) {
         if (other.student?.id == user.id) {
            return other.teacher;
         } else {
            return other.student;
         }
      }
      return other?.teacher;
   };

   const generateTitle = (type: cardType) => {
      switch (type) {
         case "invite":
            return t(
               "Lk.Profile.cards.student.invite_title",
               "Запрос занятия у преподавателя"
            );
         case "lesson":
            return t(
               "Lk.Profile.cards.student.lesson_title",
               "Занятие с преподавателем"
            );
         case "group_lesson":
            return t(
               "Lk.Profile.cards.student.group_lesson_title",
               "Открытый урок"
            );
      }
   };

   return (
      <div
         className={cn(styles.container, {
            [`${styles[`container--${type}`]}`]: true,
         })}
         onClick={() => history.push(cardRouteResolver[type] + "/" + other.id)}
      >
         <h5 className={styles.title}>{generateTitle(type)}</h5>
         <CardTime
            date_from={other.date_from}
            date_to={other.date_to}
            subject={other.subject.name}
            color={resolveCardColorByType(type)}
            user={defineUser()}
         />
         <button className={styles.button}>
            {t("Lk.Notifications.buttons.more", "Подробнее")}
         </button>
      </div>
   );
};
