import { lkApi } from "api/LkApi/methods";
import { useState } from "react";
import { useQuery } from "react-query";

export const useGetPaymentByPaymentId = (payment_id: string) => {
    const [isOpened, setIsOpened] = useState(false);
    const { data, isLoading, error } = useQuery(
        "useGetPaymentByPaymentId",
        () => lkApi.getPaymentByPaymentId(payment_id),
        {
            enabled: Boolean(payment_id),
        }
    );

    return {
        payment: data?.data[0],
        isLoading,
        error,
    };
};
