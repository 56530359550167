// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__tLgZf {\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n}\n.style_title__22iAb {\n    margin-top: 30px;\n    font-family: var(--font-Manrope);\n    font-style: normal;\n    font-weight: 600;\n    font-size: 32px;\n    line-height: 44px;\n    text-align: center;\n    margin-bottom: 10px;\n}\n.style_description__3qAj9 {\n    font-family: var(--font-Manrope);\n    font-style: normal;\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 140%;\n    text-align: center;\n    margin-bottom: 100px;\n}", "",{"version":3,"sources":["webpack://src/components/Registration/TeacherPages/Done/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;AAC1B;AACA;IACI,gBAAgB;IAChB,gCAAgC;IAChC,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,gCAAgC;IAChC,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,oBAAoB;AACxB","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n}\n.title {\n    margin-top: 30px;\n    font-family: var(--font-Manrope);\n    font-style: normal;\n    font-weight: 600;\n    font-size: 32px;\n    line-height: 44px;\n    text-align: center;\n    margin-bottom: 10px;\n}\n.description {\n    font-family: var(--font-Manrope);\n    font-style: normal;\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 140%;\n    text-align: center;\n    margin-bottom: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__tLgZf",
	"title": "style_title__22iAb",
	"description": "style_description__3qAj9"
};
export default ___CSS_LOADER_EXPORT___;
