import React, { FC, useEffect } from "react";
import { CardsGroup } from "components/UI/CardsGroup";
import { useQuery } from "hooks/useQuery";
import { lessonApi } from "api/lessonApi/methods";
import { inviteApi } from "api/inviteApi/methods";
import { groupLessonApi } from "api/groupLessonApi/methods";
import { useTranslation } from "react-i18next";
import styles from "./index.module.css";
import { ProfileCardsWrapper } from "components/Lk/Pages/LkProfile/components/ProfileCardsWrapper";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";
import moment from "moment-timezone";

type propsType = {};
export const LkStudentOwn: FC<propsType> = (props) => {
   const { t } = useTranslation();

   /* fetch */
   const { isLoading: lessonLoading, data: lessonData } = useQuery(
      () =>
         lessonApi.getLessons({
            limit: "100",
            offset: "0",
            role: "student",
            lesson_statuses: ["new_lesson"],
            date_from: moment().utcOffset("+0000").format("YYYY-MM-DD"),
         }),
      {
         serializer: (res) => {
            return res?.data?.data?.items?.map((item: any) => ({
               ...item,
               type: "lesson",
            }));
         },
      }
   );
   const user = useSelector(lkSliceSelectors.getUserData);

   const { isLoading: invitesLoading, data: invitesData } = useQuery(
      () => {
         return inviteApi.getInvite({
            limit: "100",
            offset: "0",
            role: "student",
            invite_statuses: ["new_invite"],
         });
      },
      {
         serializer: (res) => {
            return res?.data?.data?.items.map((item: any) => ({
               ...item,
               type: "invite",
            }));
         },
      }
   );

   const { isLoading: groupLessonLoading, data: groupLessonsData } = useQuery(
      () =>
         groupLessonApi.getGroupLessons({
            role: "student",
            offset: "0",
            limit: "100",
            date_from: moment().utcOffset("+0000").format("YYYY-MM-DD"),

            lesson_statuses: ["new_group_lesson"],
            user_id: user.id,
         }),
      {
         serializer: (res) => {
            return res?.data?.data?.items?.map((item: any) => ({
               ...item,
               type: "group_lesson",
            }));
         },
      }
   );
   return (
      <div className={styles.container}>
         <ProfileCardsWrapper
            title={t("Lk.LkStudentOwn.directions-picker-label", "Занятия")}
         >
            <CardsGroup
               elements={lessonData}
               title={t(
                  "Lk.LkStudentOwn.tickets.confirmed-lessons",
                  "Подтвержденные занятия"
               )}
               isLoading={lessonLoading}
               role='student'
            />
            <CardsGroup
               elements={invitesData}
               title={t(
                  "Lk.LkStudentOwn.tickets.pending-applications",
                  "Заявки в ожидании"
               )}
               isLoading={invitesLoading}
               role='student'
            />
            <CardsGroup
               elements={groupLessonsData}
               title={t(
                  "Lk.LkStudentOwn.tickets.open-lessons",
                  "Открытые уроки"
               )}
               isLoading={groupLessonLoading}
               role='student'
            />
         </ProfileCardsWrapper>
      </div>
   );
};
