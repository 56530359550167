import React, { FC } from "react";
import styles from "./index.module.css";
import { ReactComponent as DeleteIcon } from "assets/iconsCorrect/modal/Delete.svg";
import { Button } from "components/UI/Button";
import { useSelector } from "react-redux";
import { authSliceSelectors } from "store/authSlice";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { lessonThunk } from "store/lessonSlice/thunk";
import { ModalWindow } from "components/ModalWindow/index";
import { ModalWindowBody } from "components/ModalWindow/templates/components/ModalWindowBody";
import { useTranslation } from "react-i18next";

type propsType = {
   slot_id: string;
   closeModal: () => void;
   callback: () => void;
};
export const DeleteSlotTemplate: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const userId = useSelector(authSliceSelectors.getId);
   const commonState = useLocalCommonState();
   const deleteSlot = () => {
      commonState.dispatch(
         lessonThunk.deleteSlot({
            commonState,
            slot_id: String(props.slot_id),
            user_id: userId,
            callbacks: {
               204: () => {
                  props.callback();
                  props.closeModal();
               },
            },
         })
      );
   };

   return (
      <>
         <ModalWindowBody
            title={t("ModalWindow.DeleteSlot.title", "Подтвердите удаление")}
            subtitle={t(
               "ModalWindow.DeleteSlot.subtitle",
               "При удалении времени для занятия будут удалены и запросы студентов"
            )}
         />
         <div className={styles.buttons}>
            <Button
               variant={"red"}
               className={styles.button}
               onClick={deleteSlot}
               loading={commonState.loading}
            >
               {t("Buttons.delete", "Удалить")}
            </Button>
            <Button
               variant={"decision"}
               className={styles.button}
               onClick={props.closeModal}
               loading={commonState.loading}
            >
               {t("Buttons.cancel", "Отменить")}
            </Button>
         </div>
      </>
   );
};
