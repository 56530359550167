// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__1JtTg {\n  display: flex;\n  align-items: center;\n  font-family: var(--font-Source-Sans-Pro);\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 20px;\n  margin-bottom: 10px;\n}\n\n.style_avatar__3wgYj {\n  margin-left: 8px;\n  margin-right: 10px;\n\n}\n\n.style_avatar_photo__2rygj {\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  object-fit: cover;\n}", "",{"version":3,"sources":["webpack://src/components/Lk/components/Notifications/components/User/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,wCAAwC;EACxC,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;;AAEpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  font-family: var(--font-Source-Sans-Pro);\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 20px;\n  margin-bottom: 10px;\n}\n\n.avatar {\n  margin-left: 8px;\n  margin-right: 10px;\n\n}\n\n.avatar_photo {\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__1JtTg",
	"avatar": "style_avatar__3wgYj",
	"avatar_photo": "style_avatar_photo__2rygj"
};
export default ___CSS_LOADER_EXPORT___;
