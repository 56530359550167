// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_date__x1NyK {\n  margin-top: 30px;\n  margin-bottom: 20px;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 28px;\n  line-height: 38px;\n}", "",{"version":3,"sources":["webpack://src/components/Lk/components/Notifications/components/DateGroupTitle/style.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".date {\n  margin-top: 30px;\n  margin-bottom: 20px;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 28px;\n  line-height: 38px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date": "style_date__x1NyK"
};
export default ___CSS_LOADER_EXPORT___;
