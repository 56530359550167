import { createAsyncThunk } from "@reduxjs/toolkit";
import { thunkType } from "core/LTools/core/types/types";
import {
   createApplicationType,
   deleteApplicationType,
   getApplicationType,
} from "api/applicationApi/types";
import { LTools } from "core/LTools/constructor";
import { applicationApi } from "api/applicationApi/methods";
import { fastLessonSlice } from "./index";
import { routes } from "routes";
import { errorCaseHandler } from "store/lessonSlice/thunk";
import { getFirstErrorFromResponse } from "helpers/errors";

export const fastLessonThunks = {
   getApplication: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & getApplicationType>) {
      return createAsyncThunk("getApplication", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => applicationApi.getApplication(props),
            responseReactionCreator: (response) => ({
               "200": () => {
                  commonState.dispatch(
                     fastLessonSlice.actions.setCurrentApplication(
                        response.data.data
                     )
                  );
                  if (response.data.data.status == "new") {
                     setTimeout(
                        () =>
                           commonState.dispatch(
                              fastLessonThunks.getApplication({
                                 commonState,
                                 ...props,
                              })
                           ),
                        8000
                     );
                  }
               },
            }),
         });

         return response as any;
      })();
   },

   createApplication: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & createApplicationType>) {
      return createAsyncThunk("createApplication", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => applicationApi.createApplication(props),
            responseReactionCreator: (response) => ({
               201: () => {},
               400: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response)
                  );
               },
            }),
         });

         return response as any;
      })();
   },

   deleteApplication: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & deleteApplicationType>) {
      return createAsyncThunk("rejectSearching", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => applicationApi.deleteApplication(props),
            responseReactionCreator: (response) => ({
               200: () => {},
            }),
         });

         return response as any;
      })();
   },
};
