import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./index.module.css";
import {
   modalWindowTemplateType,
   uiSlice,
   uiSliceModalSelectors,
} from "store/modalWindowSlice";
import { DeleteSlotTemplate } from "components/ModalWindow/templates/DeleteSlotTemplate";
import { Icons } from "assets/iconsCorrect";
import { ModalWindowBody } from "components/ModalWindow/templates/components/ModalWindowBody";
import { DeleteEventTemplate } from "components/ModalWindow/templates/DeleteEventTemplate";
import { CreateAdminChat } from "components/ModalWindow/templates/CreateAdminChat";
import { ComplaintPopup } from "components/ModalWindow/templates/ComplaintPopup";
import { CreateSlotWarning } from "components/ModalWindow/templates/CreateSlotWarning";

export function ModalWindow() {
   const { payload, template } = useSelector(uiSliceModalSelectors.getModal);

   const dispatch = useDispatch();

   const closeModal = () => {
      dispatch(uiSlice.actions.removeAll());
   };

   const modalResolver = () => {
      switch (template) {
         case "delete-slot":
            return (
               <DeleteSlotTemplate
                  slot_id={payload.slot_id}
                  closeModal={closeModal}
                  callback={payload.callback}
               />
            );
         case "delete_event":
            return (
               <DeleteEventTemplate
                  slot_id={payload.slot_id}
                  event_id={payload.event_id}
                  deleteEventLocal={payload.deleteEventLocal}
                  deleteSlotLocal={payload.deleteSlotLocal}
                  closeModal={closeModal}
               />
            );
         case "create_admin_chat":
            return <CreateAdminChat closeModal={closeModal} />;
         case "create_lesson_report":
            return (
               <ComplaintPopup type={payload.type} closeModal={closeModal} />
            );
         case "create_slot_warning":
            return <CreateSlotWarning close={closeModal} />;
         case "default":
            return (
               <ModalWindowBody
                  title={payload.title}
                  subtitle={payload.subtitle}
                  button={payload.button}
                  close={closeModal}
               />
            );
      }
   };
   return template ? (
      <div className={styles.container}>
         <div className={styles.background} onClick={closeModal} />
         <div className={styles.modal_container}>
            <div className={styles.icon}>
               {template == "default"
                  ? ResolveIconByStatus(payload.status)
                  : ResolveIconByTemplate(template)}
            </div>
            {modalResolver()}
         </div>
      </div>
   ) : (
      <></>
   );
}

const ResolveIconByTemplate = (template: any) => {
   switch (template) {
      case "default":
         return <Icons.status.SuccessStatusIcon />;
      case "delete-slot":
      case "delete_event":
         return <Icons.status.ErrorStatusIcon />;
      case "create_slot_warning":
         return <Icons.ui.TeacherHat />;
      case "create_admin_chat":
      case "create_lesson_report":
         return undefined;
      default:
         return <Icons.status.SuccessStatusIcon />;
   }
};
const ResolveIconByStatus = (status: "success" | "error") => {
   switch (status) {
      case "success":
         return <Icons.status.SuccessStatusIcon />;
      default:
         return <Icons.status.ErrorStatusIcon />;
   }
};
