import { createAsyncThunk } from "@reduxjs/toolkit";
import { authSlice } from "./index";
import {
   appleAuthType,
   changePasswordType,
   checkCodeType,
   createCodeType,
   createPasswordType,
   createUserType,
   getTokenType,
   getUserType,
   googleAuthType,
} from "api/AuthApi/types";
import { thunkType } from "core/LTools/core/types/types";
import { LTools } from "core/LTools/constructor";
import { authApi } from "api/AuthApi/methods";
import { lkSlice } from "../lkSlice";
import { lkSLiceThunk } from "../lkSlice/thunk";
import { routes } from "../../routes";
import { getFirstErrorFromResponse } from "helpers/errors";

export const authThunks = {
   getUser: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & getUserType>) {
      return createAsyncThunk("getMerchant", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            requestMethod: "get",
            request: () => authApi.getUser(props),
            responseReactionCreator: (response) => ({
               200: () => {
                  if (!props.disableSetData) {
                     const user = response.data.data[0];
                     commonState.dispatch(
                        authSlice.actions.setEmail(props.email)
                     );

                     if (user) {
                        commonState.dispatch(
                           authSlice.actions.setId(String(user.id))
                        );
                     }
                  }
               },
            }),
         });

         return response as any;
      })();
   },

   googleAuth: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & googleAuthType>) {
      return createAsyncThunk("googleAuth", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => authApi.googleAuth(props),
            responseReactionCreator: (response) => ({
               "200": () => {
                  commonState.dispatch(
                     authSlice.actions.setToken(response.data.data.token)
                  );
                  commonState.dispatch(
                     authSlice.actions.setId(response.data.data.user_id)
                  );
                  commonState.dispatch(
                     lkSLiceThunk.getUser({
                        commonState,
                        needRedirect: true,
                     })
                  );
               },
            }),
         });

         return response as any;
      })();
   },

   appleAuth: function <T>({
                               commonState,
                               callbacks,
                               ...props
                            }: thunkType<T, appleAuthType>) {
      return createAsyncThunk("appleAuth", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => authApi.appleAuth(props),
            responseReactionCreator: (response) => ({
               "200": () => {
                  commonState.dispatch(
                      authSlice.actions.setToken(response.data.data.token)
                  );
                  commonState.dispatch(
                      authSlice.actions.setId(response.data.data.user_id)
                  );
                  commonState.dispatch(
                      lkSLiceThunk.getUser({
                         commonState,
                         needRedirect: true,
                      })
                  );
               },
            }),
         });

         return response as any;
      })();
   },

   createUser: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & createUserType>) {
      return createAsyncThunk("createUser", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => authApi.createUser(props),
            responseReactionCreator: (response) => ({
               "201": () => {
                  commonState.dispatch(
                     authSlice.actions.setId(response.data.id)
                  );
               },
            }),
         });

         return response as any;
      })();
   },

   getToken: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & getTokenType>) {
      return createAsyncThunk("getMerchant", async (arg, thunkAPI) => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => authApi.getToken(props),
            responseReactionCreator: (response) => ({
               "200": () => {
                  commonState.dispatch(
                     authSlice.actions.setToken(response.data.token)
                  );
               },
               "401": () => {
                  commonState.errors = {
                     password: getFirstErrorFromResponse(response),
                  };
               },
               "403": () => {
                  commonState.errors = {
                     password: getFirstErrorFromResponse(response),
                  };
               },
            }),
         });

         return response as any;
      })();
   },

   createCode: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & createCodeType>) {
      return createAsyncThunk("createRestoreCode", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => authApi.createCode(props),
            responseReactionCreator: (response) => ({
               "201": () => {},
            }),
         });

         return response as any;
      })();
   },

   checkCode: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & checkCodeType>) {
      return createAsyncThunk("confirmRestoreCode", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => authApi.checkCode(props),
            responseReactionCreator: (response) => ({
               "204": () => {
                  commonState.dispatch(authSlice.actions.setCode(props.code));
               },
               "404": () => {
                  commonState.errors = {
                     code: "Неверный код",
                  };
               },
            }),
         });

         return response as any;
      })();
   },

   createPassword: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & createPasswordType>) {
      return createAsyncThunk("restorePassword", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => authApi.createPassword(props),
            responseReactionCreator: (response) => ({
               "200": () => {
                  commonState.dispatch(
                     authSlice.actions.setToken(response.data.token)
                  );
               },
               "403": () => {
                  commonState.errors = {
                     password: getFirstErrorFromResponse(response),
                  };
               },
            }),
         });

         return response as any;
      })();
   },

   changePassword: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & changePasswordType>) {
      return createAsyncThunk("confirmRestoreCode", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => authApi.changePassword(props),
            responseReactionCreator: (response) => ({
               "200": () => {},
               "403": () => {
                  commonState.errors = {
                     ...commonState.errors,
                     pass_error: response.data.data.errors[0].message,
                  };
               },
            }),
         });

         return response as any;
      })();
   },
};
