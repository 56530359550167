import React, { FC } from "react";
import { useLkContext } from "../../index";
import { LkTeacherOwn } from "./LkTeacherOwn";
import { LkStudentOwn } from "components/Lk/Pages/LkProfile/LkStudent/components/LkStudentOwn";

export const LkTeacherYourPage: FC = () => {
   const { template } = useLkContext();

   return template.tempRole == "teacher" ? <LkTeacherOwn /> : <LkStudentOwn />;
};
