import React, { FC, useEffect, useState } from "react";
import styles from "./style.module.css";
import { useSelector } from "react-redux";
import { lkSlice, lkSliceSelectors } from "store/lkSlice";
import { useHistory } from "react-router-dom";
import { routes } from "routes";
import { ReactComponent as ButtonIcon } from "./assets/button.svg";
import { useQuery } from "hooks/useQuery";
import { formsDataApi } from "api/formsDataApi/methods";
import { languageSelectSerializer } from "serializers/languages";
import { useForm } from "react-hook-form";
import { Button } from "components/UI/Button";
import { CheckBox } from "components/UI/Input/CheckBox";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { useTranslation } from "react-i18next";
import { lkSLiceThunk } from "store/lkSlice/thunk";
import { authSliceSelectors } from "store/authSlice";
import { modalWindowTemplateType, uiSlice } from "store/modalWindowSlice";
import { SelectUnderControl } from "../../../../UI/Select/SelectUnderControl";
import { arrayOfTimezones } from "../../../../../staticData/timezone";
import { userApi } from "../../../../../api/userApi/methods";
import moment from "moment-timezone";
import { lc } from "store/LC";

type propsType = {};
type formType = {
   timezone: any;
   timezone_input: any;
   fast: any;
};
export const ServiceSettings: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const history = useHistory();
   const { control, handleSubmit, watch, setValue } = useForm<formType>();
   const [timezoneOptions] = useState(
      arrayOfTimezones.map((item, index) => ({
         value: item,
         id: index,
         text: item.offset,
      }))
   );
   const { data: languages, isLoading: languagesLoading } = useQuery(
      formsDataApi.getLanguage,
      {
         cachedValueName: "languages",
         serializer: languageSelectSerializer,
      }
   );
   const user = useSelector(lkSliceSelectors.getUserData);
   const userData = useSelector(lkSliceSelectors.getUserData);
   const commonState = useLocalCommonState();

   const successCaseHandler = () => {
      commonState.dispatch(
         uiSlice.actions.setModalType({
            template: "default" as modalWindowTemplateType["default"],
            payload: {
               title: t("Common.success", "Успешно"),
               button: "close",
               status: "success",
            },
         })
      );
   };

   const submit = (data: formType) => {
      commonState.dispatch(
         lkSLiceThunk.updateUser({
            commonState,
            user_id: user.id,
            payload: {
               last_name: userData.last_name,
               first_name: userData.first_name,
               birth_date: userData.birth_date,
               is_ready_get_quick_invite: !!data.fast,
               //@ts-ignore
               // instruction_subjects: user?.instruction_subjects
               //    ? user?.instruction_subjects.map((item) => item.id)
               //    : undefined,
               //@ts-ignore
               // instruction_languages: user?.instruction_languages
               //    ? user?.instruction_languages.map((item) => item.id)
               //    : undefined,
            },
            callbacks: {
               200: successCaseHandler,
            },
         })
      );
   };

   const changeTimezone = async (timezone: any) => {
      console.log(moment.tz.names());
      const timezoneCode = timezone.value.value;
      userApi
         .changeTimezone({
            user_id: user.id,
            timezone: timezoneCode,
         })
         .then(() => {
            commonState.dispatch(
               lkSlice.actions.setData({
                  ...user,
                  timezone: timezoneCode,
               })
            );

            moment.tz.setDefault(timezone.value.name);
            lc.set("timeZone", timezone.value.name);
            lc.set("timeZoneOffset", timezone.value.value);
         });
   };

   useEffect(() => {
      const userTimezoneObject = timezoneOptions.find(
         (item) => item.value.value == user.timezone
      );
      if (userTimezoneObject) {
         setValue("timezone", userTimezoneObject);
         setValue("timezone_input", userTimezoneObject.text);
      }
   }, []);

   return (
      <div className={styles.container}>
         <form className={styles.form} onSubmit={handleSubmit(submit)}>
            <div className={styles.status_title}>
               {t("Settings.service.status", "Ваш статус")}
            </div>
            <div className={styles.status}>
               {userData.type == "teacher"
                  ? t(
                       "Lk.LkProfile.RoleSwitcher.teacher-button",
                       "Преподаватель"
                    )
                  : t("Lk.LkProfile.RoleSwitcher.student-button", "Студент")}
            </div>
            {userData.type != "teacher" && (
               <button
                  className={styles.teach_button}
                  onClick={() =>
                     history.push(routes.registration.teacher.firstStage)
                  }
               >
                  <ButtonIcon className={styles.button_icon} />
                  {t("Lk.Profile.get-role", "Хотите преподавать?")}
               </button>
            )}
            {userData.type == "teacher" && (
               <div className={styles.fast}>
                  <div className={styles.fast_title}>
                     {t(
                        "Registration.TeacherPages.AddFiles.title",
                        "Срочные уроки"
                     )}
                  </div>
                  <CheckBox
                     control={control}
                     defaultChecked={userData?.is_ready_get_quick_invite}
                     name={"fast"}
                     label={t(
                        "Registration.TeacherPages.AddFiles.checkbox-label",
                        "Принимать запросы на быстрые занятия"
                     )}
                  />
               </div>
            )}
            <SelectUnderControl
               control={control}
               setValue={setValue}
               onSelectValue={changeTimezone}
               name={"timezone"}
               label={t("Settings.service.timezone", "Часовой пояс")}
               options={timezoneOptions}
            />
            <div className={styles.button}>
               <Button loading={commonState.loading}>
                  {t("Buttons.save", "Сохранить")}
               </Button>
            </div>
         </form>
      </div>
   );
};
