import { routes } from "routes";
import { addMethodToUpdateLang } from "services/translateService/useLangInit";
import i18n from "services/translateService/i18n";

export let navigationData: any;

addMethodToUpdateLang(() => {
   navigationData = {
      [`${routes.registration.auth.firstStage}`]: {
         title: i18n.t("Registration.titles.first-stage", "Аккаунт"),
      },
      [`${routes.registration.auth.registrationCode}`]: {
         title: "",
      },
      [`${routes.registration.auth.createPassword}`]: {
         title: i18n.t("Registration.titles.createPassword", "Регистрация"),
      },
      [`${routes.registration.student.fillInformation}`]: {
         title: i18n.t("Registration.titles.createPassword", "Регистрация"),
      },
      [`${routes.registration.student.studentRegistration}`]: {
         title: i18n.t(
            "Registration.titles.studentRegistration",
            "Заполните анкету"
         ),
      },
      [`${routes.registration.auth.authorization}`]: {
         title: i18n.t("Registration.titles.authorization", "Авторизация"),
      },
      [`${routes.registration.auth.forgotPassword}`]: {
         title: "",
      },
      [`${routes.registration.auth.restorePassword}`]: {
         title: i18n.t(
            "Registration.titles.restorePassword",
            "Придумайте пароль"
         ),
      },
      [`${routes.registration.teacher.root}`]: {
         title: "",
      },
   };
});
