import React, { FC } from "react";
import { PassInput } from "../../../UI/Input/PassInput";
import { useForm, useWatch } from "react-hook-form";
import { Button } from "../../../UI/Button";
import { passwordSchema } from "helpers/validationSchemes";
import { yupResolver } from "@hookform/resolvers/yup";
import { routes } from "routes";
import { useTranslation } from "react-i18next";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { authSliceSelectors } from "store/authSlice";
import { authThunks } from "store/authSlice/thunks";
import { useSelector } from "react-redux";
import styles from "./index.module.css";
import { Form } from "core/LTools/core/functional components/Form";
import cn from "classnames";
import { Preloader } from "components/UI/Preloader";
import { lkSLiceThunk } from "store/lkSlice/thunk";

type propsType = {};
type restorePasswordFormType = {
   password: string;
   password_confirm: string;
};
export const RestorePassword: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const {
      control,
      handleSubmit,
      formState: { errors },
      clearErrors,
   } = useForm<restorePasswordFormType>({
      // @ts-ignore
      resolver: yupResolver(passwordSchema),
      reValidateMode: "onSubmit",
   });
   const form = useWatch({ control });
   const code = useSelector(authSliceSelectors.getCode);
   const user_id = useSelector(authSliceSelectors.getId);
   const commonState = useLocalCommonState();
   const submit = ({ password, password_confirm }: restorePasswordFormType) => {
      commonState.dispatch(
         authThunks.createPassword({
            commonState,
            password,
            password_confirm,
            code,
            callbacks: {
               200: () => {
                  commonState.dispatch(
                     lkSLiceThunk.getUser({
                        commonState,
                        needRedirect: true,
                     })
                  );
               },
            },
         })
      );
   };

   return (
      <Form
         commonState={commonState}
         clearErrors={clearErrors}
         errors={errors}
         onSubmit={handleSubmit(submit)}
         className='flex-col'
      >
         <PassInput
            className={styles.input}
            control={control}
            name='password'
            label={t(
               "Registration.Auth.RestorePassword.password-placeholder",
               "Введите пароль"
            )}
            error={errors.password?.message || commonState.errors.password}
         />
         <PassInput
            className={styles.input}
            control={control}
            name='password_confirm'
            label={t(
               "Registration.Auth.RestorePassword.password-repeat-label",
               "Повторите пароль"
            )}
            error={
               errors.password_confirm?.message ||
               commonState.errors.password_confirm
            }
         />
         <Button
            className={cn(styles.action_button, {
               "x-centred": true,
            })}
            type='submit'
            variant={"primary"}
            disabled={
               !(form.password && form.password == form.password_confirm)
            }
            loading={commonState.loading}
         >
            {t(
               "Registration.Auth.RestorePassword.enter-button",
               "Сохранить и войти"
            )}
         </Button>
      </Form>
   );
};
