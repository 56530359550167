import { useTranslation } from "react-i18next";
import { isoCountryType } from "../types/uiData/types";

export const useGetCountries = () => {
   const { t } = useTranslation();

   const isoCountriesSelectOptions: Array<{ id: number, text: string, value: isoCountryType }> = [
      {
         id: 1,
         text: `AD - ${t("Countries.Andorra", "Андорра")}`,
         value: "AD",
      },
      {
         id: 2,
         text: `AE - ${t("Countries.United Arab Emirates", "Объединённые Арабские Эмираты")}`,
         value: "AE",
      },
      {
         id: 3,
         text: `AL - ${t("Countries.Albania", "Албания")}`,
         value: "AL",
      },
      {
         id: 4,
         text: `AO - ${t("Countries.Angola", "Ангола")}`,
         value: "AO",
      },
      {
         id: 5,
         text: `AT - ${t("Countries.Austria", "Австрия")}`,
         value: "AT",
      },
      {
         id: 6,
         text: `BA - ${t("Countries.Bosnia and Herzegovina", "Босния и Герцеговина")}`,
         value: "BA",
      },
      {
         id: 7,
         text: `BE - ${t("Countries.Belgium", "Бельгия")}`,
         value: "BE",
      },
      {
         id: 8,
         text: `BG - ${t("Countries.Bulgaria", "Болгария")}`,
         value: "BG",
      },
      {
         id: 9,
         text: `BH - ${t("Countries.Bahrain", "Бахрейн")}`,
         value: "BH",
      },
      {
         id: 10,
         text: `CH - ${t("Countries.Switzerland", "Швейцария")}`,
         value: "CH",
      },
      {
         id: 11,
         text: `CR - ${t("Countries.Costa Rica", "Коста-Рика")}`,
         value: "CR",
      },
      {
         id: 12,
         text: `CY - ${t("Countries.Cyprus", "Кипр")}`,
         value: "CY",
      },
      {
         id: 13,
         text: `CZ - ${t("Countries.Czech Republic", "Чешская Республика")}`,
         value: "CZ",
      },
      {
         id: 14,
         text: `DE - ${t("Countries.Germany", "Германия")}`,
         value: "DE",
      },
      {
         id: 15,
         text: `DK - ${t("Countries.Denmark", "Дания")}`,
         value: "DK",
      },
      {
         id: 16,
         text: `DO - ${t("Countries.Dominican Republic", "Доминиканская Республика")}`,
         value: "DO",
      },
      {
         id: 17,
         text: `DZ - ${t("Countries.Algeria", "Алжир")}`,
         value: "DZ",
      },
      {
         id: 18,
         text: `EE - ${t("Countries.Estonia", "Эстония")}`,
         value: "EE",
      },
      {
         id: 19,
         text: `ES - ${t("Countries.Spain", "Испания")}`,
         value: "ES",
      },
      {
         id: 20,
         text: `FI - ${t("Countries.Finland", "Финляндия")}`,
         value: "FI",
      },
      {
         id: 21,
         text: `FO - ${t("Countries.Faroe Islands", "Фарерские острова")}`,
         value: "FO",
      },
      {
         id: 22,
         text: `FR - ${t("Countries.France", "Франция")}`,
         value: "FR",
      },
      {
         id: 23,
         text: `GB - ${t("Countries.United Kingdom", "Великобритания")}`,
         value: "GB",
      },
      {
         id: 24,
         text: `GE - ${t("Countries.Georgia", "Грузия")}`,
         value: "GE",
      },
      {
         id: 25,
         text: `GI - ${t("Countries.Gibraltar", "Гибралтар")}`,
         value: "GI",
      },
      {
         id: 26,
         text: `GL - ${t("Countries.Greenland", "Гренландия")}`,
         value: "GL",
      },
      {
         id: 27,
         text: `GR - ${t("Countries.Greece", "Греция")}`,
         value: "GR",
      },
      {
         id: 28,
         text: `HR - ${t("Countries.Croatia", "Хорватия")}`,
         value: "HR",
      },
      {
         id: 29,
         text: `HU - ${t("Countries.Hungary", "Венгрия")}`,
         value: "HU",
      },
      {
         id: 30,
         text: `IE - ${t("Countries.Ireland", "Ирландия")}`,
         value: "IE",
      },
      {
         id: 31,
         text: `IL - ${t("Countries.Israel", "Израиль")}`,
         value: "IL",
      },
      {
         id: 32,
         text: `IS - ${t("Countries.Iceland", "Исландия")}`,
         value: "IS",
      },
      {
         id: 33,
         text: `IT - ${t("Countries.Italy", "Италия")}`,
         value: "IT",
      },
      {
         id: 34,
         text: `JO - ${t("Countries.Jordan", "Иордания")}`,
         value: "JO",
      },
      {
         id: 35,
         text: `KW - ${t("Countries.Kuwait", "Кувейт")}`,
         value: "KW",
      },
      {
         id: 36,
         text: `KZ - ${t("Countries.Kazakhstan", "Казахстан")}`,
         value: "KZ",
      },
      {
         id: 37,
         text: `LB - ${t("Countries.Lebanon", "Ливан")}`,
         value: "LB",
      },
      {
         id: 38,
         text: `LI - ${t("Countries.Liechtenstein", "Лихтенштейн")}`,
         value: "LI",
      },
      {
         id: 39,
         text: `LT - ${t("Countries.Lithuania", "Литва")}`,
         value: "LT",
      },
      {
         id: 40,
         text: `LU - ${t("Countries.Luxembourg", "Люксембург")}`,
         value: "LU",
      },
      {
         id: 41,
         text: `LV - ${t("Countries.Latvia", "Латвия")}`,
         value: "LV",
      },
      {
         id: 42,
         text: `MC - ${t("Countries.Monaco", "Монако")}`,
         value: "MC",
      },
      {
         id: 43,
         text: `MD - ${t("Countries.Moldova", "Молдавия")}`,
         value: "MD",
      },
      {
         id: 44,
         text: `ME - ${t("Countries.Montenegro", "Черногория")}`,
         value: "ME",
      },
      {
         id: 45,
         text: `MG - ${t("Countries.Madagascar", "Мадагаскар")}`,
         value: "MG",
      },
      {
         id: 46,
         text: `MK - ${t("Countries.North Macedonia", "Северная Македония")}`,
         value: "MK",
      },
      {
         id: 47,
         text: `MR - ${t("Countries.Mauritania", "Мавритания")}`,
         value: "MR",
      },
      {
         id: 48,
         text: `MT - ${t("Countries.Malta", "Мальта")}`,
         value: "MT",
      },
      {
         id: 49,
         text: `MU - ${t("Countries.Mauritius", "Маврикий")}`,
         value: "MU",
      },
      {
         id: 50,
         text: `NL - ${t("Countries.Netherlands", "Нидерланды")}`,
         value: "NL",
      },
      {
         id: 51,
         text: `NO - ${t("Countries.Norway", "Норвегия")}`,
         value: "NO",
      },
      {
         id: 52,
         text: `PK - ${t("Countries.Pakistan", "Пакистан")}`,
         value: "PK",
      },
      {
         id: 53,
         text: `PL - ${t("Countries.Poland", "Польша")}`,
         value: "PL",
      },
      {
         id: 54,
         text: `PS - ${t("Countries.Palestine", "Палестина")}`,
         value: "PS",
      },
      {
         id: 55,
         text: `PT - ${t("Countries.Portugal", "Португалия")}`,
         value: "PT",
      },
      {
         id: 56,
         text: `QA - ${t("Countries.Qatar", "Катар")}`,
         value: "QA",
      },
      {
         id: 57,
         text: `RO - ${t("Countries.Romania", "Румыния")}`,
         value: "RO",
      },
      {
         id: 58,
         text: `RS - ${t("Countries.Serbia", "Сербия")}`,
         value: "RS",
      },
      {
         id: 59,
         text: `SA - ${t("Countries.Saudi Arabia", "Саудовская Аравия")}`,
         value: "SA",
      },
      {
         id: 60,
         text: `SE - ${t("Countries.Sweden", "Швеция")}`,
         value: "SE",
      },
      {
         id: 61,
         text: `SI - ${t("Countries.Slovenia", "Словения")}`,
         value: "SI",
      },
      {
         id: 62,
         text: `SK - ${t("Countries.Slovakia", "Словакия")}`,
         value: "SK",
      },
      {
         id: 63,
         text: `SM - ${t("Countries.San Marino", "Сан-Марино")}`,
         value: "SM",
      },
      {
         id: 64,
         text: `TN - ${t("Countries.San Tunisia", "Тунис")}`,
         value: "TN",
      },
      {
         id: 65,
         text: `TR - ${t("Countries.Turkey", "Турция")}`,
         value: "TR",
      },
      {
         id: 66,
         text: `VA - ${t("Countries.Holy See (the)", "Ватикан")}`,
         value: "VA",
      },
      {
         id: 67,
         text: `VG - ${t("Countries.Virgin Islands, British", "Виргинские острова (Великобритания)")}`,
         value: "VG",
      },
   ];

   return {
      isoCountriesSelectOptions,
   };
};
