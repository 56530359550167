import React, { FC, useEffect, useState } from "react";
import styles from "./style.module.css";
import { notificationPropsType } from "../../types";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";

type propsType = any;
export const DateGroupTitle: FC<propsType> = ({ keyByDate }) => {
   const { t } = useTranslation();
   const date = moment(keyByDate).toDate();
   const weekday = date.getDay();
   const month = date.getMonth();

   const weekdayResolver = (): string => {
      switch (weekday) {
         case 0:
            return t("Weekdays.Sunday", "воскресенье");
         case 1:
            return t("Weekdays.Monday", "понедельник");
         case 2:
            return t("Weekdays.Tuesday", "вторник");
         case 3:
            return t("Weekdays.Wednesday", "среда");
         case 4:
            return t("Weekdays.Thursday", "четверг");
         case 5:
            return t("Weekdays.Friday", "пятница");
         case 6:
            return t("Weekdays.Saturday", "суббота");
         default:
            return "";
      }
   };

   const monthResolver = (): string => {
      switch (month) {
         case 0:
            return t("Months.January", "января");
         case 1:
            return t("Months.February", "февраля");
         case 2:
            return t("Months.March", "марта");
         case 3:
            return t("Months.April", "апреля");
         case 4:
            return t("Months.May", "мая");
         case 5:
            return t("Months.June", "июня");
         case 6:
            return t("Months.July", "июля");
         case 7:
            return t("Months.August", "августа");
         case 8:
            return t("Months.September", "сентября");
         case 9:
            return t("Months.October", "октября");
         case 10:
            return t("Months.November", "ноября");
         case 11:
            return t("Months.December", "декабря");
         default:
            return "";
      }
   };

   return (
      <div className={styles.date}>
         {date.getDate()} {monthResolver()}, {weekdayResolver()}
      </div>
   );
};
