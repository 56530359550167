/* LocalStorage */
import { localStorageKeysType } from "types/types";
export const lc = {
   get: (key: localStorageKeysType) => {
      return localStorage.getItem(key);
   },
   set: (key: localStorageKeysType, val: string) => {
      localStorage.setItem(key, val);
   },
   clear: () => {
      localStorage.removeItem("user_id" as localStorageKeysType);
      localStorage.removeItem("token" as localStorageKeysType);
      localStorage.removeItem("timeZone" as localStorageKeysType);
      localStorage.removeItem("email" as localStorageKeysType);
      localStorage.removeItem("timeZoneOffset" as localStorageKeysType);
   },
};
