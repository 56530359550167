import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { PageTitle } from "components/UI/PageTitle";
import { LessonTitle } from "components/Lk/Pages/LessonsService/components/LessonTitle";
import { SelectUnderControl } from "components/UI/Select/SelectUnderControl";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import {
   adminEditorSlice,
   adminEditorSliceSelectors,
} from "store/adminEditorSlice";
import { useQuery } from "hooks/useQuery";
import { formsDataApi } from "api/formsDataApi/methods";
import { Form } from "core/LTools/core/functional components/Form";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { Input } from "components/UI/Input";
import { Button } from "components/UI/Button";
import { adminApi } from "api/adminApi";
import { adminThunks } from "store/adminSlice/thunks";
import { adminEditorThunk } from "store/adminEditorSlice/thunk";
import { selectItemType } from "components/UI/SelectNew";
import { useHistory, useParams } from "react-router-dom";
import { routes } from "routes";
import { createModalWindow, uiSlice } from "store/modalWindowSlice";
import { useTranslation } from "react-i18next";

const fieldsNames = {
   es: "es",
   category_name: "category_name",
};

type formType = {
   es: selectItemType<any>;
   es_input: any;
   category_name: string;
};

type propsType = {};
export const AddCategory: FC<propsType> = (props) => {
   const {
      setValue,
      control,
      handleSubmit,
      clearErrors,
      setError,
      formState: { errors },
   } = useForm();
   const { t } = useTranslation();

   const { id } = useParams<{ id: string }>();
   const system = useSelector(adminEditorSliceSelectors.getSystem);
   const level = useSelector(adminEditorSliceSelectors.getLevel);
   const category = useSelector(adminEditorSliceSelectors.getCategory);
   const commonState = useLocalCommonState();
   const isEdit = id != undefined;
   const history = useHistory();

   useEffect(() => {
      if (!level) {
         history.push(routes.lk.admin.educationSystems.root);
      }
   }, []);
   const esFromApi = useQuery(
      () =>
         formsDataApi.getEducationSystem({
            level_id: String(level?.id),
         }),
      {
         condition: !!level?.id,
         serializer: (res) => {
            return res?.data?.data?.items.map((item: any) => ({
               id: item.id,
               text: item.name,
               value: item,
            }));
         },
      }
   );

   const createCategory = (data: formType) => {
      commonState.dispatch(
         adminEditorThunk.createCategory({
            education_system_id: String(data.es.id),
            category_name: data.category_name,
            commonState,
         })
      );
   };
   const updateCategory = (data: formType) => {
      commonState.dispatch(
         adminEditorThunk.updateCategory({
            education_system_id: String(data.es.id),
            category_name: data.category_name,
            category_id: id,
            commonState,
         })
      );
   };
   const submit = (data: formType) => {
      isEdit ? updateCategory(data) : createCategory(data);
   };

   /* effects */
   useEffect(() => {
      if (system) {
         setValue(fieldsNames.es, {
            id: system?.id,
            text: system?.name,
            value: system,
         });
         setValue(fieldsNames.es + "_input", system.name);
      } else {
      }
   }, [system]);

   const [isButtonDisabled, setIsButtonDisabled] = useState(true);

   const formWatch = useWatch({ control });
   useEffect(() => {
      if (!!formWatch.es && !!formWatch.category_name) {
         setIsButtonDisabled(false);
      } else {
         setIsButtonDisabled(true);
      }
   }, [formWatch]);

   useEffect(() => {
      if (isEdit && !category) {
         commonState.location = routes.lk.admin.educationSystems.root;
      }
   }, []);

   return (
      <div className={styles.container}>
         <PageTitle
            title={
               isEdit
                  ? t(
                       "Lk.AdminPanel.AddSystem.edit-category",
                       "Редактировать категорию"
                    )
                  : t(
                       "Lk.AdminPanel.AddSystem.add-category",
                       "Добавить категорию"
                    )
            }
         />
         <Form
            onSubmit={handleSubmit(submit)}
            commonState={commonState}
            clearErrors={clearErrors}
            errors={errors}
            className={styles.form}
         >
            <SelectUnderControl
               label={t(
                  "Lk.lesson-service.body.education-system",
                  "Система образования"
               )}
               options={esFromApi.data}
               name={fieldsNames.es}
               setValue={setValue}
               readOnly={isEdit}
               control={control}
            />
            <Input
               label={t(
                  "Lk.AdminPanel.AddSystem.category-name",
                  "Название категории"
               )}
               name={fieldsNames.category_name}
               control={control}
               error={
                  errors?.category_name?.message ||
                  commonState?.errors?.category_name
               }
               defaultValue={isEdit ? category?.category?.name : undefined}
            />
            <div className={styles.buttons}>
               <Button
                  loading={commonState.loading}
                  className={styles.add_button}
                  disabled={isButtonDisabled}
               >
                  {isEdit
                     ? t(
                          "Lk.AdminPanel.AddSystem.edit-category",
                          "Редактировать категорию"
                       )
                     : t(
                          "Lk.AdminPanel.AddSystem.add-category",
                          "Добавить категорию"
                       )}
               </Button>
               <Button
                  loading={commonState.loading}
                  className={styles.cancel_button}
                  variant={"outline"}
                  onClick={() => {
                     history.push(routes.lk.admin.educationSystems.root);
                  }}
               >
                  {t("Buttons.cancel", "Отменить")}
               </Button>
            </div>
         </Form>
      </div>
   );
};
