import React, { FC, useEffect, useState } from "react";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";
import { roleType, statisticsType } from "types/types";
import { useQuery } from "../../../../../../hooks/useQuery";
import { userApi } from "../../../../../../api/userApi/methods";
import { useDispatch, useSelector } from "react-redux";
import { lkSlice, lkSliceSelectors } from "../../../../../../store/lkSlice";
import moment from "moment-timezone";
import { Preloader } from "../../../../../UI/Preloader";

type propsType = {
   role: roleType;
   dateTo?: string;
   dateFrom?: string;
   isStatic?: boolean;
};
export const CardStats: FC<propsType> = ({
   role,
   isStatic,
   dateFrom,
   dateTo,
}) => {
   const { t } = useTranslation();
   const user = useSelector(lkSliceSelectors.getUserData);
   const dispatch = useDispatch();
   const statisticsFromStore = useSelector(lkSliceSelectors.getStatistics);
   const userType = role == "student" ? "student" : "teacher";

   const { data: statisticsResponse, isLoading } = useQuery(
      () =>
         userApi.getStatistics({
            user_id: user.id,
            user_type: userType,
            date_from: dateFrom
               ? moment(dateFrom).utcOffset("+0000").format()
               : undefined,
            date_to: dateTo
               ? moment(dateTo).utcOffset("+0000").format()
               : undefined,
         }),
      {
         condition: !isStatic,
         serializer: (res) => {
            const data = res?.data.data;
            dispatch(
               lkSlice.actions.setStatistics({
                  key: userType,
                  value: data,
               })
            );
            return data as statisticsType;
         },
         deps: [dateTo, dateFrom],
      }
   );

   const statistics = isStatic
      ? statisticsFromStore[userType]
      : statisticsResponse;

   if (!statistics || isLoading)
      return (
         <Preloader
            loading={true}
            style={{
               width: 40,
               height: 40,
               marginBottom: 10,
            }}
         />
      );
   return (
      <div className={style.card_stats} data-class='card_stats'>
         <div className={style.card_small_title}>
            {t("Lk.Statistic.lectures", "Лекции")}
            <div className={style.card_value}>{statistics?.lesson_count}</div>
         </div>

         <div className={style.card_small_title}>
            {t("Lk.Statistic.hours", "Часы")}
            <div className={style.card_value}>{statistics?.number_hours}</div>
         </div>
         {role == "teacher" && (
            <div className={style.card_small_title}>
               {t("Lk.Statistic.students", "Ученики")}
               <div className={style.card_value}>{statistics?.students}</div>
            </div>
         )}
      </div>
   );
};
