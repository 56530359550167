import React, { ChangeEvent, FC } from "react";
import styles from "./style.module.css";
import { Button } from "../../../UI/Button";
import { CheckBox } from "../../../UI/Input/CheckBox";
import { useForm, useWatch } from "react-hook-form";
import { routes } from "routes";
import { useTranslation } from "react-i18next";
import { useQuery } from "hooks/useQuery";
import { formsDataApi } from "api/formsDataApi/methods";
import { languageSelectSerializer } from "serializers/languages";
import { languageType } from "store/formsDataSlice/types";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { lkSlice } from "store/lkSlice";
import i18n from "../../../../services/translateService/i18n";
import { Preloader } from "components/UI/Preloader";

type Form = {
   languages: Array<string | false>;
};
type propsType = {};
export const TeacherPickLanguage: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const { control, handleSubmit } = useForm<Form>();
   const commonState = useLocalCommonState();
   const languagesField = useWatch({
      control,
      name: "languages",
   });

   const { data: languages, isLoading: languagesLoading } = useQuery(
      formsDataApi.getLanguage,
      {
         cachedValueName: "languages",
         serializer: languageSelectSerializer,
      }
   );

   const onSubmit = (data: Form) => {
      let resultLanguages: Array<languageType> = [];
      data.languages.map((item) => {
         if (item !== false) {
            resultLanguages.push(JSON.parse(item));
         }
      });
      commonState.dispatch(
         lkSlice.actions.updateTeacherForm({
            languages: resultLanguages,
         })
      );

      commonState.location = routes.registration.teacher.instructionsSystems;
   };

   return (
      <form className='flex-col' onSubmit={handleSubmit(onSubmit)}>
         <h2 className={"RegistrationTeacher__title"}>
            {i18n.t(
               "Registration.titles.languages",
               "Выберете языки, на которых вы можете преподавать "
            )}
         </h2>
         <div className={styles.inputs}>
            <Preloader loading={!languages} variant={"centred"} />
            {languages?.map((item, index) => {
               return (
                  <CheckBox
                     key={index}
                     label={item.value.title}
                     name={`languages.${index}` as const}
                     control={control}
                     value={JSON.stringify(item.value)}
                  />
               );
            })}
         </div>
         <Button
            className={"Registration__button"}
            variant={"primary"}
            disabled={!languagesField?.filter((item) => item)?.length}
         >
            {t(
               "Registration.TeacherPages.TeacherPickLanguages.continue-button",
               "Продолжить"
            )}
         </Button>
      </form>
   );
};
