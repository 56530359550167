import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { useScrollLimit } from "hooks/useScrollLimit";
import { useQuery } from "hooks/useQuery";
import { formsDataApi } from "api/formsDataApi/methods";
import { subjectType } from "store/types";
import { CheckBox } from "components/UI/Input/CheckBox";
import { Preloader } from "components/UI/Preloader";
import { Control, UseFormSetValue } from "react-hook-form";
import { ReactComponent as SearchIcon } from "components/Registration/TeacherPages/TeacherRegistrationChooseSubjects/assets/Search.svg";
import { SelectedSubjects } from "components/Registration/TeacherPages/TeacherRegistrationChooseSubjects/components/SelectedSubjects";
import { useTranslation } from "react-i18next";

type propsType = {
   control: Control<any>;
   onSubmit: (data: any) => void;
   setValue: UseFormSetValue<any>;
};
export const SelectSubjectArea: FC<propsType> = ({
   control,
   setValue,
   onSubmit,
}) => {
   const { t } = useTranslation();
   const [filteredData, setFilteredData] = useState<Array<any>>([]);
   const { updateLimitOnScroll, limit } = useScrollLimit({ step: 100 });

   const { data: subjects, isLoading: subjectsLoading } = useQuery(
      formsDataApi.getSubject,
      {
         cachedValueName: "subjects",
         serializer: (res): Array<subjectType> => {
            return res?.data.data.items;
         },
      }
   );
   const searchHandler = (value: string) => {
      if (value) {
         const loweredValue = value.toLocaleLowerCase();
         setFilteredData(
            subjects.filter((item) => {
               return item.name.toLocaleLowerCase().includes(loweredValue);
            })
         );
      } else {
         setFilteredData([]);
      }
   };

   const mappedSubjects = filteredData.length ? filteredData : subjects;
   const displaySubjects = () => {
      let result = [];
      for (let i = 0; i < limit; i++) {
         const item = mappedSubjects[i];
         if (item)
            result.push(
               <label className={styles.checkbox_label}>
                  <div className={styles.checkbox_container}>
                     <CheckBox
                        control={control}
                        name={"subjects" + `.${item.id}`}
                        label={item.name}
                        value={JSON.stringify(item)}
                     />
                     <div className={styles.checkbox_description}>
                        <div className={styles.checkbox_grade}>
                           {item.education_system.level.name}
                        </div>
                        <div className={styles.checkbox_grade}>
                           {item.education_system.name}
                        </div>
                        {item?.category && <div>{item?.category?.name}</div>}
                     </div>
                  </div>
               </label>
            );
      }
      return result;
   };

   // VIEW
   if (!subjects) return <Preloader loading={true} variant='centred' />;
   const searchInputProps = control.register("search");

   return (
      <div>
         <div className={styles.input_container}>
            <input
               {...searchInputProps}
               onChange={(e) => {
                  searchInputProps.onChange(e).finally();
                  searchHandler(e.currentTarget.value);
               }}
               className={styles.input}
               placeholder={t(
                  "Registration.TeacherPages.TeacherInstructionsSystems.search-placeholder",
                  "Введите предмет"
               )}
               autoComplete='off'
            />
            <label htmlFor={"search"} className={styles.input_label}>
               <SearchIcon />
            </label>
         </div>
         <form
            key={filteredData.length}
            id='form'
            className={styles.form_container}
            onSubmit={onSubmit}
            onScroll={updateLimitOnScroll}
         >
            {displaySubjects()}
         </form>
         <SelectedSubjects
            setValue={setValue}
            subjects={subjects}
            control={control}
         />
      </div>
   );
};
