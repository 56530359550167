import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { authSliceSelectors } from "store/authSlice";
import { HeaderLanding } from "./HeaderLanding";
import { HeaderLk } from "./HeaderLk";
import { useMedia } from "hooks/useMedia";
import { HeaderLkMobile } from "components/Header/HeaderLkMobile";
import { useLocation } from "react-router-dom";
import { routes } from "routes";
import { lkSliceSelectors } from "store/lkSlice";

export const Header: FC = () => {
   const user = useSelector(lkSliceSelectors.getUserData);
   const { width } = useMedia();
   const { pathname } = useLocation();

   if (width < 1024 && user.type) {
      if (
         (pathname == routes.landings.main && user.type) ||
         pathname == routes.landings.filter ||
         (pathname.includes("payment") && width > 479) ||
         (pathname.includes("financial-operations") && width > 479)
      ) {
         return <HeaderLanding />;
      }
      return <HeaderLkMobile />;
   }

   return user.type ? <HeaderLk /> : <HeaderLanding />;
};
