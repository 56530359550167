import { AxiosResponse } from "axios";
import { subjectType } from "../store/types";
import { educationSystemType } from "../store/formsDataSlice/types";
export type subjectsSelectType = {
   id: number;
   text: string;
   value: subjectType;
};
export function subjectSelectSerializer(
   res: AxiosResponse<any> | undefined
): Array<{
   id: number;
   text: string;
   value: subjectType;
}> {
   return res?.data?.data?.items.map((item: any) => ({
      id: item.id,
      text: item.name,
      value: item,
   }));
}
