import React, { FC, useRef } from "react";
import styles from "./index.module.css";
import cn from "classnames";
import { Icons } from "assets/iconsCorrect";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { routes } from "routes";
import { useBlurred } from "hooks/useBlurred";
import { LangSwitcher } from "../../../UI/LangSwitcher";
import { useDispatch, useSelector } from "react-redux";
import { lkSliceSelectors } from "../../../../store/lkSlice";
import { logout } from "store/helpers";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { useGoToProfile } from "helpers/util/goToProfile";
import { LangSwitcherSmall } from "components/UI/LangSwitcher/LangSwitcherSmall";

type propsType = {
   isVisible: boolean;
   setIsVisible: any;
};
export const HeaderMobileMenu: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const user = useSelector(lkSliceSelectors.getUserData);
   const ref = useRef(null);
   useBlurred(ref, props.setIsVisible, props.isVisible);
   const commonState = useLocalCommonState();

   const logoutHandler = () => {
      logout(commonState.dispatch);
      commonState.location = routes.registration.auth.firstStage;
   };
   const goToProfile = useGoToProfile(user.id);

   const goToLkHandler = () => {
      if (user) {
         if (!user.birth_date) {
            commonState.location = routes.registration.student.fillInformation;
            return;
         }
         if (!user.study_education_system) {
            commonState.location =
               routes.registration.student.studentRegistration;
            return;
         }
         goToProfile();
      }
   };

   return (
      <>
         <div
            className={cn({
               [styles.container_hidden]: true,
               [styles.container_visible]: props.isVisible,
            })}
            ref={ref}
         >
            <div className={styles.header}>
               <Link to='/'>
                  <Icons.logos.logoFull />
               </Link>
               <Icons.ui.burgerIcon
                  className={styles.burger}
                  onClick={() => props.setIsVisible(!props.isVisible)}
               />
            </div>
            <div className={styles.navigation}>
               <a
                  className={styles.nav_link}
                  onClick={() => props.setIsVisible(!props.isVisible)}
                  href='#how-we-work'
               >
                  {t("Header.how-we-work", "Как мы работаем")}
               </a>
               <a
                  className={styles.nav_link}
                  onClick={() => props.setIsVisible(!props.isVisible)}
                  href='#FAQ'
               >
                  {t("Header.faq", "FAQ")}
               </a>
               <a
                  className={styles.nav_link}
                  onClick={() => props.setIsVisible(!props.isVisible)}
                  href='#reviews'
               >
                  {t("Landings.Reviews.header", "Отзывы")}
               </a>
            </div>
            {!user?.id && <LangSwitcherSmall className={styles.lang} />}

            <div className={styles.bottom_link}>
               {user.type ? (
                  <a onClick={goToLkHandler}>
                     {t("Buttons.to-lk", "В личный кабинет")}
                  </a>
               ) : (
                  <Link to={routes.registration.auth.firstStage}>
                     {t("Header.registration", "Зарегистрироваться/Войти")}
                  </Link>
               )}
               {user.type && (
                  <div
                     style={{
                        cursor: "pointer",
                        marginTop: "30px",
                     }}
                     onClick={logoutHandler}
                  >
                     {t("Lk.Header.logout", "Выйти")}
                  </div>
               )}
            </div>
         </div>
         <div
            className={cn({
               [styles.background]: true,
               [styles.background_visible]: props.isVisible,
            })}
         />
      </>
   );
};
