import React, { FC, HTMLAttributes, useState } from "react";
import styles from "./index.module.css";

import { ReactComponent as AttachIcon } from "./assets/Attach.svg";
import cn from "classnames";

type propsType = {
   controllable?: boolean;
} & HTMLAttributes<HTMLDivElement>;
export const Prompt: FC<propsType> = (props) => {
   const [isShown, setIsShown] = useState(true);

   const controlHandler = () => {
      if (props.controllable) {
         setIsShown(false);
      }
   };

   if (!isShown) return null;
   return (
      <div
         {...props}
         className={cn(styles.container, {
            [`${props.className}`]: props.className,
         })}
      >
         {props.children}
         {props.controllable && (
            <button className={styles.button} onClick={controlHandler}>
               <AttachIcon />
            </button>
         )}
      </div>
   );
};
