import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AppDispatch, useAppDispatch } from "../../../../store";
import { ErrorType } from "../types/types";

export type useLocalCommonStateType<T> = {
  errors: ErrorType;
  loading: boolean;
  data: T;
  location: string;
  dispatch: AppDispatch;
};


export function useLocalCommonState<T>(): useLocalCommonStateType<T> {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<ErrorType>({});
  const [data, setData] = useState<T>({} as T);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  return {
    get errors() {
      return errors;
    },
    set errors(errors: ErrorType) {
      setErrors(errors);
    },

    get loading() {
      return loading;
    },
    set loading(value: boolean) {
      setLoading(value);
    },

    get data() {
      return data;
    },
    set data(value: T) {
      setData(value);
    },

    get location() {
      return location.pathname;
    },
    set location(val: string) {
      history.push(val);
    },

    get dispatch() {
      return dispatch;
    },
  };
}
