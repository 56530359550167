import React, { AllHTMLAttributes, FC } from "react";
import styles from "components/UI/PageTitle/index.module.css";
import { ReactComponent as ArrowLeft } from "components/UI/PageTitle/assets/arrowLeft.svg";
import { Link } from "react-router-dom";
import cn from "classnames";

type PropsType = {
   title: string;
   support?: string | number;
   urlToBack?: string;
   behavior?: "tablet_hide";
} & AllHTMLAttributes<HTMLDivElement>;

export const PageTitle: FC<PropsType> = (props) => {
   const { title, support, urlToBack } = props;

   return (
      <div
         className={cn(styles.container + ` ${props.className}`, {
            [`${styles.tablet_hide}`]: props.behavior == "tablet_hide",
         })}
      >
         <h2 className={styles.title}>
            {urlToBack && (
               <Link to={urlToBack}>
                  <ArrowLeft className={styles.icon} />
               </Link>
            )}
            {title}
         </h2>
         {(support || support == 0) && (
            <p className={styles.support}>{support.toLocaleString()}</p>
         )}
      </div>
   );
};
