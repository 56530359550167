import React, { FC, HTMLAttributes } from "react";
import styles from "./style.module.css";
import { ReactComponent as StarRatingIcon } from "../../../assets/icons/StarRating.svg";
import { ReactComponent as MessageIcon } from "./assets/Mail.svg";
import { useTranslation } from "react-i18next";
import { useGetUserAvatar } from "hooks/useGetUserAvatar";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { ChatThunk } from "store/chatSlice/thunk";
import { useSelector } from "react-redux";
import { authSliceSelectors } from "store/authSlice";
import { useGoToProfile } from "helpers/util/goToProfile";

type propsType = {
   user: any;
   variant: "teacher" | "student";
} & HTMLAttributes<HTMLDivElement>;
export const UserWidget: FC<propsType> = ({ user, variant, ...props }) => {
   const { t } = useTranslation();
   const commonState = useLocalCommonState();
   const clientUserId = useSelector(authSliceSelectors.getId);
   const goToProfile = useGoToProfile(user?.id);
   const [avatar] = useGetUserAvatar(user?.id);
   if (!user) return null;

   const createChat = () => {
      commonState.dispatch(
         ChatThunk.createChat({
            users: [Number(clientUserId), Number(user.id)],
            commonState,
            needSetLocation: true,
            admin_chat: false,
         })
      );
   };

   const rating =
      variant == "teacher" ? user.teaching_rating : user.study_rating;

   return (
      <div
         className={styles.container + ` ${props.className}`}
         style={{
            backgroundColor: variant == "teacher" ? "#E6EFFE" : "#F8F8F8",
         }}
         onClick={goToProfile}
      >
         <div className={styles.profile_container}>
            <div className={styles.avatar}>
               <img src={avatar} />
            </div>
            <div className={styles.wrapper}>
               <div className={styles.name}>
                  <p>{user.first_name}</p>
                  <p>{user.last_name}</p>
               </div>
               {(user.teaching_rating || user.study_rating) != undefined && (
                  <div className={styles.rating}>
                     <StarRatingIcon />
                     <p>{rating ? rating.toFixed(2) : "—"}</p>
                  </div>
               )}
            </div>
         </div>
         <button onClick={createChat} className={styles.send_message}>
            <MessageIcon /> {t("Buttons.write-message", "Написать сообщение")}
         </button>
      </div>
   );
};
