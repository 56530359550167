import React, { FC } from "react";
import cn from "classnames";
import { SelectListElementsType } from "../types";

export type SelectElementPropsType = {
   index: number;
   element: SelectListElementsType;
   select: (v: any) => void;
};
export const SelectElement: FC<SelectElementPropsType> = (props) => {
   const { select, children, element, index } = props;

   const selectHandler = () => {
      select(element);
   };

   return (
      <div
         id={String(index)}
         onClick={selectHandler}
         data-cy={"select-item-" + String(index)}
         className={cn({ Select__item: true })}
      >
         {children}
      </div>
   );
};

type SelectItemRenderPropsType<T> = {
   component?: FC<T>;
} & SelectElementPropsType;
export function SelectItemRender<T>({
   index,
   select,
   element,
   component,
}: SelectItemRenderPropsType<T>) {
   return (
      <SelectElement
         key={index}
         index={index}
         element={element}
         select={select}
      >
         {component ? (
            React.createElement(component, {
               ...element.value,
            })
         ) : (
            <div>{element.text}</div>
         )}
      </SelectElement>
   );
}
