import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { ProfileSettingsFormType } from "components/Lk/Pages/Settings/ProfileSettings";
import { getDateWithoutTime, showDateYMD } from "helpers/date/showDate";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { useGetUserAvatar } from "hooks/useGetUserAvatar";
import { modalWindowTemplateType, uiSlice } from "store/modalWindowSlice";
import { defaultFileType } from "api/types";
import { lkSLiceThunk } from "store/lkSlice/thunk";
import { dateMethods } from "helpers/date";
import { Form } from "core/LTools/core/functional components/Form";
import { PhotoUploader } from "components/UI/PhotoUploader";
import { Input } from "components/UI/Input";
import { Calendar } from "components/UI/Calendar";
import moment from "moment-timezone";
import { Button } from "components/UI/Button";
import { useLkContext } from "components/Lk/Pages/LkProfile/index";
import styles from "./index.module.css";
import { studentType, teacherType } from "types/userType";

type propsType = {
   user?: teacherType;
};

/** @Logic
 * Если загрузили фото - пост файл и к Update, иначе сразу к Update
 * Update - загружаем данные, делаем рефеч юзера при всех кейсах.
 * */

export const EditProfile: FC<propsType> = ({ user }) => {
   const { t } = useTranslation();
   const { fetch, setIsEditing, isEditing } = useLkContext();
   const {
      control,
      handleSubmit,
      clearErrors,
      setValue,
      formState: { errors },
   } = useForm<ProfileSettingsFormType>({
      defaultValues: {
         name: user?.first_name,
         lastName: user?.last_name,
         birthDate: getDateWithoutTime(user?.birth_date),
      },
   });

   const commonState = useLocalCommonState();
   const [avatar] = useGetUserAvatar(user?.id);

   const sendData = (data: any) => {
      if (data?.avatar?.content) {
         commonState.dispatch(
            lkSLiceThunk.uploadFile({
               user_id: String(user?.id),
               commonState,
               content: data.avatar.content,
               file_name: data.avatar.file_name,
               file_type: "avatar",
               callbacks: {
                  201: () => update(data),
               },
            })
         );
      } else {
         update(data);
      }
   };

   const update = (data: any) => {
      commonState.dispatch(
         lkSLiceThunk.updateUser({
            commonState,
            user_id: String(user?.id),
            disableSetToStore: true,
            payload: {
               birth_date: dateMethods.formatDMY(data.birthDate),
               first_name: data.name,
               last_name: data.lastName,

               //@ts-ignore
               instruction_subjects: user?.instruction_subjects
                  ? user?.instruction_subjects.map((item) => item.id)
                  : undefined,
               //@ts-ignore
               instruction_languages: user?.instruction_languages
                  ? user?.instruction_languages.map((item) => item.id)
                  : undefined,
            },

            callbacks: {
               200: () => {
                  successCaseHandler();
               },
               400: fetch,
               401: fetch,
               404: fetch,
            },
         })
      );
   };

   const successCaseHandler = () => {
      setIsEditing(false);
      fetch();
      commonState.dispatch(
         uiSlice.actions.setModalType({
            template: "default" as modalWindowTemplateType["default"],
            payload: {
               title: t("Common.success", "Успешно"),
               button: "close",
               status: "success",
            },
         })
      );
   };

   return (
      <Form
         commonState={commonState}
         clearErrors={clearErrors}
         onSubmit={handleSubmit(sendData)}
         errors={errors}
         className={styles.container}
      >
         <Input
            className={styles.input}
            control={control}
            name='name'
            label={t("Registration.StudentPages.name-label", "Имя")}
            error={commonState.errors?.first_name}
         />
         <Input
            className={styles.input}
            control={control}
            name='lastName'
            label={t("Registration.StudentPages.surname-label", "Фамилия")}
            error={commonState.errors?.last_name}
         />
         <Calendar
            className={styles.input}
            control={control}
            name='birthDate'
            placeholder={t(
               "Registration.StudentPages.birth-date-label",
               "Дата рождения"
            )}
            minDate={moment().subtract(100, "year").toDate()}
            maxDate={moment().subtract(6, "year").toDate()}
            error={commonState.errors?.birth_date}
         />
         <PhotoUploader defaultImg={avatar} name='avatar' setValue={setValue} />
         <Button
            loading={commonState.loading}
            style={{
               marginTop: 36,
            }}
         >
            {t("Buttons.save", "Сохранить")}
         </Button>
      </Form>
   );
};
