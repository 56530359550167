import React, { FC } from "react";
import styles from "./index.module.css";
import mobileStyles from "./mobile.module.css";
import { ReactComponent as Arrow } from "../../../../../../../../UI/Table/assets/arrow.svg";
import { lessonType } from "../../../../../../../../../types/LessonService/types";
import { useGetUserAvatar } from "../../../../../../../../../hooks/useGetUserAvatar";
import { useHistory } from "react-router-dom";
import { routes } from "../../../../../../../../../routes";
import { dateMethods } from "../../../../../../../../../helpers/date";
import { useMedia } from "hooks/useMedia";
import { Icons } from "assets/iconsCorrect";

export type NoteHistoryTableItemType = {
   lesson: lessonType;
   user_id: string;
   type?: "lesson" | "group_lesson";
};

export const NoteTableItem: FC<NoteHistoryTableItemType> = (props) => {
   const { lesson, user_id: clientUserId } = props;
   const history = useHistory();
   const { width } = useMedia();

   const goToLesson = () => {
      if (props.type == "lesson") {
         history.push(routes.lk.lesson.default.view + `/${lesson.id}`);
      } else if (props.type == "group_lesson") {
         history.push(routes.lk.lesson.group.view + `/${lesson.id}`);
      }
   };

   const rating = (function () {
      if (!lesson.votes.length) return "—";
      let rate: number = 0;

      lesson.votes.map((vote) => {
         rate += Number(vote.rating);
      });
      rate /= lesson.votes.length;

      return rate;
   })();

   const user = (function () {
      if (!lesson?.student) {
         return lesson?.teacher;
      } else {
         return lesson?.teacher?.id == clientUserId
            ? lesson.student
            : lesson.teacher;
      }
   })();

   const [avatar] = useGetUserAvatar(user?.id);

   if (!lesson) return null;

   if (width < 720) {
      return (
         <div className={mobileStyles.item}>
            <div className={mobileStyles.preview}>
               <div className={mobileStyles.preview_header}>
                  <div className={mobileStyles.preview_left}>
                     <div
                        className={mobileStyles.status}
                        style={{
                           backgroundColor:
                              clientUserId == lesson.teacher.id
                                 ? "#0741AD"
                                 : "#7807AD",
                        }}
                     />
                     <img className={mobileStyles.preview_photo} src={avatar} />
                     <div className={mobileStyles.header_nameAndId}>
                        <p className={mobileStyles.preview_name}>
                           {user?.first_name} {user?.last_name}
                        </p>
                     </div>
                  </div>
                  <button
                     onClick={goToLesson}
                     className={mobileStyles.open_button}
                  >
                     <Arrow />
                  </button>
               </div>
               <div className={mobileStyles.preview_row}>
                  <div className={mobileStyles.preview_item}>
                     <p className={mobileStyles.preview_text}>
                        {dateMethods.format(lesson?.date_from, "DD.MM.YYYY")}
                     </p>
                     <p className={mobileStyles.preview_text}>
                        {dateMethods.getTimeInterval(
                           lesson.date_from,
                           lesson.date_to
                        )}
                     </p>
                  </div>
                  {String(rating) != "—" && (
                     <div className={mobileStyles.preview_item}>
                        <p className={mobileStyles.rate}>
                           {rating} <Icons.ui.starFilled />
                        </p>
                     </div>
                  )}
               </div>
            </div>
         </div>
      );
   }

   return (
      <>
         <tr className={styles.preview}>
            <td className={styles.table_item}>
               <div
                  className={styles.status}
                  style={{
                     backgroundColor:
                        clientUserId == lesson.teacher.id
                           ? "#0741AD"
                           : "#7807AD",
                  }}
               />
            </td>
            <td className={styles.table_item}>
               <div className={styles.photoAndName}>
                  <img className={styles.avatar} src={avatar} alt='' />
                  <div className={styles.user}>
                     <p className={styles.user_name}>
                        {user.first_name + " " + user.last_name}
                     </p>
                  </div>
               </div>
            </td>
            <td className={styles.table_item}>
               {dateMethods.format(lesson?.date_from, "DD.MM.YYYY")}
               <br />
               {dateMethods.getTimeInterval(lesson.date_from, lesson.date_to)}
            </td>
            <td className={styles.table_item}>{lesson?.language?.name}</td>
            <td className={styles.table_item}>{lesson?.subject?.name}</td>
            <td className={styles.table_item}>{lesson?.topic}</td>
            <td className={styles.table_item}>{rating}</td>
            <td className={styles.button}>
               <button onClick={goToLesson}>
                  <Arrow />
               </button>
            </td>
         </tr>
      </>
   );
};
