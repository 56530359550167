import React, { useState } from "react";
import "./style.css";
import { InputPropsType } from "../index";
import { RadioOff } from "assets/inputs/RadioOff";
import { RadioOn } from "assets/inputs/RadioOn";

type radioInputPropsType<T> = {} & InputPropsType<T> & any;
export function RadioInput<T>(props: radioInputPropsType<T>) {
   return (
      <div className='RadioInput'>
         <div className='Input__wrapper'>
            <input
               type='radio'
               {...props.control.register(props.name)}
               value={props.value}
               id={props.name + props.value}
               defaultChecked={props.defaultChecked}
            />

            <label className='Radio__label' htmlFor={props.name + props.value}>
               <div className='Radio__label-on'>
                  <RadioOn />
               </div>
               <div className='Radio__label-off'>
                  <RadioOff />
               </div>

               <p>{props.label}</p>
            </label>
         </div>
      </div>
   );
}
