import React, { FC } from "react";
import { SelectUnderControl } from "components/UI/Select/SelectUnderControl";
import { FilesInput } from "components/UI/Input/FilesInput";
import { Button } from "components/UI/Button";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";
import { ContentBlockContainer } from "components/UI/ContentBlockContainer";
import styles from "./index.module.css";
import { languageType } from "store/formsDataSlice/types";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { lessonThunk } from "store/lessonSlice/thunk";
import { DateAndTimePicker } from "components/UI/DateAndTimePicker";
import { languagesTitleResolver } from "staticData/languages";
import { useTranslation } from "react-i18next";
import { Form } from "core/LTools/core/functional components/Form";
import { uiSlice } from "store/modalWindowSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import { timeAndDatePickerSchema } from "helpers/validationSchemes";
import { addMethodToUpdateLang } from "services/translateService/useLangInit";
import * as yup from "yup";
import i18n from "services/translateService/i18n";
import { useScrollToError } from "hooks/useScrollToError";
import moment from "moment-timezone";
import { defaultFileType } from "../../../../../../api/types";
import { groupLessonApi } from "../../../../../../api/groupLessonApi/methods";
import { uploadFiles } from "../../../../../../helpers/uploadFiles";
import { Input } from "components/UI/Input";
import { showDateYMDNative } from "helpers/date/showDate";

let scheme: any;
addMethodToUpdateLang(() => {
   scheme = yup.object().shape({
      topic: yup
         .string()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         ),
      language: yup
         .object()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         ),
   });
});

export const CreateOpenLesson: FC = () => {
   const { t } = useTranslation();
   const userData = useSelector(lkSliceSelectors.getUserData);
   const commonState = useLocalCommonState();
   const {
      handleSubmit,
      control,
      setValue,
      watch,
      clearErrors,
      formState: { errors },
   } = useForm({
      // @ts-ignore
      resolver: yupResolver(timeAndDatePickerSchema.concat(scheme)),
      reValidateMode: "onChange",
   });
   useScrollToError(errors);
   const es = useWatch({
      control,
      name: "education_system",
   });
   const subject = useWatch({
      control,
      name: "subject",
   });

   /* Logic */
   const showSuccessModal = () => {
      commonState.loading = false;
      commonState.dispatch(
         uiSlice.actions.setModalType({
            template: "default",
            payload: {
               status: "success",
               title: t(
                  "ModalWindow.GroupLesson.title",
                  "Групповой урок успешно создан"
               ),
               button: "to_lk",
            },
         })
      );
   };
   const uploadFilesAndShowSuccessModal = ({
      files,
      groupLessonId,
   }: {
      files: Array<defaultFileType>;
      groupLessonId: string | undefined;
   }) => {
      commonState.loading = true;
      uploadFiles({
         apiMethod: (file) =>
            groupLessonApi.addFileToGroupLesson({
               group_lesson_id: groupLessonId,
               ...file,
            }),
         files: files,
         callback: showSuccessModal,
      });
   };
   const submit = (data: any) => {
      // const date = moment(showDateYMDNative(data.date));

      const dateFrom = moment(data.date)
         .add(data.date_from.value.hours, "hours")
         .add(data.date_from.value.minutes, "minutes")
         .utcOffset("+0000");
      const dateTo = moment(dateFrom).add(
         data.duration.value.minutes,
         "minutes"
      );

      commonState.dispatch(
         lessonThunk.createGroupLesson({
            commonState,
            language_id: data?.language?.value?.language?.id,
            subject_id: data.subject.value.id,
            date_from: dateFrom.format(),
            date_to: dateTo.format(),
            topic: data.topic,
            callbacks: {
               "201": (res) => {
                  uploadFilesAndShowSuccessModal({
                     files: data.files,
                     groupLessonId: res?.data.data.id,
                  });
               },
            },
         })
      );
   };

   if (!userData) return null;
   return (
      <Form
         errors={errors}
         clearErrors={clearErrors}
         onSubmit={handleSubmit(submit)}
         className={styles.container}
         commonState={commonState}
      >
         <ContentBlockContainer
            title={t("Lk.SendRequest.date-label", "Дата и время")}
            className={styles.date_container}
         >
            <DateAndTimePicker
               control={control}
               setValue={setValue}
               variant={"row_1"}
               errors={errors}
            />
         </ContentBlockContainer>
         <ContentBlockContainer
            title={t("Lk.SendRequest.subject-title", "Предмет и тема")}
            className={styles.extra}
         >
            <SelectUnderControl
               watch={watch}
               name='language'
               className={styles.extra__select}
               setValue={setValue}
               options={userData.instruction_languages.map((item, index) => {
                  return {
                     id: index,
                     text: `${languagesTitleResolver[item.name]}`,
                     value: {
                        language: item as languageType,
                     },
                  };
               })}
               component={({ language }) => (
                  <div>{languagesTitleResolver[language.name]}</div>
               )}
               control={control}
               label={t("Common.language", "Язык")}
            />
            <SelectUnderControl
               watch={watch}
               name='education_system'
               className={styles.extra__select}
               setValue={setValue}
               options={userData?.instruction_education_systems?.map(
                  (item, index) => ({
                     id: index,
                     text: item?.name,
                     value: item,
                  })
               )}
               component={({ name }) => <div>{name}</div>}
               control={control}
               label={t(
                  "Lk.lesson-service.body.education-system",
                  "Система образования"
               )}
            />

            <SelectUnderControl
               watch={watch}
               name='subject'
               disabled={!es?.value}
               className={styles.extra__select}
               setValue={setValue}
               options={userData.instruction_subjects
                  .filter((item) => item.education_system.id == es?.value?.id)
                  .map((item, index) => {
                     return {
                        id: 0,
                        text: `${item.name}`,
                        value: item,
                     };
                  })}
               component={({ name }) => <div>{name}</div>}
               control={control}
               label={t("Lk.lesson-service.body.subject", "Предмет")}
            />

            <SelectUnderControl
               watch={watch}
               name='grade'
               className={styles.extra__select}
               setValue={setValue}
               options={userData?.instruction_grades?.map((item) => ({
                  id: item.id,
                  text: item.name,
                  value: item,
               }))}
               component={({ name }) => <div>{name}</div>}
               control={control}
               label={t("Lk.lesson-service.body.lesson-grade", "Грейд")}
            />

            <Input
               error={commonState.errors.topic || errors?.topic?.message}
               control={control}
               name='topic'
               label={t("Lk.lesson-service.body.topic", "Тема занятия")}
            />
            <FilesInput
               className={styles.files}
               setValue={setValue}
               control={control}
               name='files'
            />
         </ContentBlockContainer>

         <Button
            disabled={!subject?.value?.name}
            className={styles.action_button}
            variant={"primary"}
            loading={commonState.loading}
         >
            {t("Lk.SendRequest.add-button", "Добавить")}
         </Button>
      </Form>
   );
};
