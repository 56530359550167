import React, { FC, HTMLAttributes, useState } from "react";
import styles from "./index.module.css";
import cn from "classnames";

type TitleSwitcherType = {
   labels: [string, string];
   setSelect: (event: boolean) => void;
   selected: boolean;
   disabled?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const SwitcherBig: FC<TitleSwitcherType> = (props) => {
   const { className, labels, setSelect, selected, ...other } = props;

   function setSelectedState(event: boolean) {
      setSelect(event);
   }

   return (
      <div
         className={cn(styles.container, {
            [`${className}`]: className,
            [`${styles[`container--left_active`]}`]: !selected,
            [`${styles[`container--right_active`]}`]: selected,
            [`${styles.disabled}`]: props.disabled,
         })}
      >
         <div className={styles.bg} />
         <div
            className={cn(styles.left, {
               [styles["left-active"]]: !selected,
            })}
         >
            <h5
               onClick={() => setSelectedState(false)}
               className={styles.label}
            >
               {labels[0]}
            </h5>
            {/*<div*/}
            {/*   className={cn(styles.stripe, {*/}
            {/*      [styles["left-active"]]: !selected,*/}
            {/*   })}*/}
            {/*/>*/}
         </div>
         <div
            className={cn(styles.right, {
               [styles["right-active"]]: selected,
            })}
         >
            <h5 onClick={() => setSelectedState(true)} className={styles.label}>
               {labels[1]}
            </h5>
            {/*<div*/}
            {/*   className={cn(styles.stripe, {*/}
            {/*      [styles["right-active"]]: selected,*/}
            {/*   })}*/}
            {/*/>*/}
         </div>
      </div>
   );
};
