import { getAllCards } from "api/adminApi/types";
import { lkApi } from "api/LkApi/methods";
import { useMutation, useQueryClient } from "react-query";
import RevolutCheckout from "@revolut/checkout";

export const useAddCard = (type: getAllCards) => {
    const queryClient = useQueryClient();

    const mutation = useMutation(() => lkApi.createNewCard(type), {
        onSuccess: (data) => {
            lkApi.getCardByCardID(data?.data?.data?.id).then((cardData) => {
                const token = cardData?.data?.data?.ruble_payment?.token
                RevolutCheckout(token).then((instance: any) => {
                    const cardOptions: any = {
                        savePaymentMethodFor: "merchant",
                        target: null,
                        onSuccess() {
                            queryClient.invalidateQueries("useGetCard");
                        },
                    };
                    instance.payWithPopup(cardOptions)
                })
            });
        },
        // onSettled: (data) => {
        //     console.log(data?.data?.data, "data")
        //     queryClient.invalidateQueries("useGetCard");
        // },
    });

    const addCard = () => {
        mutation.mutate();
    };

    return {
        addCard,
        isLoading: mutation.isLoading,
        error: mutation.isError ? mutation.error : null,
    };
};
