import React, { FC, useRef } from "react";
import styles from "./index.module.css";
import {
   resolveLanguageNameByCode,
   switchLanguageOptions,
} from "staticData/languages";
import { Icons } from "assets/iconsCorrect";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";
import { useBlurred } from "hooks/useBlurred";
import { useToggle } from "hooks/useToggle";
import { languageCodeType } from "services/translateService";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { useQuery } from "../../../../hooks/useQuery";
import { userApi } from "../../../../api/userApi/methods";
import { authSlice, authSliceSelectors } from "../../../../store/authSlice";

type propsType = {
   className?: string;
};
export const LangSwitcherSmall: FC<propsType> = (props) => {
   const { i18n } = useTranslation();
   const lang = useSelector(lkSliceSelectors.getLang);
   const [open, toggleOpen, setOpen] = useToggle(false);
   const ref = useRef<HTMLDivElement | null>(null);
   const user_id = useSelector(authSliceSelectors.getId);
   useBlurred(ref, setOpen, open);

   const changeLang = async (code: languageCodeType) => {
      i18n.changeLanguage(code);
      setOpen(false);

      if (user_id !== null && user_id !== undefined) {
         await userApi.changeLanguage({
            user_id,
            language: code,
         });
      }
   };

   return (
      <div ref={ref} className={cn(styles.container, props.className)}>
         <div className={styles.selected} onClick={toggleOpen}>
            {React.createElement(
               Icons.languageFlags[resolveLanguageNameByCode[lang]],
               { className: styles.selected__icon }
            )}
            <Icons.ui.arrowTriangleIcon className={styles.arrow} />
         </div>
         {open && (
            <div className={styles.option_list}>
               {switchLanguageOptions.map((item) => (
                  <div
                     className={styles.option}
                     onClick={() => changeLang(item.value.code)}
                  >
                     {React.createElement(
                        Icons.languageFlags[
                           resolveLanguageNameByCode[item.value.code]
                        ],
                        {
                           className: styles.option__icon,
                        }
                     )}
                     <p>{item.text}</p>
                  </div>
               ))}
            </div>
         )}
      </div>
   );
};
