import React from "react";
import { ScheduleDay } from "components/Lk/Pages/SchedulePage/Schedule/ScheduleCalendar/ScheduleDays/ScheduleDay";
import moment from "moment-timezone";

/* Кол-во дней в месяце */
export function daysInMonth(date: Date) {
   return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
}

/* Создаёт новую дату со смещением месяца */
export function offsetMonth(date: Date, offset: number) {
   const newDate = new Date(date);
   newDate.setDate(1);
   newDate.setMonth(date.getMonth() + offset);
   return newDate;
}

export function getDayOfWeek(date: Date, offset: number) {
   const newDate = new Date(date.getFullYear(), date.getMonth(), offset);
   return newDate.getDay();
}

/* Возвращает индекс первого дня в месяце */
export function getFirstDayWeekIndex(date: Date) {
   const newDate = moment(date).toDate();
   newDate.setDate(0);
   return newDate.getDay();
}

/* @param
 * limit - граница итератора
 * role - enum для позиции месяца
 * dayOffset - дополнительное число для дня
 *  */
export const generateArea = (
   limit: number,
   role: "next" | "prev" | "curr",
   dayOffset: number,
   type: string
) => {
   let result = [];
   for (let i = 0; i < limit; i++) {
      result.push(
         <ScheduleDay
            role={role}
            key={role + i}
            day={i + dayOffset}
            type={type}
         />
      );
   }
   return result;
};
