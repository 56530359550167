import React, { FC } from "react";
import "./style.css";
import { Main } from "./Main";
import { Footer } from "./Footer";
import { Route, useHistory } from "react-router-dom";
import { routes } from "../../routes";
import { SearchLessonFilter } from "./Filter";

type propsType = {};
export const Landings: FC<propsType> = (props) => {
   const history = useHistory();

   return (
      <div>
         <Route exact path={routes.landings.main}>
            <Main />
            <Footer />
         </Route>
         <Route path={routes.landings.filter}>
            <SearchLessonFilter
               config={{
                  firstBreadCrumbAction: () => history.push("/"),
               }}
            />
         </Route>
      </div>
   );
};
