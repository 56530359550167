import React, { FC } from "react";
import styles from "./index.module.css";
import { ReactComponent as Agree } from "../../../assets/agree.svg";
import { ReactComponent as Block } from "../../../assets/block.svg";
import { Button } from "components/UI/Button";
import { FileDownloader } from "components/UI/FileDownloader";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { adminThunks } from "store/adminSlice/thunks";
import { gradeType } from "store/formsDataSlice/types";

import { ReactComponent as Rating } from "../../../../../../../../../assets/icons/StarRating.svg";
import { ReactComponent as ErrorIcon } from "../../../../../../../../../assets/iconsCorrect/status/error.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";
import moment from "moment-timezone";
import { showDateDMY } from "helpers/date/showDate";
import { teacherType } from "types/userType";
import { routes } from "../../../../../../../../../routes";
import { CardItem } from "./components/CardItem";
import { useGetCard } from "hooks/useGetCard";
import { useDeleteCard } from "hooks/useDeleteCard";

type propsType = {
   allCards?: any;
   user: teacherType;
   avatar: string;
   updateState: () => void;
   updateTable: () => void;
};

const data = [
   {
      id: 1,
      number: "3770 xxxx xx00 0000",
      type: "VISA",
      date: "05.2025"
   },
   {
      id: 2,
      number: "5645 xxxx xx00 0000",
      type: "Master Card",
      date: "05.2025"
   },
   {
      id: 3,
      number: "3701 xxxx xx00 0000",
      type: "American Express",
      date: "05.2025"
   },
]

export const AdminUserInfoBlock: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const { user, updateState, updateTable } = props;
   const myUser = useSelector(lkSliceSelectors.getUserData);
   const commonState = useLocalCommonState();

   function userToAdmin() {
      commonState
         .dispatch(
            adminThunks.setUserRole({
               user_id: props.user.id,
               role: "admin",
               commonState,
            })
         )
         .then(() => updateTable());
   }

   function adminToUser() {
      commonState
         .dispatch(
            adminThunks.setUserRole({
               user_id: props.user.id,
               role: "user",
               commonState,
            })
         )
         .then(() => updateTable());
   }

   function blockUser() {
      commonState
         .dispatch(
            adminThunks.blockUser({
               user_id: props.user.id,
               commonState,
            })
         )
         .then(() => updateTable());
   }

   function teacherToUser() {
      commonState
         .dispatch(
            adminThunks.blockTeacher({
               user_id: props.user.id,
               commonState,
            })
         )
         .then(() => updateTable());
   }

   const AllPayinCards = props.allCards?.filter((item: any) => {
      return item?.type === "payin"
   })

   const AllPayoutCards = props.allCards?.filter((item: any) => {
      return item?.type === "payout"
   })

   const activePaymentCard = AllPayinCards?.find((item: any) => {
      return item.status === "new";
   })

   const activePayoutCard = AllPayinCards?.find((item: any) => {
      return item.status === "new";
   })

   if (!user) return null;
   return (
      <div className={styles.container}>
         <div className={styles.firstLine}>
            <div className={styles.photoAndName}>
               <img className={styles.photo} src={props.avatar} alt='' />
               <h4
                  className={styles.name}
                  onClick={() => {
                     commonState.location = routes.lk.lkStudent + "/" + user.id;
                  }}
               >
                  {user.first_name} {user.last_name}
               </h4>
            </div>
            <div className={styles.header_info}>
               <h4
                  className={styles.name}
                  onClick={() => {
                      commonState.location = routes.lk.lkStudent + "/" + user.id;
                  }}
               >
                  {user.first_name} {user.last_name}
               </h4>
               <div className={styles.bottom}>
                  <div className={styles.header_item}>
                     <p className={styles.header_title}>id</p>
                     <p className={styles.header_text}>{user.id}</p>
                  </div>
                  <div
                     className={[
                        styles.header_item,
                        styles.date_registration,
                     ].join(" ")}
                  >
                     <p className={styles.header_title}>
                        {t(
                           "Lk.AdminPanel.Table.registration-date",
                           "Дата регистрации"
                        )}
                     </p>
                     <p className={styles.header_text}>
                        {showDateDMY(user.registration_date)}
                     </p>
                  </div>
                  <div className={styles.header_item}>
                     <p className={styles.header_title}>
                        {t("Lk.AdminPanel.Table.birth-date", "Дата рождения")}
                     </p>
                     <p className={styles.header_text}>
                        {showDateDMY(user.birth_date)}
                     </p>
                  </div>
                  <div className={styles.header_item}>
                     <p className={styles.header_title}>
                        {t("Lk.AdminPanel.Table.status", "Статус")}
                     </p>
                     <p className={styles.header_text}>
                        {user.type === "inactive_teacher" ||
                        user.type === "student" ||
                        user.is_blocked
                           ? t("Lk.AdminPanel.Table.student", "Студент")
                           : t(
                                "Lk.ScheduleFilter.filter.title-teacher",
                                "Преподаватель"
                             )}
                     </p>
                  </div>
                  <div className={styles.header_item}>
                     <p className={styles.header_title}>email</p>
                     <p className={styles.header_text}>{user.email || "—"}</p>
                  </div>
               </div>
            </div>
         </div>
         <div className={styles.second}>
            <div className={styles.title}>
               <Rating />
               <b>{user.study_rating}</b>{" "}
               {t("Lk.AdminPanel.Table.student", "Студент")}
            </div>
            <div className={styles.second_item}>
               <p className={styles.header_title}>
                  {t(
                     "Lk.AdminPanel.Table.registration-date",
                     "Дата регистрации"
                  )}
               </p>
               <p className={styles.header_text}>
                  {showDateDMY(user.registration_date)}
               </p>
            </div>
            <div className={styles.second_item}>
               <p className={styles.header_title}>
                  {t(
                     "Lk.AdminPanel.Table.studied-subjects",
                     "Изучаемые предметы"
                  )}
               </p>
               <p className={styles.header_text}>
                  {user.study_subjects?.length
                     ? user.study_subjects
                          .map((subject: any) => {
                             return subject.name;
                          })
                          .join(", ")
                     : "—"}
               </p>
            </div>
            <div className={styles.second_item}>
               <p className={styles.header_title}>
                  {t(
                     "Notebook.headers.education_systems",
                     "Системы образования"
                  )}
               </p>
               <p className={styles.header_text}>
                  {user.study_education_system?.name || "—"}
               </p>
            </div>
            <div className={styles.second_item}>
               <p className={styles.header_title}>
                  {t("Lk.AdminPanel.Table.grades", "Грейды")}
               </p>
               <p className={styles.header_text}>
                  {user.study_grade?.id || "—"}
               </p>
            </div>
         </div>
         {user.type === "teacher" && !user.is_blocked && (
            <div className={styles.third}>
               <div className={styles.title}>
                  <Rating />
                  <b>{user.teaching_rating}</b>
                  {t("Lk.ScheduleFilter.filter.title-teacher", "Преподаватель")}
               </div>
               <div className={styles.second_item}>
                  <p className={styles.header_title}>
                     {t(
                        "Lk.Notifications.Date.student-to-inactive",
                        "Дата подачи заявки"
                     )}
                  </p>
                  <p className={styles.header_text}>
                     {showDateDMY(user.inactive_teacher_created_date)}
                  </p>
               </div>
               <div className={styles.second_item}>
                  <p className={styles.header_title}>
                     {t("Lk.AdminPanel.Table.grades", "Грейды")}
                  </p>
                  <p className={styles.header_text}>
                     {user.instruction_grades.map((item: gradeType) => (
                        <div>{item.name}</div>
                     ))}
                  </p>
               </div>
               <div className={styles.second_item}>
                  <p className={styles.header_title}>
                     {t(
                        "Notebook.headers.education_systems",
                        "Системы образования"
                     )}
                  </p>
                  <p className={styles.header_text}>
                     {user.instruction_education_systems?.map((item) => {
                        return <div>{item?.name}</div>;
                     })}
                  </p>
               </div>
               <div className={styles.second_item}>
                  <p className={styles.header_title}>
                     {t(
                        "Lk.AdminPanel.Table.subjects-to-teach",
                        "Предметы для преподавания"
                     )}
                  </p>
                  <p className={styles.header_text}>
                     {user.instruction_subjects?.map((item) => {
                        return <div>{item.name}</div>;
                     })}
                  </p>
               </div>
            </div>
         )}
         <div className={styles.cards}>
            <div className={styles.cards_title}>
               {t(
                  "Lk.AdminPanel.Table.cards-for-payment.titles.cards-for-payment",
                  "Карты для оплаты"
               )}
            </div>
            {(activePaymentCard && props.allCards) ?
                <div className={styles.cards_items}>
                   {AllPayinCards?.map((item:any) => <CardItem item={item} number={item?.pan} date={item?.created_at} type={item?.mps}/>)}
                </div>
                  :
                <div className={styles.cards_items_none}>У вас пока нет карт для оплаты</div>
            }
            {user.type === "teacher" && (
               <>
                  <div className={styles.cards_title}>
                     {t(
                        "Lk.AdminPanel.Table.cards-for-payment.titles.cards-for-payout",
                        "Карты для выплат"
                     )}
                  </div>
                  {/*<div className={styles.cards_items}>*/}
                  {/*   {props.allCards?.map((item: any) => <CardItem item={item} number={item?.pan} date={item?.created_at} type={item?.mps}/>)}*/}
                  {/*</div>*/}
                  {(activePayoutCard && props.allCards) ?
                      <div className={styles.cards_items}>
                         {AllPayoutCards?.map((item:any) => <CardItem item={item} number={item?.pan} date={item?.created_at} type={item?.mps}/>)}
                      </div>
                      :
                      <div className={styles.cards_items_none}>У вас пока нет карт для выплат</div>
                  }
               </>
            )}
            <div className={styles.requisites}>
               <div className={styles.cards_title}>
                  {t(
                    "",
                    "Реквизиты для зачисления денег"
                  )}
               </div>
               <div className={styles.cards_items}>
                  <div className={styles.cards_item}>
                     <div className={styles.info_title}>
                        {t(
                          "",
                          "Номер банковского счета"
                        )}
                     </div>
                     <div className={styles.info_record}>12344565564234</div>
                  </div>
                  <div className={styles.cards_item}>
                     <div className={styles.info_title}>
                        {t(
                          "",
                          "Маршрутный транзитный номер"
                        )}
                     </div>
                     <div className={styles.info_record}>08200510</div>
                  </div>
               </div>
               <div className={styles.cards_title}>
                  {t(
                    "",
                    "Подробности адреса"
                  )}
               </div>
               <div className={styles.cards_items}>
                  <div className={styles.cards_item}>
                     <div className={styles.info_title}>
                        {t(
                          "",
                          "Recipient country"
                        )}
                     </div>
                     <div className={styles.info_record}>United States</div>
                  </div>
                  <div className={styles.cards_item}>
                     <div className={styles.info_title}>
                        {t(
                          "",
                          "State or province"
                        )}
                     </div>
                     <div className={styles.info_record}>New Mexico</div>
                  </div>
                  <div className={styles.cards_item}>
                     <div className={styles.info_title}>
                        {t(
                          "",
                          "Address line 1"
                        )}
                     </div>
                     <div className={styles.info_record}>Negra Arroyo Lane</div>
                  </div>
                  <div className={styles.cards_item}>
                     <div className={styles.info_title}>
                        {t(
                          "",
                          "Address line 2"
                        )}
                     </div>
                     <div className={styles.info_record}>308</div>
                  </div>
                  <div className={styles.cards_item}>
                     <div className={styles.info_title}>
                        {t(
                          "",
                          "City"
                        )}
                     </div>
                     <div className={styles.info_record}>Albuquerque</div>
                  </div>
                  <div className={styles.cards_item}>
                     <div className={styles.info_title}>
                        {t(
                          "",
                          "Postal code"
                        )}
                     </div>
                     <div className={styles.info_record}>87002</div>
                  </div>
               </div>
            </div>
            <div className={styles.cards_button}>
               <Button className={styles.button_save} disabled={true}>
                  {t("Buttons.save", "Сохранить")}
               </Button>
            </div>
         </div>
         <div className={styles.files}>
            <div className={styles.title}>
               {t("Lk.AdminPanel.Table.files", "Файлы")}
            </div>
            <div className={styles.second_item}>
               <p className={styles.header_title}>
                  {t("Settings.form.student.title", "Анкета студента")}
               </p>
               {user.files.filter(
                  (item: any) => item.file_type === "education_document"
               ).length > 0 ? (
                  user.files
                     .filter(
                        (item: any) => item.file_type === "education_document"
                     )
                     .map((file: any, index: number) => (
                        <FileDownloader
                           user_id={user.id}
                           file={file}
                           className={styles.file}
                        />
                     ))
               ) : (
                  <p className={styles.header_text}>-</p>
               )}
            </div>

            {(user.type === "teacher" || user.type === "inactive_teacher") &&
               !user.is_blocked && (
                  <>
                     <div className={styles.second_item}>
                        <p className={styles.header_title}>
                           Passport / National ID
                        </p>
                        {user.files.filter(
                           (item: any) => item.file_type === "passport"
                        ).length > 0 ? (
                           user.files
                              .filter(
                                 (item: any) => item.file_type === "passport"
                              )
                              .map((file: any, index: number) => (
                                 <FileDownloader
                                    user_id={user.id}
                                    file={file}
                                    className={styles.file}
                                 />
                              ))
                        ) : (
                           <p className={styles.header_text}>-</p>
                        )}
                     </div>
                     <div className={styles.second_item}>
                        <p className={styles.header_title}>
                           School Transcript (Grades)
                        </p>
                        {user.files.filter(
                           (item: any) => item.file_type === "school_transcript"
                        ).length > 0 ? (
                           user.files
                              .filter(
                                 (item: any) =>
                                    item.file_type === "school_transcript"
                              )
                              .map((file: any, index: number) => (
                                 <FileDownloader
                                    user_id={user.id}
                                    file={file}
                                    className={styles.file}
                                 />
                              ))
                        ) : (
                           <p className={styles.header_text}>-</p>
                        )}
                     </div>
                  </>
               )}
         </div>
         <div className={styles.buttons}>
            <div className={styles.buttons_left}>
               {user.type === "teacher" && !user.is_blocked && !user.is_banned && (
                  <Button
                     loading={commonState.loading}
                     variant={"outline"}
                     onClick={teacherToUser}
                  >
                     <ErrorIcon style={{ height: "20px", width: "20px" }} />
                     {t(
                        "Buttons.unmake-teacher",
                        "Убрать статус преподавателя"
                     )}
                  </Button>
               )}
            </div>
            <div className={styles.buttons_right}>
               {myUser.role === "superadmin" && !user.is_banned && (
                  <>
                     {user.role === "user" && (
                        <Button onClick={userToAdmin} variant={"decision"}>
                           <Agree style={{ marginRight: "9px" }} />
                           {t("Buttons.make-admin", "Сделать Администратором")}
                        </Button>
                     )}
                     {user.role === "admin" && (
                        <Button variant={"outline"} onClick={adminToUser}>
                           <ErrorIcon
                              style={{ height: "20px", width: "20px" }}
                           />
                           {t("Buttons.unmake-admin", "Убрать роль админа")}
                        </Button>
                     )}
                  </>
               )}
               {user.is_banned ? (
                  <div
                     style={{
                        color: "var(--color-warning)",
                     }}
                     className={styles.button_mock}
                  >
                     {t("Buttons.Common.banned", "Пользователь заблокирован")}
                  </div>
               ) : (
                  <Button
                     loading={commonState.loading}
                     onClick={blockUser}
                     variant={"decision"}
                  >
                     <Block style={{ marginRight: "9px" }} />
                     {t("Buttons.block-user", "Заблокировать пользователя")}
                  </Button>
               )}
            </div>
         </div>
      </div>
   );
};
