import { AxiosResponse } from "axios";

import {
  callbacksType,
  requestMethodType,
  statusHandlersType,
} from "../types/types";
import { useLocalCommonStateType } from "../hooks/useLocalCommonState";
import { methodResolver } from "../../constructor";

type responseHandlerType<T> = {
  response: AxiosResponse<any>;
  commonState: useLocalCommonStateType<T>;
  statusHandlers: statusHandlersType;
  callbacks?: callbacksType;
  request: () => Promise<AxiosResponse<any>>;
  requestMethod?: requestMethodType;
};

type responseHandlerConstructorType = {
  nonResponseCallback: () => void;
};

export function responseHandlerConstructor({
  nonResponseCallback,
}: responseHandlerConstructorType) {
  return async function <T>(props: responseHandlerType<T>) {
    const {
      response,
      commonState,
      statusHandlers,
      callbacks,
      request,
      requestMethod,
    } = props;

    /* break if request was canceled */
    if (!response) {
      nonResponseCallback();
      return;
    }

    /* define behavior */
    methodResolver({
      response,
      statusHandlers,
      callbacks,
      commonState,
      request,
      requestMethod,
    });

    return response;
  };
}
