// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateSlotOrLesson_switcher__2zKCt {\n\n}\n\n@media screen and (max-width: 1023px) {\n    .CreateSlotOrLesson_switcher__2zKCt {\n        justify-content: center;\n        width: 100%;\n    }\n    .CreateSlotOrLesson_container__2JGiv {\n        margin-top: 0px;\n    }\n}\n\n@media screen and (max-width: 719px) {\n\n}", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/LessonsService/CreateSlotOrLesson/index.module.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI;QACI,uBAAuB;QACvB,WAAW;IACf;IACA;QACI,eAAe;IACnB;AACJ;;AAEA;;AAEA","sourcesContent":[".switcher {\n\n}\n\n@media screen and (max-width: 1023px) {\n    .switcher {\n        justify-content: center;\n        width: 100%;\n    }\n    .container {\n        margin-top: 0px;\n    }\n}\n\n@media screen and (max-width: 719px) {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switcher": "CreateSlotOrLesson_switcher__2zKCt",
	"container": "CreateSlotOrLesson_container__2JGiv"
};
export default ___CSS_LOADER_EXPORT___;
