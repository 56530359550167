import { getAllCards } from "api/adminApi/types";
import { lkApi } from "api/LkApi/methods";
import { useQuery } from "react-query";

export const useGetCard = (type?: getAllCards) => {
    const { isLoading, data, error } = useQuery(
        "useGetCard",
        () => lkApi.getCard({
            type
        }),
        {
            // cacheTime: 10000,
        }
    );

    return {
        data: data?.data?.data,
        pan: data?.data?.data?.pan,
        isLoading,
        error,
    };
}
