import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { ListButton } from "components/UI/ListButton";
import { ReactComponent as AttachIcon } from "./assets/Attach.svg";
import { groupSubjectsByEducationSystem } from "./helpers/inputGroupBySystem";
import { useTranslation } from "react-i18next";
import { subjectType } from "store/types";
import { Control, useWatch } from "react-hook-form";

type propsType = {
   setValue: any;
   control: Control<any>;
   subjects: Array<subjectType>;
};
export const SelectedSubjects: FC<propsType> = ({ setValue, control }) => {
   const { t } = useTranslation();
   const [isOpen, setIsOpen] = useState(false);
   const [count, setCount] = useState(0);
   const [grouped, setGrouped] = useState<Array<any>>([]);

   const subjects = useWatch({
      control,
      name: "subjects",
   });
   useEffect(() => {
      if (!subjects?.length) return;
      let resultSubjects: Array<subjectType> = [];
      subjects.map((item: any) => {
         if (item !== false && item !== undefined) {
            resultSubjects.push(JSON.parse(item));
         }
      });
      setGrouped(groupSubjectsByEducationSystem(resultSubjects));
      setCount(resultSubjects.length);
   }, [subjects]);
   return (
      <div className={styles.container}>
         {isOpen && (
            <div className={styles.list}>
               <button
                  className={styles.rollup}
                  onClick={() => setIsOpen(false)}
               >
                  {t("Buttons.collapse", "Свернуть")}
               </button>
               <div className={styles.items}>
                  {grouped?.map((group, groupIndex) => {
                     return (
                        <div>
                           <p className={styles.label}>
                              {group.group.includes("Elementary School")
                                 ? "Elementary School"
                                 : group.group}
                           </p>
                           {group.subjects.map((subject: any) => {
                              return (
                                 <div className={styles.item} key={subject.id}>
                                    {subject.name}
                                    <AttachIcon
                                       className={styles.icon}
                                       onClick={() => {
                                          setValue(
                                             "subjects" + `.${subject.id}`,
                                             false
                                          );
                                       }}
                                    />
                                 </div>
                              );
                           })}
                        </div>
                     );
                  })}
               </div>
            </div>
         )}
         <div
            className={styles.button}
            // onClick={() => {
            //    indexes.length > 0 && setIsOpen(!isOpen);
            //    // props.setValue("subjects" + `.${0}`, false);
            // }}
         >
            <ListButton
               setIsOpen={setIsOpen}
               isOpen={isOpen}
               title={t(
                  "UI.SelectedSubjects.selected-count",
                  "Выбранные предметы"
               )}
               counter={count}
            />
         </div>
      </div>
   );
};
