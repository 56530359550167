import React, { FC } from "react";
import styles from "./index.module.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../../../../../../UI/Button";
import { dateMethods } from "helpers/date";
import { useDeleteCard } from "hooks/useDeleteCard";

type PropsType = {
  item?: any;
  number: string;
  date: string;
  type: string;
};

export const CardItem: FC<PropsType> = ({ number, date, type, item }) => {

  const { t } = useTranslation();

  const { deleteCard, isLoading, error } = useDeleteCard(item?.id)

  return (
    <>
      {item?.status !== "deleted" &&
          <div className={styles.card_info}>
              <div className={styles.info_item}>
                  <div className={styles.info_title}>
                    {t(
                      "Lk.AdminPanel.Table.cards-for-payment.headers.card-number",
                      "Номер карты"
                    )}
                  </div>
                  <div className={styles.info_record}>{number}</div>
              </div>
              <div className={styles.info_item}>
                  <div className={styles.info_title}>
                    {t(
                      "Lk.AdminPanel.Table.cards-for-payment.headers.card-expiry-date",
                      "Дата добавления"
                    )}
                  </div>
                  <div className={styles.info_record}>{dateMethods.format(date, "MM.YYYY")}</div>
              </div>
              <div className={styles.info_item}>
                  <div className={styles.info_title}>
                    {t(
                      "Lk.AdminPanel.Table.cards-for-payment.headers.payment-system-type",
                      "Тип платежной системы"
                    )}
                  </div>
                  <div className={styles.info_record}>{type ? type : " - "}</div>
              </div>
              <div className={styles.item_button}>
                  <Button
                      onClick={deleteCard}
                      loading={isLoading}
                      className={styles.button}
                      variant={"red"}
                  >{t("Buttons.remove", "Отвязать")}
                  </Button>
              </div>
          </div>
      }
    </>
  )
}
