import React, { FC } from "react";
import styles from "./index.module.css";
import { SelectUnderControl } from "components/UI/Select/SelectUnderControl";
import moment from "moment-timezone";
import { Calendar } from "components/UI/Calendar";
import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form";
import cn from "classnames";
import {
   renderDurationOptions,
   renderStartTimeOptions,
} from "helpers/selectOptions";
import { dateMethods } from "helpers/date";
import { useTranslation } from "react-i18next";
import { Input } from "components/UI/Input";

type propsType = {
   control: Control<any>;
   setValue: UseFormSetValue<any>;
   hideDate?: boolean;
   dateFrom?: string;
   dateTo?: string;
   errors?: any;
   variant?: "row_1" | "row_2";
};
export const DateAndTimePicker: FC<propsType> = ({
   setValue,
   control,
   hideDate,
   children,
   variant = "2_row",
   errors,
   dateFrom,
}) => {
   const { t } = useTranslation();
   const bottomBoundaries = Number(
      dateFrom && dateMethods.format(dateFrom, "HH")
   );

   return (
      <div
         className={cn(styles.container, {
            [`${styles[variant]}`]: true,
         })}
      >
         {children}
         {!hideDate && (
            <Calendar
               className={styles.calendar}
               control={control}
               minDate={moment().toDate()}
               error={
                  errors?.date_from?.message
                     ? errors?.date_from?.message
                     : errors?.date_from
               }
               name='date'
               placeholder={t("UI.DateAndTimePicker.date", "Дата")}
            />
         )}
         <SelectUnderControl
            name='date_from'
            setValue={setValue}
            searchField
            options={renderStartTimeOptions()}
            control={control}
            label={t("UI.DateAndTimePicker.date-from", "Начало")}
            error={errors?.date_from?.message}
            className={styles.date_from}
         />
         <SelectUnderControl
            name='duration'
            setValue={setValue}
            options={renderDurationOptions()}
            control={control}
            error={errors?.date_from?.message}
            label={t("UI.DateAndTimePicker.duration", "Длительность")}
            className={styles.duration}
         />
      </div>
   );
};
