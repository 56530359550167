// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_main__3s7Ou {\n    padding: 20px;\n}\n.style_main__value__2dUQm {\n    font-size: 18px;\n    margin-bottom: 4px;\n}\n\n@media screen and (max-width: 719px) {\n    .style_main__3s7Ou {\n        padding: 14px;\n    }\n}\n\n", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/Settings/Worksheet/TeacherWorksheet/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".main {\n    padding: 20px;\n}\n.main__value {\n    font-size: 18px;\n    margin-bottom: 4px;\n}\n\n@media screen and (max-width: 719px) {\n    .main {\n        padding: 14px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "style_main__3s7Ou",
	"main__value": "style_main__value__2dUQm"
};
export default ___CSS_LOADER_EXPORT___;
