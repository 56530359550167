import React, { AllHTMLAttributes, FC } from "react";
import styles from "./index.module.css";
import ReactPaginate, { ReactPaginateProps } from "react-paginate";

export type PaginateType = {
   pageCount: number;
   onPageActive: (n: { selected: number }) => void;
   onPageChange: (n: { selected: number }) => void;
} & AllHTMLAttributes<HTMLDivElement>;

export const Paginate: FC<PaginateType> = (props) => {
   const { className, ...other } = props;

   return (
      <div className={className}>
         <ReactPaginate
            containerClassName={styles.container}
            previousClassName={styles.none}
            previousLabel={"<"}
            pageClassName={styles.button}
            nextClassName={styles.none}
            breakClassName={styles.button}
            activeClassName={styles.button_selected}
            nextLabel={">"}
            {...other}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
         />
      </div>
   );
};
