import React, {FC, useEffect, useState} from 'react'
import style from "./style.module.css";
import {ReactComponent as ArrowIcon} from "../../assets/Arrow.svg";
import {useTranslation} from "react-i18next";
import {routes} from "../../../../../../routes";
import {Link} from "react-router-dom"

type propsType = any
export const BackButton: FC<propsType> = ({closeGenerated}) => {
   const {t} = useTranslation()

   return (
      <Link to={routes.lk.statistic.root}>
         <button
            className={style.statistic_button}
         >
            <ArrowIcon className={style.button_arrow}/>{" "}
            {t("Lk.Statistic.arrow-button-label", "Статистика")}
         </button>
      </Link>
   )
}