import { defaultFileType } from "api/types";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

type uploadFilesType = {
   apiMethod: (data: any) => Promise<any>;
   files: Array<defaultFileType>;
   callback: () => void;
};
export const uploadFiles = (props: uploadFilesType) => {
   if (!props.files?.length) {
      props.callback();
      return;
   }
   let arrayOfPromises: Array<Promise<any>> = [];
   props.files.forEach((item) => {
      arrayOfPromises.push(props.apiMethod(item));
   });

   Promise.all(arrayOfPromises).then((res) => {
      props.callback();
   });
};
export const fileErrorTypeAlert = (file: any, accept?: string) => {
   if (
      file &&
      accept?.includes(file.type.split("/").pop()) &&
      file.type.split("/").pop() != ""
   ) {
      return true;
   } else if (accept) {
      alert(i18n.t("UI.file-type-alert", "Выберите правильный формат файла"));
      return false;
   }
};
