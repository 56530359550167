// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_invite_body__iKpU3 {\n    max-width: 323px;\n}\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n\n}\n\n@media screen and (max-width: 1023px) {\n    .style_container__3wdWN {\n        width: 100%;\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        margin-left: 24px;\n    }\n}\n\n@media screen and (max-width: 719px) {\n    .style_invite_body__iKpU3 {\n        max-width: 425px;\n        width: 100%;\n    }\n    .style_container__3wdWN {\n        margin-left: 0px;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/LessonsService/Pages/InvitePage/style.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;;AAEA;;AAEA;;AAEA;;AAEA;IACI;QACI,WAAW;QACX,aAAa;QACb,sBAAsB;QACtB,mBAAmB;QACnB,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,gBAAgB;QAChB,WAAW;IACf;IACA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".invite_body {\n    max-width: 323px;\n}\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n\n}\n\n@media screen and (max-width: 1023px) {\n    .container {\n        width: 100%;\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        margin-left: 24px;\n    }\n}\n\n@media screen and (max-width: 719px) {\n    .invite_body {\n        max-width: 425px;\n        width: 100%;\n    }\n    .container {\n        margin-left: 0px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"invite_body": "style_invite_body__iKpU3",
	"container": "style_container__3wdWN"
};
export default ___CSS_LOADER_EXPORT___;
