import { SelectItemType } from "components/UI/Select/types";
import { slotType } from "types/LessonService/types";
import moment from "moment-timezone";

export const generateSelectTimeOptionsByGroupOfSlots = function (group: any) {
   if (!group?.value) return [];
   let options: Array<
      SelectItemType<{
         time: string;
         slot: slotType;
         rawTime: string;
      }>
   > = [];
   group?.value?.elements?.map((slot: slotType, index: number) => {
      const dateFromMoment = moment(slot.date_from);
      const countOfSteps = Math.abs(
         moment(slot.date_from).diff(slot.date_to, "minutes") / 15
      );

      for (let i = 0; i < countOfSteps; i++) {
         const timeForOptionRaw = dateFromMoment.add(
            Number(!!i) * 15,
            "minutes"
         );
         const timeForOption = timeForOptionRaw.format("HH:mm");

         options.push({
            // @ts-ignore
            id: timeForOption,
            text: timeForOption,
            value: {
               time: timeForOption,
               rawTime: timeForOptionRaw.format(),
               slot,
            },
         });
      }
   });

   const sortedOptions = options.sort((a, b) => {
      const aMoment = moment(a.value.rawTime);
      const bMoment = moment(b.value.rawTime);

      if (Number(aMoment.isAfter(bMoment))) {
         return 1;
      }
      if (Number(aMoment.isBefore(bMoment))) {
         return -1;
      }
      return 0;
   });

   return sortedOptions;
};
