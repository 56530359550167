/* Languages */
import { ReactComponent as English } from "./languageIcons/English.svg";
import { ReactComponent as Russian } from "./languageIcons/Russia.svg";
import { ReactComponent as Spanish } from "./languageIcons/Spain.svg";
import { ReactComponent as Arabic } from "./languageIcons/Arabic.svg";
import { ReactComponent as Catalan } from "./languageIcons/Catalan.svg";
import { ReactComponent as Chinese } from "./languageIcons/Chinise.svg";
import { ReactComponent as Dutch } from "./languageIcons/Dutch.svg";
import { ReactComponent as German } from "./languageIcons/German.svg";
import { ReactComponent as Greek } from "./languageIcons/Greek.svg";
import { ReactComponent as Hebrew } from "./languageIcons/Hebrew.svg";
import { ReactComponent as Hindi } from "./languageIcons/Hindi.svg";
import { ReactComponent as Norwegian } from "./languageIcons/Norsk.svg";
import { ReactComponent as Italian } from "./languageIcons/Italian.svg";
import { ReactComponent as Japanese } from "./languageIcons/Japanise.svg";
import { ReactComponent as Portuguese } from "./languageIcons/Portug.svg";
import { ReactComponent as Swedish } from "./languageIcons/Swedish.svg";
import { ReactComponent as Korean } from "./languageIcons/Korean.svg";
import { ReactComponent as French } from "./languageIcons/Franch.svg";

/* Levels */
import { ReactComponent as ElementarySchool } from "./esIcons/Elementary.svg";
import { ReactComponent as MiddleSchool } from "./esIcons/Middle.svg";
import { ReactComponent as HighSchool } from "./esIcons/High.svg";

/* UI */
import { ReactComponent as ArrowDropdown } from "./uiIcons/arrowDropdown.svg";
import { ReactComponent as likeIconEmpty } from "./uiIcons/likeIconEmpty.svg";
import { ReactComponent as likeIconFilled } from "./uiIcons/likeIconFIlled.svg";
import { ReactComponent as messageIcons } from "./uiIcons/messageIcons.svg";
import { ReactComponent as burgerIcon } from "./uiIcons/Burger.svg";
import { ReactComponent as cancelIcon } from "./uiIcons/Cancel.svg";
import { ReactComponent as searchIcon } from "./uiIcons/Search.svg";
import { ReactComponent as clockIcon } from "./uiIcons/clockIcon.svg";
import { ReactComponent as calendarIcon } from "./uiIcons/calendar.svg";
import { ReactComponent as Preloader } from "./loaders/preloader.svg";
import { ReactComponent as HourglassIcon } from "./uiIcons/Hourglass.svg";
import { ReactComponent as RejectIcon } from "./uiIcons/Reject.svg";
import { ReactComponent as AlarmClockIcon } from "./uiIcons/AlarmClock.svg";
import { ReactComponent as FilterIcon } from "./uiIcons/filterIcon.svg";
import { ReactComponent as Microphone } from "./uiIcons/Microphone.svg";
import { ReactComponent as RepeatIcon } from "./uiIcons/Repeat.svg";
import { ReactComponent as arrowTriangleIcon } from "./uiIcons/arrowTriangle.svg";
import { ReactComponent as documentIcon } from "./uiIcons/document.svg";
import { ReactComponent as closeIcon } from "./uiIcons/closeIcon.svg";
import { ReactComponent as complaintIcon } from "./uiIcons/complaintIcon.svg";
import { ReactComponent as starFilled } from "./uiIcons/startIconFilled.svg";
import { ReactComponent as TeacherHat } from "./uiIcons/TeacherHat.svg";
import { ReactComponent as CrossMenuIcon } from "./uiIcons/MenuCross.svg";
import { ReactComponent as Upload } from "./uiIcons/Upload.svg";
/* Logo */
import { ReactComponent as logoFull } from "./logos/Logo_full.svg";
import { ReactComponent as logoSmall } from "./logos/logoSmall.svg";

/* Avatars */
import { ReactComponent as TeacherAvatar } from "./avatars/TeacherAvatar.svg";

// Social
import { ReactComponent as EmailIcon } from "assets/iconsCorrect/social/Email.svg";
import { ReactComponent as InstIcon } from "assets/iconsCorrect/social/Inst.svg";
import { ReactComponent as YouTubeIcon } from "assets/iconsCorrect/social/YouTube.svg";
import { ReactComponent as LinkdIcon } from "assets/iconsCorrect/social/Linkd.svg";

// Buttons
import { ReactComponent as DownArrowSelect } from "./buttons/DownArrowSelect.svg";
import { ReactComponent as MenuAdminSettings } from "./buttons/MenuAdminSettings.svg";
import { ReactComponent as MenuClassesIcon } from "./buttons/MenuClassesIcon.svg";
import { ReactComponent as MenuLkIcon } from "./buttons/MenuLkIcon.svg";
import { ReactComponent as MenuLogoutIcon } from "./buttons/MenuLogoutIcon.svg";
import { ReactComponent as MenuMessagesIcon } from "./buttons/MenuMessagesIcon.svg";
import { ReactComponent as MenuNotebookIcon } from "./buttons/MenuNotebookIcon.svg";
import { ReactComponent as MenuScheduleIcon } from "./buttons/MenuScheduleIcon.svg";
import { ReactComponent as MenuSearchIcon } from "./buttons/MenuSearchIcon.svg";
import { ReactComponent as MenuStatisticButton } from "./buttons/MenuStatisticButton.svg";
import { ReactComponent as SendMessageIcon } from "./buttons/SendMessageIcon.svg";
import { ReactComponent as SendRequest } from "./buttons/SendRequest.svg";
import { ReactComponent as DownloadIcon } from "./buttons/download.svg";
import { ReactComponent as ApproveButtonIcon } from "./buttons/Approve.svg";
import { ReactComponent as RejectButtonIcon } from "./buttons/Reject.svg";
import { ReactComponent as LogoutIcon } from "./buttons/LogoutIcon.svg";
import { ReactComponent as DeleteIcon } from "./buttons/DeleteIcon.svg";
import { ReactComponent as UndoIcon } from "./buttons/UndoIcon.svg";
import { ReactComponent as CirclePlusIcon } from "../buttons/CirclePlus.svg";
import { ReactComponent as CircleEditIcon } from "../buttons/CircleEdit.svg";

// Inputs
import { ReactComponent as MastersAvatar } from "./inputs/Masters/Avatar.svg";
import { ReactComponent as Attach } from "./inputs/Attach.svg";
import { ReactComponent as Avatar } from "./inputs/Avatar.svg";
import { ReactComponent as CheckBoxChecked } from "./inputs/CheckBoxChecked.svg";
import { ReactComponent as CheckBoxSVG } from "./inputs/CheckBoxSVG.svg";
import { ReactComponent as CheckMark } from "./inputs/CheckMark.svg";
import { ReactComponent as InputLoader } from "./inputs/InputLoader.svg";
import { ReactComponent as InputLock } from "./inputs/InputLock.svg";
import { ReactComponent as PassInvis } from "./inputs/PassInvis.svg";
import { ReactComponent as PassVis } from "./inputs/PassVis.svg";
import { ReactComponent as PhonePencil } from "./inputs/PhonePencil.svg";
import { ReactComponent as RadioOff } from "./inputs/RadioOff.svg";
import { ReactComponent as RadioOn } from "./inputs/RadioOn.svg";
import { ReactComponent as TextCheck } from "./inputs/TextCheck.svg";
import { ReactComponent as TextEdit } from "./inputs/TextEdit.svg";
import { ReactComponent as TextLoad } from "./inputs/TextLoad.svg";
import { ReactComponent as TextLock } from "./inputs/TextLock.svg";
import { ReactComponent as TrashBinFill } from "./inputs/TrashBinFill.svg";
import { ReactComponent as TrashBinHover } from "./inputs/TrashBinHover.svg";

// Status
import { ReactComponent as ErrorStatusIcon } from "./status/error.svg";
import { ReactComponent as SuccessStatusIcon } from "./status/success.svg";

export const Icons = {
   languageFlags: {
      English,
      Russian,
      Spanish,
      Arabic,
      Catalan,
      Chinese,
      Dutch,
      German,
      Greek,
      Hebrew,
      Hindi,
      Norwegian,
      Italian,
      Japanese,
      Portuguese,
      Swedish,
      Korean,
      French,
   },
   levelIcons: {
      "Elementary School": ElementarySchool,
      "Middle School": MiddleSchool,
      "High School": HighSchool,
   },
   ui: {
      ArrowDropdown,
      likeIconEmpty,
      likeIconFilled,
      messageIcons,
      burgerIcon,
      cancelIcon,
      searchIcon,
      clockIcon,
      calendarIcon,
      HourglassIcon,
      RejectIcon,
      AlarmClockIcon,
      FilterIcon,
      Preloader,
      Microphone,
      RepeatIcon,
      arrowTriangleIcon,
      documentIcon,
      closeIcon,
      complaintIcon,
      starFilled,
      TeacherHat,
      CrossMenuIcon,
      Upload,
   },
   logos: {
      logoFull,
      logoSmall,
   },
   avatars: {
      TeacherAvatar,
   },
   social: {
      EmailIcon,
      InstIcon,
      YouTubeIcon,
      LinkdIcon,
   },
   buttons: {
      DownArrowSelect,
      MenuAdminSettings,
      MenuClassesIcon,
      MenuLkIcon,
      MenuLogoutIcon,
      MenuMessagesIcon,
      MenuNotebookIcon,
      MenuScheduleIcon,
      MenuSearchIcon,
      MenuStatisticButton,
      SendMessageIcon,
      SendRequest,
      DownloadIcon,
      ApproveButtonIcon,
      RejectButtonIcon,
      LogoutIcon,
      DeleteIcon,
      UndoIcon,
      CirclePlusIcon,
      CircleEditIcon,
   },
   inputs: {
      masters: {
         MastersAvatar,
      },
      Attach,
      Avatar,
      CheckBoxChecked,
      CheckBoxSVG,
      CheckMark,
      InputLoader,
      InputLock,
      PassInvis,
      PassVis,
      PhonePencil,
      RadioOff,
      RadioOn,
      TextCheck,
      TextEdit,
      TextLoad,
      TextLock,
      TrashBinFill,
      TrashBinHover,
   },
   status: {
      ErrorStatusIcon,
      SuccessStatusIcon,
   },
};
