import React, { FC, useEffect } from "react";
import styles from "./index.module.css";
import { Prompt } from "components/UI/Prompt";
import { RadioInput } from "components/UI/Input/RadioInput";
import { Button } from "components/UI/Button";
import { useForm, useWatch } from "react-hook-form";
import { ContentBlockContainer } from "components/UI/ContentBlockContainer";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { lessonThunk } from "store/lessonSlice/thunk";
import { authSliceSelectors } from "store/authSlice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { uiSlice } from "store/modalWindowSlice";
import { Calendar } from "components/UI/Calendar";
import { SelectUnderControl } from "components/UI/Select/SelectUnderControl";
import { renderStartTimeOptions } from "helpers/selectOptions";
import moment from "moment-timezone";
import * as yup from "yup";
import i18n from "services/translateService/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { showDateYMDNative } from "helpers/date/showDate";
import { InfoBlock } from "../../../../../UI/InfoBlock";
import { Board } from "../../../../../UI/Board";
import { getTimeFromTo } from "../../../../../../helpers/time";
import { useGetPrice } from "hooks/useGetPrice";
import { Preloader } from "components/UI/Preloader";

type FormType = {
   start_hours: any;
   date: any;
   end_hours: any;
   periodicity: any;
} & {
   [key: string]: any;
};

const scheme = yup.object().shape({
   start_hours: yup
      .object()
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      ),
   end_hours: yup
      .object()
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      ),
   date: yup
      .string()
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      ),
});

export const CreateSlot: FC = () => {
   const { t } = useTranslation();
   const {
      control,
      setValue,
      handleSubmit,
      formState: { errors },
   } = useForm<FormType>({
      // @ts-ignore
      resolver: yupResolver(scheme),
   });
   const commonState = useLocalCommonState();
   const user_id = useSelector(authSliceSelectors.getId);

   const {data, isLoading, error} = useGetPrice()

   const minimumPrice = data?.filter((item: any) => {
     return item.currency
   }).find((value: number, index: number, array: number[]) => {
      return array.every((otherValue) => value <= otherValue);
   })?.price;

   // useScrollToError(errors);

   const start = useWatch({
      control,
      name: "start_hours",
   });

   const end = useWatch({
      control,
      name: "end_hours",
   });

   // const rate = 1;
   const cost = (start && end) ? minimumPrice * getTimeFromTo(start.text, end.text) : 0;

   const submit = (data: FormType) => {
      // const date = moment(showDateYMDNative(data.date));

      const dateFromMoment = moment(data.date)
         .add(data.start_hours.value.hours, "hours")
         .add(data.start_hours.value.minutes, "minutes")
      const dateToMoment = moment(data.date)
         .add(data.end_hours.value.hours, "hours")
         .add(data.end_hours.value.minutes, "minutes")


      commonState.dispatch(
         lessonThunk.createEvent({
            user_id,
            commonState,
            date_from: dateFromMoment.utcOffset("+0000").format(),
            date_to: dateToMoment.utcOffset("+0000").format(),
            periodicity: data.periodicity,
            callbacks: {
               "200": () => {
                  commonState.dispatch(
                     uiSlice.actions.setModalType({
                        template: "default",
                        payload: {
                           status: "success",
                           title: t(
                              "ModalWindow.CreateSlot.title",
                              "Слот успешно создан"
                           ),
                           button: "to_lk",
                        },
                     })
                  );
               },
            },
         })
      );
   };

   const startHours = useWatch({
      control,
      name: "start_hours",
   });
   useEffect(() => {
      setValue("end_hours", undefined);
      setValue("end_hours_input", "");
   }, [startHours]);

   return (
      <>
         {/*<div className={styles.cost}>*/}
         {/*   {isLoading*/}
         {/*       ? <Preloader variant="small" loading={isLoading}/>*/}
         {/*       :*/}
         {/*       <>*/}
         {/*      <Board*/}
         {/*          title={(*/}
         {/*              <>*/}
         {/*                 {t("CreateSlot.rate", "Тариф от")}:{" "}*/}
         {/*                 <span style={{ color: "var(--color-primary)"}}>*/}
         {/*               {minimumPrice ? minimumPrice : " - "}${" "}{t("CreateSlot.at-hour", "в час")}*/}
         {/*            </span>*/}
         {/*              </>*/}
         {/*          )}*/}
         {/*      />*/}
         {/*      <Board*/}
         {/*          title={(*/}
         {/*              <>*/}
         {/*                 {t("CreateSlot.lesson-computed-cost", "Расчетная стоимость урока")}:{" "}*/}
         {/*                 <span style={{ color: "var(--color-primary)"}}>{cost ? cost : " - "}$</span>*/}
         {/*              </>*/}
         {/*          )}*/}
         {/*      />*/}
         {/*   </>}*/}

         {/*</div>*/}
         <form onSubmit={handleSubmit(submit)} className={styles.container}>
            <Prompt controllable className={styles.prompt}>
               {t(
                  "CreateSlot.prompt",
                  "Выберите временной диапазон, на который к вам смогут отправлять заявки на проведение занятий"
               )}
            </Prompt>
            <ContentBlockContainer
               title={t("Lk.SendRequest.date-label", "Дата и время")}
            >
               <div className={styles.date_picker}>
                  <Calendar
                     className={styles.calendar}
                     control={control}
                     style={{
                        gridArea: "date",
                     }}
                     minDate={moment().toDate()}
                     error={
                        errors?.date?.message
                           ? errors?.date?.message
                           : errors?.date
                     }
                     name='date'
                     placeholder={t("UI.DateAndTimePicker.date", "Дата")}
                  />
                  <SelectUnderControl
                     name='start_hours'
                     style={{
                        gridArea: "start_hours",
                     }}
                     setValue={setValue}
                     searchField
                     options={renderStartTimeOptions()}
                     control={control}
                     label={t("Common.start_period", "Начало периода")}
                     error={errors?.date?.message}
                     className={styles.date_from}
                  />
                  <SelectUnderControl
                     name='end_hours'
                     setValue={setValue}
                     style={{
                        gridArea: "end_hours",
                     }}
                     searchField
                     readOnly={!startHours}
                     options={renderStartTimeOptions(
                        startHours?.value?.hours * 60 + startHours?.value.minutes
                     )}
                     control={control}
                     label={t("Common.end_period", "Конец периода")}
                     error={errors?.date?.message}
                     className={styles.date_from}
                  />
               </div>
            </ContentBlockContainer>
            <div className={styles.reiteration}>
               <ContentBlockContainer
                  title={t("CreateSlot.periodicity.title", "Повторять")}
               >
                  <RadioInput
                     label={t("CreateSlot.periodicity.array.3", "Не повторять")}
                     control={control}
                     name='periodicity'
                     defaultChecked
                     value='once'
                  />
                  <RadioInput
                     label={t("CreateSlot.periodicity.array.0", "Каждый день")}
                     control={control}
                     name='periodicity'
                     value='every_day'
                  />
                  <RadioInput
                     label={t("CreateSlot.periodicity.array.1", "Каждую неделю")}
                     control={control}
                     name='periodicity'
                     value='every_week'
                  />
                  <RadioInput
                     label={t("CreateSlot.periodicity.array.2", "Каждый месяц")}
                     control={control}
                     name='periodicity'
                     value='every_month'
                  />
               </ContentBlockContainer>
            </div>

            <Button
               className={styles.action_button}
               variant={"primary"}
               loading={commonState.loading}
            >
               {t("Lk.SendRequest.add-button", "Добавить")}
            </Button>
         </form>
      </>
   );
};
