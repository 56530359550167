import { FC, HTMLAttributes } from "react";
import styles from "./index.module.css";

type PropsType = {
   title: string | JSX.Element;
   className?: string;
};

export const Board: FC<PropsType> = ({ title, ...props }) => {
   return (
      <div className={styles.container + ` ${props.className}`}>
         <div className={styles.content}>{title}</div>
      </div>
   );
};
