import React, { FC } from "react";
import { ReactComponent as HatIcon } from "./assets/Hat.svg";
import { Button } from "../../../UI/Button";
import { Link, useHistory } from "react-router-dom";
import { routes } from "routes";
import { useTranslation } from "react-i18next";
import styles from "./style.module.css";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";
import { useGoToProfile } from "helpers/util/goToProfile";
type propsType = {};
export const TeacherFirstStage: FC<propsType> = (props) => {
   const history = useHistory();
   const { id } = useSelector(lkSliceSelectors.getUserData);
   const { t } = useTranslation();
   const goToProfile = useGoToProfile(id);

   return (
      <div className={styles.container}>
         <HatIcon className={styles.icon} />
         <h4 className={"RegistrationTeacher__title"}>
            {t(
               "Registration.TeacherPages.TeacherFirstStage.title",
               "Анкета преподавателя"
            )}
         </h4>
         <p className={styles.description}>
            {t(
               "Registration.TeacherPages.TeacherFirstStage.description",
               "Для того, чтобы проводить занятия со студентами, вам необходимо заполнить анкету. После одобрения заявки администраторами у вас появится специальный статус “Преподаватель”"
            )}
         </p>
         <Button
            className='Registration__button'
            variant={"primary"}
            onClick={() =>
               history.push(routes.registration.teacher.studyLanguages)
            }
         >
            {t(
               "Registration.TeacherPages.TeacherFirstStage.continue-button",
               "Продолжить"
            )}
         </Button>
         <Button
            style={{ marginTop: "24px" }}
            className='Registration__button'
            variant={"outline"}
            onClick={goToProfile}
         >
            {t(
               "Registration.TeacherPages.TeacherFirstStage.back-button",
               "Вернуться в профиль"
            )}
         </Button>
      </div>
   );
};
