// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Input-module_container__JDzgn {\n    width: 100%;\n}\n.Input-module_input__2WFH3 {\n    border: none;\n    outline: none;\n\n\n    width: 100%;\n\n    font-family: Source Sans Pro;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 18px;\n\n    color: var(--color-black)\n}\n.Input-module_input__2WFH3::placeholder {\n    border: none;\n    outline: none;\n\n    color: var(--color-black3)\n\n}\n.Input-module_input__2WFH3:focus::placeholder {\n   opacity: 0;\n\n}", "",{"version":3,"sources":["webpack://src/components/UI/Input-module/index.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,YAAY;IACZ,aAAa;;;IAGb,WAAW;;IAEX,4BAA4B;IAC5B,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;;IAEjB;AACJ;AACA;IACI,YAAY;IACZ,aAAa;;IAEb;;AAEJ;AACA;GACG,UAAU;;AAEb","sourcesContent":[".container {\n    width: 100%;\n}\n.input {\n    border: none;\n    outline: none;\n\n\n    width: 100%;\n\n    font-family: Source Sans Pro;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 18px;\n\n    color: var(--color-black)\n}\n.input::placeholder {\n    border: none;\n    outline: none;\n\n    color: var(--color-black3)\n\n}\n.input:focus::placeholder {\n   opacity: 0;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Input-module_container__JDzgn",
	"input": "Input-module_input__2WFH3"
};
export default ___CSS_LOADER_EXPORT___;
