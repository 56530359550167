import React, { FC, useEffect } from "react";
import styles from "./style.module.css";
import { LessonBody } from "../../components/LessonBody";
import { SearchFastLessonHeader } from "./FastLessonHeader";
import { SearchFastLessonButtons } from "./FastLessonButtons";
import { useSelector } from "react-redux";
import { fastLessonSliceSelectors } from "store/fastLessonSlice";
import { applicationApi } from "api/applicationApi/methods";

type propsType = {};

export const SearchFastLesson: FC<propsType> = (props) => {
   const application = useSelector(
      fastLessonSliceSelectors.getCurrentApplication
   );

   const getApplicationFile = async (file_id: string) => {
      return await applicationApi.getApplicationFile({
         application_id: String(application?.id),
         file_id,
      });
   };

   return (
      <div className={styles.container}>
         <SearchFastLessonHeader />
         <main className='Lessons__main'>
            <LessonBody
               date_from={application.created_at}
               duration={application.duration}
               subject={application.subject}
               language={application.language}
               grade={application?.grade}
               topic={application.topic}
               files={application.files}
               getFileMethod={getApplicationFile}
            />
            <SearchFastLessonButtons />
         </main>
      </div>
   );
};
