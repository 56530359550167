import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StateType } from "../index";
import { mainAxios } from "api";
import { authApiAxios } from "api/AuthApi";
import { lc } from "store/LC";

type authSliceType = {
   user_id: string | null;
   email: string | null;
   token: string | null;
   code: string | null;
   isRestoring: boolean;
};
const initialState = {
   email: null,
   user_id: null,
   token: null,
   code: null,
   isRestoring: false,
} as authSliceType;
export const authSlice = createSlice({
   name: "@authSlice",
   initialState: initialState,
   reducers: {
      setId: (state, action: PayloadAction<string>) => {
         state.user_id = action.payload;
         lc.set("user_id", action.payload);
      },
      setToken: (state, action: PayloadAction<string>) => {
         state.token = action.payload;
         lc.set("token", action.payload);
         mainAxios.defaults.headers.Authorization = "Bearer " + action.payload;
         authApiAxios.defaults.headers.Authorization =
            "Bearer " + action.payload;
      },
      setCode: (state, action: PayloadAction<string>) => {
         state.code = action.payload;
      },
      setEmail: (state, action: PayloadAction<string>) => {
         state.email = action.payload;
         lc.set("email", action.payload);
      },
      setIsRestoring: (state, action: PayloadAction<boolean>) => {
         state.isRestoring = action.payload;
      },
      logout: () => {
         lc.clear();
         mainAxios.defaults.headers.Authorization = "";
         authApiAxios.defaults.headers.Authorization = "";
         return { ...initialState };
      },
   },
});

export const authSliceSelectors = {
   getId: (state: StateType) => state.authReducer.user_id as string,
   getToken: (state: StateType) => state.authReducer.token,
   getEmail: (state: StateType) => state.authReducer.email as string,
   getCode: (state: StateType) => state.authReducer.code as string,
   getIsRestoring: (state: StateType) => state.authReducer.isRestoring,
};
