import React, { FC, useEffect } from "react";
import { LessonTitle } from "../../../components/LessonTitle";
import { useDispatch, useSelector } from "react-redux";
import {
   fastLessonSlice,
   fastLessonSliceSelectors,
} from "store/fastLessonSlice";
import { applicationApi } from "api/applicationApi/methods";
import { authSliceSelectors } from "store/authSlice";
import { applicationType } from "store/fastLessonSlice/types";
import { FastLessonTimer } from "./components/FastLessonTimer";
import { useTranslation } from "react-i18next";
import { Icons } from "assets/iconsCorrect";
import { routes } from "routes";
import { useHistory, useParams } from "react-router-dom";

let timeoutId = 0;
var isRejected: boolean;

export const SearchFastLessonHeader: FC = () => {
   const { t } = useTranslation();
   const { id: applicationId } = useParams<any>();
   const user_id = useSelector(authSliceSelectors.getId);
   const dispatch = useDispatch();
   const history = useHistory();
   const application = useSelector(
      fastLessonSliceSelectors.getCurrentApplication
   );
   const setApplication = (data: applicationType) => {
      dispatch(fastLessonSlice.actions.setCurrentApplication(data));
   };

   /* methods */
   const method = async () => {
      const response = await applicationApi.getApplication({
         user_id,
         application_id: applicationId,
      });
      if (!response || isRejected) {
         return;
      }

      const applicationResponse = response?.data?.data;

      if (response.status == 403) {
         setApplication({
            ...application,
            status: "rejected_application",
         });
         return;
      }

      setApplication(response?.data?.data);
      switch (applicationResponse?.status) {
         case "application": {
            timeoutId = window.setTimeout(method, 6000);
            break;
         }
         case "rejected_application": {
            break;
         }
         case "approved_application": {
            history.push(
               routes.lk.lesson.default.view +
                  `/${applicationResponse?.approved_suggestion?.lesson_id}`
            );
            break;
         }
         default:
            break;
      }
   };

   useEffect(() => {
      isRejected = false;
      method().finally();
      return () => {
         isRejected = true;
         window.clearTimeout(timeoutId);
         dispatch(fastLessonSlice.actions.setCurrentApplication({}));
      };
   }, []);

   /* view */
   const titleResolver = () => {
      switch (application.status) {
         case "application":
            return t(
               "Lk.lesson-service.fast-lesson.search.process",
               "Идет поиск преподавателя"
            );

         case "rejected_application":
            return t(
               "Lk.lesson-service.fast-lesson.search.not-found",
               "Преподаватель не найден"
            );
      }
      return "";
   };
   return (
      <>
         <LessonTitle
            title={titleResolver()}
            icon={
               application.status == "rejected_application" && (
                  <Icons.status.ErrorStatusIcon
                     style={{
                        width: "40px",
                        height: "40px",
                     }}
                  />
               )
            }
         />
         {application.status == "application" && <FastLessonTimer />}
      </>
   );
};
