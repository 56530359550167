import {useSelector} from "react-redux";
import {lkSlice, lkSliceSelectors} from "store/lkSlice";
import {useEffect} from "react";
import {useQuery} from "hooks/useQuery";
import {userApi} from "api/userApi/methods";


var onlinePoolingIntervalId:number
export const useOnlinePooling = () => {
    const isOnline = useSelector(lkSliceSelectors.getIsOnline)
    const {id} = useSelector(lkSliceSelectors.getUserData)

    const {fetch: poolOnlineMethod} = useQuery(() => userApi.changeOnlineStatus({
        user_id: id,
        status: "online",
    }), {
        condition: false
    })

    const startPooling = () => {
        onlinePoolingIntervalId = window.setInterval(poolOnlineMethod, 60000)
    }
    const stopPooling = () => {
        window.clearInterval(onlinePoolingIntervalId)
    }

    useEffect(() => {
        if (isOnline) {
            startPooling()
        } else {
            stopPooling()
        }
    }, [isOnline])

}