// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_buttons__3LIoD {\n    display: flex;\n}\n\n.style_button__2olB5 {\n    margin-right: 10px;\n}\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n\n}\n\n@media screen and (max-width: 1023px) {\n    .style_container__38fOZ {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n    }\n    .style_buttons__3LIoD {\n        flex-direction: column;\n        align-items: center;\n    }\n    .style_button__2olB5 {\n        margin-bottom: 32px;\n        margin-right: 0;\n    }\n}\n\n@media screen and (max-width: 719px) {\n\n\n}", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/LessonsService/Pages/Application/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;AACA;;AAEA;;AAEA;;AAEA;;AAEA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,mBAAmB;IACvB;IACA;QACI,sBAAsB;QACtB,mBAAmB;IACvB;IACA;QACI,mBAAmB;QACnB,eAAe;IACnB;AACJ;;AAEA;;;AAGA","sourcesContent":[".buttons {\n    display: flex;\n}\n\n.button {\n    margin-right: 10px;\n}\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n\n}\n\n@media screen and (max-width: 1023px) {\n    .container {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n    }\n    .buttons {\n        flex-direction: column;\n        align-items: center;\n    }\n    .button {\n        margin-bottom: 32px;\n        margin-right: 0;\n    }\n}\n\n@media screen and (max-width: 719px) {\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": "style_buttons__3LIoD",
	"button": "style_button__2olB5",
	"container": "style_container__38fOZ"
};
export default ___CSS_LOADER_EXPORT___;
