import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { ModalWindowBody } from "components/ModalWindow/templates/components/ModalWindowBody";
import { useForm } from "react-hook-form";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { Input } from "components/UI/Input";
import { emailSchema } from "helpers/validationSchemes";
import { Button } from "components/UI/Button";
import { authThunks } from "store/authSlice/thunks";
import { ChatThunk } from "store/chatSlice/thunk";
import { useSelector } from "react-redux";
import { authSliceSelectors } from "store/authSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "core/LTools/core/functional components/Form";
import { routes } from "routes";
import { Icons } from "assets/iconsCorrect";
import { teacherType } from "types/userType";
import { TextArea } from "components/UI/TextArea";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { addMethodToUpdateLang } from "services/translateService/useLangInit";
import i18n from "services/translateService/i18n";
import { RadioInput } from "../../../UI/Input/RadioInput";
import { bool } from "yup";
import { log } from "util";

type propsType = {
   closeModal: () => void;
};

let scheme: any;
addMethodToUpdateLang(() => {
   scheme = yup.object().shape({
      message: yup
         .string()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         ),
   });
});

type FormType = {
   email: string;
   message: string;
   admin_chat: "default_chat" | "admin_chat";
};
export const CreateAdminChat: FC<propsType> = (props) => {
   const form = useForm<FormType>({
      resolver: yupResolver(emailSchema.concat(scheme)),
      reValidateMode: "onSubmit",
      defaultValues: {
         admin_chat: "default_chat",
      },
   });
   const { t } = useTranslation();
   const clientUserId = useSelector(authSliceSelectors.getId);
   const commonState = useLocalCommonState();

   const postMessage = (chat_id: number, message: string) => {
      commonState.dispatch(
         ChatThunk.creatNewMessage({
            commonState,
            chat_id,
            message: {
               message_type: "message",
               message,
            },
            callbacks: {
               "201": () => {
                  props.closeModal();
               },
            },
         })
      );
   };

   const createChat = (
      user: teacherType,
      message: string,
      admin_chat: boolean
   ) => {
      commonState.dispatch(
         ChatThunk.createChat({
            admin_chat: admin_chat,
            users: admin_chat
               ? [Number(user.id)]
               : [Number(user.id), Number(clientUserId)],
            needSetLocation: false,
            commonState,
            callbacks: {
               "201": (responseChat) => {
                  const chat = responseChat.data.data;
                  postMessage(chat.id, message);
                  commonState.location = admin_chat
                     ? routes.lk.messages + "/help/" + chat.id
                     : routes.lk.messages + "/default/" + chat.id;
               },
            },
         })
      );
   };

   const startProcess = (data: FormType) => {
      commonState.dispatch(
         authThunks.getUser({
            email: data.email,
            disableSetData: true,
            commonState,
            callbacks: {
               "200": (responseUser) => {
                  const user = responseUser?.data?.data[0];
                  if (user) {
                     createChat(
                        user,
                        data.message,
                        data.admin_chat == "default_chat" ? false : true
                     );
                  } else {
                     form.setError("email", {
                        message: "Not found",
                     });
                  }
               },
            },
         })
      );
   };

   return (
      <Form
         onSubmit={form.handleSubmit(startProcess)}
         commonState={commonState}
         clearErrors={form.clearErrors}
         errors={form.formState.errors}
         className={styles.container}
      >
         <Icons.ui.closeIcon
            onClick={props.closeModal}
            className={styles.close}
         />
         <ModalWindowBody
            title={t("Messages.write_to_user", "Написать пользователю")}
            subtitle={""}
            close={props.closeModal}
         />
         <Input
            className={styles.input}
            name='email'
            control={form.control}
            label={"Email"}
            error={
               form.formState.errors.email?.message || commonState.errors?.email
            }
         />
         <TextArea
            className={styles.textarea}
            placeholder={t(
               "UI.DefaultTextarea.placeholder",
               "Введите сообщение"
            )}
            control={form.control}
            name={"message"}
            error={
               form.formState.errors.message?.message ||
               commonState.errors?.message
            }
         />
         <div className={styles.radio_buttons}>
            <RadioInput
               control={form.control}
               name='admin_chat'
               label={t("Messages.private-message", "Личное сообщение")}
               value={"default_chat"}
               defaultChecked={true}
            />
            <RadioInput
               control={form.control}
               name='admin_chat'
               label={t("Messages.support", "Поддержка")}
               value={"admin_chat"}
            />
         </div>
         <Button loading={commonState.loading} className={styles.button}>
            {t("Messages.write", "Написать")}
         </Button>
      </Form>
   );
};
