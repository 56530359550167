// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScheduleCalendar_container__1IqqX {\n    width: 950px;\n}\n@media screen and (max-width: 1919px) {\n    .ScheduleCalendar_container__1IqqX {\n        width: 800px;\n    }\n}\n\n@media screen and (max-width: 1439px) {\n    .ScheduleCalendar_container__1IqqX {\n        width: 490px;\n    }\n}\n\n@media screen and (max-width: 1023px) {\n\n}\n\n@media screen and (max-width: 719px) {\n    .ScheduleCalendar_container__1IqqX {\n        width: 294px;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/SchedulePage/Schedule/ScheduleCalendar/index.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI;QACI,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;AACJ;;AAEA;;AAEA;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".container {\n    width: 950px;\n}\n@media screen and (max-width: 1919px) {\n    .container {\n        width: 800px;\n    }\n}\n\n@media screen and (max-width: 1439px) {\n    .container {\n        width: 490px;\n    }\n}\n\n@media screen and (max-width: 1023px) {\n\n}\n\n@media screen and (max-width: 719px) {\n    .container {\n        width: 294px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ScheduleCalendar_container__1IqqX"
};
export default ___CSS_LOADER_EXPORT___;
