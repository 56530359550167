import React, { FC, useEffect } from "react";
import { TeacherCard } from "./TeacherCard";
import { landingTeachersMock } from "../../mock";
import { useTranslation } from "react-i18next";
import styles from "./index.module.css";
import { userApi } from "api/userApi/methods";
import { useQuery } from "hooks/useQuery";

type propsType = {};
export const TeachersForYou: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const { isLoading: teachersDataLoading, data: teachersData } = useQuery(
      () => {
         return userApi.getUsers({
            limit: "8",
            offset: "0",
            type: "teacher",
            is_has_active_slots: true,
            is_banned: false,
            is_blocked: false,
         });
      }
   );
   const showTeachers = () => {
      let teachers: any[] = [];
      if (teachersDataLoading) {
         Array(8)
            .fill(undefined)
            .forEach((item) => {
               teachers.push(
                  <div className={styles.loading + " " + styles.loadingBg} />
               );
            });
      } else if (teachersData && !teachersDataLoading)
         teachersData?.data?.data.items.map((teacher: any) => {
            teachers.push(
               <TeacherCard userData={teacher} variant={"landing"} />
            );
         });

      return teachers.map((item) => item);
   };
   return (
      <div className={styles.container}>
         <div className={styles.main_header}>
            {t("Landings.TeachersForYou.header", "Преподаватели для вас")}
         </div>
         <div className={styles.cards_container}>
            {(teachersDataLoading || teachersData) && showTeachers()}
         </div>
      </div>
   );
};
