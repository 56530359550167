import React, { FC, useState } from "react";
import styles from "./index.module.css";
import m_styles from "./TableItem_mobile/index.module.css";
import photo from "../../assets/Photo.png";
import cn from "classnames";
import { AdminTableItem } from "./TableItem";
import { AdminTableItem_mobile } from "./TableItem_mobile";
import { useTranslation } from "react-i18next";

type AdminTableType = {
   data: any[];
   updateState: () => void;
};

export const AdminTable: FC<AdminTableType> = (props) => {
   const { t } = useTranslation();
   const { data, updateState } = props;

   if (window.innerWidth > 719)
      return (
         <div className={styles.container}>
            <table className={styles.table}>
               <tr className={styles.header}>
                  <th></th>
                  <th className={styles.header_item}>
                     {t("Lk.AdminPanel.Table.id-full-name", "id / Полное имя")}
                  </th>
                  <th className={styles.header_item}>
                     {t(
                        "Lk.AdminPanel.Table.registration-date",
                        "Дата регистрации"
                     )}
                  </th>
                  <th className={styles.header_item}>
                     {t("Lk.AdminPanel.Table.birth-date", "Дата рождения")}
                  </th>
                  <th className={styles.header_item}>
                     {t("Lk.AdminPanel.Table.rating-role", "Рейтинг / Роль")}
                  </th>
                  <th className={[styles.header_item, styles.email].join(" ")}>
                     {t("Lk.AdminPanel.Table.email", "Email")}
                  </th>
                  <th></th>
                  <th className={styles.header_item}></th>
               </tr>

               {data?.map((inactiveTeacherData, index) => (
                  <AdminTableItem
                     updateState={updateState}
                     key={index}
                     inactiveTeacherData={inactiveTeacherData}
                  />
               ))}
            </table>
         </div>
      );
   else
      return (
         <div className={m_styles.container}>
            {data.map((inactiveTeacherData, index) => (
               <AdminTableItem_mobile
                  updateState={updateState}
                  key={index}
                  inactiveTeacherData={inactiveTeacherData}
               />
            ))}
         </div>
      );
};
