import React, { FC } from "react";
import styles from "./style.module.css";
import cn from "classnames";
import { cardType } from "../types";
import { useHistory } from "react-router-dom";
import { routes } from "routes";
import { CardTime } from "../components/CardTime";
import { inviteType, slotType } from "types/LessonService/types";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";
import { CircledCounter } from "components/UI/CircledCounter";
import { useTranslation } from "react-i18next";
import { suggestionType } from "store/fastLessonSlice/types";
import moment from "moment-timezone";
import { ControlButtonsInviteCard } from "components/Lk/Pages/LkProfile/Cards/TeacherCard/components/ControlButtonsInviteCard";

type propsType = {
   type: cardType;
   setData?: any;
   data?: any;
} & {
   [key: string]: any;
} & {
   className?: string;
};

const cardRouteResolver: {
   [key in cardType]?: string;
} = {
   lesson: routes.lk.lesson.default.view,
   invite: routes.lk.lesson.invite.view,
   group_lesson: routes.lk.lesson.group.view,
   suggestion: routes.lk.lesson.application.view,
};

export const TeacherCard: FC<propsType> = ({ type, ...other }) => {
   const history = useHistory();
   const { t } = useTranslation();
   const user = useSelector(lkSliceSelectors.getUserData);

   const defineUser = () => {
      if (type == "suggestion") {
         return (other as suggestionType).application.student;
      }

      if (other?.student) {
         if (other.student?.id == user.id) {
            return other.teacher;
         } else {
            return other.student;
         }
      }
      return undefined;
   };

   const removeInviteCardHandler = () => {
      if (!other.data || !other.slot_id || !other.id) return;
      let slot = [...other.data].find(
         (item: slotType) => item.id == other.slot_id
      );

      const newArray = [...other.data].filter(
         (item: slotType) => item.id != other.slot_id
      );

      if (!slot) return;
      slot = {
         ...slot,
         invites: [...slot.invites].filter(
            (item: inviteType) => item.id != other.id
         ),
      };
      other.setData && other.setData([...newArray, slot]);
   };

   const resolveBody = () => {
      switch (type) {
         case "invite":
            return (
               <>
                  {other.data && (
                     <ControlButtonsInviteCard
                        slot_id={other.slot_id}
                        invite_id={other.id}
                        removeCardHandler={removeInviteCardHandler}
                     />
                  )}
                  <CardTime
                     date_from={other?.date_from}
                     date_to={other?.date_to}
                     subject={other?.subject?.name}
                     user={defineUser()}
                     color={"black"}
                  />
               </>
            );
         case "lesson":
            return (
               <CardTime
                  date_from={other?.date_from}
                  date_to={other?.date_to}
                  subject={other?.subject?.name}
                  user={defineUser()}
                  color={"white"}
               />
            );
         case "group_lesson":
            return (
               <CardTime
                  date_from={other?.date_from}
                  date_to={other?.date_to}
                  subject={other?.subject?.name}
                  user={defineUser()}
                  color={"white"}
               >
                  <div className={styles.group_lesson}>
                     <CircledCounter count={other?.students.length} />
                     <p>
                        {t(
                           "Lk.Profile.cards.teacher.enrolled_students",
                           "Записано студентов"
                        )}
                     </p>
                  </div>
               </CardTime>
            );
         case "suggestion": {
            const dateTo = moment(other.created_at)
               .add(
                  other.application.duration.replace(" minutes", ""),
                  "minutes"
               )
               .format();
            return (
               <CardTime
                  date_from={other?.created_at}
                  date_to={dateTo}
                  subject={other?.application?.subject?.name}
                  user={other?.application?.student}
               />
            );
         }
      }
   };

   const generateTitle = (type: cardType) => {
      switch (type) {
         case "suggestion":
            return t(
               "Lk.Notifications.SuggestionCard.new-suggestion",
               "Запрос на Быстрое занятие"
            );
         case "invite":
            return t(
               "Lk.Profile.cards.teacher.invite_title",
               "Заявка на занятие от"
            );
         case "lesson":
            return t(
               "Lk.Profile.cards.teacher.lesson_title",
               "Занятие со студентом"
            );
         case "group_lesson":
            return t(
               "Lk.Profile.cards.teacher.group_lesson_title",
               "Открытый урок"
            );
      }
   };

   const action = () => {
      const route = cardRouteResolver[type];
      if (route) {
         history.push(cardRouteResolver[type] + "/" + other.id);
      }
   };

   return (
      <>
         <div
            className={cn(styles.container, {
               [`${styles[`container--${type}`]}`]: true,
               [`${other.className}`]: !!other.className,
            })}
         >
            <h5 className={styles.title}>{generateTitle(type)}</h5>
            {resolveBody()}
            <button onClick={action} className={styles.button}>
               {t("Lk.Notifications.buttons.more", "Подробнее")}
            </button>
         </div>
      </>
   );
};
