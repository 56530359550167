import React, { useState } from "react";
import "./style.css";
import { Input, InputPropsType } from "../index";
import { PassInvis } from "../../../../assets/inputs/PassInvis";
import { PassVis } from "../../../../assets/inputs/PassVis";
import { Controller } from "react-hook-form";
import ReactInputMask from "react-input-mask";

type RateInputType<T> = {} & InputPropsType<T>;
export function RateInput<T>(props: RateInputType<T>) {
   // const [isVisible, setIsVisible] = useState(true);
   //
   // const toggle = () => {
   //    setIsVisible(!isVisible);
   // };

   return (
      <div data-class='Input' className={"Input__wrapper " + props.className}>
         <Controller
            control={props.control}
            name={props.name}
            // @ts-ignore
            defaultValue={props.defaultValue}
            render={({ field }) => (
               <ReactInputMask
                  autoComplete='off'
                  // @ts-ignore
                  maskChar={null}
                  mask={props.mask || ""}
                  {...props}
                  {...field}
                  className=''
                  placeholder={props.placeholder}
                  onChange={(e) => {
                     field.onChange(e);
                     if (props.onChange) {
                        props.onChange(e);
                     }
                  }}
               />
            )}
         />
         {props.label && <label className='Input__label'>{props.label}</label>}
         {/*{props.support && props.isLoading && (*/}
         {/*   <div className='Input__support-loader'>{props.support}</div>*/}
         {/*)}*/}
         {props.support && (
            <div data-class='input-support' className='Input__support'>
               {props.support}
            </div>
         )}
         {Boolean(props.error) && (
            <div title={props.error} className='Input__error'>
               {props.error}
            </div>
         )}
      </div>
   );
}
