import React, { FC } from "react";
import styles from "components/Lk/Pages/SchedulePage/Schedule/ScheduleHeader/style.module.css";
import { ReactComponent as CalendarLogo } from "assets/icons/Calendar.svg";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as RightArrow } from "assets/icons/Arrow-right.svg";
import { scheduleSlice, scheduleSliceSelectors } from "store/scheduleSlice";
import cn from "classnames";
import moment from "moment-timezone";
import { firstLetterUpperCase } from "helpers/string/firstLetterUppercase";
import { useTranslation } from "react-i18next";
import { ScheduleFiltersUi } from "components/UI/ScheduleFiltersUi";
import { offsetMonth } from "helpers/schedule";

type propsType = {
   type?: "sidebar";
   setIsOpen?: any;
   setValue?: any;
};

export const ScheduleHeader: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const calendarDate = useSelector(scheduleSliceSelectors.getDate);
   const setNextMonth = () => {
      dispatch(
         scheduleSlice.actions.setDate(offsetMonth(calendarDate, 1).toString())
      );
   };
   const setPrevMonth = () => {
      dispatch(
         scheduleSlice.actions.setDate(offsetMonth(calendarDate, -1).toString())
      );
   };
   const setToday = () => {
      dispatch(scheduleSlice.actions.setDate(new Date().toString()));
      dispatch(scheduleSlice.actions.setDay(new Date().toString()));
   };
   // 14 sec
   return (
      <>
         <div
            className={cn({
               [styles.container]: true,
               [styles["container--sidebar"]]: props.type == "sidebar",
            })}
         >
            <div className={styles.left}>
               <div className={styles.arrows}>
                  <RightArrow
                     className={styles.left_arrow}
                     onClick={setPrevMonth}
                     style={{
                        transform: "rotateZ(180deg)",
                     }}
                  />
                  <RightArrow
                     className={styles.right_arrow}
                     onClick={setNextMonth}
                  />
               </div>
               <div
                  className={styles.day_info}
                  onClick={() => props.setIsOpen && props.setIsOpen(true)}
               >
                  <CalendarLogo className={styles.icon} />
                  <div className={styles.labels}>
                     <p className={styles.label}>
                        {firstLetterUpperCase(
                           moment()
                              .month(calendarDate.getMonth())
                              .format("MMMM")
                        )}
                     </p>
                     <p className={styles.label}>
                        {calendarDate.getFullYear()}
                     </p>
                  </div>
               </div>
            </div>
            {props.type != "sidebar" && (
               <button onClick={setToday} className={styles.back_to_day}>
                  {t(
                     "Lk.ScheduleCalendar.back-to-today",
                     "Вернуться к текущей дате"
                  )}
               </button>
            )}
         </div>
         <div className={styles.filters}>
            <ScheduleFiltersUi setValue={props.setValue} />
         </div>
      </>
   );
};
