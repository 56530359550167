import React, { FC, useEffect, useState } from "react";
import styles from "./style.module.css";
import { useForm, useWatch } from "react-hook-form";
import { PhotoUploader } from "../../../UI/PhotoUploader";
import { Button } from "../../../UI/Button";
import { fillInfoSchema } from "helpers/validationSchemes";
import { yupResolver } from "@hookform/resolvers/yup";
import { routes } from "routes";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { useSelector } from "react-redux";
import { authSliceSelectors } from "store/authSlice";
import { useTranslation } from "react-i18next";
import { lkSLiceThunk } from "store/lkSlice/thunk";
import { defaultFileType } from "api/types";
import { Calendar } from "components/UI/Calendar";
import { Form } from "core/LTools/core/functional components/Form";
import { dateMethods } from "helpers/date";
import { Preloader } from "components/UI/Preloader";
import { Input } from "components/UI/Input";
import { userApi } from "../../../../api/userApi/methods";
import i18n from "../../../../services/translateService/i18n";
import moment from "moment-timezone";
import { lkSlice, lkSliceSelectors } from "store/lkSlice";
import { lc } from "../../../../store/LC";
import { arrayOfTimezones } from "../../../../staticData/timezone";

type propsType = {};
type FillInformationFormType = {
   role: string;
   first_name: string;
   last_name: string;
   birth_date: any;
   avatar: defaultFileType;
};

export const FillInformation: FC<propsType> = (props) => {
   const [timezoneOptions] = useState(
      arrayOfTimezones.map((item, index) => ({
         value: item,
         id: index,
         text: item.offset,
      }))
   );

   const { t } = useTranslation();
   const {
      control,
      handleSubmit,
      setValue,
      formState: { errors },
      clearErrors,
   } = useForm<FillInformationFormType>({
      // @ts-ignore
      resolver: yupResolver(fillInfoSchema),
   });
   const commonState = useLocalCommonState();
   const user_id = useSelector(authSliceSelectors.getId);
   const user = useSelector(lkSliceSelectors.getUserData);
   const name = useWatch({ control, name: "first_name" });
   const lastName = useWatch({ control, name: "last_name" });
   const birthDate = useWatch({ control, name: "birth_date" });
   const postFile = (file: defaultFileType) => {
      commonState.dispatch(
         lkSLiceThunk.uploadFile({
            user_id,
            commonState,
            ...file,
            callbacks: {
               201: () => {
                  commonState.location =
                     routes.registration.student.studentRegistration;
               },
            },
         })
      );
   };

   const submit = (data: FillInformationFormType) => {
      commonState.dispatch(
         lkSLiceThunk.updateUser({
            commonState,
            user_id,
            payload: {
               birth_date: data.birth_date ? dateMethods.formatDMY(data.birth_date) : null,
               first_name: data.first_name,
               last_name: data.last_name,
            },
            callbacks: {
               200: () => {
                  userApi.changeLanguage({
                     language: i18n.language,
                     user_id,
                  });
                  if (data?.avatar?.content) {
                     postFile({
                        content: data.avatar.content,
                        file_name: data.avatar.file_name,
                        file_type: "avatar",
                     });
                  } else {
                     commonState.location =
                        routes.registration.student.studentRegistration;
                  }

                  const userTimezoneObject = timezoneOptions.find(
                     (item) => item.value.value == user.timezone
                  );

                  // const timezoneCode = userTimezoneObject?.value.value;
                  userApi
                     .changeTimezone({
                        user_id,
                        timezone: moment(Date.now()).format("Z"),
                     })
                     .then(() => {
                        // commonState.dispatch(
                        //    lkSlice.actions.setData({
                        //       ...user,
                        //       timezone: timezoneCode,
                        //    })
                        // );

                        moment.tz.setDefault(userTimezoneObject?.value.name);
                        lc.set("timeZone", userTimezoneObject?.value.name || "");
                        lc.set("timeZoneOffset", userTimezoneObject?.value.value || "");
                     });
               },
            },
         })
      );
   };

   useEffect(() => {
      if (user_id != undefined) {
         if (user.first_name) {
            setValue("first_name", user.first_name);
         }
         if (user.last_name) {
            setValue("last_name", user.last_name);
         }
      }
   }, [user_id]);

   return (
      <Form
         errors={errors}
         clearErrors={clearErrors}
         commonState={commonState}
         className={styles.container}
         onSubmit={handleSubmit(submit)}
      >
         <h6 className={styles.label}>
            {t(
               "Registration.StudentPages.FillInformation.info-about",
               "Информация о вас"
            )}
         </h6>
         <Input
            control={control}
            name='first_name'
            label={t(
               "Registration.StudentPages.FillInformation.name-label",
               "Имя"
            )}
            error={errors.first_name?.message || commonState.errors.first_name}
         />
         <Input
            control={control}
            name='last_name'
            label={t(
               "Registration.StudentPages.FillInformation.surname-label",
               "Фамилия"
            )}
            error={errors.last_name?.message || commonState.errors.last_name}
         />
         <Calendar
            placeholder={t(
               "Registration.StudentPages.FillInformation.birth-date-label",
               "Дата рождения (не обязательно)"
            )}
            minDate={moment().subtract(100, "year").toDate()}
            maxDate={moment().subtract(6, "year").toDate()}
            name='birth_date'
            control={control}
            error={commonState.errors.birth_date || errors?.birth_date?.message}
         />
         <div className={styles.photo_label}>
            <p>
               {t(
                  "Registration.StudentPages.FillInformation.download-photo",
                  "Загрузите ваше фото"
               )}
            </p>
            <p>
               {t(
                  "Registration.StudentPages.FillInformation.download-photo-note",
                  "это можно сделать позже в настройках"
               )}
            </p>
         </div>
         <div className={styles.photo}>
            <PhotoUploader setValue={setValue} name='avatar' />
         </div>
         <Button
            type='submit'
            variant={"primary"}
            className={"Registration__button"}
            disabled={!(name && lastName)}
            loading={commonState.loading}
         >
            {t(
               "Registration.StudentPages.FillInformation.continue-button",
               "Продолжить"
            )}
         </Button>
      </Form>
   );
};
