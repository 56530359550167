import React, { AllHTMLAttributes, FC, useState } from "react";
import styles from "./index.module.css";
import { ReactComponent as File } from "./assets/file.svg";
import { ReactComponent as LinkToDownload } from "./assets/linkToDownload.svg";
import cn from "classnames";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { lkSLiceThunk } from "store/lkSlice/thunk";
import { Preloader } from "components/UI/Preloader";
import { chatApi } from "api/chatApi/methods";
import { downloadFilesChatType } from "api/chatApi/types";

type FileDownloaderType = {
   file?: {
      id: string;
      file_name: string;
      file_type?: string;
      url: string;
   };
   user_id: string;
   chat?: downloadFilesChatType & { file_name: string };
} & AllHTMLAttributes<HTMLDivElement>;

export const FileDownloader: FC<FileDownloaderType> = (props) => {
   const { className, user_id, file, chat } = props;
   const [isLoading, setIsLoading] = useState<boolean>(false);

   const localCommonState = useLocalCommonState();

   function downloadFile() {
      setIsLoading(true);

      file &&
         localCommonState
            .dispatch(
               lkSLiceThunk.getUserFile({
                  commonState: localCommonState,
                  callbacks: {},
                  file_id: file.id,
                  user_id: user_id,
               })
            )
            .then((response) => {
               if (response.payload.data.data.content) {
                  let link = window.document.createElement("a");
                  link.setAttribute("download", file.file_name);
                  link.setAttribute("href", response.payload.data.data.content);
                  link.click();
               }
               setIsLoading(false);
            });
      chat &&
         chatApi.downloadFiles(chat).then((response) => {
            if (response.data.data.content) {
               let link = window.document.createElement("a");
               link.setAttribute("download", chat?.file_name);
               link.setAttribute("href", response.data.data.content);
               link.click();
            }
            setIsLoading(false);
         });
   }

   return (
      <div
         className={cn(styles.container, {
            [`${className}`]: className,
         })}
      >
         <File className={styles.file} />
         <p className={styles.text}>
            {file ? file.file_name : chat?.file_name}
         </p>
         {isLoading ? (
            <Preloader className={styles.loading} loading={isLoading} />
         ) : (
            <a onClick={downloadFile} className={styles.link}>
               <LinkToDownload />
            </a>
         )}
      </div>
   );
};
