import React, { FC } from "react";
import "./style.css";
import { ReactComponent as DropdownIcon } from "./assets/Dropdown.svg";
import cn from "classnames";
import { CircledCounter } from "components/UI/CircledCounter";
import { Preloader } from "components/UI/Preloader";

type propsType = {
   setIsOpen: any;
   isOpen: boolean;
   title: string;
   counter?: number;
   isLoading?: boolean;
};
export const ListButton: FC<propsType> = (props) => {
   return (
      <div
         className='ListButton'
         onClick={() => props.setIsOpen(!props.isOpen)}
      >
         <div className='ListButton-left'>
            {props.isLoading ? (
               <Preloader loading={props.isLoading} variant={"small"} />
            ) : (
               <>
                  <CircledCounter count={props.counter} variant='blue' />
                  <p>{props.title}</p>
               </>
            )}
         </div>
         {props.counter != 0 && (
            <DropdownIcon
               style={{ stroke: props.isOpen ? "#2D49FE" : "#000000" }}
               className={cn({
                  ListButton__dropdown: true,
                  "ListButton__dropdown--active": props.isOpen,
               })}
            />
         )}
      </div>
   );
};
