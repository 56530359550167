import styles from "../PopupFilters/index.module.css";
import { SelectUnderControl } from "../../../../../../../UI/Select/SelectUnderControl";
import React from "react";
import { Input } from "../../../../../../../UI/Input";
import { Control, UseFormSetValue } from "react-hook-form";
import { addMethodToUpdateLang } from "../../../../../../../../services/translateService/useLangInit";
import i18n from "services/translateService/i18n";
import { SelectItemType } from "../../../../../../../UI/Select/types";

/* Options */

/* Options types */
export let categoryOptions: Array<{
   id: number;
   value: boolean;
   text: string;
}> = [];

export let ratingOptions: Array<SelectItemType<number>> = [];

/* Options */
addMethodToUpdateLang(() => {
   categoryOptions = [
      {
         id: 1,
         value: true,
         text: i18n.t("Common.favorites", "Избранные"),
      },
      {
         id: 2,
         value: false,
         text: i18n.t("Common.all", "Все"),
      },
   ];
});

addMethodToUpdateLang(() => {
   ratingOptions = [
      {
         id: 1,
         value: 1,
         text: i18n.t("Notebook.rating.options.from.1", "От 1"),
      },
      {
         id: 2,
         value: 2,
         text: i18n.t("Notebook.rating.options.from.2", "От 2"),
      },
      {
         id: 3,
         value: 3,
         text: i18n.t("Notebook.rating.options.from.3", "От 3"),
      },
      {
         id: 4,
         value: 4,
         text: i18n.t("Notebook.rating.options.from.4", "От 4"),
      },
      {
         id: 5,
         value: 5,
         text: "5",
      },
   ];
});

export const getNotePeoplesFilterFields = function ({
   setValue,
   control,
   t,
   onSelect,
}: {
   control: Control<any>;
   t: any;
   setValue: UseFormSetValue<any>;
   onSelect: (v: any) => void;
}) {
   return {
      category: (
         <SelectUnderControl
            name={"category"}
            label={t("Common.favorites", "Избранные")}
            className={styles.category_select}
            setValue={setValue}
            options={categoryOptions}
            onSelectValue={onSelect}
            defaultSelectValue={categoryOptions[1]}
            control={control}
         />
      ),
      rating: (
         <SelectUnderControl
            name={"rating"}
            label={t("Common.rating", "Рейтинг")}
            className={styles.filters__input}
            onSelectValue={onSelect}
            setValue={setValue}
            options={ratingOptions}
            control={control}
         />
      ),
      name: (
         <Input
            className={styles.filters__input}
            control={control}
            label={t("Common.filters.search_by_name", "Поиск по имени")}
            name='user_name'
         />
      ),
   };
};
