import React, { FC, useEffect, useState } from "react";
import styles from "./style.module.css";
import { ContentBlockContainer } from "components/UI/ContentBlockContainer";
import { InfoBlock } from "components/UI/InfoBlock";
import { Prompt } from "components/UI/Prompt";
import { Input } from "components/UI/Input";
import { useForm } from "react-hook-form";
import { SelectUnderControl } from "components/UI/Select/SelectUnderControl";
import { FilesInput } from "components/UI/Input/FilesInput";
import { Button } from "components/UI/Button";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { fastLessonThunks } from "store/fastLessonSlice/thunks";
import { useSelector } from "react-redux";
import { authSliceSelectors } from "store/authSlice";
import { createApplicationType } from "api/applicationApi/types";
import { useTranslation } from "react-i18next";
import {
   fastLessonSlice,
   fastLessonSliceSelectors,
} from "store/fastLessonSlice";
import { languagesTitleResolver } from "staticData/languages";
import { Link } from "react-router-dom";
import { routes } from "routes";
import { useQuery } from "hooks/useQuery";
import { formsDataApi } from "api/formsDataApi/methods";
import { gradeType } from "store/formsDataSlice/types";
import { renderDurationOptions } from "helpers/selectOptions";
import { uploadFiles } from "helpers/uploadFiles";
import { defaultFileType } from "api/types";
import { applicationApi } from "api/applicationApi/methods";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerFastLessonScheme } from "./helpers/scheme";

type Form = {
   time: {
      id: number;
      text: string;
      value: {
         time: createApplicationType["duration"];
      };
   };
   topic: string;
   grade: any;
   files: Array<defaultFileType>;
};

type propsType = {};
export const RegisterFastLesson: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const {
      control,
      setValue,
      handleSubmit,
      watch,
      formState: { errors },
      setError,
   } = useForm<Form>({
      // @ts-ignore
      resolver: yupResolver(registerFastLessonScheme),
   });
   const commonState = useLocalCommonState();
   const { data: filtersData } = useSelector(
      fastLessonSliceSelectors.getFiltersState
   );

   
   const errorsFromFields = errors?.topic?.message || errors?.grade?.message || (errors?.time as any)?.message
   const areFieldsFilled = watch().time && watch().grade && watch().topic

   const isSubmitButtonDisabled = !areFieldsFilled || errorsFromFields

   const user_id = useSelector(authSliceSelectors.getId);

   const { data: grades } = useQuery(
      () =>
         formsDataApi.getGrade({
            education_system_id: String(
               filtersData.educationSystem?.id || "18"
            ),
         }),
      {
         serializer: (res) => {
            return res?.data.data.items.map((item: gradeType) => ({
               id: item.id,
               text: item.name,
               value: item as gradeType,
            })) as {
               id: number;
               text: string;
               value: gradeType;
            }[];
         },
      }
   );

   const submit = (data: Form) => {
      commonState.dispatch(
         fastLessonThunks.createApplication({
            user_id,
            commonState,
            duration: data?.time?.value?.time,
            topic: data?.topic,
            grade_id: Number(data?.grade?.id),
            education_system_id: Number(filtersData?.educationSystem?.id),
            language_id: Number(filtersData?.language?.id),
            subject_id: Number(filtersData?.subject?.id),

            callbacks: {
               201: (response) => {
                  uploadFiles({
                     callback: () => {
                        commonState.dispatch(
                           fastLessonSlice.actions.setCurrentApplication(
                              response.data.data
                           )
                        );
                        commonState.location =
                           routes.lk.lesson.fast.search +
                           "/" +
                           response.data.data.id;
                     },
                     apiMethod: (file: defaultFileType) =>
                        applicationApi.addFileToApplication({
                           user_id,
                           content: file.content,
                           application_id: response.data.data.id,
                           file_name: file.file_name,
                        }),
                     files: data.files,
                  });
               },
            },
         })
      );
   };

   return (
      <div className={styles.container}>
         <h2 className='LessonsTitle'>
            {t("Lk.RegisterFastLesson.search-title", "Поиск быстрого занятия")}
         </h2>
         <main className='Lessons__main'>
            <form onSubmit={handleSubmit(submit)} className={styles.form}>
               <ContentBlockContainer
                  title={t("Lk.RegisterFastLesson.duration", "Длительность")}
               >
                  <SelectUnderControl
                     className={styles.time_select}
                     watch={watch}
                     setError={setError}
                     name='time'
                     error={(errors?.time as any)?.message}
                     setValue={setValue}
                     label={t(
                        "Lk.RegisterFastLesson.duration-select",
                        "Длительность"
                     )}
                     options={renderDurationOptions()}
                     control={control}
                  />
                  <Prompt className={styles.prompt}>
                     {t(
                        "Lk.RegisterFastLesson.prompt",
                        "Время начнет отсчитываться с момента начала занятия, после подтверждения заявки на занятие преподавателем"
                     )}
                  </Prompt>
               </ContentBlockContainer>
               <ContentBlockContainer
                  title={t(
                     "Lk.RegisterFastLesson.subject-title",
                     "Предмет и тема"
                  )}
               >
                  <InfoBlock
                     infoKey={t(
                        "Lk.RegisterFastLesson.language-label",
                        "Язык преподавания"
                     )}
                     infoValue={
                        filtersData.language?.name
                           ? languagesTitleResolver[filtersData.language?.name]
                           : "—"
                     }
                  />
                  <InfoBlock
                     infoKey={t(
                        "Lk.RegisterFastLesson.level-label",
                        "Уровень образования"
                     )}
                     infoValue={filtersData.level?.name}
                  />
                  <InfoBlock
                     infoKey={t(
                        "Lk.RegisterFastLesson.system-label",
                        "Система образования"
                     )}
                     infoValue={filtersData.educationSystem?.name}
                  />
                  <InfoBlock
                     infoKey={t("Lk.RegisterFastLesson.subject", "Предмет")}
                     infoValue={filtersData.subject?.name}
                  />
                  <SelectUnderControl
                     watch={watch}
                     name='grade'
                     error={errors?.grade?.message}
                     setValue={setValue}
                     setError={setError}
                     options={grades}
                     label={t("Lk.lesson-service.body.lesson-grade", "Грейд")}
                     component={(grade) => <div>{grade.name}</div>}
                     control={control}
                  />
                  <Input
                     control={control}
                     error={errors?.topic?.message}
                     name='topic'
                     label={t(
                        "Lk.RegisterFastLesson.topic-label",
                        "Вы можете предложить тему занятия"
                     )}
                  />
                  <FilesInput
                     className={styles.files}
                     setValue={setValue}
                     control={control}
                     name='files'
                  />
               </ContentBlockContainer>
               <div className={styles.buttons}>
                  <Button
                     className={styles.button}
                     variant='primary'
                     loading={commonState.loading}
                     disabled={isSubmitButtonDisabled}
                  >
                     {t("Lk.RegisterFastLesson.sign-up-button", "Записаться")}
                  </Button>
                  <Link to={routes.landings.main}>
                     <Button
                        className={styles.button}
                        variant='primary'
                        loading={commonState.loading}
                     >
                        {t("Lk.RegisterFastLesson.cancel-button", "Отменить")}
                     </Button>
                  </Link>
               </div>
            </form>
         </main>
      </div>
   );
};
