import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SelectUnderControl } from "../../../../../../../UI/Select/SelectUnderControl";
import { useForm, useWatch } from "react-hook-form";
import { useQuery } from "../../../../../../../../hooks/useQuery";
import { formsDataApi } from "../../../../../../../../api/formsDataApi/methods";
import {
   educationSystemType,
   languagesNameType,
   levelType,
} from "../../../../../../../../store/formsDataSlice/types";
import { Input } from "../../../../../../../UI/Input";
import { selectLanguageItem } from "../../../../../../../UI/Select/SelectItems/SelectLanguageItem";
import { languageSelectSerializer } from "../../../../../../../../serializers/languages";
import i18n from "../../../../../../../../services/translateService/i18n";
import { Icons } from "assets/iconsCorrect";
import { Select } from "../../../../../../../UI/SelectNew";
import { Button } from "../../../../../../../UI/Button";
import classNames from "classnames";
import { useSelector } from "react-redux";
import {
   adminEditorSlice,
   adminEditorSliceSelectors,
} from "../../../../../../../../store/adminEditorSlice";
import { routes } from "../../../../../../../../routes";
import { Preloader } from "../../../../../../../UI/Preloader";
import { useLocalCommonState } from "../../../../../../../../core/LTools/core/hooks/useLocalCommonState";
import { adminEditorThunk } from "../../../../../../../../store/adminEditorSlice/thunk";
import { PageTitle } from "../../../../../../../UI/PageTitle";

type propsType = {};
type formType = {
   level: any;
   level_input: any;
   es: string;
   languages: Array<any>;
};
export const AddSystem: FC<propsType> = (props) => {
   const { t } = useTranslation();

   const {
      control,
      setValue,
      handleSubmit,
      watch,
      formState: { errors },
      setError,
      reset,
      unregister,
   } = useForm<formType>({
      // @ts-ignore
      // resolver: yupResolver(registerFastLessonScheme),
   });

   const { id } = useParams<{ id: string }>();

   const commonState = useLocalCommonState();
   const form = useWatch({ control });

   useEffect(() => {
      form?.languages?.map((item, index) => {
         setValue(`languages.${index}.${index}_input`, item);
      });
   }, []);

   const storeLevel = useSelector(adminEditorSliceSelectors.getLevel);
   const history = useHistory();

   const { data: esData, isLoading: esDataLoading } = useQuery(
      () =>
         formsDataApi.getEducationSystemById({
            level_id: String(storeLevel!.id),
            education_system_id: id,
         }),
      {
         condition: !!id && !!storeLevel?.id,
         serializer: (res) => {
            return res?.data.data as educationSystemType;
         },
      }
   );

   const { data: levelsData, isLoading: levelsDataLoading } = useQuery(
      formsDataApi.getLevel,
      {
         serializer: (res) => {
            return res?.data.data.items as Array<levelType>;
         },
      }
   );
   useEffect(() => {
      if (id && !storeLevel) {
         history.push(routes.lk.admin.educationSystems.root);
      }
      if (id && storeLevel) {
         console.log(storeLevel);
         setValue("level", {
            id: storeLevel.id,
            text: storeLevel.name,
            value: {
               id: storeLevel.id,
               text: storeLevel.name,
            },
         });
         setValue("level_input", storeLevel.name);
      }
   }, [levelsData, storeLevel]);

   const { data: languages, isLoading: languagesLoading } = useQuery(
      formsDataApi.getLanguage,
      {
         cachedValueName: "languages",
         serializer: languageSelectSerializer,
         reserializeDeps: [i18n.language],
      }
   );
   // useEffect(() => {
   //     if (levelsData && id) {
   //         setValue('level', {
   //             id: number;
   //             text: string;
   //             value: {
   //                 title: string;
   //                 id: number;
   //                 name: languagesNameType;
   //             }
   //         })
   //     }
   // },[levelsData])
   const [selectsArray, setSelectsArray] = useState<Array<any>>([0]);
   const [isButtonDisabled, setIsButtonDisabled] = useState(true);
   useEffect(() => {
      if (esData && esData.languages) {
         setSelectsArray(
            esData.languages.map((item) => {
               setValue(`languages.${item.id}`, {
                  id: item.id,
                  text: item.name,
                  value: {
                     title: item.name,
                     id: item.id,
                     name: item.name as languagesNameType,
                  },
               });
               return item.id;
            })
         );
      }
   }, [esData]);
   useEffect(() => {
      console.log(form);
      if (
         form.level &&
         form.es &&
         (!!form.languages?.[0] || !!form.languages?.[1])
      ) {
         setIsButtonDisabled(false);
      } else {
         setIsButtonDisabled(true);
      }
   }, [form, esData]);

   const submit = (data: formType) => {
      console.log(data);
      if (form && id && esData) {
         commonState.dispatch(
            adminEditorThunk.editSystem({
               commonState,
               callbacks: {
                  "200": () => {
                     commonState.dispatch(
                        adminEditorSlice.actions.setLevel(form.level)
                     );
                     commonState.location =
                        routes.lk.admin.educationSystems.root;
                  },
               },
               level_id: data.level.id,
               education_system_id: esData.id,
               data: {
                  education_name: data.es,
                  languages: data.languages.filter((i) => i).map((l) => l.id),
               },
            })
         );
      } else if (form) {
         commonState.dispatch(
            adminEditorThunk.addSystem({
               commonState,
               callbacks: {
                  "201": () => {
                     commonState.dispatch(
                        adminEditorSlice.actions.setLevel(form.level)
                     );
                     commonState.location =
                        routes.lk.admin.educationSystems.root;
                  },
               },
               level_id: data.level.id,
               data: {
                  education_name: data.es,
                  languages: data.languages.filter((i) => i).map((l) => l.id),
               },
            })
         );
      }
   };

   // } else if (id) {
   //    adminApi.editEducationSystem({
   //       level_id: data.level.id,
   //       education_system_id: String(data.es),
   //       data: {
   //          education_name: form.es,
   //          languages: languages.map((language) => {
   //             if (language) {
   //                return language.id;
   //             }
   //          }),
   //       },
   //    });
   // }
   const [readOnly, setReadOnly] = useState(true);
   return (
      <form className={styles.container} onSubmit={handleSubmit(submit)}>
         <PageTitle
            title={
               id
                  ? t(
                       "Lk.AdminPanel.AddSystem.edit-es",
                       "Редактировать систему образования"
                    )
                  : t(
                       "Lk.AdminPanel.AddSystem.add-es",
                       "Добавить систему образования"
                    )
            }
         />

         <div className={styles.title}>
            {t("Lk.RegisterFastLesson.level-label", "Уровень образования")}
         </div>
         {levelsDataLoading ? (
            <Preloader loading={levelsDataLoading} variant={"small"} />
         ) : (
            levelsData && (
               <SelectUnderControl
                  name={"level"}
                  label={t(
                     "Lk.RegisterFastLesson.level-label",
                     "Уровень образования"
                  )}
                  setValue={setValue}
                  control={control}
                  options={levelsData?.map((level) => {
                     return {
                        id: level.id,
                        text: level.name,
                        value: {
                           id: level.id,
                           text: level.name,
                        },
                     };
                  })}
                  className={styles.input}
               />
            )
         )}

         <div className={styles.title}>
            {t(
               "Lk.lesson-service.body.education-system",
               "Система образования"
            )}
         </div>
         {id ? (
            esDataLoading || !esData ? (
               <Preloader loading={esDataLoading} variant={"small"} />
            ) : (
               <Input
                  control={control}
                  name={"es"}
                  label={t(
                     "Common.education-system-name",
                     "Название системы образования"
                  )}
                  className={classNames({
                     [styles.input]: true,
                     [styles.locked]: readOnly,
                  })}
                  support={
                     <Icons.inputs.TextEdit
                        onClick={() => {
                           setReadOnly(!readOnly);
                        }}
                        className={!readOnly ? styles.locked_svg : ""}
                     />
                  }
                  readOnly={readOnly}
                  defaultValue={esData.name}
               />
            )
         ) : (
            <Input
               control={control}
               name={"es"}
               label={t(
                  "Common.education-system-name",
                  "Название системы образования"
               )}
               className={styles.input}
            />
         )}

         <div className={styles.title}> {t("Common.language", "Язык")}</div>
         <div className={styles.language_selects}>
            {selectsArray.map((item, index) => {
               //0-1-3
               return (
                  <div className={styles.languages_item} key={item}>
                     <Select
                        name={`languages.${item}`}
                        isLoading={languagesLoading}
                        setValue={setValue}
                        placeholder={t(
                           "SearchFilters.grade.language",
                           "Язык обучения"
                        )}
                        options={languages?.filter((language) => {
                           let isIncludes = false;
                           form.languages?.forEach((formLang) => {
                              if (language?.id == formLang?.id) {
                                 isIncludes = true;
                              }
                           });
                           return !isIncludes;
                        })}
                        // variant='blue-bg'
                        // bgColor={"var(--color-black4)"}
                        optionComponent={selectLanguageItem}
                        control={control}
                        className={styles.select_language}
                     />
                     {index != 0 && (
                        <button
                           className={styles.delete_button}
                           onClick={() => {
                              setValue(`languages.${item}`, undefined);
                              setSelectsArray(
                                 selectsArray.filter(
                                    (selectItem) => item != selectItem
                                 )
                              );
                           }}
                        >
                           <Icons.buttons.DeleteIcon />
                        </button>
                     )}
                  </div>
               );
            })}
            <button
               className={styles.add_button}
               onClick={(e) => {
                  e.preventDefault();
                  setSelectsArray([
                     ...selectsArray,
                     selectsArray[selectsArray.length - 1] + 1,
                  ]);
               }}
            >
               <Icons.buttons.CirclePlusIcon />
               {t("Common.add-lang", "Добавить язык")}
            </button>
         </div>
         <Button
            disabled={isButtonDisabled}
            className={styles.button}
            loading={commonState.loading}
         >
            {id
               ? t(
                    "Lk.AdminPanel.AddSystem.edit-es",
                    "Редактировать систему образования"
                 )
               : t(
                    "Lk.AdminPanel.AddSystem.add-es",
                    "Добавить систему образования"
                 )}
         </Button>
      </form>
   );
};
