// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchLesson_container__1DOpZ {\n    width: calc(100%);\n    min-height: calc(var(--vh) * 100);\n}\n\n.SearchLesson_first_stage__3S0c3 {\n    margin-top: 70px;\n}\n\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n\n}\n\n@media screen and (max-width: 1023px) {\n    .SearchLesson_container__1DOpZ {\n        padding-top: 0;\n        margin-top: -60px;\n    }\n}\n\n@media screen and (max-width: 719px) {\n\n}", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/SearchLesson/index.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,iCAAiC;AACrC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;IACI;QACI,cAAc;QACd,iBAAiB;IACrB;AACJ;;AAEA;;AAEA","sourcesContent":[".container {\n    width: calc(100%);\n    min-height: calc(var(--vh) * 100);\n}\n\n.first_stage {\n    margin-top: 70px;\n}\n\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n\n}\n\n@media screen and (max-width: 1023px) {\n    .container {\n        padding-top: 0;\n        margin-top: -60px;\n    }\n}\n\n@media screen and (max-width: 719px) {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SearchLesson_container__1DOpZ",
	"first_stage": "SearchLesson_first_stage__3S0c3"
};
export default ___CSS_LOADER_EXPORT___;
