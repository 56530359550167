import React, { FC, useEffect } from "react";
import styles from "components/Lk/Pages/SchedulePage/Schedule/index.module.css";
import { ScheduleHeader } from "components/Lk/Pages/SchedulePage/Schedule/ScheduleHeader";
import { ScheduleCalendar } from "components/Lk/Pages/SchedulePage/Schedule/ScheduleCalendar";
import { useDispatch, useSelector } from "react-redux";
import { scheduleApi } from "store/scheduleSlice/thunk";
import { authSliceSelectors } from "store/authSlice";
import { ScheduleFiltersUi } from "components/UI/ScheduleFiltersUi";
type propsType = {
   setValue: any;
};
export const Schedule: FC<propsType> = ({ setValue }) => {
   const dispatch = useDispatch();
   const userId = useSelector(authSliceSelectors.getId);

   useEffect(() => {
      userId && dispatch(scheduleApi.getTickets()(userId));
   }, []);
   return (
      <div className={styles.container}>
         <div className={styles.calendar}>
            <ScheduleHeader setValue={setValue} />
            <ScheduleCalendar type={"fullscreen"} />
         </div>
      </div>
   );
};
