import React, { FC } from "react";
import styles from "./style.module.css";
import { RadioInput } from "../../../UI/Input/RadioInput";
import { useForm, useWatch } from "react-hook-form";
import { Button } from "../../../UI/Button";
import { useSelector } from "react-redux";
import { studentRegistrationSchema } from "helpers/validationSchemes";
import { yupResolver } from "@hookform/resolvers/yup";
import { FilesInput, onAddFileType } from "../../../UI/Input/FilesInput";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { useQuery } from "hooks/useQuery";
import { formsDataApi } from "api/formsDataApi/methods";
import { useTranslation } from "react-i18next";
import { educationSystemType, levelType } from "store/formsDataSlice/types";
import { lkSliceSelectors } from "store/lkSlice";
import { lkSLiceThunk } from "store/lkSlice/thunk";
import { defaultFileType } from "api/types";
import { uiSlice } from "store/modalWindowSlice";
import { userApi } from "api/userApi/methods";
import {
   fastLessonSlice,
   fastLessonSliceSelectors,
} from "store/fastLessonSlice";
import { Preloader } from "components/UI/Preloader";

type propsType = {};
type StudentRegistrationFormType = {
   es: educationSystemType;
   level: string;
   file: Array<defaultFileType>;
};

export const StudentRegistration: FC<propsType> = (props) => {
   const { t } = useTranslation();
   /* state */
   const {
      control,
      handleSubmit,
      setValue,
   } = useForm<StudentRegistrationFormType>({
      // @ts-ignore
      resolver: yupResolver(studentRegistrationSchema),
      defaultValues: {
         level: "",
      },
   });
   const levelField = useWatch({
      control,
      name: "level",
   });
   const esInput = useWatch({ control, name: "es" });
   const files = useWatch({
      control: control,
      name: "file",
   });
   const commonState = useLocalCommonState();
   const userData = useSelector(lkSliceSelectors.getUserData);
   const chosenTeacherUrl = useSelector(
      fastLessonSliceSelectors.getChosenTeacherUrl
   );

   /* fetch */
   const { data: levelsData, isLoading: levelsDataLoading } = useQuery(
      formsDataApi.getLevel,
      {
         cachedValueName: "level",
         serializer: (res) => {
            return res?.data.data.items as Array<levelType>;
         },
      }
   );

   const { data: esData, isLoading: esDataLoading } = useQuery(
      () =>
         formsDataApi.getEducationSystem({
            level_id: String(levelField),
         }),
      {
         deps: [levelField],
         condition: !!levelField,
         serializer: (res) => {
            return res?.data.data.items as Array<educationSystemType>;
         },
      }
   );

   /* methods */
   const successCallback = () => {
      if (!chosenTeacherUrl) {
         commonState.dispatch(
            uiSlice.actions.setModalType({
               template: "default",
               payload: {
                  title: t(
                     "ModalWindow.student-registration-success.title",
                     "Спасибо за регистрацию"
                  ),
                  subtitle: t(
                     "ModalWindow.student-registration-success.subtitle",
                     "Теперь вы можете записываться на занятия и общаться с преподавателями"
                  ),
                  button: "to_lk",
                  status: "success",
               },
            })
         );
      } else {
         commonState.location = chosenTeacherUrl;
         commonState.dispatch(fastLessonSlice.actions.setUrlOfTeacher());
      }
   };
   const submit = (data: StudentRegistrationFormType) => {
      if (!userData.id) return;
      commonState.dispatch(
         lkSLiceThunk.updateUser({
            commonState,
            user_id: String(userData.id),
            payload: {
               //@ts-ignore
               study_education_system: !isChosenNotElementary()
                  ? String(esData[0].id)
                  : data.es,
               first_name: userData.first_name,
               last_name: userData.last_name,
               birth_date: userData.birth_date,
            },
            callbacks: {
               200: successCallback,
            },
         })
      );
   };

   const onAddFile = (data: onAddFileType) => {
      return userApi.uploadUserFile({
         content: data.content,
         file_name: data.file_name,
         file_type: "education_document",
         user_id: userData.id,
      });
   };

   // Костыль для elementary
   const isChosenNotElementary = () => {
      const nameOfLevel = levelsData.find(
         (item) => item.id == Number(levelField)
      );
      return !(nameOfLevel && nameOfLevel.name == "Elementary School");
   };
   return (
      <>
         <form className={"flex-col"} onSubmit={handleSubmit(submit)}>
            <h4 className={styles.label}>
               {t(
                  "Registration.StudentPages.StudentRegistration.level-title",
                  "Выберите ваш уровень образования"
               )}
            </h4>
            {levelsDataLoading ? (
               <Preloader loading={esDataLoading} variant={"centred"} />
            ) : (
               <div className={styles.radio_container}>
                  {levelsData?.map((item: any) => (
                     <RadioInput
                        key={item.id}
                        control={control}
                        name='level'
                        label={item.name}
                        value={item.id}
                     />
                  ))}
               </div>
            )}
            {esDataLoading ? (
               <Preloader loading={esDataLoading} variant={"centred"} />
            ) : (
               esData?.length &&
               isChosenNotElementary() && (
                  <>
                     <p className={styles.label}>
                        {t(
                           "Registration.StudentPages.StudentRegistration.study-sys-title",
                           "Выберите систему образования"
                        )}
                     </p>
                     <div className={styles.es_container}>
                        {esData.map((item: any) => (
                           <RadioInput
                              key={item.id}
                              control={control}
                              name='es'
                              label={item.name}
                              value={item.id}
                           />
                        ))}
                     </div>
                     <p className={styles.label}>
                        {t(
                           "Registration.StudentPages.StudentRegistration.upload-title",
                           "Добавьте документы об образовании"
                        )}
                     </p>
                  </>
               )
            )}
            {levelField && (
               <>
                  {!files?.length && (
                     <>
                        <div className={styles.file_labels}>
                           {t(
                              "Registration.StudentPages.StudentRegistration.upload-note",
                              "Можете добавить документы об образовании в форматах jpg, png и pdf до 10 штук."
                           )}
                        </div>
                        <div className={styles.file_labels_gray}>
                           {t(
                              "Registration.StudentPages.StudentRegistration.upload-note-unimportant",
                              "Данный шаг не обязателен, можете его пропустить."
                           )}
                        </div>
                     </>
                  )}
                  <FilesInput
                     control={control}
                     onAddFile={onAddFile}
                     setValue={setValue}
                     name='file'
                  />
                  <Button
                     variant={"primary"}
                     type='submit'
                     className={"Registration__button"}
                     disabled={
                        levelField == undefined ||
                        (!esInput && isChosenNotElementary())
                     }
                     loading={commonState.loading}
                  >
                     {t(
                        "Registration.StudentPages.StudentRegistration.continue-button",
                        "Продолжить"
                     )}
                  </Button>
               </>
            )}
         </form>
      </>
   );
};
