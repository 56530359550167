import { log } from "util";
import { firstLetterUpperCase } from "helpers/string/firstLetterUppercase";
import moment from "moment-timezone";

export const months = [
   "Январь",
   "Февраль",
   "Март",
   "Апрель",
   "Май",
   "Июнь",
   "Июль",
   "Август",
   "Сентябрь",
   "Октябрь",
   "Ноябрь",
   "Декабрь",
];

export const formatWeekDay = (formattedDate: string) => {
   // switch (formattedDate) {
   //    case "понедельник":
   //       return firstLetterUpperCase(moment().date(1).format("dd"));
   //    case "вторник":
   //       return firstLetterUpperCase(moment().date(2).format("dd"));
   //    case "среда":
   //       return firstLetterUpperCase(moment().date(3).format("dd"));
   //    case "четверг":
   //       return firstLetterUpperCase(moment().date(4).format("dd"));
   //    case "пятница":
   //       return firstLetterUpperCase(moment().date(5).format("dd"));
   //    case "суббота":
   //       return firstLetterUpperCase(moment().date(6).format("dd"));
   //    case "воскресенье":
   //       return firstLetterUpperCase(moment().date(0).format("dd"));
   // }
   const dateObject = moment(formattedDate, "dddd");
   return firstLetterUpperCase(dateObject.format("dd"));
};

export const renderDayContents = (dayOfMonth: number) => {
   if (dayOfMonth < 10) {
      return "0" + dayOfMonth;
   }
   return dayOfMonth;
};
export function zero(value: number): string {
   return value < 10 ? `0${value}` : `${value}`;
}

export function dateFormat(month: number, day: number, year: number): string {
   return `${zero(month + 1)}-${zero(day)}-${year}`;
}

export const prepareDateForServer = (date: string) => {
   const splitedDate = date.split("-");
   return `${splitedDate[2]}-${splitedDate[0]}-${splitedDate[1]}T00:00:00+03:00`;
};
