// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SmartForm_container__3rUXA {\n\n}\n\n.SmartForm_container--composed__1Ohzu {\n\n}\n\n", "",{"version":3,"sources":["webpack://src/components/hocs/SmartForm/index.module.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;;AAEA","sourcesContent":[".container {\n\n}\n\n.container--composed {\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SmartForm_container__3rUXA",
	"container--composed": "SmartForm_container--composed__1Ohzu"
};
export default ___CSS_LOADER_EXPORT___;
