import React, { FC } from "react";
import styles from "./index.module.css";
import { ReactComponent as TeachersIcon } from "./assets/Teachers.svg";
import { ReactComponent as ScheduleIcon } from "./assets/Schedule.svg";
import { ReactComponent as StudentsIcon } from "./assets/Students.svg";
import { routes } from "routes";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";
import { useTranslation } from "react-i18next";
import { LinkCard } from "components/UI/LinkCard";

type propsType = {};
/**
 * @i18n key: Notebook
 * */
export const Notebook: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const userData = useSelector(lkSliceSelectors.getUserData);

   return (
      <div className={[styles.container, "LkDefaultPage"].join(" ")}>
         <div className={styles.Notebook__title}>
            {t("Notebook.title", "Записная книжка")}
         </div>
         <div className={styles.Cards}>
            <Link to={routes.lk.notebook.peoples.teachers}>
               <LinkCard
                  className={styles.Cards_item}
                  icon={TeachersIcon}
                  title={t(
                     "Notebook.titles.your_teachers",
                     "Ваши преподаватели"
                  )}
                  count={null}
                  bgColor={"#E6EFFE"}
               />
            </Link>
            <Link to={routes.lk.notebook.history}>
               <LinkCard
                  className={styles.Cards_item}
                  icon={ScheduleIcon}
                  title={t("Notebook.titles.history.title", "История занятий")}
                  bgColor={"#EBEBEB"}
                  count={null}
               />
            </Link>
            {userData.type == "teacher" && (
               <Link to={routes.lk.notebook.peoples.students}>
                  <LinkCard
                     className={styles.Cards_item}
                     icon={StudentsIcon}
                     count={null}
                     title={t("Notebook.titles.your_students", "Ваши студенты")}
                     bgColor={"#EECEFD"}
                  />
               </Link>
            )}
         </div>
      </div>
   );
};
