// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScheduleDays_container__2yDox {\n    display: flex;\n    flex-wrap: wrap;\n    margin-top: 24px;\n}\n.ScheduleDays_sidebar__GhGBB{\n    margin-top: 8px;\n    margin-left: 0px;\n    margin-right: 0px;\n    width: 340px;\n    justify-content: left;\n}\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n    .ScheduleDays_container__2yDox {\n        margin-top: 10px;\n    }\n}\n\n@media screen and (max-width: 1023px) {\n\n}\n\n@media screen and (max-width: 719px) {\n\n}", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/SchedulePage/Schedule/ScheduleCalendar/ScheduleDays/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;IACZ,qBAAqB;AACzB;AACA;;AAEA;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA;;AAEA;;AAEA;;AAEA","sourcesContent":[".container {\n    display: flex;\n    flex-wrap: wrap;\n    margin-top: 24px;\n}\n.sidebar{\n    margin-top: 8px;\n    margin-left: 0px;\n    margin-right: 0px;\n    width: 340px;\n    justify-content: left;\n}\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n    .container {\n        margin-top: 10px;\n    }\n}\n\n@media screen and (max-width: 1023px) {\n\n}\n\n@media screen and (max-width: 719px) {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ScheduleDays_container__2yDox",
	"sidebar": "ScheduleDays_sidebar__GhGBB"
};
export default ___CSS_LOADER_EXPORT___;
