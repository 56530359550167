import { useState } from "react";

export const useScrollLimit = (props: {
   step: number;
   difference?: number;
   reverse?: boolean;
}) => {
   const { step, difference = 800, reverse = false } = props;
   const [limit, setLimit] = useState(step);
   const [blockedScroll, setBlockedScroll] = useState<boolean>(false);
   const updateLimitOnScroll = (e: any) => {
      if (!reverse && !blockedScroll) {
         if (
            e.currentTarget.scrollHeight - e.currentTarget.scrollTop <
            difference
         ) {
            setLimit((prev) => prev + step);
         }
      }
      if (reverse && !blockedScroll) {
         if (
            e.currentTarget.scrollHeight -
               (e.currentTarget.scrollHeight - e.currentTarget.scrollTop) <
            difference
         ) {
            setLimit((prev) => prev + step);
         }
      }
   };
   const resetLimit = () => {
      setLimit(step);
   };
   const setBlockedScrollState = (status: boolean) => {
      setBlockedScroll(status);
   };

   return {
      limit,
      updateLimitOnScroll,
      resetLimit,
      setBlockedScrollState,
   };
};
