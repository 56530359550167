import { lc } from "store/LC";
import moment from "moment";

/**
 * @DateMoment
 * Получаем таймзону вида Europe/Moscow
 * С помощью moment вытягиваем оффсет полученной таймзоны
 */
export const resolveAndSetTimezone = () => {
   const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
   const tzOffset = moment.tz(timezone).format("Z");
   if (!lc.get("timeZone") && !lc.get("timeZoneOffset")) {
      lc.set("timeZoneOffset", tzOffset);
      lc.set("timeZone", timezone);
   }
};
