// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotificationChatCard_container__1pLOc {\n    background-color: var(--color-black4);\n}", "",{"version":3,"sources":["webpack://src/components/Lk/components/Notifications/variants/NotificationChatCard/index.module.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;AACzC","sourcesContent":[".container {\n    background-color: var(--color-black4);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "NotificationChatCard_container__1pLOc"
};
export default ___CSS_LOADER_EXPORT___;
