import React, { useState } from "react";
import "./style.css";
import { Input, InputPropsType } from "../index";
import { PassInvis } from "../../../../assets/inputs/PassInvis";
import { PassVis } from "../../../../assets/inputs/PassVis";

type PassInputType<T> = {} & InputPropsType<T>;
export function PassInput<T>(props: PassInputType<T>) {
   const [isVisible, setIsVisible] = useState(true);

   const toggle = () => {
      setIsVisible(!isVisible);
   };

   return (
      <Input
         className='PassInput'
         type={!isVisible ? "text" : "password"}
         support={
            !isVisible ? (
               <PassVis onClick={toggle} />
            ) : (
               <PassInvis onClick={toggle} />
            )
         }
         {...props}
      />
   );
}
