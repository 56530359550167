import React, { FC } from "react";
import styles from "./style.module.css";
import { Button } from "components/UI/Button";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { routes } from "routes";
import { useTranslation } from "react-i18next";
import { lkSliceSelectors } from "store/lkSlice";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { lessonThunk } from "store/lessonSlice/thunk";
import { useGoToProfile } from "helpers/util/goToProfile";

type propsType = {
   variant: "default" | "teacher";
   lesson_id?: string;
};
export const LessonButtons: FC<propsType> = (props) => {
   const { id: user_id } = useSelector(lkSliceSelectors.getUserData);
   const { t } = useTranslation();
   const commonState = useLocalCommonState();
   const goToProfile = useGoToProfile(user_id);

   const reject = () => {
      commonState.dispatch(
         lessonThunk.deleteLesson({
            commonState,
            lesson_id: String(props.lesson_id),
            callbacks: {
               204: () => {
                  goToProfile();
               },
            },
         })
      );
   };

   return (
      <div className={styles.container}>
         {props.variant == "teacher" ? (
            <>
               <Button
                  className={styles.button}
                  variant={"primary"}
                  onClick={goToProfile}
               >
                  {t("Buttons.to-lk", "В личный кабинет")}
               </Button>
               <Button
                  variant={"red"}
                  onClick={reject}
                  loading={commonState.loading}
               >
                  {t(
                     "Lk.lesson-service.default.cancel-lesson",
                     "Отменить занятие"
                  )}
               </Button>
            </>
         ) : (
            <>
               <Button className={styles.button} variant={"primary"} onClick={goToProfile}>
                  {t("Buttons.to-lk", "В личный кабинет")}
               </Button>
               <Button
                  variant={"red"}
                  onClick={reject}
                  loading={commonState.loading}
               >
                  {t(
                     "Lk.lesson-service.default.cancel-lesson",
                     "Отменить занятие"
                  )}
               </Button>
            </>
         )}
      </div>
   );
};
