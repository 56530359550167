import React, { FC } from "react";
import styles from "./index.module.css";
import { Trans, useTranslation, withTranslation } from "react-i18next";
import { useMedia } from "hooks/useMedia";
import { TableItem } from "./TableItem";

type CardsTableType = {
   id: number;
   number: string;
   type: string;
   date: string;
};

type data = {
   data: CardsTableType[];
}

export const ThisComponent: FC<data> = ({ data }) => {
   const { t } = useTranslation();
   const { width } = useMedia();

   return (
      <div className={styles.container}>
         <table className={styles.table}>
            {width > 719 && (
               <tr className={styles.header}>
                  <th className={styles.header_item}>
                     {t(
                        "Payment.pages.AddCardsForPayment.headers.card-number",
                        "Номер карты"
                     )}
                  </th>
                  <th className={styles.header_item}>
                     {t(
                        "Payment.pages.AddCardsForPayment.headers.card-expiry-date",
                        "Дата прикрепления"
                     )}
                  </th>
                  <th className={styles.header_item}>
                     {t(
                        "Payment.pages.AddCardsForPayment.headers.payment-system-type",
                        "Тип платежной системы"
                     )}
                  </th>
                  <th className={styles.header_item} />
               </tr>
            )}

            {data?.map((item: any) => (
               <TableItem item={item} />
            ))}
         </table>
      </div>
   );
};

export const Table = withTranslation()(ThisComponent);
