import React, {
   FC,
   ReactNode,
   useCallback,
   useEffect,
   useMemo,
   useState,
} from "react";
import styles from "components/Lk/Pages/SchedulePage/Schedule/ScheduleCalendar/ScheduleDays/ScheduleDay/style.module.css";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import {
   scheduleFiltersType,
   scheduleSlice,
   scheduleSliceSelectors,
} from "store/scheduleSlice";
import { StateType } from "store";
import { ScheduleTicketTypes } from "components/UI/ScheduleStatuses";
import { format } from "date-fns";
import { authSliceSelectors } from "store/authSlice";
import { offsetMonth } from "helpers/schedule";
import moment from "moment-timezone";
import { studentType } from "types/userType";

type propsType = {
   day: number;
   role: "prev" | "next" | "curr";
   type: string;
};

const offsetResolver = {
   prev: -1,
   next: 1,
   curr: 0,
};

const scheduleTypeResolver = (
   ticket: {
      type: string;
      teacher: studentType;
   },
   userId: string
): scheduleFiltersType => {
   switch (ticket.type) {
      case "slot": {
         return "slot";
      }
      case "invite": {
         if (ticket.teacher.id == userId) {
            return "teacher_unproved";
         } else {
            return "student_unproved";
         }
      }
      case "lesson": {
         if (ticket.teacher.id == userId) {
            return "teacher_proved";
         } else {
            return "student_proved";
         }
      }
      case "group_lesson": {
         if (ticket.teacher.id == userId) {
            return "teacher_open";
         } else {
            return "student_open";
         }
      }
      default: {
         return "slot";
      }
   }
};

type ticketsPointsType = {
   [key in scheduleFiltersType]?: Array<any>;
};

export const ScheduleDay: FC<propsType> = ({ day, role, type }) => {
   // State
   const dispatch = useDispatch();
   const calendarDate = useSelector(scheduleSliceSelectors.getDate);
   const storeDay = useSelector(scheduleSliceSelectors.getDay);
   const tickets = useSelector(
      (state: StateType) => state.scheduleSlice.tickets
   );
   const clientUserId = useSelector(authSliceSelectors.getId);
   const filters = useSelector(
      (state: StateType) => state.scheduleSlice.filters
   );
   const [ticketsInDay, setTicketsInDay] = useState<ticketsPointsType>({});

   // Update tickets in current day
   useEffect(() => {
      if (!tickets) return;

      // const dayDate = format(
      //    new Date(calendarDate.toString()).setDate(day + 1),
      //    "dd-MM-yyyy"
      // );
      const dayDate = moment(calendarDate.toString())
         .set("date", day + 1)
         .format("DD-MM-YYYY");
      let tempTicketsInDay: ticketsPointsType = {};

      tickets[dayDate]?.forEach((item) => {
         const type = scheduleTypeResolver(item, clientUserId);
         if (!tempTicketsInDay[type]) {
            tempTicketsInDay[type] = [];
         }
         tempTicketsInDay[type]?.push(item);
      });

      setTicketsInDay(tempTicketsInDay);
   }, [tickets]);

   // methods
   const displayTicketPoints = useCallback(() => {
      let resultPoints: Array<ReactNode> = [];

      let ticketsInDayKey: scheduleFiltersType;
      for (ticketsInDayKey in ticketsInDay) {
         const category = ticketsInDay[ticketsInDayKey];
         if (!category) continue;

         if (
            (filters[ticketsInDayKey] || type == "sidebar") &&
            role == "curr"
         ) {
            resultPoints.push(
               <ScheduleTicketTypes
                  variant={scheduleTypeResolver(category[0], clientUserId)}
                  dateFrom={category[0].date_from}
                  dateTo={category[0].date_to}
                  length={category.length}
                  calendarType={type}
               />
            );
         }
      }
      return resultPoints;
   }, [ticketsInDay, filters]);

   const setMonth = () => {
      const month = offsetMonth(calendarDate, offsetResolver[role]);
      month.setDate(day + 1);
      dispatch(scheduleSlice.actions.setDay(month.toString()));
   };

   return (
      <div
         className={cn({
            [styles.day]: type != "sidebar",
            [styles.day_sidebar]: type == "sidebar",
            [styles.day_before]: role == "prev",
            [styles.day_after]: role == "next",
            [styles.day_chosen]:
               storeDay?.getDate() == day + 1 &&
               calendarDate.getMonth() == storeDay.getMonth() &&
               role == "curr",
         })}
         onClick={setMonth}
      >
         <p className={styles.day_number}>{day + 1}</p>
         <div className={styles.all_events}>
            <div className={styles.day_events}>{displayTicketPoints()}</div>
         </div>
      </div>
   );
};
