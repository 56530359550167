import React, { FC } from "react";
import styles from "./index.module.css";
import { Icons } from "assets/iconsCorrect";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { lessonThunk } from "store/lessonSlice/thunk";
import { inviteType } from "types/LessonService/types";
import { rejects } from "assert";
import { Preloader } from "components/UI/Preloader";

type propsType = {
   invite_id: string;
   slot_id: string;
   removeCardHandler: () => void;
};
export const ControlButtonsInviteCard: FC<propsType> = ({
   invite_id,
   removeCardHandler,
   slot_id,
}) => {
   const commonState = useLocalCommonState();

   const approveHandler = () => {
      commonState.dispatch(
         lessonThunk.createLessonByInvite({
            commonState,
            invite_id,
            callbacks: {
               201: () => {
                  removeCardHandler();
               },
            },
         })
      );
   };
   const declineHandler = () => {
      commonState.dispatch(
         lessonThunk.deleteInvite({
            commonState,
            invite_id,
            slot_id,
            callbacks: {
               204: () => {
                  removeCardHandler();
               },
            },
         })
      );
   };

   return (
      <div className={styles.container}>
         {commonState.loading ? (
            <Preloader loading={true} style={{ width: 24, height: 24 }} />
         ) : (
            <>
               <Icons.buttons.ApproveButtonIcon
                  onClick={approveHandler}
                  style={{
                     marginRight: 10,
                  }}
               />
               <Icons.buttons.RejectButtonIcon onClick={declineHandler} />
            </>
         )}
      </div>
   );
};
