import React, { FC, useEffect, useState } from "react";
import styles from "./style.module.css";
import { dateMethods } from "helpers/date";

type propsType = {
   icon?: FC<any>;
   title?: string;
   date: string;
};

export const NotificationCardHeader: FC<propsType> = ({
   icon,
   title,
   date,
}) => {
   return (
      <div className={styles.container}>
         <div className={styles.left}>
            {icon && React.createElement(icon, { className: styles.icon })}
            <div className={styles.title}>{title}</div>
         </div>
         <div className={styles.time}>{dateMethods.format(date, "HH:mm")}</div>
      </div>
   );
};
