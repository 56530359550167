import * as React from "react";

export function InputLoader(props: React.SVGProps<SVGSVGElement>) {
   return (
      <svg
         width={18}
         height={19}
         viewBox='0 0 18 19'
         fill='none'
         xmlns='http://www.w3.org/2000/svg'
         {...props}
      >
         <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M8.2 1.676a.8.8 0 01.8-.8 8.8 8.8 0 11-8.8 8.8.8.8 0 111.6 0 7.2 7.2 0 107.2-7.2.8.8 0 01-.8-.8z'
            fill='url(#prefix__paint0_linear)'
         />
         <defs>
            <linearGradient
               id='prefix__paint0_linear'
               x1={1}
               y1={17.675}
               x2={17}
               y2={17.675}
               gradientUnits='userSpaceOnUse'
            >
               <stop stopColor='#CCDBF6' />
               <stop offset={1} stopColor='#556BFB' />
            </linearGradient>
         </defs>
      </svg>
   );
}
