import { lkApi } from "api/LkApi/methods";
import { useState } from "react";
import { useQuery } from "react-query";

export const useGetCardByUserID = (user: any, type?: string) => {
    const [isOpened, setIsOpened] = useState(false);
    const { data, isLoading, error } = useQuery(
        "useGetCardByUserID",
        () => lkApi.getCardByUserID({
            user_id: user.id,
            type
        }),
        {
            enabled: isOpened,
        }
    );

    const toggleUserInfo = () => {
        setIsOpened((prevState) => !prevState);
    };

    return {
        isOpened,
        allCards: data?.data?.data,
        isLoading,
        error, toggleUserInfo
    };
};
