// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__1idx1 {\n    width: 100%;\n    padding-top: 90px;\n    margin-left: 20px;\n    max-width: 1400px;\n}\n\n@media screen and (max-width: 1439px) {\n    .style_container__1idx1 {\n        padding-right: 24px;\n        padding-left: 24px;\n    }\n}\n\n@media screen and (max-width: 1023px) {\n    .style_container__1idx1 {\n        margin-left: 0;\n        padding-top: 0px;\n    }\n}\n@media screen and (max-width: 719px) {\n    .style_container__1idx1 {\n        padding-right: 14px;\n        padding-left: 14px;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/AdminService/style.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI;QACI,mBAAmB;QACnB,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,cAAc;QACd,gBAAgB;IACpB;AACJ;AACA;IACI;QACI,mBAAmB;QACnB,kBAAkB;IACtB;AACJ","sourcesContent":[".container {\n    width: 100%;\n    padding-top: 90px;\n    margin-left: 20px;\n    max-width: 1400px;\n}\n\n@media screen and (max-width: 1439px) {\n    .container {\n        padding-right: 24px;\n        padding-left: 24px;\n    }\n}\n\n@media screen and (max-width: 1023px) {\n    .container {\n        margin-left: 0;\n        padding-top: 0px;\n    }\n}\n@media screen and (max-width: 719px) {\n    .container {\n        padding-right: 14px;\n        padding-left: 14px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__1idx1"
};
export default ___CSS_LOADER_EXPORT___;
