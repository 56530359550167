import { lkApi } from "api/LkApi/methods";
import { useMutation, useQueryClient } from "react-query";
import {useState} from "react";

export const useDeletePayment = ({payment_id}: any) => {
    const queryClient = useQueryClient();
    const [isInvalidating, setIsInvalidating] = useState(false);

    const mutation = useMutation(() => lkApi.deletePayment({
        payment_id
    }), {
        onSettled: (res, error) => {
            if (!error) {
                setIsInvalidating(true);
                queryClient.invalidateQueries("useGetPaymentByPaymentId");
            }
        },
    });

    const deletePayment = () => {
        mutation.mutate();
    };

    return {
        deletePayment,
        isLoading: mutation.isLoading || isInvalidating,
        error: mutation.isError ? mutation.error : null,
    };
};
