import React, { FC } from "react";
import styles from "components/Lk/Pages/Settings/ProfileSettings/style.module.css";
import { PhotoUploader } from "components/UI/PhotoUploader";
import { Input } from "components/UI/Input";
import { Calendar } from "components/UI/Calendar";
import moment from "moment-timezone";
import { Button } from "components/UI/Button";
import { Form } from "core/LTools/core/functional components/Form";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { useForm, UseFormClearErrors } from "react-hook-form";
import { modalWindowTemplateType, uiSlice } from "store/modalWindowSlice";
import { defaultFileType } from "api/types";
import { lkSLiceThunk } from "store/lkSlice/thunk";
import { dateMethods } from "helpers/date";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";
import { useGetUserAvatar } from "hooks/useGetUserAvatar";
import { ProfileSettingsFormType } from "components/Lk/Pages/Settings/ProfileSettings/index";
import { studentType } from "types/userType";
import { useLkContext } from "components/Lk/Pages/LkProfile";
import { getDateWithoutHours } from "helpers/date/ticketsGroud";
import { getDateWithoutTime } from "helpers/date/showDate";

export const MainUserDataSettings: FC = () => {
   const { t } = useTranslation();
   const user = useSelector(lkSliceSelectors.getUserData);
   const {
      control,
      handleSubmit,
      clearErrors,
      setValue,
      formState: { errors },
   } = useForm<ProfileSettingsFormType>({
      defaultValues: {
         name: user.first_name,
         lastName: user.last_name,
         birthDate: getDateWithoutTime(user.birth_date),
      },
   });
   const commonState = useLocalCommonState();
   const [avatar] = useGetUserAvatar(user.id);

   const successCaseHandler = () => {
      commonState.dispatch(
         uiSlice.actions.setModalType({
            template: "default" as modalWindowTemplateType["default"],
            payload: {
               title: t("Common.success", "Успешно"),
               button: "close",
               status: "success",
            },
         })
      );
   };

   const postFile = (file: defaultFileType) => {
      commonState.dispatch(
         lkSLiceThunk.uploadFile({
            user_id: user.id,
            commonState,
            ...file,
            callbacks: {
               201: successCaseHandler,
            },
         })
      );
   };

   const changeUserData = (data: any) => {
      commonState.dispatch(
         lkSLiceThunk.updateUser({
            commonState,
            user_id: user.id,
            payload: {
               birth_date: dateMethods.formatDMY(data.birthDate),
               first_name: data.name,
               last_name: data.lastName,
            },
            callbacks: {
               200: () => {
                  if (data?.avatar?.content) {
                     postFile({
                        content: data.avatar.content,
                        file_name: data.avatar.file_name,
                        file_type: "avatar",
                     });
                  } else {
                     successCaseHandler();
                  }
               },
            },
         })
      );
   };

   return (
      <Form
         commonState={commonState}
         clearErrors={clearErrors}
         onSubmit={handleSubmit(changeUserData)}
         errors={errors}
      >
         <div className={styles.photo_title}>
            {t("Settings.account.photo", "Ваше фото")}
         </div>
         <PhotoUploader defaultImg={avatar} name='avatar' setValue={setValue} />
         <div className={styles.bio_title}>
            {t("Settings.account.info-tile", "Информация о вас")}
         </div>
         <Input
            className={styles.input}
            control={control}
            name='name'
            label={t("Registration.StudentPages.name-label", "Имя")}
            error={commonState.errors?.first_name}
         />
         <Input
            className={styles.input}
            control={control}
            name='lastName'
            label={t("Registration.StudentPages.surname-label", "Фамилия")}
            error={commonState.errors?.last_name}
         />
         <Calendar
            className={styles.input}
            control={control}
            name='birthDate'
            placeholder={t(
               "Registration.StudentPages.birth-date-label",
               "Дата рождения"
            )}
            minDate={moment().subtract(100, "year").toDate()}
            maxDate={moment().subtract(6, "year").toDate()}
            error={commonState.errors?.birth_date}
         />
         <Button
            style={{
               marginTop: 36,
            }}
         >
            {t("Buttons.save", "Сохранить")}
         </Button>
      </Form>
   );
};
