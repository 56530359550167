// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_buttons_container__1DNou {\n    display: flex;\n}\n.style_buttons_container__1DNou > *:first-child {\n    margin-right: 16px;\n}\n\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n\n}\n\n@media screen and (max-width: 1023px) {\n    .style_buttons_container__1DNou button {\n        width: 100%;\n    }\n    .style_buttons_container__1DNou {\n        flex-direction: column;\n    }\n    .style_buttons_container__1DNou > *:first-child {\n        margin-right: 0px;\n        margin-bottom: 20px;\n    }\n}\n\n@media screen and (max-width: 719px) {\n\n}", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/LessonsService/Pages/GroupLesson/Buttons/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,kBAAkB;AACtB;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;IACI;QACI,WAAW;IACf;IACA;QACI,sBAAsB;IAC1B;IACA;QACI,iBAAiB;QACjB,mBAAmB;IACvB;AACJ;;AAEA;;AAEA","sourcesContent":[".buttons_container {\n    display: flex;\n}\n.buttons_container > *:first-child {\n    margin-right: 16px;\n}\n\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n\n}\n\n@media screen and (max-width: 1023px) {\n    .buttons_container button {\n        width: 100%;\n    }\n    .buttons_container {\n        flex-direction: column;\n    }\n    .buttons_container > *:first-child {\n        margin-right: 0px;\n        margin-bottom: 20px;\n    }\n}\n\n@media screen and (max-width: 719px) {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons_container": "style_buttons_container__1DNou"
};
export default ___CSS_LOADER_EXPORT___;
