import React, { FC } from "react";
import styles from "./style.module.css";
import { groupLessonViewVariant } from "../index";
import { LessonTitle } from "../../../components/LessonTitle";
import { UserWidget } from "components/UI/UserWidget";
import { LessonRating } from "../../../components/LessonRating";
import { lessonApi } from "api/lessonApi/methods";
import { useTranslation } from "react-i18next";
import { groupLessonType } from "types/LessonService/types";
import { Icons } from "assets/iconsCorrect";
import { useSelector } from "react-redux";
import { authSliceSelectors } from "../../../../../../../store/authSlice";
import { groupLessonApi } from "../../../../../../../api/groupLessonApi/methods";
import { StudentsList } from "../../../../../../UI/StudentsList";

type propsType = {
   variant: groupLessonViewVariant;
   groupLesson: groupLessonType;
};
export const GroupLessonHeader: FC<propsType> = ({ variant, groupLesson }) => {
   const { t } = useTranslation();

   const userWidget = () => (
      <UserWidget
         className={styles.user_widget}
         user={groupLesson.teacher}
         variant={"student"}
      />
   );

   const titleResolver = () => {
      switch (variant) {
         case "teacher":
            return t("Lk.lesson-service.group.title-1", "Открытый урок");
         case "student_registered":
            return t("Lk.lesson-service.group.title-1", "Открытый урок");
         case "student_unregistered":
            return t(
               "Lk.lesson-service.group.title-2",
               "Запись на открытый урок"
            );
         case "teacher_archive":
            return t("Lk.lesson-service.group.title-3", "В архиве");
         case "student_archive":
            return t("Lk.lesson-service.group.title-3", "В архиве");
      }
   };

   const headerResolver = () => {
      switch (variant) {
         case "teacher":
            return <StudentsList students={groupLesson.students} />;
         case "student_registered":
            return userWidget();
         case "student_unregistered":
            return userWidget();
         case "teacher_archive":
            return <StudentsList students={groupLesson.students} />;
         case "student_archive":
            return (
               <>
                  {userWidget()}
                  <LessonRating
                     votes={groupLesson?.votes || []}
                     apiMethod={groupLessonApi.vote}
                     lesson_id={String(groupLesson?.id)}
                     target={"teacher"}
                  />
               </>
            );
         default:
            return null;
      }
   };

   return (
      <div className={styles.container}>
         <LessonTitle title={titleResolver()} icon={<Icons.ui.Microphone />} />
         <main className={"Lessons__main"}>{headerResolver()}</main>
      </div>
   );
};
