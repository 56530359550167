import React, { FC } from "react";
import styles from "./style.module.css";
import { ReactComponent as Hat } from "./assets/Hat.svg";
import { Button } from "../../../UI/Button";
import { Link } from "react-router-dom";
import { routes } from "routes";
import { useTranslation } from "react-i18next";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";
import { userStatusResolver } from "staticData/userStatusResolver";
import { useGoToProfile } from "helpers/util/goToProfile";

export const Done: FC = () => {
   const { t } = useTranslation();
   const user = useSelector(lkSliceSelectors.getUserData);
   const goToProfile = useGoToProfile(user.id);

   return (
      <div className={styles.container}>
         <Hat />
         <div className={styles.title}>
            {t(
               "Registration.TeacherPages.Done.title",
               "Спасибо за заполение анкеты"
            )}
         </div>
         <div className={styles.description}>
            {t(
               "Registration.TeacherPages.Done.description",
               "Регистрация не закончена.  Заполните  расписание"
            )}
         </div>
         <Button
            className={"Registration__button"}
            variant={"primary"}
            onClick={goToProfile}
         >
            {t("Registration.continue-button", "Продолжить")}
         </Button>
      </div>
   );
};
