import { useEffect, useState } from "react";

export const useDebounce = (value: any, interval?: number) => {
   const [result, setResult] = useState<any>(value);
   const [timeoutId, setTimeoutId] = useState(0);

   useEffect(() => {
      window.clearTimeout(timeoutId);
      setTimeoutId(window.setTimeout(() => setResult(value), interval || 400));
   }, [value]);

   return result;
};
