import { useEffect } from "react";

export function useScrollToError(errors: any) {
   useEffect(() => {
      const errorsvalues = Object.values(errors) as any;
      if (errorsvalues.length > 0) {
         if (typeof errorsvalues[0]?.ref?.scrollIntoView == "function") {
            errorsvalues[0]?.ref?.scrollIntoView({ behavior: "smooth" });
         }
      }
   }, [errors]);
}
