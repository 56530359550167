import * as yup from "yup";
import i18n from "../../../../../../services/translateService/i18n";

export const schemeWithNoAddress = yup.object().shape({
   name: yup
      .string()
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      )
      .nullable(),
   account_number: yup
      .string()
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      )
      .nullable(),
   sort_code_or_routing_number: yup
      .string()
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      )
      .nullable(),
});

export const schemeWithAddress = yup.object().shape({
   name: yup
      .string()
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      )
      .nullable(),
   account_number: yup
      .string()
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      )
      .nullable(),
   sort_code_or_routing_number: yup
      .string()
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      )
      .nullable(),
   recipient_bank_country: yup
      .object()
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      ),
   state_or_province: yup
      .string()
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      ),
   address_line_1: yup
      .string()
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      ),
   address_line_2: yup
      .string()
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      ),
   city: yup
      .string()
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      ),
   postal_code: yup
      .string()
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      ),
});

export const schemeForIBAN = yup.object().shape({
   name: yup
      .string()
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      )
      .nullable(),
   account_number: yup
      .string()
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      )
      .nullable(),
   recipient_bank_country: yup
      .object()
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      ),
   state_or_province: yup
      .string()
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      ),
   address_line_1: yup
      .string()
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      ),
   address_line_2: yup
      .string()
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      ),
   city: yup
      .string()
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      ),
   postal_code: yup
      .string()
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      ),
});
