// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__1Fg-K {\n    margin-bottom: 30px;\n}\n\n.style_title__358x6 {\n    font-weight: 500;\n    font-size: 28px;\n    margin-bottom: 20px;\n}\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n\n}\n\n@media screen and (max-width: 1023px) {\n\n}\n\n@media screen and (max-width: 719px) {\n    .style_title__358x6 {\n        font-style: normal;\n        font-weight: 600;\n        font-size: 22px;\n        line-height: 30px;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/UI/ContentBlockContainer/style.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,mBAAmB;AACvB;AACA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;IACI;QACI,kBAAkB;QAClB,gBAAgB;QAChB,eAAe;QACf,iBAAiB;IACrB;AACJ","sourcesContent":[".container {\n    margin-bottom: 30px;\n}\n\n.title {\n    font-weight: 500;\n    font-size: 28px;\n    margin-bottom: 20px;\n}\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n\n}\n\n@media screen and (max-width: 1023px) {\n\n}\n\n@media screen and (max-width: 719px) {\n    .title {\n        font-style: normal;\n        font-weight: 600;\n        font-size: 22px;\n        line-height: 30px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__1Fg-K",
	"title": "style_title__358x6"
};
export default ___CSS_LOADER_EXPORT___;
