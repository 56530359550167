import {useState} from "react";

export const useScrollLimitExtend = (props: {
    step: number;
    difference?: number;
    reverse?: boolean
}) => {
    const {step, difference = 800, reverse = false} = props;
    const [limit, setLimit] = useState(step);
    const [blockedScroll, setBlockedScroll] = useState<boolean>(false)
    const updateLimitOnScroll = (e: any) => {
        if (!reverse && !blockedScroll) {

            if (
                e.currentTarget.scrollHeight - e.currentTarget.scrollTop <
                difference
            ) {
                setLimit((prev) => prev + step);
                setBlockedScroll(true)
            }
        }
        if (reverse && !blockedScroll) {
            if (
                e.currentTarget.scrollHeight - (e.currentTarget.scrollHeight - e.currentTarget.scrollTop) <
                difference
            ) {
                setLimit((prev) => prev + step);
                setBlockedScroll(true)

            }

        }
    };
    const resetLimit = () => {
        setLimit(step);
    };
    const setBlockedScrollState = (status : boolean) => {
        setBlockedScroll(status);
    };

    return {
        limit,
        updateLimitOnScroll,
        resetLimit,
        setBlockedScrollState
    };
};
