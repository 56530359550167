import * as React from "react";

export function PassInvis(props: React.SVGProps<SVGSVGElement>) {
   return (
      <svg
         width={16}
         height={14}
         viewBox='0 0 16 14'
         fill='none'
         xmlns='http://www.w3.org/2000/svg'
         {...props}
      >
         <path
            d='M7.998 3.34a3.335 3.335 0 013.334 3.334c0 .43-.087.84-.237 1.216l1.95 1.95a7.917 7.917 0 002.29-3.166c-1.157-2.927-4-5-7.337-5-.933 0-1.826.166-2.656.466l1.44 1.437a3.35 3.35 0 011.216-.237zM1.332 1.524l1.52 1.52.303.303a7.896 7.896 0 00-2.49 3.327c1.153 2.926 4 5 7.333 5 1.034 0 2.02-.2 2.924-.563l.283.283 1.943 1.947.85-.847L2.182.674l-.85.85zm3.686 3.683l1.03 1.03a2.002 2.002 0 001.95 2.437c.15 0 .294-.02.434-.05l1.03 1.03a3.28 3.28 0 01-1.464.353 3.335 3.335 0 01-3.333-3.333c0-.527.133-1.02.353-1.467zm2.87-.523l2.1 2.1.01-.11c0-1.104-.896-2-2-2l-.11.01z'
            fill='#000'
         />
      </svg>
   );
}
