import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StateType } from "../index";
import { scheduleApiExtraReducers } from "./thunk";
import { timelineTicketsGroupHandler } from "store/scheduleSlice/helpers/timelineTicketsGroupHandler";
import { ticketsGroupHandler } from "store/scheduleSlice/helpers/ticketsGroupHandler";

type ticketType = "lessons" | "slots" | "invites" | "groupLessons";

export type scheduleFiltersType =
   | "student_open"
   | "student_proved"
   | "student_unproved"
   | "teacher_open"
   | "teacher_proved"
   | "slot"
   | "teacher_unproved";
export type scheduleSliceType = {
   date: string;
   day: string;
   loading: boolean;
   tickets: { [key: string]: any[] };
   ticketsForTimeline: {
      [key: string]: {
         hours: Array<{
            hour: string;
            tickets: any[];
         }>;
      };
   };
   isFiltersOpened: boolean;
   filters: {
      [key in scheduleFiltersType]: boolean;
   };
   ticketsRaw: {
      lessons: any[];
      invites: any[];
      groupLessons: any[];
      slots: any[];
   };
};

const initialState = {
   date: new Date().toString(),
   day: new Date().toString(),
   filters: {
      student_open: true,
      student_proved: true,
      student_unproved: true,
      teacher_open: true,
      teacher_proved: true,
      slot: true,
      teacher_unproved: true,
   },
   isFiltersOpened: false,
   loading: false,
   ticketsRaw: {},
} as scheduleSliceType;

export const scheduleSlice = createSlice({
   name: "scheduleSlice",
   initialState,
   extraReducers: {
      ...scheduleApiExtraReducers,
   },
   reducers: {
      setDate: (state, action: PayloadAction<string>) => {
         state.date = action.payload;
      },
      setDay: (state, action: PayloadAction<string>) => {
         state.day = action.payload;
         state.date = action.payload;
      },
      setFilters: (state, action: PayloadAction<any>) => {
         state.filters = action.payload;
      },
      setIsFiltersOpened: (state, action: PayloadAction<boolean>) => {
         state.isFiltersOpened = action.payload;
      },
      updateTickets: (
         state,
         {
            payload,
         }: PayloadAction<
            {
               type: ticketType;
            } & { [key: string]: any }
         >
      ) => {
         const newGroupTickets = [...state.ticketsRaw[payload.type]];
         newGroupTickets.unshift(payload);

         state.ticketsRaw[payload.type] = newGroupTickets;

         console.log(state.ticketsRaw[payload.type]);
         // state.ticketsForTimeline = timelineTicketsGroupHandler(payload);
         // state.tickets = ticketsGroupHandler(payload);
      },
      resetFilter: (
         state,
         action: PayloadAction<
            | "student_open"
            | "student_proved"
            | "student_unproved"
            | "teacher_open"
            | "teacher_proved"
            | "slot"
            | "teacher_unproved"
         >
      ) => {
         state.filters[action.payload] = false;
      },
      clear: () => {
         return { ...initialState };
      },
   },
});

export const scheduleSliceSelectors = {
   getDate: (state: StateType) => new Date(state.scheduleSlice.date),
   getDay: (state: StateType) => new Date(state.scheduleSlice.day),
};
