// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__VdV3n {\n  background: var(--color-black4);\n}\n\n.style_role_inactive_to_student__1px1r {\n  background: #FEE7EB;\n}\n\n.style_role_inactive_to_teacher__1UlnQ, .style_role_teacher_to_admin__sjFcK {\n  background: var(--color-blue4);\n}\n\n\n.style_buttons__2I8aq {\n  display: flex;\n  justify-content: space-between;\n}\n\n.style_button__2L2sn {\n  font-family: var(--font-Manrope);\n  color: var(--color-blue2);\n  font-size: 14px;\n  font-weight: 500;\n}", "",{"version":3,"sources":["webpack://src/components/Lk/components/Notifications/variants/NotificationStatusCard/style.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;AAChC;;;AAGA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,gCAAgC;EAChC,yBAAyB;EACzB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".container {\n  background: var(--color-black4);\n}\n\n.role_inactive_to_student {\n  background: #FEE7EB;\n}\n\n.role_inactive_to_teacher, .role_teacher_to_admin {\n  background: var(--color-blue4);\n}\n\n\n.buttons {\n  display: flex;\n  justify-content: space-between;\n}\n\n.button {\n  font-family: var(--font-Manrope);\n  color: var(--color-blue2);\n  font-size: 14px;\n  font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__VdV3n",
	"role_inactive_to_student": "style_role_inactive_to_student__1px1r",
	"role_inactive_to_teacher": "style_role_inactive_to_teacher__1UlnQ",
	"role_teacher_to_admin": "style_role_teacher_to_admin__sjFcK",
	"buttons": "style_buttons__2I8aq",
	"button": "style_button__2L2sn"
};
export default ___CSS_LOADER_EXPORT___;
