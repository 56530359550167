import { QueryClient } from "react-query";
import { store } from "../store";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
            cacheTime: 0,
            refetchOnWindowFocus: false,
        },
        mutations: {
        },
    },
});
