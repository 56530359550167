import {
   callbacksType,
   StatusCodeType,
   statusHandlersType,
} from "../types/types";
export type callCallbacksType = {
   methods: statusHandlersType;
   callbacks?: callbacksType;
   response: any;
};
export const callCallbacks = ({
   callbacks,
   methods,
   response,
}: callCallbacksType) => {
   const status = response.status as StatusCodeType;
   methods[status]?.call({});
   if (callbacks && callbacks[status]) {
      callbacks[status]?.call({}, response);
   }
};
