import { createRequest } from "core/LTools/core/apiMethods/createRequest";
import {
   getEducationSystemByIdType,
   getEducationSystemType,
   getGradeType,
   gradeIdType,
   languageIdType,
   levelIdType,
   subjectIdType,
} from "./types";
import axios from "axios";
import { mainAxios } from "../index";
import { dataToQueryString } from "core/LTools/core/apiMethods/dataToQueryString";

export const formsDataApi = {
   getLanguage: async () => createRequest(mainAxios.get("/language")),
   getLanguageById: async ({ language_id }: languageIdType) =>
      createRequest(mainAxios.get(`/language/${language_id}`)),

   getSubject: async () => createRequest(mainAxios.get("/subject")),
   getSubjectById: async ({ subject_id }: subjectIdType) =>
      createRequest(mainAxios.get(`/subject/${subject_id}`)),

   getGrade: async (props: getGradeType) =>
      createRequest(mainAxios.get(`/grade${dataToQueryString(props)}`)),
   getGradeById: async ({ grade_id }: gradeIdType) =>
      createRequest(mainAxios.get(`/subject/${grade_id}`)),

   getLevel: async () => createRequest(mainAxios.get("/level")),
   getLevelById: async ({ level_id }: levelIdType) =>
      createRequest(mainAxios.get(`/level/${level_id}`)),

   getEducationSystem: async ({ level_id }: getEducationSystemType) =>
      createRequest(mainAxios.get(`/level/${level_id}/education-system`)),
   getEducationSystemById: async ({
      level_id,
      education_system_id,
   }: getEducationSystemByIdType) =>
      createRequest(
         mainAxios.get(
            `/level/${level_id}/education-system/${education_system_id}`
         )
      ),
};
