import React, { FC } from "react";
import styles from "./style.module.css";
import { StudentWorksheet } from "./Student";
import { TeacherWorksheet } from "components/Lk/Pages/Settings/Worksheet/TeacherWorksheet";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";

type propsType = {};

export const Worksheet: FC<propsType> = (props) => {
   const user = useSelector(lkSliceSelectors.getUserData);

   return (
      <div className={styles.container}>
         <StudentWorksheet />
         {user.type == "teacher" && <TeacherWorksheet />}
      </div>
   );
};
