import { Icons } from "assets/iconsCorrect";
import React, { AllHTMLAttributes, FC, HTMLAttributes, ReactNode } from "react";
import styles from "./index.module.css";
import cn from "classnames";

type propsType = {
   loading: boolean;
   variant?: "fixed" | "large" | "centred" | "lesson-service" | "small" | "middle";
} & HTMLAttributes<HTMLOrSVGElement>;
// @ts-ignore
export const Preloader: FC<propsType> = ({
   loading,
   variant,
   children,
   ...attributes
}) => {
   if (!loading) return children || null;
   return (
      <Icons.ui.Preloader
         {...attributes}
         className={cn(styles.preloader, {
            [`${styles["preloader--fixed"]}`]: variant == "fixed",
            [`${styles["preloader--small"]}`]: variant == "small",
            [`${styles["preloader--middle"]}`]: variant == "middle",
            [`${styles["preloader--large"]}`]: variant == "large",
            [`${styles["preloader--centred"]}`]: variant == "centred",
            [`${styles["preloader--lesson-service"]}`]:
               variant == "lesson-service",
            [`${attributes.className}`]: attributes.className,
         })}
      />
   );
};
