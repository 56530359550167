import React, { FC } from "react";
import styles from "./index.module.css";
import cn from "classnames";
import { ReactComponent as Checked } from "../../../../assets/messageChecked.svg";
import { ReactComponent as Warning } from "../../../../assets/warning.svg";
import { FileDownloader } from "components/UI/FileDownloader";
import {useGetUserAvatar} from "hooks/useGetUserAvatar";
import {studentType} from "types/userType";
import {MessageType} from "store/chatSlice";
import {useSelector} from "react-redux";
import {lkSliceSelectors} from "store/lkSlice";


type PropType = {

};

export const LoadMessage: FC<PropType> = (props) => {




   return (
      <div
         className={cn(styles.container, {
            [styles.ownerMessage]: Math.random() < 0.5,
         })}
      >
         <div className={styles.avatar}>
         </div>
         <div className={styles.body}>

         </div>

      </div>
   );
};
