import React, { FC, useEffect, useState } from "react";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { notificationPropsType } from "../../types";
import { useQuery } from "hooks/useQuery";
import { userApi } from "api/userApi/methods";
import { lessonApi } from "api/lessonApi/methods";
import { LessonItemType } from "components/Lk/Pages/Notebook/components/NoteHistory/components/Table";
import { inviteApi } from "api/inviteApi/methods";
import { dateMethods } from "helpers/date";

type propsType = {
    notification : notificationPropsType
};

export const NotificationCardSuggestInfo: FC<propsType> = ({
   notification
}) => {
   const { t } = useTranslation();

   return (
      <div className={styles.container}>
         <div className={styles.title}>
             {notification.entity.application.subject.education_system.name}
         </div>
         <div className={styles.value}>
            <div className={styles.text}>
               {notification.entity.application.subject.name}
            </div>
         </div>
          <div className={styles.title}>{t("Lk.Notifications.duration", "Продолжительность")}</div>
         <div className={styles.date}>
            <div className={styles.text}>
               {notification.entity.application.duration}
            </div>
         </div>
      </div>
   );
};
