// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PrivacyPolicy_container__1bRE5 {\n    margin: 50px 80px;\n    line-height: 120%;\n}\n\n.PrivacyPolicy_container__1bRE5 h3 {\n    font-weight: 800;\n}\n\n.PrivacyPolicy_container__1bRE5 p {\n    margin-bottom: 15px;\n}\n", "",{"version":3,"sources":["webpack://src/components/PrivacyPolicy/index.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".container {\n    margin: 50px 80px;\n    line-height: 120%;\n}\n\n.container h3 {\n    font-weight: 800;\n}\n\n.container p {\n    margin-bottom: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "PrivacyPolicy_container__1bRE5"
};
export default ___CSS_LOADER_EXPORT___;
