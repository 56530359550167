import React, { FC, useEffect, useState } from "react";
import styles from "./style.module.css";
import { NotificationCardHeader } from "../../components/Header";
import { NotificationCardUser } from "../../components/User";
import { NotificationCardMessage } from "../../components/Message";
import { NotificationCardRating } from "../../components/Rating";
import { NotificationCardDate } from "../../components/Date";
import { NotificationCardButtons } from "../../components/Buttons";
import { notificationCardIcons } from "../../assets";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { notificationPropsType } from "../../types";
import { NotificationCardWrapper } from "../../components/NotificationCardWrapper";
import { useQuery } from "hooks/useQuery";
import { inviteApi } from "api/inviteApi/methods";
import { groupLessonType, inviteType } from "types/LessonService/types";
import { lessonApi } from "api/lessonApi/methods";
import { LessonItemType } from "components/Lk/Pages/Notebook/components/NoteHistory/components/Table";
import { groupLessonApi } from "api/groupLessonApi/methods";
import { userApi } from "api/userApi/methods";
import { teacherType } from "types/userType";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";

type propsType = { notification: notificationPropsType; isPush?: boolean };
export const NotificationGroupLessonCard: FC<propsType> = (props) => {
   const { notification, isPush = false } = props;
   const { t } = useTranslation();

   const iconResolver = (): any => {
      switch (notification.notification_type) {
         case "join":
            return notificationCardIcons.calendar;
         case "join_participation":
            return notificationCardIcons.calendar;
         case "reject_participation":
            return notificationCardIcons.cross;
         case "reject_lesson":
            return notificationCardIcons.cross;
         case "finish_lesson":
            return notificationCardIcons.cross;
         default:
            return notificationCardIcons.clock;
      }
   };

   const titleResolver = (): any => {
      switch (notification.notification_type) {
         case "join":
            return t(
               "Lk.Notifications.GroupLessonCard.title-join",
               "Запись на открытый урок"
            );
         case "join_participation":
            return t(
               "Lk.Notifications.GroupLessonCard.title-join",
               "Запись на открытый урок"
            );

         case "reject_participation":
            return t(
               "Lk.Notifications.GroupLessonCard.title-cancel",
               "Отмена записи на урок"
            );
         case "reject_lesson":
            return t(
               "Lk.Notifications.GroupLessonCard.title-reject",
               "Отмена открытого урока"
            );

         case "start_lesson":
            return t(
               "Lk.Notifications.LessonCard.title-join",
               "Занятие началось"
            );
         case "finish_lesson":
            return t(
               "Lk.Notifications.LessonCard.title-rate",
               "Оцените ваше занятие"
            );
         default:
            return "";
      }
   };

   return (
      <NotificationCardWrapper
         className={cn(styles.container, {
            [styles[`${notification.notification_type}`]]: true,
         })}
      >
         <NotificationCardHeader
            date={notification.date}
            icon={iconResolver()}
            title={titleResolver()}
         />
         <NotificationCardUser user={notification.related_entity} />

         {notification.notification_type === "finish_lesson" ? (
            <NotificationCardRating
               isGroupLesson={true}
               notification={notification}
            />
         ) : notification.notification_type !== "reject_lesson" ? (
            <NotificationCardDate
               entity_type={notification.entity_type}
               date_to={notification.entity.date_to}
               date_from={notification.entity.date_from}
               notification_type={notification.notification_type}
            />
         ) : null}

         <NotificationCardButtons
            entity_id={Number(notification.entity.id)}
            isPush={isPush}
            notification={notification}
         />
      </NotificationCardWrapper>
   );
};
