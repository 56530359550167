import React, { FC } from "react";
import styles from "./index.module.css";
import { NoteTableItem } from "./TableItem";
import { studentType, teacherType } from "types/userType";
import { subjectType, voteType } from "store/types";
import { useSelector } from "react-redux";
import { authSliceSelectors } from "store/authSlice";
import { Trans, useTranslation, withTranslation } from "react-i18next";
import { lessonType } from "types/LessonService/types";
import { Preloader } from "components/UI/Preloader";
import { useMedia } from "hooks/useMedia";

export type LessonItemType = {
   id: number;
   status: "";
   topic: string;
   student: studentType;
   teacher: teacherType;
   subject: subjectType;
   date_from: string;
   date_to: string;
   votes: Array<voteType>;
   rejectDate: string;
};

type AdminTableType = {
   data: any;
   loading?: boolean;
   type?: "lesson" | "group_lesson";
};

export const ThisComponent: FC<AdminTableType> = (props) => {
   const { t } = useTranslation();
   const { data } = props;
   const id = useSelector(authSliceSelectors.getId);
   const { width } = useMedia();

   if (props.loading) {
      return (
         <div className={styles.container}>
            <table className={styles.table}>
               <Preloader loading={true} />
            </table>
         </div>
      );
   }
   return (
      <div className={styles.container}>
         <table className={styles.table}>
            {width > 719 && (
               <tr className={styles.header}>
                  {/*<th />*/}
                  <th className={styles.header_item}>
                     {t("FinancialOperations.headers.subject", "Предмет")}
                  </th>
                  <th className={styles.header_item}>
                     <Trans i18nKey={"FinancialOperations.headers.date-and-time"}>
                        Дата и время <br /> занятия
                     </Trans>
                  </th>
                  <th className={styles.header_item}>
                     <Trans i18nKey={"Common.userRole.user"}>
                        ФИО ученика
                     </Trans>
                  </th>
                  {width > 1023 ? (
                     <>
                        <th className={styles.header_item}>
                           <Trans i18nKey={"FinancialOperations.headers.cost"}>
                              Стоимость
                           </Trans>
                        </th>
                        <th className={styles.header_item}>
                           <Trans i18nKey={"FinancialOperations.headers.duration"}>
                              Тип платежа
                           </Trans>
                        </th>
                        <th className={styles.header_item}>
                           <Trans i18nKey={"FinancialOperations.headers.payment-status"}>
                              Статус платежа
                           </Trans>
                        </th>
                        <th className={styles.header_item} />
                     </>
                  ) : (
                     <>
                        <th className={styles.header_item}>
                           <Trans i18nKey={"FinancialOperations.headers.duration-and-cost"}>
                              Продолжительность <br /> и стоимость
                           </Trans>
                        </th>
                        <th className={styles.header_item}>
                           <Trans i18nKey={"FinancialOperations.headers.payment-status-and-type"}>
                              Статус и тип <br/> платежа
                           </Trans>
                        </th>
                        <th className={styles.header_item} />
                     </>
                  )}

               </tr>
            )}
            {data?.map((item: any, index: any) => (
               <NoteTableItem
                  // user_id={id}
                  lesson={item}
                  key={item.id}
                  // type={props.type}
               />
            ))}
         </table>
      </div>
   );
};

export const NoteTable = withTranslation()(ThisComponent);
