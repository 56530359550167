import React, { FC } from "react";
import { useForm, useWatch } from "react-hook-form";
import { PassInput } from "../../../UI/Input/PassInput";
import { useHistory } from "react-router-dom";
import { Button } from "../../../UI/Button";
import { routes } from "routes";
import { useSelector } from "react-redux";
import { passwordSchema } from "helpers/validationSchemes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { authThunks } from "store/authSlice/thunks";
import { authSliceSelectors } from "store/authSlice";
import { useTranslation } from "react-i18next";
import styles from "./index.module.css";
import { Form } from "core/LTools/core/functional components/Form";
import cn from "classnames";
import { Preloader } from "components/UI/Preloader";

type propsType = {};
type createPasswordFormType = {
   email: string;
   password: string;
   password_confirm: string;
};

export const CreatePassword: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const history = useHistory();
   const email = useSelector(authSliceSelectors.getEmail);
   const {
      control,
      handleSubmit,
      formState: { errors },
      clearErrors,
   } = useForm<createPasswordFormType>({
       // @ts-ignore
       resolver: yupResolver(passwordSchema),
      reValidateMode: "onSubmit",
   });
   const commonState = useLocalCommonState();
   const code = useSelector(authSliceSelectors.getCode);
   const form = useWatch({ control });
   const submit = ({ password, password_confirm }: createPasswordFormType) => {
      commonState.dispatch(
         authThunks.createPassword({
            commonState,
            code,
            password,
            password_confirm,
            callbacks: {
               "200": () => {
                  commonState.location =
                     routes.registration.student.fillInformation;
               },
            },
         })
      );
   };

   const anotherAccount = () => {
      history.push(routes.registration.auth.firstStage);
   };
   return (
      <div className='CreatePassword'>
         <p className={styles.text}>
            {t(
               "Registration.Auth.CreatePassword.title",
               "Придумайте пароль для аккаунта, зарегестрированного на email"
            )}
            <br />
            <b>{email}</b>
         </p>

         <Button
            variant='link'
            onClick={anotherAccount}
            className={styles.button_link}
         >
            {t(
               "Registration.Auth.CreatePassword.another-account",
               "Другой аккаунт"
            )}
         </Button>
         <Form
            commonState={commonState}
            errors={errors}
            clearErrors={clearErrors}
            onSubmit={handleSubmit(submit)}
            className='flex-col'
         >
            <PassInput
               className={styles.input}
               autoFocus
               control={control}
               name='password'
               label={t(
                  "Registration.Auth.CreatePassword.password-label",
                  "Введите пароль"
               )}
               error={errors.password?.message || commonState.errors.password}
            />
            <PassInput
               className={styles.input}
               control={control}
               name='password_confirm'
               label={t(
                  "Registration.Auth.CreatePassword.confirm-password-label",
                  "Повторите пароль"
               )}
               error={
                  errors.password_confirm?.message ||
                  commonState.errors.password_confirm
               }
            />
            <Button
               variant={"primary"}
               disabled={
                  !(form.password && form.password == form.password_confirm)
               }
               className={cn(styles.button_action, {
                  "x-centred": true,
               })}
               loading={commonState.loading}
            >
               {t(
                  "Registration.Auth.CreatePassword.continue-button",
                  "Продолжить"
               )}
            </Button>
         </Form>
      </div>
   );
};
