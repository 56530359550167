import styles from "./index.module.css";
import React, { FC } from "react";
import { paymentSystemType } from "../../Pages/AddCardForPayout";

type propsType = {
   active: paymentSystemType;
   value: paymentSystemType;
   disabled: boolean;
   handleChange: (event: React.ChangeEvent<HTMLInputElement & { value: paymentSystemType }>) => void;
};

export const RadioBlock: FC<propsType> = ({
   active,
   value,
   disabled,
   handleChange
}) => {
   return (
      <div className={styles.container}>
         <div className={styles.item}>
            <label className={styles.label}>
               <input
                  type="radio"
                  id={"us-usd"}
                  name={"payment-system"}
                  value={"us-usd"}
                  className={styles.input}
                  onChange={handleChange}
                  checked={value === "us-usd"}
                  disabled={disabled && active !== "us-usd"}
               />
               <p>US USD</p>
            </label>
         </div>
         <div className={styles.item}>
            <label className={styles.label}>
               <input
                  type="radio"
                  id={"iban"}
                  name={"payment-system"}
                  value={"iban"}
                  className={styles.input}
                  onChange={handleChange}
                  checked={value === "iban"}
                  disabled={disabled && active !== "iban"}
               />
               <p>IBAN</p>
            </label>
         </div>
         <div className={styles.item}>
            <label className={styles.label}>
               <input
                  type="radio"
                  id={"swift"}
                  name={"payment-system"}
                  value={"swift"}
                  className={styles.input}
                  onChange={handleChange}
                  checked={value === "swift"}
                  disabled={disabled && active !== "swift"}
               />
               <p>SWIFT/SWIFT MX</p>
            </label>
         </div>
         {/*<div className={styles.item}>*/}
         {/*   <label className={styles.label}>*/}
         {/*      <input*/}
         {/*         type="radio"*/}
         {/*         id={"swift-mx"}*/}
         {/*         name={"payment-system"}*/}
         {/*         value={"swift-mx"}*/}
         {/*         className={styles.input}*/}
         {/*         onChange={handleChange}*/}
         {/*         disabled={disabled && active !== "swift-mx"}*/}
         {/*      />*/}
         {/*      <p>SWIFT MX</p>*/}
         {/*   </label>*/}
         {/*</div>*/}
         {/*<div className={styles.item}>*/}
         {/*   <label className={styles.label}>*/}
         {/*      <input*/}
         {/*         type="radio"*/}
         {/*         id={"in-inr"}*/}
         {/*         name={"payment-system"}*/}
         {/*         value={"in-inr"}*/}
         {/*         className={styles.input}*/}
         {/*         onChange={handleChange}*/}
         {/*         // disabled={true}*/}
         {/*      />*/}
         {/*      <p>IN INR</p>*/}
         {/*   </label>*/}
         {/*</div>*/}
         {/*<div className={styles.item}>*/}
         {/*   <label className={styles.label}>*/}
         {/*      <input*/}
         {/*         type="radio"*/}
         {/*         id={"au-aud"}*/}
         {/*         name={"payment-system"}*/}
         {/*         value={"au-aud"}*/}
         {/*         className={styles.input}*/}
         {/*         onChange={handleChange}*/}
         {/*         disabled={true}*/}
         {/*      />*/}
         {/*      <p>AU AUD</p>*/}
         {/*   </label>*/}
         {/*</div>*/}
      </div>
   );
};
