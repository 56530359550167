// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RegistrationCode_text__2xS8Z {\n    font-size: 14px;\n    margin-bottom: 20px;\n}\n.RegistrationCode_button_link__3zu6y {\n    margin-bottom: 16px;\n}\n.RegistrationCode_action_title__3PGv1 {\n    font-weight: 500;\n    font-size: 24px;\n    margin-top: 50px;\n    margin-bottom: 25px;\n    text-align: center;\n}\n", "",{"version":3,"sources":["webpack://src/components/Registration/Auth/RegistrationCode/index.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,mBAAmB;AACvB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".text {\n    font-size: 14px;\n    margin-bottom: 20px;\n}\n.button_link {\n    margin-bottom: 16px;\n}\n.action_title {\n    font-weight: 500;\n    font-size: 24px;\n    margin-top: 50px;\n    margin-bottom: 25px;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "RegistrationCode_text__2xS8Z",
	"button_link": "RegistrationCode_button_link__3zu6y",
	"action_title": "RegistrationCode_action_title__3PGv1"
};
export default ___CSS_LOADER_EXPORT___;
