import React, { FC } from "react";
import styles from "./style.module.css";
import { Route } from "react-router-dom";
import { routes } from "routes";
import { AdminRoot } from "./Pages/Root";
import { AdminTeacherActivation } from "./Pages/TeacherActivation";
import { AdminUsersPanel } from "./Pages/Users";
import { SystemsOverview } from "./Pages/EducationSystems/Pages/SystemsOverview";
import { EducationSystems } from "./Pages/EducationSystems";

type propsType = {};
export const AdminService: FC<propsType> = (props) => {
   return (
      <div className={styles.container}>
         <Route exact path={routes.lk.admin.root}>
            <AdminRoot />
         </Route>
         <Route path={routes.lk.admin.activation + "/:id?"}>
            <AdminTeacherActivation />
         </Route>
         <Route path={routes.lk.admin.users}>
            <AdminUsersPanel />
         </Route>
         <Route path={routes.lk.admin.educationSystems.root}>
            <EducationSystems />
         </Route>
      </div>
   );
};
