import i18n from "services/translateService/i18n";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "moment/locale/ru";
import "moment/locale/en-ca";
import "moment/locale/es";
import "moment/locale/it";
import "moment/locale/fr";
import "moment/locale/de";
import { useDispatch, useSelector } from "react-redux";
import { lkSlice, lkSliceSelectors } from "store/lkSlice";
import { languageCodeType } from "services/translateService/index";
import { authApi } from "api/AuthApi/methods";
import { authApiAxios } from "api/AuthApi";
import { mainAxios } from "api";

const arrayOfUpdateLangMethods: Array<(lang: string) => void> = [];

export const addMethodToUpdateLang = (method: (lang: string) => void) => {
   arrayOfUpdateLangMethods.push(method);
};

// export const formatToText = (date:string) => {
//    moment(date).locale()
// };

export const useLangInit = () => {
   const { t } = useTranslation();
   const [didMound, setDidMound] = useState(false);
   const dispatch = useDispatch();

   useEffect(() => {
      i18n.on("languageChanged", (lang: languageCodeType) => {
         arrayOfUpdateLangMethods.map((method) => {
            method(lang);
         });
         moment.locale(lang);
         dispatch(lkSlice.actions.setLang(lang));

         if (lang === "ru") {
            dispatch(lkSlice.actions.setLang("en"));
            i18n.changeLanguage("en");
         }

         authApiAxios.defaults.headers["Accept-Language"] = lang;
         mainAxios.defaults.headers["Accept-Language"] = lang;
      });
      /* init */
      arrayOfUpdateLangMethods.map((method) => {
         method(i18n.language);
      });
      moment.locale(i18n.language);

      /* update */
      setDidMound(true);
   }, []);

   return {
      readyState: didMound,
   };
};
