import React, { FC, HTMLAttributes } from "react";
import styles from "components/UI/Table/index.module.css";
import { TableItem } from "components/UI/Table/TableItem";
import { Preloader } from "components/UI/Preloader";

export type TableItemType = {
   itemTableStyles: string;
} & HTMLAttributes<HTMLDivElement>;

type TableType<T> = {
   numberColumn: number;
   tableHeaders: string[];
   tableItem: FC<T>;
   data: Array<T>;
   loading?: boolean;
};

export function Table<T>(props: TableType<T>) {
   const { tableHeaders, numberColumn, tableItem: TableItem, data } = props;

   return (
      <div className={styles.container}>
         <table className={styles.table}>
            {props.loading ? (
               <Preloader loading={true} />
            ) : (
               <>
                  {!!tableHeaders.length && (
                     <tr className={styles.header}>
                        {tableHeaders?.map((item, index) => (
                           <th
                              key={"header " + index}
                              className={styles.header_item}
                           >
                              {item}
                           </th>
                        ))}
                     </tr>
                  )}
                  {data?.map((item, index) => (
                     // @ts-ignore
                     <TableItem key={item.id} {...item} />
                  ))}
               </>
            )}
         </table>
      </div>
   );
}
