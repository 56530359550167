import React, { FC, useEffect, useState } from "react";
import { UserWidget } from "components/UI/UserWidget";
import { SelectUnderControl } from "components/UI/Select/SelectUnderControl";
import { useForm, useWatch } from "react-hook-form";
import { Button } from "components/UI/Button";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useQuery } from "hooks/useQuery";
import { userApi } from "api/userApi/methods";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { lessonThunk } from "store/lessonSlice/thunk";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";
import { teacherType } from "types/userType";
import styles from "./index.module.css";
import { ContentBlockContainer } from "components/UI/ContentBlockContainer";
import { PageTitle } from "components/UI/PageTitle";
import { DateAndTimePicker } from "components/UI/DateAndTimePicker";
import { dateMethods } from "helpers/date";
import { uiSlice } from "store/modalWindowSlice";
import { Form } from "core/LTools/core/functional components/Form";
import { eventSlotApi } from "api/eventSlotApi/methods";
import { slotType } from "types/LessonService/types";
import { Preloader } from "components/UI/Preloader";
import { routes } from "routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useScrollToError } from "hooks/useScrollToError";
import moment from "moment-timezone";
import { groupSlotsByDate } from "serializers/slots";
import { SelectItemType } from "components/UI/Select/types";
import { generateSelectTimeOptionsByGroupOfSlots } from "components/Lk/Pages/LessonsService/SendRequest/helpers/optionsGenerators";
import { renderDurationOptions } from "helpers/selectOptions";
import {
   SendFreeRequestFormScheme,
   SendRequestFormScheme
} from "components/Lk/Pages/LessonsService/SendRequest/helpers/scheme";
import {FilesInput, onAddFileType} from "../../../../UI/Input/FilesInput";
import { defaultFileType } from "../../../../../api/types";
import { uploadFiles } from "../../../../../helpers/uploadFiles";
import { inviteApi } from "../../../../../api/inviteApi/methods";
import { subjectType } from "../../../../../store/types";
import { Input } from "components/UI/Input";
import { useGoToProfile } from "helpers/util/goToProfile";
import {
   fastLessonSlice,
   fastLessonSliceSelectors,
} from "store/fastLessonSlice";
import { NotificationStatusCard } from "../../../components/Notifications/variants/NotificationStatusCard";
import { NotificationPaymentCard } from "../../../components/Notifications/variants/NotificationPaymentCard";
import { Board } from "../../../../UI/Board";
import { Select } from "../../../../UI/SelectNew";
import { useGetCard } from "hooks/useGetCard";
import { lkApi } from "api/LkApi/methods";
import {useGetPrice} from "../../../../../hooks/useGetPrice";
import {getTimeFromTo} from "../../../../../helpers/time";
import RevolutCheckout from '@revolut/checkout'
import cn from "classnames";



type selectGroupType = SelectItemType<{
   slotGroup: string;
   text: string;
   date: string;
   elements: Array<slotType>;
}>;

type FormType = {
   group: selectGroupType;
   hours?: SelectItemType<{
      time: string;
      slot: slotType;
      rawTime: string;
   }>;
} & {
   [key: string]: any;
};

type propsType = {};
export const SendRequest: FC<propsType> = (props) => {
   const [price, setPrice] = useState(0);
   /* state */
   const { t } = useTranslation();
   const { id: teacher_id, slot_id } = useParams<{
      id: string;
      slot_id: string;
   }>();
   const user = useSelector(lkSliceSelectors.getUserData);
   const {
      control,
      setValue,
      watch,
      handleSubmit,
      formState: { errors },
      clearErrors,
   } = useForm<FormType>({
      // @ts-ignore
      resolver: yupResolver(price === 0 ? SendFreeRequestFormScheme : SendRequestFormScheme),
   });
   const userData = useSelector(lkSliceSelectors.getUserData);

   const onAddFile = (data: onAddFileType) => {
      return userApi.uploadUserFile({
         content: data.content,
         file_name: data.file_name,
         file_type: "education_document",
         user_id: userData.id,
      });
   };

   useScrollToError(errors);
   const filtersData = useSelector(fastLessonSliceSelectors.getFiltersState);
   const group = useWatch({
      control,
      name: "group",
   });
   const hours = useWatch({
      control,
      name: "hours",
   });

   const duration = useWatch({
      control,
      name: "duration",
   });

   const subjectInput = useWatch({
      control,
      name: "subject",
   });

   const subjectId = subjectInput?.value?.id

   const goToProfile = useGoToProfile(user.id);


   useEffect(() => {
      setValue("hours", undefined);
      setValue("hours_input", "");
      setValue("duration", undefined);
      setValue("duration_input", "");
   }, [group]);

   const commonState = useLocalCommonState();

   /* fetch */
   const teacherResponse = useQuery(
      () =>
         userApi.getUser({
            user_id: teacher_id,
         }),
      {
         serializer: (res) => {
            return res?.data.data as teacherType;
         },
      }
   );

   const { data: slots } = useQuery(
      () =>
         eventSlotApi.getSlot({
            user_id: teacher_id,
            date_start_order_by: "asc",
         }),
      {
         serializer: (res) => {
            return groupSlotsByDate(res?.data.data.items) as {
               [key: string]: Array<slotType>;
            };
         },
      }
   );

   const {data: allCards, isLoading, error} = useGetCard("payin")

   const allPrice = useQuery(
       () =>
           lkApi.getPriceByUserId({
              user_id: teacher_id,
           }),
       {
          serializer: (res) => {
             return res?.data.data as any;
          },
       }
   );

   const currentPrice = allPrice?.data?.find((item: any) => {
      return item?.subject?.id === subjectId
   });

   useEffect(() => {
      setPrice(currentPrice?.price);
   }, [currentPrice?.price]);

   // const currentPrice = subjectId === 0 || subjectId ? allPrice?.data[subjectId] : null

   const minutes = duration?.value?.minutes || 0;
   const hoursEnd = minutes / 60;

   const cost = (hours && duration) ? currentPrice?.price * hoursEnd : 0;

   const cardSelectValue = useWatch({ control, name: "card" });

   const cardSelectToken= cardSelectValue?.value?.ruble_payment?.token


   /* Main logic methods */
   const submit = (data: FormType) => {
      // Время начала содержится в объекте hours включая часы и минуты
      const dateFrom = moment(data?.hours?.value?.rawTime);
      const dateTo = moment(dateFrom).add(
         data?.duration?.value?.minutes,
         "minutes"
      );
      if (!data?.hours) return;

      const createInviteAndUploadFiles = ({data, user, dateFrom, dateTo, cardSelectValue}: any) => {

         commonState.dispatch(
             lessonThunk.createInviteBySlot({
                commonState,
                slot_id: data?.hours?.value?.slot?.id,
                student_id: Number(user.id),
                subject_id: data?.subject?.value.id,
                date_from: dateFrom.utcOffset("+0000").format(),
                date_to: dateTo.utcOffset("+0000").format(),
                language_id: data?.language?.value?.id,
                topic: data.topic,
                callbacks: {
                   "201": (res) =>
                       uploadFilesHandler({
                          files: data.files,
                          invite_id: res?.data.data.id,
                          bank_card_id: cardSelectValue?.id,
                       }),
                },
             })
         );
      };



      if (cardSelectValue?.value === "Добавить новую карту") {
         lkApi.createNewCard("payin").then((res: any) => {
            lkApi.getCardByCardID(res?.data?.data?.id).then((res: any) => {
               const newCardToken = res.data?.data?.ruble_payment?.token;
               console.log(res?.data?.data, "res?.data?.data")
               RevolutCheckout(newCardToken).then((instance: any) => {
                  const cardOptions: any = {
                     savePaymentMethodFor: "merchant",
                     target: cardSelectValue,
                     onSuccess() {
                        createInviteAndUploadFiles({
                           data,
                           user,
                           dateFrom,
                           dateTo,
                           cardSelectValue: res?.data?.data,
                        });
                     },
                  };
                  instance.payWithPopup(cardOptions)
               })
            })
         })
      } else {
         // RevolutCheckout(cardSelectToken).then((instance: any) => {
         //    const cardOptions: any = {
         //       savePaymentMethodFor: "merchant",
         //       target: cardSelectValue,
         //       onSuccess() {
         //          createInviteAndUploadFiles({
         //             data,
         //             user,
         //             dateFrom,
         //             dateTo,
         //             cardSelectValue,
         //          });
         //       },
         //    };
         //    instance.payWithPopup(cardOptions)
         // })
         createInviteAndUploadFiles({
            data,
            user,
            dateFrom,
            dateTo,
            cardSelectValue
         })
      }
   };
   const uploadFilesHandler = ({
      files,
      invite_id,
      bank_card_id
   }: {
      invite_id: string;
      files: Array<defaultFileType>;
      bank_card_id: number;
   }) => {
      commonState.loading = true;
      uploadFiles({
         apiMethod: (file) =>
            inviteApi.addFileToInvite({
               invite_id: invite_id,
               ...file,
            }),
         callback: () => {
            if (currentPrice?.price === 0) {
               handleNoPayment(invite_id);
            } else {
               handlePaymentWithCard(invite_id, bank_card_id);
            }
         },
         files: files,
      });
   };

   const handlePaymentWithCard = (invite_id: string, bank_card_id: number) => {
      lkApi.addInvitePayment({
         invite_id,
         bank_card_id
      }).then((res) => {
         if (res.status === 201) {
            const intervalId = setInterval(() => {
               lkApi
                  .getInvitePayment({
                     invite_id,
                     payment_id: res?.data?.data?.payment_id
                  })
                  .then((res) => {
                     clearInterval(intervalId);
                     handleUploadSuccess(invite_id, res?.data?.data?.payment_id);
                  })
                  .catch((error) => {
                     clearInterval(intervalId);
                     handleUploadError(error);
                  });
            }, 1000);
         }
         if (res.status === 400) {
            commonState.dispatch(
               uiSlice.actions.setModalType({
                  template: "default",
                  payload: {
                     title: "Ошибка",
                     subtitle: `${res?.data?.data?.errors[0]?.message}`,
                     button: "close",
                     status: "error",
                  },
               })
            );
            commonState.loading = false;
         }
      }).catch((error) => {
         commonState.loading = false;
      });
   };

   const handleNoPayment = (invite_id: string) => {
      lkApi.addInviteNoPayment({
         invite_id,
      }).then((res) => {
         if (res.status === 201) {
            const intervalId = setInterval(() => {
               // lkApi
               //    .getInvitePayment({
               //       invite_id,
               //       payment_id: res?.data?.data?.payment_id
               //    })
               //    .then((res) => {
                     clearInterval(intervalId);
                     handleUploadSuccess(invite_id, res?.data?.data?.payment_id);
                  // })
                  // .catch((error) => {
                  //    clearInterval(intervalId);
                  //    handleUploadError(error);
                  // });
            }, 1000);
         }
         if (res.status === 400) {
            commonState.dispatch(
               uiSlice.actions.setModalType({
                  template: "default",
                  payload: {
                     title: "Ошибка",
                     subtitle: `${res?.data?.data?.errors[0]?.message}`,
                     button: "close",
                     status: "error",
                  },
               })
            );
            commonState.loading = false;
         }
      }).catch((error) => {
         commonState.loading = false;
      }).finally(() => {
         commonState.loading = false;
      });
   };

   const handleUploadSuccess = (invite_id: string, payment_id: number) => {
      commonState.loading = false;
      successCallback(invite_id);
   };

   const handleUploadError = (error: any) => {
      commonState.loading = false;
      console.error("Error:", error);
   };

   const successCallback = (invite_id: string) => {
      commonState.loading = false;
      /* redirect to lesson */
      commonState.location = routes.lk.lesson.invite.view + `/${invite_id}`;
      /* show success modal */
      commonState.dispatch(
         uiSlice.actions.setModalType({
            template: "default",
            payload: {
               title: t("Lk.SendRequest.success.title", "Запрос отправлен"),
               subtitle: t(
                  "Lk.SendRequest.success.subtitle",
                  "Ожидайте подтверждение занятия преподавателем"
               ),
               button: "close",
               status: "success",
            },
         })
      );
   };

   const dateOptions = (function (): Array<selectGroupType> {
      const result: any = [];
      for (let slotsKey in slots) {
         const dateFrom = slots[slotsKey];

         // console.log(slots[slotsKey]);
         const elements = slots[slotsKey];
         result.push({
            id: 1,
            text: dateMethods.format(slotsKey, "DD.MM.YYYY"),
            value: {
               slotGroup: slotsKey,
               text: dateMethods.format(slotsKey, "DD.MM.YYYY"),
               date: moment(slotsKey).set("hours", 0),
               elements,
            },
         });
      }
      return result;
   })();


   /* set group select option by params id */
   useEffect(() => {
      if (slots) {
         const option = dateOptions?.find((item) =>
            item.value.elements.find((item) => item.id == slot_id)
         );
         if (option) {
            setValue("group", option);
            setValue("group_input", option.text);
         }
      }
   }, [slots]);


   useEffect(() => {
      if (teacherResponse.data && slots) {
         try {
            const lang = filtersData.data.language;
            if (lang) {
               setValue("language", {
                  id: lang.id,
                  text: lang.name,
                  value: lang,
               });
               setValue("language_input", lang.name);
            }

            const subject = filtersData.data.subject;
            if (subject) {
               setValue("subject", {
                  id: subject.id,
                  text: subject.name,
                  value: subject,
               });
               setValue("subject_input", subject.name);
            }
         } catch (e) {
            console.log(e);
         }
      }
   }, [slots]);

   useEffect(() => {
      return () => {
         commonState.dispatch(fastLessonSlice.actions.clearFilters());
      };
   }, []);

   if (!teacherResponse.data || !slots || !allCards)
      return (
         <Preloader
            loading={teacherResponse.isLoading}
            variant={"lesson-service"}
         />
      );

   /* @TODO вынести в стейт для оптимизации */
   /* select options */
   // const dateOptions = (function (): Array<selectGroupType> {
   //    const result: any = [];
   //    for (let slotsKey in slots) {
   //       const dateFrom = slots[slotsKey];
   //
   //       // console.log(slots[slotsKey]);
   //       const elements = slots[slotsKey];
   //       result.push({
   //          id: 1,
   //          text: dateMethods.format(slotsKey, "DD.MM.YYYY"),
   //          value: {
   //             slotGroup: slotsKey,
   //             text: dateMethods.format(slotsKey, "DD.MM.YYYY"),
   //             date: moment(slotsKey).set("hours", 0),
   //             elements,
   //          },
   //       });
   //    }
   //    return result;
   // })();
   const renderDurationOptionsByChosenSlot = function () {
      if (!hours?.value) return [];
      const slot = hours?.value?.slot;
      const topBoundary = Math.floor(
         moment(slot.date_to).diff(moment(hours?.value.rawTime), "minutes") / 15
      );
      return renderDurationOptions(topBoundary);
   };
   const teacherSubjects =
      teacherResponse.data?.instruction_subjects.map((item, index) => {
         return {
            id: index,
            text: `${item.name}`,
            value: item as subjectType,
         };
      }) || [];
   const teacherLanguages =
      teacherResponse.data?.instruction_languages.map((item, index) => {
         return {
            id: index,
            text: `${item.name}`,
            value: item,
         };
      }) || [];

   const newCard = {
      id: 0,
      text: t("CreateSlot.add-new-card", "Добавить новую карту"),
      value: "Добавить новую карту"
   };

   const studentCard = [newCard, ...allCards?.filter((item: any) => {
      return item.status === 'ok';
   }).map((item: any, index: number) => {
      return {
         id: item.id,
         text: `${item.pan}`.toLowerCase(),
         value: item,
      };
   })] || [];

   return (
      <Form
         commonState={commonState}
         clearErrors={clearErrors}
         errors={errors}
         onSubmit={handleSubmit(submit)}
         className={styles.container}
      >
         <PageTitle
            className={styles.title}
            title={t("Lk.SendRequest.title", "Запрос занятия")}
         />
         <main className='Lessons__main'>
            <UserWidget user={teacherResponse.data} variant={"teacher"} />
            <ContentBlockContainer
               className={styles.time_form}
               title={t("Lk.SendRequest.date-label", "Дата и время")}
            >
               <div className={styles.interval}>
                  {t(
                     "Lk.SendRequest.interval",
                     "Доступный интервал для занятий:"
                  )}
                  {group?.value.elements.map((slot: slotType) => (
                     <div className={styles.interval__time}>
                        {`${dateMethods.getTime(
                           slot?.date_from
                        )} — ${dateMethods.getTime(slot?.date_to)} `}
                     </div>
                  ))}
               </div>
               <div className={styles.date}>
                  <SelectUnderControl
                     name='group'
                     style={{
                        gridArea: "group",
                     }}
                     setValue={setValue}
                     control={control}
                     options={dateOptions}
                     label={t("UI.DateAndTimePicker.date", "Дата")}
                  />
                  <SelectUnderControl
                     name='hours'
                     style={{
                        gridArea: "hours",
                     }}
                     onSelectValue={() => {
                        setValue("duration", undefined);
                        setValue("duration_input", "");
                     }}
                     readOnly={!group?.value}
                     setValue={setValue}
                     control={control}
                     error={errors?.hours?.message}
                     options={generateSelectTimeOptionsByGroupOfSlots(group)}
                     label={t("UI.DateAndTimePicker.date-from", "Начало")}
                  />
                  <SelectUnderControl
                     name='duration'
                     style={{
                        gridArea: "duration",
                     }}
                     readOnly={!hours?.value}
                     setValue={setValue}
                     control={control}
                     error={errors?.duration?.message}
                     options={renderDurationOptionsByChosenSlot()}
                     label={t("UI.DateAndTimePicker.duration", "Длительность")}
                  />
               </div>
            </ContentBlockContainer>
            <ContentBlockContainer
               className={styles.extra_info_form}
               title={t("Lk.SendRequest.subject-title", "Предмет и тема")}
            >
               <SelectUnderControl
                  watch={watch}
                  name='language'
                  setValue={setValue}
                  options={teacherLanguages}
                  component={({ name }) => <div>{name}</div>}
                  control={control}
                  error={errors?.language?.message}
                  label={t("Lk.SendRequest.language", "Язык преподавания")}
               />
               <SelectUnderControl
                  watch={watch}
                  name='subject'
                  setValue={setValue}
                  options={teacherSubjects}
                  /* TODO вынести */
                  component={({ name, education_system, category }) => (
                     <div className={styles.subject_option}>
                        <div>{name}</div>
                        <div className={styles.subject_option__description}>
                           <span>{education_system?.level?.name}</span>{" "}
                           <span>{education_system?.name}</span>{" "}
                           <span>{category?.name || ""}</span>
                        </div>
                     </div>
                  )}
                  control={control}
                  error={errors?.subject?.message}
                  label={t("Lk.SendRequest.subject", "Предмет")}
               />
               <Input
                  control={control}
                  name='topic'
                  error={commonState.errors.topic || errors?.topic?.message}
                  label={t("Lk.SendRequest.comment-text-label", "Тема занятия")}
               />
               <div className={cn(styles.payment__container, {
                  [styles.payment__container_disabled]: currentPrice?.price === 0
               })}>
                  <div className={styles.payment__title}>
                     {t("CreateSlot.payment", "Оплата")}
                  </div>
                  <div className={styles.board}>
                     <Board
                        title={
                           <>
                              {t(
                                 "CreateSlot.lesson-computed-cost",
                                 "Расчетная стоимость урока"
                              )}:{" "}
                              <span style={{ color: "var(--color-primary)" }}>
                                 {cost ? cost : "-"}{cost ? currentPrice?.currency : null}
                              </span>
                           </>
                        }
                        className={currentPrice?.price === 0 ? styles.board_disabled : undefined}
                     />
                  </div>
                  <div>
                     <SelectUnderControl
                        watch={watch}
                        name='card'
                        error={errors?.card?.message}
                        setValue={setValue}
                        control={control}
                        options={studentCard}
                        label={t("CreateSlot.card-for-payment", "Карта для оплаты")}
                        disabled={currentPrice?.price === 0}
                     />
                  </div>
               </div>
               <FilesInput
                  className={styles.files}
                  control={control}
                  onAddFile={onAddFile}
                  setValue={setValue}
                  name='file'
                  accept={".jpg, .png, .pdf, .jpeg"}
               />
               <div className={styles.buttons}>
                  <Button
                     disabled={commonState.loading}
                     variant={"primary"}
                     className={styles.button}
                     loading={commonState.loading}
                  >
                     {t("Lk.SendRequest.add-button", "Добавить")}
                  </Button>
                  <Button
                     variant={"red"}
                     disabled={commonState.loading}
                     className={styles.button}
                     loading={commonState.loading}
                     onClick={goToProfile}
                     form={"_"}
                  >
                     {t("Lk.SendRequest.cancel-button", "Отменить")}
                  </Button>
               </div>
            </ContentBlockContainer>
         </main>
      </Form>
   );
};
