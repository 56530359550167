import { createRequest } from "../../core/LTools/core/apiMethods/createRequest";
import { mainAxios } from "../index";
import { inviteIdType, lessonIdType } from "../types";
import {
   deleteLessonType,
   getLessonDataType,
   getLessonFileType,
   getLessonsType,
   updateLessonDataType,
   voteForLessonType,
} from "./types";
import { dataToQueryString } from "../../core/LTools/core/apiMethods/dataToQueryString";

export const lessonApi = {
   /* Default lessons */
   createLessonByInvite: async ({ invite_id }: inviteIdType) => {
      return createRequest(mainAxios.post(`/invite/${invite_id}/lesson`));
   },
   getLessonData: async ({ lesson_id }: getLessonDataType) => {
      return createRequest(mainAxios.get(`/lesson/${lesson_id}`));
   },
   updateLessonData: async ({
      lesson_id,
      ...payload
   }: updateLessonDataType) => {
      return createRequest(mainAxios.put(`/lesson/${lesson_id}`, payload));
   },
   deleteLesson: async ({ lesson_id }: deleteLessonType) => {
      return createRequest(mainAxios.delete(`/lesson/${lesson_id}`));
   },
   getLessons: async (payload: getLessonsType) => {
      return createRequest(
         mainAxios.get(`/lesson${dataToQueryString(payload)}`)
      );
   },
   voteForLesson: async ({ lesson_id, ...payload }: voteForLessonType) => {
      return createRequest(
         mainAxios.post(`/lesson/${lesson_id}/vote`, payload)
      );
   },
   getLessonFile: async ({ lesson_id, file_id }: getLessonFileType) => {
      return createRequest(
         mainAxios.get(`/lesson/${lesson_id}/file/${file_id}`)
      );
   },
   getLessonLink: async ({ lesson_id }: { lesson_id: string }) => {
      return createRequest(
         mainAxios.get(`/lesson/${lesson_id}/zoom`)
      );
   },
};
