// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalWindowBody_container__rDuuA {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n}\n\n.ModalWindowBody_title__1JHmX {\n    font-weight: 600;\n    font-size: 30px;\n    text-align: center;\n    margin-bottom: 20px;\n}\n\n.ModalWindowBody_subtitle__1Jfx6 {\n    font-size: 16px;\n    line-height: 195.5%;\n    text-align: center;\n    margin-bottom: 40px;\n}\n\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n\n}\n\n@media screen and (max-width: 1023px) {\n\n}\n\n@media screen and (max-width: 719px) {\n    .ModalWindowBody_title__1JHmX {\n        font-weight: 600;\n        font-size: 22px;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/ModalWindow/templates/components/ModalWindowBody/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;IACI;QACI,gBAAgB;QAChB,eAAe;IACnB;AACJ","sourcesContent":[".container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n}\n\n.title {\n    font-weight: 600;\n    font-size: 30px;\n    text-align: center;\n    margin-bottom: 20px;\n}\n\n.subtitle {\n    font-size: 16px;\n    line-height: 195.5%;\n    text-align: center;\n    margin-bottom: 40px;\n}\n\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n\n}\n\n@media screen and (max-width: 1023px) {\n\n}\n\n@media screen and (max-width: 719px) {\n    .title {\n        font-weight: 600;\n        font-size: 22px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ModalWindowBody_container__rDuuA",
	"title": "ModalWindowBody_title__1JHmX",
	"subtitle": "ModalWindowBody_subtitle__1Jfx6"
};
export default ___CSS_LOADER_EXPORT___;
