import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "store";
import * as serviceWorker from "./serviceWorker";
import "services/translateService/i18n.js";
import { QueryClientProvider } from "react-query";
import { queryClient } from "services/react_query";

ReactDOM.render(
   <Provider store={store}>
      <React.StrictMode>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
          </QueryClientProvider>
      </React.StrictMode>
   </Provider>,
   document.getElementById("root")
);

serviceWorker.unregister();
