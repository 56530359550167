// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EducationSystems_container__1XbLZ {\n    margin-left: 100px;\n}\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n\n}\n\n@media screen and (max-width: 1023px) {\n    .EducationSystems_container__1XbLZ {\n        margin-left: 24px;\n    }\n}\n\n@media screen and (max-width: 719px) {\n    .EducationSystems_container__1XbLZ {\n        margin-left: 0px;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/AdminService/Pages/EducationSystems/index.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;;AAEA;;AAEA;;AAEA;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".container {\n    margin-left: 100px;\n}\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n\n}\n\n@media screen and (max-width: 1023px) {\n    .container {\n        margin-left: 24px;\n    }\n}\n\n@media screen and (max-width: 719px) {\n    .container {\n        margin-left: 0px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "EducationSystems_container__1XbLZ"
};
export default ___CSS_LOADER_EXPORT___;
