// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DeleteEventTemplate_buttons__1wWl_ {\n    display: flex;\n    justify-content: space-between;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n}\n.DeleteEventTemplate_button__3nacZ {\n    width: 100%;\n    margin-bottom: 10px;\n}", "",{"version":3,"sources":["webpack://src/components/ModalWindow/templates/DeleteEventTemplate/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;AACf;AACA;IACI,WAAW;IACX,mBAAmB;AACvB","sourcesContent":[".buttons {\n    display: flex;\n    justify-content: space-between;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n}\n.button {\n    width: 100%;\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": "DeleteEventTemplate_buttons__1wWl_",
	"button": "DeleteEventTemplate_button__3nacZ"
};
export default ___CSS_LOADER_EXPORT___;
