import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import cn from "classnames";
import { CardTime } from "components/Lk/Pages/LkProfile/Cards/components/CardTime";
import { inviteType, slotType } from "types/LessonService/types";
import { CircledCounter } from "components/UI/CircledCounter";
import { useToggle } from "hooks/useToggle";
import {
   createModalWindow,
   modalWindowTemplateType,
   uiSlice,
} from "store/modalWindowSlice";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { TeacherCard } from "components/Lk/Pages/LkProfile/Cards/TeacherCard";
import { useTranslation } from "react-i18next";
import { Icons } from "assets/iconsCorrect";
import { resolveTitleByPeriodicity } from "staticData/periodicity";

type propsType = {
   className?: string;
   setData?: (data: any) => void;
   data?: Array<slotType>;
} & slotType;
export const TeacherCardSlot: FC<propsType> = ({
   invites,
   className,
   event_periodicity,
   event_id,
   id: slot_id,
   date_from,
   date_to,
   setData,
   data,
}) => {
   const { t } = useTranslation();
   const [isExpanded, toggleExpand] = useToggle();
   const commonState = useLocalCommonState();
   const [filteredInvites, setFilteredInvites] = useState<Array<inviteType>>(
      []
   );
   useEffect(() => {
      setFilteredInvites(invites.filter((item) => item.status == "payed_invite"));
   }, [invites]);

   const showDeleteSlotModal = () => {
      commonState.dispatch(
         uiSlice.actions.setModalType(
            createModalWindow({
               template: "delete-slot" as modalWindowTemplateType["deleteSlot"],
               payload: {
                  slot_id,
                  callback: () => {
                     setData &&
                        setData(data?.filter((item) => item.id != slot_id));
                  },
               },
            })
         )
      );
   };

   const showDeleteEventModal = () => {
      commonState.dispatch(
         uiSlice.actions.setModalType(
            createModalWindow({
               template: "delete_event" as modalWindowTemplateType["deleteEvent"],
               payload: {
                  slot_id,
                  event_id,
                  deleteSlotLocal: () => {
                     setData &&
                        setData(
                           data?.filter((item) => item.event_id != event_id)
                        );
                  },
                  deleteEventLocal: () => {
                     setData &&
                        setData(data?.filter((item) => item.id != slot_id));
                  },
               },
            })
         )
      );
   };

   const deleteSlotOrEvent = () => {
      if (event_periodicity && event_periodicity != "once") {
         showDeleteEventModal();
      } else {
         showDeleteSlotModal();
      }
   };

   const action = () => {
      filteredInvites?.length ? toggleExpand() : deleteSlotOrEvent();
      return;
   };

   const resolveButtonTitle = () => {
      if (!filteredInvites?.length) {
         return t("Buttons.delete", "Удалить");
      }
      return isExpanded
         ? t("Buttons.collapse", "Свернуть")
         : t("Buttons.expand", "Развернуть");
   };

   return (
      <>
         <div
            className={cn(styles.container, {
               [`${className}`]: !!className,
            })}
         >
            <h5 className={styles.title}>
               {t(
                  "Lk.Profile.cards.teacher.slot.title",
                  "Время для проведение занятий"
               )}
            </h5>
            <CardTime date_to={date_to} date_from={date_from}>
               <div className={styles.slot}>
                  {filteredInvites?.length ? (
                     <>
                        <CircledCounter
                           variant='blue'
                           count={filteredInvites?.length}
                        />
                        <p>
                           {t(
                              "Lk.Profile.cards.teacher.slot.body",
                              "Неподтвержденные занятия"
                           )}
                        </p>
                     </>
                  ) : (
                     <div className={styles.periodicity}>
                        <Icons.ui.RepeatIcon
                           className={styles.periodicity_icon}
                        />{" "}
                        {t("Common.repeat", "Повтор")}:{" "}
                        {resolveTitleByPeriodicity[event_periodicity]}
                     </div>
                  )}
               </div>
            </CardTime>
            <button onClick={action} className={styles.button}>
               {resolveButtonTitle()}
            </button>
         </div>
         {isExpanded && (
            <div className={styles.slot_invites}>
               {filteredInvites
                  ?.filter((item) => item.status == "payed_invite")
                  ?.map((item) => {
                     return (
                        <TeacherCard
                           key={item.id}
                           type={"invite"}
                           setData={setData}
                           data={data}
                           {...item}
                           className={styles.slot_invite}
                        />
                     );
                  })}
            </div>
         )}
      </>
   );
};
