import React, { FC } from "react";
import styles from "./style.module.css";
import { groupLessonViewVariant } from "../index";
import { Button } from "components/UI/Button";
import { Link } from "react-router-dom";
import { routes } from "routes";
import { useSelector } from "react-redux";
import { authSliceSelectors } from "store/authSlice";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { lessonThunk } from "store/lessonSlice/thunk";
import { useTranslation } from "react-i18next";
import moment from "moment";

type propsType = {
   groupLessonData?: any;
   variant: groupLessonViewVariant;
   group_lesson_id?: string;
   refetch: () => void;
};

export const GroupLessonButtons: FC<propsType> = ({
   groupLessonData,
   variant,
   group_lesson_id,
   refetch,
}) => {
   const { t } = useTranslation();
   const user_id = useSelector(authSliceSelectors.getId);
   const commonState = useLocalCommonState();

   /* methods */
   const addStudent = () => {
      commonState.dispatch(
         lessonThunk.addStudentToGroupLessonType({
            group_lesson_id: String(group_lesson_id),
            commonState,
            callbacks: {
               204: () => {
                  refetch();
               },
            },
         })
      );
      //  const dateFrom = moment(groupLessonData?.date_from).utcOffset("+0000");
      //  const dateTo = moment(dateFrom).add(
      //      groupLessonData?.date_to,
      //      "minutes"
      //  );

       // commonState.dispatch(
       //     lessonThunk.createInviteBySlot({
       //         commonState,
       //         slot_id: String(group_lesson_id),
       //         student_id: Number(user_id),
       //         subject_id: groupLessonData?.subject?.id,
       //         date_from: dateFrom?.format(),
       //         date_to: dateTo?.format(),
       //         language_id: groupLessonData?.language?.id,
       //         topic: groupLessonData?.topic,
       //         callbacks: {
       //             // 204: () => {
       //             //     refetch();
       //             // },
       //             // 204: (res) => {
       //             //     console.log(res)
       //             // },
       //         },
       //     })
       // );
   };

   const deleteStudentFromLesson = () => {
      commonState.dispatch(
         lessonThunk.deleteStudentFromLesson({
            commonState,
            group_lesson_id: String(group_lesson_id),
            callbacks: {
               204: () => {
                  refetch();
               },
            },
         })
      );
   };

   const rejectLesson = () => {
      commonState.dispatch(
         lessonThunk.deleteGroupLesson({
            group_lesson_id: String(group_lesson_id),
            commonState,
            callbacks: {
               204: () => {
                  commonState.location = routes.lk.profile + `/${user_id}`;
               },
            },
         })
      );
   };

   /* view */
   const linkToLk = (
      <Link to={routes.lk.lkStudent + `/${user_id}`}>
         <Button variant='primary'>
            {t("Buttons.to-lk", "В личный кабинет")}
         </Button>
      </Link>
   );
   const buttonsResolver = () => {
      switch (variant) {
         case "student_archive":
         case "teacher_archive":
            return linkToLk;
         case "student_unregistered":
            return (
               <div className={styles.buttons_container}>
                  <Button
                     variant='primary'
                     onClick={addStudent}
                     loading={commonState.loading}
                  >
                     {t("Lk.lesson-service.group.register", "Записаться")}
                  </Button>
               </div>
            );
         case "student_registered":
            return (
               <div className={styles.buttons_container}>
                  {linkToLk}
                  <Button
                     variant={"primary"}
                     onClick={deleteStudentFromLesson}
                     loading={commonState.loading}
                  >
                     {t(
                        "Lk.lesson-service.group.unregister",
                        "Отменить запись"
                     )}
                  </Button>
               </div>
            );
         case "teacher":
            return (
               <div className={styles.buttons_container}>
                  {linkToLk}
                  <Button
                     variant={"red"}
                     onClick={rejectLesson}
                     loading={commonState.loading}
                  >
                     {t(
                        "Lk.lesson-service.group.cancel-lesson",
                        "Отменить занятие"
                     )}
                  </Button>
               </div>
            );
      }
   };

   return <div className={styles.container}>{buttonsResolver()}</div>;
};
