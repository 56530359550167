// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Lk_container__zhBgB {\n    display: flex;\n    overflow: scroll;\n    width: 100vw;\n    overflow-x: hidden;\n    position: relative;\n    /*z-index: 2;*/\n    padding-bottom: 200px;\n    min-height: calc( var(--vh) * 100 );\n}\n\n.Lk_lk_main__3TmSF {\n    margin-left: 180px;\n    width: 100%;\n    overflow-x: hidden;\n    display: flex;\n}\n\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n\n}\n\n@media screen and (max-width: 1023px) {\n    .Lk_lk_main__3TmSF {\n        margin-left: 0;\n        width: 100%;\n        flex-direction: column;\n        padding-top: 16px;\n    }\n}\n\n@media screen and (max-width: 719px) {\n    .Lk_container__zhBgB {\n        z-index: unset;\n    }\n\n\n}", "",{"version":3,"sources":["webpack://src/components/Lk/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,cAAc;IACd,qBAAqB;IACrB,mCAAmC;AACvC;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,kBAAkB;IAClB,aAAa;AACjB;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;IACI;QACI,cAAc;QACd,WAAW;QACX,sBAAsB;QACtB,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,cAAc;IAClB;;;AAGJ","sourcesContent":[".container {\n    display: flex;\n    overflow: scroll;\n    width: 100vw;\n    overflow-x: hidden;\n    position: relative;\n    /*z-index: 2;*/\n    padding-bottom: 200px;\n    min-height: calc( var(--vh) * 100 );\n}\n\n.lk_main {\n    margin-left: 180px;\n    width: 100%;\n    overflow-x: hidden;\n    display: flex;\n}\n\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n\n}\n\n@media screen and (max-width: 1023px) {\n    .lk_main {\n        margin-left: 0;\n        width: 100%;\n        flex-direction: column;\n        padding-top: 16px;\n    }\n}\n\n@media screen and (max-width: 719px) {\n    .container {\n        z-index: unset;\n    }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Lk_container__zhBgB",
	"lk_main": "Lk_lk_main__3TmSF"
};
export default ___CSS_LOADER_EXPORT___;
