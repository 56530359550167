import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StateType } from "../index";
import { studentType, teacherType } from "types/userType";

export type DialogType = {
   id: number;
   is_admin_chat: boolean;
   last_message_data: string;
   users: studentType[];
   lead_admin: teacherType | null;
   is_has_invisible_messages: boolean;
};

export type MessageType = {
   id: number;
   user: studentType;
   date: string;
   link_message_id: number;
   message: string;
   files: {
      id: number;
      file_name: string;
      url: string;
   }[];
   message_type: string;
   message_status: "invisible" | "visible";
};

/**
 * Для удобвства и оптимизации хранения сообщейний чата использую объект,
 * в котором key = id чата. id чата уникальные.
 * */

type ChatSliceType = {
   loadingDialogs: boolean;
   loadingAdminsDialogs: boolean;

   loadingOldMessages: boolean;
   dialogs: DialogType[];
   adminsDialogs: DialogType[];
   chatsMessages: {
      [key: number]: MessageType[];
   };
   chatsMessagesCount: {
      [key: number]: number;
   };
   needScrollBottom: boolean;
};
const initialState: ChatSliceType = {
   loadingDialogs: false,
   loadingAdminsDialogs: false,
   loadingOldMessages: false,
   dialogs: [],
   adminsDialogs: [],
   chatsMessages: {},
   chatsMessagesCount: {},
   needScrollBottom: false,
};
export const chatSlice = createSlice({
   name: "chatSlice",
   initialState,
   reducers: {
      /**
       *
       * */
      updateDialogs: (state, { payload }: PayloadAction<DialogType[]>) => {
         state.dialogs = payload;
         state.loadingDialogs = true;
      },
      updateAdminsDialogs: (
         state,
         { payload }: PayloadAction<DialogType[]>
      ) => {
         state.adminsDialogs = payload;
         state.loadingAdminsDialogs = true;
      },
      /**
       * @updateChatMessagesById: принимает id чата и сообщений, которые относятся к данному чату
       * id нужен для того чтобы обратиться к chatMessages[id] и изменить значение свойтва по key: id
       *
       * update props:
       * type: "new" | "old"
       * new: пинг каждые n секунд и первый запрос
       * old : пагинация для подгрузки истории запросов
       *
       * -> messages.filter(new_item => !state.chatsMessages[id].find(old_item => old_item.id === new_item.id))
       * -> фильтруем и сравниваем массив полученных сообщений со state , чтобы не было дубликатов
       * пример :
       * state.messages[id] = [{id: 1} , {id: 2}, {id: 3}]
       * messages : = [{id: 3} , {id: 4}, {id: 5}]
       * result = [{id: 4}, {id: 5}]
       *
       *
       * */
      updateChatMessagesById: (
         state,
         {
            payload,
         }: PayloadAction<{
            id: number;
            messages: MessageType[];
            messages_count?: number;
            type: "new" | "old";
         }>
      ) => {
         const { id, messages, messages_count, type } = payload;
         if (payload.type === "new" && Array.isArray(state.chatsMessages[id])) {
            let new_messages: MessageType[] = messages.filter(
               (new_item) =>
                  !state.chatsMessages[id].find(
                     (old_item) => old_item.id === new_item.id
                  )
            );
            state.chatsMessages[id].push(...new_messages);

            state.chatsMessages[id] = state.chatsMessages[id].map((item) => {
               if (messages.find((new_item) => new_item.id === item.id)) {
                  return {
                     ...item,
                     message_status: messages.find(
                        (new_item) => new_item.id === item.id
                     )!.message_status,
                  };
               } else return item;
            });

            if (new_messages.length > 0) {
               state.needScrollBottom = true;
            }
         } else if (
            payload.type === "old" &&
            Array.isArray(state.chatsMessages[id])
         ) {
            state.chatsMessages[id].unshift(
               ...messages.filter(
                  (new_item) =>
                     !state.chatsMessages[id].find(
                        (old_item) => old_item.id === new_item.id
                     )
               )
            );
            state.loadingOldMessages = false;
         } else {
            state.chatsMessages[id] = messages;
            state.needScrollBottom = true;
         }
         if (messages_count) state.chatsMessagesCount[id] = messages_count;
      },
      addWaitingMessageById: (
         state,
         { payload }: PayloadAction<{ id: number; messages: MessageType }>
      ) => {
         // const {id, messages} = payload;
         // state.chatsMessages[id] = [...state.chatsMessages[id], {...messages, waitingAlert: true}];
      },
      setNeedScrollBottom: (state, { payload }: PayloadAction<boolean>) => {
         state.needScrollBottom = payload;
      },
      setLoadingOldMessages: (state, { payload }: PayloadAction<boolean>) => {
         state.loadingOldMessages = payload;
      },
      /**
       * В случае если пользователь выйдет из аккаунта, необходимо почистить state( ? прировнять к initialStateDefault ? )
       * */
      clearSlice: (state) => {
         return { ...initialState };
      },
      /**
       * Временные методы для тестирования поведения чата
       * */
      addNewMessageToChatForId: (
         state,
         { payload }: PayloadAction<{ id: number; message: MessageType }>
      ) => {
         const { id, message } = payload;
         // state.chatsMessages[id] = [...state.chatsMessages[id], message];
      },
   },
});

export const chatSliceSelectors = {
   getDialogs: (state: StateType) => state.chatSlice.dialogs,
   getAdminsDialogs: (state: StateType) => state.chatSlice.adminsDialogs,
   getChatsMessages: (state: StateType) => state.chatSlice.chatsMessages,
   getChatsMessagesCount: (state: StateType) =>
      state.chatSlice.chatsMessagesCount,

   getLoadingAdminsDialogs: (state: StateType) =>
      state.chatSlice.loadingAdminsDialogs,
   getLoadingDialogs: (state: StateType) => state.chatSlice.loadingDialogs,

   getNeedScrollBottom: (state: StateType) => state.chatSlice.needScrollBottom,
   getLoadingOldMessages: (state: StateType) =>
      state.chatSlice.loadingOldMessages,
};
