import React, { FC } from "react";
import styles from "./index.module.css";
import mobileStyles from "./mobile.module.css";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import { useHistory } from "react-router-dom";
// import { routes } from "../../../../../../../routes";
// import { dateMethods } from "../../../../../../../helpers/date";
import { useMedia } from "hooks/useMedia";
import { Icons } from "assets/iconsCorrect";
import cn from "classnames";
import { dateMethods } from "helpers/date";
import { lkApi } from "api/LkApi/methods";
import { useDeleteCard } from "hooks/useDeleteCard";
import { Preloader } from "components/UI/Preloader";

type TableItemType = {
   item: any;
   // user_id: string;
   // type?: "lesson" | "group_lesson";
};

export const TableItem: FC<TableItemType> = ({ item }) => {
   // const { lesson, user_id: clientUserId } = props;
   // const { lesson } = props;
   const history = useHistory();
   const { width } = useMedia();

   const goToLesson = () => {
      // if (props.type == "lesson") {
      // history.push(routes.lk.payment.transactions + `/${lesson.id}`);
      // } else if (props.type == "group_lesson") {
      //    history.push(routes.lk.lesson.group.view + `/${lesson.id}`);
      // }
   };

   const {deleteCard, isLoading, error} = useDeleteCard(item.id)


   if (!item) return null;

   if (width < 720) {
      return (
          <>
             {item?.status !== "deleted" && item?.status !== "new" &&
         <div className={mobileStyles.item}>
            <div className={mobileStyles.preview}>
               <div className={mobileStyles.preview_header}>
                  <div>
                     <div className={mobileStyles.table_item_bold}>{item?.pan}</div>
                     <div>
                        <span style={{ marginRight: "9px" }}>{dateMethods.format(item?.created_at, ("MM.YYYY"))}</span>
                        <span>{item?.mps ? item?.mps : " - "}</span>
                     </div>

                  </div>
                  <div>
                     <button onClick={deleteCard}>
                        {isLoading ? <Preloader className={styles.loader}  loading={isLoading}/> : <DeleteIcon />}
                     </button>
                  </div>
               </div>
            </div>
         </div>}
          </>
      );
   }

   return (
      <>
         {item?.status !== "deleted" && item?.status !== "new" &&
         <tr className={styles.preview}>
            <td className={cn(styles.table_item, styles.item_bold)}>{item?.pan}</td>
            <td className={cn(styles.table_item, styles.item_bold)}>{dateMethods.format(item?.created_at, ("MM.YYYY"))}</td>
            <td className={cn(styles.table_item, styles.item_bold)}>{item?.mps ? item?.mps : " - "}</td>
            <td className={cn(styles.table_item, styles.button)}>
               <button onClick={deleteCard}>
                  {isLoading ? <Preloader className={styles.loader}  loading={isLoading}/> : <DeleteIcon />}
               </button>
            </td>
         </tr>
         }
      </>
   );
};
