import React, { FC, useEffect } from "react";
import { useScrollToTop } from "hooks/useScrollToTop";
import { useAuth } from "services/autoAuth/useAuth";
import { Route } from "react-router-dom";
import { DownloadCert } from "../../components/Lk/Pages/DownloadCert";
import { useGoogleAuthInit } from "../googleAuth";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "../../store/lkSlice";
import i18n from "../translateService/i18n";
import moment from "moment-timezone";
import { arrayOfTimezones } from "../../staticData/timezone";
import { useOnlinePooling } from "services/onlinePooling";
import { lc } from "store/LC";

export const ServicesConnector: FC = () => {
   const { user_language, timezone } = useSelector(
      lkSliceSelectors.getUserData
   );

   useScrollToTop();
   useAuth();
   useGoogleAuthInit();
   useOnlinePooling();

   useEffect(() => {
      if (user_language) {
         i18n.changeLanguage(user_language);
      }
   }, [user_language]);
   useEffect(() => {
      if (timezone) {
         const foundTimezoneObject = arrayOfTimezones.find(
            (item) => item.value == timezone
         );

         if (!foundTimezoneObject) {
            alert("Timezone not found");
            return;
         }
         const date = moment().utcOffset(timezone.replace(":", ""));

         console.log(foundTimezoneObject, 'foundTimezoneObject')
         if (foundTimezoneObject) {
            // @ts-ignore
            // moment.tz.setDefault(foundTimezoneObject.name);
            lc.set("timeZone", foundTimezoneObject.name);
            lc.set("timeZoneOffset", foundTimezoneObject.offset);
         }
      }
   }, [timezone]);

   return (
      <>
         <Route path={"/certificate/:id"}>
            <DownloadCert />
         </Route>
      </>
   );
};
