import React, { AllHTMLAttributes, FC } from "react";
import styles from "components/UI/LinkCard/index.module.css";
import cn from "classnames";
import { Preloader } from "components/UI/Preloader";

type PropsType = {
   title: string;
   count: number | null;
   bgColor?: string;
   modify?: ["white"?, "black"?];
   icon?: FC<any>;
} & AllHTMLAttributes<HTMLDivElement>;

export const LinkCard: FC<PropsType> = (props) => {
   const {
      title,
      count,
      bgColor,
      modify,
      icon: Icon,
      className,
      ...other
   } = props;

   return (
      <div
         className={cn({
            [styles.container]: true,
            [[modify?.map((item) => styles[String(item)])]
               .join("")
               .split(",")
               .join(" ")]: modify,
            [`${className}`]: className,
         })}
      >
         <div
            style={{ background: bgColor }}
            className={styles.reverse_container}
         >
            <h5 className={styles.title}>{title}</h5>
         </div>
         <div
            className={cn(styles.wrapper, {})}
            style={{ background: bgColor }}
         >
            <div className={styles.top}>
               {Icon && (
                  <div className={styles.icon}>
                     <Icon />
                  </div>
               )}
               {count === null ? null : (
                  <p className={styles.count}>
                     {count >= 0 ? (
                        count.toLocaleString()
                     ) : (
                        <Preloader loading className={styles.loadingCount} />
                     )}
                  </p>
               )}
            </div>
            <h5 className={styles.title}>{title}</h5>
         </div>
      </div>
   );
};
