import styles from "./index.module.css";
import React, { FC, useState } from "react";
import cn from "classnames";
import { AdminUserInfoBlock } from "../UserInfoBlock";
import { ReactComponent as Arrow } from "../../../assets/arrow.svg";
import { useTranslation } from "react-i18next";
import { useGetUserAvatar } from "hooks/useGetUserAvatar";
import { showDateDMY } from "helpers/date/showDate";
import { CreateChatButton } from "components/UI/CreateChatButton";
import { teacherType } from "types/userType";
import { useHistory } from "react-router-dom";
import { routes } from "../../../../../../../../../routes";

type propsType = {
   user: teacherType;
   updateState: () => void;
   updateTable: () => void;
};

export const AdminUsersTableItem_mobile: FC<propsType> = (props) => {
   const { user, updateState, updateTable } = props;
   const { t } = useTranslation();
   const [isOpened, setIsOpened] = useState<boolean>(false);
   const [avatar] = useGetUserAvatar(user.id);
   const history = useHistory();
   function openUserInfo() {
      setIsOpened((prevState) => !prevState);
   }

   return (
      <div className={styles.item}>
         <div className={styles.preview}>
            <div className={styles.preview_header}>
               <div className={styles.preview_left}>
                  <img
                     className={styles.preview_photo}
                     src={avatar}
                     alt='photo'
                  />
                  <div className={styles.header_nameAndId}>
                     <p className={styles.preview_id}>{user.id}</p>
                     <p
                        className={styles.preview_name}
                        onClick={() =>
                           history.push(routes.lk.lkStudent + "/" + user.id)
                        }
                     >
                        {user.first_name} {user.last_name}
                     </p>
                  </div>
               </div>
               <div className={styles.buttons}>
                  <CreateChatButton user_id={user.id} />
                  <button
                     className={styles.open_button}
                     onClick={() => openUserInfo()}
                  >
                     <Arrow />
                  </button>
               </div>
            </div>
            <div className={styles.preview_dates}>
               <div className={styles.preview_item}>
                  <p className={styles.preview_title}>
                     {t(
                        "Lk.AdminPanel.Table.registration-date",
                        "Дата регистрации"
                     )}
                  </p>
                  <p className={styles.preview_text}>
                     {showDateDMY(user.registration_date)}
                  </p>
               </div>
               <div className={styles.preview_item}>
                  <p className={styles.preview_title}>
                     {t("Lk.AdminPanel.Table.birth-date", "Дата рождения")}
                  </p>
                  <p className={styles.preview_text}>
                     {showDateDMY(user.birth_date)}
                  </p>
               </div>
            </div>
            <div className={styles.preview_rateAndRole}>
               <p className={styles.preview_text}>
                  {user.type === "inactive_teacher" &&
                     t("Lk.AdminPanel.Table.student", "Студент")}
               </p>
            </div>
         </div>
         <div
            className={cn(styles.info, {
               [styles["info-open"]]: isOpened,
            })}
         >
            <AdminUserInfoBlock
               updateTable={updateTable}
               updateState={updateState}
               avatar={avatar}
               user={user}
            />
         </div>
      </div>
   );
};
