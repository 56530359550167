import React, {
   FC,
   ReactNode,
   RefObject,
   useEffect,
   useRef,
   useState,
} from "react";
import styles from "./style.module.css";
import { ReactComponent as CrossIcon } from "./assets/cross.svg";
import { ReactComponent as cameNotificationIcon } from "./assets/OnNotification.svg";
import { ReactComponent as NotificationLogo } from "./assets/Notification.svg";
import { useTranslation } from "react-i18next";
import { useBlurred } from "hooks/useBlurred";
import { useToggle } from "hooks/useToggle";
import { Notifications } from "../index";
import { userApi } from "api/userApi/methods";
import { useDispatch, useSelector } from "react-redux";
import {
   notificationSlice,
   notificationSliceSelectors,
} from "store/notificationSlice";
import { notificationThunk } from "store/notificationSlice/thunk";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { Preloader } from "components/UI/Preloader";
import { useScrollLimit } from "hooks/useScrollLimit";
import { useQuery } from "hooks/useQuery";
import { notificationPropsType } from "components/Lk/components/Notifications/types";
import cn from "classnames";
import { useInfinityScroll } from "hooks/useInfinityScroll";
import { lc } from "../../../../../store/LC";

type propsType = {};

const LIMIT = 10,
   TIMEOUT_DELAY = 1000 * 60 * 2;

export const NotificationsSidebar: FC<propsType> = () => {
   const commonState = useLocalCommonState();
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const [isOpen, toggleOpen, setIsOpen] = useToggle(false);
   const [
      clearAllNotificationLoading,
      setClearAllNotificationLoading,
   ] = useState<boolean>(false);

   /* Sidebar logic S */
   const [sidebarNotifications, setSidebarNotifications] = useState<
      Array<notificationPropsType>
   >([]);
   const [limit, setLimit] = useState(LIMIT);
   const { fetch, isLoading } = useQuery(
      () =>
         userApi.getNotifications({
            offset: String(limit - LIMIT),
            limit: String(LIMIT),
         }),
      {
         condition: false,
         serializer: (response) => {
            const notifications = response?.data?.data?.items;
            if (notifications) {
               setSidebarNotifications((prev) => [...prev, ...notifications]);
            }
         },
      }
   );
   const { onScroll } = useInfinityScroll({
      callback: () => {
         setLimit((prev) => prev + LIMIT);
      },
      currentLength: sidebarNotifications.length,
   });
   useEffect(() => {
      fetch();
   }, [limit]);
   useEffect(() => {
      const timeoutId = window.setTimeout(() => {
         setSidebarNotifications([]);
         if (limit == LIMIT) {
            fetch();
         } else {
            setLimit(LIMIT);
         }
      }, TIMEOUT_DELAY);
      return () => {
         window.clearTimeout(timeoutId);
      };
   }, [sidebarNotifications]);
   /* Sidebar logic E */

   const ref = useRef<HTMLDivElement | null>(null);
   useBlurred(
      ref,
      (value) => {
         setIsOpen(value);
      },
      isOpen
   );

   const NewNotification = useSelector(
      notificationSliceSelectors.getNewNotification
   );
   const NewNotification_count = useSelector(
      notificationSliceSelectors.getCountNewNotification
   );

   useEffect(() => {
      // getAllNotification({ ping: true });
      getNewNotification({ ping: true });
   }, []);

   useEffect(() => {
      NewNotification.length < NewNotification_count && getNewNotification({});
      NewNotification.length < NewNotification_count && getAllNotification({});
   }, [NewNotification.length]);

   function getAllNotification(props: { ping?: boolean; limit?: number }) {
      const { ping = false } = props;

      // commonState.dispatch(
      //    notificationThunk.getAll({
      //       commonState,
      //       callbacks: {
      //          "200": () => {
      //             ping &&
      //                setTimeout(() => {
      //                   getAllNotification({ ping: ping });
      //                }, 60000);
      //          },
      //       },
      //    })
      // );
   }

   function getNewNotification(props: { ping?: boolean; limit?: number }) {
      const { ping = false } = props;
      const token = lc.get("token");

      if (token) {
         commonState.dispatch(
            notificationThunk.getNew({
               commonState,
               callbacks: {
                  "200": () => {
                     ping &&
                     setTimeout(() => {
                        getNewNotification({ ping: ping });
                     }, 60000);
                  },
               },
            })
         );
      }
   }

   const showNotificationsLabel = () => {
      return NewNotification_count === 0 ? (
         <p>{t("Lk.Header.no-notifications", "Уведомлений нет")}</p>
      ) : NewNotification_count % 10 == 1 && NewNotification_count < 100 ? (
         <p>
            {NewNotification_count}{" "}
            {t("Lk.Header.new-notification", "новое уведомление")}
         </p>
      ) : NewNotification.length < 100 ? (
         <p>
            {NewNotification.length}{" "}
            {t("Lk.Header.new-notifications", "новых уведомлений")}
         </p>
      ) : (
         <p>{t("Lk.Header.many-new-notifications", "99+ новых уведомлений")}</p>
      );
   };

   function clearAllNotification() {
      setClearAllNotificationLoading(true);
      userApi
         .changeNotificationStatus({
            notification_ids: [...NewNotification.map((item) => item.id)],
         })
         .then((response) => {
            getNewNotification({});
            NewNotification.map((item) => {
               dispatch(
                  notificationSlice.actions.setStatusToReadNotification(item.id)
               );
            });
            setClearAllNotificationLoading(false);
         });
   }

   return (
      <>
         <div className={styles.notificationButton} onClick={toggleOpen}>
            <button className={styles.button}>
               {React.createElement(
                  NewNotification_count > 0
                     ? cameNotificationIcon
                     : NotificationLogo
               )}
               {showNotificationsLabel()}
            </button>
         </div>
         {isOpen && (
            <div className={styles.container}>
               <div className={styles.sidebar_wrapper}>
                  <button className={styles.close_button} onClick={toggleOpen}>
                     <CrossIcon className={styles.cross} />
                  </button>
                  <div className={styles.sidebar} ref={ref}>
                     <div className={styles.header}>
                        <div className={styles.header_title}>
                           <div>
                              {React.createElement(
                                 NewNotification_count > 0
                                    ? cameNotificationIcon
                                    : NotificationLogo,
                                 {
                                    className: styles.icon,
                                 }
                              )}
                           </div>
                           {showNotificationsLabel()}
                        </div>
                        <div className={styles.title}>
                           {t(
                              "Lk.Notifications.NotificationsSidebar.title",
                              "Уведомления"
                           )}
                        </div>
                     </div>
                     <div onScroll={onScroll} className={styles.cards_wrapper}>
                        <Notifications
                           notificationsData={sidebarNotifications}
                        />
                     </div>
                     <Preloader
                        className={cn(styles.sidebar_loader, {
                           [styles.sidebar_loader_disabled]: !isLoading,
                        })}
                        loading={true}
                        variant={"centred"}
                     />
                  </div>
               </div>
            </div>
         )}
         {NewNotification && NewNotification?.length > 0 && (
            <div className={styles.temp_notifications_wrapper}>
               <div className={styles.clear}>
                  {clearAllNotificationLoading && (
                     <Preloader
                        style={{ height: "24px" }}
                        loading={clearAllNotificationLoading}
                     />
                  )}
                  {!clearAllNotificationLoading && NewNotification?.length > 1 && (
                     <button
                        onClick={clearAllNotification}
                        className={styles.clear_button}
                     >
                        {t(
                           "Lk.Notifications.NotificationsSidebar.clear",
                           "Очистить все"
                        )}
                     </button>
                  )}
               </div>
               <Notifications
                  splitByDate={false}
                  notificationsData={NewNotification.filter(
                     (item, index) => index < 5
                  )}
               />
            </div>
         )}
      </>
   );
};
