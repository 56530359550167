import { useHistory } from "react-router-dom";
import { routes } from "routes";

export const useGoToProfile = (userId: string) => {
   const history = useHistory();

   if (!userId) return () => null;
   return () => {
      history.push(`${routes.lk.profile}/${userId}`);
   };
};
