import React, { FC } from "react";
import styles from "./style.module.css";
import { ContentBlockContainer } from "components/UI/ContentBlockContainer";
import { InfoBlock } from "components/UI/InfoBlock";
import { subjectType } from "store/types";
import { ReactComponent as LinkIcon } from "./assets/link.svg";
import { ReactComponent as CameraIcon } from "./assets/camera.svg";
import { useTranslation } from "react-i18next";
import { dateMethods, getTimeDifference } from "helpers/date";
import moment from "moment-timezone";
import { gradeType, languageType } from "store/formsDataSlice/types";
import { defaultFileType } from "../../../../../../api/types";
import { FilesArea } from "../../../../../UI/FilesArea";
import { AxiosResponse } from "axios";
import { Button } from "../../../../../UI/Button";
import cn from "classnames";
import { lessonApi } from "../../../../../../api/lessonApi/methods";

type propsType = {
   subject: subjectType;
   date_from: string;
   date_to?: string;
   zoomLink?: string;
   meetingRecordUrl?: string | null;
   topic: string;
   language?: languageType;
   duration?: string;
   grade?: gradeType;
   files?: Array<defaultFileType>;
   lesson_id?: string;
   getFileMethod?: (id: string) => Promise<AxiosResponse<any>>;
};
export const LessonBody: FC<propsType> = ({
   date_from,
   date_to,
   topic,
   subject,
   zoomLink,
   duration,
   grade,
   language,
   files,
   getFileMethod,
   meetingRecordUrl,
   lesson_id,
}) => {
   const { t } = useTranslation();

   const getLessonLink = () => {
      if (lesson_id) {
         return lessonApi.getLessonLink({
            lesson_id,
         }).then((res) => {
            if (res.data.data.link) {
               window.open(res.data.data.link, '_blank', 'noopener,noreferrer');
            }
         });
      }
   };

   if (!subject) return null;
   return (
      <div className={styles.container}>
         <ContentBlockContainer
            title={t("Lk.lesson-service.title.date", "Дата и время")}
         >
            <div className={styles.date_info_wrapper}>
               <InfoBlock
                  className={styles.date_block}
                  infoKey={t("Lk.lesson-service.body.date", "Дата занятия")}
                  infoValue={
                     date_from ? moment(date_from).format("DD.MM.YYYY") : "—"
                  }
               />
               <InfoBlock
                  className={styles.date_block}
                  infoKey={t("Lk.lesson-service.body.time", "Время занятия")}
                  infoValue={
                     date_to
                        ? dateMethods.getTime(date_from) +
                        "—" +
                        dateMethods.getTime(date_to)
                        : "—"
                  }
               />
               <InfoBlock
                  className={styles.date_block}
                  infoKey={t("Lk.lesson-service.body.duration", "Длительность")}
                  infoValue={
                     duration
                        ? duration
                        : date_to
                           ? String(
                              getTimeDifference(date_from, date_to).formatted
                           )
                           : "—"
                  }
               />
            </div>
         </ContentBlockContainer>
         {lesson_id && (
            <ContentBlockContainer
               title={t(
                  "Lk.lesson-service.body.zoom-link",
                  "Ссылка на занятие"
               )}
            >
               <div className={styles.zoom_wrapper}>
                  <a href={meetingRecordUrl || undefined} target='_blank'>
                     <CameraIcon
                        className={cn(styles.zoom_icon, {
                           [styles.zoom_icon_disabled]: !meetingRecordUrl
                        })}
                     />{" "}
                  </a>
                  <Button className={styles.button} variant={"primary"} onClick={getLessonLink}>
                     {t("Lk.lesson-service.buttons.lesson", "Занятие")}
                  </Button>
               </div>
            </ContentBlockContainer>
         )}
         <ContentBlockContainer
            title={t(
               "Lk.lesson-service.body.lesson-info",
               "Информация о занятии"
            )}
         >
            <InfoBlock
               infoKey={t(
                  "Lk.lesson-service.body.lesson-language",
                  "Язык преподавания"
               )}
               infoValue={language?.name || "—"}
            />
            {!!grade && (
               <InfoBlock
                  infoKey={t("Lk.lesson-service.body.lesson-grade", "Грейд")}
                  infoValue={grade?.name}
               />
            )}
            <InfoBlock
               infoKey={t(
                  "Lk.lesson-service.body.education-system",
                  "Система образования"
               )}
               infoValue={subject?.education_system?.name}
            />
            <InfoBlock
               infoKey={t("Lk.lesson-service.body.subject", "Предмет")}
               infoValue={subject.name}
            />
            <InfoBlock
               infoKey={t("Lk.lesson-service.body.topic", "Тема занятия")}
               infoValue={topic}
            />
            {!!files?.length && (
               <InfoBlock
                  infoKey={t("Lk.lesson-service.body.extra", "Дополнительно")}
               >
                  <FilesArea files={files} apiMethod={getFileMethod} />
               </InfoBlock>
            )}
         </ContentBlockContainer>
      </div>
   );
};
