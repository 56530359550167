import { monthsLabels, usaWeek } from "../../components/Lk/data/date";
import React from "react";
import moment from "moment-timezone";
import { addZeroToNumber } from "helpers/date/index";

export const showDate = (responseData: string) => {
   return (
      <p>
         {new Date(responseData).getDate() > 10
            ? new Date(responseData).getDate()
            : "0" + new Date(responseData).getDate()}
         .
         {new Date(responseData).getMonth() + 1 > 10
            ? new Date(responseData).getMonth() + 1
            : "0" + (new Date(responseData).getMonth() + 1)}
         .{new Date(responseData).getFullYear()}
      </p>
   );
};
export const showDateLabel = (day: Date) => {
   return (
      <div className='ScheduleSidebar__ticket-date'>
         <p>
            {day.getDate()}{" "}
            {moment().month(day.getMonth()).format("MMMM").toLowerCase()},
            {" " + moment(day).date(day.getDate()).format("dddd").toLowerCase()}
         </p>
         {}
      </div>
   );
};
export const showDateDMY = (date: string | Date | null) => {
   if (!date) return "—";
   return moment(date).utcOffset("+0000").format("DD.MM.YYYY");
};
export const showDateYMD = (date: string | Date) => {
   if (!date) return "—";
   const newDate = moment(date).utcOffset("+0000");
   return `${newDate.get("year")}-${newDate.get("month") + 1}-${newDate.get(
      "date"
   )}`;
};
export const getDateWithoutTime = (date?: string | Date | null) => {
   try {
      if (!date) return undefined;
      const newDate = moment(date).utcOffset("+0000");
      const rawDate = `${newDate.get("year")}-${addZeroToNumber(
         newDate.get("month") + 1
      )}-${addZeroToNumber(newDate.get("date"))}`;

      return new Date(rawDate);
   } catch (e) {
      return undefined;
   }
};
export const showDateYMDNative = (date: string | Date) => {
   const newDate = new Date(date);
   return `${newDate.getFullYear()}-${
      newDate.getMonth() + 1
   }-${newDate.getDate()}`;
};
