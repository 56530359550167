import React, { FC } from "react";
import styles from "./index.module.css";
import { QuestionsData } from "./QuestionsData";
import { useTranslation } from "react-i18next";

type propsType = {};
export const Questions: FC<propsType> = (props) => {
   const { t } = useTranslation();
   return (
      <div className={styles.container}>
         <div className={styles.main_header} id="FAQ">
            <p>{t("Landings.Questions.header", "Вопросы")}</p>
         </div>
         <div className={styles.questions_container}>
            {QuestionsData.map((item, id) => {
               return (
                  <div className={styles.text_container}>
                     <div className={styles.number}>
                        <p>{id + 1}</p>
                     </div>
                     <div className={styles.text}>
                        <div className={styles.ask}>
                           <p>{item.ask}</p>
                        </div>
                        <div className={styles.answer}>
                           <p>{item.answer}</p>
                        </div>
                     </div>
                  </div>
               );
            })}
         </div>
      </div>
   );
};
