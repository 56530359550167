import React, { useState } from "react";
import "./style.css";
import { InputPropsType } from "../index";
import cn from "classnames";

type checkBoxPropsType<T> = {
   blockValue?: boolean;
} & InputPropsType<T>;

export function CheckBox<T>({ control, name, ...props }: checkBoxPropsType<T>) {
   const inputProps = control.register(name);

   return (
      <div
         className={cn("CheckBox", {
            [`${props.className}`]: props.className,
         })}
      >
         <div className='Input__wrapper'>
            <label tabIndex={0} className='CheckBox__label'>
               <input
                  type='checkbox'
                  value={props.blockValue ? undefined : props.value}
                  {...inputProps}
                  {...props}
                  onChange={(event) => {
                     inputProps.onChange(event).finally();
                     props.onChange && props.onChange(event);
                  }}
               />
               <div className='CheckBox__icon' />
               <p>{props.label}</p>
            </label>
         </div>
      </div>
   );
}
