// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_inputs__yP12l {\n    margin-bottom: 140px;\n}\n\n@media screen and (max-width: 719px) {\n    .style_inputs__yP12l {\n        margin-bottom: 60px;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/Registration/TeacherPages/TeacherPickLanguages/style.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;;AAEA;IACI;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".inputs {\n    margin-bottom: 140px;\n}\n\n@media screen and (max-width: 719px) {\n    .inputs {\n        margin-bottom: 60px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputs": "style_inputs__yP12l"
};
export default ___CSS_LOADER_EXPORT___;
