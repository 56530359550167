import React, { FC } from "react";
import styles from "../style.module.css";
import { useTimer } from "hooks/useTimer";
import { addZeroToNumber } from "helpers/date";

export const FastLessonTimer: FC = () => {
   const timer = useTimer();

   return (
      <div className={styles.timer}>
         {addZeroToNumber(timer[0])}:{addZeroToNumber(timer[1])}
      </div>
   );
};
