import React, { FC } from "react";
import { useLkContext } from "../index";
import { LkStudentOwn } from "./components/LkStudentOwn";
import { useTranslation } from "react-i18next";
import { InfoBlock } from "components/UI/InfoBlock";

export const LkStudent: FC = () => {
   const { t } = useTranslation();
   const { template, profileData } = useLkContext();

   if (!profileData) return null;
   return (
      <>
         <InfoBlock infoKey={t("Lk.LkStudent.grade", "Грейд")} variant='bold'>
            {profileData?.study_education_system?.level.name || "—"}
         </InfoBlock>
         <InfoBlock
            infoKey={t("Lk.LkStudent.system", "Система образования")}
            variant='bold'
         >
            {profileData.study_education_system?.name}
         </InfoBlock>

         {template.isOwn && <LkStudentOwn />}
      </>
   );
};
