import { createRequest } from "../../core/LTools/core/apiMethods/createRequest";
import { mainAxios } from "../index";
import {
   addFileToInviteType,
   createInviteBySlotType,
   deleteInviteType,
   getInviteFileType,
   getInviteType,
   getSpecificInviteType,
} from "./types";
import { dataToQueryString } from "../../core/LTools/core/apiMethods/dataToQueryString";

export const inviteApi = {
   getInvite: async (query: getInviteType) => {
      return createRequest(mainAxios.get(`/invite${dataToQueryString(query)}`));
   },
   getSpecificInvite: async ({
      invite_id,
      ...query
   }: getSpecificInviteType) => {
      return createRequest(
         mainAxios.get(`/invite/${invite_id}${dataToQueryString(query)}`)
      );
   },
   createInviteBySlot: async ({
      slot_id,
      ...payload
   }: createInviteBySlotType) => {
      return createRequest(mainAxios.post(`/slot/${slot_id}/invite`, payload));
   },

   deleteInvite: async ({ slot_id, invite_id }: deleteInviteType) => {
      return createRequest(mainAxios.delete(`/invite/${invite_id}`));
   },

   addFileToInvite: async ({ invite_id, ...payload }: addFileToInviteType) => {
      return createRequest(
         mainAxios.post(`/invite/${invite_id}/file`, payload)
      );
   },

   getInviteFile: async ({ invite_id, file_id }: getInviteFileType) => {
      return createRequest(
         mainAxios.get(`/invite/${invite_id}/file/${file_id}`)
      );
   },
};
