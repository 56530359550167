import { thunkType } from "core/LTools/core/types/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { LTools } from "core/LTools/constructor";
import {
   createLessonByInviteType,
   deleteLessonType,
   getLessonDataType,
   voteForLessonType,
} from "api/lessonApi/types";
import { lessonApi } from "api/lessonApi/methods";
import { lessonSlice } from "./index";
import { inviteApi } from "api/inviteApi/methods";
import {
   createInviteBySlotType,
   deleteInviteType,
   getInviteType,
} from "api/inviteApi/types";
import {
   addStudentToGroupLessonType,
   createGroupLessonType,
   deleteGroupLessonType,
   deleteStudentFromGroupLessonType,
} from "api/groupLessonApi/types";
import { groupLessonApi } from "api/groupLessonApi/methods";
import {
   createLessonFromApplicationType,
   deleteSuggestionType,
} from "api/applicationApi/types";
import { applicationApi } from "api/applicationApi/methods";
import {
   createEventType,
   deleteEventChainType,
   deleteSlotType,
} from "api/eventSlotApi/types";
import { eventSlotApi } from "api/eventSlotApi/methods";
import { modalWindowButtonType, uiSlice } from "store/modalWindowSlice";
import { addMethodToUpdateLang } from "services/translateService/useLangInit";
import i18n from "services/translateService/i18n";
import { getFirstErrorFromResponse } from "helpers/errors";
import { scheduleApi } from "store/scheduleSlice/thunk";
import { lkApi } from "api/LkApi/methods";

export let errorCaseHandler: (
   dispatch: any,
   message?: string,
   button?: modalWindowButtonType
) => void;
addMethodToUpdateLang(() => {
   errorCaseHandler = (
      dispatch: any,
      message?: string,
      button?: modalWindowButtonType
   ) => {
      dispatch(
         uiSlice.actions.setModalType({
            template: "default",
            payload: {
               title: i18n.t("Common.errors.default", "Ошибка"),
               subtitle: message,
               button: button || "to_lk",
               status: "error",
            },
         })
      );
   };
});

export const lessonThunk = {
   /* Lesson */
   getLesson: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & getLessonDataType>) {
      return createAsyncThunk("getLesson", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => lessonApi.getLessonData(props),
            responseReactionCreator: (response) => ({
               "200": () => {
                  commonState.dispatch(
                     lessonSlice.actions.setCurrentLesson(response.data.data)
                  );
               },
            }),
         });

         return response as any;
      })();
   },
   createLessonByInvite: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & createLessonByInviteType>) {
      return createAsyncThunk("createLessonByInvite", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => lessonApi.createLessonByInvite(props),
            responseReactionCreator: (response) => ({
               200: () => {},
               400: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response)
                  );
               },
               404: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response)
                  );
               },
            }),
         });

         return response as any;
      })();
   },
   voteForLesson: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & voteForLessonType>) {
      return createAsyncThunk("voteForLesson", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => lessonApi.voteForLesson(props),
            responseReactionCreator: (response) => ({
               200: () => {},
            }),
         });

         return response as any;
      })();
   },
   deleteLesson: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & deleteLessonType>) {
      return createAsyncThunk("deleteLesson", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => lessonApi.deleteLesson(props),
            responseReactionCreator: (response) => ({
               200: () => {},
               404: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response)
                  );
               },
               500: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response)
                  );
               },
            }),
         });

         return response as any;
      })();
   },

   /* Invite */
   getInvite: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & getInviteType>) {
      return createAsyncThunk("getInvite", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => inviteApi.getInvite(props),
            responseReactionCreator: (response) => ({
               "200": () => {
                  commonState.dispatch(
                     lessonSlice.actions.setCurrentLesson(
                        response.data.data.items[0]
                     )
                  );
               },
            }),
         });

         return response as any;
      })();
   },
   createInviteBySlot: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & createInviteBySlotType>) {
      return createAsyncThunk("createInviteBySlot", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => inviteApi.createInviteBySlot(props),
            responseReactionCreator: (response) => ({
               200: () => {},
               400: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response)
                  );
               },
               404: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response)
                  );
               },
            }),
         });

         return response as any;
      })();
   },
   deleteInvite: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & deleteInviteType>) {
      return createAsyncThunk("deleteInvite", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => inviteApi.deleteInvite(props),
            responseReactionCreator: (response) => ({
               200: () => {},
            }),
         });

         return response as any;
      })();
   },

   /* Group */
   addStudentToGroupLessonType: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & addStudentToGroupLessonType>) {
      return createAsyncThunk("joinStudentToOpenLesson", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => groupLessonApi.addStudentToGroupLesson(props),
            responseReactionCreator: (response) => ({
               201: () => {},
               400: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response)
                  );
               },
               404: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response)
                  );
               },
            }),
         });

         return response as any;
      })();
   },
   deleteGroupLesson: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & deleteGroupLessonType>) {
      return createAsyncThunk("rejectGroupLesson", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => groupLessonApi.deleteGroupLesson(props),
            responseReactionCreator: (response) => ({
               204: () => {},
               400: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response)
                  );
               },
               404: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response)
                  );
               },
               500: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response)
                  );
               },
            }),
         });

         return response as any;
      })();
   },
   createGroupLesson: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & createGroupLessonType>) {
      return createAsyncThunk("createGroupLesson", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => groupLessonApi.createGroupLesson(props),
            responseReactionCreator: (response) => ({
               201: () => {
                  commonState.dispatch(
                     scheduleApi.getTickets()(String(lkApi.user_id))
                  );
               },
               400: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response)
                  );
               },
               404: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response)
                  );
               },
            }),
         });

         return response as any;
      })();
   },
   deleteStudentFromLesson: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & deleteStudentFromGroupLessonType>) {
      return createAsyncThunk("deleteStudentFromLesson", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => groupLessonApi.deleteStudentFromLesson(props),
            responseReactionCreator: (response) => ({
               200: () => {},
            }),
         });

         return response as any;
      })();
   },

   /* Application */
   createLessonFromApplication: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & createLessonFromApplicationType>) {
      return createAsyncThunk("approveApplication", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => applicationApi.createLessonFromApplication(props),
            responseReactionCreator: (response) => ({
               201: () => {},
               400: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response)
                  );
               },
               404: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response)
                  );
               },
               500: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     "Internal error",
                     "close"
                  );
               },
            }),
         });

         return response as any;
      })();
   },
   deleteSuggestion: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & deleteSuggestionType>) {
      return createAsyncThunk("deleteApplication", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => applicationApi.deleteSuggestion(props),
            responseReactionCreator: (response) => ({
               204: () => {},
               404: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response)
                  );
               },
               500: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     "Internal error",
                     "close"
                  );
               },
            }),
         });

         return response as any;
      })();
   },

   /* Slot */
   createEvent: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & createEventType>) {
      return createAsyncThunk("createEvent", async (_, { dispatch }) => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => eventSlotApi.createEvent(props),
            responseReactionCreator: (response) => ({
               200: () => {
                  commonState.dispatch(
                     scheduleApi.getTickets()(String(lkApi.user_id))
                  );
               },
               400: () => {
                  errorCaseHandler(
                     dispatch,
                     getFirstErrorFromResponse(response)
                  );
               },
               404: () => {
                  errorCaseHandler(
                     dispatch,
                     getFirstErrorFromResponse(response)
                  );
               },
            }),
         });

         return response as any;
      })();
   },
   deleteSlot: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & deleteSlotType>) {
      return createAsyncThunk("deleteSlot", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => eventSlotApi.deleteSlot(props),
            responseReactionCreator: (response) => ({
               204: () => {},
               400: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response)
                  );
               },
               404: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response)
                  );
               },
            }),
         });

         return response as any;
      })();
   },
   deleteEvent: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & deleteEventChainType>) {
      return createAsyncThunk("deleteEvent", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => eventSlotApi.deleteEventChain(props),
            responseReactionCreator: (response) => ({
               204: () => {},
               400: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response)
                  );
               },
               404: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response)
                  );
               },
            }),
         });

         return response as any;
      })();
   },
};
