import { subjectType } from "store/types";
import {
   categoryType,
   educationSystemType,
} from "../../../../../store/formsDataSlice/types";

export const groupSubjectsByCategory = (subjects: any) => {
   const undefKey = "Undef";
   let result: {
      [key: string]: Array<subjectType>;
   } = {};
   subjects.map((item: any) => {
      if (!result[item.category?.name || undefKey]) {
         result[item.category?.name || undefKey] = [];
      }
      result[item.category?.name || undefKey].push(item);
   });
   return result;
};
export const groupSubjectsByCategorySelect = (
   subjects: any,
   pickedSystem: educationSystemType | undefined
) => {
   const undefKey = "Undef";
   let result: {
      [key: string]: Array<subjectType>;
   } = {};
   if (pickedSystem) {
      subjects.map((item: any) => {
         if (item.value.education_system?.id == pickedSystem?.id) {
            if (!result[item.value.category?.name || undefKey]) {
               result[item.value.category?.name || undefKey] = [];
            }
            result[item.value.category?.name || undefKey].push(item);
         }
      });
   } else {
      return result;
   }
   return result;
};
