// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__27ygy {\n    position: relative;\n    width: 100%;\n\n}", "",{"version":3,"sources":["webpack://src/components/Registration/TeacherPages/TeacherRegistrationChooseSubjects/style.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;;AAEf","sourcesContent":[".container {\n    position: relative;\n    width: 100%;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__27ygy"
};
export default ___CSS_LOADER_EXPORT___;
