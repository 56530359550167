import {createRequest} from "../../core/LTools/core/apiMethods/createRequest";
import {cancelTokenSource, mainAxios} from "../index";
import {createChatType, createNewMessage, downloadFilesChatType, getDialogsQueryType} from "./types";
import {dataToQueryString} from "core/LTools/core/apiMethods/dataToQueryString";

export const chatApi = {
   createChat: async (data: createChatType) => {
      return createRequest(mainAxios.post("/chat", data));
   },
   getDialogs: async (query : getDialogsQueryType) => {
      return createRequest(mainAxios.get(`/chat${dataToQueryString(query)}`));
   },
   getCheckInvisibleMessages: async () => {
      return createRequest(mainAxios.get(`/message:check`));
   },
   sendChatToAnotherAdmin: async (chat_id: number, admin_id : string) => {
      return createRequest(mainAxios.put(`/chat/${chat_id}` , {lead_admin_id : admin_id}));
   },
   getMessageByChatId: async (chat_id: number, limit: string ,offset: string) => {
      return createRequest(mainAxios.get(`/chat/${chat_id}/message${dataToQueryString({limit: limit, offset : offset })}` ));
   },
   alertNewMessageForChatId: async ({message , chat_id} : createNewMessage) => {
      return createRequest(mainAxios.post(`/chat/${chat_id}/message`, {...message}));
   },
   downloadFiles: async ({message_id,file_id , chat_id} : downloadFilesChatType) => {
      return createRequest(mainAxios.get(`/chat/${chat_id}/message/${message_id}/file/${file_id}`));
   },




};
