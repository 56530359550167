import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { ModalWindowBody } from "components/ModalWindow/templates/components/ModalWindowBody";
import { useTranslation } from "react-i18next";
import { Button } from "components/UI/Button";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { lessonThunk } from "store/lessonSlice/thunk";
import { useSelector } from "react-redux";
import { authSliceSelectors } from "store/authSlice";

type propsType = {
   slot_id: string;
   event_id: string;
   closeModal: () => void;
   deleteSlotLocal: () => void;
   deleteEventLocal: () => void;
};
export const DeleteEventTemplate: FC<propsType> = ({
   event_id,
   slot_id,
   closeModal,
   deleteEventLocal,
   deleteSlotLocal,
}) => {
   const { t } = useTranslation();
   const commonState = useLocalCommonState();
   const user_id = useSelector(authSliceSelectors.getId);

   const deleteEvent = () => {
      commonState.dispatch(
         lessonThunk.deleteEvent({
            commonState,
            slot_id,
            event_id,
            user_id,
            callbacks: {
               204: () => {
                  closeModal();
                  deleteEventLocal();
               },
            },
         })
      );
   };
   const deleteSlot = () => {
      commonState.dispatch(
         lessonThunk.deleteSlot({
            commonState,
            slot_id: slot_id,
            user_id,
            callbacks: {
               204: () => {
                  closeModal();
                  deleteSlotLocal();
               },
            },
         })
      );
   };

   return (
      <>
         <ModalWindowBody
            title={t("ModalWindow.DeleteSlot.title", "Подтвердите удаление")}
            subtitle={t(
               "ModalWindow.DeleteSlot.subtitle",
               "При удалении времени для занятия будут удалены и запросы студентов"
            )}
         />
         <div className={styles.buttons}>
            <Button
               onClick={deleteSlot}
               variant={"red"}
               className={styles.button}
               loading={commonState.loading}
            >
               {t(
                  "ModalWindow.DeleteEvent.button1",
                  "Удалить только текущее занятие"
               )}
            </Button>
            <Button
               variant={"red"}
               className={styles.button}
               onClick={deleteEvent}
               loading={commonState.loading}
            >
               {t(
                  "ModalWindow.DeleteEvent.button2",
                  "Удалить все повторяющиеся занятия"
               )}
            </Button>
            <Button
               loading={commonState.loading}
               className={styles.button}
               onClick={closeModal}
            >
               {t("Buttons.cancel", "Отменить")}
            </Button>
         </div>
      </>
   );
};
