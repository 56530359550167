import React, { FC, useEffect } from "react";
import styles from "./style.module.css";
import { lessonThunk } from "store/lessonSlice/thunk";
import { useSelector } from "react-redux";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { useParams } from "react-router-dom";
import { lessonSliceSelectors } from "store/lessonSlice";
import { LessonTitle } from "../../components/LessonTitle";
import { UserWidget } from "components/UI/UserWidget";
import { LessonBody } from "../../components/LessonBody";
import { LessonButtons } from "./LessonButtons";
import { LessonRating } from "../../components/LessonRating";
import { lessonApi } from "api/lessonApi/methods";
import { useTranslation } from "react-i18next";
import { authSliceSelectors } from "store/authSlice";
import { Icons } from "assets/iconsCorrect";
import { Preloader } from "components/UI/Preloader";
import { Board } from "../../../../../UI/Board";

var defaultLessonPingId = 0;
export const DefaultLesson: FC = () => {
   const { t } = useTranslation();
   const userId = useSelector(authSliceSelectors.getId);
   const commonState = useLocalCommonState();
   const { id: lesson_id } = useParams<{ id: string }>();
   const lesson = useSelector(lessonSliceSelectors.getCurrentLesson);
   const isTeacher = userId == String(lesson?.teacher?.id);

   const lessonIconResolver = () => {
      switch (lesson.status) {
         case "finished_lesson":
            return null;
         case "rejected_lesson":
            return <Icons.ui.RejectIcon />;
         case "new_lesson":
            return <Icons.ui.AlarmClockIcon />;
         default:
            return null;
      }
   };
   const lessonTitleResolver = () => {
      if (lesson.status == "new_lesson") {
         return isTeacher
            ? t(
                 "Lk.lesson-service.default.teacher-title",
                 "Занятие со студентом"
              )
            : t(
                 "Lk.lesson-service.default.student-title",
                 "Занятие с преподавателем"
              );
      }
      return t("Lk.lesson-service.default.lesson-canceled", "Занятие отменено");
   };

   const lessonButtons = () => {
      if (lesson.status == "new_lesson" && isTeacher) {
         return "teacher";
      } else return "default";
   };

   const getFileHandler = (id: string) => {
      return lessonApi.getLessonFile({
         file_id: id,
         lesson_id,
      });
   };

   const getLesson = () => {
      commonState.dispatch(
         lessonThunk.getLesson({
            commonState,
            lesson_id: lesson_id,
            callbacks: {
               200: () => {
                  defaultLessonPingId = window.setTimeout(getLesson, 120000);
               },
            },
         })
      );
   };

   useEffect(() => {
      window.clearTimeout(defaultLessonPingId);
      getLesson();
      return () => window.clearTimeout(defaultLessonPingId);
   }, [lesson_id]);

   return commonState.loading ? (
      <Preloader loading={commonState.loading} variant={"lesson-service"} />
   ) : lesson.id ? (
      <div className={styles.container}>
         {lesson.status != "finished_lesson" ? (
            <LessonTitle
               title={lessonTitleResolver()}
               icon={lessonIconResolver()}
            />
         ) : (
            <LessonRating
               lesson_id={String(lesson?.id)}
               votes={lesson?.votes || []}
               apiMethod={lessonApi.voteForLesson}
               target={isTeacher ? "student" : "teacher"}
            />
         )}
         <main className='Lessons__main'>
            <div className={styles.body_container}>
               <UserWidget
                  user={isTeacher ? lesson.student : lesson.teacher}
                  variant={isTeacher ? "student" : "teacher"}
                  className={styles.user_widget}
               />
               <Board
                  title={
                     <>
                        {t(
                           "Lk.lesson-service.body.lesson-computed-cost",
                           "Расчетная стоимость урока"
                        )}:{" "}
                        <span style={{ color: "var(--color-primary)" }}>{lesson.price}$</span>
                     </>
                  }
                  className={styles.board}
               />
               <LessonBody
                  zoomLink={lesson?.link}
                  meetingRecordUrl={lesson.meeting_record_url}
                  subject={lesson.subject}
                  date_from={lesson.date_from}
                  date_to={lesson.date_to}
                  topic={lesson.topic}
                  files={lesson.files}
                  language={lesson?.language}
                  getFileMethod={getFileHandler}
                  lesson_id={lesson_id}
               />
               <LessonButtons lesson_id={lesson_id} variant={lessonButtons()} />
            </div>
         </main>
      </div>
   ) : (
      <>Unexpected error</>
   );
};
