import React, {FC, useEffect, useState} from "react";
import styles from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
   fastLessonSlice,
   fastLessonSliceSelectors,
} from "store/fastLessonSlice";
import { subjectType } from "store/types";
import { useHistory } from "react-router-dom";
import { routes } from "routes";
import { useTranslation } from "react-i18next";
import { useQuery } from "hooks/useQuery";
import { formsDataApi } from "api/formsDataApi/methods";
import { groupSubjectsByCategory } from "components/Landings/Filter/SubjectPicker/helpers/groupSubjectsByCategory";
import { firstLetterUpperCase } from "helpers/string/firstLetterUppercase";
import { Preloader } from "components/UI/Preloader";
import { lkSliceSelectors } from "store/lkSlice";

type propsType = {};
export const SubjectPicker: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const [pickedCategory, setPickedCategory] = useState("");
   const isFast = useSelector(fastLessonSliceSelectors.getIsFast);
   const { data } = useSelector(fastLessonSliceSelectors.getFiltersState);
   const user = useSelector(lkSliceSelectors.getUserData);
   const history = useHistory();

   const setSubject = (subject: subjectType) => {
      dispatch(
         fastLessonSlice.actions.setFiltersData({
            subject: subject,
         })
      );
      if (isFast) {
         if (user.id !== undefined) {
            history.push(routes.lk.lesson.fast.register);
         } else {
            history.push(routes.registration.auth.firstStage);
         }
      } else {
         dispatch(fastLessonSlice.actions.setFiltersStage(2));
      }
   };

   const { data: subjects, isLoading: subjectsLoading } = useQuery(
      formsDataApi.getSubject,
      {
         cachedValueName: "subjects",
         serializer: (res): Array<subjectType> => {
            return res?.data.data.items.filter((item: any) => {
               return (
                  Number(item.education_system.id) == data.educationSystem?.id
               );
            });
         },
      }
   );
   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])
   const groupedSubjects: {
      [key: string]: Array<subjectType>;
   } = subjects ? groupSubjectsByCategory(subjects) : {};
   console.log(subjects, groupedSubjects);
   const categoriesNames = Object.keys(groupedSubjects) || [];

   return (
      <div className={styles.container}>
         {subjectsLoading ? (
            <Preloader loading={subjectsLoading} variant={"large"} />
         ) : (
            !!categoriesNames.length &&
            categoriesNames[0] != "Undef" && (
               <div className={styles.categories_container}>
                  <div className={styles.categories_header}>
                     {t(
                        "SearchFilters.subject.category",
                        "Выберите интересующий раздел"
                     )}
                  </div>
                  <div className={styles.categories}>
                     {categoriesNames.map((categoryName) => {
                        const category = groupedSubjects[categoryName];
                        return (
                           <div
                              key={categoryName}
                              className={styles.category}
                              onClick={() => {
                                 setPickedCategory(categoryName);
                              }}
                           >
                              <div className={styles.category_left}>
                                 {categoryName}
                              </div>
                           </div>
                        );
                     })}
                  </div>
               </div>
            )
         )}
         {(pickedCategory || categoriesNames[0] == "Undef") && (
            <div className={styles.subjects_header}>
               {t(
                  "SearchFilters.subject.subject",
                  "Выберите интересующий предмет"
               )}
            </div>
         )}

         <div className={styles.subjects}>
            {categoriesNames[0] == "Undef" &&
               subjects.map((subject) => {
                  return (
                     <SubjectComponent
                        subject={subject}
                        setSubject={setSubject}
                     />
                  );
               })}
            {pickedCategory &&
               groupedSubjects[pickedCategory].map((subject: subjectType) => {
                  if (pickedCategory == subject.category?.name)
                     return (
                        <SubjectComponent
                           subject={subject}
                           setSubject={setSubject}
                        />
                     );
               })}
         </div>
      </div>
   );
};

type SubjectComponentType = {
   setSubject: (subject: subjectType) => void;
   subject: subjectType;
};
const SubjectComponent: FC<SubjectComponentType> = ({
   subject,
   setSubject,
}) => (
   <div className={styles.subject} onClick={() => setSubject(subject)}>
      {firstLetterUpperCase(subject.name)}
   </div>
);
