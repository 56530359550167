import moment from "moment-timezone";

export const getDateWithoutHours = (date: string | Date) => {
   return moment(date).startOf("day").format();
};

export function sortElementsByDate<T extends string, F>(
   elements: Array<{ [key in T]: string } & F>,
   key: T
): Array<F> {
   return elements.sort((a, b) => {
      const dateFromA = moment(a[key]);
      const dateFromB = b[key];
      if (dateFromA.isAfter(dateFromB)) {
         return 1;
      }
      if (dateFromA.isBefore(dateFromB)) {
         return -1;
      }
      return 0;
   });
}

export const ticketsGroupByDay = (
   array: any
): Array<{
   date: string;
   elements: Array<any>;
}> => {
   if (!array.length) return [];

   let result: {
      [key: string]: Array<any>;
   } = {};

   for (let i = 0; i < array.length; i++) {
      const event = array[i];
      const tempDate = getDateWithoutHours(
         event?.date_from || event?.created_at
      );

      if (!result[tempDate]) {
         result[tempDate] = [];
      }
      result[tempDate].push(event);
   }

   const resultArray: Array<{
      date: string;
      elements: Array<any>;
   }> = [];
   for (let key in result) {
      resultArray.push({
         date: key,
         elements: result[key],
      });
   }
   return sortElementsByDate(resultArray, "date");
};

export const ticketGroupHandler = (array: any) => {
   if (!array.length) return [];
   let currentDate = array[0].date;
   let result: Array<{
      date: Date;
      operations: any;
   }> = [
      {
         date: currentDate,
         operations: [],
      },
   ];
   let currentIndex = 0;

   for (let i = 0; i < array.length; i++) {
      const event = array[i];
      if (event.date.toString() != currentDate.toString()) {
         currentIndex++;
         currentDate = event.date;
         result.push({
            date: currentDate,
            operations: [],
         });
      }
      result[currentIndex].operations.push(event);
   }
   return result;
};
