import React, { FC, useState } from "react";
import styles from "./index.module.css";
import { ReactComponent as Agree } from "../../../assets/agree.svg";
import { ReactComponent as Block } from "../../../assets/block.svg";
import { Button } from "components/UI/Button";
import { FileDownloader } from "components/UI/FileDownloader";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { adminThunks } from "store/adminSlice/thunks";
import { ReactComponent as ErrorIcon } from "assets/iconsCorrect/status/error.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";
import { showDateDMY } from "helpers/date/showDate";
import { teacherType } from "types/userType";
import { Icons } from "assets/iconsCorrect";
import classNames from "classnames";

type propsType = {
   inactiveTeacherData: teacherType;
   avatar: string;
   updateState: () => void;
};

export const AdminInfoBlock: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const { inactiveTeacherData, updateState } = props;
   const commonState = useLocalCommonState();
   const [userCopy, setUserCopy] = useState<teacherType>(
      JSON.parse(JSON.stringify(inactiveTeacherData))
   );

   const myUser = useSelector(lkSliceSelectors.getUserData);

   function confirmApplication() {
      // commonState.dispatch(
      //    adminThunks.updateUser({
      //       user_id: inactiveTeacherData.id,
      //       commonState,
      //       last_name: userCopy.last_name,
      //       first_name: userCopy.first_name,
      //       birth_date: userCopy.birth_date,
      //       instruction_subjects: userCopy.instruction_subjects
      //          .filter((item) => !item.deleted)!
      //          .map((item) => String(item.id)),
      //       instruction_languages: userCopy.instruction_languages
      //          .filter((item) => !item.deleted)!
      //          .map((item) => Number(item.id)),
      //       callbacks: {
      //          "200": () => {
      //             commonState
      //                .dispatch(
      //                   adminThunks.confirmApplication({
      //                      user_id: inactiveTeacherData.id,
      //                      commonState,
      //                   })
      //                )
      //                .then(() => updateState());
      //          },
      //       },
      //    })
      // );
      commonState
          .dispatch(
              adminThunks.confirmApplication({
                 user_id: inactiveTeacherData.id,
                 commonState,
              })
          )
          .then(() => updateState());
   }

   function rejectApplication() {
      commonState
         .dispatch(
            adminThunks.declineApplication({
               user_id: props.inactiveTeacherData.id,
               commonState,
            })
         )
         .then(() => updateState());
   }

   function userToAdmin() {
      commonState
         .dispatch(
            adminThunks.setUserRole({
               user_id: props.inactiveTeacherData.id,
               role: "admin",
               commonState,
            })
         )
         .then(() => updateState());
   }

   function adminToUser() {
      commonState
         .dispatch(
            adminThunks.setUserRole({
               user_id: props.inactiveTeacherData.id,
               role: "user",
               commonState,
            })
         )
         .then(() => updateState());
   }

   function blockUser() {
      commonState
         .dispatch(
            adminThunks.blockUser({
               user_id: props.inactiveTeacherData.id,
               commonState,
            })
         )
         .then(() => updateState());
   }

   const checkIfArrayFieldHasLessThanOneElement = (
      key: keyof Pick<
         teacherType,
         | "instruction_subjects"
         | "instruction_languages"
         | "instruction_education_systems"
      >
   ) => {
      return [...userCopy[key]].filter((item) => !item.deleted).length <= 1;
   };

   /**
    * @Logic Delete handling
    * Достаём айди сущности с дом элемента
    * Если сущность сейчас удалится и кол-во не удалённых сущностей <=1 - не удаляем
    * Далее для каждого случая описывается своё поведение удаления (предметы и системы связаны)
    */
   const deleteLanguage = (e: any) => {
      const languageId = e.currentTarget.getAttribute("data-id");
      const language = userCopy.instruction_languages.find(
         (item) => item.id == languageId
      );
      const shouldDelete = !language!.deleted;

      if (
         checkIfArrayFieldHasLessThanOneElement("instruction_languages") &&
         shouldDelete
      ) {
         return;
      }

      setUserCopy((user) => ({
         ...user,
         instruction_languages: user.instruction_languages.map((lang) => ({
            ...lang,
            deleted: languageId == lang.id ? !lang.deleted : lang.deleted,
         })),
      }));
   };

   const deleteSystem = (e: any) => {
      const systemId = e.currentTarget.getAttribute("data-id");
      const system = userCopy.instruction_education_systems.find(
         (item) => item.id == systemId
      );
      const shouldDelete = !system!.deleted;

      if (
         checkIfArrayFieldHasLessThanOneElement(
            "instruction_education_systems"
         ) &&
         shouldDelete
      ) {
         return;
      }

      setUserCopy((user) => ({
         ...user,
         instruction_education_systems: user.instruction_education_systems.map(
            (item) => ({
               ...item,
               deleted: systemId == item.id ? !item.deleted : item.deleted,
            })
         ),
         instruction_subjects: user.instruction_subjects.map((item) => ({
            ...item,
            deleted:
               // Находим предмет, инвертируем значения предметов в зависимости от того
               // удалена ли будет сейчас система или восстановлена
               systemId == item.education_system.id
                  ? !system!.deleted
                  : item.deleted,
         })),
      }));
   };
   const deleteSubject = (e: any) => {
      const subjectId = e.currentTarget.getAttribute("data-id");
      const subject = userCopy.instruction_subjects.find(
         (item) => item.id == subjectId
      );
      const shouldDelete = !subject!.deleted;

      if (
         checkIfArrayFieldHasLessThanOneElement("instruction_subjects") &&
         shouldDelete
      ) {
         return;
      }

      setUserCopy((user) => ({
         ...user,
         instruction_subjects: user.instruction_subjects.map((item) => ({
            ...item,
            deleted: subjectId == item.id ? !item.deleted : item.deleted,
         })),
         instruction_education_systems: user.instruction_education_systems.map(
            (system) => ({
               ...system,
               deleted:
                  // Нашли систему, если предмет и система были
                  // удалены, то восстанавливаем систему
                  subject!.education_system.id == system.id &&
                  subject!.deleted &&
                  system.deleted
                     ? false
                     : system.deleted,
            })
         ),
      }));
   };

   if (!inactiveTeacherData || inactiveTeacherData.is_banned) return null;
   return (
      <div className={styles.container}>
         <div className={styles.firstLine}>
            <div className={styles.photoAndName}>
               <img className={styles.photo} src={props.avatar} alt='' />
               <h4 className={styles.name}>
                  {inactiveTeacherData.first_name}{" "}
                  {inactiveTeacherData.last_name}
               </h4>
            </div>
            <div className={styles.header_info}>
               <h4 className={styles.name}>
                  {inactiveTeacherData.first_name}{" "}
                  {inactiveTeacherData.last_name}
               </h4>
               <div className={styles.bottom}>
                  <div className={styles.header_item}>
                     <p className={styles.header_title}>id</p>
                     <p className={styles.header_text}>
                        {inactiveTeacherData.id}
                     </p>
                  </div>
                  <div
                     className={[
                        styles.header_item,
                        styles.date_registration,
                     ].join(" ")}
                  >
                     <p className={styles.header_title}>
                        {t(
                           "Lk.AdminPanel.Table.registration-date",
                           "Дата регистрации"
                        )}
                     </p>
                     <p className={styles.header_text}>
                        {showDateDMY(inactiveTeacherData.registration_date)}
                     </p>
                  </div>
                  <div className={styles.header_item}>
                     <p className={styles.header_title}>
                        {t("Lk.AdminPanel.Table.birth-date", "Дата рождения")}
                     </p>
                     <p className={styles.header_text}>
                        {showDateDMY(inactiveTeacherData.birth_date)}
                     </p>
                  </div>
                  <div className={styles.header_item}>
                     <p className={styles.header_title}>
                        {t("Lk.AdminPanel.Table.status", "Статус")}
                     </p>
                     <p className={styles.header_text}>
                        {(inactiveTeacherData.type === "inactive_teacher" ||
                           inactiveTeacherData.type === "student") &&
                           t("Lk.AdminPanel.Table.student", "Студент")}
                        {(inactiveTeacherData.type === "teacher" ||
                           inactiveTeacherData.type === "student") &&
                           t(
                              "Lk.ScheduleFilter.filter.title-teacher",
                              "Преподаватель"
                           )}
                     </p>
                  </div>
                  <div className={styles.header_item}>
                     <p className={styles.header_title}>email</p>
                     <p className={styles.header_text}>
                        {inactiveTeacherData.email || "—"}
                     </p>
                  </div>
               </div>
            </div>
         </div>
         <div className={styles.second}>
            <div className={styles.title}>
               {inactiveTeacherData.type === "inactive_teacher" &&
                  t("Lk.AdminPanel.Table.student", "Студент")}
            </div>
            <div className={styles.second_item}>
               <p className={styles.header_title}>
                  {t(
                     "Lk.AdminPanel.Table.registration-date",
                     "Дата регистрации"
                  )}
               </p>
               <p className={styles.header_text}>
                  {showDateDMY(inactiveTeacherData.registration_date)}
               </p>
            </div>
            <div className={styles.second_item}>
               <p className={styles.header_title}>
                  {t(
                     "Lk.AdminPanel.Table.studied-subjects",
                     "Изучаемые предметы"
                  )}
               </p>
               <p className={styles.header_text}>
                  {inactiveTeacherData.study_subjects?.length
                     ? inactiveTeacherData.study_subjects
                          .map((subject: any) => {
                             return subject.name;
                          })
                          .join(", ")
                     : "—"}
               </p>
            </div>
            <div className={styles.second_item}>
               <p className={styles.header_title}>
                  {t(
                     "Notebook.headers.education_systems",
                     "Системы образования"
                  )}
               </p>
               <p className={styles.header_text}>
                  {inactiveTeacherData.study_education_system?.name || "—"}
               </p>
            </div>
            <div className={styles.second_item}>
               <p className={styles.header_title}>
                  {t("Lk.AdminPanel.Table.grades", "Грейды")}
               </p>
               <p className={styles.header_text}>
                  {inactiveTeacherData.study_grade?.id || "—"}
               </p>
            </div>
         </div>
         <div className={styles.third}>
            <div className={styles.title}>
               {t(
                  "Registration.TeacherPages.TeacherFirstStage.title",
                  "Анкета преподавателя"
               )}
            </div>
            <div className={styles.second_item}>
               <p className={styles.header_title}>
                  {t(
                     "Lk.Notifications.Date.student-to-inactive",
                     "Дата подачи заявки"
                  )}
               </p>
               <p className={styles.header_text}>
                  {showDateDMY(
                     inactiveTeacherData.inactive_teacher_created_date
                  )}
               </p>
            </div>
            <div className={styles.second_item}>
               <p className={styles.header_title}>
                  {t("SearchFilters.grade.language", "Язык обучения")}
               </p>
               <div className={styles.delete_list}>
                  {userCopy.instruction_languages.map((item) => (
                     <DeletableInfoBlock
                        deleted={item.deleted}
                        key={item.id}
                        onClick={deleteLanguage}
                        text={item.name}
                        entity_id={String(item.id)}
                     />
                  ))}
               </div>
            </div>
            <div className={styles.second_item}>
               <p className={styles.header_title}>
                  {t(
                     "Notebook.headers.education_systems",
                     "Системы образования"
                  )}
               </p>
               <p className={styles.delete_list}>
                  {userCopy.instruction_education_systems.map(
                     (item: any, index: number) => (
                        <DeletableInfoBlock
                           deleted={item.deleted}
                           key={item.id}
                           onClick={deleteSystem}
                           text={item.name}
                           entity_id={item.id}
                        />
                     )
                  )}
               </p>
            </div>
            <div className={styles.second_item}>
               <p className={styles.header_title}>
                  {t(
                     "Lk.AdminPanel.Table.subjects-to-teach",
                     "Предметы для преподавания"
                  )}
               </p>
               <p className={styles.delete_list}>
                  {userCopy.instruction_subjects.map(
                     (item: any, index: number) => (
                        <DeletableInfoBlock
                           deleted={item.deleted}
                           key={item.id}
                           onClick={deleteSubject}
                           text={item.name}
                           entity_id={item.id}
                        />
                     )
                  )}
               </p>
            </div>
         </div>
         <div className={styles.files}>
            <div className={styles.title}>
               {t("Lk.AdminPanel.Table.files", "Файлы")}
            </div>
            <div className={styles.second_item}>
               <p className={styles.header_title}>
                  {t("Settings.form.student.title", "Анкета студента")}
               </p>
               {inactiveTeacherData.files.filter(
                  (item: any) => item.file_type === "education_document"
               ).length > 0 ? (
                  inactiveTeacherData.files
                     .filter(
                        (item: any) => item.file_type === "education_document"
                     )
                     .map((file: any, index: number) => (
                        <FileDownloader
                           user_id={inactiveTeacherData.id}
                           file={file}
                           className={styles.file}
                        />
                     ))
               ) : (
                  <p className={styles.header_text}>-</p>
               )}
            </div>

            {(inactiveTeacherData.type === "teacher" ||
               inactiveTeacherData.type === "inactive_teacher") && (
               <>
                  <div className={styles.second_item}>
                     <p className={styles.header_title}>
                        Passport / National ID
                     </p>
                     {inactiveTeacherData.files.filter(
                        (item: any) => item.file_type === "passport"
                     ).length > 0 ? (
                        inactiveTeacherData.files
                           .filter((item: any) => item.file_type === "passport")
                           .map((file: any, index: number) => (
                              <FileDownloader
                                 user_id={inactiveTeacherData.id}
                                 file={file}
                                 className={styles.file}
                              />
                           ))
                     ) : (
                        <p className={styles.header_text}>-</p>
                     )}
                  </div>
                  <div className={styles.second_item}>
                     <p className={styles.header_title}>
                        School Transcript (Grades)
                     </p>
                     {inactiveTeacherData.files.filter(
                        (item: any) => item.file_type === "school_transcript"
                     ).length > 0 ? (
                        inactiveTeacherData.files
                           .filter(
                              (item: any) =>
                                 item.file_type === "school_transcript"
                           )
                           .map((file: any, index: number) => (
                              <FileDownloader
                                 user_id={inactiveTeacherData.id}
                                 file={file}
                                 className={styles.file}
                              />
                           ))
                     ) : (
                        <p className={styles.header_text}>-</p>
                     )}
                  </div>
               </>
            )}
         </div>
         <div className={styles.buttons}>
            <div className={styles.buttons_left}>
               <Button
                  loading={commonState.loading}
                  onClick={confirmApplication}
               >
                  {t("Lk.lesson-service.invite.accept", "Подтвердить заявку")}
               </Button>
               <Button
                  loading={commonState.loading}
                  onClick={rejectApplication}
                  variant={"red"}
               >
                  {t("Lk.lesson-service.invite.cancel", "Отклонить заявку")}
               </Button>
            </div>
            <div className={styles.buttons_right}>
               {myUser.role === "superadmin" && (
                  <>
                     {inactiveTeacherData.role === "user" && (
                        <Button onClick={userToAdmin} variant={"decision"}>
                           <Agree style={{ marginRight: "9px" }} />
                           {t("Buttons.make-admin", "Сделать Администратором")}
                        </Button>
                     )}
                     {inactiveTeacherData.role === "admin" && (
                        <Button variant={"outline"} onClick={adminToUser}>
                           <ErrorIcon
                              style={{ height: "20px", width: "20px" }}
                           />
                           {t("Buttons.unmake-admin", "Убрать роль админа")}
                        </Button>
                     )}
                  </>
               )}
               <Button onClick={blockUser} variant={"decision"}>
                  <Block style={{ marginRight: "9px" }} />
                  {t("Buttons.block-user", "Заблокировать пользователя")}
               </Button>
            </div>
         </div>
      </div>
   );
};

type DeletableInfoBlockType = {
   entity_id: string;
   text: string;
   onClick: (e: any) => void;
   deleted?: boolean;
};
const DeletableInfoBlock: FC<DeletableInfoBlockType> = (props) => {
   return (
      <div
         className={classNames(styles.deletable_block, {
            [`${styles[`deletable_block__delete--deleted`]}`]: props.deleted,
         })}
      >
         {props.text}{" "}
         <div
            data-id={props.entity_id}
            className={styles.deletable_block__delete}
            onClick={props.onClick}
         >
            {props.deleted ? (
               <Icons.buttons.UndoIcon />
            ) : (
               <Icons.buttons.DeleteIcon />
            )}
         </div>
      </div>
   );
};
