import React, { FC, useEffect, useState } from "react";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { userApi } from "api/userApi/methods";
import { routes } from "routes";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { notificationSlice } from "store/notificationSlice";

import {
   notification_typeType,
   notificationPropsType,
} from "components/Lk/components/Notifications/types";
import { Preloader } from "components/UI/Preloader";
import { lkSliceSelectors } from "store/lkSlice";

type propsType = {
   notification: notificationPropsType;
   isPush: boolean;
   entity_id?: number;
};

const resolveChatRoute = (notification: notificationPropsType) => {
   switch (notification.notification_type) {
      case "create_admin_chat":
         return routes.lk.messages + "/help/" + notification.entity_id;
      case "create_abuse_message":
      case "create_abuse_vote":
         return routes.lk.messages + "/help/" + notification.related_entity_id;
      default:
         return "";
   }
};

const resolveUserRoute = (
   notification: notificationPropsType,
   user_id: string
) => {
   switch (notification.notification_type) {
      case "reject_participation":
      case "role_admin_to_teacher":
      case "role_inactive_to_student":
      case "role_teacher_to_admin":
         return routes.lk.root;
      case "role_inactive_to_teacher":
         return routes.lk.lkTeacher + "/" + user_id;
      case "role_student_to_inactive":
         return (
            routes.lk.admin.activation + `/${notification.related_entity.id}`
         );
      default:
         return "";
   }
};

const resolveInviteRoute = (notification: notificationPropsType) => {
   switch (notification.notification_type) {
      case "create":
      case "reject":
         return routes.lk.lesson.invite.view + "/" + notification.entity_id;
      case "approve":
         return (
            routes.lk.lesson.default.view + "/" + notification.entity.lesson_id
         );
      default:
         return "";
   }
};

const entityTypeRouteResolver = (
   notification: notificationPropsType,
   user_id: string
) => {
   return {
      lesson: routes.lk.lesson.default.view + "/" + notification.entity.id,
      invite: resolveInviteRoute(notification),
      group_lesson: routes.lk.lesson.group.view + "/" + notification.entity.id,
      suggestion: "",
      user: resolveUserRoute(notification, user_id),
      chat: resolveChatRoute(notification),
      message: resolveChatRoute(notification),
   }[notification.entity_type];
};

export const NotificationCardButtons: FC<propsType> = (props) => {
   const { notification, isPush, entity_id } = props;
   const user = useSelector(lkSliceSelectors.getUserData);
   const [route, setRoute] = useState<string>("");

   useEffect(() => {
      if (notification) {
         setRoute(entityTypeRouteResolver(notification, user.id));
      }
   }, [notification.entity_type]);

   const { t } = useTranslation();

   const history = useHistory();
   const dispatch = useDispatch();

   const [isLoading, setIsLoading] = useState<boolean>(false);

   function toOpenNotification() {
      history.push(route);
      !isPush && !isLoading && hideNotification();
   }

   function hideNotification() {
      setIsLoading(true);
      userApi
         .changeNotificationStatus({
            notification_ids: [notification.id],
         })
         .then((response) => {
            dispatch(
               notificationSlice.actions.setStatusToReadNotification(
                  notification.id
               )
            );
            setIsLoading(false);
         });
   }

   return (
      <div className={styles.container}>
         {route && (
            <button onClick={toOpenNotification} className={styles.button}>
               {t("Lk.Notifications.buttons.more", "Подробнее")}
            </button>
         )}
         {!isPush &&
            (isLoading ? (
               <Preloader
                  style={{ height: "14px", width: "14px" }}
                  loading={isLoading}
               />
            ) : (
               <button onClick={hideNotification} className={styles.button}>
                  {t("Lk.Notifications.buttons.hide", "Скрыть")}
               </button>
            ))}
      </div>
   );
};
