import React, { FC, useEffect, useState } from "react";
import styles from "components/Lk/Pages/SchedulePage/Schedule/ScheduleCalendar/ScheduleDays/index.module.css";
import { useSelector } from "react-redux";
import { scheduleSliceSelectors } from "store/scheduleSlice";
import cn from "classnames";
import {
   daysInMonth,
   generateArea,
   getFirstDayWeekIndex,
   offsetMonth,
} from "helpers/schedule";

type propsType = {
   type: string;
};

export const ScheduleDays: FC<propsType> = (props) => {
   /* State */
   const calendarDate = useSelector(scheduleSliceSelectors.getDate);
   const [prevMonth, setPrevMonth] = useState<Date>(
      offsetMonth(calendarDate, -1)
   );
   const currentDays = daysInMonth(calendarDate);
   const firstDayWeekIndex = getFirstDayWeekIndex(calendarDate);
   const daysPrevMonth = daysInMonth(prevMonth);

   /* Methods */
   const refreshPrevMonth = () => {
      setPrevMonth(offsetMonth(calendarDate, -1));
   };
   /* Effects */
   useEffect(() => {
      refreshPrevMonth();
   }, [calendarDate]);

   //requests

   /* View methods */
   const generateTiles = () => {
      if (!prevMonth || !calendarDate) return <></>;
      return [
         ...generateArea(
            firstDayWeekIndex,
            "prev",
            daysPrevMonth - firstDayWeekIndex,
            props.type
         ),
         ...generateArea(currentDays, "curr", 0, props.type),
         ...generateArea(
            42 - currentDays - firstDayWeekIndex,
            "next",
            0,
            props.type
         ),
      ];
   };
   return (
      <div
         className={cn({
            [styles.container]: true,
            [styles.sidebar]: props.type == "sidebar",
         })}
         key={calendarDate.getMonth()}
      >
         {generateTiles()}
      </div>
   );
};
