// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SliderArrows_container__21nmo {\n    position: relative;\n    width: 150px;\n    z-index: 2;\n    cursor: pointer;\n}\n.SliderArrows_right__1x6wz {\n    margin-left: 50px;\n    transform: rotate(-180deg);\n}\n.SliderArrows_gray__2X79F path {\n    fill: gray;\n    transition: 0.3s;\n}", "",{"version":3,"sources":["webpack://src/components/UI/SliderArrows/index.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,UAAU;IACV,eAAe;AACnB;AACA;IACI,iBAAiB;IACjB,0BAA0B;AAC9B;AACA;IACI,UAAU;IACV,gBAAgB;AACpB","sourcesContent":[".container {\n    position: relative;\n    width: 150px;\n    z-index: 2;\n    cursor: pointer;\n}\n.right {\n    margin-left: 50px;\n    transform: rotate(-180deg);\n}\n.gray path {\n    fill: gray;\n    transition: 0.3s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SliderArrows_container__21nmo",
	"right": "SliderArrows_right__1x6wz",
	"gray": "SliderArrows_gray__2X79F"
};
export default ___CSS_LOADER_EXPORT___;
