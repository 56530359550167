import { slotType } from "types/LessonService/types";
import { getDateWithoutHours } from "helpers/date/ticketsGroud";
import moment from "moment-timezone";

export const divideSlotsByDays = (slots: Array<slotType>) => {
   let transformedSlots: Array<slotType> = [];
   slots.forEach((slot) => {
      const dateFrom = moment(slot.date_from);
      const dateTo = moment(slot.date_to);

      if (dateFrom.format("DD") != dateTo.format("DD")) {
         let tempSlots: Array<slotType> = [
            {
               ...slot,
               date_to: dateFrom.set("hours", 23).set("minutes", 59).format(),
            },
            {
               ...slot,
               date_from: dateTo.set("hours", 0).set("minutes", 0).format(),
            },
         ];
         transformedSlots = [...transformedSlots, ...tempSlots];
         return;
      } else {
         transformedSlots.push(slot);
      }
   });
   return transformedSlots;
};

export const groupSlotsByDate = (slots: Array<slotType>) => {
   if (!slots.length) return [];
   const groups: {
      [key: string]: Array<slotType>;
   } = {};

   /**
    * @Logic
    * Слот может оказаться в разных днях.
    * Делаем проверку и разбиваем на "2" слота.
    */

   let transformedSlots = divideSlotsByDays(slots);
   transformedSlots.forEach((slot) => {
      const tempDate = getDateWithoutHours(slot.date_from);
      if (!groups[tempDate]) {
         groups[tempDate] = [];
      }
      groups[tempDate].push(slot);
   });

   return groups;
};
