// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__3plMv {\n    padding-left: 6px;\n    width: 100%;\n    border-left: 1px solid black;\n}\n\n\n.style_row__3ktaA {\n    margin-bottom: 10px;\n    display: flex;\n    align-items: center;\n}\n.style_icon__3vMeM {\n    margin-right: 10px;\n    width: 16px;\n    height: 16px;\n}\n\n.style_user__r4JM3 {\n    display: flex;\n    align-items: center;\n    margin-bottom: 10px;\n}\n.style_user_avatar__1ms6v {\n    width: 24px;\n    height: 24px;\n    margin-right: 10px;\n}\n\n.style_duration__TiOsb {\n    font-weight: 300;\n    font-size: 12px;\n    margin-left: 8px;\n}\n\n.style_container--white__3x_ZO {\n    border-left: 1px solid white;\n}\n.style_container--white__3x_ZO .style_icon__3vMeM path {\n    fill: white;\n}", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/LkProfile/Cards/components/CardTime/style.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,WAAW;IACX,4BAA4B;AAChC;;;AAGA;IACI,mBAAmB;IACnB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,4BAA4B;AAChC;AACA;IACI,WAAW;AACf","sourcesContent":[".container {\n    padding-left: 6px;\n    width: 100%;\n    border-left: 1px solid black;\n}\n\n\n.row {\n    margin-bottom: 10px;\n    display: flex;\n    align-items: center;\n}\n.icon {\n    margin-right: 10px;\n    width: 16px;\n    height: 16px;\n}\n\n.user {\n    display: flex;\n    align-items: center;\n    margin-bottom: 10px;\n}\n.user_avatar {\n    width: 24px;\n    height: 24px;\n    margin-right: 10px;\n}\n\n.duration {\n    font-weight: 300;\n    font-size: 12px;\n    margin-left: 8px;\n}\n\n.container--white {\n    border-left: 1px solid white;\n}\n.container--white .icon path {\n    fill: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__3plMv",
	"row": "style_row__3ktaA",
	"icon": "style_icon__3vMeM",
	"user": "style_user__r4JM3",
	"user_avatar": "style_user_avatar__1ms6v",
	"duration": "style_duration__TiOsb",
	"container--white": "style_container--white__3x_ZO"
};
export default ___CSS_LOADER_EXPORT___;
