import axios from "axios";
import { lc } from "store/LC";

export const cancelTokenSource = axios.CancelToken.source();

export const mainAxios = axios.create({
   baseURL: process.env.REACT_APP_MAIN_URL,
   headers: {
      "Accept-Language": lc.get("lang") || "en",
   },
});
