import moment from "moment-timezone";

export const ticketsGroupHandler = (tickets: {
   lessons: any[];
   invites: any[];
   groupLessons: any[];
   slots: any[];
}) => {
   const { lessons, invites, groupLessons, slots } = tickets;
   const arrayOfTickets = [...lessons, ...invites, ...groupLessons, ...slots];
   let currentDate = "";
   const groupedTickets: {
      [key: string]: any[];
   } = {};
   arrayOfTickets.map((ticket, index) => {
      currentDate = moment(ticket.date_from).format("DD-MM-YYYY");
      groupedTickets[currentDate] = groupedTickets[currentDate]
         ? [...groupedTickets[currentDate], ticket]
         : [ticket];
   });
   return groupedTickets;
};
