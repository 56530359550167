import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StateType } from "store/index";

type cachedObjectAvatarType = {
   [key: string]: string;
};
type initialStateType = {
   avatars: cachedObjectAvatarType;
};
const initialState: initialStateType = {
   avatars: {},
};

export const cacheSlice = createSlice({
   name: "@cacheSlice",
   initialState,
   reducers: {
      cacheAvatar: (
         state,
         {
            payload,
         }: PayloadAction<{
            user_id: string;
            content: string;
         }>
      ) => {
         state.avatars[payload.user_id] = payload.content;
      },
      deleteCachedAvatar: (
         state,
         {
            payload,
         }: PayloadAction<{
            user_id: string;
         }>
      ) => {
         const clone = { ...state.avatars };
         delete clone[payload.user_id];
         state.avatars = clone;
      },
   },
});

export const cacheSliceSelectors = {
   getAvatars: (state: StateType) => state.cacheSlice.avatars,
};
