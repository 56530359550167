import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import { ScheduleHeader } from "../../Pages/SchedulePage/Schedule/ScheduleHeader";
import { ScheduleCalendar } from "../../Pages/SchedulePage/Schedule/ScheduleCalendar";
import { useDispatch, useSelector } from "react-redux";
import { scheduleSlice, scheduleSliceSelectors } from "store/scheduleSlice";
import { useBlurred } from "hooks/useBlurred";
import { showDateLabel } from "helpers/date/showDate";
import { useTranslation } from "react-i18next";
import { StateType } from "store";
import { authSliceSelectors } from "store/authSlice";
import { scheduleApi } from "store/scheduleSlice/thunk";
import { EventTimeLine } from "../../../UI/EventTimeLine";
import moment from "moment-timezone";
import { ticketTypeResolver } from "helpers/date/ticketsTypeResolver";
import { PopupCalendar } from "components/UI/PopupCalendar";
import { daysInMonth } from "helpers/schedule";

let intervalId: number;
const TIMEOUT = 1000 * 100;

export const ScheduleSidebar: FC = () => {
   const { t } = useTranslation();
   const calendarDate = useSelector(scheduleSliceSelectors.getDate);
   const dispatch = useDispatch();
   const [days, setDays] = useState(daysInMonth(calendarDate));
   const tickets = useSelector(
      (state: StateType) => state.scheduleSlice.ticketsForTimeline
   );
   useEffect(() => {
      setDays(daysInMonth(calendarDate));
   }, [calendarDate]);

   const [isOpen, setIsOpen] = useState<boolean>(false);
   const calendarRef = useRef<HTMLDivElement | null>();
   useBlurred(calendarRef, setIsOpen, isOpen);
   //requests
   const userId = useSelector(authSliceSelectors.getId);
   useEffect(() => {}, []);
   useEffect(() => {
      if (!userId) {
         return;
      }

      dispatch(scheduleApi.getTickets()(userId));
      intervalId = window.setInterval(() => {
         dispatch(scheduleApi.getTickets()(userId));
      }, TIMEOUT);

      return () => {
         window.clearInterval(intervalId);
      };
   }, [userId]);
   // if (!tickets) return null;
   const setToday = () => {
      dispatch(scheduleSlice.actions.setDate(new Date().toString()));
      dispatch(scheduleSlice.actions.setDay(new Date().toString()));
   };
   return (
      <div className={styles.container}>
         {tickets ? (
            <>
               <div className={styles.title}>
                  {t("Lk.ScheduleSidebar.title", "Ваше расписание")}
               </div>
               <div className={styles.calendar}>
                  {isOpen && (
                     <div
                        className={styles.calendar_container}
                        ref={calendarRef as React.Ref<HTMLDivElement>}
                     >
                        {
                           <PopupCalendar
                              date={calendarDate}
                              setIsOpen={setIsOpen}
                           />
                        }
                        {/*<SidebarDateList setIsOpen={setIsOpen} />*/}
                     </div>
                  )}
                  <ScheduleHeader type='sidebar' setIsOpen={setIsOpen} />
                  <ScheduleCalendar type={"sidebar"} />
               </div>
               <button onClick={setToday} className={styles.back_to_day}>
                  {t(
                     "Lk.ScheduleCalendar.back-to-today",
                     "Вернуться к текущей дате"
                  )}
               </button>
               <div className={styles.tickets}>
                  <div className={styles.label}>
                     {showDateLabel(calendarDate)}
                  </div>
                  {tickets[
                     moment(calendarDate).format("DD-MM-YYYY")
                  ]?.hours.map((item) => {
                     return (
                        <>
                           <EventTimeLine hour={item.hour} />
                           <div className={styles.hours_events}>
                              {ticketTypeResolver(item.tickets, userId)}
                           </div>
                        </>
                     );
                  })}
               </div>
            </>
         ) : (
            <div className={styles.loader}>
               <div
                  className={
                     styles.calendar_header_loader + " " + styles.gradient
                  }
               />
               <div
                  className={styles.calendar_loader + " " + styles.gradient}
               />
            </div>
         )}
      </div>
   );
};
