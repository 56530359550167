import React, { FC, useEffect, useState } from "react";
import styles from "./style.module.css";
import cn from "classnames";
import { ReactComponent as DropDownIcon } from "../assets/Dropdown.svg";
import { Button } from "components/UI/Button";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { useQuery } from "hooks/useQuery";
import { formsDataApi } from "api/formsDataApi/methods";
import { languageSelectSerializer } from "serializers/languages";
import { CheckBox } from "components/UI/Input/CheckBox";
import { educationSystemType, levelType } from "store/formsDataSlice/types";
import { subjectType } from "store/types";
import { useTranslation } from "react-i18next";
import { ContentBlockContainer } from "components/UI/ContentBlockContainer";
import { useToggle } from "hooks/useToggle";
import { SettingsDropdown } from "components/Lk/Pages/Settings/Worksheet/components/SettingsDropdown";

type propsType = {};
type formType = {
   languages: Array<string | false>;
   esElementary: Array<string | false>;
   esMiddle: Array<string | false>;
   esHigh: Array<string | false>;
   subjects: Array<string | false>;
} & any;
export const TeacherWorksheet: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const user = useSelector(lkSliceSelectors.getUserData);

   return (
      <SettingsDropdown
         title={t(
            "Registration.TeacherPages.TeacherFirstStage.title",
            "Анкета преподавателя"
         )}
      >
         <main className={styles.main}>
            <ContentBlockContainer title={t("Common.language", "Язык")}>
               {user.instruction_languages.map((item) => (
                  <div className={styles.main__value}>{item.name}</div>
               ))}
            </ContentBlockContainer>
            <ContentBlockContainer
               title={t(
                  "Notebook.headers.education_systems",
                  "Системы образования"
               )}
            >
               {user.instruction_education_systems.map((item) => (
                  <div className={styles.main__value}>{item.name}</div>
               ))}
            </ContentBlockContainer>
            <ContentBlockContainer
               title={t("Lk.LkTeacherOwn.subjects", "Предметы")}
            >
               {user.instruction_subjects.map((item) => (
                  <div className={styles.main__value}>{item.name}</div>
               ))}
            </ContentBlockContainer>
         </main>
      </SettingsDropdown>
   );
};
