import React, { FC } from "react";
import "./style.css";
import { RegistrationWrapper } from "../RegistrationWrapper";
import { Route } from "react-router-dom";
import { routes } from "routes";
import { StudentRegistration } from "./StudentRegistration";
import { FillInformation } from "./FillInformation";
import { RegistrationBackground } from "../RegistrationBackground";

type propsType = {};
export const RegistrationStudent: FC<propsType> = (props) => {
   return (
      <div className='RegistrationStudent'>
         <RegistrationWrapper>
            <Route exact path={routes.registration.student.fillInformation}>
               <FillInformation />
            </Route>
            <Route exact path={routes.registration.student.studentRegistration}>
               <StudentRegistration />
            </Route>
         </RegistrationWrapper>
         <Route
            path={[
               routes.registration.auth.firstStage,
               routes.registration.auth.authorization,
               routes.registration.auth.forgotPassword,
               routes.registration.auth.restorePassword,
            ]}
         >
            <RegistrationBackground type={"authorization"} />
         </Route>
         <Route
            path={[
               routes.registration.student.studentRegistration,
               routes.registration.auth.registrationCode,
               routes.registration.auth.createPassword,
               routes.registration.student.fillInformation,
            ]}
         >
            <RegistrationBackground type={"student"} />
         </Route>
      </div>
   );
};
