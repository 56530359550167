// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotificationCardWrapper_container__1RKB3 {\n    width: 247px;\n    z-index: 200;\n    border-radius: 10px;\n    padding: 7px 8px;\n    margin-top: 10px;\n}", "",{"version":3,"sources":["webpack://src/components/Lk/components/Notifications/components/NotificationCardWrapper/index.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".container {\n    width: 247px;\n    z-index: 200;\n    border-radius: 10px;\n    padding: 7px 8px;\n    margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "NotificationCardWrapper_container__1RKB3"
};
export default ___CSS_LOADER_EXPORT___;
