import { lkApi } from "api/LkApi/methods";
import { useQuery } from "react-query";

export const useGetPrice = () => {
    const { isLoading, data, error } = useQuery(
        "useGetPrice",
        () => lkApi.getPrice(),
        {
            // cacheTime: 10000,
        }
    );

    return {
        data: data?.data?.data,
        isLoading,
        error,
    };
}
