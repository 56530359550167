import React, { FC, ReactNode, useEffect, useState } from "react";
import "./style.css";
import cn from "classnames";
import { ListButton } from "../ListButton";
import { ticketsGroupByDay } from "helpers/date/ticketsGroud";
import { StudentCard } from "../../Lk/Pages/LkProfile/Cards/StudentCard";
import { TeacherCard } from "../../Lk/Pages/LkProfile/Cards/TeacherCard";
import { useTranslation } from "react-i18next";
import { GuestSlotCard } from "components/Lk/Pages/LkProfile/Cards/GuestSlotCard";
import { TeacherCardSlot } from "components/Lk/Pages/LkProfile/Cards/TeacherCardSlot";
import moment from "moment-timezone";
import styles from "./index.module.css";
import { Icons } from "assets/iconsCorrect";
import { Button } from "components/UI/Button";

type propsType = {
   title: string;
   elements?: Array<any>;
   component?: React.FC<any>;
   isLoading?: boolean;
   role: "student" | "teacher";
   showMore?: () => void;
   showMoreVisible?: boolean;
};

//@TODO в компоненту
const CardsTimeGroup: FC<{ date?: string; elements: Array<ReactNode> }> = (
   props
) => {
   const momentTime = moment(props.date);
   const [currentSlide, setCurrentSlide] = useState(0);
   if (props.elements == undefined) return null;

   const nextSlideHandler = () => {
      setCurrentSlide(
         currentSlide >= props.elements.length - 1 ? 0 : currentSlide + 1
      );
   };
   const prevSlideHandler = () => {
      setCurrentSlide(currentSlide == 0 ? 0 : currentSlide - 1);
   };

   return (
      <div className={styles.time_group}>
         <h5 className={styles.time_group__time}>
            <b>
               {momentTime.date()}, {momentTime.format("MMMM")},{" "}
               {momentTime.format("YYYY")}
            </b>
            {", "}
            {momentTime.format("dddd")}
         </h5>

         <div className={styles.time_group__cards}>
            {currentSlide != 0 && (
               <div
                  className={cn(
                     styles.time_group__arrow_wrapper,
                     styles["time_group__arrow_wrapper--prev"]
                  )}
                  onClick={prevSlideHandler}
               >
                  <Icons.buttons.DownArrowSelect
                     className={styles.time_group__arrow}
                     style={{ transform: "rotateZ(90deg)" }}
                  />
               </div>
            )}
            {props.elements.map((item, index) => (
               <div
                  key={index}
                  style={{
                     transform: `translateX(calc(-${currentSlide} * 100% - 20px * ${currentSlide} + ${
                        currentSlide != 0 ? 40 : 0
                     }px))`,
                     transition: ".3s",
                  }}
               >
                  {item}
               </div>
            ))}
            {props.elements.length > 1 && (
               <div
                  className={styles.time_group__arrow_wrapper}
                  onClick={nextSlideHandler}
               >
                  <Icons.buttons.DownArrowSelect
                     className={styles.time_group__arrow}
                  />
               </div>
            )}
         </div>
      </div>
   );
};

export const CardsGroup: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const [isOpen, setIsOpen] = useState(false);
   const [cards, setCards] = useState<Array<{ date: string; elements: any[] }>>(
      []
   );

   useEffect(() => {
      if (props.elements?.length) {
         setCards(ticketsGroupByDay(props.elements));
      }
   }, [props.elements]);

   const resolveCard = (item: any) => {
      if (item.type == "slot") {
         return React.createElement(TeacherCardSlot, item);
      } else if (item.type == "free") {
         return React.createElement(GuestSlotCard, item);
      } else if (props.role == "student") {
         return React.createElement(StudentCard, item);
      } else {
         return React.createElement(TeacherCard, item);
      }
   };

   const displayCards = () => {
      return cards.map((item) => (
         <CardsTimeGroup
            elements={item.elements.map((item: any) => {
               return resolveCard(item);
            })}
            date={item.date}
         />
      ));
   };

   const expandHandler = (val: boolean) => {
      if (props.elements?.length) {
         setIsOpen(val);
      }
   };

   return (
      <div
         className={cn({
            CardsGroup: true,
            "CardsGroup--open": isOpen,
         })}
      >
         <ListButton
            setIsOpen={expandHandler}
            isOpen={isOpen}
            title={props.title}
            counter={props.elements?.length}
            isLoading={props.isLoading}
         />

         {isOpen && displayCards()}
         {props.showMore && props.showMoreVisible && isOpen && (
            <Button
               // loading={slotsFetching}
               className={styles.slots_show_more}
               onClick={props.showMore}
            >
               {t("SearchFilters.teacher-filters.show-more", "Показать ещё")}
            </Button>
         )}
         {isOpen && (
            <button
               className='CardsGroup__rollup'
               onClick={() => expandHandler(false)}
            >
               {t("CardsGroup.rollup", t("Buttons.collapse", "Свернуть"))}
            </button>
         )}
      </div>
   );
};
