import React, { FC, useEffect, useState } from "react";
import styles from "./style.module.css";
import { useGetUserAvatar } from "hooks/useGetUserAvatar";

type propsType = {
   user: {
      id: string;
      first_name: string;
      last_name: string;
   };
};
export const NotificationCardUser: FC<propsType> = (props) => {
   const { user } = props;

   const [avatar] = useGetUserAvatar(user?.id);
   return (
      <div className={styles.container}>
         <div className={styles.avatar}>
            <img
               className={styles.avatar_photo}
               src={avatar}
               alt={user ? user.first_name : "avatar"}
            />
         </div>
         <div>
            <div className={styles.name}>{user ? user.first_name : "  "}</div>
            <div className={styles.name}>{user ? user.last_name : "  "}</div>
         </div>
      </div>
   );
};
