import * as React from "react";

export function RadioOn(props: React.SVGProps<SVGSVGElement>) {
   return (
      <svg
         width={20}
         height={20}
         viewBox='0 0 20 20'
         fill='none'
         xmlns='http://www.w3.org/2000/svg'
         {...props}
      >
         <rect
            x={0.5}
            y={0.5}
            width={19}
            height={19}
            rx={9.5}
            stroke='#2D49FE'
         />
         <rect x={4} y={4} width={12} height={12} rx={6} fill='#2D49FE' />
      </svg>
   );
}
