// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewFile_container__1UvEO {\n    display: flex;\n    align-items: center;\n\n    margin-bottom: 5px;\n}\n\n.NewFile_icon__cqFLj {\n\n}\n.NewFile_name__2sy62 {\n    font-family: Source Sans Pro;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    line-height: 20px;\n\n    color: var(--color-primary);\n\n    margin-left: 8px;\n    max-width: 100px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n\n.NewFile_close__1Ne8s {\n    border: none;\n    outline: none;\n    margin-left: 22px;\n}", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/Messages/components/MessagesArea/components/NewFile/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;;IAEnB,kBAAkB;AACtB;;AAEA;;AAEA;AACA;IACI,4BAA4B;IAC5B,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;;IAEjB,2BAA2B;;IAE3B,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,iBAAiB;AACrB","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n\n    margin-bottom: 5px;\n}\n\n.icon {\n\n}\n.name {\n    font-family: Source Sans Pro;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    line-height: 20px;\n\n    color: var(--color-primary);\n\n    margin-left: 8px;\n    max-width: 100px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n\n.close {\n    border: none;\n    outline: none;\n    margin-left: 22px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "NewFile_container__1UvEO",
	"icon": "NewFile_icon__cqFLj",
	"name": "NewFile_name__2sy62",
	"close": "NewFile_close__1Ne8s"
};
export default ___CSS_LOADER_EXPORT___;
