import { thunkType } from "core/LTools/core/types/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { LTools } from "core/LTools/constructor";
import { chatApi } from "api/chatApi/methods";
import { createChatType, createNewMessage } from "api/chatApi/types";
import { chatSlice } from "store/chatSlice/index";
import { routes } from "routes";
import { errorCaseHandler } from "store/lessonSlice/thunk";
import { getFirstErrorFromResponse } from "helpers/errors";
import { log } from "util";

export const ChatThunk = {
   createChat: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, { needSetLocation?: boolean } & createChatType>) {
      console.log(typeof props.admin_chat);
      return createAsyncThunk("getLesson", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () =>
               chatApi.createChat({
                  admin_chat: props.admin_chat,
                  users: props.users,
               }),
            responseReactionCreator: (response) => ({
               "201": () => {
                  const { needSetLocation = false } = props;
                  if (needSetLocation) {
                     commonState.location =
                        routes.lk.messages +
                        (props.admin_chat ? "/help/" : "/default/") +
                        response.data.data.id;
                  }
               },
               400: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response),
                     "close"
                  );
               },
               404: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response),
                     "close"
                  );
               },
            }),
         });

         return response as any;
      })();
   },
   getDialogs: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, { typeChat: boolean; userIsAdmin: boolean }>) {
      return createAsyncThunk("getLesson", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () =>
               chatApi.getDialogs({
                  is_admin_chat: props.userIsAdmin ? props.typeChat : undefined,
                  limit: "100",
                  offset: "0",
               }),
            responseReactionCreator: (response) => ({
               "200": () => {
                  !props.typeChat &&
                     commonState.dispatch(
                        chatSlice.actions.updateDialogs(
                           response.data.data.items
                        )
                     );
                  props.typeChat &&
                     commonState.dispatch(
                        chatSlice.actions.updateAdminsDialogs(
                           response.data.data.items
                        )
                     );
               },
            }),
         });

         return response as any;
      })();
   },
   getMessageByChatId: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, { chat_id: number; limit: string; offset: string }>) {
      return createAsyncThunk("getLesson", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () =>
               chatApi.getMessageByChatId(
                  props.chat_id,
                  props.limit,
                  props.offset
               ),
            responseReactionCreator: (response) => ({
               "200": () => {
                  commonState.dispatch(
                     chatSlice.actions.updateChatMessagesById({
                        id: props.chat_id,
                        messages: response.data.data.items.reverse(),
                        messages_count: response.data.data.items_count,
                        type: props.offset === "0" ? "new" : "old",
                     })
                  );
               },
            }),
         });

         return response as any;
      })();
   },
   creatNewMessage: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, createNewMessage & { needUpdateChat?: boolean }>) {
      return createAsyncThunk("creatNewMessage", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () =>
               chatApi.alertNewMessageForChatId({
                  message: props.message,
                  chat_id: props.chat_id,
               }),
            responseReactionCreator: (response) => ({
               "201": () => {
                  commonState.dispatch(
                     chatSlice.actions.updateChatMessagesById({
                        id: props.chat_id,
                        messages: [response.data.data],
                        type: "new",
                     })
                  );
               },
               400: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response),
                     "close"
                  );
               },
               404: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response),
                     "close"
                  );
               },
            }),
         });

         return response as any;
      })();
   },
   sendChatToAnotherAdmin: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, { chat_id: number; admin_id: string }>) {
      return createAsyncThunk("getLesson", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () =>
               chatApi.sendChatToAnotherAdmin(props.chat_id, props.admin_id),
            responseReactionCreator: (response) => ({
               "201": () => {},
            }),
         });

         return response as any;
      })();
   },
};
