import { useEffect, useState } from "react";

export const useTimer = (): Array<number> => {
   const [timer, setTimer] = useState<Array<number>>([0, 0]);

   const updateTimer = () => {
      setTimer((prev) => {
         let newTime = [...prev];

         if (newTime[1] == 59) {
            newTime[0] += 1;
            newTime[1] = 0;
         } else {
            newTime[1] += 1;
         }
         return newTime;
      });
   };

   useEffect(() => {
      let intervalId = window.setInterval(() => {
         updateTimer();
      }, 1000);

      return () => window.clearInterval(intervalId);
   }, []);

   return timer;
};
