import React, { FC, useEffect } from "react";
import { ReactComponent as StartIcon } from "assets/icons/StarRating.svg";
import { ReactComponent as SendMessageIcon } from "./assets/Message.svg";
import { Switcher } from "components/UI/Switcher";
import { showDate, showDateDMY } from "helpers/date/showDate";
import { firstLetterUpperCase } from "helpers/string/firstLetterUppercase";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { routes } from "routes";
import { useGetUserAvatar } from "hooks/useGetUserAvatar";
import cn from "classnames";
import styles from "./index.module.css";
import { Button } from "components/UI/Button";
import { InfoBlock } from "components/UI/InfoBlock";
import { useLkContext } from "components/Lk/Pages/LkProfile/index";
import { Icons } from "assets/iconsCorrect";
import { languagesTitleResolver } from "staticData/languages";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { ChatThunk } from "store/chatSlice/thunk";
import { useSelector } from "react-redux";
import { lkSlice, lkSliceSelectors } from "store/lkSlice";
import { Preloader } from "components/UI/Preloader";

export const Profile: FC = () => {
   const { t } = useTranslation();
   const {
      template,
      profileData,
      setIsEditing,
      isEditing,
      avatar,
   } = useLkContext();
   const role = template.isExtended ? template.tempRole : "student";
   const { role: clientRole } = useSelector(lkSliceSelectors.getUserData);
   const commonState = useLocalCommonState();
   const ownId = useSelector(lkSliceSelectors.getUserId);

   function openChat() {
      commonState.dispatch(
         ChatThunk.createChat({
            commonState,
            callbacks: {},
            needSetLocation: true,
            admin_chat: false,
            users: [Number(profileData.id), Number(ownId)],
         })
      );
   }

   if (!profileData) return null;

   const resolveRole = () => {
      if (profileData.is_banned) {
         return "—";
      }
      if (profileData.role == "admin") {
         return t("Lk.Profile.role.admin", "Админ");
      }
      if (profileData.role == "superadmin" && profileData.type === "student") {
         return t("Lk.LkProfile.RoleSwitcher.student-button", "Студент");
      }
      if (profileData.role == "superadmin" && profileData.type === "teacher") {
         return t("Lk.LkProfile.RoleSwitcher.teacher-button", "Преподаватель");
      }
      if (profileData.type == "teacher" && !profileData.is_blocked) {
         return t("Lk.LkProfile.RoleSwitcher.teacher-button", "Преподаватель");
      }
      return t("Lk.LkProfile.RoleSwitcher.student-button", "Студент");
   };

   const rating =
      template.tempRole == "teacher"
         ? profileData.teaching_rating
         : profileData.study_rating;
   return (
      <div
         className={cn(styles.container, {
            [`${styles[`container--${role}`]}`]: true,
         })}
      >
         <img src={avatar} className={styles.avatar} />
         <main className={styles.info_body}>
            {template.isOwn &&
               !template.isExtended &&
               profileData.type == "student" && (
                  <div className={styles.button_to_teacher_form}>
                     <Icons.avatars.TeacherAvatar
                        style={{
                           marginRight: 10,
                        }}
                     />
                     <Link to={routes.registration.teacher.firstStage}>
                        <Button variant='link'>
                           {t("Lk.Profile.get-role", "Хотите преподавать?")}
                        </Button>
                     </Link>
                  </div>
               )}

            <div className={styles.fio}>
               <p>{profileData?.first_name}</p>
               <p>{profileData?.last_name}</p>
            </div>

            <div className={styles.rating_and_status}>
               {/*@TODO выести рейтинг в компоненту*/}
               <div className={styles.rating}>
                  {React.createElement(StartIcon)}
                  <p>{rating ? rating.toFixed(2) : "—"}</p>
               </div>
               <div className={styles.status}>
                  <p>{resolveRole()}</p>
               </div>
            </div>

            {!template.isOwn && (
               <button onClick={openChat} className={styles.send_message}>
                  {React.createElement(SendMessageIcon, {
                     style: { marginRight: 10 },
                  })}
                  {commonState.loading ? (
                     <Preloader loading={true} variant={"small"} />
                  ) : (
                     t("Lk.Profile.send-message", "Написать сообщение")
                  )}
               </button>
            )}
            {!template.isOwn &&
               (clientRole == "admin" || clientRole == "superadmin") && (
                  <button
                     onClick={() => setIsEditing(!isEditing)}
                     className={styles.send_message}
                  >
                     {React.createElement(Icons.buttons.CircleEditIcon, {
                        style: { marginRight: 10 },
                     })}
                     {t("Lk.Profile.edit", "Редактировать")}
                  </button>
               )}
            {!template.isOwn && (clientRole == "superadmin" || clientRole == "admin") && (
               <InfoBlock
                  className={styles.birth}
                  infoKey={t("Lk.Profile.birth-date", "Дата рождения")}
               >
                  {showDateDMY(profileData.birth_date)}
               </InfoBlock>
            )}
            {role == "teacher" && (
               <InfoBlock
                  infoKey={t("Lk.Profile.languages", "Язык преподавания")}
                  className={styles.languages}
               >
                  {profileData.instruction_languages?.map((item) => {
                     return <p>{languagesTitleResolver[item.name]}</p>;
                  })}
               </InfoBlock>
            )}
         </main>
      </div>
   );
};
