import React, { FC, useEffect } from "react";
import styles from "./style.module.css";
import { useQuery } from "hooks/useQuery";
import { groupLessonApi } from "api/groupLessonApi/methods";
import { useParams } from "react-router-dom";
import { LessonBody } from "../../components/LessonBody";
import { useSelector } from "react-redux";
import { authSliceSelectors } from "store/authSlice";
import { GroupLessonButtons } from "./Buttons";
import { GroupLessonHeader } from "./Header";
import { groupLessonType } from "types/LessonService/types";
import { useToggle } from "hooks/useToggle";
import { Preloader } from "components/UI/Preloader";
import { StudentsList } from "components/UI/StudentsList";

export type groupLessonViewVariant =
   | "student_registered"
   | "student_unregistered"
   | "teacher"
   | "teacher_archive"
   | "student_archive";

type propsType = {};
var groupLessonPingId = 0;
export const GroupLesson: FC<propsType> = (props) => {
   const { id: group_lesson_id } = useParams<{ id: string }>();
   const user_id = useSelector(authSliceSelectors.getId);
   const [refetchTrigger, refetch] = useToggle(false);

   const {
      data: groupLessonData,
      isLoading: isGroupLessonLoading,
      fetch,
   } = useQuery(
      () =>
         groupLessonApi.getGroupLessonData({
            group_lesson_id: group_lesson_id,
         }),
      {
         serializer: (res): groupLessonType => {
            return res?.data.data;
         },
         deps: [refetchTrigger, group_lesson_id],
      }
   );

   const defineVariant = (): groupLessonViewVariant => {
      const isArchive = groupLessonData.status == "finished_group_lesson";
      const isTeacher = user_id == String(groupLessonData.teacher.id);
      const isStudentRegistered = groupLessonData.students.find(
         (student) => student.id == user_id
      );

      if (isTeacher) {
         return isArchive ? "teacher_archive" : "teacher";
      }
      if (isStudentRegistered) {
         return isArchive ? "student_archive" : "student_registered";
      }
      return "student_unregistered";
   };

   const getGroupLessonFile = async (file_id: string) => {
      return await groupLessonApi.getGroupLessonFile({
         file_id,
         group_lesson_id,
      });
   };

   /* ping */
   const ping = () => {
      groupLessonPingId = window.setTimeout(() => {
         fetch();
         ping();
      }, 120000);
   };
   useEffect(() => {
      ping();
      return () => window.clearTimeout(groupLessonPingId);
   }, [group_lesson_id]);

   const variant = groupLessonData && groupLessonData.status && defineVariant();
   return isGroupLessonLoading ? (
      <Preloader loading={isGroupLessonLoading} />
   ) : (
      !!groupLessonData && (
         <div className={styles.container}>
            <GroupLessonHeader
               variant={variant}
               groupLesson={groupLessonData}
            />
            <main className='Lessons__main'>
               {groupLessonData && (
                  <LessonBody
                     subject={groupLessonData.subject}
                     date_from={groupLessonData.date_from}
                     date_to={groupLessonData.date_to}
                     topic={groupLessonData.topic}
                     zoomLink={groupLessonData.link}
                     language={groupLessonData.language}
                     files={groupLessonData.files}
                     getFileMethod={getGroupLessonFile}
                     meetingRecordUrl={groupLessonData.meeting_record_url}
                     lesson_id={group_lesson_id}
                  />
               )}
               <GroupLessonButtons
                  groupLessonData={groupLessonData}
                  variant={variant}
                  refetch={refetch}
                  group_lesson_id={groupLessonData.id}
               />
            </main>
         </div>
      )
   );
};
