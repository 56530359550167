import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { ChatThunk } from "store/chatSlice/thunk";
import { Icons } from "assets/iconsCorrect";
import { Preloader } from "components/UI/Preloader";
import { routes } from "routes";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";

type propsType = {
   user_id: string;
};
export const CreateChatButton: FC<propsType> = (props) => {
   const commonState = useLocalCommonState();
   const clientId = useSelector(lkSliceSelectors.getUserId);

   const createChat = () => {
      commonState.dispatch(
         ChatThunk.createChat({
            commonState,
            users: [Number(clientId), Number(props.user_id)],
            admin_chat: false,
            callbacks: {
               200: (responseChat) => {
                  const chat = responseChat.data.data;
                  commonState.location =
                     routes.lk.messages + "/default/" + chat.id;
               },
               201: (responseChat) => {
                  const chat = responseChat.data.data;
                  commonState.location =
                     routes.lk.messages + "/default/" + chat.id;
               },
            },
         })
      );
   };

   return (
      <div>
         {commonState.loading ? (
            <Preloader loading={true} variant='small' />
         ) : (
            <Icons.ui.messageIcons
               style={{
                  cursor: "pointer",
               }}
               onClick={createChat}
            />
         )}
      </div>
   );
};
