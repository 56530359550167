import i18n from "services/translateService/i18n";
import { addMethodToUpdateLang } from "services/translateService/useLangInit";

export let filterCheckboxesData: {
   student: Array<{
      label: string;
      name: string;
   }>;
   teacher: Array<{
      label: string;
      name: string;
   }>;
};

addMethodToUpdateLang(() => {
   filterCheckboxesData = {
      student: [
         {
            label: i18n.t(
               "Lk.ScheduleFilter.filter.student-unproved",
               "Неподтвержденные занятия"
            ),
            name: "student_unproved",
         },
         {
            label: i18n.t(
               "Lk.ScheduleFilter.filter.student-proved",
               "Подтвержденные занятия"
            ),
            name: "student_proved",
         },
         {
            label: i18n.t(
               "Lk.ScheduleFilter.filter.student-open-lesson",
               "Открытый урок"
            ),
            name: "student_open",
         },
      ],
      teacher: [
         {
            label: i18n.t(
               "Lk.ScheduleFilter.filter.teacher-time",
               "Время для занятий"
            ),
            name: "slot",
         },
         {
            label: i18n.t(
               "Lk.ScheduleFilter.filter.teacher-unproved",
               "Неподтвержденные занятия"
            ),
            name: "teacher_unproved",
         },
         {
            label: i18n.t(
               "Lk.ScheduleFilter.filter.teacher-proved",
               "Подтвержденные занятия"
            ),
            name: "teacher_proved",
         },
         {
            label: i18n.t(
               "Lk.ScheduleFilter.filter.teacher-open-lesson",
               "Открытый урок"
            ),
            name: "teacher_open",
         },
      ],
   };
});
