import Saeed from "../../../../assets/landing/reviews/Saeed.png";
import Cesar from "../../../../assets/landing/reviews/Cesar.png";
import Girl from "../../../../assets/landing/reviews/Girl.png";
import Alexander from "../../../../assets/landing/reviews/Alexander.png";
import { addMethodToUpdateLang } from "services/translateService/useLangInit";
import i18n from "services/translateService/i18n";

type _type = {
   image: string;
   name: string;
   country: string;
   review: string;
};
export let ReviewsData: Array<_type>;

addMethodToUpdateLang(() => {
   ReviewsData = [
      {
         image: Saeed,
         name: "Saeed Anwar",
         country: "Saudi Arabia",
         review: i18n.t(
            "Landings.Main.Reviews.1",
            "Это прекрасная возможность познакомиться с новыми людьми: учиться и общаться"
         ),
      },
      {
         image: Cesar,
         name: "César Martinez",
         country: "Mexico",
         review: i18n.t(
            "Landings.Main.Reviews.2",
            "E-Tutor International - это уникальный опыт поддержки студентов во всем мире в эти трудные времена. Я очень благодарен совету директоров и другим преподавателям за возможность"
         ),
      },
      {
         image: Girl,
         name: "Berta Perez Pons",
         country: "Spain",
         review: i18n.t(
            "Landings.Main.Reviews.3",
            "E-Tutor International дал мне возможность поделиться своими знаниями, обучаясь у разных людей со всего мира."
         ),
      },
      {
         image: Alexander,
         name: "TaeHwan Alexander Kim",
         country: "Uzbekistan",
         review: i18n.t(
            "Landings.Main.Reviews.4",
            "E-Tutor International предоставил мне возможность расширить мои связи, а также навыки общения и подготовки"
         ),
      },
   ];
});
