import React, { FC } from "react";
import styles from "./index.module.css";

type reviewType = {
   image: string;
   name: string;
   country: string;
   review: string;
};

type propsType = {
   id: number;
   currentSlide: number;
   setCurrentSlide: (val: number) => void;
   translate: number;
   review: reviewType;
};
export const ReviewItem: FC<propsType> = (props) => {
   const {
      setCurrentSlide,
      currentSlide,
      translate,
      id,
      children,
      review,
   } = props;
   return (
      <div
         className={styles.container + " " + styles.grid}
         style={{
            transform: `translateX(calc(-${props.currentSlide * 100}%  - ${
               110 * currentSlide
            }px)) `,
         }}
      >
         <div className={styles.image}>
            <img src={review.image} />
         </div>
         <div className={styles.right}>
            <div className={styles.name}>
               <p>{review.name}</p>
            </div>
            <div className={styles.country}>
               <p>{review.country}</p>
            </div>
         </div>

         <div className={styles.review}>
            <p>{review.review}</p>
         </div>
      </div>
   );
};
