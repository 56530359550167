import { AxiosResponse } from "axios";

export const getFirstErrorFromResponse = (res: AxiosResponse<any>) => {
   try {
      const errorMessage = res?.data?.data?.errors[0]?.message;
      if (!errorMessage) {
         return "Error";
      }
      const indexOfDots = errorMessage.indexOf(":");
      return errorMessage.substr(indexOfDots + 1);
   } catch {
      return "Error";
   }
};
