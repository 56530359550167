import { routes } from "routes";
import { addMethodToUpdateLang } from "services/translateService/useLangInit";
import i18n from "i18next";

export let headerTitlesData: Array<{
   route: string;
   strict?: boolean;
   title: string;
}>;

addMethodToUpdateLang(() => {
   headerTitlesData = [
      {
         route: routes.lk.profile,
         title: i18n.t("Menu.teacherButtons.profile", "Профиль"),
      },
      {
         route: routes.lk.schedule,
         title: i18n.t("Menu.teacherButtons.schedule", "Расписание"),
      },
      {
         route: routes.lk.settings.root,
         title: i18n.t("Lk.Header.settings", "Настройки"),
      },
      {
         route: routes.lk.statistic.root,
         title: i18n.t("Menu.teacherButtons.statistic", "Статистика"),
      },
      {
         route: routes.lk.notebook.root,
         strict: true,
         title: i18n.t("Notebook.title", "Записная книжка"),
      },
      {
         route: routes.lk.messages + "/help",
         strict: true,
         title: i18n.t("Menu.teacherButtons.messages", "Сообщения"),
      },
      {
         route: routes.lk.messages + "/default",
         strict: true,
         title: i18n.t("Menu.teacherButtons.messages", "Сообщения"),
      },
      {
         route: routes.lk.lesson.sendRequest,
         title: i18n.t("Lk.SendRequest.title", "Запрос занятия"),
      },
      {
         route: routes.lk.lesson.create_slot,
         title: i18n.t(
            "Lk.lesson-service.add-lesson.title",
            "Добавить занятие"
         ),
      },
   ];
});
