import { addMethodToUpdateLang } from "services/translateService/useLangInit";
import i18n from "services/translateService/i18n";
import * as yup from "yup";

export let SendRequestFormScheme: any;
export let SendFreeRequestFormScheme: any;

addMethodToUpdateLang(() => {
   SendRequestFormScheme = yup.object().shape({
      group: yup
         .object()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         ),
      hours: yup
         .object()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         ),
      duration: yup
         .object()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         ),
      language: yup
         .object()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         ),
      subject: yup
         .object()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         ),
      topic: yup
         .string()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         ),
      card: yup
         .object()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         ),
   });
});

addMethodToUpdateLang(() => {
   SendFreeRequestFormScheme = yup.object().shape({
      group: yup
         .object()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         ),
      hours: yup
         .object()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         ),
      duration: yup
         .object()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         ),
      language: yup
         .object()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         ),
      subject: yup
         .object()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         ),
      topic: yup
         .string()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         ),
   });
});
