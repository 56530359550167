import React, { FC } from "react";
import styles from "./index.module.css";
import { ReactComponent as Close } from "./assets/close.svg";
import { ReactComponent as File } from "./assets/file.svg";

type PropsType = {
   id: number;
   fileName: string;
   onDelete: (id: number) => void;
};

export const NewFile: FC<PropsType> = (props) => {
   const { id, fileName, onDelete } = props;

   function deleteFile() {
      onDelete(id);
   }

   return (
      <div className={styles.container}>
         <File className={styles.icon} />
         <p className={styles.name}>{fileName}</p>
         <button className={styles.close} onClick={deleteFile}>
            <Close />
         </button>
      </div>
   );
};
