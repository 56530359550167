import React, {AllHTMLAttributes, FC, forwardRef} from "react";
import styles from "./Rates.module.css";
import {Control, Controller, UseFormRegisterReturn} from "react-hook-form";

type RatesType = {
    name?: string;
    value?: number;
    control: Control<any>;
    readOnly? : boolean | undefined
} & AllHTMLAttributes<HTMLInputElement>;

export const Rates: FC<RatesType> = (props) => {
    const {value, id = "rate"  , name = "rate", control , readOnly = false,...other} = props;
    return (
        <div className={styles.Rates}>

            {/**
                5
            */}
            <input
                readOnly={value ? true : false}
                defaultChecked={value === 5 ? true : false}
                type="radio"
                id={`${id}` + 5}
                value={5}
                {...props.control.register(name)}
                {...other}
            />


            <label data-class="total" className={styles.rating} htmlFor={!readOnly ? `${id}` + 5 : ""}>
                <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M25.9627 9.99788C25.8731 9.70876 25.6348 9.49806 25.3479 9.45441L17.2877 8.22657L13.683 0.570089C13.5548 0.297531 13.2899 0.125 13 0.125C12.7101 0.125 12.4453 0.297531 12.317 0.570089L8.71214 8.22657L0.652174 9.45441C0.365312 9.49806 0.126895 9.70876 0.0373174 9.99782C-0.0523112 10.2869 0.0224385 10.6043 0.230082 10.8164L6.06218 16.7762L4.68561 25.1915C4.63656 25.4911 4.75406 25.7938 4.98862 25.9725C5.12131 26.0736 5.27848 26.125 5.43641 26.125C5.55767 26.125 5.67934 26.0947 5.79076 26.0333L13 22.06L20.2089 26.0332C20.4656 26.1747 20.7765 26.1511 21.0111 25.9725C21.2457 25.7938 21.3632 25.491 21.3142 25.1914L19.9372 16.7762L25.7699 10.8163C25.9775 10.6043 26.0523 10.2869 25.9627 9.99788Z" fill="#B3B3B3"/>
                </svg>

            </label>



            {/**
             4
             */}

            <input
                readOnly={value ? true : false}
                defaultChecked={value === 4 ? true : false}
                type="radio"
                {...props.control.register(name)}
                id={`${id}` + 4}
                value={4}
                {...other}
            />


            <label data-class="total" className={styles.rating} htmlFor={!readOnly ? `${id}` + 4 : ""}>
                <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M25.9627 9.99788C25.8731 9.70876 25.6348 9.49806 25.3479 9.45441L17.2877 8.22657L13.683 0.570089C13.5548 0.297531 13.2899 0.125 13 0.125C12.7101 0.125 12.4453 0.297531 12.317 0.570089L8.71214 8.22657L0.652174 9.45441C0.365312 9.49806 0.126895 9.70876 0.0373174 9.99782C-0.0523112 10.2869 0.0224385 10.6043 0.230082 10.8164L6.06218 16.7762L4.68561 25.1915C4.63656 25.4911 4.75406 25.7938 4.98862 25.9725C5.12131 26.0736 5.27848 26.125 5.43641 26.125C5.55767 26.125 5.67934 26.0947 5.79076 26.0333L13 22.06L20.2089 26.0332C20.4656 26.1747 20.7765 26.1511 21.0111 25.9725C21.2457 25.7938 21.3632 25.491 21.3142 25.1914L19.9372 16.7762L25.7699 10.8163C25.9775 10.6043 26.0523 10.2869 25.9627 9.99788Z" fill="#B3B3B3"/>
                </svg>

            </label>



            {/**
             3
             */}

            <input
                readOnly={value ? true : false}
                defaultChecked={value === 3 ? true : false}
                type="radio"
                id={`${id}` + 3}
                {...props.control.register(name)}
                value={3}
                {...other}
            />


            <label data-class="total" className={styles.rating} htmlFor={!readOnly ? `${id}` + 3 : ""}>
                <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M25.9627 9.99788C25.8731 9.70876 25.6348 9.49806 25.3479 9.45441L17.2877 8.22657L13.683 0.570089C13.5548 0.297531 13.2899 0.125 13 0.125C12.7101 0.125 12.4453 0.297531 12.317 0.570089L8.71214 8.22657L0.652174 9.45441C0.365312 9.49806 0.126895 9.70876 0.0373174 9.99782C-0.0523112 10.2869 0.0224385 10.6043 0.230082 10.8164L6.06218 16.7762L4.68561 25.1915C4.63656 25.4911 4.75406 25.7938 4.98862 25.9725C5.12131 26.0736 5.27848 26.125 5.43641 26.125C5.55767 26.125 5.67934 26.0947 5.79076 26.0333L13 22.06L20.2089 26.0332C20.4656 26.1747 20.7765 26.1511 21.0111 25.9725C21.2457 25.7938 21.3632 25.491 21.3142 25.1914L19.9372 16.7762L25.7699 10.8163C25.9775 10.6043 26.0523 10.2869 25.9627 9.99788Z" fill="#B3B3B3"/>
                </svg>

            </label>





            {/**
             2
             */}


            <input
                readOnly={value ? true : false}
                defaultChecked={value === 2 ? true : false}
                type="radio"
                id={`${id}` + 2}
                {...props.control.register(name)}
                value={2}
                {...other}
            />


            <label data-class="total" className={styles.rating} htmlFor={!readOnly ? `${id}` + 2 : ""}>
                <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M25.9627 9.99788C25.8731 9.70876 25.6348 9.49806 25.3479 9.45441L17.2877 8.22657L13.683 0.570089C13.5548 0.297531 13.2899 0.125 13 0.125C12.7101 0.125 12.4453 0.297531 12.317 0.570089L8.71214 8.22657L0.652174 9.45441C0.365312 9.49806 0.126895 9.70876 0.0373174 9.99782C-0.0523112 10.2869 0.0224385 10.6043 0.230082 10.8164L6.06218 16.7762L4.68561 25.1915C4.63656 25.4911 4.75406 25.7938 4.98862 25.9725C5.12131 26.0736 5.27848 26.125 5.43641 26.125C5.55767 26.125 5.67934 26.0947 5.79076 26.0333L13 22.06L20.2089 26.0332C20.4656 26.1747 20.7765 26.1511 21.0111 25.9725C21.2457 25.7938 21.3632 25.491 21.3142 25.1914L19.9372 16.7762L25.7699 10.8163C25.9775 10.6043 26.0523 10.2869 25.9627 9.99788Z" fill="#B3B3B3"/>
                </svg>

            </label>


            {/**
             1
             */}

            <input
                readOnly={value ? true : false}
                defaultChecked={value === 1 ? true : false}
                type="radio"
                id={`${id}` + 1}
                {...props.control.register(name)}
                value={1}
                {...other}
            />


            <label data-class="total" className={styles.rating} htmlFor={!readOnly ? `${id}` + 1 : ""}>
                <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M25.9627 9.99788C25.8731 9.70876 25.6348 9.49806 25.3479 9.45441L17.2877 8.22657L13.683 0.570089C13.5548 0.297531 13.2899 0.125 13 0.125C12.7101 0.125 12.4453 0.297531 12.317 0.570089L8.71214 8.22657L0.652174 9.45441C0.365312 9.49806 0.126895 9.70876 0.0373174 9.99782C-0.0523112 10.2869 0.0224385 10.6043 0.230082 10.8164L6.06218 16.7762L4.68561 25.1915C4.63656 25.4911 4.75406 25.7938 4.98862 25.9725C5.12131 26.0736 5.27848 26.125 5.43641 26.125C5.55767 26.125 5.67934 26.0947 5.79076 26.0333L13 22.06L20.2089 26.0332C20.4656 26.1747 20.7765 26.1511 21.0111 25.9725C21.2457 25.7938 21.3632 25.491 21.3142 25.1914L19.9372 16.7762L25.7699 10.8163C25.9775 10.6043 26.0523 10.2869 25.9627 9.99788Z" fill="#B3B3B3"/>
                </svg>

            </label>

        </div>
    );
};
