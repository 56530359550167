export const arrayOfTimezones = [
   {
      offset: "GMT-12:00",
      value: "-12:00",
      name: "Etc/GMT+12",
   },
   {
      offset: "GMT-11:00",
      value: "-11:00",
      name: "Etc/GMT+11",
   },
   {
      offset: "GMT-10:00",
      value: "-10:00",
      name: "Etc/GMT+10",
   },
   {
      offset: "GMT-09:00",
      value: "-09:00",
      name: "Etc/GMT+9",
   },
   {
      offset: "GMT-08:00",
      value: "-08:00",
      name: "Etc/GMT+8",
   },
   {
      offset: "GMT-07:00",
      value: "-07:00",
      name: "Etc/GMT+7",
   },
   {
      offset: "GMT-06:00",
      value: "-06:00",
      name: "Etc/GMT+6",
   },
   {
      offset: "GMT-05:00",
      value: "-05:00",
      name: "Etc/GMT+5",
   },
   {
      offset: "GMT-04:00",
      value: "-04:00",
      name: "Etc/GMT+4",
   },
   {
      offset: "GMT-03:00",
      value: "-03:00",
      name: "Etc/GMT+3",
   },
   {
      offset: "GMT-02:00",
      value: "-02:00",
      name: "Etc/GMT+2",
   },
   {
      offset: "GMT-01:00",
      value: "-01:00",
      name: "Etc/GMT+1",
   },
   {
      offset: "GMT-00:00",
      value: "+00:00",
      name: "Etc/GMT+0",
   },
   {
      offset: "GMT+01:00",
      value: "+01:00",
      name: "Etc/GMT-1",
   },
   {
      offset: "GMT+02:00",
      value: "+02:00",
      name: "Etc/GMT-2",
   },

   {
      offset: "GMT+03:00",
      value: "+03:00",
      name: "Etc/GMT-3",
   },
   {
      offset: "GMT+03:30",
      value: "+03:30",
      name: "Asia/Tehran",
   },

   {
      offset: "GMT+04:00",
      value: "+04:00",
      name: "Etc/GMT-4",
   },
   {
      offset: "GMT+04:30",
      value: "+04:30",
      name: "Asia/Kabul",
   },
   {
      offset: "GMT+05:00",
      value: "+05:00",
      name: "Etc/GMT-5",
   },
   {
      offset: "GMT+05:30",
      value: "+05:30",
      name: "Asia/Kolkata",
   },
   {
      offset: "GMT+05:45",
      value: "+05:45",
      name: "Asia/Katmandu",
   },
   {
      offset: "GMT+06:00",
      value: "+06:00",
      name: "Etc/GMT-6",
   },
   {
      offset: "GMT+06:30",
      value: "+06:30",
      name: "Asia/Rangoon",
   },
   {
      offset: "GMT+07:00",
      value: "+07:00",
      name: "Etc/GMT-7",
   },

   {
      offset: "GMT+08:00",
      value: "+08:00",
      name: "Etc/GMT-8",
   },
   {
      offset: "GMT+08:45",
      value: "+08:45",
      name: "Australia/Eucla",
   },
   {
      offset: "GMT+09:00",
      value: "+09:00",
      name: "Etc/GMT-9",
   },
   {
      offset: "GMT+09:30",
      value: "+09:30",
      name: "Australia/Darwin",
   },

   {
      offset: "GMT+10:00",
      value: "+10:00",
      name: "Etc/GMT-10",
   },
   {
      offset: "GMT+10:30",
      value: "+10:30",
      name: "Australia/South",
   },
   {
      offset: "GMT+11:00",
      value: "+11:00",
      name: "Etc/GMT-11",
   },
   {
      offset: "GMT+12:00",
      value: "+12:00",
      name: "Etc/GMT-12",
   },
   {
      offset: "GMT+13:00",
      value: "+13:00",
      name: "Etc/GMT-13",
   },
   {
      offset: "GMT+14:00",
      value: "+14:00",
      name: "Etc/GMT-14",
   },
];
