import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "../../../../hooks/useQuery";
import { certificateApi } from "../../../../api/certificateApi";

type propsType = {};
export const DownloadCert: FC<propsType> = (props) => {
   const { id } = useParams<any>();

   useQuery(() => certificateApi.getPublicCertificate(id), {
      serializer: (res) => {
         const aTag = document.createElement("a");
         aTag.setAttribute("href", res?.data.data.content);
         aTag.setAttribute("download", "Certificate");
         aTag.click();
         aTag.remove();
         window.close();
      },
   });

   return <></>;
};
