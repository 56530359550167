import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {StateType} from "../index";
import {notificationPropsType} from "components/Lk/components/Notifications/types";
import {notificationsData} from "components/Lk/components/Notifications/mock";

type initialStateType = {
    notifications_all: notificationPropsType[];
    notifications_new: notificationPropsType[];
    notifications_new_count : number
};
const initialState: initialStateType = {
    notifications_all: [],
    notifications_new: [],
    notifications_new_count : 0
};

export const notificationSlice = createSlice({
    name: "notificationSlice",
    initialState,
    reducers: {
        onloadNotification: (
            state,
            {payload }: PayloadAction<{is_new : boolean, notifications: notificationPropsType[]}>
        ) => {
            const { is_new , notifications } = payload
            if (is_new) {
                state.notifications_new = notifications
            } else {
                state.notifications_all = notifications
            }
        },
        setCountNewNotification: (
            state,
            {payload }: PayloadAction<number>
        ) => {
            state.notifications_new_count =  payload
        },
        setStatusToReadNotification: (
            state,
            {payload}: PayloadAction<number>
        ) => {
            state.notifications_new = state.notifications_new.filter(item =>  item.id !== payload)
        },
        clear: (state) => {
            return {...initialState}
        }
    },
});

export const notificationSliceSelectors = {
    getAllNotification: (state: StateType) => state.notificationSlice.notifications_all,
    getNewNotification: (state: StateType) => state.notificationSlice.notifications_new.filter(item => item.status === "new"),
    getCountNewNotification: (state: StateType) => state.notificationSlice.notifications_new_count,
}
