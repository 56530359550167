import { AxiosResponse } from "axios";
import {
   callbacksType,
   commonMethodProps,
   requestMethodType,
   StatusCodeType,
   statusHandlersType,
} from "../types/types";
import { callCallbacks } from "../simple methods/callCallbacks";
import { errorMapper } from "../../constructor";

type MethodResolverType<T> = {
   statusHandlers: statusHandlersType;
   response: AxiosResponse<any>;
   request: () => Promise<AxiosResponse<any>>;
} & commonMethodProps<T>;

type methodResolverConstructorType = {
   internalServerStatusCallback: (props: {
      response: AxiosResponse<any>;
      callbacks?: callbacksType;
      request: () => Promise<AxiosResponse<any>>;
      requestMethod?: requestMethodType;
      methods: statusHandlersType;
   }) => void;
};

export const methodResolverConstructor = ({
   internalServerStatusCallback,
}: methodResolverConstructorType) => {
   return function <T>({
      statusHandlers,
      commonState,
      response,
      callbacks,
      request,
      requestMethod,
   }: MethodResolverType<T>) {
      const methods = {
         200: statusHandlers["200"] || function () {},

         201: statusHandlers["201"] || function () {},

         204: statusHandlers["204"] || function () {},

         400: function () {
            statusHandlers["400"]?.call({});
            if (response) {
               //@ts-ignore
               commonState.errors = errorMapper(response);
            }
         },

         401:
            statusHandlers["401"] ||
            function () {
               if (response.data.data.errors) {
                  commonState.errors = response.data.data.errors;
               }
            },

         403: statusHandlers["403"] || function () {},

         404:
            statusHandlers["404"] ||
            function () {
               if (response.data.data.errors)
                  commonState.errors = response.data.data.errors;
            },

         500: statusHandlers["500"] || async function () {},
      };

      /* Internal server status case */
      if (response.status == 500) {
         if (methods[response.status]) {
            methods[response.status]();
         }
         internalServerStatusCallback({
            request,
            response,
            callbacks,
            methods,
            requestMethod,
         });
         return;
      }

      /* Default status case */
      callCallbacks({
         methods,
         callbacks,
         response,
      });
   };
};
