// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Filters_filters__rCFhO {\n    display: flex;\n    justify-content: space-between;\n}\n\n.Filters_filter_button__21Yyj {\n    margin-bottom: 20px;\n}\n\n.Filters_filters__block__1ME7c {\n    display: grid;\n    grid-gap: 20px;\n    grid-template-columns: 1fr 1fr;\n}", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/AdminService/Pages/Users/components/Filters/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,8BAA8B;AAClC","sourcesContent":[".filters {\n    display: flex;\n    justify-content: space-between;\n}\n\n.filter_button {\n    margin-bottom: 20px;\n}\n\n.filters__block {\n    display: grid;\n    grid-gap: 20px;\n    grid-template-columns: 1fr 1fr;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filters": "Filters_filters__rCFhO",
	"filter_button": "Filters_filter_button__21Yyj",
	"filters__block": "Filters_filters__block__1ME7c"
};
export default ___CSS_LOADER_EXPORT___;
