// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Table_container__ZrHAt {\n    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.25);\n    border-radius: 10px 10px 0px 0px;\n    width: 100%;\n\n}\n\n.Table_table__2h9Xn {\n    background: #FFFFFF;\n    width: 100%;\n}\n.Table_table__2h9Xn tr {\n    width: 100%;\n    border-bottom: 1px solid #D8D8D8;\n}\n.Table_table__2h9Xn tr th {\n    text-align: left;\n\n}\n.Table_table__2h9Xn td {\n}\n\n.Table_header__1fFJc {\n    background: #F8F8F8;\n    height: 40px;\n}\n\n.Table_header_item__2sFEb {\n    font-family: Source Sans Pro;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 12px;\n    line-height: 15px;\n    color: var(--color-black)\n}\n\n\n@media screen and (max-width: 1439px){\n    .Table_email__2yMIo {\n        display: none;\n    }\n}\n", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/AdminService/Pages/Users/components/Table/index.module.css"],"names":[],"mappings":"AAAA;IACI,4CAA4C;IAC5C,gCAAgC;IAChC,WAAW;;AAEf;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf;AACA;IACI,WAAW;IACX,gCAAgC;AACpC;AACA;IACI,gBAAgB;;AAEpB;AACA;AACA;;AAEA;IACI,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,4BAA4B;IAC5B,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB;AACJ;;;AAGA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".container {\n    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.25);\n    border-radius: 10px 10px 0px 0px;\n    width: 100%;\n\n}\n\n.table {\n    background: #FFFFFF;\n    width: 100%;\n}\n.table tr {\n    width: 100%;\n    border-bottom: 1px solid #D8D8D8;\n}\n.table tr th {\n    text-align: left;\n\n}\n.table td {\n}\n\n.header {\n    background: #F8F8F8;\n    height: 40px;\n}\n\n.header_item {\n    font-family: Source Sans Pro;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 12px;\n    line-height: 15px;\n    color: var(--color-black)\n}\n\n\n@media screen and (max-width: 1439px){\n    .email {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Table_container__ZrHAt",
	"table": "Table_table__2h9Xn",
	"header": "Table_header__1fFJc",
	"header_item": "Table_header_item__2sFEb",
	"email": "Table_email__2yMIo"
};
export default ___CSS_LOADER_EXPORT___;
