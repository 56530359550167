import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { useSelector } from "react-redux";
import {
   adminEditorSlice,
   adminEditorSliceSelectors,
} from "../../../../../../../../store/adminEditorSlice";
import { useHistory, useParams } from "react-router-dom";
import { routes } from "../../../../../../../../routes";
import { Input } from "../../../../../../../UI/Input";
import { useForm, useWatch } from "react-hook-form";
import { Button } from "../../../../../../../UI/Button";
import { TextLock } from "../../../../../../../../assets/inputs/TextLock";
import cn from "classnames";
import { useQuery } from "../../../../../../../../hooks/useQuery";
import { formsDataApi } from "../../../../../../../../api/formsDataApi/methods";
import {
   categoryType,
   educationSystemType,
} from "../../../../../../../../store/formsDataSlice/types";
import { adminApi } from "../../../../../../../../api/adminApi";
import { subjectType } from "../../../../../../../../store/types";
import { Preloader } from "../../../../../../../UI/Preloader";
import { SelectUnderControl } from "../../../../../../../UI/Select/SelectUnderControl";
import { useLocalCommonState } from "../../../../../../../../core/LTools/core/hooks/useLocalCommonState";
import { adminEditorThunk } from "../../../../../../../../store/adminEditorSlice/thunk";
import { userApi } from "../../../../../../../../api/userApi/methods";
import { PageTitle } from "../../../../../../../UI/PageTitle";
import { useTranslation } from "react-i18next";

type propsType = {};
type formType = {
   es: any;
   category: any;
   subject: string;
   category_input: string;
};
export const AddSubject: FC<propsType> = (props) => {
   const pickedSystem = useSelector(adminEditorSliceSelectors.getSystem);
   const pickedCategory = useSelector(adminEditorSliceSelectors.getCategory);
   const { t } = useTranslation();

   const { id } = useParams<{ id: string }>();
   console.log(id);
   const commonState = useLocalCommonState();

   const { setValue, handleSubmit, control } = useForm<formType>();
   const [isButtonDisabled, setIsButtonDisabled] = useState(true);
   const form = useWatch({ control });

   useEffect(() => {
      if (form.es && form.subject) {
         setIsButtonDisabled(false);
      } else {
         setIsButtonDisabled(true);
      }
   }, [form]);
   const history = useHistory();
   useEffect(() => {
      if (!pickedSystem) {
         history.push(routes.lk.admin.educationSystems.root);
      }
      if (pickedCategory && pickedCategory?.category?.id != -1 && !id) {
         //это id extra
         setValue("category", {
            id: pickedCategory!.category!.id,
            text: pickedCategory!.category!.name,
            value: {
               id: pickedCategory!.category!.id,
               text: pickedCategory!.category!.name,
            },
         });
         setValue("category_input", pickedCategory!.category!.name);
      }
   }, []);

   const { data: categoryData, isLoading: categoryDataLoading } = useQuery(
      () =>
         adminApi.getCategory({
            education_system_id: String(pickedSystem?.id),
         }),
      {
         // condition: !!id && !!storeLevel?.id,
         serializer: (res) => {
            return res?.data.data as Array<categoryType>;
         },
      }
   );
   const { data: subjectData, isLoading: subjectDataLoading } = useQuery(
      () =>
         formsDataApi.getSubjectById({
            subject_id: id,
         }),
      {
         condition: !!id,
         serializer: (res) => {
            return res?.data.data as subjectType;
         },
      }
   );
   useEffect(() => {
      if (subjectData?.id) {
         setValue("es", subjectData.education_system.name);
         setValue("category", {
            id: subjectData!.category?.id,
            text: subjectData!.category?.name,
            value: {
               id: subjectData!.category?.id,
               text: subjectData!.category?.name,
            },
         });
         setValue("category_input", subjectData!.category?.name);
         setValue("subject", subjectData!.name);
      }
   }, [subjectData]);
   const submit = (data: formType) => {
      console.log(data);
      if (!id) {
         commonState.dispatch(
            adminEditorThunk.addSubject({
               commonState,
               callbacks: {
                  201: () => {
                     commonState.dispatch(
                        adminEditorSlice.actions.setSystem(pickedSystem!)
                     );
                  },
               },
               education_system_id: pickedSystem!.id,
               data: {
                  subject_name: data.subject,
                  category_id: data.category?.id,
               },
            })
         );
      } else {
         commonState.dispatch(
            adminEditorThunk.editSubject({
               commonState,
               callbacks: {
                  200: () => {
                     commonState.dispatch(
                        adminEditorSlice.actions.setSystem(pickedSystem!)
                     );
                  },
               },
               education_system_id: pickedSystem!.id,
               subject_id: Number(id),
               data: {
                  subject_name: data.subject,
                  category_id: data.category?.id,
               },
            })
         );
      }
   };

   return (
      <form className={styles.container} onSubmit={handleSubmit(submit)}>
         <PageTitle
            title={
               id
                  ? t(
                       "Lk.AdminPanel.AddSystem.edit-subject",
                       "Редактировать предмет образования"
                    )
                  : t("Lk.AdminPanel.AddSystem.add-subject", "Добавить предмет")
            }
         />
         <Input
            control={control}
            name={"es"}
            defaultValue={pickedSystem?.name}
            className={cn({
               [styles.input]: true,
               [styles.locked_input]: true,
            })}
            label={t(
               "Lk.AdminPanel.AddSystem.es-name",
               "Название системы образования"
            )}
            readOnly={true}
            support={<TextLock />}
         />
         <SelectUnderControl
            name={"category"}
            setValue={setValue}
            control={control}
            label={t("Lk.AdminPanel.AddSystem.category", "Категория")}
            isLoading={categoryDataLoading}
            className={styles.input}
            options={categoryData?.map((category) => {
               return {
                  id: category.id,
                  text: category.name,
                  value: {
                     id: category.id,
                     text: category.name,
                  },
               };
            })}
         />

         <Input
            control={control}
            name={"subject"}
            className={styles.input}
            label={t(
               "Lk.AdminPanel.AddSystem.subject-name",
               "Название предмета"
            )}
         />
         <div className={styles.buttons}>
            <Button
               className={styles.add_button}
               disabled={isButtonDisabled}
               loading={commonState.loading}
            >
               {id
                  ? t("Lk.AdminPanel.AddSystem.edit", "Редактировать")
                  : t("Lk.SendRequest.add-button", "Добавить")}
            </Button>
            <Button
               className={styles.cancel_button}
               variant={"outline"}
               onClick={() => {
                  history.push(routes.lk.admin.educationSystems.root);
               }}
               loading={commonState.loading}
            >
               {t("Buttons.cancel", "Отменить")}
            </Button>
         </div>
      </form>
   );
};
