// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FiltersArea_container__2L6zP {\n    display: flex;\n    justify-content: center;\n    align-items: flex-start;\n    flex-direction: column;\n    margin-bottom: 24px;\n}\n\n.FiltersArea_title__3WqzC {\n    font-weight: 500;\n    font-size: 20px;\n    margin-bottom: 12px;\n}\n\n.FiltersArea_filter__1-DXQ {\n    background: #F8F8F8;\n    border-radius: 17px;\n    padding: 6px 12px;\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    margin-bottom: 10px;\n}\n\n.FiltersArea_close__kFfiZ {\n    margin-left: 10px;\n    cursor: pointer;\n}", "",{"version":3,"sources":["webpack://src/components/UI/FiltersArea/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,iBAAiB;IACjB,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".container {\n    display: flex;\n    justify-content: center;\n    align-items: flex-start;\n    flex-direction: column;\n    margin-bottom: 24px;\n}\n\n.title {\n    font-weight: 500;\n    font-size: 20px;\n    margin-bottom: 12px;\n}\n\n.filter {\n    background: #F8F8F8;\n    border-radius: 17px;\n    padding: 6px 12px;\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    margin-bottom: 10px;\n}\n\n.close {\n    margin-left: 10px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FiltersArea_container__2L6zP",
	"title": "FiltersArea_title__3WqzC",
	"filter": "FiltersArea_filter__1-DXQ",
	"close": "FiltersArea_close__kFfiZ"
};
export default ___CSS_LOADER_EXPORT___;
