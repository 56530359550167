import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StateType } from "../index";

export type modalWindowButtonType = "close" | "to_lk";

export type modalWindowTemplateType = {
   default: "default";
   deleteSlot: "delete-slot";
   deleteEvent: "delete_event";
   registrationSuccess: "registration_success";
   createAdminChat: "create_admin_chat";
   createLessonReport: "create_lesson_report";
   createSlotWarning: "create_slot_warning";
};

type modalOptionsType<T> = T extends modalWindowTemplateType["deleteSlot"]
   ? {
        slot_id: string;
        callback: () => void;
     }
   : T extends modalWindowTemplateType["deleteEvent"]
   ? {
        slot_id: string;
        event_id: string;
        deleteSlotLocal: () => void;
        deleteEventLocal: () => void;
     }
   : T extends modalWindowTemplateType["createLessonReport"]
   ? {
        type: "lesson" | "message";
     }
   : {};
type modalType<T> = {
   template: T;
   payload: {
      title?: string;
      subtitle?: string;
      button?: "to_lk" | "close" | "create-slot";
      status?: "success" | "error";
   } & modalOptionsType<T>;
};

export function createModalWindow<T>(data: modalType<T>) {
   return data;
}

type uiSliceType = {
   modalType: modalType<any>;
};
export const uiSlice = createSlice({
   name: "uiSlice",
   initialState: {
      modalType: {},
   } as uiSliceType,
   reducers: {
      setModalType: (state, { payload }: PayloadAction<modalType<any>>) => {
         state.modalType.template = payload.template;
         if (payload.payload) {
            state.modalType.payload = payload.payload;
         }
      },
      removeAll: (state) => {
         // @ts-ignore
         state.modalType = {};
      },
   },
});
export const uiSliceModalSelectors = {
   getModal: (state: StateType) => ({
      template: state.uiSlice.modalType.template,
      payload: state.uiSlice.modalType.payload as any,
   }),
};
