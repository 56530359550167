import React, { FC, useState } from "react";
import "./style.css";
import { ReactComponent as AddFileIcon } from "./assets/AddFile.svg";
import { UseFormHandleSubmit, useWatch } from "react-hook-form";
import { ReactComponent as FileIcon } from "./assets/File.svg";
import { ReactComponent as CancelIcon } from "./assets/Cancel.svg";
import { InputPropsType } from "../index";
import { useTranslation } from "react-i18next";
import { arrayLengthWithoutEmpty } from "helpers/array";
import styles from "./index.module.css";
import { Preloader } from "../../Preloader";
import { fileErrorTypeAlert } from "../../../../helpers/uploadFiles";

export type onAddFileType = { content: string; file_name: string };

type propsType<T> = {
   handleSubmit?: UseFormHandleSubmit<Record<string, any>>;
   setValue: any;
   onAddFile?: (file: onAddFileType) => Promise<any>;
   accept?: string;
} & InputPropsType<T>;

export const FilesInput: FC<propsType<any>> = (props) => {
   const { t } = useTranslation();
   const [currentIndex, setCurrentIndex] = useState("0");
   const [loading, setLoading] = useState(false);
   const files = useWatch({
      control: props.control,
      name: props.name,
   });

   const deleteFileHandler = (index: number | string) => {
      props.setValue(
         props.name,
         files.filter((item: any, tempIndex: number) => {
            return tempIndex != index;
         })
      );
   };

   const imageHandler = (e: any) => {
      e.preventDefault();
      props.onChange && props.onChange(e);
      const reader = new FileReader();
      const file = e.target.files[0];
      //check accept types if exist
      if (fileErrorTypeAlert(file, props.accept)) {
      } else {
         return;
      }
      reader.readAsDataURL(file);

      reader.onloadend = (e) => {
         if (props.onAddFile) {
            setLoading(true);
            props
               .onAddFile({
                  content: reader.result as string,
                  file_name: file.name,
               })
               .then((res) => {
                  setLoading(false);
                  if (res.status != 201) {
                     return;
                     alert("Error");
                  }
                  props.setValue(props.name + `.${currentIndex}`, {
                     file_name: file.name,
                     content: reader.result,
                     index: currentIndex,
                  });
               });
         } else {
            props.setValue(props.name + `.${currentIndex}`, {
               file_name: file.name,
               content: reader.result,
               index: currentIndex,
            });
         }

         setCurrentIndex((prev) => String(Number(prev) + 1));
      };
   };
   /* View */
   const arrayOfFiles = (function () {
      let result = [];
      for (let key in files) {
         if (files[key]) {
            result.push(files[key]);
         }
      }
      return result;
   })();
   return (
      <div className={`FilesInput__with-file ${props.className}`}>
         <div className={styles.files}>
            {arrayOfFiles.map((file: any, index: number) => {
               return (
                  <div key={index} className='FilesInput__file-preload-loaded'>
                     {React.createElement(FileIcon)}
                     <p className='FileInput__file-preload-loading-p'>
                        {file?.file_name}
                     </p>
                     <CancelIcon onClick={() => deleteFileHandler(index)} />
                  </div>
               );
            })}
         </div>

         <div className='FilesInput__area'>
            <div className='FilesInput__file'>
               {loading ? (
                  <Preloader className={styles.preloader} loading={true} />
               ) : (
                  <>
                     <input
                        {...props}
                        disabled={loading}
                        type='file'
                        name={props.name}
                        className='FilesInput__input'
                        id={props.name}
                        onChange={imageHandler}
                     />
                     {(arrayLengthWithoutEmpty(files) < 10 || !files) && (
                        <label
                           className='FilesInput__file-label'
                           htmlFor={props.name}
                        >
                           {React.createElement(AddFileIcon)}
                           <p>
                              {t(
                                 "UI.Input.FilesInput.add-files",
                                 "Добавить файлы"
                              )}
                           </p>
                        </label>
                     )}
                  </>
               )}
            </div>
         </div>
      </div>
   );
};
