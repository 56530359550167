import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { scheduleSlice } from "./scheduleSlice";
import { uiSlice } from "./modalWindowSlice";
import { useDispatch } from "react-redux";
import { lkSlice } from "./lkSlice";
import { authSlice } from "./authSlice";
import { fastLessonSlice } from "./fastLessonSlice";
import { lessonSlice } from "./lessonSlice";
import { chatSlice } from "./chatSlice";
import { cacheSlice } from "store/cacheSlice";
import { notificationSlice } from "store/notificationSlice";
import { formDataSlice } from "store/formsDataSlice";
import { adminEditorSlice } from "./adminEditorSlice";

/* Dispatch */
export type AppDispatch = typeof store.dispatch;

// useAppDispatch нужен, чтобы типизировать useDispatch. Дальше он кладется в useLocalCommonState и вызывается оттуда
export const useAppDispatch = () => useDispatch<AppDispatch>();

/* Init */
const reducer = combineReducers({
   authReducer: authSlice.reducer,
   scheduleSlice: scheduleSlice.reducer,
   uiSlice: uiSlice.reducer,
   lkSLice: lkSlice.reducer,
   fastLessonSlice: fastLessonSlice.reducer,
   lessonSlice: lessonSlice.reducer,
   chatSlice: chatSlice.reducer,
   cacheSlice: cacheSlice.reducer,
   notificationSlice: notificationSlice.reducer,
   formDataSlice: formDataSlice.reducer,
   adminEditorSlice: adminEditorSlice.reducer,
});

export const store = configureStore({
   reducer,
});
export type StateType = ReturnType<typeof reducer>;
