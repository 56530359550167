// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DeleteSlotTemplate_buttons__ecz8B {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n}\n.DeleteSlotTemplate_button__19jzD {\n    min-width: -moz-fit-content;\n    min-width: fit-content;\n    max-width: 292px;\n    margin-bottom: 10px;\n}", "",{"version":3,"sources":["webpack://src/components/ModalWindow/templates/DeleteSlotTemplate/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;AACf;AACA;IACI,2BAAsB;IAAtB,sBAAsB;IACtB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".buttons {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n}\n.button {\n    min-width: fit-content;\n    max-width: 292px;\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": "DeleteSlotTemplate_buttons__ecz8B",
	"button": "DeleteSlotTemplate_button__19jzD"
};
export default ___CSS_LOADER_EXPORT___;
