import React, { FC } from "react";
import styles from "./index.module.css";
import { NoteTableItem } from "./TableItem";
import { studentType, teacherType } from "types/userType";
import { subjectType, voteType } from "store/types";
import { useSelector } from "react-redux";
import { authSliceSelectors } from "store/authSlice";
import { Trans, useTranslation, withTranslation } from "react-i18next";
import { lessonType } from "types/LessonService/types";
import { Preloader } from "components/UI/Preloader";
import { useMedia } from "hooks/useMedia";

// export type LessonItemType = {
//    id: number;
//    status: "";
//    topic: string;
//    student: studentType;
//    teacher: teacherType;
//    subject: subjectType;
//    date_from: string;
//    date_to: string;
//    votes: Array<voteType>;
//    rejectDate: string;
// };

type AdminTableType = {
   data: Array<{
      id: number;
      date: Date;
      // time: string;
      // fileName: string;
      // status: string;
   }>;
   loading?: boolean;
   type?: "lesson" | "group_lesson";
};

export const ThisComponent: FC<AdminTableType> = (props) => {
   const { t } = useTranslation();
   const { data } = props;
   const id = useSelector(authSliceSelectors.getId);
   const { width } = useMedia();

   if (props.loading) {
      return (
         <div className={styles.container}>
            <table className={styles.table}>
               <Preloader loading={true}/>
            </table>
         </div>
      );
   }
   return (
      <div className={styles.container}>
         <table className={styles.table}>
            <tr className={styles.header}>
               <th className={styles.header_item}>
                  <Trans i18nKey={"FinancialOperations.headers.id"}>
                     ID
                  </Trans>
               </th>
               <th className={styles.header_item}>
                  <Trans i18nKey={"FinancialOperations.headers.date"}>
                     Дата
                  </Trans>
               </th>
               <th></th>
            </tr>
            {data?.map((item: any, index: any) => (
               <NoteTableItem item={item} key={item.id} />
            ))}
         </table>
      </div>
   );
};

export const NoteTable = withTranslation()(ThisComponent);
