import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { lkApi } from "../../../../../../api/LkApi/methods";
import { routes } from "../../../../../../routes";
import { schemeForIBAN, schemeWithAddress, schemeWithNoAddress } from "./schema";
import { ReactComponent as ArrowIcon } from "../../assets/arrow.svg";
import { Button } from "../../../../../UI/Button";
import { useGetCard } from "hooks/useGetCard";
import { useAddCard } from "hooks/useAddCard";
import { SwitcherBig } from "../../../../../UI/SwitcherBig";
import { useForm, useWatch } from "react-hook-form";
import { RadioBlock } from "../../components/RadioBlock";
import { Input } from "../../../../../UI/Input";
import { Popup } from "../../../../../UI/Popup";
import { getFirstErrorFromResponse } from "../../../../../../helpers/errors";
// import { isoCountriesSelectOptions } from "../../../../../../data/ui";
import { isoCountryType } from "../../../../../../types/uiData/types";
import { useGetCountries } from "../../../../../../hooks/useGetCountries";
import { uiSlice } from "../../../../../../store/modalWindowSlice";
import { useLocalCommonState } from "../../../../../../core/LTools/core/hooks/useLocalCommonState";
import { yupResolver } from "@hookform/resolvers/yup";
import { SmartForm } from "../../../../../hocs/SmartForm";
import { SelectUnderControl } from "../../../../../UI/Select/SelectUnderControl";

export type paymentSystemType = "us-usd" | "iban" | "swift" | "swift-mx" | "in-inr" | "au-aud" | "" | undefined;

export type formType = {
   name: string;
   account_number: string;
   sort_code_or_routing_number: string;
   recipient_bank_country: {
      id: number,
      text: string,
      value: isoCountryType;
   } | undefined;
   state_or_province: string;
   address_line_1: string;
   address_line_2: string;
   city: string;
   postal_code: string;
};

export const AddCardForPayout: FC = () => {
   const [switcherState, setSwitcherState] = useState(true);
   const [radioValue, setRadioValue] = useState<paymentSystemType>("");
   const [disabled, setDisabled] = useState(false);
   const [isPopupOpen, setIsPopupOpen] = useState(false);
   const [loading, setLoading] = useState(true);
   const [recipientCountry, setRecipientCountry] = useState<{
      id: number,
      text: string,
      value: isoCountryType;
   } | undefined>(undefined);

   const { t } = useTranslation();

   const form = useForm<formType>({
      resolver: yupResolver(
         switcherState
            ? radioValue === "iban"
               ? schemeForIBAN
               : schemeWithAddress
            : schemeWithNoAddress
      )
   });

   const labelResolver = (value: paymentSystemType) => {
      switch (value) {
         case "us-usd":
            return {
               leftInput: "Номер банковского счета",
               rightInput: "Маршрутный транзитный номер"
            }
         case "iban":
            return {
               leftInput: "IBAN",
               rightInput: "BIC"
            }
         case "swift":
            return {
               leftInput: "Номер банковского счета",
               rightInput: "БИК"
            }
         case "swift-mx":
            return {
               leftInput: "CLABE",
               rightInput: "Bank BIC"
            }
         case "in-inr":
            return {
               leftInput: "Bank account number",
               rightInput: "IFSC"
            }
         case "au-aud":
            return {
               leftInput: "Bank account number",
               rightInput: "BSB"
            }
         default:
            return {
               leftInput: `${t(
                  "Payment.pages.BankAccountDetails.fields.partner-bank-account-number",
                  "Номер банковского счета контрагента")
               }`,
               rightInput: `${t(
                  "Payment.pages.BankAccountDetails.fields.partner-bank-account-sort-code",
                  "Код сортировки счета контрагента")
               }`
            }
      }
   };

   const { isoCountriesSelectOptions } = useGetCountries();
   // const { data, isLoading: isLoadingGet } = useGetCard("payout");
   // const { error, addCard, isLoading: isLoadingAdd } = useAddCard("payout");

   const commonState = useLocalCommonState();

   // const [
   //    // name,
   //    // accountNumber,
   //    // bic,
   //    // stateOrProvince,
   //    // city,
   //    // addressLine1,
   //    // addressLine2,
   //    // postalCode,
   //    recipientBankCountry,
   // ] = useWatch({
   //    control: form.control,
   //    name: [
   //       // "name",
   //       // "account_number",
   //       // "bic",
   //       // "state_or_province",
   //       // "city",
   //       // "address_line_1",
   //       // "address_line_2",
   //       // "postal_code",
   //       "recipient_bank_country",
   //    ],
   // });

   const handleChange = (event: React.ChangeEvent<HTMLInputElement & { value: paymentSystemType }>) => {
      setRadioValue(event.target.value);
   };

   const handleReset = () => {
      setDisabled(false);
      setIsPopupOpen(false);
      setRecipientCountry(undefined);

      form.setValue("account_number", "");
      form.setValue("sort_code_or_routing_number", "");
      form.setValue("state_or_province", "");
      form.setValue("city", "");
      form.setValue("address_line_1", "");
      form.setValue("address_line_2", "");
      form.setValue("postal_code", "");
      form.setValue("recipient_bank_country", undefined);
   };

   const handleSave = async (data: formType) => {
      try {
         const response = await lkApi.saveRequisites({
            name: data.name,
            requisites: radioValue === "iban" ? {
               iban: data.account_number,
               bic: data.sort_code_or_routing_number,
            } : !switcherState || radioValue === "us-usd" ? {
               account_number: data.account_number,
               sort_code_or_routing_number: data.sort_code_or_routing_number,
            } : {
               account_number: data.account_number,
               bic: data.sort_code_or_routing_number,
            },
            ...(switcherState && {
               address: {
                  recipient_bank_country: data.recipient_bank_country?.value,
                  state_or_province: data.state_or_province,
                  address_line_1: data.address_line_1,
                  address_line_2: data.address_line_2,
                  postal_code: data.postal_code,
                  city: data.city,
               }
            })
         });

         if (response.status === 201) {
            setDisabled(true);
         }

         if (response.status === 400) {
            const firstError = getFirstErrorFromResponse(response);
            commonState.dispatch(
               uiSlice.actions.setModalType({
                  template: "default",
                  payload: {
                     title: "Ошибка",
                     subtitle: firstError,
                     button: "close",
                     status: "error",
                  },
               })
            );
         }
      } catch (error: any) {
         console.log(error);
      }
   };

   const popupData = {
      buttons: (
         <>
            <Button onClick={handleReset}>
               {t("Buttons.confirm", "Подтвердить")}
            </Button>
            <Button className={styles.button_cancel} onClick={() => setIsPopupOpen(false)}>
               Отменить
            </Button>
         </>
      )
   };

   useEffect(() => {
      setRadioValue("us-usd");
   }, [switcherState]);

   useEffect(() => {
      lkApi.getRequisites()
         .then((res) => {
            if (res.status === 200) {
               const requisitesData = res.data.data;

               console.log(res.data.data);

               if (res.data.data.requisites.iban) {
                  setRadioValue("iban");
               } else if (res.data.data.requisites.bic) {
                  setRadioValue("swift");
               } else if (res.data.data.requisites.sort_code_or_routing_number && res.data.data.address.address_line_1) {
                  setRadioValue("us-usd");
               } else {
                  setSwitcherState(false);
               }

               setRecipientCountry(isoCountriesSelectOptions.find(
                  item => item.value === res.data.data.address.recipient_bank_country)
               );

               form.setValue(
                  "name",
                  requisitesData.name
               );
               form.setValue(
                  "account_number",
                  requisitesData.requisites.account_number || requisitesData.requisites.iban
               );
               form.setValue(
                  "sort_code_or_routing_number",
                  requisitesData.requisites.sort_code_or_routing_number || requisitesData.requisites.bic
               );
               form.setValue(
                  "city",
                  requisitesData.address.city
               );
               form.setValue(
                  "postal_code",
                  requisitesData.address.postal_code
               );
               form.setValue(
                  "address_line_1",
                  requisitesData.address.address_line_1
               );
               form.setValue(
                  "address_line_2",
                  requisitesData.address.address_line_2
               );
               form.setValue(
                  "state_or_province",
                  requisitesData.address.state_or_province
               );

               setDisabled(true);
            }
         })
         .catch((e) => console.log(e))
         .finally(() => setLoading(false));
   }, []);

   return (
      <div className={cn(styles.container, "LkPaymentPage", {
         [styles.blurred]: loading,
      })}>
         <div className={styles.title}>
            {t(
               "Payment.pages.BankAccountDetails.title",
               "Реквизиты счета"
            )}
         </div>
         <div className={styles.subtitles}>
            <NavLink
               exact
               to={routes.lk.payment.root}
               activeClassName={styles.button_active}
            >
               <div className={styles.subtitles__item}>
                  <ArrowIcon className={styles.subtitles__icon}/>
                  <div className={styles.subtitles__title}>
                     {t("Payment.nav-links.payment", "Оплата")}
                  </div>
               </div>
            </NavLink>
            <NavLink
               exact
               to={routes.lk.payment.addCardForPayout}
               activeClassName={styles.button_active}
            >
               <div className={styles.subtitles__item}>
                  <ArrowIcon className={styles.subtitles__icon}/>
                  <div className={styles.subtitles__title}>
                     {t("Payment.nav-links.bank-account-details", "Реквизиты счета")}
                  </div>
               </div>
            </NavLink>
         </div>
         <SmartForm submit={handleSave} form={form}>
            <div className={styles.switcher}>
               <SwitcherBig
                  selected={switcherState}
                  labels={[
                     t("Payment.texts.uk", "UK"),
                     t("Payment.texts.non-uk", "Non UK")
                  ]}
                  setSelect={setSwitcherState}
                  disabled={disabled}
               />
            </div>
            {switcherState && (
               <div className={styles.radio_block}>
                  <RadioBlock
                     active={radioValue}
                     value={radioValue}
                     disabled={disabled}
                     handleChange={handleChange}
                  />
               </div>
            )}
            <div className={styles.content}>
               <div style={{ marginTop: 48 }}>
                  <Input
                     className={styles.input}
                     control={form.control}
                     name={"name"}
                     label={t("Payment.pages.BankAccountDetails.fields.name", "Имя")}
                     disabled={disabled}
                     error={form.formState.errors.name?.message}
                  />
               </div>
               <div className={styles.inputs}>
                  <Input
                     className={styles.input}
                     control={form.control}
                     name={"account_number"}
                     label={labelResolver(radioValue).leftInput}
                     disabled={disabled}
                     error={form.formState.errors.account_number?.message}
                  />
                  <Input
                     className={styles.input}
                     control={form.control}
                     name={"sort_code_or_routing_number"}
                     label={labelResolver(radioValue).rightInput}
                     disabled={disabled}
                     error={form.formState.errors.sort_code_or_routing_number?.message}
                  />
               </div>
               {switcherState && (
                  <div className={styles.address_details}>
                     <div className={styles.address_container}>
                        <div className={styles.address_title}>
                           {t(
                              "Payment.pages.BankAccountDetails.headers.address-details",
                              "Подробности адреса"
                           )}
                        </div>
                        <div className={styles.fields}>
                           <div className={styles.fields_left}>
                              <SelectUnderControl
                                 name={"recipient_bank_country"}
                                 setValue={form.setValue}
                                 // isLoading={subjectsLoading}
                                 label={t(
                                    "Payment.pages.TransactionDetails.fields.recipient-bank-country",
                                    "Recipient country"
                                 )}
                                 options={isoCountriesSelectOptions}
                                 // readOnly={!languages?.length}
                                 searchField
                                 // component={selectSubjectItem}
                                 control={form.control}
                                 bgColor={"#F8F8F8"}
                                 // variant='blue-bg'
                                 // loupe
                                 className={styles.address_select}
                                 disabled={disabled}
                                 // defaultSelectValue={recipientBankCountry}
                                 // @ts-ignore
                                 error={form.formState.errors.recipient_bank_country?.message}
                              />
                              {/*<Select*/}
                              {/*   className={styles.select}*/}
                              {/*   variant={"default"}*/}
                              {/*   name={"recipient_bank_country"}*/}
                              {/*   setValue={setValue}*/}
                              {/*   options={isoCountriesSelectOptions}*/}
                              {/*   control={control}*/}
                              {/*   support={<Icons.ui.ArrowDropdown/>}*/}
                              {/*   selectTitle={"Recipient country"}*/}
                              {/*   titleColor={"default"}*/}
                              {/*   type={"requisites"}*/}
                              {/*   disabled={disabled}*/}
                              {/*/>*/}
                              <Input
                                 className={styles.address_input}
                                 control={form.control}
                                 name={"state_or_province"}
                                 label={t(
                                    "Payment.pages.TransactionDetails.fields.state-or-province",
                                    "Recipient country"
                                 )}
                                 disabled={disabled}
                                 error={form.formState.errors.state_or_province?.message}
                              />
                              <Input
                                 className={styles.address_input}
                                 control={form.control}
                                 name={"city"}
                                 label={t(
                                    "Payment.pages.TransactionDetails.fields.city",
                                    "Recipient country"
                                 )}
                                 disabled={disabled}
                                 error={form.formState.errors.city?.message}
                              />
                           </div>
                           <div className={styles.fields_right}>
                              <Input
                                 className={styles.address_input}
                                 control={form.control}
                                 name={"address_line_1"}
                                 label={t(
                                    "Payment.pages.TransactionDetails.fields.address-line-1",
                                    "Recipient country"
                                 )}
                                 disabled={disabled}
                                 error={form.formState.errors.address_line_1?.message}
                              />
                              <Input
                                 className={styles.address_input}
                                 control={form.control}
                                 name={"address_line_2"}
                                 label={t(
                                    "Payment.pages.TransactionDetails.fields.address-line-2",
                                    "Recipient country"
                                 )}
                                 disabled={disabled}
                                 error={form.formState.errors.address_line_2?.message}
                              />
                              <Input
                                 className={styles.address_input}
                                 control={form.control}
                                 name={"postal_code"}
                                 label={t(
                                    "Payment.pages.TransactionDetails.fields.postal-code",
                                    "Recipient country"
                                 )}
                                 disabled={disabled}
                                 error={form.formState.errors.postal_code?.message}
                              />
                           </div>
                        </div>
                        <div>
                           {!disabled ? (
                              <Button className={styles.address_button}>
                                 {t("Buttons.save", "Сохранить")}
                              </Button>
                           ) : (
                              <Button
                                 type={"button"}
                                 className={cn(styles.address_button, styles.button_delete)}
                                 onClick={() => setIsPopupOpen(true)}
                              >
                                 {t("Buttons.delete", "Удалить")}
                              </Button>
                           )}
                        </div>
                     </div>
                  </div>
               )}
            </div>
            {!switcherState && !disabled && (
               <Button className={styles.button}>
                  {t("Buttons.save", "Сохранить")}
               </Button>
            )}
         </SmartForm>
         {!switcherState && disabled && (
            <Button className={cn(styles.button, styles.button_delete)} onClick={() => setIsPopupOpen(true)}>
               {t("Buttons.delete", "Удалить")}
            </Button>
         )}
         <Popup
            isOpen={isPopupOpen}
            setIsOpen={setIsPopupOpen}
            title={t("Common.attention", "Внимание!")}
            {...popupData}
         />
      </div>
   );
};
