import React, {FC, useEffect, useState} from 'react'
import styles from './style.module.css'
import {useTranslation} from "react-i18next";
import {notificationPropsType} from "../../types";

type propsType = {
   notification: notificationPropsType
}
export const NotificationCardMessage: FC<propsType> = ({notification}) => {
   const {t} = useTranslation()

   const messageTitleResolver = (): any => {
      switch (notification.notification_type) {
         case "role_teacher_to_admin":
            return t("Lk.Notifications.Message.title.teacher-to-admin", "Поздравляем, вы Администратор")
         case "role_admin_to_teacher":
            return t("Lk.Notifications.Message.title.admin-to-teacher", "Вы больше не Администратор")
         case "role_inactive_to_teacher":
            return t("Lk.Notifications.Message.title.inactive-to-teacher", "Поздравляем, ваша анкета преподавателя подтверждена")
         case "role_inactive_to_student":
            return t("Lk.Notifications.Message.title.inactive-to-student", "Ваша анкета преподавателя отклонена")
         default:
            return ""
      }
   }

   const messageTextResolver = (): any => {
      switch (notification.notification_type) {
         case "role_teacher_to_admin" :
            return t("Lk.Notifications.Message.text.teacher-to-admin", "Теперь вам доступен вход в панель администратора и возможности администратора")
         case "role_admin_to_teacher":
            return t("Lk.Notifications.Message.text.admin-to-teacher", "Статус вашего аккаунта понижен. По вопросам можете обратиться в поддержку")
         case "role_inactive_to_teacher":
            return t("Lk.Notifications.Message.text.inactive-to-teacher", "Теперь вам доступны проведение занятий и новые возможности")
         case "role_inactive_to_student":
            return t("Lk.Notifications.Message.text.inactive-to-student", "Заявка не прошла модерацию. По вопросам можете обратиться в поддержку")
         default:
            return ""
      }
   }

   return (
      <div className={styles.container}>
         <div className={styles.title}>{messageTitleResolver()}</div>
         <div className={styles.text}>{messageTextResolver()}
         </div>
      </div>
   )
}