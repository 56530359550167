import React, { FC } from "react";
import styles from "./style.module.css";
import { NotificationCardHeader } from "../../components/Header";
import { NotificationCardUser } from "../../components/User";
import { NotificationCardDate } from "../../components/Date";
import { NotificationCardButtons } from "../../components/Buttons";
import { notificationCardIcons } from "../../assets";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { notificationPropsType } from "../../types";
import { NotificationCardWrapper } from "../../components/NotificationCardWrapper";

type propsType = { notification: notificationPropsType; isPush?: boolean };
export const NotificationInviteCard: FC<propsType> = (props) => {
   const { notification, isPush = false } = props;

   const { t } = useTranslation();

   const iconResolver = (): any => {
      switch (notification.notification_type) {
         case "create":
            return notificationCardIcons.plus;
         case "approve":
            return notificationCardIcons.approve;
         case "reject":
            return notificationCardIcons.cross;
      }
   };

   const titleResolver = (): any => {
      switch (notification.notification_type) {
         case "create":
            return t(
               "Lk.Notifications.InviteCard.title-create",
               "Запрос на занятие"
            );
         case "approve":
            return t(
               "Lk.Notifications.InviteCard.title-approve",
               "Запрос на занятие принят"
            );
         case "reject":
            return t(
               "Lk.Notifications.InviteCard.title-reject",
               "Запрос на занятие отклонен"
            );
      }
   };

   if (!notification) return null;
   return (
      <NotificationCardWrapper
         className={cn(styles.container, {
            [styles[`${notification.notification_type}`]]: true,
         })}
      >
         <NotificationCardHeader
            date={notification.date}
            icon={iconResolver()}
            title={titleResolver()}
         />
         <NotificationCardUser user={notification.related_entity} />
         <NotificationCardDate
            entity_type={notification.entity_type}
            date_to={notification.entity?.date_to}
            date_from={notification.entity?.date_from}
            notification_type={notification.notification_type}
         />
         <NotificationCardButtons
            entity_id={Number(notification.entity?.id)}
            isPush={isPush}
            notification={notification}
         />
      </NotificationCardWrapper>
   );
};
