import { lkApi } from "api/LkApi/methods";
import { useMutation, useQueryClient } from "react-query";

export const useDeleteCard = (id: number | string) => {
    const queryClient = useQueryClient();

    const mutation = useMutation(() => lkApi.deleteCard(id), {
        onSettled: () => {
            queryClient.invalidateQueries("useGetCard");
            queryClient.invalidateQueries("useGetCardByUserID");
        },
    });

    const deleteCard = () => {
        mutation.mutate();
    };

    return {
        deleteCard,
        isLoading: mutation.isLoading,
        error: mutation.isError ? mutation.error : null,
    };
};
