import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { useTranslation } from "react-i18next";
import { useQuery } from "../../../../../../../../hooks/useQuery";
import { formsDataApi } from "../../../../../../../../api/formsDataApi/methods";
import { subjectSelectSerializer } from "../../../../../../../../serializers/subject";
import { SelectUnderControl } from "../../../../../../../UI/Select/SelectUnderControl";
import { useForm, useWatch } from "react-hook-form";
import { selectSubjectItem } from "../../../../../../../Landings/Main/SearchFilters";
import {
   categoryType,
   educationSystemType,
   levelType,
} from "../../../../../../../../store/formsDataSlice/types";
import { Icons } from "../../../../../../../../assets/iconsCorrect";
import cn from "classnames";
import { Preloader } from "../../../../../../../UI/Preloader";
import { useDispatch, useSelector } from "react-redux";
import {
   adminEditorSlice,
   adminEditorSliceSelectors,
} from "../../../../../../../../store/adminEditorSlice";
import { useHistory } from "react-router-dom";
import { routes } from "../../../../../../../../routes";
import { subjectType } from "../../../../../../../../store/types";
import { groupSubjectsByCategorySelect } from "../../../../../../../Landings/Filter/SubjectPicker/helpers/groupSubjectsByCategory";
import { adminApi } from "../../../../../../../../api/adminApi";

type propsType = {};
export const SystemsOverview: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const { control, handleSubmit, setValue, reset } = useForm();
   const history = useHistory();

   const pickedLevel = useSelector(adminEditorSliceSelectors.getLevel);
   const pickedSystem = useSelector(adminEditorSliceSelectors.getSystem);
   const pickedCategory = useSelector(adminEditorSliceSelectors.getCategory);

   const dispatch = useDispatch();

   const { data: categories, isLoading: categoriesLoading } = useQuery(
      () =>
         adminApi.getCategory({
            education_system_id: String(pickedSystem!.id),
         }),
      {
         condition: !!pickedSystem?.id,
         deps: [pickedSystem],
         serializer: (res): Array<categoryType> => res?.data.data,
      }
   );
   const { data: subjects, isLoading: subjectsLoading } = useQuery(
      formsDataApi.getSubject,
      {
         serializer: subjectSelectSerializer,
         // cachedValueName: "subjects",
         deps: [pickedSystem],
      }
   );
   const { data: level, isLoading: levelLoading } = useQuery(
      formsDataApi.getLevel,
      {
         cachedValueName: "level",
         serializer: (res): Array<levelType> => res?.data.data.items,
      }
   );
   const { data: es, isLoading: esLoading } = useQuery(
      () =>
         formsDataApi.getEducationSystem({
            level_id: String(pickedLevel?.id),
         }),
      {
         condition: !!pickedLevel?.id,
         serializer: (res) =>
            res?.data.data.items as Array<educationSystemType>,
         deps: [pickedLevel],
      }
   );

   const [groupedSubjects, setGroupedSubjects] = useState<{
      [key: string]: Array<any>;
   }>(subjects ? groupSubjectsByCategorySelect(subjects, pickedSystem) : {});
   useEffect(() => {
      subjects &&
         setGroupedSubjects(
            groupSubjectsByCategorySelect(subjects, pickedSystem)
         );
   }, [subjects, pickedSystem]);

   const setPickedLevel = (level: levelType) => {
      dispatch(adminEditorSlice.actions.setLevel(level));
   };
   const setPickedSystem = (system: educationSystemType) => {
      dispatch(adminEditorSlice.actions.setSystem(system));
   };
   const setPickedCategory = (category: categoryType) => {
      dispatch(
         adminEditorSlice.actions.setCategory({
            category: category,
            subjects: groupedSubjects[category.name],
         })
      );
   };
   const setPickedSubject = (subject: subjectType) => {
      dispatch(adminEditorSlice.actions.setSubject(subject));
   };
   const subjectWatch = useWatch({ control, name: "subject" });
   useEffect(() => {
      if (subjectWatch) {
         // console.log(subjectWatch, categories);
         subjectWatch.value?.education_system?.level &&
            setPickedLevel(subjectWatch.value.education_system.level);
         subjectWatch.value?.education_system &&
            setPickedSystem(subjectWatch.value.education_system);
         subjectWatch.value?.category &&
            setPickedCategory(subjectWatch.value?.category);
      }
   }, [subjectWatch]);

   return (
      <div className={styles.container}>
         <div className={styles.header}>
            {t("Notebook.headers.education_systems", "Системы образования")}
         </div>
         <div className={styles.search_container}>
            <div className={styles.title}>
               {t("Lk.AdminPanel.AddSystem.subject-search", "Поиск предмета")}
            </div>
            <SelectUnderControl
               name='subject'
               setValue={setValue}
               isLoading={subjectsLoading}
               label={t(
                  "Landings.SearchFilters.entry-filters-right",
                  "Введите интересуемый предмет"
               )}
               options={subjects}
               searchField
               component={selectSubjectItem}
               control={control}
               bgColor={"#F8F8F8"}
               variant='blue-bg'
               loupe
            />
         </div>
         <div className={styles.level_container}>
            <div className={styles.title}>
               {t("Lk.RegisterFastLesson.level-label", "Уровень образования")}
            </div>
            <div className={styles.levels_picker}>
               {level?.map((level, index) => (
                  <button
                     key={index}
                     data-id={level.id}
                     className={cn({
                        [styles.grade_button]: true,
                        [styles[`grade_button--active`]]:
                           level.id == pickedLevel?.id,
                     })}
                     onClick={() => setPickedLevel(level)}
                  >
                     {level?.name &&
                        React.createElement(Icons.levelIcons[level?.name], {
                           className: styles.level_icon,
                        })}
                     <p>{level.name}</p>
                  </button>
               ))}
            </div>
         </div>
         <div className={styles.systems_container}>
            <div className={styles.title}>
               {t("Notebook.headers.education_systems", "Системы образования")}
            </div>
            <div className={styles.system_picker}>
               {es && !esLoading ? (
                  es.map((item: educationSystemType) => (
                     <div
                        className={cn({
                           [styles.system_container]: true,
                           [styles["system_container--active"]]:
                              item.id == pickedSystem?.id,
                        })}
                        onClick={() => setPickedSystem(item)}
                     >
                        <div className={styles.language_icon}>
                           {item.languages.slice(0, 8).map((item) => {
                              return React.createElement(
                                 Icons.languageFlags[item.name],
                                 {
                                    className: styles.system_flag,
                                 }
                              );
                           })}
                        </div>
                        <div className={styles.system_title}>{item.name}</div>
                     </div>
                  ))
               ) : (
                  <Preloader variant={"centred"} loading={esLoading} />
               )}
            </div>
            {es && !esLoading && (
               <button
                  className={styles.add_button}
                  onClick={() =>
                     history.push(routes.lk.admin.educationSystems.addSystem)
                  }
               >
                  <Icons.buttons.CirclePlusIcon />
                  {t(
                     "Lk.AdminPanel.AddSystem.add-es",
                     "Добавить систему образования"
                  )}
               </button>
            )}
         </div>
         {!!pickedSystem?.id && (
            <>
               <div className={styles.info_container}>
                  <div className={styles.title}>
                     {t("Lk.AdminPanel.AddSystem.information", "Информация")}
                  </div>
                  <div className={styles.info_title}>
                     {t("Lk.AdminPanel.AddSystem.name", "Название")}
                  </div>
                  <div className={styles.system_name}>{pickedSystem.name}</div>
                  <div className={styles.language_title}>
                     {t("Common.language", "Язык")}
                  </div>
                  <div className={styles.languages_container}>
                     {pickedSystem.languages.map((language) => {
                        return (
                           <div className={styles.system_language_item}>
                              {React.createElement(
                                 Icons.languageFlags[language.name],
                                 {
                                    className: styles.system_flag,
                                 }
                              )}
                              {language.name}
                           </div>
                        );
                     })}
                  </div>
                  <button
                     className={styles.add_button}
                     onClick={() =>
                        history.push(
                           routes.lk.admin.educationSystems.addSystem +
                              "/" +
                              pickedSystem.id
                        )
                     }
                  >
                     <Icons.buttons.CircleEditIcon />
                     {t("Lk.AdminPanel.AddSystem.edit", "Редактировать")}
                  </button>
               </div>
               {categoriesLoading ? (
                  <Preloader loading={categoriesLoading} variant={"small"} />
               ) : (
                  <div className={styles.categories_container}>
                     <div className={styles.categories_title}>
                        {t("Lk.AdminPanel.AddSystem.categories", "Категории")}
                        <span className={styles.counter_title}>
                           {categories?.length}
                        </span>
                     </div>
                     {!subjectsLoading ? (
                        <>
                           <div className={styles.categories_picker}>
                              {categories?.map((category) => {
                                 return (
                                    <div
                                       className={cn({
                                          [styles.category_item]: true,
                                          [styles["category_item--active"]]:
                                             category.id ==
                                             pickedCategory?.category?.id,
                                       })}
                                       onClick={() => {
                                          setPickedCategory(category);
                                       }}
                                    >
                                       {category.name}
                                    </div>
                                 );
                              })}
                              {groupedSubjects["Undef"] && (
                                 <div
                                    className={cn({
                                       [styles.category_item]: true,
                                       [styles["category_item--active"]]:
                                          "Undef" ==
                                          pickedCategory?.category?.name,
                                    })}
                                    onClick={() => {
                                       setPickedCategory({
                                          id: -1,
                                          name: "Undef",
                                       });
                                    }}
                                 >
                                    {t(
                                       "Lk.AdminPanel.AddSystem.extra",
                                       "Без категории"
                                    )}
                                 </div>
                              )}
                           </div>
                           <div className={styles.categories_buttons}>
                              <button
                                 className={styles.add_button}
                                 onClick={() =>
                                    history.push(
                                       routes.lk.admin.educationSystems
                                          .addCategory
                                    )
                                 }
                              >
                                 <Icons.buttons.CirclePlusIcon />
                                 {t(
                                    "Lk.AdminPanel.AddSystem.add-category",
                                    "Добавить категорию"
                                 )}
                              </button>
                              {categories?.length >= 1 && (
                                 <button
                                    className={styles.add_button}
                                    onClick={() =>
                                       history.push(
                                          routes.lk.admin.educationSystems
                                             .addCategory +
                                             "/" +
                                             pickedCategory?.category?.id
                                       )
                                    }
                                 >
                                    <Icons.buttons.CircleEditIcon />
                                    {t(
                                       "Lk.AdminPanel.AddSystem.edit",
                                       "Редактировать"
                                    )}
                                 </button>
                              )}
                           </div>
                        </>
                     ) : (
                        <Preloader
                           variant={"centred"}
                           loading={subjectsLoading}
                        />
                     )}
                  </div>
               )}
               {
                  <div className={styles.subjects_container}>
                     <div className={styles.categories_title}>
                        {t("Lk.LkTeacherOwn.subjects", "Предметы")}
                        <span className={styles.counter_title}>
                           {pickedCategory?.subjects?.length}
                        </span>
                     </div>
                     <div className={styles.subjects_picker}>
                        {pickedCategory?.subjects?.map((subject) => {
                           return (
                              <div
                                 className={styles.subject_item}
                                 onClick={() =>
                                    history.push(
                                       routes.lk.admin.educationSystems
                                          .addSubject +
                                          "/" +
                                          subject.id
                                    )
                                 }
                              >
                                 {subject.value.name}
                              </div>
                           );
                        })}
                     </div>
                     <button
                        className={styles.add_button}
                        onClick={() =>
                           history.push(
                              routes.lk.admin.educationSystems.addSubject
                           )
                        }
                     >
                        <Icons.buttons.CirclePlusIcon />
                        {t(
                           "Lk.AdminPanel.AddSystem.add-subject",
                           "Добавить предмет"
                        )}
                     </button>
                  </div>
               }
            </>
         )}
      </div>
   );
};
