import React, { FC, useEffect, useState } from "react";
import styles from "./style.module.css";
import { ReactComponent as StarIcon } from "./assets/Start.svg";
import { ReactComponent as ArchiveIcon } from "./assets/Archive.svg";
import { ReactComponent as HeaderStar } from "./assets/HeaderStar.svg";
import { Button } from "components/UI/Button";
import { voteType } from "store/types";
import { voteForLessonType } from "api/lessonApi/types";
import { AxiosResponse } from "axios";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { authSliceSelectors } from "../../../../../../store/authSlice";
import { Icons } from "assets/iconsCorrect";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { ChatThunk } from "store/chatSlice/thunk";
import { createModalWindow, uiSlice } from "store/modalWindowSlice";

type propsType = {
   votes: Array<voteType>;
   apiMethod: (props: voteForLessonType) => Promise<AxiosResponse<any>>;
   lesson_id: string;
   target: "student" | "teacher";
};
export const LessonRating: FC<propsType> = ({
   votes,
   apiMethod,
   lesson_id,
   target,
}) => {
   const { t } = useTranslation();
   const userId = useSelector(authSliceSelectors.getId);
   const [tempRating, setTempRating] = useState(0);
   const [staticRating, setStaticRating] = useState(0);
   const commonState = useLocalCommonState();

   useEffect(() => {
      const vote = votes.find((vote) => String(vote?.initiator_id) == userId);
      if (vote) {
         setTempRating(vote.rating);
         setStaticRating(vote.rating);
      }
   }, [votes]);

   const voteHandler = async () => {
      if (staticRating == 0) {
         const response = await apiMethod({
            rating: tempRating,
            target: target || "student",
            lesson_id: lesson_id,
         });
         if (response.status == 201) {
            setStaticRating(tempRating);
         }
      }
   };

   const complaintHandler = () => {
      // voteHandler().then(() =>
      commonState.dispatch(
         uiSlice.actions.setModalType(
            createModalWindow<"create_lesson_report">({
               template: "create_lesson_report",
               payload: {
                  type: "lesson",
               },
            })
         )
      );
      // );
   };

   const tempVoteHandler = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      if (staticRating != 0) return;
      const rate = Number(e.currentTarget.getAttribute("data-rate"));
      setTempRating(rate);
   };

   return (
      <div className={styles.container}>
         <div className={styles.header}>
            {!staticRating ? (
               <>
                  <HeaderStar className={styles.header_icon} />
                  {t(
                     "Lk.lesson-service.rating.title-1",
                     "Оцените ваше занятие"
                  )}
               </>
            ) : (
               <>
                  <ArchiveIcon className={styles.header_icon} />{" "}
                  {t("Lk.lesson-service.rating.title-2", "В архиве")}
               </>
            )}
         </div>

         <div className={styles.input_wrapper}>
            <div className={styles.rating_title}>
               {tempRating ? tempRating : "-"}
            </div>
            <div className={styles.rating_input}>
               {[1, 2, 3, 4, 5].map((item) => (
                  <StarIcon
                     data-rate={item}
                     className={cn(styles.star, {
                        [`${styles["star--checked"]}`]:
                           tempRating != 0 && item <= tempRating,
                     })}
                     onClick={tempVoteHandler}
                  />
               ))}
            </div>
            {tempRating <= 2 && !!tempRating && !staticRating && (
               <Icons.ui.complaintIcon
                  className={styles.complaint}
                  onClick={complaintHandler}
               />
            )}
         </div>
         {tempRating == 0 && (
            <div className={styles.prompt_title}>
               {t(
                  "Lk.lesson-service.rating.action-1",
                  " Пожалуйста, оцените как прошло ваше занятие"
               )}
            </div>
         )}
         {!!tempRating && !staticRating && (
            <Button onClick={voteHandler}>
               {t("Lk.lesson-service.rating.button", "Оценить занятие")}
            </Button>
         )}
      </div>
   );
};
