import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { lessonApi } from "api/lessonApi/methods";
import { eventSlotApi } from "api/eventSlotApi/methods";
import { inviteApi } from "api/inviteApi/methods";
import { groupLessonApi } from "api/groupLessonApi/methods";
import { scheduleSliceType } from "./index";
import { ticketsGroupHandler } from "store/scheduleSlice/helpers/ticketsGroupHandler";
import { timelineTicketsGroupHandler } from "store/scheduleSlice/helpers/timelineTicketsGroupHandler";

export const scheduleApi = {
   getTickets: function () {
      return createAsyncThunk(
         "authExtraSlice/getTickets",
         async (userId: string) => {
            const lessons = await lessonApi.getLessons({
               // limit: "100",
               // offset: "0",
               lesson_statuses: ["new_lesson"],
            });
            const slots = await eventSlotApi.getSlot({
               user_id: userId,
            });
            const invites = await inviteApi.getInvite({
               // limit: "100",
               // offset: "0",
               role: "student",
               invite_statuses: ["new_invite"],
            });
            const groupLessons = await groupLessonApi.getGroupLessons({
               user_id: userId,
               // limit: "100",
               // offset: "0",
               lesson_statuses: ["new_group_lesson"],
            });
            return {
               lessons: lessons.data.data.items.map((item: any) => ({
                  ...item,
                  type: "lesson",
               })),
               slots: slots.data.data.items.map((item: any) => ({
                  ...item,
                  type: "slot",
               })),
               invites: invites.data.data.items.map((item: any) => ({
                  ...item,
                  type: "invite",
               })),
               groupLessons: groupLessons.data.data.items.map((item: any) => ({
                  ...item,
                  type: "group_lesson",
               })),
            };
         }
      );
   },
};

export const scheduleApiExtraReducers = {
   [scheduleApi.getTickets().pending.toString()]: (
      state: scheduleSliceType
   ) => {
      state.loading = true;
   },
   [scheduleApi.getTickets().fulfilled.toString()]: (
      state: scheduleSliceType,
      {
         payload,
      }: PayloadAction<{
         lessons: any[];
         invites: any[];
         groupLessons: any[];
         slots: any[];
      }>
   ) => {
      // state.ticketsRaw = payload;
      state.ticketsForTimeline = timelineTicketsGroupHandler(payload);
      state.tickets = ticketsGroupHandler(payload);
      state.loading = false;
   },
   [scheduleApi.getTickets().rejected.toString()]: (
      state: scheduleSliceType,
      { payload }: any
   ) => {},
};
