import { ModalWindowBody } from "components/ModalWindow/templates/components/ModalWindowBody";
import { useTranslation } from "react-i18next";
import { Button } from "components/UI/Button";
import React, { FC } from "react";
import { routes } from "routes";
import { useHistory } from "react-router-dom";
import styles from "./index.module.css";

type propsType = {
   close: () => void;
};
export const CreateSlotWarning: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const history = useHistory();

   const createSlot = () => {
      props.close && props.close();

      history.push(routes.lk.lesson.create_slot);
   };

   return (
      <div className={styles.container}>
         <ModalWindowBody
            title={t("ModalWindow.SlotWarning.title", "Заполните  расписание.")}
            subtitle={t(
               "ModalWindow.SlotWarning.description",
               "Перейдите в  расписание и создайте время  для занятия. Это необходимое  условие для  записи к вам учеников."
            )}
         />
         <Button className={styles.button} onClick={createSlot}>
            {t("Lk.lesson-service.add-lesson.title", "Добавить занятие")}
         </Button>
      </div>
   );
};
