import React, { FC } from "react";
import styles from "components/Landings/Footer/index.module.css";
import { useTranslation } from "react-i18next";
import { Icons } from "assets/iconsCorrect";
// @ts-ignore
import pp from "../../../pdf/privacy_policy.pdf";
// @ts-ignore
import ua from "../../../pdf/user_agreement.pdf";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import { useMedia } from "../../../hooks/useMedia";

type propsType = {
   variant?: "default" | "menu";
};

export const Footer: FC<propsType> = ({ variant = "default" }) => {
   const { t } = useTranslation();
   const history = useHistory();

   return (
      <>
         <div className={cn(styles.container, {
            [styles.container_column]: variant === "menu"
         })}>
            <div className={styles.icons}>
               <a href='mailto:support@e-tutorinternational.com'  className={styles.icon}>
                  <Icons.social.EmailIcon className={styles.icon} />
               </a>
               <a
                  target={"_blank"}
                  href='https://www.instagram.com/etutor.international/'
                  className={styles.icon}
               >
                  <Icons.social.InstIcon className={styles.icon} />
               </a>
               <a
                  href='http://linkedin.com//company/etutor-international'
                  target={"_blank"}
                  className={styles.icon}
               >
                  <Icons.social.LinkdIcon className={styles.icon} />
               </a>
               <a
                  href='https://www.youtube.com/channel/UCyfW_iwrOfvdO0_YevGF-Dw'
                  target={"_blank"}
                  className={styles.icon}
               >
                  <Icons.social.YouTubeIcon className={styles.icon} />
               </a>
               <a
                  href='https://play.google.com/store/apps/details?id=com.etutor.inter'
                  target={"_blank"}
                  className={styles.icon}
               >
                  <Icons.buttons.GooglePlay className={styles.icon} />
               </a>
               <a
                  href='https://apps.apple.com/en/app/e-tutor-international/id6502837286'
                  target={"_blank"}
                  // className={styles.icon}
               >
                  <Icons.buttons.AppStore className={styles.icon} />
               </a>
            </div>
            <p className={styles.text}>
               {t(
                  "Footer.text",
                  "2021 All Rights Reserved | E-Tutor International"
               )}
               <a className={styles.link}
                  onClick={() => history.push("/privacy_policy")}
               >
                  | Privacy Policy
               </a>
               <a className={styles.link}
                  onClick={() => history.push("/user_agreement")}
               >
                  | User Agreement
               </a>
            </p>
            <p> Version 1.0 </p>
            <p style={{ height: 50 }}></p>
            <p></p>
            <p></p>
            <p></p>
         </div>
      </>
   );
};
