import React, { FC, useEffect } from "react";
import styles from "./style.module.css";
import { Button } from "components/UI/Button";
import { useDispatch, useSelector } from "react-redux";
import {
   fastLessonSlice,
   fastLessonSliceSelectors,
} from "store/fastLessonSlice";
import { routes } from "routes";
import { Link } from "react-router-dom";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { fastLessonThunks } from "store/fastLessonSlice/thunks";
import { useTranslation } from "react-i18next";
import { lkSliceSelectors } from "store/lkSlice";

export const SearchFastLessonButtons: FC = () => {
   const { t } = useTranslation();
   const application = useSelector(
      fastLessonSliceSelectors.getCurrentApplication
   );
   const dispatch = useDispatch();
   const user = useSelector(lkSliceSelectors.getUserData);

   const commonState = useLocalCommonState();
   const rejectSearching = () => {
      commonState.dispatch(
         fastLessonThunks.deleteApplication({
            commonState,
            user_id: user.id,
            application_id: String(application.id),
            callbacks: {
               "204": () => {
                  commonState.location = routes.lk.profile + `/${user.id}`;
                  // dispatch(
                  //    fastLessonSlice.actions.setCurrentApplication({
                  //       ...application,
                  //       status: "rejected_application",
                  //    })
                  // );
               },
               "403": () => {
                  commonState.location = routes.lk.profile + `/${user.id}`;
               },
            },
         })
      );
   };

   return (
      <div className={styles.container}>
         {application.status == "application" ? (
            <Button
               variant='primary'
               onClick={rejectSearching}
               loading={commonState.loading}
            >
               {t("Buttons.cancel", "Отменить")}
            </Button>
         ) : (
            <Link to={routes.lk.profile + `/${user.id}`}>
               <Button variant='primary'>
                  {t("Buttons.to-lk", "В личный кабинет")}
               </Button>
            </Link>
         )}
      </div>
   );
};
