import React, { FC, HTMLAttributes, useEffect, useState } from "react";
import styles from "./index.module.css";
import { Icons } from "../../../../../assets/iconsCorrect";
import { Button } from "../../index";
import { useTranslation } from "react-i18next";

type propsType = {
   val?: boolean;
   setIsPopupOpen: (val: boolean) => void;
   title?: string;
} & HTMLAttributes<HTMLButtonElement>;
export const FilterButtonSec: FC<propsType> = ({ setIsPopupOpen, val, ...props }) => {
   const { t } = useTranslation();
   return (
      <Button {...props} onClick={() => setIsPopupOpen(!val)} variant="filters" bgColor='gray-no-hover'>
         <Icons.ui.FilterIcon />
         <div className={styles.title}>
            {props.title || t("Buttons.filters", "Фильтры")}
         </div>
      </Button>
   );
};
