import React, { FC, useEffect, useState } from "react";
import styles from "./style.module.css";
import { NotificationCardHeader } from "../../components/Header";
import { NotificationCardUser } from "../../components/User";
import { NotificationCardMessage } from "../../components/Message";
import { NotificationCardRating } from "../../components/Rating";
import { NotificationCardDate } from "../../components/Date";
import { NotificationCardButtons } from "../../components/Buttons";
import { notificationCardIcons } from "../../assets";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { notificationPropsType } from "../../types";
import { NotificationCardWrapper } from "../../components/NotificationCardWrapper";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { lkSLiceThunk } from "store/lkSlice/thunk";

type propsType = { notification: notificationPropsType; isPush?: boolean };
export const NotificationStatusCard: FC<propsType> = (props) => {
   const commonState = useLocalCommonState();
   const { notification, isPush = false } = props;

   const { t } = useTranslation();

   // Изменение прав:
   //    Когда админ дал учителю права админа - уведомление этому учителю (NotificationType = role_teacher_to_admin), связей нет
   //    Когда админ перевел админа в учителя - уведомление этому учителю (NotificationType = role_admin_to_teacher), связей нет
   //    Для Status admin entity_type будет null
   // Когда перевели InactiveTeacher в Teacher - уведомление этому учителю (NotificationType = role_inactive_to_teacher), связей нет
   // Когда перевели InactiveTeacher в Student- уведомление этому студенту (NotificationType = role_inactive_to_student), связей нет Reject
   // Когда появился InactiveTeacher - уведомление всем админам (NotificationType = role_student_to_inactive), entity - InactiveTeacher

   const iconResolver = (): any => {
      switch (notification.notification_type) {
         case "role_teacher_to_admin":
         case "role_admin_to_teacher":
            return notificationCardIcons.info;
         case "role_inactive_to_teacher":
            return notificationCardIcons.hat;
         case "role_inactive_to_student":
            return notificationCardIcons.hat;
         case "role_student_to_inactive":
            return notificationCardIcons.question;
         default:
            return notificationCardIcons.info;
      }
   };

   const titleResolver = (): any => {
      switch (notification.notification_type) {
         case "role_active_to_student":
            return t(
               "Lk.Notifications.StatusCard.active-to-student",
               "Вы больше не преподаватель"
            );
         case "role_teacher_to_admin":
         case "role_admin_to_teacher":
            return t(
               "Lk.Notifications.StatusCard.role-change",
               "Изменение статуса"
            );
         case "role_inactive_to_teacher":
            return t(
               "Lk.Notifications.StatusCard.inactive-to-teacher",
               "Анкета подтверждена"
            );
         case "role_inactive_to_student":
            return t(
               "Lk.Notifications.StatusCard.inactive-to student",
               "Анкета отклонена"
            );
         case "role_student_to_inactive":
            return t(
               "Lk.Notifications.StatusCard.admin_inactive-to teacher",
               "Заявка на преподавание"
            );
         default:
            return "";
      }
   };

   useEffect(() => {
      if (notification.status === "new") {
         commonState.dispatch(
            lkSLiceThunk.getUser({ commonState, callbacks: {} })
         );
      }
   }, []);

   return (
      <NotificationCardWrapper
         className={cn(styles.container, {
            [styles[`${notification.notification_type}`]]: true,
         })}
      >
         <NotificationCardHeader
            date={notification.date}
            icon={iconResolver()}
            title={titleResolver()}
         />
         <NotificationCardUser user={notification.related_entity} />
         <NotificationCardMessage notification={notification} />
         {notification.notification_type === "role_student_to_inactive" && (
            <NotificationCardDate
               entity_type={notification.entity_type}
               date_to={notification.entity?.date_to}
               date_from={notification.date}
               notification_type={notification.notification_type}
            />
         )}
         <NotificationCardButtons isPush={isPush} notification={notification} />
      </NotificationCardWrapper>
   );
};
