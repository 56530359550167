import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StateType } from "../index";
import {lessonType} from "types/LessonService/types";

const initialState: {
   currentLesson: lessonType;
} = {
   currentLesson: {} as lessonType,
};

export const lessonSlice = createSlice({
   name: "lessonSlice",
   initialState,
   reducers: {
      setCurrentLesson: (state, { payload }: PayloadAction<any>) => {
         state.currentLesson = payload;
      },
   },
});

export const lessonSliceSelectors = {
   getCurrentLesson: (state: StateType) => state.lessonSlice.currentLesson,
};
