import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { ReactComponent as DropDownIcon } from "components/Lk/Pages/Settings/Worksheet/assets/Dropdown.svg";
import { useToggle } from "hooks/useToggle";

type propsType = {
   title?: string;
   className?: string;
   style?: any;
};
export const SettingsDropdown: FC<propsType> = (props) => {
   const [isOpen, toggleOpen] = useToggle(false);

   return (
      <div
         className={styles.container + " " + props.className}
         style={props.style}
      >
         <div onClick={toggleOpen} className={styles.title}>
            <p> {props.title}</p>
            <DropDownIcon
               style={{
                  stroke: "#000000",
                  transform: `rotateZ(${180 * Number(isOpen)}deg)`,
               }}
               className={styles.arrow}
            />
         </div>
         {isOpen && <main className={styles.main}>{props.children}</main>}
      </div>
   );
};
