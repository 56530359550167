import { Calendar } from "../../../../../../UI/Calendar";
import { SelectUnderControl } from "../../../../../../UI/Select/SelectUnderControl";
import {
   renderDurationOptions,
   renderStartTimeOptions,
} from "../../../../../../../helpers/selectOptions";
import React from "react";
import { Control } from "react-hook-form";
import { ratingOptions } from "../../../../../../Lk/Pages/Notebook/components/NotePeoples/components/filterFields";
import { TeacherSearchFiltersType } from "../../../index";
import moment from "moment-timezone";

export const getTeacherSearchFiltersElements = ({
   setValue,
   control,
   t,
   filters,
   errors,
}: {
   control: Control<any>;
   setValue: any;
   t: any;
   filters: TeacherSearchFiltersType;
   errors: any;
}) => ({
   date: (
      <Calendar
         control={control}
         name='date'
         // defaultValue={filters.date}
         placeholder={t("UI.DateAndTimePicker.date", "Дата")}
         minDate={moment().toDate()}
         error={errors?.date?.message}
      />
   ),
   date_from: (
      <SelectUnderControl
         name='date_from'
         setValue={setValue}
         error={errors?.date_from?.message}
         defaultSelectValue={filters.date_from}
         options={renderStartTimeOptions()}
         control={control}
         label={t("UI.DateAndTimePicker.date-from", "Начало")}
      />
   ),
   duration: (
      <SelectUnderControl
         name='duration'
         setValue={setValue}
         defaultSelectValue={filters.duration}
         options={renderDurationOptions()}
         control={control}
         label={t("UI.DateAndTimePicker.duration", "Длительность")}
      />
   ),
   rating: (
      <SelectUnderControl
         name='rating'
         setValue={setValue}
         defaultSelectValue={filters.rating}
         options={ratingOptions}
         control={control}
         label={t(
            "SearchFilters.teacher-filters.labels.teacher-rating",
            "Рейтинг преподавателя"
         )}
      />
   ),
});
