/* LTools */
import { makeRequestAndResolveConstructor } from "./core/core methods/makeRequestAndResolve";
import { methodResolverConstructor } from "./core/core methods/methodResolver";
import { pingRequest } from "./core/simple methods/pingRequest";
import { callCallbacks } from "./core/simple methods/callCallbacks";
import { errorsMapperConstructor } from "./core/simple methods/errorsMapper";
import { ErrorType } from "./core/types/types";
import { responseHandlerConstructor } from "./core/core methods/responseHandler";
import { useLocalCommonState } from "./core/hooks/useLocalCommonState";

/* errorMapper */
export const errorMapper = errorsMapperConstructor((response) => {
   const errorsFromServer = response.data.data.errors;
   if (!Array.isArray(errorsFromServer)) return {};

   let errorsObject: ErrorType = {};
   errorsFromServer.forEach((item: ErrorType) => {
      const indexOfDots = item.message.indexOf(":");
      errorsObject[item.field] = indexOfDots
         ? item.message.substr(indexOfDots + 2)
         : item.message;
   });
   return errorsObject;
});

/* MethodResolverConstructor */
export const methodResolver = methodResolverConstructor({
   // Колбек смотрит на статус и вызывается по статусу
   internalServerStatusCallback: (props) => {
      if (props?.requestMethod == "get") {
         pingRequest(
            props.request,
            (response: any) => {
               callCallbacks({
                  methods: props.methods,
                  callbacks: props.callbacks,
                  response,
               });
            },
            () => {
               // alert("Server error");
            }
         ).finally();
      } // } else {
      //     alert("Server error");
      // }
   },
});

/* MakeRequestAndResolve */

// Обертка, в которой
const makeRequestAndResolve = makeRequestAndResolveConstructor({
   nonResponseCallback: () => alert("error"),
});
const responseHandler = responseHandlerConstructor({
   nonResponseCallback: () => alert("error"),
});

/* Config */
export const LToolsConfig = {
   ping: {
      timeout: 8000,
      iterations: 3,
   },
};

export const LTools = {
   makeRequestAndResolve,
   responseHandler,
   useLocalCommonState,
};
