import * as React from "react";

export function TextLock(props: React.SVGProps<SVGSVGElement>) {
   return (
      <svg
         width={24}
         height={25}
         viewBox='0 0 24 25'
         fill='none'
         xmlns='http://www.w3.org/2000/svg'
         {...props}
      >
         <rect x={1} y={1.676} width={22} height={22} rx={11} fill='#E8E8E8' />
         <path
            d='M15 10.342h-.5V9.295c0-1.446-1.12-2.62-2.5-2.62S9.5 7.85 9.5 9.296v1.047H9c-.553 0-1 .47-1 1.048v5.238c0 .579.447 1.048 1 1.048h6c.553 0 1-.469 1-1.048V11.39c0-.579-.447-1.048-1-1.048zm-3 4.715c-.553 0-1-.47-1-1.048 0-.579.447-1.048 1-1.048.553 0 1 .47 1 1.048 0 .579-.447 1.048-1 1.048zm1.55-4.715h-3.1V9.295c0-.896.695-1.624 1.55-1.624.855 0 1.55.728 1.55 1.624v1.047z'
            fill='#000'
         />
      </svg>
   );
}
