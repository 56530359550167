import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StateType } from "../index";
import { applicationType, breadCrumbsType } from "./types";
import {
   educationSystemType,
   gradeType,
   languageType,
} from "../formsDataSlice/types";
import { subjectType } from "../types";

export const filtersStageResolver = {
   level: 0,
   language: 0,
   educationSystem: 1,
   subject: 2,
};

type filtersDataType = {
   language?: languageType;
   level?: gradeType;
   educationSystem?: educationSystemType;
   subject?: subjectType;
};
type initialStateType = {
   currentApplication: applicationType;
   filtersState: {
      stage: number;
      data: filtersDataType;
      isFast: boolean;
   };
   rejected: boolean;
   chosenTeacherUrl?: string;
};
const initialState: initialStateType = {
   currentApplication: {} as applicationType,
   filtersState: {
      stage: 0,
      data: {},
      isFast: false,
   },
   rejected: false,
};

export const fastLessonSlice = createSlice({
   name: "@fastLessonSlice",
   initialState,
   reducers: {
      setCurrentApplication: (state, { payload }: PayloadAction<any>) => {
         state.currentApplication = payload;
      },
      setFiltersStage: (state, { payload }: PayloadAction<number>) => {
         state.filtersState.stage = payload;
      },
      clearFilters: (state) => {
         state.filtersState.data = {};
      },
      setIsFast: (state, { payload }: PayloadAction<boolean>) => {
         state.filtersState.isFast = payload;
      },
      reject: (state, { payload }: PayloadAction<boolean>) => {
         state.rejected = payload;
      },
      setUrlOfTeacher: (
         state,
         { payload }: PayloadAction<string | undefined>
      ) => {
         state.chosenTeacherUrl = payload;
      },
      setFiltersData: (
         state,
         {
            payload,
         }: PayloadAction<
            {
               [key in keyof filtersDataType]: filtersDataType[key];
            }
         >
      ) => {
         state.filtersState.data = {
            ...state.filtersState.data,
            ...payload,
         };
      },
      clear: () => {
         return { ...initialState };
      },
   },
});

export const fastLessonSliceSelectors = {
   getCurrentApplication: (state: StateType) =>
      state.fastLessonSlice.currentApplication,
   getIsFast: (state: StateType) => state.fastLessonSlice.filtersState.isFast,
   getFiltersState: (state: StateType) => state.fastLessonSlice.filtersState,
   getChosenTeacherUrl: (state: StateType) =>
      state.fastLessonSlice.chosenTeacherUrl,
};
