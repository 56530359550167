import moment from "moment-timezone";
import { DialogType } from "store/chatSlice";

export function sortDate(a: DialogType, b: DialogType) {
   let aDate = moment(a.last_message_data).toDate();
   let bDate = moment(b.last_message_data).toDate();
   if (aDate < bDate) return 1;
   else if (aDate > bDate) return -1;
   else return 0;
}
