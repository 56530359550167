import { createRequest } from "../../core/LTools/core/apiMethods/createRequest";
import { mainAxios } from "../index";
import {
   createEventType,
   deleteEventChainType,
   deleteSlotType,
   getEventType,
   getSlotType,
} from "./types";
import { dataToQueryString } from "core/LTools/core/apiMethods/dataToQueryString";

export const eventSlotApi = {
   createEvent: async ({ user_id, ...payload }: createEventType) => {
      return createRequest(mainAxios.post(`/user/${user_id}/event`, payload));
   },

   deleteEventChain: async ({
      user_id,
      event_id,
      slot_id,
   }: deleteEventChainType) => {
      return createRequest(
         mainAxios.delete(`/user/${user_id}/event/${event_id}/slot/${slot_id}`)
      );
   },

   getSlot: async ({ user_id, ...query }: getSlotType) => {
      return createRequest(
         mainAxios.get(`/user/${user_id}/slot${dataToQueryString(query)}`)
      );
   },
   getEventById: async ({ user_id }: getEventType) => {
      return createRequest(mainAxios.get(`/user/${user_id}/event`));
   },

   deleteSlot: async ({ slot_id, user_id }: deleteSlotType) => {
      return createRequest(
         mainAxios.delete(`/user/${user_id}/slot/${slot_id}`)
      );
   },
};
