import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import * as yup from "yup";
import axios, { CancelTokenSource } from "axios";
import { yupResolver } from "@hookform/resolvers/yup";

export namespace filtersTypes {
    export type limitOffset = {
        limit: number;
        offset: number;
    };
    export type amount = {
        limit: number;
        offset: number;
    };
    export type date = {
        date_to?: string;
        date_from: string;
    };
    export type dateAmountLimitUnion = limitOffset & amount & date;
}

export type taxationType = "usn" | "esxn" | "osn" | "envd";

export type vatRateType = "0%" | "10%" | "18%" | "20%" | "without_vat";

type useFiltersPropsType<T> = {
    initialState: any;
    defaultValues: {
        [key in keyof filtersTypeCreator<T>]: any;
    };
    queryKey: string;
    queryFn: (data: any, cancelToken?: CancelTokenSource) => Promise<any>;
    scheme?: any;
    deps?: any;
    resetDeps?: Array<any>;
};

export type filtersTypeCreator<T> = {
    [key in keyof T]: any;
};

/**
 * @Props
 * initialState фильтров,
 * defaultValues полей для сброса,
 * queryKey полей для useQuery,
 * queryFn апи метод получения данных
 * @Result
 * isLoading,
 * applyFiltersHandler,
 * resetHandler,
 * showMoreHandler,
 * data,
 * form ( hook-form )
 * */
//@TODO вернуть hasMoreItems
export function useFilters<T, DataType>({
                                            initialState,
                                            defaultValues,
                                            queryKey,
                                            queryFn,
                                            scheme,
                                            deps,
                                            resetDeps,
                                        }: useFiltersPropsType<T>) {
    const cancelToken = useRef<CancelTokenSource>();
    const [data, setData] = useState<Array<DataType>>([]);
    const [didMount, setDidMount] = useState(false);
    const [itemsCount, setItemsCount] = useState<number>(0);
    const [filters, setFilters] = useState<
        filtersTypeCreator<T> & filtersTypes.limitOffset
        >(initialState);

    const form = useForm<any>({
        defaultValues,
        mode: "onChange",
        resolver: scheme ? yupResolver(scheme) : scheme,
    });

    const refreshToken = () => {};

    const { isLoading, mutate } = useMutation({
        mutationFn: (filters: any) => {
            console.log("filters", filters);
            return queryFn(filters, cancelToken.current);
        },
        onSuccess: (response: any) => {
            setData((prev) => response?.data?.data?.items);
            setItemsCount(response?.data?.data?.items_count);
        },
        onError: (E) => {
            console.log(E);
        },
    });

    useEffect(() => {
        if (didMount) {
            resetHandler();
        }
    }, resetDeps || []);

    const mutateProxy = (data: any) => {
        cancelToken.current?.cancel();
        const token = axios.CancelToken.source();
        cancelToken.current = token;

        mutate(data);
    };

    const applyFiltersHandler = (filters: any) => {
        setData([]);
        setFilters({
            ...filters,
            offset: initialState?.offset || 0,
            limit: initialState?.limit || 10,
        });
    };

    const resetHandler = () => {
        setData([]);
        setItemsCount(0);
        setFilters({ ...initialState });
        form.reset();
    };


    const pageChangeHandler = (offset: number) => {
        setFilters((prev: any) => {
            return {
                ...prev,
                limit: initialState?.limit || 10,
                offset: prev.offset + initialState?.limit,
            };
        });
    };

    const refetch = () => {
        refreshToken();
        setData([]);
        setItemsCount(0);
        mutateProxy(filters);
    };

    useEffect(() => {
        refreshToken();
        mutateProxy(filters);
    }, [filters]);

    useEffect(() => {
        setDidMount(true);
    }, []);

    useEffect(() => {
        if (didMount) {
            resetHandler();
        }
    }, [deps]);

    console.log("filters", filters);

    return {
        setFilters,
        // hasMoreItems: filters.offset + filters.limit <= itemsCount,
        isLoading: isLoading,
        applyFiltersHandler,
        resetHandler,
        // showMoreHandler,
        data,
        form,
        refetch,
        itemsCount,
    };
}
