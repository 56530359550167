import { useEffect, useState } from "react";
import { authThunks } from "../../store/authSlice/thunks";

export const useGoogleAuthInit = () => {
  const [tokenClient, setTokenClient] = useState({});

   // useEffect(() => {
   //    // @ts-ignore
   //    // window.gapi.load("auth2", function () {
   //    //    // @ts-ignore
   //    //    window.gapi.auth2.init({
   //    //       client_id:
   //    //          "1021962961458-8egrbfrba862nrrdfnijr496ln41neb9.apps.googleusercontent.com",
   //    //       scope: "email profile openid",
   //    //       response_type: "id_token permission email",
   //    //    });
   //    // });
   // }, []);
   // useEffect(() => {
   //    // console.log("EFFECT")
   //    // @ts-ignore
   //    window.google.accounts.id.initialize({
   //       client_id: "367188503702-ant22llp1pkqabi53mkb27kfhg5r18jb.apps.googleusercontent.com",
   //       callback: (response: any) => console.log("Encoded JWT ID token: " + response.credential),
   //    })
   //
   //
   //    // @ts-ignore
   //    // window.gapi.load("auth2", function () {
   //    //    // @ts-ignore
   //    //    window.gapi.auth2.init({
   //    //       client_id:
   //    //          "1021962961458-8egrbfrba862nrrdfnijr496ln41neb9.apps.googleusercontent.com",
   //    //       scope: "email profile openid",
   //    //       response_type: "id_token permission email",
   //    //    });
   //    // });
   //    window.google.accounts.id.renderButton(
   //      document.getElementById("signInDiv"),
   //      { theme: "outline", size: "large" }
   //    )
   //
   //    setTokenClient(    // @ts-ignore
   //      window.google.accounts.oauth2.initTokenClient({
   //         client_id: "367188503702-ant22llp1pkqabi53mkb27kfhg5r18jb.apps.googleusercontent.com",
   //         scope: "https://www.googleapis.com/auth/drive",
   //         callback: (tokenResponse: any) => {
   //            console.log(tokenResponse)
   //            console.log(tokenResponse.access_token)
   //
   //            commonState.dispatch(
   //              authThunks.googleAuth({
   //                 google_token: tokenResponse.access_token,
   //                 commonState,
   //              })
   //            );
   //         }
   //      }))
   //
   //    // google.accounts.id.renderButton(
   //    //   document.getElementById("signInDiv")
   //    // )
   // }, []);
};
