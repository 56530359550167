import React, { FC, useRef, useState } from "react";
import "./style.css";
import { useForm, useWatch } from "react-hook-form";
import { useBlurred } from "../../hooks/useBlurred";
import { Calendar } from "components/UI/Calendar";
import { getTimeDifference } from "helpers/date";
import moment from "moment-timezone";

type propsType = {};
type inputsCheckFormType = {
   date: string;
};

type LessonType = {
   name: string;
   leng: string;
   rate: number;
};

const data: LessonType[] = [{ rate: 5, leng: "123", name: "keks" }];
export const SandBox: FC<propsType> = (props) => {
   const {
      control,
      handleSubmit,
      register,
      setValue,
      watch,
   } = useForm<inputsCheckFormType>();
   const [isOpen, setOpen] = useState<boolean>(true);
   const calendarRef = useRef<HTMLDivElement | null>();
   useBlurred(calendarRef, setOpen, isOpen);

   return (
      <div
         style={{
            marginTop: "200px",
            width: "292px",
         }}
      >
         <Calendar
            control={control}
            name='date'
            placeholder={"Дата рождения"}
         />
      </div>
   );
};

export const SelectTimeItem = ({ time }: { time: string }) => {
   return <div>{time}</div>;
};

export const LessonItem = (item: LessonType) => {
   return (
      <tr>
         <td>asd</td>
         <td>{item.name}</td>
         <td>{item.leng}</td>
         <td>{item.rate}</td>
      </tr>
   );
};
