import * as yup from "yup";
import { addMethodToUpdateLang } from "../../../../../../services/translateService/useLangInit";
import i18n from "../../../../../../services/translateService/i18n";
export let registerFastLessonScheme: any;

addMethodToUpdateLang(() => {
   registerFastLessonScheme = yup.object().shape({
      time: yup
         .object()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         ),
      grade: yup
         .object()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         ),
      topic: yup
         .string()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         ),
   });
});
