import { Control, UseFormSetValue } from "react-hook-form";
import { SelectUnderControl } from "components/UI/Select/SelectUnderControl";
import React from "react";
import { Input } from "../../../../../../../UI/Input";

export const getAdminUsersFilterElements = ({
   setValue,
   control,
   t,
}: {
   control: Control<any>;
   setValue: UseFormSetValue<any>;
   t: any;
}) => ({
   name: (
      <Input
         name={"name"}
         control={control}
         label={t("Notebook.headers.name", "Имя")}
      />
   ),
   userRole: (
      <SelectUnderControl
         name={"role"}
         setValue={setValue}
         clearable
         control={control}
         variant={"blue-bg"}
         label={t("Common.userRole.title", "Роль")}
         options={[
            {
               id: 1,
               text: t("Common.userRole.user", "Пользователь"),
               value: "user",
            },
            {
               id: 2,
               text: t("Lk.Profile.role.admin", "Админ"),
               value: "admin",
            },
            {
               id: 3,
               text: t("Lk.Profile.role.super-admin", "Супер админ"),
               value: "superadmin",
            },
         ]}
      />
   ),
   userType: (
      <SelectUnderControl
         name={"type"}
         setValue={setValue}
         clearable
         control={control}
         variant={"blue-bg"}
         label={t("Common.userType", "Тип учётной записи")}
         options={[
            {
               id: 1,
               text: t(
                  "Lk.LkProfile.RoleSwitcher.teacher-button",
                  "Преподаватель"
               ),
               value: "teacher",
            },
            {
               id: 2,
               text: t("Lk.LkProfile.RoleSwitcher.student-button", "Студент"),
               value: "student",
            },
         ]}
      />
   ),
});
