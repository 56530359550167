// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Rates_Rates__2P1a9 {\ndisplay: flex;\n    width: -moz-fit-content;\n    width: fit-content;\n    flex-direction: row-reverse;\n}\n.Rates_Rates__2P1a9 [data-class=\"total\"] {\n    margin-right: 5px;\n}\n.Rates_Rates__2P1a9 input[type=\"radio\"] {\n    appearance: none;\n    -webkit-appearance: none;\n    opacity: 0;\n    display: none;\n}\n.Rates_Rates__2P1a9 label svg path  {\n    transition: .2s;\n}\n\n\n.Rates_Rates__2P1a9 input[type=\"radio\"]:checked ~ .Rates_rating__qbkq7 svg path {\n    fill: #0741AD;\n    opacity: 1;\n    transition: .2s;\n}\n", "",{"version":3,"sources":["webpack://src/components/UI/Rates/Rates.module.css"],"names":[],"mappings":"AAAA;AACA,aAAa;IACT,uBAAkB;IAAlB,kBAAkB;IAClB,2BAA2B;AAC/B;AACA;IACI,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,wBAAwB;IACxB,UAAU;IACV,aAAa;AACjB;AACA;IACI,eAAe;AACnB;;;AAGA;IACI,aAAa;IACb,UAAU;IACV,eAAe;AACnB","sourcesContent":[".Rates {\ndisplay: flex;\n    width: fit-content;\n    flex-direction: row-reverse;\n}\n.Rates [data-class=\"total\"] {\n    margin-right: 5px;\n}\n.Rates input[type=\"radio\"] {\n    appearance: none;\n    -webkit-appearance: none;\n    opacity: 0;\n    display: none;\n}\n.Rates label svg path  {\n    transition: .2s;\n}\n\n\n.Rates input[type=\"radio\"]:checked ~ .rating svg path {\n    fill: #0741AD;\n    opacity: 1;\n    transition: .2s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Rates": "Rates_Rates__2P1a9",
	"rating": "Rates_rating__qbkq7"
};
export default ___CSS_LOADER_EXPORT___;
