// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ComplaintPopup_container__3FPBJ {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    padding-top: 30px;\n}\n\n.ComplaintPopup_button__1_ZEX {\n    margin-top: 30px;\n}\n\n.ComplaintPopup_close__2jXHK {\n    position: absolute;\n    right: 20px;\n    top: 20px;\n    cursor: pointer;\n}\n\n.ComplaintPopup_textarea__3n0WU {\n    width: 292px;\n    border-radius: 5px;\n    min-height: 100px;\n    padding: 10px;\n    border: 1px solid var(--color-blue3);\n    max-height: 300px;\n    overflow: scroll;\n}", "",{"version":3,"sources":["webpack://src/components/ModalWindow/templates/ComplaintPopup/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,aAAa;IACb,oCAAoC;IACpC,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    padding-top: 30px;\n}\n\n.button {\n    margin-top: 30px;\n}\n\n.close {\n    position: absolute;\n    right: 20px;\n    top: 20px;\n    cursor: pointer;\n}\n\n.textarea {\n    width: 292px;\n    border-radius: 5px;\n    min-height: 100px;\n    padding: 10px;\n    border: 1px solid var(--color-blue3);\n    max-height: 300px;\n    overflow: scroll;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ComplaintPopup_container__3FPBJ",
	"button": "ComplaintPopup_button__1_ZEX",
	"close": "ComplaintPopup_close__2jXHK",
	"textarea": "ComplaintPopup_textarea__3n0WU"
};
export default ___CSS_LOADER_EXPORT___;
