import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { Message } from "./components/Message";
import { TextArea } from "components/UI/TextArea";
import { useForm, useWatch } from "react-hook-form";
import moment from "moment-timezone";
import { ReactComponent as Files } from "../../assets/files.svg";
import { ReactComponent as Alert } from "../../assets/alert.svg";
import { ReactComponent as AlertFill } from "../../assets/alertFill.svg";
import { NewFile } from "./components/NewFile";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { chatSlice, chatSliceSelectors, MessageType } from "store/chatSlice";
import { useTranslation } from "react-i18next";
import { ChatThunk } from "store/chatSlice/thunk";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { lkSliceSelectors } from "store/lkSlice";
import { LoadMessage } from "components/Lk/Pages/Messages/components/MessagesArea/components/LoadMessage";
import { Preloader } from "../../../../../UI/Preloader";
import { useScrollLimitExtend } from "../../../../../../hooks/useScrollLimitExtend";
import { ReportPop } from "../ReportPop";
import { routes } from "../../../../../../routes";

type FormType = {
   message: string;
};

type MessagesAreaType = {
   chatId: number;
   isChatSent: boolean;
   adminNameToGetComplaint: {
      id?: number;
      name?: string;
      chat_id?: number;
   };
};

var needPing: {
   [key: number]: boolean;
} = {};

export const MessagesArea: FC<MessagesAreaType> = (props) => {
   const { t } = useTranslation();
   const { chatId } = props;

   const chatsMessages = useSelector(chatSliceSelectors.getChatsMessages);
   const chatsMessages_count = useSelector(
      chatSliceSelectors.getChatsMessagesCount
   );
   const loadingOldMessages = useSelector(
      chatSliceSelectors.getLoadingOldMessages
   );
   const myUser = useSelector(lkSliceSelectors.getUserData);
   const dispatch = useDispatch();

   const { control, handleSubmit, setValue } = useForm<FormType>();

   /**
    *
    * */

   const [files, setFiles] = useState<{ content: string; file_name: string }[]>(
      []
   );

   const imageHandler = (eventInput: any) => {
      if (eventInput.target.files[0]) {
         eventInput.preventDefault();
         const reader = new FileReader();
         reader?.readAsDataURL(eventInput.target.files[0]);
         reader.onload = () => {
            if (reader.readyState === 2) {
            }
         };
         reader.onloadstart = () => {};
         reader.onloadend = (e) => {
            setFiles((prevState) => [
               ...prevState,
               {
                  content: String(reader.result),
                  file_name: String(eventInput.target.files[0].name),
               },
            ]);
         };
      }
   };

   function deleteFile(id: number) {
      setFiles((prevState) => prevState.filter((item, index) => index !== id));
   }

   /**
    * при изменении chatsMessages скролит до конца
    * */
   const needScrollBottom = useSelector(chatSliceSelectors.getNeedScrollBottom);

   function scrollToBottom() {
      let objDiv = document.getElementById("area_messages");
      // @ts-ignore
      objDiv.scrollTop = objDiv.scrollHeight;
   }

   useEffect(() => {
      needScrollBottom && scrollToBottom();
      needScrollBottom &&
         commonState.dispatch(chatSlice.actions.setNeedScrollBottom(false));
   }, [needScrollBottom]);

   /**
    * submit, внтури проверка на ( не пустое сообщение или есть хотя бы 1 файл )
    * */

   const watchTextMessage = useWatch({
      control,
      name: "message",
      defaultValue: "",
   });

   function submit() {
      if (!Boolean(watchTextMessage.trim()) && !files.length) {
         return;
      }
      console.log("sub");
      if (
         (!/^\s+$/.test(watchTextMessage) && watchTextMessage !== "") ||
         files.length > 0
      ) {
         setLoadingSendMessage(true);
         commonState.dispatch(
            ChatThunk.creatNewMessage({
               message: {
                  message: watchTextMessage,
                  files:
                     files.length > 0
                        ? files.map((item) => item.content)
                        : undefined,
                  message_type: "message",
               },
               chat_id: chatId,
               commonState,
               callbacks: {
                  "201": () => {
                     setLoadingSendMessage(false);
                  },
                  "400": () => {
                     setLoadingSendMessage(false);
                  },
               },
            })
         );
         setFiles([]);
         setValue("message", "");
      }

      // try {
      //    var audio = new Audio(); // Создаём новый элемент Audio
      //    audio.src = 'https://zvukogram.com/mp3/cats/249/sumasshedshiy-krik.mp3'; // Указываем путь к звуку "клика"
      //    audio.autoplay = true; // Автоматически запускаем
      // }
      // catch {
      //    alert("123")
      // }
   }

   /**
    *
    * */
   const commonState = useLocalCommonState();
   const [initialTimeOutId, setInitialTimeOutId] = useState<number>();

   useEffect(() => {
      needPing[chatId] = true;
      getNewMessage({});
      // setInterval(() => {
      //    setValue("message", "test ping 1s");
      //    handleSubmit(submit)();
      // }, 1000);
   }, []);

   function getNewMessage(props: {
      ping?: boolean;
      limit?: string;
      offset?: string;
   }) {
      const { ping = true, limit = "50", offset = "0" } = props;
      commonState.dispatch(
         ChatThunk.getMessageByChatId({
            commonState,
            callbacks: {
               "200": () => {
                  if (needPing[chatId] && ping) {
                     let timeOutId = setTimeout(() => {
                        getNewMessage({});
                     }, 10000);
                     setInitialTimeOutId(Number(timeOutId));
                  }
               },
            },
            chat_id: chatId,
            offset: offset,
            limit: limit,
         })
      );
   }

   useEffect(() => {
      return () => {
         needPing[chatId] = false;
         window.clearTimeout(initialTimeOutId);
      };
   }, []);

   function keySendMessage(e: React.KeyboardEvent<HTMLTextAreaElement>) {
      if (e.key === "Enter") {
         e.preventDefault();
         e.stopPropagation();
         submit();
      }
   }

   /**
    *
    * */

   const {
      limit,
      updateLimitOnScroll,
      resetLimit,
      setBlockedScrollState,
   } = useScrollLimitExtend({
      step: 50,
      difference: 400,
      reverse: true,
   });

   useEffect(() => {
      limit !== 50 &&
         commonState.dispatch(chatSlice.actions.setLoadingOldMessages(true));
      limit !== 50 &&
         getNewMessage({
            limit: "50",
            offset: String(limit - 50),
            ping: false,
         });
   }, [limit]);

   useEffect(() => {
      !loadingOldMessages && setBlockedScrollState(false);
   }, [loadingOldMessages]);

   const [loadingSendMessage, setLoadingSendMessage] = useState<boolean>(false);

   /**
    *
    **/

   const [isOpenReportPop, setIsOpenReportPop] = useState<number | undefined>();

   function closeReportPop() {
      setIsOpenReportPop(undefined);
   }
   function onClickReport(message_id: number) {
      setIsOpenReportPop(message_id);
   }

   function compareMessage(a: MessageType, b: MessageType) {
      if (parseToDate(a.date) > parseToDate(b.date)) return 1;
      if (parseToDate(a.date) == parseToDate(b.date)) return 0;
      if (parseToDate(a.date) < parseToDate(b.date)) return -1;
      return 0;
   }

   function parseToDate(time: string) {
      let date = moment(time).toDate();
      return date;
   }

   // console.log(loadingSendMessage);
   return (
      <div className={styles.container}>
         {chatsMessages?.[chatId]?.length == 0 && (
            <div className={styles.empty_message}>
               <p className={styles.empty_message_title}>
                  {t("Messages.empty-chat", "В чате нет сообщений")}
               </p>
            </div>
         )}
         <div className={styles.wrapper}>
            <div
               onScroll={
                  chatsMessages_count[chatId] > limit && !loadingOldMessages
                     ? updateLimitOnScroll
                     : undefined
               }
               id={"area_messages"}
               className={styles.area}
            >
               {loadingOldMessages && (
                  <div className={styles.preloaderOldMessage}>
                     <Preloader
                        style={{ height: "30px", width: "30px" }}
                        loading={loadingOldMessages}
                     />
                  </div>
               )}
               {chatsMessages[chatId] &&
                  chatsMessages[chatId]
                     .slice(0)
                     .sort(compareMessage)
                     .map((item, index) => (
                        <Message
                           onClickReport={onClickReport}
                           key={item.id}
                           chat_id={chatId}
                           owner={item.user.id == myUser.id}
                           item={item}
                        />
                     ))}
            </div>
            <div className={styles.area}>
               {!chatsMessages[chatId] &&
                  Array(10)
                     .fill("")
                     .map((item, index) => <LoadMessage key={index} />)}
            </div>
         </div>
         <div className={styles.new_message}>
            {props.isChatSent &&
            chatId == props.adminNameToGetComplaint.chat_id ? (
               <div className={styles.complaint_sent}>
                  {t("Messages.sent_complaint", "Жалоба была передана")}{" "}
                  <div
                     className={styles.complaint_sent_name}
                     onClick={() => {
                        commonState.location =
                           routes.lk.lkStudent +
                           "/" +
                           props.adminNameToGetComplaint.id;
                     }}
                  >
                     {props.adminNameToGetComplaint.name}
                  </div>
               </div>
            ) : (
               <>
                  <div className={styles.top}>
                     <label
                        style={
                           files.length < 3
                              ? { cursor: "pointer" }
                              : { cursor: "not-allowed" }
                        }
                        htmlFor={"file"}
                        className={styles.left}
                     >
                        <Files />
                        <input
                           type='file'
                           className={styles.fileInput}
                           id={files.length < 3 ? "file" : "none"}
                           onChange={(event) => {
                              imageHandler(event);
                           }}
                        />
                     </label>
                     <form
                        onSubmit={handleSubmit(submit)}
                        id={"NewMessage"}
                        className={styles.middle}
                     >
                        <TextArea
                           onKeyDown={keySendMessage}
                           placeholder={t(
                              "Messages.enter-message",
                              "Введите сообщение"
                           )}
                           control={control}
                           name='message'
                        />
                     </form>
                     {!loadingSendMessage ? (
                        <button
                           type={"submit"}
                           form={"NewMessage"}
                           className={styles.right}
                        >
                           {files.length > 0 || watchTextMessage !== "" ? (
                              <AlertFill />
                           ) : (
                              <Alert />
                           )}
                        </button>
                     ) : (
                        <Preloader
                           className={styles.preloadSendMessage}
                           loading={loadingSendMessage}
                        />
                     )}
                  </div>
                  <div
                     className={cn(styles.bottom, {
                        [styles["bottom-open"]]: files.length > 0,
                     })}
                  >
                     {files.map((item, index) => (
                        <NewFile
                           key={index}
                           id={index}
                           fileName={item.file_name}
                           onDelete={deleteFile}
                        />
                     ))}
                  </div>
               </>
            )}
         </div>
         {isOpenReportPop ? (
            <ReportPop
               closeReportPop={closeReportPop}
               user_id={myUser.id}
               message_id={isOpenReportPop}
            />
         ) : null}
      </div>
   );
};
