// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__3lqiW {\n  font-family: var(--font-Source-Sans-Pro);\n  margin-bottom: 10px;\n}\n\n.style_title__1PNI6 {\n  font-size: 14px;\n  font-weight: 600;\n  margin-bottom: 4px;\n}\n\n.style_count__2GZNr {\n  font-size: 28px;\n  margin-right: 20px;\n}\n\n.style_rate__3k_3t {\n  display: flex;\n  align-items: center;\n}\n\n.style_stars__2g44O {\n  margin-left: 23px;\n}\n\n.style_star_icon__GFBMu {\n  width: 26px;\n  height: 26px;\n  color: var(--color-black3);\n}", "",{"version":3,"sources":["webpack://src/components/Lk/components/Notifications/components/Rating/style.module.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,0BAA0B;AAC5B","sourcesContent":[".container {\n  font-family: var(--font-Source-Sans-Pro);\n  margin-bottom: 10px;\n}\n\n.title {\n  font-size: 14px;\n  font-weight: 600;\n  margin-bottom: 4px;\n}\n\n.count {\n  font-size: 28px;\n  margin-right: 20px;\n}\n\n.rate {\n  display: flex;\n  align-items: center;\n}\n\n.stars {\n  margin-left: 23px;\n}\n\n.star_icon {\n  width: 26px;\n  height: 26px;\n  color: var(--color-black3);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__3lqiW",
	"title": "style_title__1PNI6",
	"count": "style_count__2GZNr",
	"rate": "style_rate__3k_3t",
	"stars": "style_stars__2g44O",
	"star_icon": "style_star_icon__GFBMu"
};
export default ___CSS_LOADER_EXPORT___;
