import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { useQuery } from "hooks/useQuery";
import { lessonApi } from "api/lessonApi/methods";
import { CardsGroup } from "components/UI/CardsGroup";
import { eventSlotApi } from "api/eventSlotApi/methods";
import { useLkContext } from "../../../index";
import { groupLessonApi } from "api/groupLessonApi/methods";
import { inviteApi } from "api/inviteApi/methods";
import { useTranslation } from "react-i18next";
import { InfoBlock } from "components/UI/InfoBlock";
import { ProfileCardsWrapper } from "components/Lk/Pages/LkProfile/components/ProfileCardsWrapper";
import { applicationApi } from "api/applicationApi/methods";
import { useDispatch, useSelector } from "react-redux";
import { authSliceSelectors } from "store/authSlice";
import {
   groupLessonType,
   inviteType,
   lessonType,
} from "types/LessonService/types";
import { suggestionType } from "store/fastLessonSlice/types";
import moment from "moment-timezone";
import { ReactComponent as ClassesIcon } from "../../../../../../../assets/buttons/MenuClassesIcon.svg";
import { useHistory } from "react-router-dom";
import { routes } from "../../../../../../../routes";
import { uiSlice } from "../../../../../../../store/modalWindowSlice";
type propsType = {};
export const LkTeacherOwn: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const { profileData } = useLkContext();
   const user_id = useSelector(authSliceSelectors.getId);
   const history = useHistory();
   const dispatch = useDispatch();
   /* fetch layer */
   const lessonsResponse = useQuery(
      () =>
         lessonApi.getLessons({
            limit: "100",
            offset: "0",
            role: "teacher",
            lesson_statuses: ["new_lesson"],
            date_from: moment().utcOffset("+0000").format("YYYY-MM-DD"),
         }),
      {
         serializer: (res) => res?.data?.data?.items as Array<lessonType>,
      }
   );

   const [isDisplaySlotWarning, setIsDisplaySlotWarning] = useState(false);
   const slotResponse = useQuery(
      () =>
         eventSlotApi.getSlot({
            user_id: String(profileData?.id),
            date_start_order_by: "asc",
            limit: "100",
            offset: "0",
         }),
      {
         condition: !!profileData?.id,
         serializer: (res) => {
            if (!res?.data?.data?.items?.length) {
               setIsDisplaySlotWarning(true);
            }
            return res?.data?.data?.items as any;
         },
      }
   );


    // const filteredSlot = slotResponse?.data?.filter((item: any) => {
    //     return item.invites && item.invites.length > 0 && item.invites[0].status === "payed_invite";
    // });


   const groupLessonsResponse = useQuery(
      () =>
         groupLessonApi.getGroupLessons({
            user_id,
            role: "teacher",
            limit: "100",
            offset: "0",
            date_from: moment().utcOffset("+0000").format("YYYY-MM-DD"),
            lesson_statuses: ["new_group_lesson"],
         }),
      {
         serializer: (res) => {
            return res?.data?.data?.items as Array<groupLessonType>;
         },
      }
   );
   const suggestionsResponse = useQuery(
      () => {
         return applicationApi.getSuggestions({
            user_id,
            limit: "100",
            offset: "0",
            suggestion_statuses: ["new_suggestion"],
         });
      },
      {
         serializer: (res) => res?.data?.data?.items as Array<suggestionType>,
      }
   );
   const invitesResponse = useQuery(
      () => {
         return inviteApi.getInvite({
            limit: "100",
            offset: "0",
            role: "teacher",
            // invite_statuses: ["new_invite"],
         });
      },
      {
         serializer: (res) => res?.data?.data?.items as Array<inviteType>,
      }
   );

   const generateRequests = () => {
      const invites =
         invitesResponse?.data?.map((item: any) => ({
            ...item,
            type: "invite",
         })) || [];

      const filteredInvites = invites.filter((invite) => invite.status !== "new_invite" && invite.status !== "rejected_invite" && invite.status !== "approved_invite");

      const suggestions =
         suggestionsResponse.data?.map((item: any) => ({
            ...item,
            type: "suggestion",
         })) || [];
      return [...suggestions, ...filteredInvites];
   };
   // useEffect(() => {
   //    if (isDisplaySlotWarning) {
   //       dispatch(
   //          uiSlice.actions.setModalType({
   //             template: "create_slot_warning",
   //             payload: {},
   //          })
   //       );
   //    }
   // }, [slotResponse.data]);
   return (
      <div>
         <InfoBlock
            variant='bold'
            infoKey={t("Lk.LkTeacherOwn.subjects", "Предметы")}
         >
            {profileData.instruction_subjects.map((item) => (
               <span>{item.name} </span>
            ))}
         </InfoBlock>
         <ProfileCardsWrapper
            title={t("Lk.LkTeacherOwn.subjects-picker-label", "Занятия")}
         >
            {isDisplaySlotWarning && (
               <>
                  {/*<div className={styles.slots_warning}>*/}
                  {/*   {t(*/}
                  {/*      "Lk.LkTeacherOwn.slot-warning",*/}
                  {/*      "Добавьте занятия, чтобы студенты могли записываться к вам"*/}
                  {/*   )}*/}
                  {/*</div>*/}
                  <button
                     className={styles.add_lesson}
                     onClick={() => {
                        history.push(routes.lk.lesson.create_slot);
                     }}
                  >
                     <ClassesIcon className={styles.add_lesson_icon} />
                     {t(
                        "Lk.lesson-service.add-lesson.title",
                        "Добавить занятие"
                     )}
                  </button>
               </>
            )}
            <CardsGroup
               role='teacher'
               isLoading={
                  suggestionsResponse.isLoading || invitesResponse.isLoading
               }
               elements={generateRequests()}
               title={t(
                  "Lk.LkTeacherOwn.tickets.pending-applications",
                  "Заявки в ожидании"
               )}
            />
            {lessonsResponse && (
               <CardsGroup
                  role='teacher'
                  isLoading={lessonsResponse.isLoading}
                  elements={lessonsResponse?.data?.map((item: any) => ({
                     ...item,
                     type: "lesson",
                  }))}
                  title={t(
                     "Lk.LkTeacherOwn.tickets.confirmed-lessons",
                     "Подтвержденные занятия"
                  )}
               />
            )}
            <CardsGroup
               role='teacher'
               isLoading={groupLessonsResponse.isLoading}
               elements={groupLessonsResponse?.data?.map((item: any) => ({
                  ...item,
                  type: "group_lesson",
               }))}
               title={t(
                  "Lk.LkTeacherOwn.tickets.open-lessons",
                  "Открытые уроки"
               )}
            />
            <CardsGroup
               role='teacher'
               isLoading={slotResponse.isLoading}
               elements={slotResponse.data?.map((item: any) => ({
                  ...item,
                  type: "slot",
                  setData: slotResponse.setData,
                  data: slotResponse.data,
               }))}
               title={t(
                  "Lk.LkTeacherOwn.tickets.time-for-lessons",
                  "Время для занятий"
               )}
            />
         </ProfileCardsWrapper>
      </div>
   );
};
