import { AxiosResponse } from "axios";
import { LToolsConfig } from "../../constructor";

var pingCounter = 0;
var requestTimeoutId = 0;

export const pingRequest = async (
  request: () => Promise<AxiosResponse<any>>,
  successCb: any,
  stopCb: any
) => {
  const timeoutCallback = async () => {
    pingCounter++;

    const response = await request();

    if (pingCounter == LToolsConfig.ping.iterations) {
      window.clearInterval(requestTimeoutId);
      stopCb();
      successCb(response);
      pingCounter = 0;
      return;
    }

    if (response.status != 500) {
      successCb(response);
      window.clearInterval(requestTimeoutId);
      stopCb();
      pingCounter = 0;
      return;
    }
    window.setTimeout(timeoutCallback, LToolsConfig.ping.timeout);
  };
  window.setTimeout(timeoutCallback, LToolsConfig.ping.timeout);
};
