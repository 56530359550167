import React, { FC } from "react";
import "./style.css";
import { useHistory, useLocation } from "react-router-dom";
import cn from "classnames";
import { navigationData } from "./titlesData";
import { Icons } from "assets/iconsCorrect";
import { routes } from "routes";

type propsType = {
   variant?: "teacher";
};

export const RegistrationWrapper: FC<propsType> = (props) => {
   const { pathname } = useLocation();
   const history = useHistory();
   return (
      <div
         className={cn({
            RegistrationWrapper: true,
            RegistrationWrapper__teacher: props.variant == "teacher",
         })}
      >
         <Icons.logos.logoFull
            className={"RegistrationWrapper__logo"}
            onClick={() => history.push(routes.landings.main)}
         />
         {navigationData[pathname] && (
            <h2 className='RegistrationWrapper__title'>
               {navigationData[pathname].title}
            </h2>
         )}
         <div className='RegistrationWrapper__children'>{props.children}</div>
      </div>
   );
};
