// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CircledCounter_container__naHsj {\n    width: 24px;\n    height: 24px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 12px;\n    color: black;\n    font-size: 12px;\n    margin-right: 8px;\n    flex-shrink: 0;\n}\n\n.CircledCounter_default__1zerr {\n    background: #E6EFFE;\n}\n.CircledCounter_blue__3Otqt {\n    background: var(--color-blue2);\n    color: white;\n}", "",{"version":3,"sources":["webpack://src/components/UI/CircledCounter/index.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB;AACA;IACI,8BAA8B;IAC9B,YAAY;AAChB","sourcesContent":[".container {\n    width: 24px;\n    height: 24px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 12px;\n    color: black;\n    font-size: 12px;\n    margin-right: 8px;\n    flex-shrink: 0;\n}\n\n.default {\n    background: #E6EFFE;\n}\n.blue {\n    background: var(--color-blue2);\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CircledCounter_container__naHsj",
	"default": "CircledCounter_default__1zerr",
	"blue": "CircledCounter_blue__3Otqt"
};
export default ___CSS_LOADER_EXPORT___;
