// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FilterButtonSec_title__tf7uR {\n    white-space: nowrap;\n}\n\n@media screen and (max-width: 1023px) {\n    .FilterButtonSec_title__tf7uR {\n        font-size: 13px;\n    }\n}\n", "",{"version":3,"sources":["webpack://src/components/UI/Button/Templates/FilterButtonSec/index.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".title {\n    white-space: nowrap;\n}\n\n@media screen and (max-width: 1023px) {\n    .title {\n        font-size: 13px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "FilterButtonSec_title__tf7uR"
};
export default ___CSS_LOADER_EXPORT___;
