import {
   confirmApplicationType,
   declineApplicationType,
   setUserStatusType,
   blockUserType,
   blockTeacherType,
   updateUserByAdminType,
} from "api/adminApi/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { LTools } from "core/LTools/constructor";
import { thunkType } from "core/LTools/core/types/types";
import { adminApi } from "api/adminApi";
import { errorCaseHandler } from "store/lessonSlice/thunk";
import { getFirstErrorFromResponse } from "helpers/errors";

export const adminThunks = {
   confirmApplication: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & confirmApplicationType>) {
      return createAsyncThunk("confirmApplication", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => adminApi.confirmApplication(props),
            responseReactionCreator: (response) => ({
               400: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response),
                     "close"
                  );
               },
               404: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response),
                     "close"
                  );
               },
               500: () => {
                  errorCaseHandler(commonState.dispatch, "Error", "close");
               },
            }),
         });

         return response as any;
      })();
   },
   updateUser: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & updateUserByAdminType>) {
      return createAsyncThunk("updateUser", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => adminApi.updateUser(props),
            responseReactionCreator: (response) => ({
               400: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response),
                     "close"
                  );
               },
               404: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response),
                     "close"
                  );
               },
               500: () => {
                  errorCaseHandler(commonState.dispatch, "Error", "close");
               },
            }),
         });

         return response as any;
      })();
   },
   declineApplication: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & declineApplicationType>) {
      return createAsyncThunk("declineApplication", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => adminApi.declineApplication(props),
            responseReactionCreator: (response) => ({
               200: () => {},
            }),
         });

         return response as any;
      })();
   },
   blockUser: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & blockUserType>) {
      return createAsyncThunk("blockUser", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => adminApi.blockUser(props),
            responseReactionCreator: (response) => ({
               200: () => {},
            }),
         });

         return response as any;
      })();
   },
   blockTeacher: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & blockTeacherType>) {
      return createAsyncThunk("TeacherToStudent", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => adminApi.blockTeacher(props),
            responseReactionCreator: (response) => ({
               200: () => {},
            }),
         });

         return response as any;
      })();
   },
   setUserRole: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & setUserStatusType>) {
      return createAsyncThunk("setUserRole", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => adminApi.setUserStatus(props),
            responseReactionCreator: (response) => ({
               200: () => {},
            }),
         });

         return response as any;
      })();
   },
};
