import { thunkType } from "core/LTools/core/types/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { LTools } from "core/LTools/constructor";
import { lkApi } from "api/LkApi/methods";
import { lkSlice } from "./index";
import {
   getNotificationsType,
   updateUserType,
   uploadUserFile,
} from "api/userApi/types";
import { userApi } from "api/userApi/methods";
import { eventSlotApi } from "api/eventSlotApi/methods";
import { getSlotType } from "api/eventSlotApi/types";
import { routes } from "../../routes";
import { teacherType } from "types/userType";
import { logout } from "store/helpers";
import { authSliceSelectors } from "store/authSlice";
import {
   fastLessonSlice,
   fastLessonSliceSelectors,
} from "store/fastLessonSlice";
import { errorCaseHandler } from "store/lessonSlice/thunk";
import { getFirstErrorFromResponse } from "helpers/errors";

export const lkSLiceThunk = {
   getUserFile: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, { user_id: string; file_id: string }>) {
      return createAsyncThunk("getUserFile", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => userApi.getUserFile(props),
            responseReactionCreator: (response) => ({
               "200": () => {},
            }),
         });

         return response as any;
      })();
   },
   getUser: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, { needRedirect?: boolean }>) {
      return createAsyncThunk("getUser", async (arg, { getState }) => {
         const teacherUrl = fastLessonSliceSelectors.getChosenTeacherUrl(
            getState() as any
         );

         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => lkApi.getUser(),
            responseReactionCreator: (response) => ({
               "200": () => {
                  const user = response.data.data as teacherType;
                  if (user) {
                     if (user.is_banned) {
                        logout(commonState.dispatch);
                        commonState.location = routes.landings.main;
                        return;
                     }

                     commonState.dispatch(lkSlice.actions.setData(user));
                     if (!user.birth_date) {
                        commonState.location =
                           routes.registration.student.fillInformation;
                        return;
                     } else if (!user.study_education_system) {
                        commonState.location =
                           routes.registration.student.studentRegistration;
                        return;
                     }

                     if (props.needRedirect) {
                        if (teacherUrl) {
                           commonState.location = teacherUrl;
                           commonState.dispatch(
                              fastLessonSlice.actions.setUrlOfTeacher()
                           );
                        } else {
                           commonState.location = `${routes.lk.profile}/${user.id}`;
                        }
                     }
                  } else {
                     commonState.location =
                        routes.registration.student.fillInformation;
                  }
               },
               404: () => {
                  commonState.location =
                     routes.registration.student.fillInformation;
               },
            }),
         });

         return response as any;
      })();
   },
   getSlots: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & getSlotType>) {
      return createAsyncThunk("getUser", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => eventSlotApi.getSlot(props),
            responseReactionCreator: (response) => ({
               "200": () => {
                  const slots = response.data;
                  if (slots) {
                     commonState.dispatch(lkSlice.actions.setSlots(slots));
                  }
               },
            }),
         });

         return response as any;
      })();
   },
   uploadFile: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & uploadUserFile>) {
      return createAsyncThunk("uploadFile", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => userApi.uploadUserFile(props),
            responseReactionCreator: (response) => ({
               201: () => {},
            }),
         });

         return response as any;
      })();
   },
   updateUser: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, { disableSetToStore?: boolean } & updateUserType>) {
      return createAsyncThunk("updateUser", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => userApi.update(props),
            responseReactionCreator: (response) => ({
               200: () => {
                  if (props.disableSetToStore) {
                     return;
                  }
                  commonState.dispatch(
                     lkSlice.actions.setData(response.data.data)
                  );
               },
               400: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response),
                     "close"
                  );
               },
               404: () => {
                  errorCaseHandler(
                     commonState.dispatch,
                     getFirstErrorFromResponse(response),
                     "close"
                  );
               },
            }),
         });

         return response as any;
      })();
   },
   getNotifications: function <T>({
      commonState,
      callbacks,
      ...props
   }: thunkType<T, {} & getNotificationsType>) {
      return createAsyncThunk("getNotifications", async () => {
         const response = await LTools.makeRequestAndResolve({
            commonState,
            callbacks,
            request: () => userApi.getNotifications(props),
            responseReactionCreator: (response) => ({
               200: () => {},
            }),
         });

         return response as any;
      })();
   },
};
