// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FilesInput_files__3tsIF {\n    max-width: 200px;\n}\n\n.FilesInput_preloader__2FJ2N {\n    display: block;\n    margin: 0 auto;\n    width: 50px;\n    height: 50px;\n    margin-bottom: 20px;\n}", "",{"version":3,"sources":["webpack://src/components/UI/Input/FilesInput/index.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,cAAc;IACd,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".files {\n    max-width: 200px;\n}\n\n.preloader {\n    display: block;\n    margin: 0 auto;\n    width: 50px;\n    height: 50px;\n    margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"files": "FilesInput_files__3tsIF",
	"preloader": "FilesInput_preloader__2FJ2N"
};
export default ___CSS_LOADER_EXPORT___;
