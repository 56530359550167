// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__31AWV {\n    display: flex;\n}\n.style_button__MlzF8 {\n    margin-right:  24px;\n}\n\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n\n}\n\n@media screen and (max-width: 1023px) {\n\n    .style_container__31AWV {\n        flex-direction: column;\n        justify-content: center;\n        align-items: center;\n    }\n    .style_container__31AWV button {\n        width: 100%;\n    }\n    .style_button__MlzF8 {\n        margin-right: 0;\n        margin-bottom: 16px;\n    }\n}\n\n@media screen and (max-width: 719px) {\n}", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/LessonsService/Pages/DefaultLesson/LessonButtons/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,mBAAmB;AACvB;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;IAEI;QACI,sBAAsB;QACtB,uBAAuB;QACvB,mBAAmB;IACvB;IACA;QACI,WAAW;IACf;IACA;QACI,eAAe;QACf,mBAAmB;IACvB;AACJ;;AAEA;AACA","sourcesContent":[".container {\n    display: flex;\n}\n.button {\n    margin-right:  24px;\n}\n\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n\n}\n\n@media screen and (max-width: 1023px) {\n\n    .container {\n        flex-direction: column;\n        justify-content: center;\n        align-items: center;\n    }\n    .container button {\n        width: 100%;\n    }\n    .button {\n        margin-right: 0;\n        margin-bottom: 16px;\n    }\n}\n\n@media screen and (max-width: 719px) {\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__31AWV",
	"button": "style_button__MlzF8"
};
export default ___CSS_LOADER_EXPORT___;
