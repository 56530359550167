// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__15YM2 {\n    position: relative;\n    width: 100%;\n}\n\n.style_testWrapper__3I8gw {\n    background: red;\n}\n\n\n\n\n@media screen and (max-width: 1919px) {\n\n}\n\n\n@media screen and (max-width: 1439px) {\n\n}\n\n\n@media screen and (max-width: 1023px) {\n\n}\n\n\n@media screen and (max-width: 719px) {\n    .style_right__2Vfkt {\n        left: inherit!important;\n        right: 0;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/UI/Calendar/style.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;;;;;AAKA;;AAEA;;;AAGA;;AAEA;;;AAGA;;AAEA;;;AAGA;IACI;QACI,uBAAuB;QACvB,QAAQ;IACZ;AACJ","sourcesContent":[".container {\n    position: relative;\n    width: 100%;\n}\n\n.testWrapper {\n    background: red;\n}\n\n\n\n\n@media screen and (max-width: 1919px) {\n\n}\n\n\n@media screen and (max-width: 1439px) {\n\n}\n\n\n@media screen and (max-width: 1023px) {\n\n}\n\n\n@media screen and (max-width: 719px) {\n    .right {\n        left: inherit!important;\n        right: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__15YM2",
	"testWrapper": "style_testWrapper__3I8gw",
	"right": "style_right__2Vfkt"
};
export default ___CSS_LOADER_EXPORT___;
