import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";

type propsType = {
   formElements: any;
};
export const NotePeoplesPopupFilters: FC<propsType> = ({ formElements }) => {
   return (
      <div className={styles.filters}>
         {formElements.category}
         <div className={styles.filters_right}>
            {formElements.rating}
            {formElements.name}
         </div>
      </div>
   );
};
