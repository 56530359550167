import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { Icons } from "../../../assets/iconsCorrect";
import { AxiosResponse } from "axios";
import { defaultFileType } from "../../../api/types";
import { Preloader } from "components/UI/Preloader";

type propsType = {
   files?: Array<defaultFileType>;
   apiMethod?: (id: string) => Promise<AxiosResponse<any>>;
};
export const downloadFile = async (e: any, apiMethod: any) => {
   const id = e.currentTarget.getAttribute("data-id");
   const file_name = e.currentTarget.getAttribute("data-name");
   const response = await apiMethod(id);
   if (response.status == 200) {
      const aTag = document.createElement("a");
      aTag.setAttribute("href", response?.data.data.content);
      aTag.setAttribute("download", file_name);
      aTag.click();
      aTag.remove();
   }
};

export const FilesArea: FC<propsType> = ({ files, apiMethod }) => {
   const [loading, setLoading] = useState(false);
   if (!files || !apiMethod) return null;

   const proxyDownloadFile = async (e: any) => {
      try {
         setLoading(true);
         await downloadFile(e, apiMethod);
         setLoading(false);
      } catch {
         setLoading(false);
      }
   };

   return (
      <div className={styles.container}>
         {files?.map((item) => (
            <div className={styles.file} key={item.id}>
               <Icons.ui.documentIcon className={styles.file__document_icon} />
               <span className={styles.file__text}>{item.file_name}</span>
               {loading ? (
                  <Preloader loading={true} variant='small' />
               ) : (
                  <Icons.buttons.DownloadIcon
                     className={styles.file__download}
                     data-id={item.id}
                     data-name={item.file_name}
                     onClick={proxyDownloadFile}
                  />
               )}
            </div>
         ))}
      </div>
   );
};
