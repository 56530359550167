import React, { FC, useState } from "react";
import { GuestSlotCard } from "../../Cards/GuestSlotCard";
import { useQuery } from "hooks/useQuery";
import { eventSlotApi } from "api/eventSlotApi/methods";
import { useParams } from "react-router-dom";
import { ProfileCardsWrapper } from "components/Lk/Pages/LkProfile/components/ProfileCardsWrapper";
import { groupLessonApi } from "api/groupLessonApi/methods";
import { groupLessonType, slotType } from "types/LessonService/types";
import { CardsGroup } from "components/UI/CardsGroup";
import styles from "./index.module.css";
import { useTranslation } from "react-i18next";
import { Preloader } from "components/UI/Preloader";
import moment from "moment-timezone";
import { Button } from "components/UI/Button";
import { divideSlotsByDays } from "serializers/slots";

type propsType = {};
export const LkTeacherGuestPage: FC<propsType> = (props) => {
   const { t } = useTranslation();
   const { id: teacher_id } = useParams<{ id: string }>();
   const [slots, setSlots] = useState<Array<slotType>>([]);
   const [offset, setOffset] = useState(0);

   const {
      data: slotsResponse,
      isLoading: slotsFetching,
      fetch: fetchSlots,
   } = useQuery(
      () =>
         eventSlotApi.getSlot({
            user_id: teacher_id,
            limit: "10",
            offset: String(offset),
            date_start_order_by: "asc",
         }),
      {
         serializer: (res) => {
            const slotsResponse = res?.data.data;
            const transformedSlots = divideSlotsByDays(slotsResponse.items);
            setSlots((slots) => [
               ...slots,
               ...(transformedSlots as Array<slotType>),
            ]);
            return slotsResponse;
         },
         deps: [offset],
      }
   );

   const showMoreSlots = () => {
      setOffset((offset) => offset + 10);
   };

   const { data: groupLessons } = useQuery(
      () =>
         groupLessonApi.getGroupLessons({
            user_id: teacher_id,
            role: "teacher",
            limit: "100",
            offset: "0",
            date_from: moment().utcOffset("+0000").format("YYYY-MM-DD"),
            lesson_statuses: ["new_group_lesson"],
         }),
      {
         serializer: (res) => {
            return res?.data.data.items as Array<groupLessonType>;
         },
      }
   );

   if (!slots || !groupLessons) return <Preloader loading={true} />;
   return (
      <div className='LkTeacher__registration-classes'>
         <ProfileCardsWrapper
            title={t("Lk.LkTeacherGuest.title", "Запись на занятия")}
         >
            <h4 className={styles.subtitle}>
               {t("Lk.LkTeacherGuest.available-dates", "Свободные даты")}
            </h4>
            {groupLessons.length ? (
               <div>
                  <CardsGroup
                     elements={slots?.map((item) => ({
                        type: "free",
                        ...item,
                        teacher_id: teacher_id,
                     }))}
                     title={t(
                        "Lk.LkTeacherOwn.subjects-picker-label",
                        "Занятия"
                     )}
                     role='student'
                     showMore={showMoreSlots}
                     showMoreVisible={offset + 10 < slotsResponse?.items_count}
                  />
                  <CardsGroup
                     elements={groupLessons?.map((item) => ({
                        ...item,
                        type: "group_lesson",
                     }))}
                     title={t(
                        "Lk.LkStudentOwn.tickets.open-lessons",
                        "Открытые уроки"
                     )}
                     role='student'
                  />
               </div>
            ) : (
               <div>
                  {slots.map((item, index) => {
                     return (
                        <GuestSlotCard
                           key={index}
                           {...item}
                           teacher_id={teacher_id}
                        />
                     );
                  })}
                  {offset + 10 < slotsResponse?.items_count && (
                     <Button
                        loading={slotsFetching}
                        className={styles.slots_show_more}
                        onClick={showMoreSlots}
                     >
                        {t(
                           "SearchFilters.teacher-filters.show-more",
                           "Показать ещё"
                        )}
                     </Button>
                  )}
               </div>
            )}
         </ProfileCardsWrapper>
      </div>
   );
};
