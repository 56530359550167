import { Control, Controller, useWatch } from "react-hook-form";
import { CalendarHeader } from "components/UI/Calendar/Bricks/CalendarHeader";
import {
   formatWeekDay,
   renderDayContents,
} from "components/UI/Calendar/helpers";
import cn from "classnames";
import DatePicker, { registerLocale } from "react-datepicker";
import { FC, useEffect, useState } from "react";
import {ru, de, es, uk, fr, it, enUS} from "date-fns/locale";
import styles from "components/UI/Calendar/style.module.css";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarInput } from "components/UI/Input/CalendarInput";
import { ReactComponent as Icon } from "assets/inputs/Date.svg";
import { useToggle } from "hooks/useToggle";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";
import moment from "moment-timezone";
import { lc } from "store/LC";
type propsType = {
   control: Control<any>;
   name: string;
   big?: boolean;
   highlightDates?: any[];
   placeholder?: string;
   placeholderColor?: "dark-gray";
   rightCalendar?: boolean;
   className?: string;
   error?: string;
   style?: any;
   minDate?: Date;
   maxDate?: Date;
};
export const Calendar: FC<propsType> = ({
   control,
   name,
   big,
   highlightDates,
   placeholder = "Дата",
   placeholderColor,
   rightCalendar = false,
   className,
   error,
   style,
   minDate,
   maxDate,
}) => {
   const [didMount, setDidMount] = useState(false);
   useEffect(() => {
      setDidMount(true);
   }, []);
   const [isOpen, toggleOpen, setIsOpen] = useToggle(false);
   const close = () => setIsOpen(false);
   const lang = useSelector(lkSliceSelectors.getLang);
   const datePickerLocaleResolver = () => {
      switch (lang) {
         case "ru":
            return ru;
         case "de":
            return de;
         case "es":
            return es;
         case "en":
            return enUS;
         case "fr":
            return fr;
         case "it":
            return it;
      }
   };

   return (
      <div
         className={cn({
            [styles.container]: true,
            [`${className}`]: className,
            big: big,
         })}
         style={style}
      >
         <Controller
            control={control}
            name={name}
            render={({ field }) => {
               return (
                  <DatePicker
                     locale={datePickerLocaleResolver()}
                     formatWeekDay={formatWeekDay}
                     inline={big}
                     popperClassName={cn({
                        [styles.right]: rightCalendar,
                     })}
                     open={isOpen}
                     minDate={minDate}
                     selected={field.value}
                     // selected={startDate}
                     highlightDates={highlightDates}
                     renderDayContents={renderDayContents}
                     renderCustomHeader={(props) => (
                        <CalendarHeader big={big} {...props} />
                     )}
                     maxDate={maxDate}
                     onChange={(date) => {
                        field.onChange(date);
                        close();
                     }}
                     onBlur={close}
                     dateFormat='dd.MM.yyyy'
                     placeholderText={placeholder}
                     name={field.name}
                     value={field.value}
                     customInput={
                        <CalendarInput
                           support={<Icon onClick={toggleOpen} />}
                           placeholder={placeholder}
                           placeholderColor={placeholderColor}
                           label={placeholder}
                           error={error}
                        />
                     }
                  />
               );
            }}
         />
      </div>
   );
};

function convertTZ(date: any, tzString: string) {
   return new Date(
      (typeof date === "string" ? new Date(date) : date).toLocaleString(
         "en-US",
         { timeZone: tzString }
      )
   );
}
