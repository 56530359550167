// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FilterElement_container__DmCaY {\n    padding: 10px 14px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background: #F8F8F8;\n    border-radius: 10px;\n    padding-right: 36px;\n    margin-bottom: 10px;\n    position: relative;\n    cursor: pointer;\n}\n\n.FilterElement_arrow__3-K70 {\n    position: absolute;\n    top: 5px;\n    right: 6px;\n\n    transform: rotateZ(-90deg);\n}", "",{"version":3,"sources":["webpack://src/components/UI/FilterElement/index.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,UAAU;;IAEV,0BAA0B;AAC9B","sourcesContent":[".container {\n    padding: 10px 14px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background: #F8F8F8;\n    border-radius: 10px;\n    padding-right: 36px;\n    margin-bottom: 10px;\n    position: relative;\n    cursor: pointer;\n}\n\n.arrow {\n    position: absolute;\n    top: 5px;\n    right: 6px;\n\n    transform: rotateZ(-90deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FilterElement_container__DmCaY",
	"arrow": "FilterElement_arrow__3-K70"
};
export default ___CSS_LOADER_EXPORT___;
