import React, { FC } from "react";
import styles from "./index.module.css";
import { HowWeWork } from "./HowWeWork";
import { Reviews } from "./Reviews";
import { Questions } from "./Questions";
import { SearchFilters } from "./SearchFilters";
import { TeachersForYou } from "./TeachersForYou";
import { About } from "./About";
import { routes } from "routes";

type propsType = {};
export const Main: FC<propsType> = (props) => {
   return (
      <div className={styles.container}>
         <SearchFilters
            config={{
               redirectUrl: routes.landings.filter,
            }}
         />
         <TeachersForYou />
         <HowWeWork />
         <About />
         <Reviews />
         <Questions />
      </div>
   );
};
