import { addMethodToUpdateLang } from "services/translateService/useLangInit";
import i18n from "../services/translateService/i18n";
import { languageCodeType } from "services/translateService";

export type languagesKeysType =
   | "English"
   | "Arabic"
   | "Catalan"
   | "Chinese"
   | "Dutch"
   | "French"
   | "German"
   | "Greek"
   | "Hebrew"
   | "Hindi"
   | "Italian"
   | "Japanese"
   | "Korean"
   | "Norwegian"
   | "Portuguese"
   | "Russian"
   | "Spanish"
   | "Swedish";

export let languagesTitleResolver: {
   [key in languagesKeysType]: string;
};

addMethodToUpdateLang(() => {
   languagesTitleResolver = {
      English: i18n.t("Languages.English", "Английский"),
      Arabic: i18n.t("Languages.Arabic", "Арабский"),
      Catalan: i18n.t("Languages.Catalan", "Каталанский"),
      Chinese: i18n.t("Languages.Chinese", "Китайский"),
      Dutch: i18n.t("Languages.Dutch", "Нидерландский"),
      French: i18n.t("Languages.French", "Французский"),
      German: i18n.t("Languages.German", "Немецкий"),
      Greek: i18n.t("Languages.Greek", "Греческий"),
      Hebrew: i18n.t("Languages.Hebrew", "Иврит"),
      Hindi: i18n.t("Languages.Hindi", "Хинди"),
      Italian: i18n.t("Languages.Italian", "Итальянский"),
      Japanese: i18n.t("Languages.Japanese", "Японский"),
      Korean: i18n.t("Languages.Korean", "Корейский"),
      Norwegian: i18n.t("Languages.Norwegian", "Норвежский"),
      Portuguese: i18n.t("Languages.Portuguese", "Португальский"),
      Russian: i18n.t("Languages.Russian", "Русский"),
      Spanish: i18n.t("Languages.Spanish", "Испанский"),
      Swedish: i18n.t("Languages.Swedish", "Шведский"),
   };
});

export const languageCodeResolver: {
   [key in languagesKeysType]: string;
} = {
   English: "en",
   Arabic: "ar",
   Catalan: "ca",
   Chinese: "ch",
   Dutch: "Нидерландский",
   French: "fr",
   German: "de",
   Greek: "Греческий",
   Hebrew: "Иврит",
   Hindi: "Хинди",
   Italian: "it",
   Japanese: "Японский",
   Korean: "Корейский",
   Norwegian: "Норвежский",
   Portuguese: "Португальский",
   Russian: "ru",
   Spanish: "es",
   Swedish: "Шведский",
};
export const resolveLanguageNameByCode: {
   [key in languageCodeType]: languagesKeysType;
} = {
   en: "English",
   fr: "French",
   de: "German",
   it: "Italian",
   ru: "Russian",
   es: "Spanish",
};

export let switchLanguageOptions: Array<{
   id: number;
   text: string;
   value: {
      title: string;
      code: languageCodeType;
   };
}> = [];
addMethodToUpdateLang(() => {
   switchLanguageOptions = [
      // {
      //    id: 0,
      //    text: "Русский",
      //    value: {
      //       title: "Русский",
      //       code: "ru",
      //    },
      // },
      {
         id: 1,
         text: "English",
         value: {
            title: "English",
            code: "en",
         },
      },
      {
         id: 2,
         text: "Español",
         value: {
            title: "Español",
            code: "es",
         },
      },
      {
         id: 3,
         text: "Deutsch",
         value: {
            title: "Deutsch",
            code: "de",
         },
      },
      {
         id: 4,
         text: "Italiano",
         value: {
            title: "Italiano",
            code: "it",
         },
      },
      {
         id: 5,
         text: "Français",
         value: {
            title: "Français",
            code: "fr",
         },
      },
   ];
});
