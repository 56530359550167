import React, { AllHTMLAttributes, FC, ReactNode } from "react";
import styles from "./style.module.css";
import cn from "classnames";
import ReactInputMask from "react-input-mask";

type propsType = {
   support?: ReactNode;
   error?: string;
   placeholderColor?: "dark-gray";
} & AllHTMLAttributes<HTMLInputElement>;
export const CalendarInput: FC<propsType> = ({
   className,
   support,
   ...props
}) => {
   return (
      <div
         className={cn({
            [styles.container]: true,
            [`${className}`]: className,
         })}
      >
         <ReactInputMask
            mask='99.99.9999'
            className={styles.Input}
            {...props}
         />
         <label className={cn(styles.label, {
            [styles.label_gray]: props.placeholderColor === "dark-gray"
         })}>{props.placeholder}</label>
         {support && (
            <span
               className={cn({
                  [styles.Input__support]: true,
               })}
            >
               {support}
            </span>
         )}
         {props.error && <div className={styles.error}>{props.error}</div>}
      </div>
   );
};
