import React, {
   Dispatch,
   FC,
   HTMLAttributes,
   SetStateAction,
   useEffect,
   useRef,
   useState,
} from "react";
import styles from "./index.module.css";
import styles_button from "./sendChatToAdminButton.module.css";
import { Input } from "../../../../../UI/Input";
import { useForm, useWatch } from "react-hook-form";
import { useQuery } from "../../../../../../hooks/useQuery";
import { userApi } from "../../../../../../api/userApi/methods";
import { studentType } from "../../../../../../types/userType";
import { SendChatToAdminItem } from "./adminItem";
import { Button } from "../../../../../UI/Button";
import { useLocalCommonState } from "../../../../../../core/LTools/core/hooks/useLocalCommonState";
import { ChatThunk } from "../../../../../../store/chatSlice/thunk";
import { useTranslation } from "react-i18next";
import { useBlurred } from "../../../../../../hooks/useBlurred";

export const SendChatToAdminPop = ({
   chat_id,
   setIsOpened,
   isOpened,
   setIsChatSent,
   setAdminNameToGetComplaint,
}: {
   chat_id: number;
   setIsOpened: Dispatch<SetStateAction<boolean>>;
   setIsChatSent: Dispatch<SetStateAction<boolean>>;
   setAdminNameToGetComplaint: Dispatch<
      SetStateAction<{
         id?: number;
         name?: string;
         chat_id?: number;
      }>
   >;
   isOpened: boolean;
}) => {
   const { t } = useTranslation();
   const commonState = useLocalCommonState();
   const { control, setValue } = useForm();

   const ref = useRef(null);
   useBlurred(ref, setIsOpened, isOpened);

   const { data: adminsData, isLoading } = useQuery(
      () =>
         userApi.getUsers({
            limit: `100`,
            offset: `0`,
            user_role: "admin",
            is_banned: false,
         }),
      {
         deps: [],
         serializer: (res) => {
            return {
               items: res?.data.data.items,
               items_count: res?.data.data.items_count,
            } as {
               items: Array<studentType>;
               items_count: number;
            };
         },
      }
   );
   const [adminsNames, setAdminsNames] = useState<string[]>([]);
   useEffect(() => {
      adminsData?.items &&
         setAdminsNames(updateDialogsUsersNames(adminsData.items));
   }, [adminsData]);

   function updateDialogsUsersNames(admins: studentType[]) {
      return admins.map((user) => {
         return user.first_name + " " + user.last_name + " ";
      });
   }

   const [selectedAdmin, setSelectedAdmin] = useState<
      studentType | undefined
   >();

   function sendChat() {
      selectedAdmin?.id &&
         commonState.dispatch(
            ChatThunk.sendChatToAnotherAdmin({
               commonState,
               callbacks: {
                  200: (response) => {
                     console.log(response);
                     setIsOpened(false);
                     setIsChatSent(true);
                     setAdminNameToGetComplaint({
                        id: response?.data?.data?.lead_admin?.id,
                        name:
                           " " +
                           response?.data?.data?.lead_admin?.first_name +
                           " " +
                           response?.data?.data?.lead_admin?.last_name,
                        chat_id: response?.data?.data?.id,
                     });
                  },
               },
               admin_id: selectedAdmin.id,
               chat_id: chat_id,
            })
         );
   }

   function selectAdmin(user: studentType) {
      setSelectedAdmin(user);
   }

   const searchDialogsInput = useWatch({
      control,
      name: "searchInput",
      defaultValue: "",
   });

   function filterDialogsList(item: studentType, index: number) {
      if (searchDialogsInput) {
         if (
            adminsNames[index]
               .toLowerCase()
               .includes(searchDialogsInput.toLowerCase())
         ) {
            return item;
         } else {
            return false;
         }
      } else {
         return item;
      }
   }

   return (
      <div className={styles.container}>
         <div className={styles.body} ref={ref}>
            <h2>{t("Messages.transfer-chat", "Передать чат")}</h2>
            <Input
               control={control}
               name={"searchInput"}
               label={t("Messages.admin-search", "Найдите администратора")}
               support={
                  selectedAdmin ? (
                     <div onClick={() => setSelectedAdmin(undefined)}>
                        <Loupe />
                     </div>
                  ) : (
                     <button
                        onClick={() => setValue("searchInput", "")}
                        className={styles.clearInput}
                     >
                        <Cross />
                     </button>
                  )
               }
            />
            <div className={styles.admins}>
               {!selectedAdmin &&
                  !isLoading &&
                  adminsData &&
                  adminsData.items
                     .filter(filterDialogsList)
                     .map((item) => (
                        <SendChatToAdminItem
                           onClick={() => selectAdmin(item)}
                           admin={item}
                        />
                     ))}
               {selectedAdmin && (
                  <p className={styles.text}>
                     {t("Messages.transferred-to", "Ваш чат будет передан:")}
                  </p>
               )}
               {selectedAdmin && <SendChatToAdminItem admin={selectedAdmin} />}
               {selectedAdmin && (
                  <Button onClick={sendChat} className={styles.sendButton}>
                     {t("Messages.transfer", "Передать")}
                  </Button>
               )}
            </div>
         </div>
      </div>
   );
};

export const SendChatToAdmin: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
   const { className, onClick, ...other } = props;
   const { t } = useTranslation();

   return (
      <div className={styles_button.container} onClick={onClick} {...other}>
         <Send />
         {t("Messages.transfer-chat", "Передать чат")}
      </div>
   );
};

const Send = () => (
   <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
   >
      <path
         d='M7.49139 9.90419C7.62523 10.0439 7.84352 10.0439 7.97736 9.90419L8.42188 9.44089C8.55594 9.30138 8.55594 9.07387 8.42188 8.93437L7.97736 8.47106H14.0938C14.6624 8.47106 15.125 8.95318 15.125 9.54591C15.125 9.84216 15.3564 10.0833 15.6406 10.0833H15.9844C16.2686 10.0833 16.5 9.84216 16.5 9.54591C16.5 8.16294 15.4206 7.03793 14.0938 7.03793H7.97736L8.42188 6.57462C8.55594 6.43512 8.55594 6.20761 8.42188 6.0681L7.97736 5.6048C7.84352 5.46507 7.62523 5.46507 7.49139 5.6048L5.55027 7.62775C5.48324 7.69784 5.48324 7.81115 5.55027 7.88124L7.49139 9.90419Z'
         fill='#2D49FE'
      />
      <path
         d='M14.5086 12.0956C14.3748 11.9559 14.1567 11.9559 14.0226 12.0956L13.5781 12.5589C13.4438 12.6989 13.4436 12.9252 13.5781 13.0655L14.0226 13.5288H7.90625C7.33756 13.5288 6.875 13.0467 6.875 12.4539C6.875 12.1577 6.64361 11.9165 6.35937 11.9165H6.01562C5.73139 11.9165 5.5 12.1577 5.5 12.4539C5.5 13.8369 6.57937 14.9619 7.90625 14.9619H14.0226L13.5781 15.4252C13.4438 15.5652 13.4436 15.7915 13.5781 15.9317L14.0226 16.395C14.1567 16.5348 14.3745 16.5348 14.5086 16.395L16.4497 14.3721C16.5168 14.302 16.5168 14.1887 16.4497 14.1186L14.5086 12.0956Z'
         fill='#2D49FE'
      />
      <path
         fill-rule='evenodd'
         clip-rule='evenodd'
         d='M10.9998 20.1665C16.0624 20.1665 20.1665 16.0624 20.1665 10.9998C20.1665 5.93723 16.0624 1.83317 10.9998 1.83317C5.93723 1.83317 1.83317 5.93723 1.83317 10.9998C1.83317 16.0624 5.93723 20.1665 10.9998 20.1665ZM10.9998 21.0832C16.5687 21.0832 21.0832 16.5687 21.0832 10.9998C21.0832 5.43097 16.5687 0.916504 10.9998 0.916504C5.43097 0.916504 0.916504 5.43097 0.916504 10.9998C0.916504 16.5687 5.43097 21.0832 10.9998 21.0832Z'
         fill='#2D49FE'
      />
   </svg>
);

const Cross = () => (
   <svg
      width='8'
      height='8'
      viewBox='0 0 8 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
   >
      <path
         d='M0.5 0.5L7.5 7.5M7.5 0.5L0.5 7.5'
         stroke='white'
         stroke-width='0.777778'
         stroke-linecap='round'
         stroke-linejoin='round'
      />
   </svg>
);

const Loupe = () => (
   <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
   >
      <path
         fill-rule='evenodd'
         clip-rule='evenodd'
         d='M15.5113 14.6782H16.4635C16.4635 14.6782 21.1535 19.2107 22.2996 20.4583C23.4456 21.7059 21.8209 23.142 20.6748 22.1991C19.5288 21.2561 14.6782 16.4635 14.6782 16.4635V15.5113L14.3211 15.1543C13.0119 16.3445 11.2266 17.0586 9.32227 17.0586C5.03753 17.0586 1.58594 13.607 1.58594 9.32227C1.58594 5.03753 5.03753 1.58594 9.32227 1.58594C13.607 1.58594 17.0586 5.03753 17.0587 9.32227C17.0587 11.2266 16.3445 13.0119 15.1543 14.3211L15.5113 14.6782ZM9.30971 15.5921C12.7794 15.5921 15.5921 12.7794 15.5921 9.30971C15.5921 5.84006 12.7794 3.02734 9.30971 3.02734C5.84006 3.02734 3.02734 5.84006 3.02734 9.30971C3.02734 12.7794 5.84006 15.5921 9.30971 15.5921Z'
         fill='#B3B3B3'
      />
   </svg>
);
