// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../node_modules/postcss-loader/src/index.js??postcss!./reset.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../node_modules/postcss-loader/src/index.js??postcss!./grid.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../node_modules/postcss-loader/src/index.js??postcss!./fonts.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../node_modules/postcss-loader/src/index.js??postcss!./colors.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../node_modules/postcss-loader/src/index.js??postcss!./calendar.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html {\n    font-family: Manrope;\n}\n.App {\n    min-height: calc(var(--vh) * 100);\n}\n\n.x-centred {\n    display: block;\n    margin: 0 auto;\n}\n\nb {\n    font-weight: 600;\n}", "",{"version":3,"sources":["webpack://src/style/index.css"],"names":[],"mappings":"AAMA;IACI,oBAAoB;AACxB;AACA;IACI,iCAAiC;AACrC;;AAEA;IACI,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["@import \"reset.css\";\n@import \"grid.css\";\n@import \"fonts.css\";\n@import \"colors.css\";\n@import 'calendar.css';\n\nhtml {\n    font-family: Manrope;\n}\n.App {\n    min-height: calc(var(--vh) * 100);\n}\n\n.x-centred {\n    display: block;\n    margin: 0 auto;\n}\n\nb {\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
