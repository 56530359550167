import React, { FC } from "react";
import styles from "./style.module.css";
import { ReactComponent as RatingIcon } from "assets/icons/StarRating.svg";
import { Link } from "react-router-dom";
import { routes } from "routes";
import { Button } from "components/UI/Button";
import { useTranslation } from "react-i18next";
import { useGetUserAvatar } from "hooks/useGetUserAvatar";
import cn from "classnames";
import { Switcher } from "components/UI/Switcher";
import { useForm } from "react-hook-form";
import { ReactComponent as MessageIcon } from "components/UI/UserWidget/assets/Mail.svg";
import { Icons } from "assets/iconsCorrect";
import { roleType } from "types/types";
import { teacherType } from "types/userType";
import { ChatThunk } from "store/chatSlice/thunk";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "store/lkSlice";
import { OnlineStatusSwitcher } from "components/UI/OnlineStatusSwitcher";
import { useLkContext } from "components/Lk/Pages/LkProfile/index";

type propsType = {
   template: {
      isOwn: boolean;
      isExtended: boolean;
      tempRole: roleType;
   };
   user: teacherType;
   avatar?: string;
};

export const ProfileCardMobile: FC<propsType> = ({
   template,
   user,
   avatar,
}) => {
   const { t } = useTranslation();
   const ownId = useSelector(lkSliceSelectors.getUserId);
   const commonState = useLocalCommonState();
   const { role: clientRole } = useSelector(lkSliceSelectors.getUserData);
   const { setIsEditing, isEditing } = useLkContext();

   const resolveRole = () => {
      if (user.is_banned) {
         return "—";
      }
      if (user.role == "admin") {
         return t("Lk.Profile.role.admin", "Админ");
      }
      if (user.role == "superadmin") {
         return t("Lk.Profile.role.super-admin", "Супер админ");
      }
      if (user.type == "teacher" && !user.is_blocked) {
         return t("Lk.LkProfile.RoleSwitcher.teacher-button", "Преподаватель");
      }
      return t("Lk.LkProfile.RoleSwitcher.student-button", "Студент");
   };

   function openChat() {
      commonState.dispatch(
         ChatThunk.createChat({
            commonState,
            callbacks: {},
            needSetLocation: true,
            admin_chat: false,
            users: [Number(user.id), Number(ownId)],
         })
      );
   }

   if (!user) return null;
   return (
      <div
         className={cn(styles.profile_card, {
            [`${styles[`profile_card_${template.tempRole}`]}`]: true,
         })}
      >
         <div className={styles.user}>
            <img src={avatar} className={styles.avatar} />
            <div className={styles.right_part}>
               <div className={styles.name}>
                  {user.first_name} <br /> {user.last_name}
               </div>
               <div className={styles.user_info}>
                  <RatingIcon className={styles.rating_icon} />
                  <div className={styles.rating}>
                     {user?.study_rating
                        ? Number(user?.study_rating).toFixed(2)
                        : "—"}
                  </div>
                  <div className={styles.role}>{resolveRole()}</div>
               </div>
            </div>
         </div>
         {template.tempRole === "teacher" && template.isOwn && (
            <>
               <OnlineStatusSwitcher className={styles.online} />
            </>
         )}

         {!template.isOwn && (
            <button onClick={openChat} className={styles.send_message}>
               <MessageIcon className={styles.message_icon} />{" "}
               {t("Buttons.write-message", "Написать сообщение")}
            </button>
         )}

         {!template.isOwn &&
            (clientRole == "admin" || clientRole == "superadmin") && (
               <button
                  onClick={() => setIsEditing(!isEditing)}
                  className={styles.send_message}
               >
                  {React.createElement(Icons.buttons.CircleEditIcon, {
                     style: { marginRight: 10 },
                  })}
                  {t("Lk.Profile.edit", "Редактировать")}
               </button>
            )}

         {template.tempRole === "student" &&
            template.isOwn &&
            !template.isExtended && (
               <div className={styles.button_to_teacher_form}>
                  <Icons.avatars.TeacherAvatar className={styles.teach_icon} />
                  <Link to={routes.registration.teacher.firstStage}>
                     <Button
                        variant='link'
                        style={{
                           fontSize: 18,
                           fontWeight: 400,
                           textDecoration: "none",
                        }}
                     >
                        {t("Lk.Profile.get-role", "Хотите преподавать?")}
                     </Button>
                  </Link>
               </div>
            )}
      </div>
   );
};
