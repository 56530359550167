// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__2nNuu {\n  background: var(--color-black4);\n}\n\n.style_finish_lesson__1ZKdI{\n  background: var(--color-black4);\n}\n\n.style_reject_lesson__17q-w{\n  background: #FEE7EB;\n}\n.style_start_lesson__3eADL {\n  background: var(--color-blue4);\n}\n\n\n.style_buttons__2_16p {\n  display: flex;\n  justify-content: space-between;\n}\n\n.style_button__a5_yI {\n  font-family: var(--font-Manrope);\n  color: var(--color-blue2);\n  font-size: 14px;\n  font-weight: 500;\n}", "",{"version":3,"sources":["webpack://src/components/Lk/components/Notifications/variants/NotificationLessonCard/style.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,8BAA8B;AAChC;;;AAGA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,gCAAgC;EAChC,yBAAyB;EACzB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".container {\n  background: var(--color-black4);\n}\n\n.finish_lesson{\n  background: var(--color-black4);\n}\n\n.reject_lesson{\n  background: #FEE7EB;\n}\n.start_lesson {\n  background: var(--color-blue4);\n}\n\n\n.buttons {\n  display: flex;\n  justify-content: space-between;\n}\n\n.button {\n  font-family: var(--font-Manrope);\n  color: var(--color-blue2);\n  font-size: 14px;\n  font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__2nNuu",
	"finish_lesson": "style_finish_lesson__1ZKdI",
	"reject_lesson": "style_reject_lesson__17q-w",
	"start_lesson": "style_start_lesson__3eADL",
	"buttons": "style_buttons__2_16p",
	"button": "style_button__a5_yI"
};
export default ___CSS_LOADER_EXPORT___;
