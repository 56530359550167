import * as yup from "yup";
import { addMethodToUpdateLang } from "services/translateService/useLangInit";
import i18n from "services/translateService/i18n";

export let emailSchema: any;
addMethodToUpdateLang(() => {
   emailSchema = yup.object().shape({
      email: yup
         .string()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         )
         .email(i18n.t("Errors.fields.email", "Введен не коректный E-mail")),
   });
});

export const passwordSchema = yup.object().shape({
   password: yup
      .string()
      .min(
         8,
         i18n.t(
            "Errors.fields.password.length",
            "Пароль должен содержать не менее 8 символов"
         )
      )
      .matches(
         /[a-z,0-9]/giu,
         i18n.t(
            "Errors.fields.password.content",
            "Пароль не должен содержать кириллические символы"
         )
      )
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      ),
   password_confirm: yup
      .string()
      .min(
         8,
         i18n.t(
            "Errors.fields.password.length",
            "Пароль должен содержать не менее 8 символов"
         )
      )
      .matches(
         /[a-z,0-9]/giu,
         i18n.t(
            "Errors.fields.password.content",
            "Пароль не должен содержать кириллические символы"
         )
      )
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      ),
});

export const authPasswordSchema = yup.object().shape({
   password: yup
      .string()
      .min(
         8,
         i18n.t(
            "Errors.fields.password.length",
            "Пароль должен содержать не менее 8 символов"
         )
      )
      .matches(
         /[a-z,0-9]/giu,
         i18n.t(
            "Errors.fields.password.content",
            "Пароль не должен содержать кириллические символы"
         )
      )
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      ),
});

export let fillInfoSchema: any;
addMethodToUpdateLang(() => {
   fillInfoSchema = yup.object().shape({
      first_name: yup
         .string()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         ),
      last_name: yup
         .string()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         ),
      birth_date: yup
         .string()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         )
         .min(10, i18n.t("Errors.fields.birth", "Заполните дату рождения")),
   });
});

export const studentRegistrationSchema = yup.object().shape({
   level: yup
      .string()
      .required(
         i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
      ),
});
export const teacherFilesSchema = yup.object().shape({
   passport: yup.array().required(""),
   grades: yup.array().required(""),
});

export let timeAndDatePickerSchema: any;
addMethodToUpdateLang(() => {
   timeAndDatePickerSchema = yup.object().shape({
      duration: yup
         .object()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         ),
      date_from: yup
         .object()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         ),
      date: yup
         .string()
         .required(
            i18n.t("Errors.fields.required", "Поле обязательно к заполнению")
         ),
   });
});
