// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderLkMobile_container__3k1pk {\n    /*height: 77px;*/\n    /*display: flex;*/\n    /*justify-content: space-between;*/\n    /*align-items: center;*/\n    margin-bottom: -20px;\n    padding: 24px 16px 0;\n    width: 100%;\n    z-index: 10;\n    background: white;\n}\n.HeaderLkMobile_title__10QOS {\n    font-weight: normal;\n    font-size: 26px;\n}\n\n.HeaderLkMobile_title_none__2BSaT {\n    display: none;\n}", "",{"version":3,"sources":["webpack://src/components/Header/HeaderLkMobile/index.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,kCAAkC;IAClC,uBAAuB;IACvB,oBAAoB;IACpB,oBAAoB;IACpB,WAAW;IACX,WAAW;IACX,iBAAiB;AACrB;AACA;IACI,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".container {\n    /*height: 77px;*/\n    /*display: flex;*/\n    /*justify-content: space-between;*/\n    /*align-items: center;*/\n    margin-bottom: -20px;\n    padding: 24px 16px 0;\n    width: 100%;\n    z-index: 10;\n    background: white;\n}\n.title {\n    font-weight: normal;\n    font-size: 26px;\n}\n\n.title_none {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "HeaderLkMobile_container__3k1pk",
	"title": "HeaderLkMobile_title__10QOS",
	"title_none": "HeaderLkMobile_title_none__2BSaT"
};
export default ___CSS_LOADER_EXPORT___;
