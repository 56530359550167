import React, { FC, useEffect } from "react";
import styles from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import { EducationSystemPicker } from "./GradePicker";
import { SubjectPicker } from "./SubjectPicker";
import { TeachersSearch } from "./TeachersSearch";
import {
   fastLessonSlice,
   fastLessonSliceSelectors,
   filtersStageResolver,
} from "store/fastLessonSlice";
import { ReactComponent as BreadIcon } from "./assets/Bread.svg";

type propsType = {
   config: {
      firstBreadCrumbAction: () => void;
   };
};
export const SearchLessonFilter: FC<propsType> = (props) => {
   const filtersState = useSelector(fastLessonSliceSelectors.getFiltersState);
   const dispatch = useDispatch();

   const displayPage = () => {
      switch (filtersState.stage) {
         case 0: {
            return <EducationSystemPicker />;
         }
         case 1: {
            return <SubjectPicker />;
         }
         case 2: {
            return <TeachersSearch />;
         }
      }
   };

   const navigateByBreadcrumb = (stage: number) => {
      dispatch(fastLessonSlice.actions.setFiltersStage(stage));
   };

   /* filtersStageResolver отвечает за соответсвие стадии элементу крошек */
   const displayBreadCrumbs = () => {
      let arr: any = [];
      let key: keyof typeof filtersState.data;
      for (key in filtersState.data) {
         const propsForBread = (function () {
            switch (key) {
               case "level":
               case "language": {
                  return {
                     onClick: props.config.firstBreadCrumbAction,
                  };
               }
               default:
                  return {};
            }
         })();

         let orderIndex = filtersStageResolver[key];

         const isElementaryEducationSystem =
            key == "educationSystem" &&
            filtersState.data[key]?.name == "Elementary School";

         if (
            orderIndex <= filtersState.stage &&
            filtersState.data[key] &&
            !isElementaryEducationSystem
         ) {
            arr.push(
               <div
                  onClick={() => navigateByBreadcrumb(orderIndex - 1)}
                  className={styles.breadcrumbs__item}
                  {...propsForBread}
               >
                  <BreadIcon className={styles.mobile_icon} />
                  {filtersState.data[key]?.name}
               </div>
            );
         }
      }
      return arr;
   };

   useEffect(() => {
      if (
         !filtersState.data.language &&
         !filtersState.data.subject &&
         !filtersState.data.educationSystem &&
         !filtersState.data.level
      ) {
         props.config.firstBreadCrumbAction();
      }
   }, [filtersState.data]);

   return (
      <div className={styles.container}>
         <div className={styles.breadcrumbs}>{displayBreadCrumbs()}</div>
         {displayPage()}
      </div>
   );
};
