import { useEffect } from "react";
import { resolveAndSetTimezone } from "init/time";
import { useLangInit } from "services/translateService/useLangInit";
import { useAuth } from "services/autoAuth/useAuth";
import { lc } from "store/LC";
import "init/vh";
const moment = require("moment-timezone");

export let timeFormatter = (date: string) => {
   return moment(date).tz(lc.get("timeZone"));
};

export const useInit = () => {
   const { readyState } = useLangInit();

   useEffect(() => {
      resolveAndSetTimezone();
   }, []);

   return { readyState };
};
