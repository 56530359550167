import React, { FC, HTMLAttributes } from "react";
import styles from "./style.module.css";
import cn from "classnames";

type propsType = {
   title?: string;
} & HTMLAttributes<HTMLDivElement>;
export const ContentBlockContainer: FC<propsType> = (props) => {
   return (
      <div
         className={cn(styles.container, {
            [`${props.className}`]: props.className,
         })}
         {...props}
         title={undefined}
      >
         <h2 className={styles.title}>{props.title}</h2>
         <main className={styles.main}>{props.children}</main>
      </div>
   );
};
