import React, { FC } from "react";
import styles from "./index.module.css";
import { ReactComponent as ArrowIcon } from "./assets/Arrow.svg";

type propsType = {
   setCurrent: (val: number) => void;
   current: number;
   length: number;
};
export const SliderArrows: FC<propsType> = ({
   current,
   setCurrent,
   length,
}) => {
   return (
      <div className={styles.container}>
         <ArrowIcon
            onClick={() => {
               setCurrent(!current ? 0 : current - 1);
            }}
            className={current == 0 ? styles.gray : ""}
         />
         <ArrowIcon
            className={
               styles.right + " " + (current == length - 1 && styles.gray)
            }
            onClick={() =>
               setCurrent(
                  current == length - 2 ? length - 1 : (current + 1) % length
               )
            }
         />
      </div>
   );
};
