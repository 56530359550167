// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LkTeacherGuestPage_subtitle__12TPh {\n    font-weight: 500;\n    font-size: 28px;\n    margin-bottom: 20px;\n}\n\n.LkTeacherGuestPage_slots_show_more__3Y8u5 {\n    margin-left: 20px;\n    margin-top: 30px;\n}\n\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n\n}\n\n@media screen and (max-width: 1023px) {\n    .LkTeacherGuestPage_subtitle__12TPh {\n        font-size: 22px;\n    }\n}\n\n@media screen and (max-width: 719px) {\n\n}", "",{"version":3,"sources":["webpack://src/components/Lk/Pages/LkProfile/LkTeacher/LkTeacherGuestPage/index.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;IACI;QACI,eAAe;IACnB;AACJ;;AAEA;;AAEA","sourcesContent":[".subtitle {\n    font-weight: 500;\n    font-size: 28px;\n    margin-bottom: 20px;\n}\n\n.slots_show_more {\n    margin-left: 20px;\n    margin-top: 30px;\n}\n\n@media screen and (max-width: 1919px) {\n\n}\n\n@media screen and (max-width: 1439px) {\n\n}\n\n@media screen and (max-width: 1023px) {\n    .subtitle {\n        font-size: 22px;\n    }\n}\n\n@media screen and (max-width: 719px) {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtitle": "LkTeacherGuestPage_subtitle__12TPh",
	"slots_show_more": "LkTeacherGuestPage_slots_show_more__3Y8u5"
};
export default ___CSS_LOADER_EXPORT___;
