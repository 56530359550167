import React, { FC } from "react";
import styles from "./index.module.css";
import { NoteTableItem } from "./TableItem";
import { studentType, teacherType } from "types/userType";
import { subjectType, voteType } from "store/types";
import { useSelector } from "react-redux";
import { authSliceSelectors } from "store/authSlice";
import { Trans, useTranslation, withTranslation } from "react-i18next";
import { lessonType } from "types/LessonService/types";
import { Preloader } from "components/UI/Preloader";
import { useMedia } from "hooks/useMedia";

export type LessonItemType = {
   id: number;
   status: "";
   topic: string;
   student: studentType;
   teacher: teacherType;
   subject: subjectType;
   date_from: string;
   date_to: string;
   votes: Array<voteType>;
   rejectDate: string;
};

type AdminTableType = {
   data: Array<lessonType>;
   loading?: boolean;
   type?: "lesson" | "group_lesson";
};

export const ThisComponent: FC<AdminTableType> = (props) => {
   const { t } = useTranslation();
   const { data } = props;
   const id = useSelector(authSliceSelectors.getId);
   const { width } = useMedia();

   if (props.loading) {
      return (
         <div className={styles.container}>
            <table className={styles.table}>
               <Preloader loading={true} />
            </table>
         </div>
      );
   }
   return (
      <div className={styles.container}>
         <table className={styles.table}>
            {width > 719 && (
               <tr className={styles.header}>
                  <th />
                  <th className={styles.header_item}>
                     {t("Notebook.history.headers.name", "Имя")}
                  </th>
                  <th className={styles.header_item}>
                     <Trans i18nKey={"Notebook.history.headers.date"}>
                        Дата и срок <br /> занятия
                     </Trans>
                  </th>
                  <th className={styles.header_item}>
                     <Trans i18nKey={"Notebook.history.headers.language"}>
                        Язык <br /> преподавания
                     </Trans>
                  </th>
                  <th className={styles.header_item}>
                     <Trans i18nKey={"Notebook.history.headers.subject"}>
                        Предмет
                     </Trans>
                  </th>
                  <th className={styles.header_item}>
                     <Trans i18nKey={"Notebook.history.headers.topic"}>
                        Тема занятия
                     </Trans>
                  </th>
                  <th className={styles.header_item}>
                     <Trans i18nKey={"Notebook.history.headers.rating"}>
                        Оценка
                        <br /> занятия
                     </Trans>
                  </th>
                  <th className={styles.header_item} />
               </tr>
            )}

            {data?.map((item, index) => (
               <NoteTableItem
                  user_id={id}
                  lesson={item}
                  key={index}
                  type={props.type}
               />
            ))}
         </table>
      </div>
   );
};

export const NoteTable = withTranslation()(ThisComponent);
