import React, { FC } from "react";
import styles from "./index.module.css";
import mobileStyles from "./mobile.module.css";
import { ReactComponent as Arrow } from "../../../../../../UI/Table/assets/arrow.svg";
import { lessonType } from "../../../../../../../types/LessonService/types";
import { useGetUserAvatar } from "../../../../../../../hooks/useGetUserAvatar";
import { useHistory } from "react-router-dom";
import { routes } from "../../../../../../../routes";
import { dateMethods } from "../../../../../../../helpers/date";
import { useMedia } from "hooks/useMedia";
import { Icons } from "assets/iconsCorrect";
import cn from "classnames";
import { useSelector } from "react-redux";
import { lkSliceSelectors } from "../../../../../../../store/lkSlice";

export type NoteHistoryTableItemType = {
   lesson: any;
   // user_id: string;
   type?: "lesson" | "group_lesson";
};

export type paymentStatus =
    "success_payin" |
    "new_payin" |
    "processing_payin" |
    "error_payin" |
    "reject_process_payin" |
    "rejected_payin" |
    "new_payout" |
    "processing_payout" |
    "success_payout"

export const statusResolver: {
   [key in paymentStatus]: { title: string };
} = {
   // В процессе
   success_payin: {
      title: "Успешно",
   },
   new_payin: {
      title: "Создан"
   },
   processing_payin: {
      title: "В обработке"
   },
   error_payin: {
      title: "Ошибка"
   },
   reject_process_payin: {
      title: "В процессе отмены"
   },
   rejected_payin: {
      title: "Отменен"
   },

   // Выплаты

   new_payout: {
      title: "Новая выплата"
   },
   processing_payout: {
      title: "В процессе выплаты"
   },
   success_payout: {
      title: "Успешная выплата"
   }
};

export const NoteTableItem: FC<NoteHistoryTableItemType> = (props) => {
   // const { lesson, user_id: clientUserId } = props;
   const { lesson } = props;
   const history = useHistory();
   const { width } = useMedia();

   const user = useSelector(lkSliceSelectors.getUserData);

   const goToLesson = () => {
      // if (props.type == "lesson") {
      history.push(routes.lk.payment.transactions + `/${lesson.id}`);
      // } else if (props.type == "group_lesson") {
      //    history.push(routes.lk.lesson.group.view + `/${lesson.id}`);
      // }
   };

   // const rating = (function () {
   //    if (!lesson.votes.length) return "—";
   //    let rate: number = 0;
   //
   //    lesson.votes.map((vote) => {
   //       rate += Number(vote.rating);
   //    });
   //    rate /= lesson.votes.length;
   //
   //    return rate;
   // })();

   // const user = (function () {
   //    if (!lesson?.student) {
   //       return lesson?.teacher;
   //    } else {
   //       return lesson?.teacher?.id == clientUserId
   //          ? lesson.student
   //          : lesson.teacher;
   //    }
   // })();

   // const [avatar] = useGetUserAvatar(user?.id);

   if (!lesson) return null;

   if (width < 720) {
      return (
         <div className={mobileStyles.item}>
            <div className={cn(mobileStyles.preview, {
               [styles.warning]: lesson?.type === "error_payin",
               [styles.blue_light]: lesson?.type === "success_payin"
            })}>
               <div className={mobileStyles.preview_header}>
                  {/*<div className={mobileStyles.preview_left}>*/}
                     {/*<div*/}
                     {/*   className={mobileStyles.status}*/}
                     {/*   // style={{*/}
                     {/*   //    backgroundColor:*/}
                     {/*   //       clientUserId == lesson.teacher.id*/}
                     {/*   //          ? "#0741AD"*/}
                     {/*   //          : "#7807AD",*/}
                     {/*   // }}*/}
                     {/*/>*/}
                     {/*<img className={mobileStyles.preview_photo} src={avatar} />*/}
                     {/*<div className={mobileStyles.header_nameAndId}>*/}
                     {/*   /!*<p className={mobileStyles.preview_name}>*!/*/}
                     {/*   /!*   {user?.first_name} {user?.last_name}*!/*/}
                     {/*   /!*</p>*!/*/}
                     {/*</div>*/}
                  {/*</div>*/}
                  <div className={cn(mobileStyles.table_item, mobileStyles.table_item_bold)}>{lesson?.invite?.subject?.name}</div>
                  <button
                     onClick={goToLesson}
                     className={mobileStyles.open_button}
                  >
                     <Arrow />
                  </button>
               </div>
               <div className={mobileStyles.preview_row}>
                  <div className={mobileStyles.preview_item}>
                     <p className={mobileStyles.preview_text}>
                        {dateMethods.format(lesson?.invite?.date_from, "DD.MM.YYYY")}
                     </p>
                     <p className={mobileStyles.preview_text}>
                        {dateMethods.getTimeInterval(lesson?.invite?.date_from, lesson?.invite?.date_to)}
                     </p>
                  </div>
                  <div className={cn(mobileStyles.preview_item, mobileStyles.wrap)}>
                     {lesson?.invite?.student?.first_name} {lesson?.invite?.student?.last_name}
                  </div>
                  <div className={mobileStyles.preview_item}>
                     {dateMethods.calculateLessonDuration(lesson?.invite?.date_from, lesson?.invite?.date_to)}<br />
                     <span>{lesson?.amount} {lesson?.currency}</span>
                  </div>
                  <div className={cn(mobileStyles.preview_item, mobileStyles.wrap)}>
                     {lesson?.bank_card?.mps ? lesson?.bank_card?.mps : " - "}
                  </div>
                  {user.type === "teacher" && (
                     <div className={cn(mobileStyles.preview_item, mobileStyles.wrap)}>
                        {statusResolver[lesson?.type as paymentStatus]?.title || " - "}
                     </div>
                  )}
                  {/*{String(rating) != "—" && (*/}
                  {/*   <div className={mobileStyles.preview_item}>*/}
                  {/*      <p className={mobileStyles.rate}>*/}
                  {/*         {rating} <Icons.ui.starFilled />*/}
                  {/*      </p>*/}
                  {/*   </div>*/}
                  {/*)}*/}
               </div>
            </div>
         </div>
      );
   }


   return (
      <>
         <tr className={cn(styles.preview, {
            [styles.warning]: lesson?.type === "error_payin",
            [styles.blue_light]: lesson?.type === "success_payin"
         })}>
            {/*<td className={styles.table_item}>*/}
            {/*   <div*/}
            {/*      // className={styles.status}*/}
            {/*      // style={{*/}
            {/*      //    backgroundColor:*/}
            {/*      //       clientUserId == lesson.teacher.id*/}
            {/*      //          ? "#0741AD"*/}
            {/*      //          : "#7807AD",*/}
            {/*      // }}*/}
            {/*   >*/}
            {/*   </div>*/}
            {/*</td>*/}
            <td className={cn(styles.table_item, styles.item_bold)}>{lesson?.invite?.subject?.name}</td>
            <td className={styles.table_item}>
               {dateMethods.format(lesson?.invite?.date_from, "DD.MM.YYYY")}
               <br />
               {dateMethods.getTimeInterval(lesson?.invite?.date_from, lesson?.invite?.date_to)}
            </td>
            <td className={styles.table_item}>{lesson?.invite?.student?.first_name} {lesson?.invite?.student?.last_name}</td>
            {width > 1023 ? (
               <>
                  <td className={cn(styles.table_item, styles.item_center)}>{dateMethods.calculateLessonDuration(lesson?.invite?.date_from, lesson?.invite?.date_to)}</td>
                  <td className={cn(styles.table_item, styles.item_bold)}>{lesson?.amount} {lesson?.currency}</td>
                  {user.type === "inactive_teacher" && <td className={styles.table_item}>{statusResolver[lesson?.type as paymentStatus]?.title || " - "}</td>}
                  {/*<td className={styles.table_item}>{statusResolver[lesson?.type as paymentStatus]?.title || " - "}</td>*/}
                  {user.type === "teacher" &&
                   <>
                   <td className={styles.table_item}>
                      {lesson?.bank_card?.mps ? lesson?.bank_card?.mps : " - "}<br />
                   </td>
                    <td className={styles.table_item}>
                       <span>{statusResolver[lesson?.type as paymentStatus]?.title || " - "}</span>
                    </td>
                   </>
                  }
                  {user.type === "student" &&
                      <>
                         <td className={styles.table_item}>
                            <span>{statusResolver[lesson?.type as paymentStatus]?.title || " - "}</span>
                         </td>
                      </>
                  }
               </>
            ) : (
               <>
                  <td className={cn(styles.table_item)}>
                     {dateMethods.calculateLessonDuration(lesson?.invite?.date_from, lesson?.invite?.date_to)} <br/>
                     <span className={styles.item_bold}>{lesson?.amount} {lesson?.currency}</span>
                  </td>
                  <td className={cn(styles.table_item)}>
                     {lesson?.bank_card?.mps ? lesson?.bank_card?.mps : " - "}<br />
                     {(user.type === "teacher" || user.type === "student") && <span>{statusResolver[lesson?.type as paymentStatus]?.title || " - "}</span>}
                  </td>
               </>
            )}



            {/*<td className={styles.table_item}>*/}
            {/*   <div className={styles.photoAndName}>*/}
            {/*      /!*<img className={styles.avatar} src={avatar} alt='' />*!/*/}
            {/*      <div className={styles.user}>*/}
            {/*         <p className={styles.user_name}>*/}
            {/*            /!*{user.first_name + " " + user.last_name}*!/*/}
            {/*         </p>*/}
            {/*      </div>*/}
            {/*   </div>*/}
            {/*</td>*/}


            <td className={styles.button}>
               <button onClick={goToLesson}>
                  <Arrow />
               </button>
            </td>
         </tr>
      </>
   );
};
