import React, { FC } from "react";
import { LkTeacherYourPage } from "./LkTeacherYourPage";
import { LkTeacherGuestPage } from "./LkTeacherGuestPage";
import { useLkContext } from "../index";
import { useTranslation } from "react-i18next";
import { InfoBlock } from "components/UI/InfoBlock";

export const LkTeacher: FC = () => {
   const { t } = useTranslation();
   const { template, profileData } = useLkContext();

   if (!profileData?.id) return null;
   return (
      <>
         {template.tempRole == "teacher" ? (
            <>
               <InfoBlock
                  variant='bold'
                  infoKey={t("Lk.LkTeacher.grade", "Грейд")}
               >
                  {profileData?.study_education_system?.level.name || "—"}
               </InfoBlock>
               <InfoBlock
                  variant='bold'
                  infoKey={t("Lk.LkTeacher.system", "Система образования")}
               >
                  {profileData?.instruction_education_systems?.map((item) => (
                     <span>{item.name} </span>
                  ))}
               </InfoBlock>
               {!template.isOwn && (
                  <InfoBlock
                     variant='bold'
                     infoKey={t("Lk.LkTeacherOwn.subjects", "Предметы")}
                  >
                     {profileData?.instruction_subjects?.map((item, index) => (
                        <span>
                           {item.name}
                           {index + 1 !=
                              profileData?.instruction_subjects.length &&
                              ", "}{" "}
                        </span>
                     ))}
                  </InfoBlock>
               )}
            </>
         ) : (
            <>
               <InfoBlock
                  infoKey={t("Lk.LkStudent.grade", "Грейд")}
                  variant='bold'
               >
                  {profileData?.study_education_system?.level.name || "—"}
               </InfoBlock>
               <InfoBlock
                  infoKey={t("Lk.LkStudent.system", "Система образования")}
                  variant='bold'
               >
                  {profileData.study_education_system?.name}
               </InfoBlock>
            </>
         )}

         {template.isOwn ? <LkTeacherYourPage /> : <LkTeacherGuestPage />}
      </>
   );
};
