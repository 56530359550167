import React, { FC, useEffect } from "react";
import styles from "./style.module.css";
import { routes } from "../../../../../../routes";
import { LessonTitle } from "../../components/LessonTitle";
import { LessonBody } from "../../components/LessonBody";
import { UserWidget } from "components/UI/UserWidget";
import { useLocalCommonState } from "core/LTools/core/hooks/useLocalCommonState";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { InviteButtons } from "./InviteButtons";
import { useTranslation } from "react-i18next";
import { authSliceSelectors } from "store/authSlice";
import { useQuery } from "hooks/useQuery";
import { inviteApi } from "api/inviteApi/methods";
import { inviteType } from "types/LessonService/types";
import { Icons } from "assets/iconsCorrect";
import { Preloader } from "components/UI/Preloader";
import { useGetPaymentByPaymentId } from "../../../../../../hooks/useGetPaymentByPaymentId";

const invitePageIconResolver = (status: string) => {
   switch (status) {
      case "new_invite":
      case "not_payment_invite":
         return <Icons.ui.HourglassIcon />;
      case "rejected_invite":
         return <Icons.ui.RejectIcon />;
      case "approved_invite":
         return <Icons.ui.AlarmClockIcon />;
      default:
         return null;
   }
};

var invitePingId = 0;
export const widgetRating = (userId: string, inviteData: inviteType) => {
   if (userId == String(inviteData?.teacher?.id)) {
      return null;
   } else return inviteData?.teacher?.teaching_rating;
};
export const InvitePage: FC = () => {
   const { t } = useTranslation();
   const userId = useSelector(authSliceSelectors.getId);
   const { id: invite_id } = useParams<{ id: string }>();
   const commonState = useLocalCommonState();
   const history = useHistory();

   /* fetch */
   const {
      data: invite,
      isLoading: isInviteLoading,
      fetch,
   } = useQuery(
      () =>
         inviteApi.getSpecificInvite({
            invite_id: invite_id,
         }),
      {
         serializer: (res) => {
            return res?.data.data as inviteType;
         },
      }
   );

   const isTeacher = userId == String(invite?.teacher?.id);

   // TODO вынести
   /* ping */
   const ping = () => {
      invitePingId = window.setTimeout(() => {
         fetch();
         ping();
      }, 120000);
   };

   const { payment } = useGetPaymentByPaymentId(invite?.payin_id);

   useEffect(() => {
      ping();
      return () => window.clearTimeout(invitePingId);
   }, [invite_id]);

   useEffect(() => {
      if (invite?.lesson_id) {
         history.push(routes.lk.lesson.default.view + "/" + invite.lesson_id);
      }
   }, [invite]);

   /* view */
   const invitePageTitleResolver = (status: string) => {
      switch (status) {
         case "new_invite":
         case "not_payment_invite":
            return t(
               "Lk.lesson-service.invite.excepting-confirm",
               "Ожидание подтверждения"
            );
         case "rejected_invite":
            return t(
               "Lk.lesson-service.invite.application-canceled",
               "Заявка отклонена"
            );
         case "approved_invite":
            return t(
               "Lk.Profile.cards.student.lesson_title",
               "Занятие с преподавателем"
            );
         default:
            return "";
      }
   };

   const inviteButtonsVariantResolver = (
      status: string,
      inviteTeacherId: string
   ) => {
      if (status == "rejected") {
         return "default";
      } else if (userId == inviteTeacherId && (status == "new_invite" || status == "payed_invite" || status == "not_payment_invite")) {
         return "teacher";
      } else return "student";
   };

   const getLessonFileMethod = async (id: string) => {
      return await inviteApi.getInviteFile({
         invite_id,
         file_id: id,
      });
   };

   return (
      <div className={styles.container}>
         {isInviteLoading ? (
            <Preloader loading={commonState.loading} variant={"fixed"} />
         ) : invite && invite.id ? (
            <>
               <LessonTitle
                  title={invitePageTitleResolver(invite.status)}
                  icon={invitePageIconResolver(invite.status)}
               />
               {invite.status === "rejected_invite" && (
                  <div className={styles.error_text}>
                     {t("Payment.titles.payment", "Payment")}{": "}
                     {payment?.type === "error_payin"
                        ? payment?.error_text
                        : t("Payment.titles.will-be-returned", "will be returned")}

                  </div>
               )}
               <div className={styles.invite_body}>
                  <UserWidget
                     user={isTeacher ? invite.student : invite.teacher}
                     variant={isTeacher ? "student" : "teacher"}
                  />
                  <LessonBody
                     subject={invite.subject}
                     date_from={invite.date_from}
                     date_to={invite.date_to}
                     language={invite.language}
                     topic={invite.topic}
                     files={invite.files}
                     getFileMethod={getLessonFileMethod}
                  />
                  <InviteButtons
                     variant={inviteButtonsVariantResolver(
                        invite?.status,
                        invite?.teacher?.id
                     )}
                     status={invite.status}
                     invite_id={invite_id}
                     slot_id={invite.slot_id}
                  />
               </div>
            </>
         ) : (
            <></>
         )}
      </div>
   );
};
