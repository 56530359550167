import React, { FC } from "react";
import styles from "./index.module.css";
import { Icons } from "assets/iconsCorrect";

type propsType = {
   title?: string;
   filters: Array<{
      name?: string;
      title: string;
      value: string;
      clearHandler: () => void;
   }>;
};
export const FiltersArea: FC<propsType> = ({ filters, title }) => {
   return (
      <div className={styles.container}>
         {title && <h3 className={styles.title}>{title}</h3>}
         {filters
            ?.filter((filter) => !!filter.value)
            ?.map((filter, index) => (
               <div className={styles.filter}>
                  <span>
                     {filter.title}: {filter.value}
                  </span>
                  <Icons.ui.closeIcon
                     onClick={filter.clearHandler}
                     className={styles.close}
                  />
               </div>
            ))}
      </div>
   );
};
