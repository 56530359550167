import { subjectType } from "store/types";

export const groupSubjectsByEducationSystem = (arr: Array<subjectType>) => {
   if (!arr.length) {
      return [];
   } else {
      /* init */
      let currentGroupIndex = 0;
      let currentGroupKey =
         arr[0].education_system.level.name +
         " " +
         arr[0].education_system.name;
      let groupedSubjects: Array<{ group: string; subjects: subjectType[] }> = [
         { group: currentGroupKey, subjects: [] },
      ];

      /* parsing */
      arr.map((item: subjectType) => {
         const tempGroupKey =
            item.education_system.level.name + " " + item.education_system.name;
         if (currentGroupKey != tempGroupKey) {
            currentGroupIndex++;
            currentGroupKey = tempGroupKey;

            groupedSubjects.push({
               group: currentGroupKey,
               subjects: [],
            });
         }
         groupedSubjects[currentGroupIndex].subjects.push(item);
      });

      return groupedSubjects;
   }
};
