import React, { useState } from "react";
import styles from "./index.module.css";
import cn from "classnames";
import { InputPropsType } from "../Input";

type propsType<T> = {
   falseStatus?: string;
   trueStatus?: string;
   variant: "primary" | "status";
} & InputPropsType<T>;
export function Switcher<T>(props: propsType<T>) {
   const [isChecked, setIsChecked] = useState(props.checked);
   const inputProps = props.control.register(props.name);

   return (
      <label
         className={cn(props.className, {
            [styles.container]: true,
            [styles[`${props.variant}`]]: props.variant,
            [styles.checked]: isChecked,
            // [styles.disabled]:
         })}
      >
         <input
            className={styles.switch_input}
            type='checkbox'
            value={props.value}
            defaultChecked={props.defaultChecked}
            {...inputProps}
            onChange={(e) => {
               inputProps.onChange(e);
               setIsChecked(!isChecked);
               props.onChange && props.onChange(e);
            }}
            disabled={props.disabled}
         />
         <div className={styles.circle_container}>
            <div className={styles.circle} />
         </div>
         {props.trueStatus && props.falseStatus && (
            <div className={styles.text}>
               <p className={styles.top_text}>{props.trueStatus}</p>
               <p className={styles.bottom_text}>{props.falseStatus}</p>
            </div>
         )}
      </label>
   );
}
