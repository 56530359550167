import React, {
   AllHTMLAttributes,
   Dispatch,
   ReactNode,
   SetStateAction,
   SVGProps,
} from "react";
import "./style.css";
import ReactInputMask from "react-input-mask";
import { Control, Controller, FieldError, Path } from "react-hook-form";

export type InputPropsType<T> = {
   mask?: string;
   support?: SVGProps<SVGElement> | ReactNode[] | ReactNode | any;
   control: Control<any>;
   name: Path<any>;
   placeholder?: string;
   type?: string;
   isLoading?: boolean;
   value?: string;
   error?: string;
   // isFilled: boolean;
} & AllHTMLAttributes<HTMLInputElement>;

export function Input<T>(props: InputPropsType<T>) {
   return (
      <div data-class='Input' className={"Input__wrapper " + props.className}>
         <Controller
            control={props.control}
            name={props.name}
            // @ts-ignore
            defaultValue={props.defaultValue}
            render={({ field }) => (
               <ReactInputMask
                  autoComplete='off'
                  // @ts-ignore
                  maskChar={null}
                  mask={props.mask || ""}
                  {...props}
                  {...field}
                  className=''
                  placeholder={props.label}
                  onChange={(e) => {
                     field.onChange(e);
                     if (props.onChange) {
                        props.onChange(e);
                     }
                  }}
               />
            )}
         />
         {props.label && <label className='Input__label'>{props.label}</label>}
         {/*{props.support && props.isLoading && (*/}
         {/*   <div className='Input__support-loader'>{props.support}</div>*/}
         {/*)}*/}
         {props.support && (
            <div data-class='input-support' className='Input__support'>
               {props.support}
            </div>
         )}
         {Boolean(props.error) && (
            <div title={props.error} className='Input__error'>
               {props.error}
            </div>
         )}
      </div>
   );
}
