import React, { FC, useEffect, useState } from "react";
import styles from "./style.module.css";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { authSliceSelectors } from "store/authSlice";
import { lkSliceSelectors } from "store/lkSlice";
import { routes } from "routes";
import { ReactComponent as MenuLkIcon } from "assets/buttons/MenuLkIcon.svg";
import { Icons } from "assets/iconsCorrect";
import {
   navigationLinkDataType,
   navigationLinksData,
} from "components/Lk/components/Menu/navigationLinksData";

type propsType = {
   setIsMenuOpen: any;
   isMenuOpen: boolean;
};
export const MobileMenu: FC<propsType> = (props) => {
   const { t, i18n } = useTranslation();
   const id = useSelector(authSliceSelectors.getId);
   const { type } = useSelector(lkSliceSelectors.getUserData);
   const [menuData, setMenuData] = useState<Array<navigationLinkDataType>>([]);

   useEffect(() => {
      setMenuData(navigationLinksData.filter((item) => item.isMobile));
   }, [i18n.language]);

   return (
      <div className={styles.container}>
         <NavLink
            activeClassName={styles.button_active}
            to={`${routes.lk.profile}/${id}`}
         >
            <button className={styles.button}>
               {React.createElement(MenuLkIcon)}
               {t("Menu.teacherButtons.profile", "Профиль")}
            </button>
         </NavLink>
         {menuData.map((item, index) => {
            return (
               <NavLink activeClassName={styles.button_active} to={item.route}>
                  <button className={styles.button}>
                     {React.createElement(item.support)}
                     {item.text}
                  </button>
               </NavLink>
            );
         })}
         <button
            className={styles.button}
            onClick={() => props.setIsMenuOpen(true)}
         >
            <Icons.ui.burgerIcon />
            {t("Lk.Mobile-menu.button", "Меню")}
         </button>
         {props.isMenuOpen && <div className={styles.background} />}
      </div>
   );
};
