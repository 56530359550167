import React, { FC, useEffect, useState } from "react";
import styles from "./style.module.css";
import { ReactComponent as BookIcon } from "./assets/book.svg";
import { teacherType } from "types/userType";
import { useGetUserAvatar } from "hooks/useGetUserAvatar";
import { TimeInterval } from "components/UI/TimeInterval";
import cn from "classnames";

type propsType = {
   date_from: string;
   date_to: string;
   subject?: string;
   color?: "black" | "white";
   user?: teacherType;
   className?: string;
};
export const CardTime: FC<propsType> = ({ color = "black", ...props }) => {
   const [avatar] = useGetUserAvatar(props?.user?.id);

   return (
      <div
         className={cn(styles.container, props.className, {
            [`${styles[`container--${color}`]}`]: true,
         })}
      >
         <TimeInterval
            color={color}
            date_from={props.date_from}
            date_to={props.date_to}
         />
         {props.subject && (
            <div className={styles.row}>
               <BookIcon className={styles.icon} />
               {props.subject}
            </div>
         )}
         <main>
            {props.user && (
               <div className={styles.user}>
                  <img className={styles.user_avatar} src={avatar} alt='' />
                  {props.user.first_name + " " + props.user.last_name}
               </div>
            )}
            {props?.children}
         </main>
      </div>
   );
};
