import React, { FC } from "react";
import "./style.css";
import { Route } from "react-router-dom";
import { routes } from "routes";
import { Menu } from "./components/Menu";
import { LkProfile } from "./Pages/LkProfile";
import { ScheduleSidebarHoc } from "./components/ScheduleSidebar/ScheduleSidebarHoc";
import { Notebook } from "./Pages/Notebook";
import { NoteHistory } from "./Pages/Notebook/components/NoteHistory";
import { Statistic } from "./Pages/Statistic";
import { Settings } from "./Pages/Settings";
import { LessonsService } from "./Pages/LessonsService";
import { NotePeoples } from "./Pages/Notebook/components/NotePeoples";
import { AdminService } from "./Pages/AdminService";
import { lkSliceSelectors } from "store/lkSlice";
import { useSelector } from "react-redux";
import { SearchLesson } from "./Pages/SearchLesson";
import { Messages } from "./Pages/Messages";
import styles from "./index.module.css";
import { Preloader } from "components/UI/Preloader";
import { SchedulePage } from "components/Lk/Pages/SchedulePage";
import { DownloadCert } from "./Pages/DownloadCert";
import { Payment } from "./Pages/LessonPayment";
import { TransactionsList } from "./Pages/LessonPayment/Pages/TransactionsList";
import { TransactionDetails } from "./Pages/LessonPayment/Pages/TransactionDetails";
import { AddCardForPayout } from "./Pages/LessonPayment/Pages/AddCardForPayout";
import { FinancialOperations } from "./Pages/AdminService/Pages/FinancialOperations";
import { FinancialOperationsDetails } from "./Pages/AdminService/Pages/FinancialOperations/FinancialOperationsDetails";
import { AddCardForPayment } from "./Pages/LessonPayment/Pages/AddCardForPayment";
import { SetOrChangeRate } from "./Pages/LessonPayment/Pages/SetOrChangeRate";
import { FinancialOperationsMain } from "./Pages/AdminService/Pages/FinancialOperationsMain";
import { WorkingWithRegistries } from "./Pages/AdminService/Pages/WorkingWithRegistries";

export const Lk: FC = () => {
   const { id: user_id } = useSelector(lkSliceSelectors.getUserData);

   if (user_id == undefined)
      return <Preloader variant='large' loading={true} />;
   return (
      <div className={styles.container}>
         <Route path={routes.lk.root}>
            <Menu />
            <main className={styles.lk_main}>
               <Route path={routes.lk.messages + "/:type" + "/:chat_id?"}>
                  <Messages />
               </Route>
               <Route path={routes.lk.schedule}>
                  <SchedulePage />
               </Route>
               <Route
                  path={[
                     `${routes.lk.profile}/:type/:id`,
                     `${routes.lk.profile}/:id`,
                  ]}
               >
                  <LkProfile />
               </Route>
               <Route exact path={routes.lk.notebook.root}>
                  <Notebook />
               </Route>

               <Route path={routes.lk.notebook.history}>
                  <NoteHistory />
               </Route>
               <Route
                  path={[
                     routes.lk.statistic.root,
                     routes.lk.statistic.teacher,
                     routes.lk.statistic.student,
                  ]}
               >
                  <Statistic />
               </Route>
               <Route path={routes.lk.settings.root}>
                  <Settings />
               </Route>
               <Route path={routes.lk.admin.root}>
                  <AdminService />
               </Route>
               <Route exact path={routes.lk.financialOperations.root}>
                  <FinancialOperationsMain />
               </Route>
               <Route exact path={routes.lk.financialOperations.operations.root}>
                  <FinancialOperations />
               </Route>
               <Route path={routes.lk.financialOperations.operations.root + "/:paymentId"}>
                  <FinancialOperationsDetails />
               </Route>
               <Route path={routes.lk.financialOperations.operations.workingWithRegistries}>
                  <WorkingWithRegistries />
               </Route>
               <Route path={routes.lk.search_lesson.root}>
                  <SearchLesson />
               </Route>
               <Route path={routes.lk.lesson.root}>
                  <LessonsService />
               </Route>
               <Route path={routes.lk.notebook.peoples.root}>
                  <NotePeoples />
               </Route>
               <Route exact path={routes.lk.payment.root}>
                  <Payment />
               </Route>
               <Route exact path={routes.lk.payment.transactions}>
                  <TransactionsList />
               </Route>
               <Route path={routes.lk.payment.transactions + "/:paymentId"}>
                  <TransactionDetails />
               </Route>
               <Route exact path={routes.lk.payment.addCardForPayout}>
                  <AddCardForPayout />
               </Route>
               <Route exact path={routes.lk.payment.addCardForPayment}>
                  <AddCardForPayment />
               </Route>
               <Route exact path={routes.lk.payment.setOrChangeRate}>
                  <SetOrChangeRate />
               </Route>
               <ScheduleSidebarHoc />
            </main>
         </Route>
      </div>
   );
};
