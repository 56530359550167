// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreatePassword_text__1Vlxh {\n    font-size: 14px;\n    text-align: center;\n    margin-bottom: 10px;\n    margin-top: -30px;\n    line-height: 20px;\n}\n\n.CreatePassword_button_link__1Xpg0 {\n    width: 100%;\n    text-align: center;\n    margin-bottom: 20px;\n}\n\n.CreatePassword_input__39gSM {\n    margin-bottom: 20px;\n}\n\n.CreatePassword_button_action__2sDHe {\n    margin-top: 40px;\n}\n\n@media screen and (max-width: 719px) {\n    .CreatePassword_text__1Vlxh {\n        margin-top: 0;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/Registration/Auth/CreatePassword/index.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".text {\n    font-size: 14px;\n    text-align: center;\n    margin-bottom: 10px;\n    margin-top: -30px;\n    line-height: 20px;\n}\n\n.button_link {\n    width: 100%;\n    text-align: center;\n    margin-bottom: 20px;\n}\n\n.input {\n    margin-bottom: 20px;\n}\n\n.button_action {\n    margin-top: 40px;\n}\n\n@media screen and (max-width: 719px) {\n    .text {\n        margin-top: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "CreatePassword_text__1Vlxh",
	"button_link": "CreatePassword_button_link__1Xpg0",
	"input": "CreatePassword_input__39gSM",
	"button_action": "CreatePassword_button_action__2sDHe"
};
export default ___CSS_LOADER_EXPORT___;
